import queryString from 'query-string';
import React, { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from 'theme-ui';
import { getAllProjectMasterData, getProjectById, getProjectByIdSuccess, getSowChecklist, getSowFields } from "../../../store/actions/project.action";
import StepOne from './StepOne/StepOne';
import StepThree from './StepThree/StepThree';
import StepTwo from './StepTwo/StepTwo';
import theme from '../../../theme';

const CreateProjectForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = queryString.parse(location.search) as any;
  const [state, setState] = useState(2);
  const [steps, setSteps] = useState(false);
  const dispatch = useDispatch();
  const stepLabels = ["Create new project", "Selected Stages", "Scope of work"];

  const getStepColors = (stepIndex: number) => {
    const isActive = (stepIndex + 1) === state;
    const isCompleted = (stepIndex + 1) < state;
    const backgroundColor = isActive ? `${theme.colors?.primary}` : isCompleted ? `${theme.colors?.primary}` : `${theme?.colors?.lightbackground}`;
    const textColor = backgroundColor === `${theme?.colors?.lightbackground}` ? `${theme.colors?.black}` : `${theme.colors?.white}`;
    return { backgroundColor, textColor };
  };

  useEffect(() => {
    dispatch(getAllProjectMasterData());
    dispatch(getSowChecklist());
    dispatch(getSowFields());
  }, [dispatch])

  useEffect(() => {
    const parsedStep = parsed?.step ? parseInt(parsed?.step) : 1;
    setState(parsedStep);
    if (parsed?.id) {
      dispatch(getProjectById(parsed?.id));
    }
  }, [parsed, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(getProjectByIdSuccess([]))
    }
  }, [])

  return (
    <div className="main-content">
      <Text as={"h1"} className="step-label text-center">{stepLabels[state - 1]}</Text>
      <Stepper activeStep={state}>
        {stepLabels.map((_, index) => (
          <Step key={index}
            onClick={() => {
              if (parsed?.id) {
                let url = "";
                if (index === 0) {
                  url = parsed?.id ? `/create-project?step=${index + 1}&id=${parsed.id}` : `/create-project?step=${index + 1}`
                } else if (state === 1 && index === 1) {
                  url = steps ? `/create-project?step=${index + 1}&id=${parsed.id}` : `/create-project?step=${2}&id=${parsed.id}`
                } else {
                  url = steps ? `/create-project?step=${index + 1}&id=${parsed.id}` : `/create-project?step=${state}&id=${parsed.id}`
                }
                navigate(url);
              }
            }} style={{ backgroundColor: getStepColors(index).backgroundColor, color: getStepColors(index).textColor }}
          />))}
      </Stepper>
      {state === 3 ?
        <StepThree /> :
        state === 2 ?
          <StepTwo setSteps={setSteps} /> :
          <StepOne />}
    </div>
  );
};

export default CreateProjectForm;

import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { RootState } from '../../@types';
import { useSelector } from 'react-redux';
import { allowedRolesForAdministration } from '../Components/pages/Sidebar';

const PrivateAdminRoute: React.FC<any> = ({ children }) => {
    const isAuthenticated = localStorage.getItem('token');
    const { auther } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (auther?.Email) {
            const hasAllowedRole = auther?.Role?.some(r => allowedRolesForAdministration.includes(r.RoleName));

            if (!hasAllowedRole)
                navigate('/')
        }
    }, [auther?.Email]) //TODO IMPROVE - this can be improved by just moving the logic at higher level. So users wont see the unauthorized page for 1-2 seconds on refresh

    console.log("DEBUG auther?.Role", auther);

    if ((!isAuthenticated)) {
        return <Navigate to="/" replace />;
    }
    return <>{children}</>;
};

export default PrivateAdminRoute;

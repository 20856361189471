import { Divider, Text } from "theme-ui";
import { TabelHeadingTxt } from "../../../Text/TabelCommonTxt";
import plus from "../../../assets/images/Icon/plus.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ColumnDef } from "@tanstack/react-table";
import TableActions from "../../Roles/TableActions";
import { setPagination } from "../../../../store/actions/Pagination.action";
import DataTable from "../../../Tabels/Tabel";
import { getApiEndPoint } from "../../../../utils/GenerateQueryString";
import { getUploadType } from "../../../../store/actions/masterData.action";

const UploadTypeMasterData: React.FC = () => {
    const navigate = useNavigate();
    const { paginations } = useSelector((state: any) => state.pagination);
    const { uploadTypes, loader } = useSelector((state: any) => state.masterData);
    console.log('chk19', uploadTypes);

    const [refresh, setRrefresh] = useState<boolean>(true);
    const dispatch = useDispatch()
    const location = useLocation();

    const handleclick = () => {
        navigate("/create-upload-type")
    }

    const columns = useMemo<ColumnDef<any>[]>(() => [

        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((paginations?.currentPage - 1) * paginations?.limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "name",
            header: 'Upload Type Name',
            cell: info => info.getValue(),
        },
        {
            header: 'Accessor',
            accessorKey: "Accessor",
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <TableActions row={row} editTo={`/edit-upload-Types/${row?.original?._id}`} deleteMessage='Are you sure you want to delete this Upload Type?' deleteApi={`uploadTypes/deleteUploadTypes/${row?.original?._id}`} toggleRefresh={() => setRrefresh(true)} />
                )
            },
        }
    ], [uploadTypes]);
    useEffect(() => {
        return () => {
            dispatch(setPagination({
                limit: 15,
                currentPage: 1,
            }))
        }
    }, [dispatch])
    console.log('chk75', uploadTypes?.count);
    useEffect(() => {
        let apiEndPoint = getApiEndPoint(paginations, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
        dispatch(getUploadType(apiEndPoint))
    }, [location?.search, dispatch]);
    useEffect(() => {
        if (refresh) {
            let apiEndPoint = getApiEndPoint(paginations, "", columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
            dispatch(getUploadType(apiEndPoint));
            setRrefresh(false);
        }
    }, [refresh])
    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Upload Type' Count={uploadTypes?.count} BtnTxt='Create Upload Type' src={plus} onClick={handleclick} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                loader={loader}
                columns={columns}
                data={uploadTypes?.data || []}
                TotalLength={uploadTypes?.count || 15}
                perPageCount={paginations?.limit}
                currentPage={paginations?.currentPage}
                onPerPageChange={(itemsPerPage) => {
                    dispatch(setPagination({
                        limit: itemsPerPage,
                        currentPage: 1
                    }));
                    setRrefresh(true);
                }}
                onPageChange={(value) => {
                    dispatch(setPagination({
                        ...paginations,
                        ...{ currentPage: value }
                    }));
                    setRrefresh(true);
                }} />

        </div>
    )
}

export default UploadTypeMasterData
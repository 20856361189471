import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button, Image, Paragraph, Spinner, Text } from "theme-ui";
import clockleft from "../../../Components/assets/images/clock-left.svg";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { AllActivities } from "../../../utils/GenerateQueryString";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { getAllRevertHistory, getRevertHistoryByView } from "../../../store/actions/project.action";
import { useSelector } from "react-redux";
import { ActivityModalProps, HistoryItem } from "../../../../@types";

const ActivityRevertHistory: React.FC<ActivityModalProps> = ({ isOpen, toggle, view, title = "All Activities", items,
    hasMore,
    fetchMoreData,
    dataForTable, projectId, stageId, taskId }) => {
    const [selectedTab, setSelectedTab] = useState("Single");
    const [selectedStartTime, setSelectedStartTime] = useState(moment());
    const [selectedEndTime, setSelectedEndTime] = useState(moment().add(1, "hour"));
    const [singleItems, setSingleItems] = useState<HistoryItem[]>([]);
    const [singlePageNumber, setSinglePageNumber] = useState(1);
    const [singleTotalItems, setSingleTotalItems] = useState(0);
    const [singleHasMore, setSingleHasMore] = useState(true);
    const [hourlyItems, setHourlyItems] = useState<HistoryItem[]>([]);
    const [hourlyPageNumber, setHourlyPageNumber] = useState(1);
    const [hourlyTotalItems, setHourlyTotalItems] = useState(0);
    const [hourlyHasMore, setHourlyHasMore] = useState(true);
    const [dailyItems, setdailyItems] = useState<HistoryItem[]>([]);
    const [dailyPageNumber, setDailyPageNumber] = useState(1);
    const [dailyTotalItems, setDailyTotalItems] = useState(0);
    const [dailyHasMore, setDailyHasMore] = useState(true);
    const limit = 10;
    const [selectedDailyDate, setSelectedDailyDate] = useState<Date | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const dispatch = useDispatch();
    const { allHistoryrevert, loader } = useSelector((state: any) => state.project);

    useEffect(() => {
        if (isOpen && selectedTab === "Single" && singlePageNumber === 1 && singleItems?.length === 0) {

            const payload = {
                projectId: projectId || null,
                stageId: stageId || null,
                taskId: taskId || null,
                scope: "single",
                page: 1,
                limit: limit,
            }
            const queryParams = new URLSearchParams(
                Object.fromEntries(
                    Object.entries(payload)?.map(([key, value]) => [key, value != null ? String(value) : ""])
                )
            ).toString();
            dispatch(getAllRevertHistory(queryParams));
        }
    }, [selectedTab, isOpen, projectId]);
    useEffect(() => {
        if (allHistoryrevert?.data?.length && selectedTab === "Single") {
            if (singlePageNumber === 1) {
                setSingleItems(allHistoryrevert?.data);
            } else {
                setSingleItems((prevItems) => [...prevItems, ...allHistoryrevert?.data]);
            }
            if (allHistoryrevert?.count) {
                setSingleTotalItems(allHistoryrevert?.count);
            }
            if (
                allHistoryrevert?.data?.length < limit ||
                singleItems.length >= allHistoryrevert?.count
            ) {
                setSingleHasMore(false);
            }
        }
    }, [allHistoryrevert?.data, singlePageNumber, selectedTab]);

    const generateSinglePayload = (page: number) => {
        return {
            projectId: projectId || null,
            stageId: stageId || null,
            taskId: taskId || null,
            scope: "single",
            page: page,
            limit: limit,
        };
    };
    const fetchMoreSingleData = () => {
        if (singleItems?.length >= singleTotalItems) {
            setSingleHasMore(false);
            return;
        }
        const nextPage = singlePageNumber + 1;
        setSinglePageNumber(nextPage);
        const payload = generateSinglePayload(nextPage);
        const queryParams = new URLSearchParams(
            Object.fromEntries(
                Object.entries(payload)?.map(([key, value]) => [key, value != null ? String(value) : ""])
            )
        ).toString();
        dispatch(getAllRevertHistory(queryParams));
    };

    useEffect(() => {
        if (allHistoryrevert?.data?.length) {
            if (hourlyPageNumber === 1) {
                setHourlyItems(allHistoryrevert?.data);
            } else {
                setHourlyItems((prevItems) => [...prevItems, ...allHistoryrevert?.data]);
            }
            if (allHistoryrevert?.count) {
                setHourlyTotalItems(allHistoryrevert?.count);
            }
            if (
                allHistoryrevert?.data?.length < limit ||
                hourlyItems.length >= allHistoryrevert?.count
            ) {
                setHourlyHasMore(false);
            }
        }
    }, [allHistoryrevert?.data, hourlyPageNumber]);
    useEffect(() => {
        if (allHistoryrevert?.data?.length) {
            if (dailyPageNumber === 1) {
                setdailyItems(allHistoryrevert?.data);
            } else {
                setdailyItems((prevItems) => [...prevItems, ...allHistoryrevert?.data]);
            }
            if (allHistoryrevert?.count) {
                setDailyTotalItems(allHistoryrevert?.count);
            }
            if (
                allHistoryrevert?.data?.length < limit ||
                dailyItems?.length >= allHistoryrevert?.count
            ) {
                setDailyHasMore(false);
            }
        }
    }, [allHistoryrevert?.data, dailyPageNumber]);
    const fetchMoreHourlyData = () => {
        if (hourlyItems?.length >= hourlyTotalItems) {
            setHourlyHasMore(false);
            return;
        }
        const nextPage = hourlyPageNumber + 1;
        setHourlyPageNumber(nextPage);
        const payload = generateHourlyPayload(nextPage);
        const queryParams = new URLSearchParams(
            Object.fromEntries(
                Object.entries(payload)?.map(([key, value]) => [key, value != null ? String(value) : ""])
            )
        ).toString();
        dispatch(
            getAllRevertHistory(queryParams)
        );
    };
    const fetchMoreDailyData = () => {
        if (dailyItems?.length >= dailyTotalItems) {
            setDailyHasMore(false);
            return;
        }
        const nextPage = dailyPageNumber + 1;
        setDailyPageNumber(nextPage);
        const payload = generateDailyPayload(nextPage);
        if (!payload) return;
        const queryParams = new URLSearchParams(
            Object.fromEntries(
                Object.entries(payload)?.map(([key, value]) => [key, value != null ? String(value) : ""])
            )
        ).toString();
        dispatch(
            getAllRevertHistory(queryParams)
        );
    };
    const generateHourlyPayload = (page: number) => {
        const formatDateTime = (date: Date, time: moment.Moment): string => {
            console.log('chk111', time);

            const combinedDateTime = moment(date)
                .set({
                    hour: time.hour(),
                    minute: time.minute(),
                    second: 0,
                    millisecond: 0,
                });
            return combinedDateTime.utc().toISOString();
        };
        return {
            projectId: projectId || null,
            stageId: stageId || null,
            taskId: taskId || null,
            scope: selectedTab === "Hourly" ? "hour" : selectedTab === "Daily" ? "day" : "",
            greaterThanTime: selectedDate && selectedStartTime ? formatDateTime(selectedDate, selectedStartTime) : "",
            lessThanTime: selectedDate && selectedEndTime ? formatDateTime(selectedDate, selectedEndTime) : "",
            page: page,
            limit: limit,
        };
    };
    const groupedByDate = useMemo(() => {
        const grouped: { [key: string]: any[] } = {};
        dailyItems?.forEach((history: any) => {
            const date = moment.utc(history.createdAt).format("YYYY-MM-DD");
            if (!grouped[date]) {
                grouped[date] = [];
            }
            grouped[date].push(history);
        });
        return grouped;
    }, [dailyItems]);
    const groupedByHour = useMemo(() => {
        const grouped: { [key: string]: any[] } = {};
        allHistoryrevert?.data?.forEach((history: any) => {
            const hour = moment(history.createdAt).hour();
            if (!grouped[hour]) {
                grouped[hour] = [];
            }
            grouped[hour].push(history);
        });
        return grouped;
    }, [allHistoryrevert?.data]);
    const uniqueItems = useMemo(() => {
        const seen = new Set();
        return dailyItems?.filter((history: any) => {
            const identifier = history?.changes?.updatedAt || history?.changes?.updateObject?.updatedAt;
            if (seen.has(identifier)) {
                return false;
            } else {
                seen.add(identifier);
                return true;
            }
        });
    }, [dailyItems]);

    const handleHourlySubmit = () => {
        if (selectedDate && selectedStartTime && selectedEndTime) {
            console.log("Selected Date:", selectedDate.toISOString());
            console.log("Selected Start Time:", selectedStartTime.format("HH:mm"));
            console.log("Selected End Time:", selectedEndTime.format("HH:mm"));
        }
        const payload = generateHourlyPayload(1)
        const queryParams = new URLSearchParams(
            Object.fromEntries(
                Object.entries(payload).map(([key, value]) => [key, value != null ? String(value) : ""])
            )
        ).toString();
        console.log('payload', payload);
        console.log('query', queryParams);

        dispatch(getAllRevertHistory(queryParams));
    }
    const generateDailyPayload = (page: number) => {
        if (!selectedDailyDate) return null;

        const startTime = moment(selectedDailyDate).startOf('day').utc().toISOString();
        const endTime = moment(selectedDailyDate).endOf('day').utc().toISOString();

        return {
            projectId: projectId || null,
            stageId: stageId || null,
            taskId: taskId || null,
            scope: "day",
            greaterThanTime: startTime,
            lessThanTime: endTime,
            page: page,
            limit: limit,
        };
    };
    useEffect(() => {
        if (selectedDailyDate) {
            const payload = generateDailyPayload(1);
            if (!payload) return;

            const queryParams = new URLSearchParams(
                Object.fromEntries(
                    Object.entries(payload)?.map(([key, value]) => [key, value != null ? String(value) : ""])
                )
            ).toString();

            console.log('Payload sent to backend:', queryParams);
            dispatch(getAllRevertHistory(queryParams));
        }
    }, [selectedDailyDate]);
    const handleDailyDateChange = async (date: Date | null): Promise<void> => {
        console.log('chk198', date);

        if (!date) {
            console.error("Invalid date selected.");
            return;
        }
        setdailyItems([]);
        setSelectedDailyDate(date);
    };

    // const handleRevert = () => {
    //  const payload={
    //     _id:history
    //  }
    // }
    useEffect(() => {
        if (selectedTab === "Single") {
            // setSingleItems([]);
            setSinglePageNumber(1);
            setSingleTotalItems(0);
            setSingleHasMore(true);
        } else if (selectedTab === "Hourly") {
            setHourlyItems([]);
            setHourlyPageNumber(1);
            setHourlyTotalItems(0);
            setHourlyHasMore(true);
            setSelectedDate(null);
            setSelectedStartTime(moment());
            setSelectedEndTime(moment().add(1, "hour"));
        } else if (selectedTab === "Daily") {
            setdailyItems([]);
            setDailyPageNumber(1);
            setDailyTotalItems(0);
            setDailyHasMore(true);
            setSelectedDailyDate(null);
        }
    }, [selectedTab]);
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" centered>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <div>
                    <Tabs onSelect={(index) => {
                        const tabNames = ["Single", "Hourly", "Daily"];
                        setSelectedTab(tabNames[index]);
                    }}>
                        <TabList>
                            <Tab>Single</Tab>
                            <Tab>Hourly</Tab>
                            <Tab>Daily</Tab>
                        </TabList>
                        <TabPanel >
                            {selectedTab === "Single" && (
                                <>
                                    {singleItems?.length > 0 ? (
                                        <InfiniteScroll
                                            dataLength={singleItems?.length || 0}
                                            next={fetchMoreSingleData}
                                            hasMore={singleHasMore}
                                            loader={
                                                <p>
                                                    <Spinner />
                                                </p>
                                            }
                                            height={600}
                                            endMessage={<p></p>}
                                        >
                                            {singleItems?.map((history: any, index: number) => {
                                                console.log('chk80', history);

                                                const activityText = AllActivities(
                                                    history?.action,
                                                    history?.changes,
                                                    history?.oldData,
                                                    dataForTable?.data?.allProjectManagers,
                                                    dataForTable?.data?.allTaskManagers,
                                                    dataForTable?.data?.allProjectWorkers,
                                                    dataForTable?.data?.allTaskWorkers,
                                                    dataForTable?.data?.allHighlighters,
                                                    dataForTable?.data?.allArchitects,
                                                    true
                                                );
                                                if (!activityText) return null;
                                                return (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                        <div style={{ flex: 1, marginRight: 5 }}>
                                                            <Paragraph
                                                                style={{ gap: 2 }}
                                                                variant="activityText"
                                                            >
                                                                <strong>
                                                                    {history?.changes?.updatedBy ||
                                                                        history?.changes?.updateObject
                                                                            ?.updatedBy ||
                                                                        "Someone"}
                                                                </strong>{" "}
                                                                {activityText}
                                                            </Paragraph>
                                                        </div>
                                                        <Image
                                                            src={clockleft}
                                                            alt="clock"
                                                            style={{
                                                                width: 20,
                                                                marginRight: '5px',
                                                                cursor: "pointer",
                                                                marginLeft: "auto",
                                                            }}
                                                            onClick={() => {
                                                                dispatch(getRevertHistoryByView({ _id: history?._id }))
                                                                toggle();
                                                            }}
                                                        />
                                                        <Paragraph
                                                            sx={{
                                                                textAlign: "end",
                                                                fontSize: "10px",
                                                                color: "#D3D5D9",
                                                                marginBottom: "0px",
                                                                flexBasis: "100%",
                                                            }}
                                                        >
                                                            {moment(history?.createdAt).format(
                                                                "MMM DD, LT"
                                                            )}
                                                        </Paragraph>
                                                    </div>
                                                );
                                            })}
                                        </InfiniteScroll>
                                    ) : (
                                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                                            <Text>No Activities found!!</Text>
                                        </div>
                                    )}
                                </>
                            )}

                        </TabPanel>
                        <TabPanel>
                            {selectedTab === "Hourly" && (
                                <div className="container">
                                    {/* Row for Date, Time Pickers, and Submit Button */}
                                    <div className="row align-items-center mb-4">
                                        {/* Date Picker */}
                                        <div className="col-md-4">
                                            <div className="mb-2">
                                                <Text variant="SelectLabel">Date</Text>
                                            </div>
                                            <ReactDatePicker
                                                selected={selectedDate}
                                                onChange={(date) => setSelectedDate(date)}
                                                placeholderText="Select date"
                                                className="form-control"
                                            />
                                        </div>

                                        {/* Time Pickers */}
                                        <div className="col-md-6">
                                            <div className="mb-2">
                                                <Text variant="SelectLabel">Select time</Text>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <TimePicker
                                                    showSecond={false}
                                                    value={selectedStartTime}
                                                    className="form-control me-2"
                                                    onChange={(value) => setSelectedStartTime(value)}
                                                    format="HH:mm"
                                                    use12Hours={true}
                                                    placeholder="Select Time"
                                                    disabled={!selectedDate}
                                                />
                                                <span className="mx-2">To</span>
                                                <TimePicker
                                                    showSecond={false}
                                                    value={selectedEndTime}
                                                    className="form-control"
                                                    onChange={(value) => setSelectedEndTime(value)}
                                                    format="HH:mm"
                                                    use12Hours={true}
                                                    placeholder="Select Time"
                                                    disabled={!selectedDate}
                                                />
                                            </div>
                                        </div>

                                        {/* Submit Button */}
                                        <div className="col-md-2 d-flex justify-content-end mt-5">
                                            <Button
                                                type="submit"
                                                onClick={handleHourlySubmit}
                                                className="btn btn-primary"
                                                disabled={!selectedDate || !selectedStartTime || !selectedEndTime}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>

                                    {/* Row for Hourly Items */}
                                    <div className="row">
                                        <div className="col-12">
                                            {selectedDate && selectedStartTime && selectedEndTime ? (
                                                hourlyItems && hourlyItems?.length > 0 ? (
                                                    Object?.keys(groupedByHour)?.map((hour, index) => {
                                                        const startHour = parseInt(hour);
                                                        const endHour = startHour + 1;
                                                        const startTimestamp = moment(selectedDate)
                                                            .set({
                                                                hour: moment(selectedStartTime).hour(),
                                                                minute: moment(selectedStartTime).minute(),
                                                                second: moment(selectedStartTime).second(),
                                                            })
                                                            .toISOString();

                                                        const endTimestamp = moment(selectedDate)
                                                            .set({
                                                                hour: moment(selectedEndTime).hour(),
                                                                minute: moment(selectedEndTime).minute(),
                                                                second: moment(selectedEndTime).second(),
                                                            })
                                                            .toISOString();


                                                        const startFormatted = moment(selectedStartTime).format("h:mm a");
                                                        const endFormatted = moment(selectedEndTime).format("h:mm a");
                                                        const hourLabel = `${startFormatted} to ${endFormatted}`;
                                                        return (
                                                            <div key={index} className="mb-3">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <h3 className="m-0">{hourlyItems?.length > 0 && (hourLabel)}</h3>
                                                                    <img
                                                                        src={clockleft}
                                                                        alt="clock"
                                                                        style={{
                                                                            width: 20,
                                                                            marginRight: "5px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => {
                                                                            const payload = {
                                                                                projectId: projectId,
                                                                                stageId: stageId,
                                                                                taskId: taskId,
                                                                                gte: startTimestamp,
                                                                                lte: endTimestamp,
                                                                            };
                                                                            console.log('chk530', payload);

                                                                            dispatch(getRevertHistoryByView(payload));
                                                                            toggle();
                                                                        }}
                                                                    />
                                                                </div>
                                                                <InfiniteScroll
                                                                    dataLength={hourlyItems?.length || 0}
                                                                    next={fetchMoreHourlyData}
                                                                    hasMore={hourlyHasMore}
                                                                    loader={
                                                                        <p>

                                                                            <Spinner />

                                                                        </p>
                                                                    }
                                                                    height={500}
                                                                >
                                                                    {hourlyItems?.map((history, idx) => {
                                                                        const activityText = AllActivities(
                                                                            history?.action,
                                                                            history?.changes,
                                                                            history?.oldData,
                                                                            dataForTable?.data?.allProjectManagers,
                                                                            dataForTable?.data?.allTaskManagers,
                                                                            dataForTable?.data?.allProjectWorkers,
                                                                            dataForTable?.data?.allTaskWorkers,
                                                                            dataForTable?.data?.allHighlighters,
                                                                            dataForTable?.data?.allArchitects,
                                                                            true
                                                                        );
                                                                        if (!activityText) return null;
                                                                        return (
                                                                            <div
                                                                                key={idx}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "space-between",
                                                                                    alignItems: "center",
                                                                                    flexWrap: "wrap",
                                                                                }}
                                                                            >
                                                                                <div style={{ flex: 1, marginRight: 5 }}>
                                                                                    <Paragraph style={{ gap: 2 }} variant="activityText">
                                                                                        <strong>{history?.changes?.updatedBy || "Someone"}</strong> {activityText}
                                                                                    </Paragraph>
                                                                                </div>
                                                                                <Paragraph
                                                                                    sx={{
                                                                                        textAlign: "end",
                                                                                        fontSize: "10px",
                                                                                        color: "#D3D5D9",
                                                                                        marginBottom: "0px",
                                                                                        flexBasis: "100%",
                                                                                    }}
                                                                                >
                                                                                    {moment(history?.createdAt).format("MMM DD, LT")}
                                                                                </Paragraph>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </InfiniteScroll>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                                                        <Text>No Activities found!!</Text>
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            )}



                        </TabPanel>
                        <TabPanel>
                            {selectedTab === "Daily" && (
                                <>
                                    <Text variant="SelectLabel">Date:</Text>

                                    <div style={{ display: "flex" }}>
                                        <ReactDatePicker
                                            selected={selectedDailyDate}
                                            onChange={(date) => handleDailyDateChange(date)}
                                            placeholderText="Select a date"
                                            className="custom-time-picker"
                                        />
                                    </div>

                                    {selectedDailyDate && (
                                        <>
                                            {uniqueItems && uniqueItems?.length > 0 ? (
                                                Object.keys(groupedByDate)?.map((date, index) => {
                                                    const gte = moment.utc(date).startOf('day').toISOString();
                                                    const lte = moment.utc(date).endOf('day').toISOString();
                                                    return (
                                                        <div key={index}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <h3 className="mt-3">{moment(date)?.format("MMM DD, YYYY")}</h3>
                                                                <img
                                                                    src={clockleft}
                                                                    alt="clock"
                                                                    style={{
                                                                        width: 20,
                                                                        marginRight: "5px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        const payload = {
                                                                            projectId: projectId,
                                                                            stageId: stageId,
                                                                            taskId: taskId,
                                                                            gte: gte,
                                                                            lte: lte
                                                                        };
                                                                        dispatch(getRevertHistoryByView(payload));
                                                                        toggle();
                                                                    }}
                                                                />
                                                            </div>
                                                            <InfiniteScroll
                                                                dataLength={dailyItems?.length || 0}
                                                                next={fetchMoreDailyData}
                                                                hasMore={dailyHasMore}
                                                                loader={
                                                                    <p>

                                                                        <Spinner />

                                                                    </p>
                                                                }
                                                                height={500}
                                                            >
                                                                {uniqueItems?.map((history, idx) => {
                                                                    const activityText = AllActivities(
                                                                        history?.action,
                                                                        history?.changes,
                                                                        history?.oldData,
                                                                        dataForTable?.data?.allProjectManagers,
                                                                        dataForTable?.data?.allTaskManagers,
                                                                        dataForTable?.data?.allProjectWorkers,
                                                                        dataForTable?.data?.allTaskWorkers,
                                                                        dataForTable?.data?.allHighlighters,
                                                                        dataForTable?.data?.allArchitects,
                                                                        true
                                                                    );

                                                                    if (!activityText) return null;
                                                                    return (
                                                                        <div
                                                                            key={idx}
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                                flexWrap: "wrap",
                                                                            }}
                                                                        >
                                                                            <div style={{ flex: 1, marginRight: 5 }}>
                                                                                <Paragraph style={{ gap: 2 }} variant="activityText">
                                                                                    <strong>{history?.changes?.updatedBy || "Someone"}</strong> {activityText}
                                                                                </Paragraph>
                                                                            </div>
                                                                            <Paragraph
                                                                                sx={{
                                                                                    textAlign: "end",
                                                                                    fontSize: "10px",
                                                                                    color: "#D3D5D9",
                                                                                    marginBottom: "0px",
                                                                                    flexBasis: "100%",
                                                                                }}
                                                                            >
                                                                                {moment(history?.createdAt).format("MMM DD, LT")}
                                                                            </Paragraph>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </InfiniteScroll>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div style={{ textAlign: "center", marginTop: "20px" }}>
                                                    <Text >No Activities found!!</Text>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                        </TabPanel>
                    </Tabs>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ActivityRevertHistory
import { all } from 'redux-saga/effects';
import { loginSaga } from './login.saga';
import { getAllMaterdataSaga } from './masterData.saga';
import { MediaSaga } from './media.saga';
import { getAllProjectMaterdataSaga } from './project.saga';
import { UserSaga } from './user.saga';
import { commonSaga } from './Common.Saga';
import { FrontAppSaga } from './frontApp.saga';
import { RatingsSaga } from './Rating.saga';
import { TradingRatingsSaga } from './Trading.Rating.saga';
import { actionHighlitersSaga } from './action.Highlighters.saga';
import { chatMessageSaga } from './chat.saga';

export default function* rootSaga() {
  yield all([
    commonSaga(),
    UserSaga(),
    loginSaga(),
    getAllProjectMaterdataSaga(),
    getAllMaterdataSaga(),
    MediaSaga(),
    FrontAppSaga(),
    RatingsSaga(),
    TradingRatingsSaga(),
    actionHighlitersSaga(),
    chatMessageSaga()
  ]);
}

import { combineReducers } from 'redux';
import CommonReducer from './Common.Reducer';
import RatingReducer from './Rating.Reducer';
import FrontAppReducer from './frontApp.Reducer';
import LoginReducer from './login.reducer';
import masterDataReducer from './masterData.reducer';
import MediaReducer from './media.reducer';
import PaginationReducer from './pagination.reducer';
import ProjectMasterDataReducer from './project.reducer';
import UserReducer from './user.reducer';
import TradingRatingReducer from './Trading.Rating.Reducer';
import ActionHighlight from './action.highliter.Reducer';
import ChatMessageReducer from './chatReducer';

export const rootReducer = combineReducers<any>({
    user: UserReducer,
    auth: LoginReducer,
    project: ProjectMasterDataReducer,
    pagination: PaginationReducer,
    masterData: masterDataReducer,
    media: MediaReducer,
    commonActions: CommonReducer,
    frontApp: FrontAppReducer,
    rating: RatingReducer,
    trading: TradingRatingReducer,
    actionHighlight: ActionHighlight,
    chats: ChatMessageReducer
});

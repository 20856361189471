import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../@types';
import highlighter from "../../assets/images/Icon/highlightericon.svg";
import info from "../../assets/images/Icon/info.svg";
export const HighlighterPopup: React.FC = ({ }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const { highlighters } = useSelector((state: RootState) => state.project);
    // console.log("highlighters---", highlighters);

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
            setIsPopoverOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={popoverRef} className='d-flex g-4 position-relative ' onClick={togglePopover}>
                <img src={highlighter} />
                <p className='mt-3 high ' style={{ cursor: 'pointer' }}>&nbsp;&nbsp;Highlighters&nbsp;&nbsp;</p>
                <img src={info} />
                {isPopoverOpen && (
                    <div className="highlighter-popup">
                        {highlighters.map((highlight, index) => {
                            return (
                                <>
                                    <p style={{ color: highlight?.color }}><img src={highlight?.icon} /> &nbsp;{highlight?.name}</p>
                                </>
                            )
                        })}
                    </div>
                )}
            </div>

        </>
    );
};

import { removeDuplicates } from "../../utils/GenerateQueryString";
import { CHAT_MESSAGE_FAIL, CREATE_CHAT, CREATE_CHAT_SUCCESS, GET_ALL_CHATS, GET_ALL_CHATS_SUCCESS, GET_ALL_GENERAL_CHATS, GET_ALL_GENERAL_CHATS_SUCCESS, GET_TRADE_FOR_CHATS, GET_TRADE_FOR_CHATS_SUCCESS } from "../storeTypes";

const initialState: any = {
  loader: false,
  chat: null,
  chats: null,
  error: null,
  tradeTask: null,
  taskCreateLoader: false
};
const ChatMessageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CREATE_CHAT: {
      return {
        ...state,
        taskCreateLoader: true,
      };
    }
    case CREATE_CHAT_SUCCESS: {
      return {
        ...state,
        taskCreateLoader: false,
        chat: action.payload
      };
    }
    case GET_ALL_CHATS: {
      return {
        ...state,
        loader: action.needLoader,
      };
    }
    case GET_ALL_CHATS_SUCCESS: {
      return {
        ...state,
        loader: false,
        chats: action.payload,
      };
    }

    case GET_ALL_GENERAL_CHATS: {
      return {
        ...state,
        chats: null,
        loader: true,
      };
    }

    case GET_ALL_GENERAL_CHATS_SUCCESS: {
      return {
        ...state,
        chats: null,
        loader: false,
      };
    }

    case GET_TRADE_FOR_CHATS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_TRADE_FOR_CHATS_SUCCESS: {
      const uniqueArray = removeDuplicates(action?.payload);
      return {
        ...state,
        loader: false,
        tradeTask: uniqueArray
      };
    }
    case CHAT_MESSAGE_FAIL: {
      return {
        ...state,
        loader: false,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default ChatMessageReducer;
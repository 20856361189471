import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Badge, Button, Divider, Flex, Text } from 'theme-ui';
import queryString from 'query-string';
import { FolderOption, RootState } from '../../../../@types';
import { GetProjectList, UpdateProjectRow } from '../../../store/actions/project.action';
import { capitalize, getApiEndPoint, msToTime } from '../../../utils/GenerateQueryString';
import DataTable from '../../Tabels/Tabel';
import { ProjectViewHeader } from '../../Text/ProjectViewHeader';
import Export from "../../assets/images/Icon/Export.svg";
import CommentsDialogBox from './CommentSection';
import { ProjectName } from './ProjectName';
import editIcon from "../../assets/images/Icon/highlightericon.svg";
import { useQuery } from '@tanstack/react-query';
import { baseUrl } from '../../../utils/global';
import { statusColors } from '../../../utils/constent';
import GeneralTimerStart from '../TaskView/GeneralTimerModal/GeneralTimerStart';
import clockIcon from "../../../Components/assets/images/Icon/Clock.svg";
import { getUserbyToken } from '../../../store/actions/login.action';

const Index: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });

    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    const { projectList, loader, highlighters, timeTracking } = useSelector((state: any) => state.project);
    // const { paginations } = useSelector((state: any) => state.pagination);
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const { auther } = useSelector((state: RootState) => state.auth);

    const location = useLocation();
    const parsedQuery = queryString.parse(location.search);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const authToken = localStorage.getItem("token")

    const toggleModal = () => {
        setModalOpen(!modalOpen);
        dispatch(getUserbyToken(authToken))

    };
    const { data: eventSource } = useQuery({
        queryKey: ['projectSSE'],
        queryFn: () => {
            const eventSource = new EventSource(`${baseUrl}sse/project`);

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    dispatch(UpdateProjectRow(data))
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };

            eventSource.onerror = (error) => {
                console.error('SSE Error:', error);
                eventSource.close();
            };
            return eventSource;
        },
        refetchInterval: 60000
    })
    useEffect(() => {
        return () => {
            eventSource?.close();
        };
    }, [])
    const generateFolderOptions = (): FolderOption[] => {
        const startMonth = 1609;
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        // console.log('Current Year:', currentYear);
        // console.log('Current Month:', currentMonth);
        const folderOptions: FolderOption[] = [];
        for (let year = 16; year <= currentYear; year++) {
            const start = (year === 16) ? startMonth % 100 : 1;
            const end = (year === currentYear) ? currentMonth + 2 : 12;
            for (let month = start; month <= end; month++) {
                const formattedOption = `projects_${year.toString().padStart(2, '0')}${month.toString().padStart(2, '0')}`;
                const optionObject: FolderOption = { label: formattedOption, value: formattedOption };
                folderOptions.push(optionObject);
                if (year === currentYear && month === currentMonth) {
                    optionObject.defaultSelected = true;
                }
            }
        }
        folderOptions.sort((a, b) => a.label.localeCompare(b.label));
        return folderOptions;
    };

    const columns = useMemo<ColumnDef<any>[]>(() => [
        // {
        //     accessorKey: "id",
        //     id: "select",
        //     enableColumnFilter: false,
        //     header: ({ table }) => (
        //         <div className="d-flex justify-content-center">
        //             <IndeterminateCheckbox
        //                 {...{
        //                     checked: table.getIsAllRowsSelected(),
        //                     indeterminate: table.getIsSomeRowsSelected(),
        //                     onChange: table.getToggleAllRowsSelectedHandler(),
        //                 }}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }) => (
        //         <IndeterminateCheckbox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        // },
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <div className='d-flex p-1 text-center' style={{ justifyContent: 'space-between' }}>
                        <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row?.index + 1}</Text>
                        {row?.original?.status === 'draft' ? <img src={editIcon} alt='' style={{ cursor: 'pointer' }} onClick={() => { navigate(`/create-project?step=2&id=${row?.original?._id}`) }} /> : ''}
                    </div >
                )
            }
        },
        {
            accessorKey: "name",
            header: 'Project name',
            cell: ({ row }) => {
                return (
                    // <Text onClick={(): void => {
                    //     setIsOpen(true);
                    //     setProjectId(row?.original?._id)
                    // }} style={{ textDecoration: 'none', color: '#0A0A0A', cursor: "pointer" , borderBottom:'2px solid green'}}>
                    //     {row?.original?.name || ''}
                    // </Text>
                    <ProjectName
                        original={row?.original}
                        projectId={row?.original?._id}
                        projectName={row?.original?.name || ''}
                    />
                )
            },
            filterType: "dropdown",
            options: (() => {
                if (parsedQuery?.status == "Delivered") {
                    return dataForTable?.data?.allDeliveredProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                } else if (parsedQuery?.status == "Ready To Deliver") {
                    return dataForTable?.data?.allRTDProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                } else if (parsedQuery?.status == "Draft") {
                    return dataForTable?.data?.allDraftProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                } else {
                    return dataForTable?.data?.allProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                }
            })()

        },
        {
            header: 'Project Manager',
            accessorKey: "projectManager",
            cell: ({ row }) => {
                return (
                    <Text >{row.original.projectManager?.FirstName} {row.original.projectManager?.LastName}</Text>
                )
            },
            filterType: 'dropdown',
            options: dataForTable?.data?.allProjectManagers?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val._id, label: `${val.FirstName} ${val.LastName}` }))
        },
        {
            header: 'Project Worker',
            accessorKey: "projectWorker",
            cell: ({ row }) => {
                return (
                    <Text >{row.original.projectWorker?.FirstName} {row.original.projectWorker?.LastName}</Text>
                )
            },
            filterType: 'multi-select-dropdown',
            sticky: 'right',
            options: dataForTable?.data?.allProjectWorkers?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val._id, label: `${val.FirstName} ${val.LastName}` }))
        },
        {
            header: 'Architect',
            accessorKey: "architect",
            cell: ({ row }) => {
                return (
                    <Text >{row.original.architect?.name}</Text>
                )
            },
            filterType: 'dropdown',
            options: dataForTable?.data?.allArchitects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }))
        },
        {
            header: 'Project Number',
            accessorKey: "folderNumber",
            cell: info => info.getValue(),
            filterType: 'dropdown',
            options: generateFolderOptions(),
        },
        {
            header: 'Stages Name list',
            accessorKey: 'stagesNameList',
            cell: ({ row }) => {
                const stages = row.original.stagesNameList;
                if (stages && stages.length > 0) {
                    return (
                        <div>
                            {stages?.map((val: { color: string, name: string, _id: string }) => (
                                // <CommonSpan color={val.color} name={val.name} />
                                <Badge sx={{ color: `${val.color}`, border: `1px solid ${val.color}`, borderRadius: 6, backgroundColor: "transparent", margin: "5px" }} key={val._id}>{val.name}</Badge>
                            ))}
                        </div>
                    );
                } else {
                    return null;
                }
            },
            filterType: 'multi-select-dropdown',
            // filterType: "none",
            sticky: 'right',
            options: dataForTable?.data?.allStages?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }))
        },
        {
            header: 'Highlighters',
            accessorKey: "highlighters",
            // filterType: "textbox",
            cell: ({ row }) => {
                const stages = row.original.highlighters;
                if (stages && stages.length > 0) {
                    return (
                        <Flex sx={{ justifyContent: "center" }}>
                            {stages?.map((val: { color: string, title: string, _id: string, name: string }) => (
                                // <CommonSpan color={val.color} name={val.name} />
                                <Badge title={val.name} sx={{ color: `${val.color}`, border: `1px solid ${val.color}`, borderRadius: 6, backgroundColor: "transparent", margin: "5px" }} key={val._id}>{val.title}</Badge>
                            ))}
                        </Flex>
                    );
                } else {
                    return null;
                }
            },
            filterType: 'multi-select-dropdown',
            options: highlighters?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }))
        },
        {
            header: 'No. of Tasks',
            accessorKey: "numberOfTask",
            cell: info => info.getValue(),
            filterType: "none"
        },
        {
            header: 'Spent Time',
            accessorKey: "timeSpend",
            id: 'timeSpend',
            cell: ({ row }) => {
                const spentTime = row.original.timeSpend;
                const formattedSpendTime = spentTime ? `${msToTime(spentTime).hours}:${msToTime(spentTime).minutes}` : "00:00";
                return (
                    <Text >
                        {formattedSpendTime}
                    </Text>
                );
            },

        },
        // {
        //     header: 'Estimated Time',
        //     accessorKey: "estimatedTime",
        //     cell: info => info.getValue(),
        // },
        {
            header: 'Quote Number',
            accessorKey: "quoteNumber",
            cell: info => info.getValue(),
            filterType: "number"
        },
        {
            header: 'Status',
            accessorKey: "status",
            cell: ({ row }) => {
                type StatusType = keyof typeof statusColors;

                const status = row.original.status as StatusType;

                const color = statusColors[status] || '#000';

                return (
                    <Badge
                        title={status}
                        sx={{
                            color: `white`,
                            border: `1px solid ${color}`,
                            borderRadius: 6,
                            backgroundColor: `${color}`,
                            margin: "5px"
                        }}
                    >
                        {status}
                    </Badge>
                );
            },
            filterType: 'dropdown',
            options: dataForTable?.data?.status?.map((val: { value: string }) => ({ value: val.value, label: capitalize(val.value) }))
        },
        {
            header: 'Comments',
            cell: ({ row }) => {
                return (
                    <CommentsDialogBox id={row?.original?._id} comments={row?.original} level="project" />
                );

            },
        }
    ], [projectList, dataForTable]);
    const dispatch = useDispatch()
    // console.log(dataForTable, "check173");
    useEffect(() => {
        const parsedQuery = queryString.parse(location.search);
        let queryArray: any = [];
        let queryData = Object.keys(parsedQuery)?.map((val) => {
            queryArray.push([val, parsedQuery[val]])
        })
        // console.log(queryArray, "check241");
    }, [])
    useEffect(() => {
        let apiEndPoint = getApiEndPoint({ limit, currentPage }, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType, header: val.header })))
        dispatch(GetProjectList(apiEndPoint))
    }, [location?.search, limit, currentPage]);

    return (
        <>
            <div className='main-content'>
                <ProjectViewHeader IsBtnShow={true} Title='Project view' Count={projectList?.count} src={Export} BtnTxt='Export' url={'/project-view'} />
                <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
                <DataTable
                    loader={loader}
                    columns={columns}
                    data={projectList?.data || []}
                    TotalLength={projectList?.count || 15}
                    perPageCount={limit || 15}
                    currentPage={currentPage || 1}
                    onPerPageChange={(itemsPerPage) => {
                        const parsedQuery = queryString.parse(location.search);
                        if (searchParams?.size > 2) {

                            setSearchParams({
                                ...parsedQuery,
                                limit: itemsPerPage.toString(),
                            });
                        } else if (projectList?.count < itemsPerPage) {
                            setSearchParams({
                                currentPage: '1',
                                limit: itemsPerPage.toString(),
                            });
                        }
                        else {
                            setSearchParams({
                                ...parsedQuery,
                                currentPage: currentPage.toString(),
                                limit: itemsPerPage.toString(),
                            });
                        }

                    }}
                    onPageChange={(value) => {
                        const parsedQuery = queryString.parse(location.search);
                        if (searchParams?.size > 2) {

                            setSearchParams({
                                ...parsedQuery,
                                currentPage: value.toString(),
                            });
                        } else {
                            setSearchParams({
                                ...parsedQuery,
                                currentPage: value.toString(),
                                limit: limit.toString(),
                            });
                        }

                    }} />
                {!timeTracking || Object?.keys(timeTracking).length === 0 ? (
                    <Button
                        sx={{
                            position: 'fixed',
                            bottom: 20,
                            right: 20,
                            bg: 'primary',
                            color: 'white',
                            borderRadius: '50%',
                            width: 60,
                            height: 60,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 24,
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                            cursor: 'pointer',
                            zIndex: 1000,
                            '&:hover': {
                                bg: 'secondary',
                            }
                        }}
                        onClick={toggleModal}
                    >
                        <img
                            src={clockIcon}
                            alt="clock-icon"
                            style={{ width: '40px', height: '40px' }}
                        />
                    </Button>
                ) : null}
                <GeneralTimerStart isOpen={modalOpen} toggle={toggleModal} autherId={auther?._id} />
            </div>
            {/* {isOpen && <EditModal isOpen={isOpen} projectId={projectId} onModelClose={() => {
                setIsOpen(false)
                navigate("/project-view")
                setProjectId("")
            }} />} */}
            {/* {isOpen && <EditTaskView taskId={taskId} onModelClose={() => {
                setIsOpen(false)
                navigate("/task-view")
                setTaskId("")
            }} */}
        </>
    )
};

export default Index;

import { MEDIA_UPLOAD, MEDIA_UPLOAD_FAIL, MEDIA_UPLOAD_SUCCESS } from "../storeTypes";

const initialState: any = {
    loader: false,
    uploadedMedia: null,
};

const MediaReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MEDIA_UPLOAD:
            return {
                ...state,
                loader: true,
            };
        case MEDIA_UPLOAD_SUCCESS:
            return {
                ...state,
                loader: false,
                uploadedMedia: action.payload
            };
        case MEDIA_UPLOAD_FAIL:
            return {
                ...state,
                loader: false,
            };
        default:
            return state;
    }
};

export default MediaReducer;

import { useEffect, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { Box } from 'theme-ui';
import { useDispatch } from 'react-redux';
import { updateActionStatusToAny } from '../../store/actions/project.action';
import { DeletePopModule } from '../DeletePopModule/DeletePopModule';
import theme from '../../theme';

export default function UpdateStatusToAny({ actionLists, levelStatus, updateStatusId, keyName }) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
    const [stautsName, setStautsName] = useState(false);
    const [target, setTarget] = useState(null);
    const dispatch = useDispatch();
    const popoverRef = useRef(null);

    const togglePopover = (event) => {
        setTarget(event.target);
        setIsPopoverOpen(!isPopoverOpen);
    };
    const handleClickOutside = (event) => {

        if (!event.target.closest('.popover-body')) {

            if (!event.target.closest('.toggle-btn')) {
                setIsPopoverOpen(false);
            }
        }

    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const updateActionStatus = () => {
        const payload = {
            status: stautsName,
            level: levelStatus,
            [keyName]: updateStatusId
        }
        dispatch(updateActionStatusToAny(payload));
    }
    return (
        <div ref={popoverRef} className='mt-3'>
            <Overlay
                show={isPopoverOpen}
                target={target}
                placement="left"
            >
                <Popover style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }} id={`popover-positioned`} >
                    <Popover.Body className="w-auto">
                        {actionLists?.map((statusName, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        color: `${theme.colors.black}`, padding: "12px", borderRadius: "8px", cursor: 'pointer', '&:hover': {
                                            backgroundColor: `${theme?.colors?.lightbackground}`
                                        }
                                    }}
                                    onClick={() => {
                                        setStautsName(statusName);
                                        setIsDeleteModelOpen(true);
                                        setIsPopoverOpen(!isPopoverOpen);
                                    }}
                                >
                                    {statusName}
                                </Box>
                            );
                        })}
                    </Popover.Body>
                </Popover>
            </Overlay >
            <button className='btn toggle-btn' onClick={togglePopover}>
                {isPopoverOpen ? <svg width="45" height="45" viewBox="0 0 30 30" fill="red" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.8215 14.9996L10.286 11.464L11.4645 10.2855L15 13.8211L18.5355 10.2855L19.714 11.464L16.1785 14.9996L19.714 18.5351L18.5355 19.7136L15 16.1781L11.4645 19.7136L10.286 18.5351L13.8215 14.9996Z" fill="red" />
                </svg>
                    : <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 20" fill="red">
                        <path d="M9.16699 10.8346H4.16699V9.16797H9.16699V4.16797H10.8337V9.16797H15.8337V10.8346H10.8337V15.8346H9.16699V10.8346Z" fill="red" />
                    </svg>
                }
            </button>
            <Box sx={{ color: `${theme.colors.red}` }}> Update Status </Box>
            <DeletePopModule setIsDeleteModelOpen={setIsDeleteModelOpen} updatedDataFun={updateActionStatus} isDeleteModelOpen={isDeleteModelOpen} message={"Are you sure to change status"} />
        </div>
    )
}

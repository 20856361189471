import { DELETE_MULTIPLE_TASK, DELETE_MULTIPLE_TASK_SUCCESS, DELETE_STAGE, DELETE_STAGE_SUCCESS, DELETE_TASK, DELETE_TASK_SUCCESS, GET_ALL_ARCHITECT_MASTERDATA, GET_ALL_ARCHITECT_MASTERDATA_SUCCESS, GET_ALL_BCPC_HISTORY, GET_ALL_BCPC_HISTORY_SUCCESS, GET_ALL_CHECKLIST_TEMPLATES, GET_ALL_CHECKLIST_TEMPLATES_BY_ID, GET_ALL_CHECKLIST_TEMPLATES_BY_ID_SUCCESS, GET_ALL_CHECKLIST_TEMPLATES_SUCCESS, GET_ALL_DELIVERIES_PROJECTVIEW, GET_ALL_DELIVERIES_PROJECTVIEW_SUCCESS, GET_ALL_DELIVERIES_STAGEVIEW, GET_ALL_DELIVERIES_STAGEVIEW_SUCCESS, GET_ALL_MASTERDATA_FOR_TABLE, GET_ALL_MASTERDATA_FOR_TABLE_SUCCESS, GET_ALL_MASTER_DATA, GET_ALL_MASTER_DATA_SUCCESS, GET_ALL_PROJECT_TYPE_MASTERDATA, GET_ALL_PROJECT_TYPE_MASTERDATA_SUCCESS, GET_ALL_STAGES_MASTERDATA, GET_ALL_STAGES_MASTERDATA_SUCCESS, GET_ALL_TASK_FIELDS_MASTERDATA, GET_ALL_TASK_FIELDS_MASTERDATA_SUCCESS, GET_ALL_TASK_MAPPING_MASTERDATA, GET_ALL_TASK_MAPPING_MASTERDATA_SUCCESS, GET_ALL_TASK_MASTERDATA, GET_ALL_TASK_MASTERDATA_SUCCESS, GET_ALL_TASK_TYPE_MASTERDATA, GET_ALL_TASK_TYPE_MASTERDATA_SUCCESS, GET_ARCHITECT_BY_ID, GET_ARCHITECT_BY_ID_SUCCESS, GET_RATING_CRITERIA, GET_RATING_CRITERIA_BY_ID, GET_RATING_CRITERIA_BY_ID_SUCCESS, GET_RATING_CRITERIA_SUCCESS, GET_STAGE_BY_ID, GET_STAGE_BY_ID_SUCCESS, GET_TASK_FIELDS_BY_ID, GET_TASK_FIELDS_BY_ID_SUCCESS, GET_TASK_MAPPING_BY_ID, GET_TASK_MAPPING_BY_ID_SUCCESS, GET_TASK_TYPE_BY_ID, GET_TASK_TYPE_BY_ID_SUCCESS, GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION, GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION_SUCCESS, GET_UPLOAD_TYPE, GET_UPLOAD_TYPE_BY_ID, GET_UPLOAD_TYPE_BY_ID_SUCCESS, GET_UPLOAD_TYPE_NO_PAGINATION, GET_UPLOAD_TYPE_NO_PAGINATION_SUCCESS, GET_UPLOAD_TYPE_SUCCESS } from "../storeTypes";

const initialState: any = {
    loader: false,
    architect: {},
    stagelist: {},
    projectType: {},
    tasklist: {},
    tasksowMapping: {},
    taskField: {},
    taskType: {},
    checklists: {},
    masterData: {},
    individualArchitect: {},
    individualChecklist: {},
    uploadTypes: {},
    uploadCategory: {},
    individualUploadType: {},
    individualStage: {},
    individualTaskType: {},
    individualTaskSow: {},
    individualTaskField: {},
    taskTypeWithoutPaginatationData: {},
    dataForTable: {},
    ratingCriteria: null,
    individualRatingCriteria: {},
    deliveryListProject: null,
    deliveryListStage: null,
    bcpcHistory: {},
};
const masterDataReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ALL_MASTERDATA_FOR_TABLE:
            return {
                ...state,
                dataForTable: {},
                loader: true,
            };

        case GET_ALL_MASTERDATA_FOR_TABLE_SUCCESS:
            return {
                ...state,
                dataForTable: action.payload,
                loader: false
            };
        case GET_ALL_ARCHITECT_MASTERDATA:
            return {
                ...state,
                loader: true,
                individualArchitect: {}
            };

        case GET_ALL_ARCHITECT_MASTERDATA_SUCCESS:
            return {
                ...state,
                loader: false,
                architect: action.payload,
            };
        case GET_ARCHITECT_BY_ID:
            return {
                ...state,
                loader: true,
                architect: {},
            };
        case GET_ARCHITECT_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                individualArchitect: action.payload,
            };
        case GET_ALL_CHECKLIST_TEMPLATES:
            return {
                ...state,
                loader: true,
                checklists: {}
            };

        case GET_ALL_CHECKLIST_TEMPLATES_SUCCESS:
            return {
                ...state,
                loader: false,
                checklists: action.payload,
            };
        case GET_ALL_CHECKLIST_TEMPLATES_BY_ID:
            return {
                ...state,
                loader: true,
                individualChecklist: {},
            };
        case GET_ALL_CHECKLIST_TEMPLATES_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                individualChecklist: action.payload,
            };
        case GET_STAGE_BY_ID:
            return {
                ...state,
                loader: true,
                stagelist: {},
            };
        case GET_STAGE_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                individualStage: action.payload,
            };
        case GET_ALL_STAGES_MASTERDATA:
            return {
                ...state,
                loader: true,
                individualStage: {}
            }
        case GET_ALL_STAGES_MASTERDATA_SUCCESS:
            return {
                ...state,
                loader: false,
                stagelist: action.payload
            }

        case GET_ALL_PROJECT_TYPE_MASTERDATA:
            return {
                ...state,
                loader: true,
            }
        case GET_ALL_PROJECT_TYPE_MASTERDATA_SUCCESS:
            return {
                ...state,
                loader: false,
                projectType: action.payload
            }
        case GET_ALL_DELIVERIES_PROJECTVIEW:
            return {
                ...state,
                loader: true,
            }
        case GET_ALL_DELIVERIES_PROJECTVIEW_SUCCESS:
            return {
                ...state,
                loader: false,
                deliveryListProject: action.payload
            }
        case GET_ALL_DELIVERIES_STAGEVIEW:
            return {
                ...state,
                loader: true,
            }
        case GET_ALL_DELIVERIES_STAGEVIEW_SUCCESS:
            return {
                ...state,
                loader: false,
                deliveryListStage: action.payload
            }
        case GET_ALL_BCPC_HISTORY:
            return {
                ...state,
                loader: true,
            }
        case GET_ALL_BCPC_HISTORY_SUCCESS:
            return {
                ...state,
                loader: false,
                bcpcHistory: action.payload
            }
        case GET_ALL_TASK_MASTERDATA:
            return {
                ...state,
                loader: true,
            }
        case GET_ALL_TASK_MASTERDATA_SUCCESS:
            return {
                ...state,
                loader: false,
                tasklist: action.payload
            }

        case GET_ALL_TASK_MAPPING_MASTERDATA:
            return {
                ...state,
                loader: true,
                individualTaskSow: {}
            }
        case GET_ALL_TASK_MAPPING_MASTERDATA_SUCCESS:
            return {
                ...state,
                loader: false,
                tasksowMapping: action.payload
            }
        case GET_TASK_MAPPING_BY_ID:
            return {
                ...state,
                loader: true,
                tasksowMapping: {}
            }
        case GET_TASK_MAPPING_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                individualTaskSow: action.payload
            }

        case GET_ALL_TASK_FIELDS_MASTERDATA:
            return {
                ...state,
                loader: true,
                individualTaskField: {}
            }
        case GET_ALL_TASK_FIELDS_MASTERDATA_SUCCESS:
            return {
                ...state,
                loader: false,
                taskField: action.payload
            }
        case GET_TASK_FIELDS_BY_ID:
            return {
                ...state,
                loader: true,
                taskField: {}
            }
        case GET_TASK_FIELDS_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                individualTaskField: action.payload
            }

        case GET_ALL_TASK_TYPE_MASTERDATA:
            return {
                ...state,
                loader: true,
                individualTaskType: {}
            }
        case GET_ALL_TASK_TYPE_MASTERDATA_SUCCESS:
            return {
                ...state,
                loader: false,
                taskType: action.payload
            }
        case GET_TASK_TYPE_BY_ID:
            return {
                ...state,
                loader: true,
                taskType: {}
            }
        case GET_TASK_TYPE_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                individualTaskType: action.payload
            }
        case DELETE_TASK:
            return {
                ...state,
                loader: true,

            }
        case DELETE_TASK_SUCCESS:
            return {
                ...state,
                loader: false,

            }
        case DELETE_MULTIPLE_TASK:
            return {
                ...state,
                loader: true,

            }
        case DELETE_MULTIPLE_TASK_SUCCESS:
            return {
                ...state,
                loader: false,

            }
        case DELETE_STAGE:
            return {
                ...state,
                loader: true,

            }
        case DELETE_STAGE_SUCCESS:
            return {
                ...state,
                loader: false,

            }
        case GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION:
            return {
                ...state,
                loader: true,
                taskTypeWithoutPaginatationData: {}
            }
        case GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION_SUCCESS:
            return {
                ...state,
                loader: false,
                taskTypeWithoutPaginatationData: action.payload
            }
        case GET_ALL_MASTER_DATA:
            return {
                ...state,
                loader: true,
            }
        case GET_ALL_MASTER_DATA_SUCCESS:
            return {
                ...state,
                loader: false,
                masterData: action.payload
            }
        case GET_RATING_CRITERIA:
            return {
                ...state,
                loader: true,
            }
        case GET_RATING_CRITERIA_SUCCESS:
            return {
                ...state,
                loader: false,
                ratingCriteria: action.payload
            }
        case GET_RATING_CRITERIA_BY_ID:
            return {
                ...state,
                loader: true,
            }
        case GET_RATING_CRITERIA_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                individualRatingCriteria: action.payload
            }
        case GET_UPLOAD_TYPE:
            return {
                ...state,
                loader: true,
            }
        case GET_UPLOAD_TYPE_SUCCESS:
            return {
                ...state,
                loader: false,
                uploadTypes: action.payload
            }
        case GET_UPLOAD_TYPE_NO_PAGINATION:
            return {
                ...state,
                loader: true,
            }
        case GET_UPLOAD_TYPE_NO_PAGINATION_SUCCESS:
            return {
                ...state,
                loader: false,
                uploadCategory: action.payload
            }
        case GET_UPLOAD_TYPE_BY_ID:
            return {
                ...state,
                loader: true,
            }
        case GET_UPLOAD_TYPE_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                individualUploadType: action.payload
            }
        default:
            return state;
    }
};

export default masterDataReducer;

import { DELETE_MEDIA_UPLOAD, MEDIA_UPLOAD, MEDIA_UPLOAD_FAIL, MEDIA_UPLOAD_SUCCESS } from "../storeTypes"

export const mediaUpload = (payload) => {
    return {
        type: MEDIA_UPLOAD,
        payload: payload,
        // callBack
    }
}
export const mediaUploadSuccess = (payload) => {
    return {
        type: MEDIA_UPLOAD_SUCCESS,
        payload: payload,
    }
}
export const mediaUploadDelete = (payload, id) => {
    return {
        type: DELETE_MEDIA_UPLOAD,
        payload: payload,
        id
    }
}

export const mediaUploadFail = (payload) => {
    return {
        type: MEDIA_UPLOAD_FAIL,
        payload: payload,
    }
}
// validationUtils.ts
import { withFormik } from 'formik';
import * as Yup from 'yup';

// Define Yup validation schema
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    color: Yup.string().required('Color is required'),
});

// Define the enhancer with Formik and Yup  
export const withFormikValidation = withFormik({
    mapPropsToValues: () => ({ name: '', color: '#000000' }),
    validationSchema,
    validateOnMount: true,
    handleSubmit: () => { },
});

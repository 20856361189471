import { ErrorMessage, Field, FormikProps } from 'formik';
import { Button, Label } from 'theme-ui';
import theme from '../../../../theme';
import { withFormikValidation } from './enhancer/AddEditTaskFieldEnhancer';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../../../@types';
import { ActionCreateByUrl, ActionUpdateById } from '../../../../store/actions/CommonAction';
import { getTaskFieldSowDataById, getTaskTypeDataWithoutPaginatation } from '../../../../store/actions/masterData.action';
import FormSelect from '../../../forms-component/globalSelect';


const AddEditTaskField: React.FC<FormikProps<any>> = ({
    values,
    errors,
    touched,
    handleSubmit,
    setValues,
    validateForm,
    isValid,
    isSubmitting,
    handleBlur,
    setFieldValue
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { taskFieldId } = useParams();
    const { individualTaskField, taskTypeWithoutPaginatationData } = useSelector((state: RootState) => state?.masterData);
    const [isNeedToValidate, setIsNeedToValidate] = useState<boolean>(false);
    const [taskNameOptions, setTaskNameOptions] = useState<{ value: string, label: string }[]>([])
    const [taskTypeOptions, setTaskTypeOptions] = useState<{ value: string, label: string }[]>([
        {
            value: "string",
            label: "String"
        },
        {
            value: "number",
            label: "Number"
        },
        {
            value: "select",
            label: "Select"
        },
        {
            value: "boolean",
            label: "Boolean"
        },
    ])
    // console.log(individualTaskField, "check31");

    useEffect((): void => {
        dispatch(getTaskTypeDataWithoutPaginatation())
        if (taskFieldId) {
            dispatch(getTaskFieldSowDataById({ id: taskFieldId }))
        }
    }, [taskFieldId])
    useEffect((): void => {
        // console.log(taskTypeWithoutPaginatationData, "check39");
        if (taskTypeWithoutPaginatationData?.status) {
            let optionValue = taskTypeWithoutPaginatationData?.data?.map((val: any) => { return { value: val._id, label: val.name } })
            setTaskNameOptions(optionValue);
        }
    }, [taskTypeWithoutPaginatationData])
    useEffect(() => {
        if (taskFieldId && individualTaskField?.status) {
            setValues({
                ...values,
                taskName: individualTaskField?.data?.taskName,
                type: individualTaskField?.data?.type,
                fieldName: individualTaskField?.data?.fieldName,
            })
            setIsNeedToValidate(true);
        }
    }, [individualTaskField?.data])
    useEffect(() => {
        if (isNeedToValidate) {
            validateForm();
        }
    }, [isNeedToValidate])
    // console.log(taskTypeOptions, "check63");
    const handleArchitectData = async (): Promise<void> => {
        handleSubmit();
        if (isValid) {
            taskFieldId ?
                dispatch(ActionUpdateById({ Query: `task-field-sow/edit-task-field-sow/${taskFieldId}`, obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/task-field")
                    }
                }))
                :
                dispatch(ActionCreateByUrl({ Query: "task-field-sow/create-task-field-sow", obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/task-field")
                    }
                }));
        }
    }
    // console.log(values, "check83");

    return (
        <div className="main-content">

            <div className='container-fluid'>
                <h1>{taskFieldId ? "Edit" : "Add"} Task Fields Sow</h1>
                <div className="row">
                    {/* <div className="col-md-6 mt-4">
                    <Label htmlFor="taskName" className={`form-label mt-1 ${errors.taskName && touched.taskName ? 'is-invalid' : ''}`}>Task Name*</Label>
                    <Field type="text" id="taskName" name="taskName" className="form-control" placeholder="Task Name" />
                    <ErrorMessage name="taskName" component="div" className="text-danger" />
                </div> */}
                    <div className="col-md-4 mt-3">
                        <Label htmlFor="fieldName" className={`form-label mt-1 ${errors.fieldName && touched.fieldName ? 'is-invalid' : ''}`}>Field Name*</Label>
                        <Field type="text" id="fieldName" name="fieldName" className="form-control" placeholder="Field Name" />
                        <ErrorMessage name="fieldName" component="div" className="text-danger" />
                    </div>
                    <div className="col-md-4 mt-3">
                        <Label htmlFor="taskName" className={`form-label mt-1 ${errors.taskName && touched.taskName ? 'is-invalid' : ''}`}>Task Name*</Label>
                        <FormSelect
                            value={values?.taskName}
                            placeholder={"Select Task Name"}
                            options={taskNameOptions || []}
                            isMulti={false}
                            DefultSelect='Select Task Name'
                            touched={touched?.taskName}
                            errors={errors?.taskName}
                            onBlur={handleBlur}
                            Name='taskName'
                            onChange={(values: any) => {
                                // console.log(values, "check100")
                                setFieldValue("taskName", values?.value, true);
                                // setValues({ ...values, taskType: values?.value })
                            }}
                        />
                    </div>
                    <div className="col-md-4 mt-3">
                        <Label htmlFor="type" className={`form-label mt-1 ${errors.type && touched.type ? 'is-invalid' : ''}`}>Task Type*</Label>
                        <FormSelect
                            value={values?.type}
                            placeholder={"Select Task Type"}
                            options={taskTypeOptions || []}
                            isMulti={false}
                            DefultSelect='Select Task Type'
                            touched={touched?.type}
                            errors={errors?.type}
                            onBlur={handleBlur}
                            Name='type'
                            onChange={(values: any) => {
                                // console.log(values, "check100")
                                setFieldValue("type", values?.value, true);
                                // setValues({ ...values, taskType: values?.value })
                            }}
                        />
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                    <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' onClick={handleArchitectData} disabled={isSubmitting}>Submit</Button></div>
                </div>
            </div>
        </div >
    );
}

export default withFormikValidation(AddEditTaskField);

import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import * as Yup from 'yup';
import { getAllUnAssignedTeams, getAllUserWithoutPagination, getSubTeambyId, getTeambyId } from '../../../store/actions/user.action';
import { ActionCreateByUrl, ActionUpdateById } from '../../../store/actions/CommonAction';
import { Button, Divider, Text } from 'theme-ui';
import { Inputform } from '../../forms-component/InputForm';
import FormSelect from '../../forms-component/globalSelect';
import { MultiSelect } from 'react-multi-select-component';
import theme from '../../../theme';
import DataTable from '../../Tabels/Tabel';
import { ColumnDef } from '@tanstack/react-table';
import TableActions from '../Roles/TableActions';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface Option {
    label: string;
    value: string;
}

const AddEditTeams = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { teamId } = useParams();
    const location = useLocation();
    const { userData, teamById, subTeams, unAssignedTeams } = useSelector((state: any) => state?.user);
    const [isCreating, setIsCreating] = useState(!teamId);
    const [updatedFields, setUpdatedFields] = useState({})
    const [isCreatingSubTeam, setIsCreatingSubTeam] = useState(false);
    const auther = useSelector((state: any) => state.auth);
    const isAllowedReport = !auther?.Role?.some(
        (r: any) => r?.RoleName === "Super Admin" || r?.RoleName === "Region Admin")
    const [refresh, setRefresh] = useState<boolean>(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [newTeamName, setNewTeamName] = useState('');
    const [teamsId, setTeamsId] = useState(null);
    console.log('chk40', subTeams);

    useEffect(() => {
        dispatch(getAllUserWithoutPagination());
        if (teamId && !isCreatingSubTeam) {
            setIsCreating(false);
            dispatch(getTeambyId(teamId));
            // dispatch(getSubTeambyId(teamId));
        } else {
            setIsCreating(true);
            formik.resetForm({
                values: {
                    teamName: '',
                    teamDescription: '',
                    teamLeader: '',
                    teamMembers: [],
                    parentTeam: null,
                }
            });
        }
    }, [teamId, dispatch, isCreatingSubTeam]);
    useEffect(() => {

        if (teamId) {
            dispatch(getSubTeambyId(teamId))
            dispatch(getAllUnAssignedTeams())
        }
    }, [teamId, dispatch])
    const handleTeamNameChange = (selectedTeam: any) => {
        setTeamsId(selectedTeam?.value);
        setNewTeamName(selectedTeam?.label)
        setShowConfirmation(true);
    };
    const confirmAddTeam = () => {
        const payload = {
            parentTeam: teamId
        }

        dispatch(ActionUpdateById({ Query: `teams/editTeam/${teamsId}`, obj: payload }, (res: any) => {
            if (res.status) {
                setShowConfirmation(false);
                setNewTeamName('');
                dispatch(getSubTeambyId(teamId))
                dispatch(getAllUnAssignedTeams())

            }
        }));

    };
    const Users = userData?.data;
    const userOptions: Option[] = Users?.map((obj: any) => ({
        label: `${obj.FirstName} ${obj.LastName}`,
        value: obj?._id,
    }));
    const teamOptions: Option[] = unAssignedTeams?.data?.map((obj: any) => ({
        label: obj?.teamName,
        value: obj?._id,
    }));
    const filteredTeamOptions: Option[] = teamOptions?.filter(
        (option: any) => option.value !== teamId || teamById?.parentTeam?._id
    );
    const getUpdatedFields = (initialValues: any, currentValues: any) => {
        let updatedFields: any = {};

        const isObject = (obj: any) => obj && typeof obj === 'object' && !Array.isArray(obj);

        const compareValues = (initial: any, current: any) => {
            if (Array.isArray(initial) && Array.isArray(current)) {
                return JSON.stringify(initial) !== JSON.stringify(current);
            } else if (isObject(initial) && isObject(current)) {
                return JSON.stringify(initial) !== JSON.stringify(current);
            }
            return initial !== current;
        };

        for (const key in currentValues) {
            if (compareValues(initialValues[key], currentValues[key])) {
                updatedFields[key] = currentValues[key];
            }
        }

        return updatedFields;
    };

    const formik = useFormik({
        enableReinitialize: !isCreatingSubTeam,
        initialValues: {
            teamName: isCreating ? '' : (teamById?.teamName || ''),
            teamDescription: isCreating ? '' : (teamById?.teamDescription || ''),
            teamLeader: isCreating ? '' : (teamById?.teamLeader?._id || ''),
            teamMembers: isCreating ? [] : teamById?.teamMembers?.map((member: any) => ({
                userId: member?.userId?._id
            })) || [],
            parentTeam: teamById?.parentTeam?._id || null,
        },
        validationSchema: Yup.object({
            teamName: Yup.string().required('Team Name is required'),
            teamDescription: Yup.string().required('Team Description is required'),
            teamLeader: Yup.string().required('Team Leader is required'),
            teamMembers: Yup.array().of(
                Yup.object({
                    userId: Yup.string().required('User ID is required')
                })
            ).required('Team Members are required').min(1, 'At least one member must be selected'),
        }),
        onSubmit: (values) => {

            const handleResponse = (res: any) => {
                if (res.status) {
                    if (isCreatingSubTeam) {
                        navigate(-1); // Navigate back to the previous page
                    } else {
                        navigate("/teams"); // Navigate to /teams
                    }
                }
            };

            if (isCreatingSubTeam) {
                const payload = {
                    ...values,
                    parentTeam: teamId,
                };
                dispatch(ActionCreateByUrl({ Query: "/teams/createTeam", obj: payload }, handleResponse));
            } else if (teamId) {
                dispatch(ActionUpdateById({ Query: `/teams/editTeam/${teamId}`, obj: getUpdatedFields(teamById, values) }, handleResponse));
            } else {
                dispatch(ActionCreateByUrl({ Query: "/teams/createTeam", obj: values }, handleResponse));
            }
        }
    });


    const { handleSubmit, setFieldValue, values, handleBlur, touched, errors, isValid, isSubmitting } = formik;
    const handleCreateSubTeam = () => {
        setIsCreatingSubTeam(true);
        formik.resetForm({
            values: {
                teamName: '',
                teamDescription: '',
                teamLeader: '',
                teamMembers: [],
                parentTeam: null
            }
        });
    };
    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{row?.index + 1}</Text>
                )
            },
        },
        {
            accessorKey: "teamName",
            header: 'Team Name',
            cell: ({ row }) => {
                console.log('chk78', row.original);

                return (
                    <Text >{row?.original?.teamName || ''}</Text>
                )
            },
        },

        {
            header: 'Team Leader',
            accessorKey: "teamLeader",
            cell: ({ row }) => {
                const teamlead = row.original.teamLeader;
                return (
                    <Text >
                        {teamlead?.FirstName} {teamlead?.LastName}
                    </Text>
                );
            },
        },
        {
            header: 'Team Members',
            accessorKey: 'members',
            cell: ({ row }) => {
                const members = row.original.members
                if (members && members.length > 0) {
                    return members?.map((member: any) => member?.userId ? `${member?.userId?.FirstName} ${member?.userId?.LastName}` : "-").join(', ');
                }

            },
        },
        {
            header: 'Accessor',
            accessorKey: "Accessor",
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <TableActions row={row} editTo={`/edit-team/${row?.original?._id}`} viewTo="/my-team" showView={false} deleteMessage='Are you sure you want to delete this team?' deleteApi={`teams/deleteTeam/${row?.original?._id}`} toggleRefresh={() => setRefresh(true)} />
                )
            },
        }
    ], [subTeams]);
    return (
        <div className="main-content px-4">
            {!isCreatingSubTeam ? (
                <>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <h1>{teamId ? "Edit" : "Add"} Team</h1>
                            <div className="d-flex justify-content-between align-items-center">
                                {/* FormSelect in col-4 */}
                                {teamId && isAllowedReport && (
                                    <div className="col-4">
                                        <FormSelect
                                            label={"Add Team"}
                                            value={newTeamName}
                                            placeholder={"Select Team "}
                                            options={filteredTeamOptions || []}
                                            isMulti={false}
                                            DefultSelect='Select Team'
                                            onBlur={handleBlur}
                                            Name='teamName'
                                            onChange={handleTeamNameChange}
                                        />
                                        <Modal isOpen={showConfirmation} toggle={() => setShowConfirmation(false)} size="sm" centered>
                                            <ModalHeader toggle={() => setShowConfirmation(false)}>
                                                Confirm Adding Team
                                            </ModalHeader>
                                            <ModalBody>
                                                {`Are you sure you want to add the team "${newTeamName}"?`}
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button
                                                    variant="CancelButton"
                                                    sx={{ mr: 2, cursor: 'pointer' }}
                                                    onClick={() => setShowConfirmation(false)}
                                                >
                                                    No
                                                </Button>
                                                <Button
                                                    type="button"
                                                    variant="SaveButton"
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={confirmAddTeam}
                                                >
                                                    Add
                                                </Button>
                                            </ModalFooter>
                                        </Modal>
                                    </div>)}
                                {/* Button aligned to the far right */}
                                {teamId && isAllowedReport && (
                                    <div>
                                        <Button onClick={handleCreateSubTeam}>Create SubTeam</Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-4">
                                <Inputform
                                    label={"Team Name "}
                                    Placeholder={"Enter Team"}
                                    Name={"teamName"}
                                    type='text'
                                    Title_sx={{ color: "grey3" }}
                                    touched={touched?.teamName}
                                    asterisk={true}
                                    errors={errors?.teamName}
                                    values={values?.teamName || ''}
                                    Max_Length={10}
                                    onBlur={handleBlur}
                                    OnChange={(event: any) => {
                                        const newName = event.target.value;
                                        setFieldValue("teamName", newName, true);
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mt-4">
                                <Inputform
                                    label={"Team Description "}
                                    Placeholder={"Enter Description"}
                                    Name={"teamDescription"}
                                    type='text'
                                    Title_sx={{ color: "grey3" }}
                                    touched={touched?.teamDescription}
                                    asterisk={true}
                                    errors={errors?.teamDescription}
                                    values={values?.teamDescription || ''}
                                    Max_Length={10}
                                    onBlur={handleBlur}
                                    OnChange={(event: any) => {
                                        const newName = event.target.value;
                                        setFieldValue("teamDescription", newName, true);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mt-4">
                                <FormSelect
                                    label={"Team Leader"}
                                    value={values?.teamLeader}
                                    placeholder={"Select Team Leader"}
                                    options={userOptions || []}
                                    isMulti={false}
                                    DefultSelect='Select Team Leader'
                                    touched={touched?.teamLeader}
                                    errors={errors?.teamLeader}
                                    asterisk={true}
                                    onBlur={handleBlur}
                                    Name='teamLeader'
                                    onChange={(teamLeader: any) => {
                                        setFieldValue('teamLeader', teamLeader?.value, true);
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mt-4">
                                <Text variant="SelectLabel">Select Members<span className="mx-1 text-danger">*</span></Text>
                                <MultiSelect
                                    options={userOptions || []}
                                    value={values?.teamMembers?.map((value: any) =>
                                        userOptions?.find((option: Option) => option?.value === value?.userId) || { label: '', value: '' }
                                    )}
                                    onChange={(selected: Option[]) =>
                                        setFieldValue('teamMembers', selected?.map((option) => ({
                                            userId: option?.value
                                        })))
                                    }
                                    labelledBy="Select"
                                />
                                {errors?.teamMembers && touched?.teamMembers && typeof errors.teamMembers === 'string' && (
                                    <div className="text-danger">
                                        {errors?.teamMembers}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mt-5'>
                            <div className='col-md-4'><Button type="reset" sx={{ border: ` 1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button>
                            </div>
                            <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' >Submit</Button></div>
                        </div>
                        {teamId && subTeams?.length > 0 && (
                            <div>
                                <Divider />

                                <div >
                                    <Text variant="SelectLabel">All Sub-Teams</Text>
                                    <DataTable
                                        data={subTeams || []}
                                        columns={columns}
                                        onPageChange={() => { }}
                                        onPerPageChange={() => { }}
                                        perPageCount={10}
                                        TotalCount={subTeams?.length}
                                        TotalLength={subTeams?.length}
                                        loader={false}
                                        showNavigation={false}
                                    />
                                </div>

                            </div>
                        )}
                    </form>

                </>
            ) : (
                <form onSubmit={handleSubmit}>
                    <h1>Create SubTeam</h1>
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <Inputform
                                label={"SubTeam Name "}
                                Placeholder={"Enter SubTeam"}
                                Name={"teamName"}
                                type='text'
                                Title_sx={{ color: "grey3" }}
                                touched={touched?.teamName}
                                asterisk={true}
                                errors={errors?.teamName}
                                values={values?.teamName || ''}
                                Max_Length={10}
                                onBlur={handleBlur}
                                OnChange={(event: any) => {
                                    const newName = event.target.value;
                                    setFieldValue("teamName", newName, true);
                                }}
                            />
                        </div>
                        <div className="col-md-6 mt-4">
                            <Inputform
                                label={"SubTeam Description "}
                                Placeholder={"Enter Description"}
                                Name={"teamDescription"}
                                type='text'
                                Title_sx={{ color: "grey3" }}
                                touched={touched?.teamDescription}
                                asterisk={true}
                                errors={errors?.teamDescription}
                                values={values?.teamDescription || ''}
                                Max_Length={10}
                                onBlur={handleBlur}
                                OnChange={(event: any) => {
                                    const newName = event.target.value;
                                    setFieldValue("teamDescription", newName, true);
                                }}
                            />
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-4 mt-4">
                            <Inputform
                                label={"Parent Team "}
                                Placeholder={"Enter Parent Team ID"}
                                Name={"parentTeam"}
                                type='text'
                                Title_sx={{ color: "grey3" }}
                                touched={touched?.parentTeam}
                                asterisk={true}
                                errors={errors?.parentTeam}
                                values={values?.parentTeam || teamById?.teamName}
                                onBlur={handleBlur}
                                OnChange={(event: any) => {
                                    const newValue = event.target.value;
                                    setFieldValue("parentTeam", newValue, true);
                                }}
                            />
                        </div>
                        <div className="col-md-4 mt-4">
                            <FormSelect
                                label={"Team Leader"}
                                value={values?.teamLeader}
                                placeholder={"Select Team Leader"}
                                options={userOptions || []}
                                isMulti={false}
                                DefultSelect='Select Team Leader'
                                touched={touched?.teamLeader}
                                errors={errors?.teamLeader}
                                asterisk={true}
                                onBlur={handleBlur}
                                Name='teamLeader'
                                onChange={(teamLeader: any) => {
                                    setFieldValue('teamLeader', teamLeader?.value, true);
                                }}
                            />
                        </div>
                        <div className="col-md-4 mt-4">
                            <Text variant="SelectLabel">Select Members<span className="mx-1 text-danger">*</span></Text>
                            <MultiSelect
                                options={userOptions || []}
                                value={values?.teamMembers?.map((value: any) =>
                                    userOptions?.find((option: Option) => option?.value === value?.userId) || { label: '', value: '' }
                                )}
                                onChange={(selected: Option[]) =>
                                    setFieldValue('teamMembers', selected?.map((option) => ({
                                        userId: option?.value
                                    })))
                                }
                                labelledBy="Select"
                            />
                            {errors?.teamMembers && touched?.teamMembers && typeof errors.teamMembers === 'string' && (
                                <div className="text-danger">
                                    {errors?.teamMembers}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-md-4'><Button type="reset" onClick={() => setIsCreatingSubTeam(false)} sx={{ border: ` 1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} > Cancel </Button>
                        </div>
                        <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' >Submit</Button></div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default AddEditTeams;

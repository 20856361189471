
import chroma from 'chroma-js';
import theme from '../../../../theme';
export const customStylesForDepartments = {
    control: (base) => ({
        ...base,
        backgroundColor: 'white',
        borderRadius: '12px',
        display: 'flex',
        padding: '10px',
        alignItems: 'center',
        gap: '8px',
        alignSelf: 'stretch',
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data?.color || '');
        const textColor = chroma(data.textColor || `${theme.colors.white}`);
        return {
            ...base,
            borderRadius: '12px',
            backgroundColor: isSelected ? `${theme.colors.white}` : undefined,
            color: isDisabled ? `${theme.colors.lightgray}` : isSelected ? textColor : textColor,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...base[':active'],
                backgroundColor: !isDisabled ? (isSelected ? `${theme?.colors?.lightbackground}` : color.alpha(0.3).css()) : undefined,
            },
            borderColor: isSelected ? color.css() : 'transparent',
        };
    },
    multiValue: (base, { data }) => {
        const color = chroma(data.color || `${theme?.colors?.lightbackground}`);
        return {
            ...base,
            border: `1px solid ${color.css()}`,
            ':hover': {
                border: `1px solid ${theme?.colors?.lightbackground}`,
            },
            backgroundColor: `${theme.colors.white}`,
            borderRadius: '12px',
            padding: '5px 5px',
            fontSize: '16px',
        };
    },
    multiValueLabel: (base, { data }) => ({
        ...base,
        borderRadius: '12px',
        color: data.color,
        fontSize: '16px',
        fontWeight: '600',
        gap: '8px',
        paddingLeft: '4px',
        ':before': {
            content: '""',
            display: 'inline-block',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            marginRight: '8px',
            backgroundColor: data.color || `${theme.colors.white}`,

        },
    }),
    multiValueRemove: (base, { data }) => ({
        ...base,
        color: data.color,
        ':hover': {

            backgroundColor: data.color,
            color: `${theme.colors.white}`,
        },
    }),
};

export const customStyles = {
    control: (base) => ({
        ...base,
        backgroundColor: `${theme.colors.white}`,
        borderRadius: '12px',
        padding: '10px',
        alignItems: 'center',
        gap: '8px',
        alignSelf: 'stretch',
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color || '#F7EFE5');
        const backgroundColor = chroma(data.backgroundColor || `${theme?.colors?.lightbackground}`);
        const textColor = chroma(data.textColor || `${theme.colors.white}`);

        return {
            ...base,
            borderRadius: '12px',
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? backgroundColor
                    : isFocused
                        ? backgroundColor.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? `${theme.colors.lightgray}`
                : isSelected
                    ? textColor
                    : textColor,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...base[':active'],
                backgroundColor: !isDisabled ? (isSelected ? backgroundColor : backgroundColor.alpha(0.3).css()) : undefined,
            },
        };
    },
    multiValue: (base, { data }) => {
        const color = chroma(data.color || `${theme?.colors?.lightbackground}`);
        return {
            ...base,
            backgroundColor: color.alpha(0.1).css(),
            borderRadius: '12px',
            padding: '5px 5px'
        };
    },
    multiValueLabel: (base, { data }) => ({
        ...base,
        color: data.color,
        fontSize: '16px',
        fontWeight: '600'
    }),
    multiValueRemove: (base, { data }) => ({
        ...base,
        color: data.color,
        ':hover': {

            backgroundColor: data.color,
            color: `${theme.colors.white}`,
        },
    }),
}

import { DELETE_MULTIPLE_TASK, DELETE_MULTIPLE_TASK_SUCCESS, DELETE_STAGE, DELETE_STAGE_SUCCESS, DELETE_TASK, DELETE_TASK_SUCCESS, GET_ALL_ARCHITECT_MASTERDATA, GET_ALL_ARCHITECT_MASTERDATA_SUCCESS, GET_ALL_BCPC_HISTORY, GET_ALL_BCPC_HISTORY_SUCCESS, GET_ALL_CHECKLIST_TEMPLATES, GET_ALL_CHECKLIST_TEMPLATES_BY_ID, GET_ALL_CHECKLIST_TEMPLATES_BY_ID_SUCCESS, GET_ALL_CHECKLIST_TEMPLATES_SUCCESS, GET_ALL_DELIVERIES_PROJECTVIEW, GET_ALL_DELIVERIES_PROJECTVIEW_SUCCESS, GET_ALL_DELIVERIES_STAGEVIEW, GET_ALL_DELIVERIES_STAGEVIEW_SUCCESS, GET_ALL_MASTERDATA_FOR_TABLE, GET_ALL_MASTERDATA_FOR_TABLE_SUCCESS, GET_ALL_MASTER_DATA, GET_ALL_MASTER_DATA_SUCCESS, GET_ALL_PROJECT_TYPE_MASTERDATA, GET_ALL_PROJECT_TYPE_MASTERDATA_SUCCESS, GET_ALL_STAGES_MASTERDATA, GET_ALL_STAGES_MASTERDATA_SUCCESS, GET_ALL_TASK_FIELDS_MASTERDATA, GET_ALL_TASK_FIELDS_MASTERDATA_SUCCESS, GET_ALL_TASK_MAPPING_MASTERDATA, GET_ALL_TASK_MAPPING_MASTERDATA_SUCCESS, GET_ALL_TASK_MASTERDATA, GET_ALL_TASK_MASTERDATA_SUCCESS, GET_ALL_TASK_TYPE_MASTERDATA, GET_ALL_TASK_TYPE_MASTERDATA_SUCCESS, GET_ARCHITECT_BY_ID, GET_ARCHITECT_BY_ID_SUCCESS, GET_RATING_CRITERIA, GET_RATING_CRITERIA_BY_ID, GET_RATING_CRITERIA_BY_ID_SUCCESS, GET_RATING_CRITERIA_SUCCESS, GET_STAGE_BY_ID, GET_STAGE_BY_ID_SUCCESS, GET_TASK_FIELDS_BY_ID, GET_TASK_FIELDS_BY_ID_SUCCESS, GET_TASK_MAPPING_BY_ID, GET_TASK_MAPPING_BY_ID_SUCCESS, GET_TASK_TYPE_BY_ID, GET_TASK_TYPE_BY_ID_SUCCESS, GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION, GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION_SUCCESS, GET_UPLOAD_TYPE, GET_UPLOAD_TYPE_BY_ID, GET_UPLOAD_TYPE_BY_ID_SUCCESS, GET_UPLOAD_TYPE_NO_PAGINATION, GET_UPLOAD_TYPE_NO_PAGINATION_SUCCESS, GET_UPLOAD_TYPE_SUCCESS } from "../storeTypes";

export const getAllMasterDataForTable = () => {
    return {
        type: GET_ALL_MASTERDATA_FOR_TABLE,
    }
};

export const getAllMasterDataForTableSuccess = (payload) => {
    return {
        type: GET_ALL_MASTERDATA_FOR_TABLE_SUCCESS,
        payload: payload,
    }
};
export const getAllArchitectMasterData = (payload) => {
    return {
        type: GET_ALL_ARCHITECT_MASTERDATA,
        payload: payload,
    }
};

export const getAllArchitectMasterDataSuccess = (payload) => {
    return {
        type: GET_ALL_ARCHITECT_MASTERDATA_SUCCESS,
        payload: payload,
    }
};
export const getArchitectDataById = (payload) => {
    return {
        type: GET_ARCHITECT_BY_ID,
        payload
    }
}
export const getArchitectDataByIdSuccess = (payload) => {
    return {
        type: GET_ARCHITECT_BY_ID_SUCCESS,
        payload
    }
}

export const getAllchecklistMasterData = (payload) => {
    return {
        type: GET_ALL_CHECKLIST_TEMPLATES,
        payload: payload,
    }
};

export const getAllchecklistMasterDataSuccess = (payload) => {
    return {
        type: GET_ALL_CHECKLIST_TEMPLATES_SUCCESS,
        payload: payload,
    }
};
export const getchecklistDataById = (payload) => {
    return {
        type: GET_ALL_CHECKLIST_TEMPLATES_BY_ID,
        payload
    }
}
export const getchecklistDataByIdSuccess = (payload) => {
    return {
        type: GET_ALL_CHECKLIST_TEMPLATES_BY_ID_SUCCESS,
        payload
    }
}
export const getAllStagesMasterData = (payload) => {
    return {
        type: GET_ALL_STAGES_MASTERDATA,
        payload: payload,
    }
};

export const getAllStagesMasterDataSuccess = (payload) => {
    return {
        type: GET_ALL_STAGES_MASTERDATA_SUCCESS,
        payload: payload,
    }
};


export const getAllprojectTypeMasterData = (payload) => {
    return {
        type: GET_ALL_PROJECT_TYPE_MASTERDATA,
        payload: payload,
    }
};

export const getAllprojectTypeMasterDataSucces = (payload) => {
    return {
        type: GET_ALL_PROJECT_TYPE_MASTERDATA_SUCCESS,
        payload: payload,
    }
};
export const getAllDeliveriesProjectView = (payload) => {
    return {
        type: GET_ALL_DELIVERIES_PROJECTVIEW,
        payload: payload,
    }
};

export const getAllDeliveriesProjectViewSuccess = (payload) => {
    return {
        type: GET_ALL_DELIVERIES_PROJECTVIEW_SUCCESS,
        payload: payload,
    }
};
export const getAllDeliveriesStageView = (payload) => {
    return {
        type: GET_ALL_DELIVERIES_STAGEVIEW,
        payload: payload,
    }
};

export const getAllDeliveriesStageViewSuccess = (payload) => {
    return {
        type: GET_ALL_DELIVERIES_STAGEVIEW_SUCCESS,
        payload: payload,
    }
};
export const getAllBcPcHistory = (payload) => {
    return {
        type: GET_ALL_BCPC_HISTORY,
        payload: payload,
    }
};
export const getAllBcPcHistorySuccess = (payload) => {
    return {
        type: GET_ALL_BCPC_HISTORY_SUCCESS,
        payload: payload,
    }
};
export const getAllTaskMasterData = (payload) => {
    return {
        type: GET_ALL_TASK_MASTERDATA,
        payload: payload,
    }
};

export const getAllTaskMasterDataSuccess = (payload) => {
    return {
        type: GET_ALL_TASK_MASTERDATA_SUCCESS,
        payload: payload,
    }
};

export const getAllTaskMappingMasterData = (payload) => {
    return {
        type: GET_ALL_TASK_MAPPING_MASTERDATA,
        payload: payload,
    }
};

export const getAllTaskMappingMasterDataSuccess = (payload) => {
    return {
        type: GET_ALL_TASK_MAPPING_MASTERDATA_SUCCESS,
        payload: payload,
    }
};

export const getAllTaskFieldsMasterData = (payload) => {
    return {
        type: GET_ALL_TASK_FIELDS_MASTERDATA,
        payload: payload,
    }
};

export const getAllTaskFieldsMasterDataSuccess = (payload) => {
    return {
        type: GET_ALL_TASK_FIELDS_MASTERDATA_SUCCESS,
        payload: payload,
    }
};


export const getAllTaskTypesMasterData = (payload) => {
    return {
        type: GET_ALL_TASK_TYPE_MASTERDATA,
        payload: payload,
    }
};

export const getAllTaskTypesMasterDataSuccess = (payload) => {
    return {
        type: GET_ALL_TASK_TYPE_MASTERDATA_SUCCESS,
        payload: payload,
    }
};



export const getAllMasterData = (payload) => {
    return {
        type: GET_ALL_MASTER_DATA,
        payload: payload,
    }
};

export const getAllMasterDataSuccess = (payload) => {
    return {
        type: GET_ALL_MASTER_DATA_SUCCESS,
        payload: payload,
    }
};


export const getStageDataById = (payload) => {
    return {
        type: GET_STAGE_BY_ID,
        payload
    }
}
export const getStageDataByIdSuccess = (payload) => {
    return {
        type: GET_STAGE_BY_ID_SUCCESS,
        payload
    }
}

export const getTaskTypeDataById = (payload) => {
    return {
        type: GET_TASK_TYPE_BY_ID,
        payload
    }
}
export const getTaskTypeDataByIdSuccess = (payload) => {
    return {
        type: GET_TASK_TYPE_BY_ID_SUCCESS,
        payload
    }
}
export const deleteTask = (id) => {
    return {
        type: DELETE_TASK,
        payload: id
    }
}
export const deleteTaskSuccess = (id) => {
    return {
        type: DELETE_TASK_SUCCESS,
        payload: id
    }
}
export const deleteMultipleTask = (payload) => {
    return {
        type: DELETE_MULTIPLE_TASK,
        payload
    }
}
export const deleteMultipleTaskSuccess = (payload) => {
    return {
        type: DELETE_MULTIPLE_TASK_SUCCESS,
        payload
    }
}
export const deleteStage = (id) => {
    return {
        type: DELETE_STAGE,
        payload: id
    }
}
export const deleteStageSuccess = (id) => {
    return {
        type: DELETE_STAGE_SUCCESS,
        payload: id
    }
}
export const getTaskTypeDataWithoutPaginatation = (payload) => {
    return {
        type: GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION,
        payload
    }
}
export const getTaskTypeDataWithoutPaginatationSuccess = (payload) => {
    return {
        type: GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION_SUCCESS,
        payload
    }
}

export const getTaskSowDataById = (payload) => {
    return {
        type: GET_TASK_MAPPING_BY_ID,
        payload
    }
}
export const getTaskSowDataByIdSuccess = (payload) => {
    return {
        type: GET_TASK_MAPPING_BY_ID_SUCCESS,
        payload
    }
}

export const getTaskFieldSowDataById = (payload) => {
    return {
        type: GET_TASK_FIELDS_BY_ID,
        payload
    }
}
export const getTaskFieldSowDataByIdSuccess = (payload) => {
    return {
        type: GET_TASK_FIELDS_BY_ID_SUCCESS,
        payload
    }
}

export const getRatingCriteria = (payload) => {
    return {
        type: GET_RATING_CRITERIA,
        payload
    }
}

export const getRatingCriteriaSuccess = (payload) => {
    return {
        type: GET_RATING_CRITERIA_SUCCESS,
        payload
    }
}

export const getRatingCriteriaById = (payload) => {
    return {
        type: GET_RATING_CRITERIA_BY_ID,
        payload
    }
}

export const getRatingCriteriaByIdSuccess = (payload) => {
    return {
        type: GET_RATING_CRITERIA_BY_ID_SUCCESS,
        payload
    }
}

export const getUploadType = (payload) => {
    return {
        type: GET_UPLOAD_TYPE,
        payload
    }
}

export const getUploadTypeSuccess = (payload) => {
    return {
        type: GET_UPLOAD_TYPE_SUCCESS,
        payload
    }
}
export const getUploadTypeNoPagination = (payload) => {
    return {
        type: GET_UPLOAD_TYPE_NO_PAGINATION,
        payload
    }
}

export const getUploadTypeNoPaginationSuccess = (payload) => {
    return {
        type: GET_UPLOAD_TYPE_NO_PAGINATION_SUCCESS,
        payload
    }
}
export const getUploadTypeById = (payload) => {
    return {
        type: GET_UPLOAD_TYPE_BY_ID,
        payload
    }
}

export const getUploadTypeByIdSuccess = (payload) => {
    return {
        type: GET_UPLOAD_TYPE_BY_ID_SUCCESS,
        payload
    }
}
import { call, put, takeEvery } from 'redux-saga/effects';
import { actionHighlightFail, createActionHighlightSuccess, createBCDoneHighlightSuccess, deleteActionHighlightSuccess, deleteMultipleActionHighlightSuccess, editActionHighlightSuccess, editActionPayloadSuccess, getActionByNameSuccess, getActionHighlighterIdsSuccess, getActionIdSuccess, getAllDeliverChecklistSuccess, getAllTemplateChecklistSuccess, getBcPcRevStagesSuccess, getExcelReportSuccess, removeHighlightFromProjectSuccess, removeHighlightFromStageSuccess, removeHighlightFromTaskSuccess } from "../actions/action.Highlighters";
import { GetAllTemplateApi, GetReportApi, addCommentActionHighlightApi, addDescriptionActionHighlightApi, addDescriptionBulkActionHighlightApi, createActionHighlighter, createBCDoneHighlighter, deleteActionHighlighter, deleteCommentActionHighlighterApi, deleteMultipleActionHighlighter, editActionHighlighterApi, editActionTemplateApi, editCommentActionHighlightApi, getActioIdApi, getActionByNameApi, getActionDeliverChecklistApi, getActionHighlighterIdsApi, getBCPCRevApi, removeActionHighlighterApi, removeActionHighlighterFromProjectApi, updateActionHighlighterApi } from "../api";
import { ADD_COMMENT_ACTION_HIGHLIGHTS, ADD_DESCRIPTION_ACTION_HIGHLIGHTS, ADD_DESCRIPTION_BULK_ACTION_HIGHLIGHTS, CREATE_ACTION_HIGHLIGHTS, CREATE_BC_DONE_HIGHLIGHTS, DELETE_ACTION__MULTIPLE_HIGHLIGHTS, DELETE_ACTION_HIGHLIGHTS, DELETE_COMMENT_ACTION_HIGHLIGHTS, EDIT_ACTION_HIGHLIGHTS, EDIT_ACTION_PAYLOAD, EDIT_COMMENT_ACTION_HIGHLIGHTS, GET_ACTION_BY_ID, GET_ACTION_HIGHLIGHTS_BY_IDS, GET_ALL_ACTION_BY_NAME, GET_ALL_BC_PC_REV_STAGES, GET_ALL_DELIVER_CHECKLIST, GET_ALL_TEMPLATES, GET_EXCEL_REPORT, REMOVE_HIGHLIGHTER, REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW, REMOVE_HIGHLIGHTER_FROM_TASKVIEW } from "../storeTypes";


function* createActionHighlightSaga(action) {
  try {
    const response = yield call(createActionHighlighter, action.payload);
    action?.callback && action?.callback(response);
    yield put(createActionHighlightSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}

function* createBCDoneHighlightSaga({ payload, actionId }) {
  try {
    const response = yield call(createBCDoneHighlighter, payload, actionId);
    yield put(createBCDoneHighlightSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}

function* getActionHighlightIdSaga(action) {
  try {
    const response = yield call(getActionHighlighterIdsApi, action.payload, action.highlight);
    yield put(getActionHighlighterIdsSuccess(response.data.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* getActionIdSaga({ actionId }) {
  try {
    const response = yield call(getActioIdApi, actionId);
    yield put(getActionIdSuccess(response.data.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* getActionByNameSaga(action) {
  try {
    const response = yield call(getActionByNameApi, action.payload, action.highlight);
    yield put(getActionByNameSuccess(response?.data?.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* getAllBcPcRevSaga(action) {
  try {
    const response = yield call(getBCPCRevApi, action.payload, action.highlight);
    yield put(getBcPcRevStagesSuccess(response?.data?.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* getActionDeliverCheckSaga(action) {
  try {
    const response = yield call(getActionDeliverChecklistApi, action.payload, action.highlight);
    yield put(getAllDeliverChecklistSuccess(response.data.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}

function* getAllTemplateCheckSaga(action) {
  try {
    const response = yield call(GetAllTemplateApi, action.payload, action.highlight);
    yield put(getAllTemplateChecklistSuccess(response.data.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* editActionHighlightSaga(action) {
  try {
    const response = yield call(editActionHighlighterApi, action);
    action?.callback && action?.callback(response);
    yield put(editActionHighlightSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* removeActionHighlightFromStageSaga({ payload, stageId }) {
  try {
    const response = yield call(updateActionHighlighterApi, payload, stageId);
    yield put(removeHighlightFromStageSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* removeActionHighlightFromTaskSaga({ payload, taskId }) {
  try {
    const response = yield call(removeActionHighlighterApi, payload, taskId);
    yield put(removeHighlightFromTaskSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* removeActionHighlightFromProjectSaga({ payload, projectId }) {
  try {
    const response = yield call(removeActionHighlighterFromProjectApi, payload, projectId);
    yield put(removeHighlightFromProjectSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* editActionPayloadSaga(action) {
  try {
    const response = yield call(editActionTemplateApi, action);
    yield put(editActionPayloadSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* addDescriptionActionHighlightSaga(action) {
  try {
    const response = yield call(addDescriptionActionHighlightApi, action);
    yield put(editActionHighlightSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* addDescriptionBulkActionHighlightSaga(action) {
  try {
    const response = yield call(addDescriptionBulkActionHighlightApi, action);
    yield put(editActionHighlightSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* deleteActionHighlightSaga(action) {
  try {
    const response = yield call(deleteActionHighlighter, action);
    yield put(deleteActionHighlightSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* deleteMultipleActionHighlightSaga(action) {
  try {
    const response = yield call(deleteMultipleActionHighlighter, action);
    yield put(deleteMultipleActionHighlightSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* addCommentActionHighlightSaga(action) {
  try {
    const response = yield call(addCommentActionHighlightApi, action);
    yield put(editActionHighlightSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* editCommentActionHighlightSaga(action) {
  try {
    const response = yield call(editCommentActionHighlightApi, action);
    if (response.data) {
      yield put(editActionHighlightSuccess(response.data))
    }
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}

function* deleteCommnetActionHighlightSaga(action) {
  try {
    const response = yield call(deleteCommentActionHighlighterApi, action);
    yield put(deleteActionHighlightSuccess(response.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
function* getAllExcelReportSaga(action) {
  try {
    const response = yield call(GetReportApi, action.payload);
    yield put(getExcelReportSuccess(response.data.data))
  } catch (error) {
    yield put(actionHighlightFail(error))
  }
}
export function* actionHighlitersSaga() {
  return [
    yield takeEvery(GET_ACTION_HIGHLIGHTS_BY_IDS, getActionHighlightIdSaga),
    yield takeEvery(GET_ACTION_BY_ID, getActionIdSaga),
    yield takeEvery(GET_ALL_ACTION_BY_NAME, getActionByNameSaga),
    yield takeEvery(GET_ALL_BC_PC_REV_STAGES, getAllBcPcRevSaga),
    yield takeEvery(GET_ALL_DELIVER_CHECKLIST, getActionDeliverCheckSaga),
    yield takeEvery(GET_EXCEL_REPORT, getAllExcelReportSaga),
    yield takeEvery(REMOVE_HIGHLIGHTER, removeActionHighlightFromStageSaga),
    yield takeEvery(REMOVE_HIGHLIGHTER_FROM_TASKVIEW, removeActionHighlightFromTaskSaga),
    yield takeEvery(REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW, removeActionHighlightFromProjectSaga),
    yield takeEvery(GET_ALL_TEMPLATES, getAllTemplateCheckSaga),
    yield takeEvery(CREATE_ACTION_HIGHLIGHTS, createActionHighlightSaga),
    yield takeEvery(CREATE_BC_DONE_HIGHLIGHTS, createBCDoneHighlightSaga),
    yield takeEvery(EDIT_ACTION_PAYLOAD, editActionPayloadSaga),
    yield takeEvery(EDIT_ACTION_HIGHLIGHTS, editActionHighlightSaga),
    yield takeEvery(ADD_DESCRIPTION_ACTION_HIGHLIGHTS, addDescriptionActionHighlightSaga),
    yield takeEvery(ADD_DESCRIPTION_BULK_ACTION_HIGHLIGHTS, addDescriptionBulkActionHighlightSaga),
    yield takeEvery(DELETE_ACTION_HIGHLIGHTS, deleteActionHighlightSaga),
    yield takeEvery(DELETE_ACTION__MULTIPLE_HIGHLIGHTS, deleteMultipleActionHighlightSaga),
    yield takeEvery(ADD_COMMENT_ACTION_HIGHLIGHTS, addCommentActionHighlightSaga),
    yield takeEvery(EDIT_COMMENT_ACTION_HIGHLIGHTS, editCommentActionHighlightSaga),
    // yield takeEvery(DELETE_ACTION_HIGHLIGHTS, deleteActionHighlightSaga),
    // yield takeEvery(ADD_COMMENT_ACTION_HIGHLIGHTS, addCommentActionHighlightSaga),
    // yield takeEvery(EDIT_COMMENT_ACTION_HIGHLIGHTS, editCommentActionHighlightSaga),
    yield takeEvery(DELETE_COMMENT_ACTION_HIGHLIGHTS, deleteCommnetActionHighlightSaga),

  ]
}

import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import DataTable from '../../../Tabels/Tabel';
import { setPagination } from '../../../../store/actions/Pagination.action';
import { getAllArchitectMasterData } from '../../../../store/actions/masterData.action';
import { Divider, Text } from 'theme-ui';
import { TabelHeadingTxt } from '../../../Text/TabelCommonTxt';
import plus from "../../../assets/images/Icon/plus.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import TableActions from '../../users/TableActions';
import { useDispatch } from 'react-redux';
import { getApiEndPoint } from '../../../../utils/GenerateQueryString';

const ArchitectMasterData: React.FC = () => {

    const { architect, loader } = useSelector((state: any) => state.masterData);
    const { paginations } = useSelector((state: any) => state.pagination);
    const [refresh, setRrefresh] = useState<boolean>(true);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const columns = useMemo<ColumnDef<any>[]>(() => [
        // {
        //     accessorKey: "id",
        //     id: "select",
        //     enableColumnFilter: false,
        //     header: ({ table }) => (
        //         <div className="d-flex justify-content-center">
        //             <IndeterminateCheckbox
        //                 {...{
        //                     checked: table.getIsAllRowsSelected(),
        //                     indeterminate: table.getIsSomeRowsSelected(),
        //                     onChange: table.getToggleAllRowsSelectedHandler(),
        //                 }}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }) => (
        //         <IndeterminateCheckbox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />

        //     ),
        // },
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((paginations?.currentPage - 1) * paginations?.limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "name",
            header: 'Name',
            cell: info => info.getValue(),
        },
        {
            header: 'Email',
            accessorKey: "email",
            cell: info => info.getValue(),
        },
        {
            header: 'Description',
            accessorKey: "description",
            cell: info => info.getValue(),
        },
        {
            header: 'Accessor',
            accessorKey: "Accessor",
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <TableActions row={row} editTo={`/edit-architect/${row?.original?._id}`} deleteMessage='Are you sure you want to delete this architect?' deleteApi={`architect/delete-architect/${row?.original?._id}`} toggleRefresh={() => setRrefresh(true)} />
                )
            },
        }
    ], [architect?.data]);
    useEffect(() => {
        return () => {
            dispatch(setPagination({
                limit: 15,
                currentPage: 1,
            }))
        }
    }, [])
    useEffect(() => {
        let apiEndPoint = getApiEndPoint(paginations, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
        dispatch(getAllArchitectMasterData(apiEndPoint))
    }, [location?.search]);
    useEffect(() => {
        if (refresh) {
            let apiEndPoint = getApiEndPoint(paginations, "", columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
            dispatch(getAllArchitectMasterData(apiEndPoint));
            setRrefresh(false);
        }
    }, [refresh])
    const handleclick = () => {
        navigate("/create-architect")
    }
    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Architect' Count={architect?.count || 15} BtnTxt='Create Architect' src={plus} onClick={handleclick} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                loader={loader}
                columns={columns}
                data={architect?.data || []}
                TotalLength={architect?.count || 15}
                perPageCount={paginations.limit}
                currentPage={paginations.currentPage}
                onPerPageChange={(itemsPerPage) => {
                    dispatch(setPagination({
                        limit: itemsPerPage,
                        currentPage: 1
                    }));
                    setRrefresh(true);
                }}
                onPageChange={(value) => {
                    dispatch(setPagination({
                        ...paginations,
                        ...{ currentPage: value }
                    }));
                    setRrefresh(true);
                }} />
            {/*<FillterModal />*/}
        </div>
    )
};

export default ArchitectMasterData;

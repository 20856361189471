import { useEffect, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { Box, Image } from 'theme-ui';
import notification1 from "../assets/images/Notification1.svg";
import crossIcon from "../assets/images/Icon/cross.svg";
import DeliveryHistory from '../History/DeliveryHistory';
import BCPCRevHistory from '../History/BCPCHistory';
import theme from '../../theme';
import BcPcRevHistory from '../History/BcPcRevHistory';

export default function HistoryAction({ selectedTab, stageId, projectId, view, viewtask, stageDataId, taskId }: any) {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isHistoryPopover, setIsHistoryPopover] = useState(false);
    const [selectedHistory, setSelectedHistory] = useState<any>(null);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [target, setTarget] = useState(null);
    const [Open, setOpen] = useState<any>(false);

    const handleHighlightClick = (highlight: any) => {
        setSelectedHistory(highlight);
        setOpen(true);
        setIsPopoverOpen(!isPopoverOpen)
        setIsHistoryPopover(!isHistoryPopover)
    };
    const togglePopover = (event: any) => {
        setIsPopoverOpen(!isPopoverOpen);
        setTarget(event.target);
    };
    const toggleHistoryPopover = (event: any) => {
        setIsHistoryPopover(!isHistoryPopover);
        setTarget(event.target);
    };
    const handleClickOutside = (event: any) => {


        if (!event.target.closest('.popover-body')) {

            if (!event.target.closest('.toggle-btn')) {
                setIsPopoverOpen(false);
                setIsHistoryPopover(false);
            }
        }

    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div ref={popoverRef}>
            <Overlay
                show={isPopoverOpen}
                target={target}
                placement="left"
            >
                <Popover style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }} id={`popover-positioned`} >
                    <Popover.Body className="w-auto">
                        {viewtask !== 'task' && (
                            <Box
                                sx={{
                                    color: '#ebd737', padding: "12px", borderRadius: "8px", cursor: 'pointer', '&:hover': {
                                        backgroundColor: `${theme?.colors?.lightbackground}`
                                    }
                                }}
                                onClick={(e: any) => {
                                    togglePopover(e)
                                    handleHighlightClick('DeliveryHistory')
                                }}
                            >
                                {'Delivery History'}
                            </Box>)}

                        <Box
                            sx={{
                                color: '', padding: "12px", borderRadius: "8px", cursor: 'pointer', '&:hover': {
                                    backgroundColor: `${theme?.colors?.lightbackground}`
                                }
                            }}
                            onClick={(e: any) => {
                                togglePopover(e)
                                handleHighlightClick('BC/PC/RevHistory')
                            }}
                        >
                            {'BC/PC/Rev History'}
                        </Box>
                        <Box
                            sx={{
                                color: '', padding: "12px", borderRadius: "8px", cursor: 'pointer', '&:hover': {
                                    backgroundColor: `${theme?.colors?.lightbackground}`
                                }
                            }}
                            onClick={(e: any) => {
                                toggleHistoryPopover(e)
                                handleHighlightClick('BC/PC/Rev/NIAHistory')
                            }}
                        >
                            {'BC/PC/Rev/NIA History'}
                        </Box>
                    </Popover.Body>
                </Popover>
            </Overlay>

            <button className='btn toggle-btn mt-3 ' style={{ backgroundColor: selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit' }} onClick={togglePopover}>
                {isPopoverOpen ? <Image src={crossIcon} alt='' style={{ fill: selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                    : <Image src={notification1} alt='' style={{ fill: selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />}
            </button>
            <p className='mt-1' style={{ cursor: 'pointer' }}>History</p>
            {selectedHistory === 'DeliveryHistory' && (
                <DeliveryHistory selectedHighlight={selectedHistory} Open={Open} setOpen={setOpen} actionObject={{ projectId: projectId }} stageId={stageId} />
            )}
            {selectedHistory === 'BC/PC/RevHistory' && (
                <BCPCRevHistory selectedHighlight={selectedHistory} Open={Open} setOpen={setOpen} actionObject={{ projectId: projectId, stageId: stageId, stageDataId: stageDataId }} />
            )}
            {selectedHistory === 'BC/PC/Rev/NIAHistory' && (
                <BcPcRevHistory selectedHighlight={selectedHistory} Open={Open} setOpen={setOpen} actionObject={{ projectId: projectId || null, stageId: stageId || null, stageDataId: stageDataId || null, taskId: taskId || null }} />
            )}
        </div>
    )
}

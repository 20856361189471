import { Box, Flex, Image, Text } from 'theme-ui';
import subMenuIcon from "../../Components/assets/images/Icon/subMenu.svg";
import watchIcon from "../../Components/assets/images/Icon/watch.svg";
import { useSelector } from 'react-redux';
import { msToTime } from '../../utils/GenerateQueryString';

export const SummeryTime = () => {
    const { timeSummery } = useSelector((state: any) => state.project);
    return (
        <>
            {timeSummery && <Flex sx={{ flexWrap: 'wrap' }} variant='styles.summaryBox' >
                <Box>
                    <Image src={subMenuIcon} width={22} />
                </Box>
                <Box>
                    <Image src={watchIcon} width={22} />
                    <Text>Est.:{msToTime(timeSummery?.totalTimeEstimated).hours}:{msToTime(timeSummery?.totalTimeEstimated).minutes},</Text>
                </Box>
                <Box>
                    <Image src={watchIcon} width={22} />
                    <Text>Spent:{msToTime(timeSummery?.totalTimeSpend).hours}:{msToTime(timeSummery?.totalTimeSpend).minutes},</Text>
                </Box>
                <Box>
                    <Image src={watchIcon} width={22} />
                    <Text>Rem.:{msToTime(timeSummery?.timeRemaining).hours}:{msToTime(timeSummery?.timeRemaining).minutes},</Text>
                </Box>
                <Box>
                    <Text>
                        <Text sx={{ display: "inline-grid", padding: "3px", borderRadius: "8px", border: `2px solid ${timeSummery?.needInfoHighlighter?.color}`, width: "22px", height: "22px", placeContent: "center" }}>{timeSummery?.needInfoHighlighter?.icon && <Image src={timeSummery?.needInfoHighlighter?.icon} width={22} />}</Text>
                        ={timeSummery?.totalNeedInfo}
                    </Text>
                </Box>
                <Box>
                    <Text>
                        <Text sx={{ display: "inline-grid", padding: "4px", borderRadius: "8px", border: `1px solid ${timeSummery?.blockedByInfoHighlight?.color}`, width: "22px", height: "22px", placeContent: "center" }}>{timeSummery?.blockedByInfoHighlight?.icon && <Image src={timeSummery?.blockedByInfoHighlight?.icon} width={18} />}</Text>
                        ={timeSummery?.totalBlockedByInfo}
                    </Text>
                </Box>
            </Flex>}
        </>
    )
}

import { FaTasks, FaUser } from "react-icons/fa";
import { Badge, Box, Card, Divider, Flex, Image, Spinner, Text } from "theme-ui";
import theme from "../../../theme";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAllTeamsByUser, getMembersByTeam } from "../../../store/actions/user.action";
import { useSearchParams } from "react-router-dom";
import { usePreviousURL } from "../../../utils/CustomHandler";
import { bucketUrl } from "../../../utils/global";
import InfiniteScroll from "react-infinite-scroll-component";
const TeamDetailView = () => {
    const { teamByUser, userData, membersInfo, loader } = useSelector((state: any) => state.user);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    const limitation = parseInt(searchParams.get('limit') || "15");
    const [tasks, setTasks] = useState<any[]>([]);
    const [totalTasks, setTotalTasks] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(currentPage);
    const [limit, setLimit] = useState<number>(limitation);
    const [hasMore, setHasMore] = useState(true);
    const teamDetails = teamByUser?.data?.data;
    const [selectedTeam, setSelectedTeam] = useState<any>(null);
    const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
    const handleTeamClick = (team: any) => {
        setTasks([])
        setSelectedTeam(team);
    };

    useEffect(() => {
        dispatch(getAllTeamsByUser())
    }, [dispatch])
    const previousURL = usePreviousURL()
    const handleMemberClick = (memberId: string) => {
        setSelectedMemberId(memberId);
        setPageNumber(1);
        setTasks([]);
        setHasMore(true);
        setSearchParams({ currentPage: '1', limit: limit.toString() });

        const params = {
            currentPage: 1,
            limit: limit,
        };
        dispatch(getMembersByTeam(memberId, params));
    };
    useEffect(() => {
        if (selectedMemberId) {
            const params = {
                currentPage: currentPage,
                limit: limitation,
            };
            dispatch(getMembersByTeam(selectedMemberId, params));
        }
    }, [selectedMemberId, currentPage, limitation, dispatch]);
    useEffect(() => {
        if (membersInfo?.tasks?.length) {
            setTasks((prevTasks) => [...prevTasks, ...membersInfo.tasks]);

            if (membersInfo.count) {
                setTotalTasks(membersInfo.count);
            }

            if (membersInfo.tasks.length < limit || tasks.length >= membersInfo.count) {
                setHasMore(false);
            }
        }
    }, [membersInfo?.tasks]);

    const loadMoreTasks = () => {
        if (tasks?.length >= totalTasks) {
            setHasMore(false);
            return;
        }
        const nextPage = pageNumber + 1;
        setPageNumber(nextPage);
        setSearchParams({ currentPage: nextPage.toString(), limit: limit.toString() });


        const params = {
            currentPage: nextPage,
            limit: limit,
        };
        if (selectedMemberId) {
            dispatch(getMembersByTeam(selectedMemberId, params));
        }
    };

    return (
        <div className="main-content">
            <div className="row">
                <div className='col-12 col-md-6 col-xl-6 mt-5 mt-xl-0'>
                    <Text className="px-2" variant='Poppins36Normal35lh'>
                        {'Teams'}
                    </Text>
                </div>
            </div>
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <div className="row">
                <div className="col-xl-4">
                    <Card sx={{ p: 4, ml: 3, borderRadius: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', mb: 4 }}>
                        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
                            <Text variant="Poppins16Bold16lh" sx={{ color: theme?.colors?.brown, fontSize: 20 }}>My Team <Badge className="badge" variant="DropDownbedge" sx={{ ml: 2, position: 'relative' }}>{teamByUser?.data?.count}</Badge></Text>
                            {/* <Text sx={{ fontSize: 0 }}>31 Aug 24 – 28 Sep 24</Text> */}
                        </Flex>
                        <Flex sx={{ flexWrap: 'wrap', gap: 4 }}>
                            {teamDetails?.map((team: any, index: number) => (
                                <Card
                                    sx={{
                                        p: 3,
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                        width: '100%',
                                        mb: 4,
                                        cursor: 'pointer',
                                        position: 'relative',
                                    }}
                                    key={team?._id}
                                    onClick={() => handleTeamClick(team)
                                    }
                                >
                                    <Text as="h4" variant="Poppins16Bold16lh" sx={{ color: theme?.colors?.brown, mb: 2, }}>
                                        {team?.teamName}
                                    </Text>

                                    {/* Task Information */}
                                    {/* <Text variant="Poppins14Medium20lh" sx={{ color: theme?.colors?.lightbrown, mb: 3 }}>
                                        <Text as="span" sx={{ color: '#A6E57F' }}>●</Text> Active project : {team?.projectName}
                                    </Text> */}
                                    <Box sx={{ fontSize: 1, mb: 3, mt: 1 }}>
                                        <Flex sx={{ alignItems: 'center', mb: 2 }}>
                                            <FaTasks style={{ marginRight: 8, color: '#8d8d8d' }} /><Text variant="Poppins14Normal24lh"> Total Members : {team?.membersCount}</Text>
                                        </Flex>
                                        <Flex sx={{ alignItems: 'center', mb: 2 }}>
                                            <FaUser style={{ marginRight: 8, color: '#8d8d8d' }} /><Text variant="Poppins14Normal24lh">  Team Leader: {team?.teamLeader?.FirstName} {team?.teamLeader?.LastName}</Text>
                                        </Flex>
                                        {/* <Flex sx={{ alignItems: 'center', mb: 2 }}>
                                            <FaHourglassHalf style={{ marginRight: 8, color: '#8d8d8d' }} /> <Text variant="Poppins14Normal24lh"> Hours of work pending : {team?.workPending} </Text>
                                        </Flex>
                                        <Flex sx={{ alignItems: 'center' }}>
                                            <FaHourglassHalf style={{ marginRight: 8, color: '#8d8d8d' }} /> <Text variant="Poppins14Normal24lh"> Hours of work completed : {team?.workCompleted} </Text>
                                        </Flex> */}
                                    </Box>

                                    {/* Rating and Avatars */}
                                    {/* <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
                                     
                                        <Flex sx={{ alignItems: 'center' }}>
                                            <Text variant="Poppins14Medium20lh" sx={{ color: theme?.colors?.brown }}>Avg. Rating</Text>
                                            <FaStar style={{ color: 'orange', marginLeft: 8 }} />
                                            <Text sx={{ ml: 1 }}>{team?.rating} ({team?.ratingCount} Ratings)</Text>
                                        </Flex>

                                       
                                        <Flex>
                                            {team?.avatars?.map((avatar: string, index: number) => (
                                                <Image
                                                    key={index}
                                                    src={avatar}
                                                    sx={{ borderRadius: '50%', width: 30, height: 30, ml: index > 0 ? -10 : 0 }}
                                                />
                                            ))}
                                        </Flex>
                                    </Flex> */}
                                </Card>
                            ))}
                        </Flex>
                    </Card>
                </div>
                <div className="col-xl-4">
                    {selectedTeam ? (
                        <Card sx={{ p: 4, borderRadius: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', mb: 4 }}>
                            <Text as="h4" variant="Poppins16Bold16lh" sx={{ color: theme?.colors?.brown, mb: 2 }}>
                                Members of {selectedTeam?.teamName}
                                <Badge className="badge" variant="DropDownbedge" sx={{ ml: 2, position: 'relative' }}>
                                    {selectedTeam?.members?.length}
                                </Badge>
                            </Text>
                            <Divider sx={{ color: '#D9D7DD', mb: 3 }} />
                            {selectedTeam?.members?.map((member: any, index: number) => (
                                <Card
                                    key={index}
                                    sx={{
                                        p: 3,
                                        mb: 3,
                                        borderRadius: '10px',
                                        border: '1px solid #ddd',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                        cursor: 'pointer', // Indicate clickable behavior
                                        '&:hover': { backgroundColor: '#f9f9f9' },
                                    }}
                                    onClick={() => handleMemberClick(member.userId._id)}

                                >
                                    <Flex sx={{ alignItems: 'center' }}>
                                        {/* Member Avatar */}
                                        {member.userId.avatar ? (
                                            <Image
                                                src={`${bucketUrl} ${member.userId.avatar}`}
                                                alt={`${member.userId.FirstName} ${member.userId.LastName}`}
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                    mr: 3,
                                                }}
                                            />
                                        ) : (
                                            <Box
                                                sx={{
                                                    width: 40,
                                                    height: 40,
                                                    borderRadius: '50%',
                                                    backgroundColor: theme?.colors?.primary || '#1976d2',
                                                    color: theme?.colors?.white || '#fff',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontWeight: 600,
                                                    fontSize: '18px',
                                                    mr: 3,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {member.userId.FirstName?.charAt(0)}
                                            </Box>
                                        )}
                                        {/* Member Details */}
                                        <Box>
                                            <Text as="h5" sx={{ fontSize: '16px', color: theme?.colors?.brown || 'black', fontWeight: 600 }}>
                                                {member.userId.FirstName} {member.userId.LastName}
                                            </Text>

                                        </Box>
                                    </Flex>
                                </Card>
                            ))}
                        </Card>
                    ) : (
                        <Card sx={{ p: 4, borderRadius: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', mb: 4 }}>
                            <Text as="h4" variant="Poppins16Bold16lh" sx={{ color: theme?.colors?.brown, mb: 2 }}>
                                Members of Selected Team
                            </Text>
                            <Text variant="Poppins14Normal24lh" sx={{ color: theme?.colors?.gray, mt: 4 }}>
                                Select a team to view its members.
                            </Text>
                        </Card>
                    )}
                </div>
                <div className="col-xl-4" >
                    {loader ? (
                        <Box sx={{ position: "relative", inset: "0", width: "100%", height: "100%", backgroundColor: 'rgba(255, 255, 255, 0.5)', display: "grid", placeContent: "center" }}><Spinner /></Box>
                    ) : tasks?.length > 0 ? (
                        <Card sx={{ p: 4, borderRadius: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', mb: 4 }}>
                            <Text as="h4" variant="Poppins16Bold16lh" sx={{ color: theme?.colors?.brown, mb: 2 }}>
                                Tasks
                                <Badge className="badge" variant="DropDownbedge" sx={{ ml: 2, position: 'relative' }}>
                                    {membersInfo?.tasks?.length}
                                </Badge>
                            </Text>
                            <Divider sx={{ color: '#D9D7DD', mb: 3 }} />
                            <InfiniteScroll
                                dataLength={tasks?.length || 0}
                                next={loadMoreTasks}
                                hasMore={hasMore}
                                loader={<h4></h4>}
                                endMessage={<p></p>}
                                height={800}

                            >
                                {tasks?.map((task: any, index: number) => (
                                    <Card
                                        key={index}
                                        sx={{
                                            p: 3,
                                            mb: 3,
                                            borderRadius: '10px',
                                            border: '1px solid #ddd',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                        }}
                                    >
                                        <Flex sx={{ flexDirection: 'column', gap: 2 }}>
                                            <Text as="h4" sx={{ color: theme?.colors?.brown || 'black' }}>
                                                Task:
                                                <Text sx={{ color: task?.color || 'black', cursor: 'pointer' }} onClick={() => { previousURL(`/edit-task/${task?._id}`) }}> {task?.name || ''}</Text>
                                            </Text>
                                            <Text as="h5" sx={{ color: theme?.colors?.brown || 'black' }}>
                                                Stage:
                                                <Text sx={{ color: task?.stageDataId?.color || 'black', cursor: 'pointer' }} onClick={() => { previousURL(`/edit-stages/${task?.stageDataId?._id}`) }}>{task?.stageDataId?.name || ''}</Text>
                                            </Text>
                                            <Text as="h6" sx={{ color: theme?.colors?.brown || 'black', cursor: 'pointer' }} onClick={() => { previousURL(`/edit-project/${task?.projectId?._id}`) }}>
                                                Project: {task?.projectId?.name || ''}
                                            </Text>
                                            <Text as="h6" sx={{ color: theme?.colors?.brown || 'black' }} >
                                                Status: {task?.status || ''}
                                            </Text>
                                        </Flex>
                                    </Card>
                                ))}
                            </InfiniteScroll>
                        </Card>
                    ) : (
                        <Card sx={{ p: 4, borderRadius: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', mb: 4 }}>
                            <Text as="h4" variant="Poppins16Bold16lh" sx={{ color: theme?.colors?.brown, mb: 2 }}>
                                Tasks
                            </Text>
                            <Text variant="Poppins14Normal24lh" sx={{ color: theme?.colors?.gray, mt: 4 }}>
                                No tasks available.
                            </Text>
                        </Card>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TeamDetailView
import moment from "moment";
import queryString from "query-string";


export const generateQueryString = (...keyValuePairs: [any, any][]): any => {
    const queryParams: { [key: string]: string } = {};
    // console.log(keyValuePairs, "check3");

    keyValuePairs.forEach(([key, value]) => {
        if (key !== '' && key !== null && key !== undefined && value !== '' && value !== null && value !== undefined) {
            queryParams[key] = value;
        }
    });

    const queryString = Object.keys(queryParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');

    return queryString ? "?" + queryString : '';
};
export const generateQueryStringForTable = (...keyValuePairs: [any, any, any][]): any => {
    const queryParams: { [key: string]: string } = {};

    keyValuePairs.forEach(([key, value]) => {
        if (key !== '' && key !== null && key !== undefined && value !== '' && value !== null && value !== undefined) {
            queryParams[key] = value;
        }
    });
    const queryString = Object.keys(queryParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');

    return queryString ? "?" + queryString : '';
};


// export const getApiEndPoint = (pagination: {
//     currentPage?: number;
//     limit?: number;
// }, searchUrl: string): string => {
//     const apiUrl = `${searchUrl ? searchUrl + "&" : "?"}page=${pagination.currentPage || 1}&limit=${pagination.limit || 15}`;
//     return apiUrl;
// };
const startTime = "00:00"; // Default to start of the day
const endTime = "23:59";
export const getApiEndPoint = (pagination: {
    currentPage?: number;
    limit?: number;
    userId?: string;
}, searchUrl: string, column?: { accessorKey: string, filterType: string, header?: any }[], pathname?: string): string => {
    const parsedQuery = queryString.parse(searchUrl);

    const keyValueArray: Array<[string, string]> = Object.entries(parsedQuery)
        .filter(([key, value]) => key && value !== null && key !== "id")
        .map(([key, value]) => [key, value as string]);
    let dynemicFilter: { type: string, value: string, key: string, caseSensitive: boolean, startDate?: string, endDate?: string, min?: string, max?: string, startTime?: string, endTime?: string }[] = [];
    keyValueArray.forEach(([key, value]) => {
        let findColumnData = column?.find((val: { accessorKey: string, filterType: string }) => val?.accessorKey === key)
        if (findColumnData) {
            console.log('chk59', findColumnData?.filterType);

            if (findColumnData?.filterType === "date") {
                let date: string[] = value?.split("to");
                const startDate = findColumnData?.accessorKey === "comment_date" ? moment(date[0].trim()).format("MM/DD/YYYY") : date[0].trim();
                const endDate = findColumnData?.accessorKey === "comment_date" ? moment(date[1].trim()).format("MM/DD/YYYY") : date[1].trim();
                dynemicFilter.push({
                    type: "date",
                    value: value.trim(),
                    key: key,
                    caseSensitive: false,
                    startDate: startDate,
                    endDate: endDate
                })
            } else if (findColumnData?.filterType === "estimateBox") {
                let numberData: string[] = value?.split("to");
                dynemicFilter.push({
                    type: key,
                    value: value.trim(),
                    key: key,
                    caseSensitive: false,
                    min: numberData[0],
                    max: numberData[1]
                })
            }

            else if (findColumnData?.filterType === "timeRangeBox") {
                const timeData: string[] = value?.split("to");

                const StartDateTimeISO = timeData[0]?.trim();
                const EndDateTimeISO = timeData[1]?.trim();

                if (StartDateTimeISO && EndDateTimeISO) {
                    dynemicFilter.push({
                        type: "updatedAt",
                        value: `${StartDateTimeISO}to${EndDateTimeISO}`,
                        key: key,
                        caseSensitive: false,
                        startDate: StartDateTimeISO,
                        endDate: EndDateTimeISO,
                    });
                }
            }


            else if (findColumnData?.filterType === "dropdown" && findColumnData?.header?.toLowerCase() === "project name") {
                dynemicFilter.push({
                    type: "string",
                    value: value,
                    key: "_id",
                    caseSensitive: false
                })
            } else if (pathname === '/generalTimer-report' && findColumnData?.filterType === "dropdown") {
                dynemicFilter.push({
                    type: "array",
                    value: value,
                    key: "_id",
                    caseSensitive: false
                })
            }
            else {
                dynemicFilter.push({
                    type: findColumnData?.filterType === "multi-select-dropdown" ? "array" : findColumnData?.filterType === "date" ? "date" : findColumnData?.filterType === "timeRangeBox" ? "timeRangeBox" : findColumnData?.filterType === "number" ? "number" : "string",
                    value: value,
                    key: findColumnData?.filterType === "multi-select-dropdown" ? (key === "highlighters" || key === "Role" || key === "projectWorker" || key === "worker" || key === "members" || key === "userId") ? `${key}` : `${key}._id` : key,
                    caseSensitive: findColumnData?.filterType === "textbox" ? true : false
                })
            }
        }
    })
    if (pathname === '/stages-view-second' || pathname === '/project-view-two') {
        dynemicFilter.push({
            type: "boolean",
            value: "true",
            key: "isNoFilters",
            caseSensitive: false
        });
    }

    if (pathname === '/completed-todos') {
        dynemicFilter.push({
            type: "string",
            value: "true",
            key: "isCompleted",
            caseSensitive: false
        });
    }
    if (pathname === '/todos' || pathname === '/my-todo' || pathname === '/assigned-todo') {
        dynemicFilter.push({
            type: "string",
            value: "false",
            key: "isCompleted",
            caseSensitive: false
        });
    }
    if ('stageViewTwo' in parsedQuery) {
        dynemicFilter.push({
            type: "array",
            value: `${parsedQuery.taskId}`,
            key: 'taskId',
            caseSensitive: false
        })
    }

    const dynamicFilterQuery: string = dynemicFilter.length > 0 ? `dynamicFilter=${JSON.stringify(dynemicFilter)}` : '';
    const paginationQuery: string = `page=${pagination.currentPage || 1}&limit=${pagination.limit || 15}`;

    return `?${[dynamicFilterQuery, paginationQuery].filter(Boolean).join('&')}`;
};

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const removeDuplicates = (array: any) => {
    const idMap: any = {};
    const result: any = [];

    array.forEach((obj: any) => {
        const taskId = obj.taskId._id;
        if (!idMap[taskId]) {
            idMap[taskId] = true;
            result.push(obj);
        }
    });

    return result;
}
const truncateDescription = (description: string, wordLimit: number = 30): string => {
    const words = description?.split(" ");
    if (words?.length > wordLimit) {
        return words?.slice(0, wordLimit)?.join(" ") + "...";
    }
    return description;
};

export const AllActivities = (
    activity: string,
    changes: any,
    oldData: any,
    allProjectManagers: any[],
    allTaskManagers: any[],
    allProjectWorkers: any[],
    allTaskWorkers: any[],
    allHighlighters: any[],
    allArchitects: any[],
    isModal: boolean = false
) => {

    const formatTime = (ms: number) => {
        const hours = Math.floor(ms / (1000 * 60 * 60));
        const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
        return `${hours}h ${minutes}m`;
    };

    const mapValue = (key: string, value: any) => {

        const isTimeKey = (k: any) => k.toLowerCase().includes('timeRemaining') || k.toLowerCase().includes('timeSpend');
        if (key === 'manager' || key === 'projectManager') {
            const manager = allProjectManagers?.find((pm) => pm._id === value) || allTaskManagers?.find((tm) => tm._id === value);
            return manager ? `${manager.FirstName} ${manager.LastName}` : "-";
        } else if (key === 'worker' || key === 'projectWorker') {
            const worker = allProjectWorkers?.find((w: any) => w._id === value) || allTaskWorkers?.find((tm) => tm._id === value);
            return worker ? `${worker.FirstName} ${worker.LastName}` : "-";
        } else if (key === 'architect') {
            // const architect = allArchitects?.find((arc: any) => arc?._id === value);
            const architect = allArchitects?.find((arc: any) => {
                return (arc?._id === value)
            })
            return architect ? architect?.name : "-";
        } else if (key === 'attachments') {
            return key || '-';
        }
        else if (key === 'highlighters') {
            const highlighterNames = value?.map((id: string) => {
                const highlighter = allHighlighters?.find((hl: any) => hl?._id === id);
                return highlighter ? highlighter.name : "-";
            }).join(", ");
            return highlighterNames;
        } else if (key === 'tasksNameList' && Array?.isArray(value)) {
            const shortForms = value?.map((task: { shortForm: string }) => task?.shortForm).join(' , ');
            return shortForms;
        } else if (key === 'timeRemaining' || key === 'timeSpend') {
            return formatTime(value);
        }
        else if (key === 'tasksNameList.0.timeRemaining') {
            return formatTime(value);
        }
        else if (isTimeKey(key)) {
            return formatTime(value);
        } else if (key === 'stagesNameList' || key === 'stagesData' && Array?.isArray(value)) {
            const stageNames = value?.map((stage: { name: string }) => stage.name)?.join(", ");
            return stageNames;
        }
        else if (key === 'dueDate' || key === 'stageDueDate') {
            return value ? moment(value)?.format('MMM DD, YYYY') : "-";
        }
        else if (typeof value === 'object' && value !== null) {
            return Object.keys(value)?.map((nestedKey) => {

                if (isTimeKey(nestedKey)) {
                    return `${nestedKey}: ${formatTime(value[nestedKey])}`;
                } else if (nestedKey === 'stagesNameList' || nestedKey === 'stagesData' && Array?.isArray(value[nestedKey])) {
                    const stageNames = value[nestedKey]?.map((stage: { name: string }) => stage.name)?.join(", ");
                    return `${nestedKey}: ${stageNames}`;
                } else if (nestedKey === 'description' || nestedKey === 'projectSow.description') {
                    return `Description: ${isModal ? value[nestedKey] : truncateDescription(value[nestedKey])}`;
                }
                else {
                    return `${nestedKey}: ${value[nestedKey] === null || value[nestedKey] === "" || value[nestedKey] === undefined ? "-" : value[nestedKey]}`;
                }
            }).join(', ');
        } else {
            return value === null || value === "" || value === undefined || value === "undefined" ? "-" : value;
        }
    };

    const handleActionUpdate = (changes: any) => {

        // if ('payload.Description' in changes) {
        //     return "Created subtasks for this task";
        // }
        const descriptionKeys = Object.keys(changes).filter(key => key.match(/^payload\.Description\.\d+$/));

        if (descriptionKeys.length > 0 && descriptionKeys.every(key => typeof changes[key] === 'object')) {
            return "Created subtasks for this task";
        }
        const updateObject = changes?.updateObject;
        const name = updateObject?.name || "N/A";
        const updatedFields: string[] = [];

        if (!updateObject) return "";

        Object.keys(changes).forEach((key) => {

            if (key.match(/payload\.Description\.\d+\.updatedBy/)) return;

            const match = key.match(/payload\.Description\.\d+\.(.+)/);
            if (match) {
                const fieldName = match[1];
                const newValue = changes[key];
                updatedFields.push(`${fieldName} to ${newValue}`);
            }
        });


        if (updatedFields.length) {
            return `Updated ${updatedFields.join(", ")} for the subtask: "${name}"`;
        }

        return "";
    };

    const filteredChanges: string[] = [];

    if (changes) {
        Object?.keys(changes)?.forEach((key) => {
            if (key !== 'updatedAt' && key !== 'updatedBy') {
                let displayKey = key.charAt(0).toUpperCase() + key.slice(1);
                const oldValue = oldData?.[key] ? mapValue(key, oldData[key]) : "-";
                const newValue = mapValue(key, changes[key]);

                if (key === 'projectSow' && changes[key]?.description) {
                    const description = changes[key].description;
                    const displayDescription = isModal ? description : truncateDescription(description);
                    filteredChanges.push(`${displayKey}: ${displayDescription}`);
                } else if (key === 'description' || key === 'projectSow.description') {
                    const displayDescription = isModal ? changes[key] : truncateDescription(changes[key]);
                    filteredChanges?.push(`${displayKey} from ${oldValue} to ${displayDescription}`);
                } else if (key === 'attachments' || key === 'estimatedTimeHistory') {
                    filteredChanges.push(`${displayKey}`);
                } else {
                    filteredChanges.push(`${displayKey} from ${oldValue} to ${newValue}`);
                }
            }
        });
    }

    if (!filteredChanges.length) return '';

    switch (activity) {
        case "PROJECT_CREATE":
            return `created this project`;
        case "PROJECT_UPDATE":
            return `updated ${filteredChanges.join(',')} in this project`;
        case "STAGES_CREATE":
            return `created this stage`;
        case "STAGES_UPDATE":
            return `updated ${filteredChanges.join(',')} in this stage`;
        case "TASK_CREATE":
            return `created this task`;
        case "TASK_UPDATE":
            return `updated ${filteredChanges.join(',')} in this task`;
        case "ACTION_UPDATE":
            return handleActionUpdate(changes);
        default:
            return "";
    }
};


export function convertMillisecondsToHoursMinutesSeconds(milliseconds: number) {
    let seconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(seconds / 86400);
    seconds %= 86400;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return { days, hours, minutes, seconds };
}

export function msToTime(duration: number) {
    let seconds: any = Math.floor(duration / 1000); // Convert milliseconds to seconds
    let hours: any = Math.floor(seconds / 3600); // Extract hours
    seconds = seconds % 3600; // Remaining seconds after extracting hours
    let minutes: any = Math.floor(seconds / 60); // Extract minutes
    seconds = seconds % 60; // Remaining seconds after extracting minutes
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    return { hours, minutes, seconds };
}

export function millisecondsToTime(milliseconds: any) {
    if (milliseconds < 0) {
        milliseconds = 0;
    }
    const duration = moment.duration(milliseconds);

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const formattedTime = moment({ hours, minutes, seconds }).format('HH:mm:ss');

    return formattedTime;
}

export const getTimeDifference = (startTime: number): number => {
    // Current time in milliseconds
    const currentMilliseconds = Date.now();
    // Calculate the difference in milliseconds
    const differenceMilliseconds = currentMilliseconds - startTime;

    // console.log(differenceMilliseconds, "check75", millisecondsToTime(differenceMilliseconds), startTime);
    return differenceMilliseconds
}
export const convertMsToTime = (ms: number) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
};
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateDTPM } from '../../../store/actions/frontApp.Action';
interface CheckboxProps {
    originalRow: any;
    checkboxType: string;
}
interface UpdateStatusPayload {
    // comment_id: any;
    DT?: string;
    PM?: string;
}
const CheckboxInput: React.FC<CheckboxProps> = ({ originalRow, checkboxType }) => {
    const dispatch = useDispatch();
    const [statusChecked, setStatusChecked] = useState(false);
    useEffect(() => {
        setStatusChecked(originalRow[checkboxType] === 'Completed');
    }, [originalRow[checkboxType]]);
    const handleCheckboxChange = () => {
        setStatusChecked(!statusChecked);
        const newValue = statusChecked ? 'NotCompleted' : 'Completed';
        updateStatus(newValue);
    };

    const updateStatus = (value: string) => {
        const updateStatusPayload: UpdateStatusPayload = {
            DT: checkboxType === 'DT' ? value : undefined,
            PM: checkboxType === 'PM' ? value : undefined
        };

        if (checkboxType === 'DT') {
            delete updateStatusPayload.PM;
        } else if (checkboxType === 'PM') {
            delete updateStatusPayload.DT;
        }
        const comment_id = originalRow?.comment_id
        // console.log("updateStatusPayload", updateStatusPayload);
        dispatch(updateDTPM(updateStatusPayload, comment_id,))
    };



    // dispatch(TableServices.updateDTPMCheckboxStatus(updateStatusPayload, paginationOptions, filtersToApply));
    // console.log("paginationOptions", paginationOptions);
    // console.log("filtersToApply//////", filtersToApply);
    // };

    return (
        <input
            className="form-check-input"
            type="checkbox"
            style={{ color: '#216ba5' }}
            checked={statusChecked}
            onChange={handleCheckboxChange}
        />

    );
}

export default CheckboxInput;

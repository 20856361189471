import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Avatar, Badge, Box, Button, Divider, Image, MenuButton, Paragraph, Spinner, Text } from "theme-ui";
import { GetLatestTimeById, GetTimeById, getAllSubtask, getAllSubtaskSummary, getHistoryByView, getAllSubtaskSummarySuccess, needInfo, getAllSubtasksOfSameTask, getAllDuplicateSubtasks } from "../../store/actions/project.action";
import activity from "../assets/images/Icon/activity.svg";
import spent from "../assets/images/Icon/spent.svg";
import taskIcon from "../assets/images/Icon/frontapp.svg";
import deleteIcon from "../../Components/assets/images/Icon/delete.svg";
import notification1 from "../assets/images/Notification1.svg";

import AddAction from "../Actions/AddAction";
import ThreadModalPop from "../ThreadModalPop/ThreadModalPop";
import { AllActivities } from "../../utils/GenerateQueryString";
import { bucketUrl } from "../../utils/global";
import { RootState } from "../../../@types";
import { Inputform } from "../forms-component/InputForm";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { FaPlus } from "react-icons/fa";
import { addDescriptionActionHighlight, addDescriptionBulkActionHighlight, createActionHighlight, deleteMultipleActionHighlight, getActionByName, getActionHighlighterIds, getAllTemplateChecklist } from "../../store/actions/action.Highlighters";
import HistoryAction from "./HistoryAction";
import { ActionCard } from "../Actions/ActionModule/SubTaskActionCard";
import { deleteTask } from "../../store/actions/masterData.action";
import FormSelect from "../forms-component/globalSelect";
import theme from "../../theme";
import clockleft from "../../Components/assets/images/clock-left.svg";

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import InfiniteScroll from "react-infinite-scroll-component";
import ActivityHistoryModal from "../pages/demotable/ActivityHistoryModal";
import ActivityRevertHistory from "../pages/demotable/ActivityRevertHistory";

// import { Tabs, TabList, Tab, TabPanel } from 'theme-ui';


export interface IChecklistTemplate {
    checklistItems: string[];
    showInActions: string[];
    _id: string;
    templateName: string;
    checklistItem: string[];
    showInAction: string[];
}
interface Option {
    label: string;
    value: IChecklistTemplate;
}


const ALL_SUBTASK_KEY: Record<string, string> = {
    BCs: "BC",
    PCs: "PC",
    Revs: "Revision",
    allSubtask: "Subtask",
    allNewNeedInfoAdded: "New Need Info Added",
    allBlockedByTrade: "Blocked By Trade",
};


const SideActions: React.FC<any> = ({ isTracking, elapsedTime, timeTaken, timeEstimate, taskId, stageId, projectId, threads, timeStatusColor }) => {

    const { historyView, spentTime, Latesttime, tasksView, AllSubtask, allSubtaskSummary, allSubtaskSametask, allDuplicateSubtasks, stagebyproject } = useSelector((state: any) => state.project);
    const { auther } = useSelector((state: RootState) => state.auth);
    const [showAll, setShowAll] = useState(false);
    const [items, setItems] = useState<any[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const limit = 25;

    // const { taskId } = useParams<{ taskId: string }>();
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('activity');
    const [selectedStageTab, setSelectedStageTab] = useState('Prelim');
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

    const [textareaValue, setTextareaValue] = useState("");
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const [taskmodalOpen, setIsTaskModalOpen] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const is_Project_Worker = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "project worker");
    const is_Project_Manager = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "project manager");
    const is_Super_Admin = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "super admin");
    const [showConfirmationTask, setShowConfirmationTask] = useState<boolean>(false);
    const [currentStageId, setCurrentStageId] = useState<string | null>(null);
    const totalMilliseconds = spentTime?.totalTimeOfTodoPerReview;
    const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((totalMilliseconds % (1000 * 60)) / 1000);
    const [deleteIdList, setDeleteIdList] = useState<string[]>([]);
    const allowRoles = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "super admin" || role.toLocaleLowerCase() === "Region Admin");

    const timeInHoursMinutesSeconds =
        (hours > 0 ? `${hours}h ` : '') +
        `${minutes}m ${seconds.toString().padStart(2, '0')}s`;
    const handleTabClick = (tab: any) => {
        setSelectedTab(tab);
    };
    const toggleModal = () => {
        setIsTaskModalOpen(!taskmodalOpen);
    };
    const toggleActivityModal = () => {
        setModalOpen(!modalOpen);
    };
    const toggleHistoryModal = () => setHistoryModalOpen(!historyModalOpen);

    const toggleConfirmation = () => setShowConfirmation((prev) => !prev);

    const cancelMultipleDeleteTask = () => {
        setShowConfirmationTask(false);
    };
    useEffect(() => {
        dispatch(getHistoryByView(`taskId=${taskId}&page=${pageNumber}&limit=${limit}`));
    }, [dispatch, pageNumber]);

    useEffect(() => {
        if (historyView?.data?.length) {
            if (pageNumber === 1) {
                setItems(historyView?.data);
            } else {
                setItems((prevItems) => [...prevItems, ...historyView?.data]);
            }

            if (historyView?.count) {
                setTotalItems(historyView?.count);
            }

            if (historyView?.data?.length < limit || items.length >= historyView?.count) {
                setHasMore(false);
            }
        }
    }, [historyView?.data, pageNumber, taskId]);

    const fetchMoreData = () => {
        if (items?.length >= totalItems) {
            setHasMore(false);
            return;
        }
        const nextPage = pageNumber + 1;
        setPageNumber(nextPage);
        dispatch(getHistoryByView(`taskId=${taskId}&page=${nextPage}&limit=${limit}`));
    };
    const uniqueItems = useMemo(() => {
        const seen = new Set();
        return items?.filter((history: any) => {
            const identifier = history?.changes?.updatedAt || history?.changes?.updateObject?.updatedAt; // You can use other identifiers like history.id
            if (seen.has(identifier)) {
                return false;
            } else {
                seen.add(identifier);
                return true;
            }
        });
    }, [items]);
    useEffect(() => {
        dispatch(GetTimeById(taskId));
        dispatch(GetLatestTimeById(taskId))
        // dispatch(getHistoryByView(`taskId=${taskId}`))
        // if (projectId) {
        //     dispatch(getStageByProjectId(projectId))

        // }
    }, [dispatch, tasksView])
    useEffect(() => {
        if (isTracking) {
            setSelectedTab('spent');
        }
    }, [isTracking]);
    function convertMillisecondsToHoursMinutesSeconds(milliseconds: number) {
        let seconds = Math.floor(milliseconds / 1000);
        const days = Math.floor(seconds / 86400);
        seconds %= 86400;
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;
        return { days, hours, minutes, seconds };
    }

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextareaValue(event.target.value);
    };
    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };
    const handleSend = () => {
        const payload = {
            actionType: "NEED_INFO",
            payload: {
                description: textareaValue
            }
        }
        dispatch(needInfo(payload, taskId))
        setIsPopoverOpen(false);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLImageElement>) => {
        setIsPopoverOpen(true);
    };

    const { loader, templateList, actionHighlights, createHighlight, } = useSelector((state: any) => state.actionHighlight)


    // Subtask Implement
    const [selectedTemplates, setSelectedTemplates] = useState<any>();
    const templateOptions: any = templateList?.map((template: IChecklistTemplate) => ({
        label: template.templateName,
        value: template._id,
        checklistItem: template?.checklistItem
    }));

    const initialValues = {
        name: ""
    }

    const formik = useFormik(
        {
            enableReinitialize: true,
            initialValues,
            validationSchema: Yup.object({
                name: Yup.string().required('Check list item is required'),
            }),
            onSubmit: (value: any) => {
                value["ischecked"] = false;
                value["isReviewed"] = false;
                value["isDone"] = false;
                if (actionHighlights?._id) {

                    const updateData = {
                        data: value,
                        ...actionObject,
                        actionType: "Subtask",
                    }
                    dispatch(addDescriptionActionHighlight(updateData, actionHighlights?._id))
                } else {
                    const payload = {
                        actionType: "Subtask",
                        payload: {
                            Description: [value]
                        },
                        ...actionObject
                    }
                    dispatch(createActionHighlight(payload))
                }
                formik.resetForm()
            }
        }
    );

    const { handleSubmit, setFieldValue, values, handleBlur, touched, errors } = formik;
    let actionObject: any = { projectId: projectId, stageId: tasksView?.stageDataId, taskId }

    const handleTemplateChange = (selected: any) => {
        const selectedTemplates = {
            _id: selected?.value?._id,
            name: selected?.value?.templateName,
        };
        const data = selected?.checklistItem.map((template: any) => ({
            comment: [],
            name: template,
            isChecked: false,
            isReviewed: false,
            isDone: false,
        }));

        const updateData = {
            data,
            ...actionObject,
            actionType: 'Subtask',
        };

        dispatch(addDescriptionBulkActionHighlight(updateData, actionHighlights?._id));

        setSelectedTemplates(selectedTemplates)

    };
    const handleDeleteTask = () => {
        setShowConfirmation(true);
    };

    const confirmDeleteTask = () => {
        dispatch(deleteTask(taskId));
        setShowConfirmation(false);
    };

    const cancelDeleteTask = () => {
        setShowConfirmation(false);
    };
    useEffect(() => {
        if (selectedTab === 'Subtask') {
            dispatch(getActionHighlighterIds(actionObject, { name: "Subtask" }))
            dispatch(getAllSubtask(actionObject))
            dispatch(getAllSubtaskSummary(actionObject))
            dispatch(getAllDuplicateSubtasks(taskId))
        }
    }, [selectedTab, createHighlight])
    console.log('chk293', selectedTab);

    useEffect(() => {
        if (selectedTab === 'Subtask') {

            const payload = { actionType: "Subtask", payload: { Description: [] }, ...actionObject }
            if (!actionHighlights?._id || actionHighlights?._id == undefined) {
                dispatch(createActionHighlight(payload))
            }
            dispatch(getActionByName(actionObject, { name: "Subtask" }))
            dispatch(getAllTemplateChecklist({ query: "subtasks" }))
            dispatch(getAllDuplicateSubtasks(taskId))
            dispatch(getAllSubtasksOfSameTask(actionObject));

        }
    }, [selectedTab, taskId, stageId])
    useEffect(() => {
        if (selectedTab === 'activity') {
            dispatch(getHistoryByView(`taskId=${taskId}&page=${pageNumber}&limit=${limit}`));

        }
    }, [taskId, pageNumber, limit, selectedTab])
    let uniqueKeyCounter = 0;


    useEffect(() => {
        return (() => {
            dispatch(getAllSubtaskSummarySuccess([]))
        })
    }, [])

    const updatedSubtasks = Array.isArray(allDuplicateSubtasks?.data?.finalData)
        ? allDuplicateSubtasks?.data?.finalData?.map((subtask: any) => ({
            ...subtask,
            stageName: subtask.stageName || subtask.stageId,
        }))
        : [];

    const uniqueStageNames = new Set<string>();
    updatedSubtasks.forEach((subtask: any) => {
        if (subtask?.stageName) {
            uniqueStageNames.add(subtask?.stageName);
        }
    });
    const dynamicStageNames = Array.from(uniqueStageNames).sort();
    // const stageNames = [...dynamicStageNames, 'All Subtasks'];
    const filteredSubtasks = updatedSubtasks.filter((subtask: any) => subtask?.stageName);
    const stageNames = ['All Subtasks', 'Others'];
    if (filteredSubtasks.length > 0) {
        stageNames.push(...dynamicStageNames);
    }
    // const stageNames = ["Prelim", "Full", "Rev"];
    const [activeTab, setActiveTab] = useState(() => {
        const matchedSubtask = updatedSubtasks?.find(
            (subtask: any) => subtask?.stageId === tasksView?.stageDataId
        );

        return matchedSubtask ? stageNames?.indexOf(matchedSubtask?.stageName) : 0;
    });

    const handleDeleteCheckboxChange = (index: number, isChecked: boolean, deleteID: string, action: any) => {
        // if (isChecked) {
        //     let deleteAction = [...deleteIdList]
        //     deleteAction.push(deleteID)
        //     setDeleteIdList([...deleteAction]);
        // }

        setDeleteIdList(prev => {

            let updatedDeleteData;
            if (isChecked) {
                updatedDeleteData = [...prev, deleteID];
            } else {
                updatedDeleteData = prev.filter(id => id !== deleteID);
            }
            return updatedDeleteData;
        });
    };

    const handleTabChange = (index: number) => {
        setActiveTab(index);
        const selectedStageName = stageNames[index];
    };


    const handleDeleteSubtasks = () => {
        setShowConfirmationTask(true)
    }

    const handleConfirmDeleteSelectedTasks = () => {
        const selectedStageName = stageNames[activeTab];
        const stageSubtasks = updatedSubtasks.filter((subtask: any) => subtask?.stageName === selectedStageName);
        if (stageSubtasks?.length > 0 && stageSubtasks[0]?._id) {
            dispatch(deleteMultipleActionHighlight({ subtaskIds: deleteIdList }, stageSubtasks[0]?._id || actionHighlights?._id));
        }
        else {
            dispatch(deleteMultipleActionHighlight({ subtaskIds: deleteIdList }, actionHighlights?._id))
        }
        setShowConfirmationTask(false);
    };
    return (
        <>
            <div className='col-md-3 p-3' style={{ maxHeight: "845px", borderLeft: `2px solid ${theme?.colors?.lightbackground}`, overflowY: "auto" }}  >
                {selectedTab === 'activity' && (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <h5>Activity</h5>
                                <p className='text-muted'>All Activities</p>
                            </div>
                            <div>
                                {allowRoles && (<Image src={clockleft} alt={'History'} style={{ width: 25, marginRight: '15px', cursor: 'pointer' }} onClick={toggleHistoryModal} />)}

                                <Image src={notification1} alt={'History'} style={{ width: 30, cursor: 'pointer' }} onClick={toggleActivityModal} />
                            </div>

                        </div>
                        <div>
                            <InfiniteScroll
                                dataLength={uniqueItems?.length || 0}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={''}
                                height={600}
                                endMessage={<p ></p>}

                            >
                                {uniqueItems?.map((history: any, index: number) => {

                                    // Find the dynamic key that matches 'payload.Description.<number>'
                                    const dynamicKey = Object.keys(history?.changes || {}).find(key => key.match(/^payload\.Description\.\d+$/));

                                    // Determine the createdBy value based on the conditions
                                    const createdBy = dynamicKey && history?.changes[dynamicKey]
                                        ? history?.changes[dynamicKey]?.createdBy
                                        : (history?.changes?.updateObject?.createdBy ||
                                            history?.changes?.updateObject?.updatedBy ||
                                            history?.changes?.updatedBy ||
                                            (Array.isArray(history?.changes?.payload?.Description)
                                                ? history?.changes?.payload?.Description[0]?.createdBy
                                                : 'Someone'));
                                    return (
                                        <React.Fragment key={index}>
                                            <Paragraph style={{ gap: 2 }} variant="activityText">
                                                <strong>{createdBy}</strong>
                                                {AllActivities(
                                                    history?.action,
                                                    history?.changes,
                                                    history?.oldData,
                                                    dataForTable?.data?.allProjectManagers,
                                                    dataForTable?.data?.allTaskManagers,
                                                    dataForTable?.data?.allProjectWorkers,
                                                    dataForTable?.data?.allTaskWorkers,
                                                    dataForTable?.data?.allHighlighters,
                                                    dataForTable?.data?.allArchitects
                                                )}
                                            </Paragraph>
                                            <Paragraph sx={{ textAlign: "end", fontSize: "10px", color: "#D3D5D9", marginBottom: "0px" }}>
                                                {moment(history?.createdAt).format('MMM DD, LT')}
                                            </Paragraph>
                                        </React.Fragment>
                                    );
                                })}
                            </InfiniteScroll>
                            {/* <div>
                                {historyView?.length > 0 && historyView?.map((history: any, index: number) => <React.Fragment key={index}><Paragraph style={{ gap: 2 }} variant="activityText"><strong>{history?.changes?.updatedBy || 'Someone'}</strong>{allActivities(history?.action, history?.changes)}</Paragraph><Paragraph sx={{ textAlign: "end", fontSize: "10px", color: "#D3D5D9", marginBottom: "0px" }}>{moment(history?.createdAt).format('MMM DD, LT')}</Paragraph ></React.Fragment>)}
                            </div> */}
                        </div>
                        <ActivityRevertHistory isOpen={historyModalOpen} toggle={toggleHistoryModal} items={items}
                            hasMore={hasMore}
                            fetchMoreData={fetchMoreData}
                            dataForTable={dataForTable}
                            view="Task"
                            taskId={taskId}
                        />
                    </>
                )}
                {selectedTab === 'Subtask' && (
                    <>
                        <div>
                            <h5>Subtask</h5>
                        </div>
                        <div>

                            <form onSubmit={handleSubmit}>
                                <Box sx={{ paddingX: "2rem", mt: "1.5rem", position: "relative", display: 'flex' }}>
                                    <Inputform
                                        label={"Create Subtask"}
                                        Placeholder={"Enter Subtask..."}
                                        Name={"name"}
                                        Title_sx={{ color: "grey3" }}
                                        touched={touched?.name}
                                        errors={errors?.name}
                                        values={values.name}
                                        Max_Length={10}
                                        onBlur={handleBlur}
                                        OnChange={(event: any) => {
                                            const newSubtask = event.target.value;
                                            setFieldValue("name", newSubtask, true);
                                        }}
                                    />
                                    <Button type='submit' sx={{ display: "flex", alignItems: "center", position: "absolute", right: "30px", top: "50px", borderRadius: "8px", paddingLeft: "12px" }}><FaPlus className="mx-1" />Add</Button>

                                </Box>
                            </form>
                            <Divider sx={{ mt: "1.5rem" }} />
                            <Box sx={{ display: "grid", gap: "1rem", position: "relative" }}>
                                <Box className="row">
                                    <Box className="col-6">
                                        <Paragraph sx={{ fontSize: "18px", fontWeight: "600", mb: "0.5rem" }}>All subtask</Paragraph>
                                    </Box>

                                    {/* {['Background Change', 'Plan Check', 'Revision']?.includes(selectedHighlight?.name) && ( */}

                                    <Box className="col-12">
                                        <FormSelect
                                            placeholder="Predefined Checklist"
                                            options={templateOptions}
                                            value={templateOptions?.find((option: any) => selectedTemplates?._id === option.value)}
                                            // onChange={""}
                                            onChange={handleTemplateChange}
                                        />
                                    </Box>
                                    {/* )} */}

                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                    <Button onClick={handleDeleteSubtasks}>Delete</Button>
                                </Box>

                                <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
                                    <TabList >
                                        {stageNames?.map((stageName) => (
                                            <Tab style={{ flex: '0 0 auto', minWidth: '100px' }} key={stageName}>{stageName}</Tab>
                                        ))}
                                    </TabList>

                                    {stageNames?.map((stageName: string, stageIndex: number) => {
                                        if (stageName === 'All Subtasks') {
                                            return (
                                                <TabPanel key={stageIndex}>
                                                    {AllSubtask?.allSubtask?.length > 0 ? (
                                                        AllSubtask?.allSubtask?.map((item: any, index: number) => (
                                                            <div key={index}>
                                                                {/* Render your task descriptions here */}
                                                                {Object.keys(item)?.map((action: any) =>
                                                                    item[action]?.Description?.map((description: any, desIndex: number) => (
                                                                        <React.Fragment key={desIndex}>
                                                                            <ActionCard
                                                                                key={uniqueKeyCounter++}
                                                                                index={uniqueKeyCounter}
                                                                                action={description}
                                                                                comment={false}
                                                                                keys={{ first: "isDone", third: "isReviewed" }}
                                                                                actionObject={
                                                                                    item?.actionType !== "Subtask" ? { ...actionObject, taskId: null } : actionObject
                                                                                }
                                                                                parentData={item}
                                                                                parentName={"Sub"}
                                                                                handleDeleteCheckboxChange={handleDeleteCheckboxChange}
                                                                                deleteIdList={deleteIdList}
                                                                            />
                                                                            <Paragraph
                                                                                sx={{
                                                                                    textAlign: 'end',
                                                                                    fontSize: '10px',
                                                                                    opacity: '0.5',
                                                                                    marginBottom: '0px',
                                                                                }}
                                                                            >
                                                                                {description?.updatedAt && moment(description?.updatedAt).format('MMM DD, LT')}
                                                                            </Paragraph>
                                                                        </React.Fragment>
                                                                    ))
                                                                )}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No All Subtasks available.</p>
                                                    )}
                                                </TabPanel>
                                            );
                                        } else if (stageName === 'Others') {
                                            // Filter the subtasks for the Others category
                                            const othersCategories: Record<string, any[]> = {
                                                BCs: AllSubtask.BCs,
                                                PCs: AllSubtask.PCs,
                                                Revs: AllSubtask.Revs,
                                                BlockedByTrade: AllSubtask.allBlockedByTrade,
                                                NewInfoAdded: AllSubtask.allNewNeedInfoAdded
                                            };

                                            return (
                                                <TabPanel key={stageIndex}>
                                                    {Object.keys(othersCategories)?.map((categoryKey: any) => {
                                                        const tasks = othersCategories[categoryKey];

                                                        if (!tasks || tasks.length === 0) {
                                                            return null;
                                                        }

                                                        return (
                                                            <div key={categoryKey}>
                                                                <p className={categoryKey}> {categoryKey}</p>
                                                                <Divider />

                                                                {tasks.map((subtask: any, subtaskIndex: number) => (
                                                                    <div key={subtaskIndex}>
                                                                        {subtask?.payload?.Description?.length > 0 ? (
                                                                            subtask?.payload?.Description?.map((description: any, desIndex: number) => (
                                                                                <React.Fragment key={desIndex}>
                                                                                    <ActionCard
                                                                                        key={desIndex}
                                                                                        index={desIndex}
                                                                                        action={description}
                                                                                        comment={false}
                                                                                        selectedHighlight={subtask?.actionType}
                                                                                        keys={{ first: "isDone", third: "isReviewed" }}
                                                                                        actionObject={actionObject}
                                                                                        parentData={subtask}
                                                                                        actionId={subtask?._id}
                                                                                        handleDeleteCheckboxChange={handleDeleteCheckboxChange}
                                                                                        deleteIdList={deleteIdList}
                                                                                        isOthersTab={true}
                                                                                        isShowDeleteCheckbox={false}
                                                                                    />
                                                                                    <Paragraph
                                                                                        sx={{
                                                                                            textAlign: 'end',
                                                                                            fontSize: '10px',
                                                                                            opacity: '0.5',
                                                                                            marginBottom: '0px',
                                                                                        }}
                                                                                    >
                                                                                        {description?.updatedAt && moment(description?.updatedAt).format('MMM DD, LT')}
                                                                                    </Paragraph>
                                                                                </React.Fragment>
                                                                            ))
                                                                        ) : (
                                                                            <p>{`No subtasks available in ${categoryKey}.`}</p>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        );
                                                    })}
                                                </TabPanel>
                                            );
                                        } else {
                                            // Existing logic for other stage names
                                            let filteredStageSubtasks = updatedSubtasks.filter((subtask: any) => subtask?.stageName === stageName);

                                            return (
                                                <TabPanel key={stageIndex}>
                                                    {filteredStageSubtasks.length > 0 ? (
                                                        filteredStageSubtasks.map((subtask: any, subtaskIndex: number) => (
                                                            <div key={subtaskIndex}>
                                                                {subtask?.payload?.Description?.length > 0 ? (
                                                                    subtask?.payload?.Description.map((description: any, desIndex: number) => (
                                                                        <ActionCard
                                                                            key={desIndex}
                                                                            index={desIndex}
                                                                            action={description}
                                                                            comment={false}
                                                                            selectedHighlight={subtask?.actionType}
                                                                            keys={{ first: "isDone", third: "isReviewed" }}
                                                                            actionObject={actionObject}
                                                                            parentData={subtask}
                                                                            actionId={subtask?._id}
                                                                            handleDeleteCheckboxChange={handleDeleteCheckboxChange}
                                                                            deleteIdList={deleteIdList}
                                                                        />
                                                                    ))
                                                                ) : (
                                                                    <p>{`No ${stageName} subtasks available.`}</p>
                                                                )}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>{`No ${stageName} subtasks available.`}</p>
                                                    )}
                                                </TabPanel>
                                            );
                                        }
                                    })}

                                </Tabs>
                                {loader && <Box sx={{ position: "absolute", inset: "0", width: "100%", height: "100%", backgroundColor: 'rgba(255, 255, 255, 0.5)', display: "grid", placeContent: "center" }}><Spinner /></Box>}
                            </Box>
                            <Modal isOpen={showConfirmationTask} toggle={cancelMultipleDeleteTask} size="sm" centered>
                                <ModalHeader toggle={cancelMultipleDeleteTask}>Confirm Deletion</ModalHeader>
                                <ModalBody>
                                    {`Are you sure you want to delete the selected tasks?`}
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="CancelButton" sx={{ mr: 2, cursor: 'pointer' }} onClick={cancelMultipleDeleteTask}>No</Button>
                                    <Button
                                        type="button"
                                        variant="SaveButton"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={handleConfirmDeleteSelectedTasks}
                                    >
                                        Yes
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </>
                )}
                {selectedTab === 'thread' && (
                    <div>
                        <h5>Chat section</h5>
                        <p className='text-muted'>All Chats</p>
                    </div>
                )}
                {selectedTab === 'spent' && (
                    <>
                        <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <div className="text-left">
                                <h5>Time Tracking</h5>
                                <p className='text-muted'>Spend history</p>
                            </div>

                            <div className="d-flex flex-column align-items-end">
                                <MenuButton aria-label="Toggle Menu" />
                                <Text className="text-muted" >
                                    Total TDPR: <Text as="span" style={{ fontWeight: 'bold', color: 'black' }}>{timeInHoursMinutesSeconds}</Text>
                                </Text>
                            </div>
                        </div>
                        <div className="text-center mt-2">
                            {(isTracking !== null && Latesttime?.userId?._id === auther?._id) && <>
                                <p>Tracking Started</p>
                                <h1 style={{ color: `${timeStatusColor}` }}>{elapsedTime}</h1>
                            </>
                            }

                        </div>

                        <hr />

                        <div className="container-fluid">
                            <div className="row mb-4">
                                <div className="col-md-8"><h5>Last Actions<Badge variant='circle' mt={-3}>{spentTime?.data?.length}</Badge></h5></div>
                                <div className="col-md-4">
                                    {spentTime?.data?.length > 5 && (
                                        <div>
                                            {showAll ? (
                                                <Button variant="spentButton" onClick={() => setShowAll(false)}>Show Less</Button>
                                            ) : (
                                                <Button variant="spentButton" onClick={() => setShowAll(true)}>Show All</Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{ maxHeight: '675px', overflow: 'auto' }}>
                                {showAll ? (
                                    spentTime?.data?.filter((time: { timeTaken: any; }) => time?.timeTaken).map((time: any, index: React.Key | null | undefined) => {
                                        const { hours, minutes, seconds, days } = convertMillisecondsToHoursMinutesSeconds(time?.timeTaken);
                                        const formattedDay = days > 0 ? `${days}d` : "";
                                        const formattedHours = hours > 0 ? `${hours}h` : "";
                                        const formattedMinutes = minutes > 0 ? `${minutes}m` : "";
                                        const formattedSeconds = seconds > 0 ? `${seconds}s` : "";
                                        return (
                                            <div className="spenttime" key={index}>
                                                <div className="conatainer-fluid">
                                                    <div className="row">
                                                        <div className="col-md-7 d-flex">
                                                            <Avatar src={`${bucketUrl}${time?.userId?.ProfileImage}`} alt="" variant="avatar" /> &nbsp;<p>{time?.userId?.FirstName} {time?.userId?.LastName}</p>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="d-flex">{formattedDay}{" "}{formattedHours}{" "}{formattedMinutes}{" "}{formattedSeconds}</p>
                                                            <p className="mt-0">{moment(time?.createdAt).format('ll')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    spentTime?.data?.filter((time: { timeTaken: any; }) => time?.timeTaken).slice(0, 5).map((time: any, index: React.Key | null | undefined) => {
                                        const { hours, minutes, seconds, days } = convertMillisecondsToHoursMinutesSeconds(time?.timeTaken);
                                        const formattedDay = days > 0 ? `${days}d` : "";
                                        const formattedHours = hours > 0 ? `${hours}h` : "";
                                        const formattedMinutes = minutes > 0 ? `${minutes}m` : "";
                                        const formattedSeconds = seconds > 0 ? `${seconds}s` : "";
                                        return (
                                            <div className="spenttime" key={index}>
                                                <div className="conatainer-fluid">
                                                    <div className="row">
                                                        <div className="col-md-2 m-0 d-flex">
                                                            <Image src={`${bucketUrl}${time?.userId?.ProfileImage}`} alt="" style={{ width: "100%", height: "45%" }} variant="avatar" />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="mb-0">{time?.userId?.FirstName} {time?.userId?.LastName}</p>
                                                            <p className="text-muted mt-0">{time?.description}</p>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="d-flex">{formattedDay}{" "}{formattedHours}{" "}{formattedMinutes}{" "}{formattedSeconds}</p>
                                                            <p className="mt-0">{moment(time?.createdAt).format('ll')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>

                        </div>
                    </>
                )}
                {selectedTab === 'action' && (
                    <div>
                        <h5>Actions</h5>
                        <p className='text-muted'>All Actions</p>
                    </div>
                )}
            </div>
            <ActivityHistoryModal
                isOpen={modalOpen}
                toggle={toggleActivityModal}
                items={items}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                dataForTable={dataForTable}
            />
            <div className=' text-center col-md-1 p-3 ' style={{ borderLeft: `2px solid ${theme?.colors?.lightbackground}` }}>
                <div onClick={() => handleTabClick('activity')}>
                    <button className='btn' style={{ backgroundColor: selectedTab === 'activity' ? `${theme?.colors?.lightbackground}` : 'inherit' }}>
                        <Image src={activity} alt='' style={{ fill: selectedTab === 'activity' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                    </button>
                </div>
                <Text className='mt-1' sx={{ cursor: 'pointer' }}>Activity</Text>

                <ThreadModalPop handleTabClick={handleTabClick} selectedTab={selectedTab} phaseId={taskId} level="task" threads={threads} />
                <div onClick={() => handleTabClick('spent')} >
                    <button className='btn ' style={{ backgroundColor: selectedTab === 'spent' ? `${theme?.colors?.lightbackground}` : 'inherit' }}>
                        <Image src={spent} alt='' style={{ fill: selectedTab === 'spent' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                    </button>
                    <p className='mt-1' style={{ cursor: 'pointer' }}>Spent History</p>
                </div>
                <div onClick={() => handleTabClick('Subtask')} >
                    <button className='btn ' style={{ backgroundColor: selectedTab === 'Subtask' ? `${theme?.colors?.lightbackground}` : 'inherit', position: 'relative' }}>
                        <Image src={taskIcon} alt='' style={{ fill: selectedTab === 'Subtask' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                        {allSubtaskSummary?.totalIsDoneFalse >= 0 && (
                            <Badge className="px-2" sx={{ position: 'absolute', top: '-4px', right: '-14px', borderRadius: '15px', backgroundColor: `${theme?.colors?.green}` }}>
                                {allSubtaskSummary?.totalIsDoneFalse}
                            </Badge>
                        )}
                        {allSubtaskSummary?.totalIsReviewedFalse >= 0 && (
                            <Badge className="px-2" sx={{ position: 'absolute', top: '21px', right: '-15px', borderRadius: '15px', backgroundColor: `${theme?.colors?.musterd}` }}>
                                {allSubtaskSummary?.totalIsReviewedFalse}
                            </Badge>
                        )}
                    </button>
                    <p className='mt-1' style={{ cursor: 'pointer' }}>Subtask</p>
                </div>
                <HistoryAction selectedTab={selectedTab} stageDataId={stageId} projectId={projectId} taskId={taskId} viewtask='task' />

                <AddAction selectedTab={selectedTab} taskId={taskId} handleTabClick={handleTabClick} />


                {is_Project_Worker || is_Project_Manager || is_Super_Admin ? (
                    <Box sx={{ alignItems: 'center', cursor: 'pointer' }} className="mt-5">
                        <img src={deleteIcon} alt='Delete' onClick={handleDeleteTask} style={{ height: '25px', width: '25px' }} />
                    </Box>
                ) : null}
            </div>

            <Modal isOpen={isPopoverOpen} toggle={togglePopover} centered>
                <ModalHeader>Need Info</ModalHeader>
                <ModalBody>
                    <textarea className="form-control" placeholder="enter need info..." value={textareaValue} onChange={handleTextareaChange}></textarea>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleSend}>Send</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={showConfirmation} toggle={cancelDeleteTask} size="sm" centered>
                <ModalHeader toggle={cancelDeleteTask} >Confirm Deletion</ModalHeader>
                <ModalBody>
                    {` Are you sure you want to delete ${tasksView?.taskId?.name} task?`}
                </ModalBody>
                <ModalFooter>
                    <Button variant="CancelButton" sx={{ mr: 2, cursor: 'pointer' }} onClick={cancelDeleteTask}>No</Button>
                    <Button type="button" variant="SaveButton" sx={{ cursor: 'pointer' }} onClick={confirmDeleteTask}>Yes</Button>
                </ModalFooter>

            </Modal>
        </>
    )
};
export default SideActions;

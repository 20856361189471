/* eslint-disable no-unused-expressions */
import { toast } from 'react-hot-toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { emailVerifyFailure, emailVerifySuccess, forgetPasswordFailure, forgotpasswordSuccess, getUserbyTokenSuccess, loginUserFailure, loginUserSuccess } from '../actions/login.action';
import { emailVerifyApi, forgotPasswordApi, getUserbyTokenApi, loginApi } from '../api';
import { FORGOT_PASSWORD, GET_EMAIL_VERIFY, GET_USER_BY_TOKEN, STORE_LOGIN } from '../storeTypes';

function* UserloginSaga(action) {
    try {
        const response = yield call(loginApi, action.payload);
        if (response?.data?.data) {
            action.callBack(response.data)
            const token = response.data.token;
            toast.success(response.data.message)
            if (token) {
                yield put(loginUserSuccess(response.data.data));
                localStorage.setItem('token', token);
            } else {
                yield put(loginUserFailure(response.data.data));
                toast.error(response.data.data.message)
            }
        } else {
            yield put(loginUserFailure(response.data.data));
        }
    } catch (error) {
        yield put(loginUserFailure(error?.response?.data));
        toast.error(error?.response?.data?.message)
    }
}

function* EmailVerifySaga(action) {
    try {
        const response = yield call(emailVerifyApi, action.payload);
        // console.log("response", response);
        if (response.data.data) {
            action.callBack(response.data)
            yield put(emailVerifySuccess(response.data.data));
            localStorage.getItem('token');
            // toast.success("Email sent successfuly !");
            toast.success("A link to reset your password has been sent to your email address !");
        }
    } catch (error) {
        // console.log("(error.response.data.data.message", error.response.data.message);
        // toast.error(error.response.data.message)
        toast.error("Invalid email");
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(emailVerifyFailure(error));
        }
    }
}

function* ForgotPasswordSaga(action) {
    try {
        const response = yield call(forgotPasswordApi, action.payload);
        if (response.status === 200) {
            action.callBack(response.data)
            yield put(forgotpasswordSuccess(response.data.data));
            toast.success(response.data.message);
        }
    } catch (error) {
        console.log(error);
        // toast.error("Invalid email");
        yield put(forgetPasswordFailure(error));
    }
}

function* GetUserbyToken(action) {
    try {
        const response = yield call(getUserbyTokenApi, action.payload);
        if (response?.status === 200) {
            yield put(getUserbyTokenSuccess(response?.data?.data));
        }
    } catch (error) {
        toast.error(error?.response?.data?.message)
    }
}

export function* loginSaga() {
    yield takeLatest(STORE_LOGIN, UserloginSaga);
    yield takeLatest(GET_EMAIL_VERIFY, EmailVerifySaga);
    yield takeLatest(FORGOT_PASSWORD, ForgotPasswordSaga);
    yield takeLatest(GET_USER_BY_TOKEN, GetUserbyToken)
}

import { FocusEventHandler, KeyboardEventHandler, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Option } from "../Teams/AddEditTeams";
import { Inputform } from "../../forms-component/InputForm";
import FormSelect from "../../forms-component/globalSelect";
import { Box, Button, Divider, Input, Text } from "theme-ui";
import theme from "../../../theme";
import * as Yup from 'yup';

import { useFormik } from "formik";
import CreatableSelect from 'react-select/creatable';
import { ActionCreateByUrl, ActionUpdateById } from "../../../store/actions/CommonAction";
import { getAllUserWithoutPagination, getTodobyId } from "../../../store/actions/user.action";
import { ColumnDef } from "@tanstack/react-table";
import DataTable from "../../Tabels/Tabel";
import { IChecklistTemplate } from "../../project-components/sideActions";
import { getAllTemplateChecklist } from "../../../store/actions/action.Highlighters";
import { RootState } from "../../../../@types";

const AddEditTodos = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { todoId } = useParams();
    const { userData, todoById } = useSelector((state: any) => state?.user);
    const { templateList } = useSelector((state: any) => state.actionHighlight)
    const location = useLocation();
    const [isCreating, setIsCreating] = useState(!todoId);
    const [inputValue, setInputValue] = useState('');
    const [todoList, setTodoList] = useState<readonly Option[]>([]);
    const [checklistTable, setChecklistTable] = useState<any[]>([]);
    const [selectedTemplates, setSelectedTemplates] = useState<any>(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const { auther } = useSelector((state: RootState) => state.auth);
    const isAllowedReport = !auther?.Role?.some(
        r => r?.RoleName === "Super Admin" || r?.RoleName === "Region Admin"
    );
    const templateOptions: any = templateList?.map((template: IChecklistTemplate) => ({
        label: template.templateName,
        value: template._id,
        checklistItem: template?.checklistItem
    }));
    const today = new Date().toISOString().split('T')[0];
    const isEditMode = !!todoId && isAllowedReport;

    useEffect(() => {
        dispatch(getAllUserWithoutPagination())
        dispatch(getAllTemplateChecklist({ query: "todos" }))

        if (todoId) {
            setIsCreating(false);
            dispatch(getTodobyId(todoId));
        }
        else {
            setIsCreating(true);
            formik.resetForm({
                values: {
                    todoName: '',
                    todoDescription: '',
                    todoAssignee: '',
                    projectId: '',
                    todoChecklist: [],
                    status: '',
                    recurrence: '',
                    todoDueDate: '',
                }
            });
            setChecklistTable([]);
            setTodoList([]);
        }
    }, [todoId, dispatch])
    useEffect(() => {
        if (todoById && todoById?.todoChecklist) {
            const initialChecklist = todoById?.todoChecklist?.map((item: any) => ({
                label: item.name,
                value: item.name,
                isChecked: item.isChecked || false,
            }));
            setTodoList(initialChecklist);
            setChecklistTable(initialChecklist);

            // Only set string values for the todoChecklist field
            if (formik.values.todoChecklist.length === 0) {
                formik.setFieldValue(
                    'todoChecklist',
                    initialChecklist?.map((item: any) => item.value)
                );
            }
        }
    }, [todoById]);
    const Users = userData?.data;
    const userOptions: Option[] = Users?.map((obj: any) => ({
        label: `${obj.FirstName} ${obj.LastName}`,
        value: obj?._id,
    }));
    const components = {
        DropdownIndicator: null,
    };
    const columns: ColumnDef<any>[] = [

        {
            header: () => (
                <div>
                    <input
                        type="checkbox"
                        style={{ marginRight: '4px' }}
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                    />
                    Completed
                </div>
            ),
            accessorKey: 'isChecked',
            cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={row.getValue('isChecked')}
                    onChange={() => handleCheckboxChange(row.index)}
                />
            ),
        },
        {
            header: 'CheckList Items',
            accessorKey: 'label',
        },
        // {

        //     accessorKey: 'isChecked',
        //     cell: ({ row }) => (
        //         <input
        //             type="checkbox"
        //             checked={row.getValue('isChecked')}
        //             onChange={() => handleCheckboxChange(row.index)}
        //         />
        //     ),
        // },
    ];
    const createOption = (label: string) => ({
        label,
        value: label,
        isChecked: false
    });

    const { dataForTable } = useSelector((state: any) => state.masterData);
    const projectOptions: Option[] = dataForTable?.data?.allProjects?.map((obj: any) => ({
        label: obj?.name,
        value: obj?._id,
    }));

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            todoName: isCreating ? '' : (todoById?.todoName || ''),
            todoDescription: isCreating ? '' : (todoById?.todoDescription || ''),
            todoAssignee: isCreating ? null : (todoById?.todoAssignee?._id || null),
            projectId: isCreating ? null : (todoById?.projectId?._id || null),
            recurrence: isCreating ? null : (todoById?.recurrence || null),
            todoDueDate: isCreating ? null : (todoById?.todoDueDate || null),
            status: todoById?.todoAssignee?._id ? 'assigned' : 'draft',
            todoChecklist:
                todoById?.todoChecklist?.map((item: any) => item.name) || [],
        },
        validationSchema: Yup.object({
            todoName: Yup.string().required('Todo Name is required'),
            todoDescription: Yup.string().required('Todo Description is required'),
            todoChecklist: Yup.array().of(Yup.string()).required('Todo Checklist is required'),
        }),
        onSubmit: (values) => {

            const status = values?.todoAssignee ? 'assigned' : 'draft';

            const formattedChecklist = checklistTable?.map((item: any) => ({
                name: item.value,
                isChecked: item.isChecked,
            }));

            const payload = {
                ...values,
                status,
                todoChecklist: formattedChecklist,
            };
            const handleResponse = (res: any) => {
                if (res.status) {
                    setTodoList([]);
                    if (location.state?.from === 'my-todo') {
                        navigate('/my-todo');
                    }
                    else if (location.state?.from === 'assigned-todo') {
                        navigate('/assigned-todo');
                    }
                    else {
                        navigate('/todos');
                    }
                }
            };

            if (todoId) {
                dispatch(ActionUpdateById({ Query: `/todo/edit-Todo/${todoId}`, obj: payload }, handleResponse));
            } else {
                dispatch(ActionCreateByUrl({ Query: '/todo/createTodo', obj: payload }, handleResponse));
            }
        },
    });
    const { handleSubmit, setFieldValue, values, handleBlur, touched, errors, } = formik;
    const isDueDatePassed = values?.todoDueDate && values?.todoDueDate.split('T')[0] < today;

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (event.key === 'Enter' || event.key === 'Tab') {
            event.preventDefault();
        }
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                const newOption = createOption(inputValue);
                setTodoList((prev) => [...prev, newOption]);
                setChecklistTable((prev) => [...prev, newOption]);
                setFieldValue('todoChecklist', [...values.todoChecklist, newOption.value]);
                setInputValue('');
        }
    };
    const handleOnBlur: FocusEventHandler = (event: any) => {
        if (inputValue.trim() !== '') {
            const newOption = createOption(inputValue);
            setTodoList((prev) => [...prev, newOption]);
            setChecklistTable((prev) => [...prev, newOption]);
            setFieldValue('todoChecklist', [...values.todoChecklist, newOption.value]);
            setInputValue('');
        }
    };
    const handleFocus: FocusEventHandler = (event: any) => {
        if (inputValue.trim() !== '') {
            const newOption = createOption(inputValue);
            setTodoList((prev) => [...prev, newOption]);
            setChecklistTable((prev) => [...prev, newOption]);
            setFieldValue('todoChecklist', [...values.todoChecklist, newOption.value]);
            setInputValue('');
        }
    };
    const handleSelectAll = () => {
        const updatedChecklist = checklistTable?.map((item) => ({
            ...item,
            isChecked: !isAllSelected,
        }));
        setChecklistTable(updatedChecklist);
        setFieldValue('todoChecklist', updatedChecklist?.map((item) => item.value));
        setIsAllSelected(!isAllSelected);
    };
    const handleCheckboxChange = (index: number) => {
        const updatedChecklist = [...checklistTable];
        updatedChecklist[index].isChecked = !updatedChecklist[index].isChecked;
        setChecklistTable(updatedChecklist);
        setFieldValue('todoChecklist', updatedChecklist.map(item => item.value));

        const allChecked = updatedChecklist.every(item => item.isChecked);
        setIsAllSelected(allChecked);
    };
    const handleTemplateChange = (selectedOption: any) => {
        setSelectedTemplates(selectedOption);

        const checklistItems = Array.isArray(selectedOption) ?
            selectedOption.flatMap((item: any) => item.checklistItem) :
            selectedOption.checklistItem;

        const updatedChecklist = checklistItems?.map((item: any) => ({
            label: item,
            value: item,
            isChecked: false,
        }));

        setTodoList(updatedChecklist);
        setChecklistTable(updatedChecklist);
        setFieldValue('todoChecklist', updatedChecklist.map((item: any) => item.value));
    };
    const checklistData = checklistTable?.map(item => ({
        label: item.label,
        isChecked: item.isChecked,
    }));

    const Recurrence = [
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Yearly", value: "yearly" },
    ];
    return (
        <div className="main-content px-4">
            <form onSubmit={(e) => {
                e.preventDefault();

                handleSubmit(e);
            }}

            >
                <h1>{todoId ? "Edit" : "Add"} Todo</h1>
                <div className="row">
                    <Box className="col-md-6 mt-4">
                        <Inputform
                            label={"Todo Name "}
                            Placeholder={"Enter Todo Name"}
                            Name={"todoName"}
                            type='text'
                            Title_sx={{ color: "grey3" }}
                            touched={touched?.todoName}
                            asterisk={true}
                            errors={errors?.todoName}
                            values={values?.todoName}
                            Max_Length={10}
                            onBlur={handleBlur}
                            disabled={isEditMode}
                            sx={{
                                backgroundColor: isEditMode ? "#f5f5f5" : "white",
                                cursor: isEditMode ? "not-allowed" : "text",
                                border: isEditMode ? "transparent" : "1px solid black"
                            }}
                            OnChange={(event: any) => {
                                const newName = event.target.value;
                                setFieldValue("todoName", newName, true);
                            }}
                        />
                    </Box>
                    <Box className="col-md-6 mt-4">
                        <Inputform
                            label={"Todo Description "}
                            Placeholder={"Enter Description"}
                            Name={"todoDescription"}
                            type='text'
                            Title_sx={{ color: "grey3" }}
                            touched={touched?.todoDescription}
                            asterisk={true}
                            errors={errors?.todoDescription}
                            values={values.todoDescription}
                            Max_Length={10}
                            onBlur={handleBlur}
                            disabled={isEditMode}
                            sx={{
                                backgroundColor: isEditMode ? "#f5f5f5" : "white",
                                cursor: isEditMode ? "not-allowed" : "text",
                                border: isEditMode ? "transparent" : "1px solid black"

                            }}
                            OnChange={(event: any) => {
                                const newName = event.target.value;
                                setFieldValue("todoDescription", newName, true);
                            }}

                        />
                    </Box>
                </div>
                <div className="row">
                    <Box className="col-md-4 mt-4">
                        <FormSelect
                            label={"Todo Assignee"}
                            value={values.todoAssignee}
                            placeholder={"Select Assignee"}
                            options={userOptions || []}
                            isMulti={false}
                            // disabled={true}
                            DefultSelect='Select Todo Assignee'
                            touched={touched?.todoAssignee}
                            errors={errors?.todoAssignee}
                            asterisk={false}
                            onBlur={handleBlur}
                            disabled={isEditMode}
                            sx={{
                                backgroundColor: isEditMode ? "#f5f5f5" : "white",
                                cursor: isEditMode ? "not-allowed" : "text",
                            }}
                            Name='todoAssignee'
                            onChange={(teamLeader: any) => {
                                setFieldValue('todoAssignee', teamLeader?.value, true);
                            }}
                        />
                    </Box>
                    <Box className="col-md-4 mt-4">
                        <FormSelect
                            label={"Project"}
                            value={values.projectId}
                            placeholder={"Select Project"}
                            options={projectOptions || []}
                            isMulti={false}
                            DefultSelect='Select projectName'
                            asterisk={false}
                            onBlur={handleBlur}
                            disabled={isEditMode}
                            sx={{
                                backgroundColor: isEditMode ? "#f5f5f5" : "white",
                                cursor: isEditMode ? "not-allowed" : "text",
                            }}
                            Name='projectId'
                            onChange={(teamLeader: any) => {
                                setFieldValue('projectId', teamLeader?.value, true);
                            }}
                        />
                    </Box>
                    <Box className="col-md-4 mt-4">
                        <Text variant="SelectLabel">Due Date</Text>
                        <Input
                            type="date"
                            // label="Todo Due Date"
                            onBlur={handleBlur}
                            name={`todoDueDate`}
                            onChange={(event: any) => {
                                const newDate = event.target.value;
                                setFieldValue("todoDueDate", newDate || null, true);
                            }}
                            value={values?.todoDueDate?.split('T')[0] || ''}
                            disabled={isEditMode}
                            placeholder="Select a date"
                            sx={{
                                borderColor: '#F7EFE5',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderRadius: '12px',
                                color: 'black',
                                backgroundColor: isEditMode ? "#f5f5f5" : "white",
                                cursor: isEditMode ? "not-allowed" : "text",
                                appearance: 'textfield',
                                '::placeholder': {
                                    color: 'transparent'
                                },
                            }}
                        />
                    </Box>
                    <div className="row mt-3">
                        <Box className="col-md-4 ">
                            <FormSelect
                                label={" Todo Recurrence"}
                                value={values.recurrence}
                                placeholder={"Select Recurrence"}
                                options={Recurrence || []}
                                isMulti={false}
                                asterisk={false}
                                onBlur={handleBlur}
                                disabled={isEditMode}
                                sx={{
                                    backgroundColor: isEditMode ? "#f5f5f5" : "white",
                                    cursor: isEditMode ? "not-allowed" : "text",
                                }}
                                Name='recurrence'
                                onChange={(Recurrence: any) => {
                                    setFieldValue('recurrence', Recurrence?.value, true);
                                }}
                            />
                        </Box>
                        <Box className="col-4 ">
                            <Text variant="SelectLabel">Todo Predefined Checklist</Text>

                            <FormSelect
                                placeholder="Predefined Checklist"
                                options={templateOptions}
                                value={templateOptions?.find((option: any) => selectedTemplates?.value === option?.value)}
                                // onChange={""}
                                onChange={handleTemplateChange}
                                disabled={isEditMode}
                                sx={{
                                    backgroundColor: isEditMode ? "#f5f5f5" : "white",
                                    cursor: isEditMode ? "not-allowed" : "text",
                                }}
                            />
                        </Box>
                        <Box className='col-4 ps-2'>
                            <Text variant="SelectLabel">Todo Checklist<span className="mx-1 text-danger">*</span></Text>
                            <CreatableSelect
                                components={{ DropdownIndicator: null }}
                                inputValue={inputValue}
                                isClearable
                                options={templateOptions}
                                isMulti
                                isDisabled={isEditMode}
                                menuIsOpen={false}
                                onCreateOption={(inputValue) => {
                                    const newOption = createOption(inputValue);
                                    setTodoList((prev) => [...prev, newOption]);
                                    setChecklistTable((prev) => [...prev, newOption]);
                                    setFieldValue('todoChecklist', [...values.todoChecklist, newOption.value]);
                                    setInputValue('');
                                }}
                                onChange={handleTemplateChange}
                                onInputChange={(newValue) => {
                                    setInputValue(newValue)
                                }}
                                onBlur={handleOnBlur}
                                onKeyDown={handleKeyDown}
                                onFocus={handleFocus}
                                placeholder=""
                                value={todoList}
                                styles={{
                                    valueContainer: (base) => ({
                                        ...base,
                                        maxHeight: '90px',
                                        overflowY: 'auto',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        paddingRight: '30px',
                                    }),
                                    control: (base) => ({
                                        ...base,
                                        border: '1px solid #f7efe5',
                                        borderRadius: '12px',
                                        minHeight: '48px',
                                        flexWrap: 'wrap',
                                        display: 'flex',
                                        position: 'relative',
                                    }),
                                    clearIndicator: (base) => ({
                                        ...base,
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-95%)',
                                        zIndex: 1,
                                        padding: '0 5px',
                                        cursor: 'pointer',
                                    }),
                                    multiValue: (base) => ({
                                        ...base,
                                        // marginRight: '5px',
                                    }),
                                }}
                            />
                            {/* {errors?.todoChecklist && touched?.todoChecklist && (
                            <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                                {errors?.todoChecklist}
                            </Text>
                        )} */}
                        </Box>

                    </div>
                    <Divider />
                    {todoId && checklistTable.length > 0 && (
                        <div className="row mt-5">
                            <div className="mt-5">
                                <Text variant="SelectLabel">Checklist Items</Text>
                                <DataTable
                                    data={checklistData}
                                    columns={columns}
                                    onPageChange={() => { }}
                                    onPerPageChange={() => { }}
                                    perPageCount={10}
                                    TotalCount={checklistData.length}
                                    TotalLength={checklistData.length}
                                    loader={false}
                                    showNavigation={false}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className='row mt-5 py-2'>
                    <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                    <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' >Submit</Button></div>
                </div>
            </form>
        </div>

    )
}

export default AddEditTodos
import React, { useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { Button } from 'theme-ui';
import AddStageStepper from './AddStageStepper';

const AddStage: React.FC = () => {
    const [open, setIsOpen] = useState(false);
    const [step, setStep] = useState(0);
    const handleOpen = () => {
        setIsOpen(true)
    }
    const handleNext = () => {
        setStep(prevStep => prevStep + 1);
    };

    const handleBack = () => {
        setStep(prevStep => prevStep - 1);
    };
    const toggle = () => { setIsOpen(false); }
    return (
        <div>
            <Button variant="SaveButton" onClick={handleOpen}>Add Stage</Button>
            <Modal isOpen={open} size='xl' centered>
                <ModalHeader className='d-flex' toggle={toggle}>Add Stage</ModalHeader>
                <AddStageStepper next={handleNext} />
            </Modal>
        </div>
    )
}

export default AddStage

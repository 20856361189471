import { ColumnDef } from "@tanstack/react-table";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FaStar } from "react-icons/fa";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Divider, Flex, Paragraph, Text } from "theme-ui";
import { RootState } from "../../../../@types";
import { getExcelReport } from "../../../store/actions/action.Highlighters";
import { getAllMasterDataForTable } from "../../../store/actions/masterData.action";
import { getAllProjectMasterData } from "../../../store/actions/project.action";
import { getAllUserWithoutPagination } from "../../../store/actions/user.action";
import Export from "../../assets/images/Icon/Export.svg";
import { HeaderBtn } from "../../Button/TabelHeadBtn";
import DataTable from "../../Tabels/Tabel";
import { FormValues, Option } from "./AdminReport";

const MyReport = () => {
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const { createprojectMasterData } = useSelector((state: any) => state.project)
    const userData = useSelector((state: any) => state?.user);
    const { auther } = useSelector((state: RootState) => state.auth);
    const { excelReport, loader } = useSelector((state: any) => state?.actionHighlight);
    const [tableData, setTableData] = useState([]);
    const Users = userData?.userData?.data;
    const teamOptions = Users?.flatMap((user: any) =>
        user._id === auther?._id ?
            user?.teamId?.filter((team: any) => team?.teamMembers?.length > 0)
                .map((team: any) => ({
                    value: team?._id,
                    label: team?.teamName,
                    members: team?.teamMembers,
                }))
            : []
    ) || [];
    const userOptions = Users?.map((user: any) => ({
        value: user?._id,
        label: `${user?.FirstName} ${user?.LastName}`
    }));
    const workerOptions = auther ? [{
        value: auther._id,
        label: `${auther.FirstName} ${auther.LastName}`
    }] : [];
    const projectOptions = dataForTable?.data?.allProjects?.map((project: any) => ({
        value: project?._id,
        label: `${project?.name} `
    }));
    const trade = createprojectMasterData?.allTask?.data?.map((trade: any) => ({
        value: trade._id,
        label: trade?.name
    }))

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllMasterDataForTable());
        dispatch(getAllProjectMasterData());

        dispatch(getAllUserWithoutPagination())
    }, [])
    useEffect(() => {
        if (excelReport) {
            setTableData(excelReport);
        }
    }, [excelReport]);
    const handleClearFilters = () => {
        formik.resetForm();


        setTableData([]);
        // dispatch(getExcelReport(''));
    };
    console.log(workerOptions, "teamOptions->", auther);

    const formik = useFormik<FormValues>({
        initialValues: {
            teamName: [],
            workerNameRef: [],
            ratingGivenByRef: [],
            projectNameRef: [],
            tradeNameRef: [],
        },
        onSubmit: (values, { setSubmitting }) => {
            const { teamName, ...otherValues } = values;

            const dynamicFilter = Object.keys(otherValues).reduce((acc, key) => {

                const value = values[key as keyof FormValues];
                if (value && Array.isArray(value)) {
                    const idTypes = ["projectId", "userId", "tradeId"];
                    idTypes.forEach((idType) => {
                        const ids = value
                            .map((item: { [key: string]: string }) => item[idType])
                            .filter(Boolean);
                        if (ids.length > 0) {
                            const jsonValue = JSON.stringify(ids);

                            acc.push({
                                type: "array",
                                value: jsonValue,
                                key: key,
                                caseSensitive: false,
                            });
                        }
                    });
                }
                return acc;
            }, [] as { type: string; value: string; key: string; caseSensitive: boolean }[]);

            // Format the dynamicFilter as a stringified array of objects
            const finalDynamicFilter =
                dynamicFilter.length > 0
                    ? `[${dynamicFilter
                        .map(
                            (item) =>
                                `{"type":"array","value":"${item.value.replace(/"/g, '\\"')}","key":"${item.key}","caseSensitive":${item.caseSensitive}}`
                        )
                        .join(",")}]`
                    : "[]";


            const payload = `?dynamicFilter=${encodeURIComponent(finalDynamicFilter)}`;

            dispatch(getExcelReport(payload));
            setTableData(excelReport);
            setSubmitting(false);
        },

    });
    const { setFieldValue, values, } = formik;
    const allworkerOptions = values?.teamName?.length
        ? teamOptions
            .filter((team: any) =>
                values?.teamName?.some((selectedTeam: any) => selectedTeam?.userId === team.value)

            )
            .flatMap((team: any) =>
                team.members?.map((member: any) => ({
                    value: member?.userId?._id,
                    label: `${member?.userId?.FirstName} ${member?.userId?.LastName}`,
                }))
            )
        : workerOptions;

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            header: ' Sr.No',
            enableColumnFilter: false,
            accessorKey: "no",
            id: "no",
            cell: ({ row }) => {
                return (
                    <Text >{((currentPage - 1) * limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            header: 'Worker',
            accessorKey: "worker_name",
        },
        {
            header: 'Rating given By',
            accessorKey: "rating_given_by",
        },
        {
            header: 'Project',
            accessorKey: "project_name",
        },
        {
            header: 'Trade',
            accessorKey: "tradeName",
        },
        {
            header: 'Average Rating',
            accessorKey: "average_rating",
        },
        {
            header: 'Criteria',
            accessor: "criteria",
            cell: ({ row }) => {

                return (
                    <div >
                        {row?.original?.criteria?.map((criteria: any, index: any) => {
                            return (
                                <Flex key={index} sx={{ borderTop: `${index !== 0 && "0.5px solid #D3D5D9"}`, paddingY: "5px" }}>
                                    <Text sx={{ fontWeight: "600" }}>{criteria?.rating}</Text> <FaStar color="#FFB721" className='mx-2 mt-1' />
                                    <Paragraph sx={{ textWrap: "nowrap" }}>{criteria?.name}</Paragraph>
                                </Flex>
                            )
                        }

                        )}
                    </div>
                );
            },
        }
    ], [tableData, excelReport]);
    const handleExportClick = (): void => {
        const dataToExport = tableData?.length > 0 ? tableData : excelReport;
        const csvRows: string[] = [];

        // Collect unique criteria names
        const criteriaSet = new Map<string, { mainHeader: string; commentHeader?: string }>();

        dataToExport?.forEach((item: any) => {
            item?.criteria?.forEach((crit: any) => {
                const [mainHeader, comment] = crit.name.split(":").map((part: any) => part.trim());
                if (!criteriaSet.has(mainHeader)) {
                    criteriaSet.set(mainHeader, {
                        mainHeader: `"${mainHeader}"`,
                        commentHeader: comment ? `"${mainHeader}_Comment"` : undefined,
                    });
                }
            });
        });

        // Build headers
        const criteriaHeaders = Array.from(criteriaSet.values());
        const headers: any[] = [
            "Worker",
            "Rating Given By",
            "Rating Date",
            "Project",
            "Trade",
            "Average Rating",
            ...criteriaHeaders.flatMap(c => [c.mainHeader, c.commentHeader].filter(Boolean)),
        ];
        csvRows.push(headers.join(","));

        const replaceCommas = (text: string | null): string => (text ? text.replace(/,/g, "_") : "");

        // Build rows
        dataToExport?.forEach((item: any) => {
            const row: { [key: string]: string } = {
                Worker: `"${replaceCommas(item.worker_name)}"`,
                "Rating Given By": `"${replaceCommas(item.rating_given_by)}"`,
                "Rating Date": `"${replaceCommas(
                    moment(item.createdAt, "MMMM Do YYYY h:mm:ss a").format("MMM D, YYYY")
                )}"`,
                Project: `"${replaceCommas(item.project_name)}"`,
                Trade: `"${replaceCommas(item.tradeName)}"`,
                "Average Rating": `"${replaceCommas(item.average_rating)}"`,
            };

            criteriaHeaders.forEach(({ mainHeader, commentHeader }) => {
                const mainHeaderKey = mainHeader.replace(/"/g, "");
                const matchingCriteria = item.criteria.find(
                    (crit: any) => crit.name.split(":")[0].trim() === mainHeaderKey
                );

                row[mainHeader] = `"${matchingCriteria?.rating || ""}"`;
                if (commentHeader) {
                    row[commentHeader] = `"${matchingCriteria?.name.split(":")[1]?.trim() || ""}"`;
                }
            });

            const rowValues = headers.map(header => row[header] || "");
            csvRows.push(rowValues.join(","));
        });

        // Generate CSV file
        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "Admin_report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const dataToDisplay = tableData?.length > 0 ? tableData : excelReport;

    return (
        <>
            <div className="main-content px-3" >
                <Box className="d-flex justify-content-between align-items-center" sx={{ mb: 4 }}>
                    <Box className="d-flex align-items-center">
                        <h2>My Report</h2>
                        {/* <Button
                            variant='PrimaryHeadBtn'
                            onClick={handleClearFilters}
                            className='toggle-btn'
                            sx={{ ml: 3 }}
                        >
                            Clear Filter
                        </Button> */}
                    </Box>
                    <HeaderBtn
                        title="Export"
                        onClick={handleExportClick}
                        src={Export}
                        sx={{ padding: '10px 10px' }}
                    />
                </Box>
                <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
                <form onSubmit={formik.handleSubmit}>

                    <Flex>
                        <div className="col-2 px-1">

                            <>
                                <Text variant="SelectLabel"> Team Name</Text>
                                <MultiSelect
                                    options={teamOptions || []}
                                    value={Array?.isArray(values?.teamName)
                                        ? values?.teamName?.map((value: any) =>
                                            teamOptions?.find((option: Option) => option?.value === value.userId) || { label: '', value: '' }
                                        )
                                        : []}
                                    onChange={(selected: Option[]) =>
                                        setFieldValue('teamName', selected.map((option) => ({ userId: option.value })))
                                    }
                                    labelledBy="Select"
                                />
                            </>

                        </div>
                        <div className="col-2 px-1">
                            <Text variant="SelectLabel"> Workers</Text>
                            <MultiSelect
                                options={allworkerOptions || []}
                                value={Array?.isArray(values?.workerNameRef) ? values?.workerNameRef?.map((value: any) =>
                                    allworkerOptions?.find((option: Option) => option?.value === value?.userId) || { label: '', value: '' }
                                ) : []}
                                onChange={(selected: Option[]) =>
                                    setFieldValue('workerNameRef', selected?.map((option) => ({
                                        userId: option?.value
                                    })))
                                }
                                labelledBy="Select"
                            />
                            {/* <FormSelect
                                label={" Worker:"}
                                value={values?.workerNameRef}
                                placeholder={"Select Worker"}
                                options={workerOptions || []}
                                isMulti={false}
                                Name='workerNameRef'
                                onChange={(selectedOption: any) => {
                                    const selectedId = selectedOption?.value || null;
                                    setFieldValue('workerNameRef', selectedId);
                                }}
                            /> */}
                        </div>
                        <div className="col-3 px-1">
                            <Text variant="SelectLabel"> Rating Given By</Text>
                            <MultiSelect
                                options={userOptions || []}
                                value={Array.isArray(values?.ratingGivenByRef) ? values?.ratingGivenByRef?.map((value: any) =>
                                    userOptions?.find((option: Option) => option?.value === value?.userId) || { label: '', value: '' }
                                ) : []}
                                onChange={(selected: Option[]) =>
                                    setFieldValue('ratingGivenByRef', selected?.map((option) => ({
                                        userId: option?.value
                                    })))
                                }
                                labelledBy="Select"
                            />
                        </div>
                        <div className="col-3 px-1">
                            <Text variant="SelectLabel">Project</Text>
                            <MultiSelect
                                options={projectOptions || []}
                                value={Array.isArray(values?.projectNameRef) ? values?.projectNameRef?.map((value: any) =>
                                    projectOptions?.find((option: Option) => option?.value === value?.projectId) || { label: '', value: '' }
                                ) : []}
                                onChange={(selected: Option[]) =>
                                    setFieldValue('projectNameRef', selected?.map((option) => ({
                                        projectId: option?.value
                                    })))
                                }
                                labelledBy="Select"
                            />
                        </div>
                        <div className="col-2">
                            <Text variant="SelectLabel">Trade</Text>
                            <MultiSelect
                                options={trade || []}
                                value={Array.isArray(values?.tradeNameRef) ? values?.tradeNameRef?.map((value: any) =>
                                    trade?.find((option: Option) => option?.value === value?.tradeId) || { label: '', value: '' }
                                ) : []}
                                onChange={(selected: Option[]) =>
                                    setFieldValue('tradeNameRef', selected?.map((option) => ({
                                        tradeId: option?.value
                                    })))
                                }
                                labelledBy="Select"
                            />
                        </div>

                    </Flex>
                    {(values?.workerNameRef && values?.workerNameRef?.length > 0) && <Box className="mt-4 px-2 d-flex justify-content-end">
                        <Button className="mx-2" variant="SaveButton" type='submit' >Preview</Button>

                    </Box>}
                </form>
                <Box className="mt-3 report-table-column">
                    <DataTable
                        loader={loader}
                        columns={columns}
                        fixedColumn={true}
                        data={dataToDisplay || []}
                        TotalLength={15}
                        perPageCount={15}
                        currentPage={1}
                        showNavigation={false}
                        onPerPageChange={() => { }}
                        onPageChange={() => { }}
                    />
                </Box>
            </div >
        </>
    )
}

export default MyReport
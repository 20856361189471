import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeUIProvider } from 'theme-ui';
import App from './App';
import './index.css';
import initStore from './store';
import theme from './theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider store={initStore}>
          <ThemeUIProvider theme={theme}>
            <App />
          </ThemeUIProvider>
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.Fragment>
);

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Image, Text } from "theme-ui";
import dropdownicon from "../assets/images/arrow.svg";
const CustomArrow: React.FC = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = () => {
    localStorage.removeItem('token');
  };
  const togglePopover = (event: any) => {
    setIsPopoverOpen(!isPopoverOpen);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setIsPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div ref={popoverRef}>

      <Button
        onClick={togglePopover}
        sx={{ variant: 'styles.button', backgroundColor: 'transparent', border: 'none', paddingRight: '52px' }}
      >
        <Image src={dropdownicon} />
      </Button>

      {isPopoverOpen && (
        <Box

          sx={{
            position: 'absolute',
            gap: '8px',
            right: 2,
            zIndex: 1,
            backgroundColor: 'white',
            border: '1px solid #black',
            boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)',
            borderRadius: '4px',
            // minWidth: '189px',
            // padding: '20px 4px',
            fontSize: 'larger',
            // top: '62px'
          }}>
          {/* <Link to={"/user"} className="text-hover" style={{ textDecoration: 'none' }}>
            Setting
          </Link> */}
          <Link to={'login'} style={{ textDecoration: 'none' }}>
            <Text className="text-hover" as="p" onClick={handleOptionClick}>
              LogOut
            </Text>
          </Link>
        </Box>
      )
      }
    </div >
  )
};

export default CustomArrow;

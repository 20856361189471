import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip: React.FC<any> = ({ id, children, title }) => {
    return (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            {children}
        </OverlayTrigger>
    )
}

export default CustomTooltip
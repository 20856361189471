import { GET_TRADING_RATING, GET_TRADING_RATING_BY_ID, GET_TRADING_RATING_BY_ID_SUCCESS, GET_TRADING_RATING_SUCCESS, TRADING_RATING_FAIL } from "../storeTypes"

const initialState: any = {
    tradingRatingData: null,
    loader: false,
    tradingRating: null,
    error: null
}
const TradingRatingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_TRADING_RATING:
            return {
                ...state,
                loader: true,
            }
        case GET_TRADING_RATING_SUCCESS:
            return {
                ...state,
                loader: false,
                tradingRatingData: action.payload
            }
        case GET_TRADING_RATING_BY_ID:
            return {
                ...state,
                loader: true
            }
        case GET_TRADING_RATING_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                tradingRating: action.payload
            }
        case TRADING_RATING_FAIL:
            return {
                ...state,
                loader: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export default TradingRatingReducer

import { Badge, Box, Button, Card, Flex, Spinner, Text } from "theme-ui";
import theme from "../../../theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Teamcard from "./Teamcard";
import MemberProfileCard from "./MemberProfileCard";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAllTeamsByUser } from "../../../store/actions/user.action";

const MyTeam = () => {
    const navigate = useNavigate();
    const { loader, teamByUser } = useSelector((state: any) => state.user);
    console.log('chk18', teamByUser);
    const teamDetails = teamByUser?.data?.data
    const dispatch = useDispatch();
    const [selectedMembers, setSelectedMembers] = useState<any>([]);

    useEffect(() => {
        dispatch(getAllTeamsByUser())

    }, [dispatch])
    useEffect(() => {
        if (teamDetails && teamDetails.length > 0) {
            setSelectedMembers(teamDetails[0]?.members);
        }
    }, [teamDetails]);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 2,
        slidesToScroll: 2,
        // cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handleTeamClick = (members: any[]) => {
        setSelectedMembers(members);
    };
    return (
        <>
            <Card
                sx={{
                    borderRadius: '20px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    p: 3,
                    bg: 'white',
                    maxWidth: '500px',
                    maxHeight: '800px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    mt: 4,
                    mb: 4,
                    ml: 4
                }}
            >
                <Flex className="mt-2" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text as='h5' sx={{ mr: 12 }}>My Team <Badge className="badge" variant='badgeCount' sx={{ mt: 0, position: 'relative' }}>
                        {teamByUser?.data?.count}
                    </Badge></Text>
                    <Button onClick={() => {
                        navigate('/my-team')
                    }} sx={{ bg: `${theme?.colors?.lightbackground}`, color: 'primary', fontSize: 1, borderRadius: '10px', gap: '10px', padding: '4px 10px' }}>
                        See all
                    </Button>
                </Flex>
                <div className="row mt-3">
                    {loader ? (
                        <Box
                            sx={{
                                position: "relative",
                                inset: "0",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(255, 255, 255, 0.5)",
                                display: "grid",
                                placeContent: "center",
                            }}
                        >
                            <Spinner />
                        </Box>
                    ) : (
                        <Slider {...settings}>
                            {teamDetails?.map((team: any, index: number) => {
                                return (
                                    <div
                                        className="col-xl-6 px-2"
                                        key={team?._id}
                                        onClick={() => handleTeamClick(team.members)}
                                    >
                                        <Teamcard
                                            key={index}
                                            teamName={team?.teamName}
                                            projectName={team?.projectName}
                                            memberData={team?.members}
                                        />
                                    </div>
                                );
                            })}
                        </Slider>
                    )}
                </div>

                <Flex className="mt-4" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text as='h5' sx={{ mr: 12 }}>List of members <Badge className="badge" variant='badgeCount' sx={{ ml: 1, mt: 0 }}>
                        {selectedMembers?.length}
                    </Badge></Text>
                    {/* <Button sx={{ bg: `${theme?.colors?.lightbackground}`, color: 'primary', fontSize: 1, borderRadius: '10px', gap: '10px', padding: '4px 10px' }}>
                        See all
                    </Button> */}
                </Flex>

                {selectedMembers.length > 0 && (
                    <Box sx={{ maxWidth: '400px', bg: 'white', borderRadius: 'lg', overflow: 'hidden', mt: 3, mx: 'auto', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {selectedMembers?.map((member: any, index: number) => (
                            <MemberProfileCard
                                key={index}
                                projectName={member.projectName}
                                name={`${member.userId.FirstName} ${member.userId.LastName}`}
                                imageUrl={member.imageUrl}
                            />
                        ))}
                    </Box>
                )}
                {/* <Flex className="mt-4" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text as='h5' sx={{ mr: 12 }}>List of other teams <Badge className="badge" variant='DropDownbedge' sx={{ mt: 0 }}>
                        {12}
                    </Badge></Text>
                    <Button sx={{ bg: `${theme?.colors?.lightbackground}`, color: 'primary', fontSize: 1, borderRadius: '10px', gap: '10px', padding: '4px 10px' }}>
                        See all
                    </Button>
                </Flex> */}
                {/* <div className="row mt-3">
                    <Slider {...settings}>
                        {teamData?.map((team, index: number) => {
                            return (
                                <div className="col-xl-5">
                                    <Teamcard key={index} teamName={team?.teamName} projectName={team?.projectName} memberData={team?.members} />
                                </div>
                            )
                        })}
                    </Slider>
                </div> */}
            </Card>

        </>
    )
}

export default MyTeam
import React from 'react';
import Select from 'react-select';
import { Text } from 'theme-ui';
import { FolderNumberDropdownProps, FolderOption } from '../../../../../@types';

const generateFolderOptions = (): FolderOption[] => {
  const startMonth = 1609;
  const currentYear = new Date().getFullYear() % 100;
  const currentMonth = new Date().getMonth() + 1;
  // console.log('Current Year:', currentYear);
  // console.log('Current Month:', currentMonth);
  const folderOptions: FolderOption[] = [];
  for (let year = 16; year <= currentYear; year++) {
    const start = (year === 16) ? startMonth % 100 : 1;
    const end = (year === currentYear) ? currentMonth + 2 : 12;
    for (let month = start; month <= end; month++) {
      const formattedOption = `projects_${year.toString().padStart(2, '0')}${month.toString().padStart(2, '0')}`;
      const optionObject: FolderOption = { label: formattedOption, value: formattedOption };
      folderOptions.push(optionObject);
      if (year === currentYear && month === currentMonth) {
        optionObject.defaultSelected = true;
      }
    }
  }
  folderOptions.sort((a, b) => a.label.localeCompare(b.label));
  // console.log('folderOptions:', folderOptions);
  return folderOptions;
};


const FolderNumberDropdown: React.FC<FolderNumberDropdownProps> = ({
  label,
  value,
  touched,
  errors,
  disable,
  onBlur,
  onChange,
  asterisk
}) => {
  const options = generateFolderOptions();
  const defaultSelectedOption = options.find((option) => option.defaultSelected);
  React.useEffect(() => {
    if (defaultSelectedOption && !value) {
      onChange(defaultSelectedOption.value);
    }
  }, [defaultSelectedOption, value, onChange]);
  return (
    <div>
      <Text variant='SelectLabel'>{label}{asterisk && <span className="mx-1 text-danger">*</span>}</Text>
      <Select
        value={options.find((option) => option.value === value)}
        defaultValue={defaultSelectedOption}
        options={options}
        classNamePrefix="an-simple-select"
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F7EFE5',
          },
        })}
        onChange={(selectedOption) => onChange(selectedOption?.value as string)}
        onBlur={onBlur}
        isSearchable
        isDisabled={disable}
      />
      {touched && errors && <p className='text-danger'>{errors}</p>}
    </div>
  );
};

export default FolderNumberDropdown;

import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getApiEndPoint } from "../../../utils/GenerateQueryString";
import { getAllTeamsByMember } from "../../../store/actions/user.action";
import { ColumnDef } from "@tanstack/react-table";
import { Divider, Text } from "theme-ui";
import { TabelHeadingTxt } from "../../Text/TabelCommonTxt";
import DataTable from "../../Tabels/Tabel";
import queryString from "query-string";

const AssignedTeam = () => {
    const { loader, teamByMember, userData } = useSelector((state: any) => state.user);
    const { paginations } = useSelector((state: any) => state.pagination);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState<boolean>(true);
    console.log('chk26', teamByMember?.data?.data);

    useEffect(() => {
        let apiEndPoint = getApiEndPoint({ limit, currentPage }, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])

        dispatch(getAllTeamsByMember(apiEndPoint))
        setRefresh(false);
    }, [refresh, location?.search, limit, currentPage])
    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row?.index + 1}</Text>
                )
            },
        },
        {
            accessorKey: "teamName",
            header: 'Team Name',
            cell: info => info.getValue(),
            enableColumnFilter: true,
            filterType: "textbox",
        },
        // {
        //     accessorKey: "teamDescription",
        //     header: 'Team Description',
        //     cell: info => info.getValue(),
        // },
        {
            header: 'Team Leader',
            accessorKey: "teamLeader",
            cell: ({ row }) => {
                const teamlead = row.original.teamLeader;
                return (
                    <Text >
                        {teamlead?.FirstName} {teamlead?.LastName}
                    </Text>
                );
            },
            filterType: 'dropdown',
            options: userData?.data?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val._id, label: `${val.FirstName} ${val.LastName}` }))
        },

        {
            header: 'Team Members',
            accessorKey: "members",
            cell: ({ row }) => {
                const membersCount = row.original?.membersCount?.length;
                return (
                    <Text >
                        {membersCount}
                    </Text>
                );
            },
        },
        // {
        //     header: 'Accessor',
        //     accessorKey: "Accessor",
        //     enableColumnFilter: false,
        //     cell: ({ row }) => {
        //         return (
        //             <TableActions showDelete={false} row={row} editTo={`/edit-team/${row?.original?._id}?readOnly=true`} deleteMessage='' toggleRefresh={() => setRefresh(true)} deleteApi={""} />
        //         )
        //     },
        // }
    ], [teamByMember?.data]);
    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Assigned Team' Count={teamByMember?.data?.count} IsExportButtonShow={false} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                loader={loader}
                columns={columns}
                data={teamByMember?.data?.data || []}
                TotalLength={teamByMember?.data.count || 15}
                perPageCount={limit || 15}
                currentPage={paginations?.currentPage}
                onPerPageChange={(itemsPerPage: string) => {
                    if (searchParams?.size > 2) {
                        const parsedQuery = queryString.parse(location.search);

                        setSearchParams({
                            ...parsedQuery,
                            limit: itemsPerPage.toString(),
                        });
                    } else if (teamByMember?.count < itemsPerPage) {
                        setSearchParams({
                            currentPage: '1',
                            limit: itemsPerPage.toString(),
                        });
                    }
                    else {
                        setSearchParams({
                            currentPage: currentPage.toString(),
                            limit: itemsPerPage.toString(),
                        });
                    }

                }}
                onPageChange={(value: string) => {
                    console.log(value, "onPageChange");

                    if (searchParams?.size > 2) {
                        const parsedQuery = queryString.parse(location.search);

                        setSearchParams({
                            ...parsedQuery,
                            currentPage: value.toString(),
                        });
                    } else {
                        setSearchParams({
                            currentPage: value.toString(),
                            limit: limit.toString(),
                        });
                    }

                }}

            />
        </div>
    )
}

export default AssignedTeam
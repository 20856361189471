import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Flex, Heading, Image, Input, Label, Spinner, Text } from 'theme-ui';
import { ResetPasswordFormValues, RootState } from '../../../../@types';
import * as yup from 'yup';
import logo from "../../assets/images/logo 2 (1).svg";
import theme from '../../../theme';
import { useState } from 'react';
import openeye from "../../assets/images/Eye.svg";
import closeeye from "../../assets/images/Eyelashes 2D.svg";
import { Link } from 'react-router-dom';

const passwordRegex = /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,})\S$/;

const validationSchema = yup.object({
  oldPassword: yup.string()
    .required('Please enter your current password'),
  newPassword: yup.string()
    .required('Please enter a new password')
    .notOneOf([yup.ref("oldPassword")], "New password cannot be the same as the old password")
    .matches(passwordRegex, "New password must contain at least one uppercase letter, one lowercase letter, and one number"),
  confirmPassword: yup.string()
    .required('Please confirm your new password')
    .oneOf([yup.ref('newPassword')], 'The confirmation password does not match the new password')
    .matches(passwordRegex, "New password must contain at least one uppercase letter, one lowercase letter, and one number"),
});



const ResetPassword = () => {
  const { auther } = useSelector((state: RootState) => state.auth);

  const loader = useSelector((state: RootState) => state.auth.loader);
  const dispatch = useDispatch();

  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleForgot = async (value: ResetPasswordFormValues) => {
    console.log(value, "Reset password");
    // dispatch();
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values: ResetPasswordFormValues) => {
      handleForgot(values);
    },
  });

  return (
    <Flex
      sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
      <Image src={logo} alt="Logo" sx={{ width: ["70%", "30%", "20%"], height: 'auto' }} />
      <Heading as='h1' className="mt-5" sx={{ color: theme.colors?.secondary, lineHeight: 'heading' }}>Reset Password</Heading>

      <Box sx={{ width: ['90%', '70%', '40%'], mt: 6, gap: "23px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box mb={3}>
            <Label htmlFor="oldPassword" style={{ fontSize: "17px", fontWeight: '600' }}>Old Password</Label>
            <div className="p-div">
              <Input type={oldPasswordVisible ? "text" : "password"} id="oldPassword" name="oldPassword" className='form-control' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.oldPassword} placeholder="Enter your current password"
                sx={{
                  width: '100%', p: 2, mt: 0.5, border: '1px solid', borderColor: formik.touched.oldPassword && formik.errors.oldPassword ? `${theme?.colors?.red}` : 'red',
                }} />
              <img style={{ display: `${formik.values.oldPassword?.length > 0 ? "block" : "none"}` }}
                src={oldPasswordVisible ? openeye : closeeye}
                alt={oldPasswordVisible ? "Hide password" : "Show password"}
                className="eye-icon"
                onClick={() => setOldPasswordVisible(!oldPasswordVisible)} />
            </div>
            {formik.touched.oldPassword && formik.errors.oldPassword && (
              <Text color="red"> {formik.errors.oldPassword} </Text>
            )}
          </Box>

          {/* <Label sx={{ fontSize: "20px", fontWeight: '600', marginTop: "50px" }}>Enter a new Password for <span style={{ textDecorationLine: "underline" }}> &nbsp;{auther?.Email}</span></Label>
          <Divider /> */}

          <Box mb={3}>
            <Label htmlFor="newPassword" style={{ fontSize: "17px", fontWeight: '600' }}>New Password</Label>
            <div className="p-div">
              <Input type={newPasswordVisible ? "text" : "password"} id="newPassword" name="newPassword" className='form-control' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.newPassword} placeholder="Enter your new password"
                sx={{
                  width: '100%', p: 2, mt: 0.5, border: '1px solid', borderColor: formik.touched.newPassword && formik.errors.newPassword ? `${theme?.colors?.red}` : 'red',
                }} />
              <img style={{ display: `${formik.values.newPassword?.length > 0 ? "block" : "none"}` }}
                src={newPasswordVisible ? openeye : closeeye}
                alt={newPasswordVisible ? "Hide password" : "Show password"}
                className="eye-icon"
                onClick={() => setNewPasswordVisible(!newPasswordVisible)} />
            </div>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <Text sx={{ color: `${theme?.colors?.red}` }}> {formik.errors.newPassword} </Text>
            )}
          </Box>
          <Box mb={3}>
            <Label htmlFor="confirmPassword" style={{ fontSize: "17px", fontWeight: '600' }}>Confirm Password</Label>
            <div className="p-div">
              <Input type={confirmPasswordVisible ? "text" : "password"} id="confirmPassword" name="confirmPassword" className='form-control' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.confirmPassword} placeholder="Enter your confirm password"
                sx={{
                  width: '100%', p: 2, mt: 0.5, border: '1px solid', borderColor: formik.touched.confirmPassword && formik.errors.confirmPassword ? `${theme?.colors?.red}` : 'red',
                }} />
              <img style={{ display: `${formik.values.confirmPassword?.length > 0 ? "block" : "none"}` }} src={confirmPasswordVisible ? openeye : closeeye}
                alt={confirmPasswordVisible ? "Hide password" : "Show password"}
                className="eye-icon"
                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} />
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <Text color="red"> {formik.errors.confirmPassword} </Text>
            )}
          </Box>
          <Button type="submit" sx={{ width: '100%', bg: `${theme.colors?.primary}`, color: `${theme?.colors?.white}`, py: 2, borderRadius: '4px', cursor: 'pointer' }}>
            {loader ? (<Spinner sx={{ color: theme.colors?.background, width: 3, p: 0, m: 0 }} />) : ("Reset Password")}
          </Button>
        </form>
        <Box mt={3}>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: `${theme.colors?.primary}` }}>
            Back to Dashboard
          </Link>
        </Box>
      </Box>
    </Flex>
  );
};

export default ResetPassword;


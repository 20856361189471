import { ACTION_HIGHLIGHTS_FAIL, ADD_COMMENT_ACTION_HIGHLIGHTS, ADD_DESCRIPTION_ACTION_HIGHLIGHTS, ADD_DESCRIPTION_BULK_ACTION_HIGHLIGHTS, CREATE_ACTION_HIGHLIGHTS, CREATE_ACTION_HIGHLIGHTS_SUCCESS, CREATE_BC_DONE_HIGHLIGHTS, CREATE_BC_DONE_HIGHLIGHTS_SUCCESS, DELETE_ACTION__MULTIPLE_HIGHLIGHTS, DELETE_ACTION_HIGHLIGHTS, DELETE_ACTION_HIGHLIGHTS_SUCCESS, DELETE_ACTION_MULTIPLE_HIGHLIGHTS_SUCCESS, DELETE_COMMENT_ACTION_HIGHLIGHTS, EDIT_ACTION_HIGHLIGHTS, EDIT_ACTION_HIGHLIGHTS_SUCCESS, EDIT_ACTION_PAYLOAD, EDIT_ACTION_PAYLOAD_SUCCESS, EDIT_COMMENT_ACTION_HIGHLIGHTS, GET_ACTION_BY_ID, GET_ACTION_BY_ID_SUCCESS, GET_ACTION_HIGHLIGHTS_BY_IDS, GET_ACTION_HIGHLIGHTS_BY_IDS_SUCCESS, GET_ALL_ACTION_BY_NAME, GET_ALL_ACTION_BY_NAME_SUCCESS, GET_ALL_BC_PC_REV_STAGES, GET_ALL_BC_PC_REV_STAGES_SUCCESS, GET_ALL_DELIVER_CHECKLIST, GET_ALL_DELIVER_CHECKLIST_SUCCESS, GET_ALL_TEMPLATES, GET_ALL_TEMPLATES_SUCCESS, GET_EXCEL_REPORT, GET_EXCEL_REPORT_SUCCESS, REMOVE_HIGHLIGHTER, REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW, REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW_SUCCESS, REMOVE_HIGHLIGHTER_FROM_TASKVIEW, REMOVE_HIGHLIGHTER_FROM_TASKVIEW_SUCCESS, REMOVE_HIGHLIGHTER_SUCCESS } from "../storeTypes";

const initialState: any = {
  actionHighlights: null,
  loader: false,
  error: null,
  createHighlight: null,
  deliverChecklist: null,
  templateList: null,
  bcActionDone: null,
  removeHighlights: null,
  removeHighlightsTask: null,
  removeHighlightsProject: null,
  actionnameList: null,
  isLoader: false,
  excelReport: null,
  BcPclist: null

};

const ActionHighlight = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ACTION_HIGHLIGHTS_BY_IDS: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ACTION_HIGHLIGHTS_BY_IDS_SUCCESS: {
      return {
        ...state,
        loader: false,
        actionHighlights: action.payload
      };
    }
    case GET_ACTION_BY_ID: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ACTION_BY_ID_SUCCESS: {
      return {
        ...state,
        loader: false,
        actionHighlights: action.payload
      };
    }
    case GET_ALL_ACTION_BY_NAME: {
      return {
        ...state,
        loader: true,
        isLoader: true,
        actionnameList: null
      };
    }
    case GET_ALL_ACTION_BY_NAME_SUCCESS: {
      return {
        ...state,
        loader: false,
        isLoader: false,
        actionnameList: action.payload
      };
    }
    case GET_ALL_BC_PC_REV_STAGES: {
      return {
        ...state,
        loader: true,
        isLoader: true,
        BcPclist: null
      };
    }
    case GET_ALL_BC_PC_REV_STAGES_SUCCESS: {
      return {
        ...state,
        loader: false,
        isLoader: false,
        BcPclist: action.payload
      };
    }
    case GET_ALL_DELIVER_CHECKLIST: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ALL_DELIVER_CHECKLIST_SUCCESS: {
      return {
        ...state,
        loader: false,
        deliverChecklist: action.payload
      };
    }
    case REMOVE_HIGHLIGHTER: {
      return {
        ...state,
        loader: true,
      };
    }
    case REMOVE_HIGHLIGHTER_SUCCESS: {
      return {
        ...state,
        loader: false,
        removeHighlights: action.payload
      };
    }
    case REMOVE_HIGHLIGHTER_FROM_TASKVIEW: {
      return {
        ...state,
        loader: true,
      };
    }
    case REMOVE_HIGHLIGHTER_FROM_TASKVIEW_SUCCESS: {
      return {
        ...state,
        loader: false,
        removeHighlightsTask: action.payload
      };
    }
    case REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW: {
      return {
        ...state,
        loader: true,
      };
    }
    case REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW_SUCCESS: {
      return {
        ...state,
        loader: false,
        removeHighlightsProject: action.payload
      };
    }
    case GET_ALL_TEMPLATES: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_ALL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        loader: false,
        templateList: action.payload
      };
    }
    case CREATE_ACTION_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
        isLoader: true
      };
    }
    case CREATE_ACTION_HIGHLIGHTS_SUCCESS: {
      return {
        ...state,
        loader: false,
        isLoader: false,
        createHighlight: action.payload
      }
    }
    case CREATE_BC_DONE_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case CREATE_BC_DONE_HIGHLIGHTS_SUCCESS: {
      return {
        ...state,
        loader: false,
        bcActionDone: action.payload
      }
    }
    case EDIT_ACTION_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_ACTION_HIGHLIGHTS_SUCCESS: {
      return {
        ...state,
        loader: false,
        createHighlight: action.payload
      }
    }
    case ADD_DESCRIPTION_ACTION_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case ADD_DESCRIPTION_BULK_ACTION_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_ACTION_PAYLOAD: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_ACTION_PAYLOAD_SUCCESS: {
      return {
        ...state,
        loader: false,
        createHighlight: action.payload
      }
    }
    case DELETE_ACTION_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_ACTION_HIGHLIGHTS_SUCCESS: {
      return {
        ...state,
        loader: false,
        createHighlight: action.payload
      }
    }
    case DELETE_ACTION__MULTIPLE_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_ACTION_MULTIPLE_HIGHLIGHTS_SUCCESS: {
      return {
        ...state,
        loader: false,
        createHighlight: action.payload
      }
    }
    case ADD_COMMENT_ACTION_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case EDIT_COMMENT_ACTION_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_COMMENT_ACTION_HIGHLIGHTS: {
      return {
        ...state,
        loader: true,
      };
    }
    case ACTION_HIGHLIGHTS_FAIL: {
      return {
        ...state,
        loader: false,
        error: action.payload
      };
    }
    case GET_EXCEL_REPORT: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_EXCEL_REPORT_SUCCESS: {
      return {
        ...state,
        loader: false,
        excelReport: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default ActionHighlight;
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createActionHighlightSuccess, getActionByName, getActionByNameSuccess, getBcPcRevStages } from "../../../store/actions/action.Highlighters";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Badge, Button, Card, Flex, Link, Text } from "theme-ui";
import CreateBC from "./CreateBC";
import ActionBcPcChecklist from "./ActionBcPcChecklist";
import { RootState } from "../../../../@types";
import CustomTooltip from "../../CustomTooltip";
import { FaLink, FaPlus } from "react-icons/fa";
import { rolePermissionsHighLighters } from "../../../utils/CustomHandler";
import DataTable from "../../Tabels/CommonBCPCRETable";
import { ColumnDef } from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import theme from "../../../theme";

const ActionBackgroundCheck = ({ Open, setOpen, selectedHighlight, actionObject, keys, showAddNewButton = true }: any) => {
    const { auther } = useSelector((state: RootState) => state.auth);
    const is_Task_Worker = auther?.Role?.map(role => role?.RoleName).every(role => role.toLocaleLowerCase() === "task worker")
    const { actionnameList, createHighlight, loader, BcPclist } = useSelector((state: any) => state.actionHighlight)
    const [showCreateBCModal, setShowCreateBCModal] = useState<boolean>(false);
    const [showActionBcPcChecklistModal, setShowActionBcPcChecklistModal] = useState<boolean>(false);
    const [actionId, setActionId] = useState("");
    const { stageId } = useParams<{ stageId: string }>();

    const { taskbyproject } = useSelector((state: any) => state.project);


    const projectId = actionObject?.projectId;
    // const taskIdSet = new Set<string>();
    // const uniqueTasks = taskbyproject?.filter((obj: any) => {
    //     const taskId = obj?.taskId?._id;
    //     const isUnique = taskId && !taskIdSet?.has(taskId);
    //     if (isUnique) {
    //         taskIdSet?.add(taskId);
    //     }
    //     return isUnique;
    // });

    const taskOptions = taskbyproject?.map((obj: any) => ({
        label: obj?.taskId?.name,
        value: obj?._id,
    }));
    const groupedData = BcPclist?.reduce((acc: any, curr: any) => {
        const stageName = curr.stageId.name;
        if (!acc[stageName]) {
            acc[stageName] = [];
        }
        acc[stageName].push(curr);
        return acc;
    }, {});

    const dispatch = useDispatch();

    const togglePopover = () => {
        setOpen(!Open);
        setShowCreateBCModal(false);
        setShowActionBcPcChecklistModal(false);
        setActionId("");
    }
    const handleDeliverClick = () => {
        (is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? setShowCreateBCModal(false) : setShowCreateBCModal(true);

    };

    const handleTemplateClick = (id: string) => {
        setActionId(id)
        setShowActionBcPcChecklistModal(true);
    };

    useEffect(() => {
        dispatch(createActionHighlightSuccess([]))
    }, [])

    useEffect(() => {
        if (createHighlight?.data?._id) {
            setActionId(createHighlight?.data?._id)
            setShowActionBcPcChecklistModal(true)
            setShowCreateBCModal(false);
        }
    }, [createHighlight?.data?._id])

    useEffect(() => {
        if (Open) {
            dispatch(getActionByName(actionObject, { name: selectedHighlight?.name }))
            dispatch(getBcPcRevStages(projectId, { name: selectedHighlight?.name }))
        }
    }, [selectedHighlight, Open, createHighlight])

    useEffect(() => {
        if (Open) {
            setShowActionBcPcChecklistModal(false)
        }

        return () => {
            dispatch(getActionByNameSuccess([]))
            dispatch(createActionHighlightSuccess([]))
        }

    }, [Open])
    const { paginations } = useSelector((state: any) => state.pagination);

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            header: 'Sr No.',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((paginations?.currentPage - 1) * paginations?.limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "bc_pc_revision_name",
            header: 'Name',
            cell: ({ row }) => {
                return (
                    <Text title={row?.original?.payload?.name} sx={{ textDecoration: 'underline', color: `${theme?.colors?.secondary}`, cursor: "pointer", fontWeight: 500 }} variant='Poppins14Normal24lh' onClick={() => handleTemplateClick(row?.original?._id)}>
                        {row?.original?.payload?.name.slice(0, 20)}
                    </Text>
                )
            },
        },
        {
            header: 'Version',
            accessorKey: "version",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{row?.original?.payload?.number}</Text>
                )
            }
        },
        {
            header: 'Major/Minor',
            accessorKey: "major/minor",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{row?.original?.payload?.majorMinor}</Text>
                )
            }
        },
        {
            header: 'Tasks',
            accessorKey: "tasks",
            cell: ({ row }) => {
                const matchingTaskNames: any = [];
                if (taskOptions) {
                    console.log('chk149', taskOptions);

                    for (const task of taskOptions) {
                        if (row?.original?.payload?.taskId?.includes(task?.value)) {
                            matchingTaskNames?.push(task?.label);
                        }
                    }
                }

                return (
                    // <Text variant='Poppins14Normal24lh'>{row?.original?.payload?.taskId?.join(', ')}</Text>
                    <Text variant='Poppins14Normal24lh' sx={{ alignItems: "center" }}>
                        {
                            matchingTaskNames?.map((taskName: string) => {
                                console.log('chk162', taskName);

                                return (<Text as={"li"} variant='Poppins14Normal24lh'>
                                    {/* {matchingTaskNames?.join(", ")}{} */}
                                    {taskName}
                                </Text>)
                            })
                        }
                    </Text>
                )
            }
        },
        {
            header: 'Link',
            accessorKey: "link",
            cell: ({ row }) => {
                return (
                    <Text variant="Poppins14Normal24lh">
                        {/* <Text variant="Poppins14Normal" className='ms-2'> */}
                        {
                            Array?.isArray(row?.original?.payload?.emailLink) ? (
                                row?.original?.payload?.emailLink?.map((email: string, index: number) => (
                                    <Text title={email}>
                                        <Link sx={{ textDecoration: "none" }} key={index} target='_blank' href={email}>
                                            {/* {email} */}
                                            <FaLink />&nbsp;&nbsp;
                                            {/* {index < row?.original?.payload?.emailLink?.length - 1 && " "} */}
                                        </Link>
                                    </Text>
                                ))
                            ) : (
                                null
                            )
                        }
                        {/* </Text> */}
                    </Text >
                )
            }
        },
        {
            header: 'Description',
            accessorKey: "description",
            cell: ({ row }) => {
                return (
                    <Text title={row?.original?.payload?.description} variant="Poppins14Normal24lh">{row?.original?.payload?.description.slice(0, 20) + (row?.original?.payload?.description.length > 20 ? " ..." : "")}</Text>
                )
            }
        },
        {
            header: 'Created At',
            accessorKey: "createdAt",
            cell: ({ row }) => {
                return (
                    <Text variant="Poppins14Normal24lh">{row?.original?.createdAt?.split("T")?.[0]}</Text>
                )
            }
        }
    ], [actionnameList]);



    return (
        <Modal isOpen={Open} scrollable toggle={togglePopover} size="lg" centered >

            <ModalHeader toggle={togglePopover}>
                <Flex sx={{ gap: "1rem" }}><img src={selectedHighlight?.icon} /><Text sx={{ color: selectedHighlight?.color }}>{selectedHighlight?.name?.toUpperCase()}</Text></Flex></ModalHeader>

            <ModalBody>
                <Flex className="justify-content-end">
                    {showAddNewButton && (
                        is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name) ? (
                            <CustomTooltip id={selectedHighlight?.name.trim()} title="Permission Denied">
                                <Button type='button' disabled={is_Task_Worker} sx={{ marginLeft: "auto", padding: '8px 12px', borderRadius: "12px", cursor: "not-allowed" }}><FaPlus className="mx-1" />Add New</Button>
                            </CustomTooltip>
                        ) : (
                            <Button variant="SaveButton" onClick={handleDeliverClick} type="submit" sx={{ marginLeft: "auto", padding: '8px 12px', pointerEvents: is_Task_Worker ? "none" : "all" }}>+ Add New</Button>
                        )
                    )}
                </Flex>
                {groupedData && Object?.keys(groupedData)?.map(stageName => {
                    const stageColor = groupedData[stageName]?.[0]?.stageId?.color || 'black';
                    const isCurrentStage = groupedData[stageName]?.[0]?.stageId?._id === stageId;

                    return (

                        <Card key={stageName} sx={{ boxShadow: "none", border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "8px 16px", position: "relative", marginBottom: "20px", marginTop: '10px' }}>
                            <Flex sx={{ alignItems: "center", marginBottom: "20px" }}>
                                <Badge sx={{
                                    color: stageColor,
                                    border: `1px solid ${stageColor}`,
                                    borderRadius: 6,
                                    backgroundColor: `${theme?.colors?.transparent}`,
                                    margin: "5px",
                                    fontSize: '20px'
                                }}>
                                    {stageName}
                                </Badge>
                                {/* {showAddNewButton && (
                                    is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name) ? (
                                        <CustomTooltip id={selectedHighlight?.name.trim()} title="Permission Denied">
                                            <Button type='button' disabled={is_Task_Worker} sx={{ marginLeft: "auto", padding: '8px 12px', borderRadius: "12px", cursor: "not-allowed" }}><FaPlus className="mx-1" />Add New</Button>
                                        </CustomTooltip>
                                    ) : (
                                        <Button variant="SaveButton" onClick={handleDeliverClick} type="submit" sx={{ marginLeft: "auto", padding: '8px 12px', pointerEvents: is_Task_Worker ? "none" : "all" }}>+ Add New</Button>
                                    )
                                )} */}
                            </Flex>

                            <DataTable
                                loader={loader}
                                columns={columns}
                                data={groupedData[stageName]?.length > 0 ? groupedData[stageName] : []}
                                perPageCount={paginations.limit}
                                currentPage={paginations.currentPage}
                                showNavigation={false}
                                onPerPageChange={(itemsPerPage) => { }}
                                onPageChange={(value) => { }}
                            />
                        </Card>
                    )
                })}
            </ModalBody>
            <ModalFooter className='gap-2 justify-content-end'>
                {/* <Button variant="CancelButton" className='me-2 px-5' onClick={togglePopover} >Cancel</Button> */}
                {/* <Button variant="SaveButton" onClick={handleDeliverClick} type="submit" >+ Add New</Button> */}
            </ModalFooter>

            <CreateBC selectedHighlight={selectedHighlight} Open={showCreateBCModal} setOpen={setShowCreateBCModal} actionObject={actionObject} setActionId={setActionId} />
            {/* {(showActionBcPcChecklistModal && actionId) && ( */}
            <ActionBcPcChecklist Open={showActionBcPcChecklistModal} selectedHighlight={selectedHighlight} setOpen={() => setShowActionBcPcChecklistModal(false)} actionId={actionId} actionObject={actionObject} keys={keys} />
            {/* )} */}

        </Modal>
    )
}

export default ActionBackgroundCheck
import { FormikErrors, FormikTouched } from "formik";
import React, { ChangeEvent, FocusEvent, useEffect, useRef } from "react";
import { Box, Checkbox, Input, Label, Select, Text, ThemeUIStyleObject } from "theme-ui";

interface InputformProps {
    label?: string;
    Placeholder: string;
    Name: string;
    sx?: ThemeUIStyleObject;
    Title_sx?: ThemeUIStyleObject;
    type?: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week' | 'select' | undefined;
    values: any;
    ref?: any;
    touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
    errors?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined | any;
    Max_Length?: number;
    OnChange?: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    onClick?: any;
    onBlur?: (e: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
    onKeyDown?: (e: KeyboardEvent) => void;
    handleSubmit?: () => void;
    autoFocus?: boolean;
    disabled?: boolean;
    description?: string;
    options?: string[] | { value: string; label: string }[];
    mb?: number | undefined | Array<number>;
    asterisk?: boolean;
    max?: string | number
}

export const Inputform: React.FC<InputformProps> = ({
    label,
    type,
    Placeholder,
    OnChange = () => null,
    Title_sx,
    sx,
    Name,
    onClick = () => null,
    values,
    onBlur = () => null,
    onKeyDown = () => null,
    handleSubmit = () => null,
    autoFocus = false,
    description = "",
    touched = false,
    disabled = false,
    errors = "",
    options = [],
    mb,
    asterisk,
    max,
}) => {
    // console.log(values, "check48");
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);


    return (
        <Box
            mb={mb}
        >
            <Text variant="SelectLabel" >
                {label}
                {asterisk && <span className="mx-1 text-danger">*</span>}
            </Text>
            {type === 'checkbox' ? (
                <Label>
                    <Checkbox
                        name={Name}
                        checked={values}
                        onChange={OnChange}
                        onBlur={onBlur}
                        disabled={disabled}
                        sx={{ ...sx, borderColor: errors && touched && "red" }}
                    />
                </Label>
            ) : type === 'select' ? (
                <Select
                    name={Name}
                    value={values}
                    onChange={OnChange}
                    onBlur={onBlur}
                    disabled={disabled}
                    sx={{ ...sx, borderColor: errors && touched && "red" }}
                >
                    {options.map((option, index) => (
                        typeof option === 'string' ? (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ) : (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        )
                    ))}
                </Select>
            ) : (
                <Input
                    variant="PersonalDetailsInput"
                    ref={inputRef}
                    placeholder={Placeholder}
                    name={Name}
                    type={type}
                    max={max ? max : ''}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    autoComplete="off"
                    className="form-textcontrol"
                    sx={{
                        py: [9, 10, 13, 13, 14, 15],
                        px: 18,
                        borderRadius: "10px",
                        ...sx,
                        borderColor: errors && touched && "red",
                    }}
                    onChange={OnChange}
                    onClick={onClick}
                    onBlur={onBlur}
                    onKeyDown={(e) => {
                        if (e?.code === "Enter" || e?.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                    value={values}
                />
            )}

            {errors && touched ? (
                <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: "5px", minHeight: "12px", lineHeight: '10px' }}>
                    {errors.toString()}
                </Text>
            ) : (
                <Text variant="Inter15Regular30lh" sx={{ my: "5px", minHeight: "11px" }}>
                    {description}
                </Text>
            )}
        </Box>
    );
};

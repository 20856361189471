import { ColumnDef } from '@tanstack/react-table';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Divider, Flex, Paragraph, Text } from 'theme-ui';
import { RootState } from '../../../../@types';
import { getMyReviews } from '../../../store/actions/Rating.Action';
import { UpdateFrontAppRow } from '../../../store/actions/frontApp.Action';
import { getApiEndPoint } from '../../../utils/GenerateQueryString';
import { baseUrl } from '../../../utils/global';
import DataTable from '../../Tabels/Tabel';
import { TabelHeadingTxt } from '../../Text/TabelCommonTxt';
import exprt from "../../assets/images/Icon/Export.svg";
import { useQuery } from '@tanstack/react-query';
import { FaStar } from 'react-icons/fa';
import { setPagination } from '../../../store/actions/Pagination.action';
import moment from 'moment';
// import { getAllFrontapp } from '../../../store/actions/frontApp.Action';
const MyReviews: React.FC = () => {
    const location = useLocation();
    const { MyRating, loader } = useSelector((state: RootState) => state?.rating);
    const { paginations } = useSelector((state: any) => state.pagination);
    const { dataForTable } = useSelector((state: any) => state.masterData);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });

    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");

    const columns = useMemo<ColumnDef<any>[]>(() => [
        // {
        //     accessorKey: "id",
        //     id: "select",
        //     enableColumnFilter: false,
        //     header: ({ table }) => (
        //         <div className="d-flex justify-content-center">
        //             <IndeterminateCheckbox
        //                 {...{
        //                     checked: table.getIsAllRowsSelected(),
        //                     indeterminate: table.getIsSomeRowsSelected(),
        //                     onChange: table.getToggleAllRowsSelectedHandler(),
        //                 }}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }) => (
        //         <IndeterminateCheckbox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        // },
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "projectName",
            header: 'Project Name',
            cell: ({ row }) => {

                return (
                    <Text>{row?.original?.projectName}</Text>
                )
            },
            filterType: "textbox"
        },
        // {
        //     accessorKey: "tradeName",
        //     header: 'Trade Name',queryString
        //     cell: ({ row }) => {
        //         return (
        //             <p>{row?.original?.tradeName}</p>
        //         )
        //     },
        //     filterType: 'textbox',
        // },
        // {
        //     header: 'Worker',
        //     accessorKey: "workerName",
        //     cell: ({ row }) => {

        //         return (
        //             <p>
        //                 {row?.original?.workerName}
        //             </p>
        //         )
        //     },
        //     filterType: 'textbox',

        // },
        {
            header: 'Task Name',
            accessorKey: "tradeName",
            id: 'tradeName',
            cell: ({ row }) => {
                return (
                    <Text >{row?.original?.tradeName}</Text>
                )
            },
            filterType: 'textbox',
            // enableColumnFilter: true,
            // filterType: 'dropdown',
            // options: dataForTable?.data?.allTasks?.map((val: { _id: string, FirstName: string }) => ({ value: val._id, label: val.FirstName }))
        },
        {
            header: 'TM Worker',
            accessorKey: "workerName",
            id: 'taskWorker',
            cell: ({ row }) => {
                return (
                    <Text >{row?.original?.workerName}</Text>
                )
            },
            filterType: 'textbox',
            // enableColumnFilter: true,
            // filterType: 'dropdown',
            // options: dataForTable?.data?.allTaskWorkers?.map((val: { _id: string, FirstName: string }) => ({ value: val._id, label: val.FirstName }))
        },
        {
            header: 'Phase',
            accessorKey: "projectPhaseLabel",
            cell: ({ row }) => {
                return (
                    <p>{row?.original?.projectPhaseLabel}</p>
                )
            },
            filterType: 'textbox',
        },
        {
            header: 'Review Date',
            accessorKey: "createdAt",
            cell: ({ row }) => {
                const reviewDate = row?.original?.createdAt;
                return (
                    <p>{reviewDate ? moment(reviewDate.split('T')[0]).format("MMM DD, YYYY") : ""}</p>
                )
            },
            filterType: 'date',
        },
        {
            header: 'Criteria',
            accessor: "criteria",
            cell: ({ row }) => {
                return (
                    <div >
                        {row?.original?.criteria?.map((criteria: any, index: any) => (
                            <Flex key={index} sx={{ borderTop: `${index !== 0 && "0.5px solid #D3D5D9"}`, paddingY: "5px" }}>
                                <Text sx={{ fontWeight: "600" }}>{criteria?.rating}</Text> <FaStar color="#FFB721" className='mx-2 mt-1' />
                                <Paragraph sx={{ textWrap: "nowrap" }}>{criteria?.name}</Paragraph>
                            </Flex>
                        ))}
                    </div>
                );
            },
            filter: 'textbox'
        },

    ], [MyRating?.data]);
    useEffect(() => {
        return () => {
            dispatch(setPagination({
                limit: 15,
                currentPage: 1,
            }))
        }
    }, [dispatch])
    useEffect(() => {
        let apiEndPoint = getApiEndPoint({ limit, currentPage }, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
        dispatch(getMyReviews(apiEndPoint))
    }, [location?.search, limit, currentPage]);

    useEffect(() => {
        let queryValueInObj = queryString.parse(location.search);
        if (queryValueInObj["id"]) {
            let idValue: string = Array.isArray(queryValueInObj["id"])
                ? queryValueInObj["id"][0] || "" // Use the first element or provide a default value
                : queryValueInObj["id"] || "";   // Handle the case where it might be null

            setIsOpen(true);
            // setStageId(idValue);
        }
    }, [location.search]);

    const { data: eventSource } = useQuery({
        queryKey: ['frontAppSSE1'],
        queryFn: () => {
            const eventSource = new EventSource(`${baseUrl}sse/frontApp`);

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    dispatch(UpdateFrontAppRow(data))
                    // handleProjectUpdate(data); // Uncomment this line if you have a function to handle the project update
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };

            eventSource.onerror = (error) => {
                console.error('SSE Error:', error);
                eventSource.close();
            };
            return eventSource;
        },
        refetchInterval: 60000
    })
    useEffect(() => {
        return () => {
            eventSource?.close();
        };
    }, [])


    return (
        <>
            <div className='main-content'>
                <TabelHeadingTxt Title='Rating Review' Count={MyRating?.count} IsExportButtonShow={true} src={exprt} BtnTxt='Export' url='/my-review' ml={'60px'} />
                <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
                <DataTable
                    loader={loader}
                    columns={columns}
                    fixedColumn={true}
                    data={MyRating?.data || []}
                    TotalLength={MyRating?.count || 15}
                    perPageCount={paginations.limit}
                    currentPage={paginations.currentPage}
                    onPerPageChange={(itemsPerPage: string) => {
                        if (searchParams?.size > 2) {
                            const parsedQuery = queryString.parse(location.search);

                            setSearchParams({
                                ...parsedQuery,
                                limit: itemsPerPage.toString(),
                            });
                        } else {
                            setSearchParams({
                                currentPage: currentPage.toString(),
                                limit: itemsPerPage.toString(),
                            });
                        }
                    }}
                    onPageChange={(value: any) => {
                        if (searchParams?.size > 2) {
                            const parsedQuery = queryString.parse(location.search);

                            setSearchParams({
                                ...parsedQuery,
                                currentPage: value.toString(),
                            });
                        } else {
                            setSearchParams({
                                currentPage: value.toString(),
                                limit: limit.toString(),
                            });
                        }
                    }}
                />
            </div>
        </>
    )
};

export default MyReviews;

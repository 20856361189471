import { useFormik } from "formik";
import { Option, StartModalProps, startTimerFormValues } from "./GeneralTimerStart";
import * as Yup from 'yup';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box, Button, Flex, Text } from "theme-ui";
import { Inputform } from "../../../forms-component/InputForm";
import FormSelect from "../../../forms-component/globalSelect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getAllUserWithoutPagination } from "../../../../store/actions/user.action";
import { MultiSelect } from "react-multi-select-component";
import { msToTime } from "../../../../utils/GenerateQueryString";
import { StopTracking } from "../../../../store/actions/project.action";
import toast from "react-hot-toast";
import { RootState } from "../../../../../@types";
import { categoryLevel, estimatedReason } from "../../../../utils/constent";

const convertToMilliseconds = (hours: number, minutes: number, seconds: number) => {
    return (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);
};
const GeneralTimerStop: React.FC<StartModalProps> = ({ isOpen, toggle, projectId, stageId }) => {
    const { elapsedTime, timeTracking, Latesttime } = useSelector((state: any) => state.project);
    const { dataForTable } = useSelector((state: any) => state.masterData);

    const projectOptions: Option[] = dataForTable?.data?.allProjects?.map((obj: any) => ({
        label: obj?.name,
        value: obj?._id,
    }));
    const stageOptions: Option[] = dataForTable?.data?.allStages?.map((obj: any) => ({
        label: obj?.name,
        value: obj?._id,
    }));
    const [selectedCategoryLevel, setSelectedCategoryLevel] = useState<string | null>(null);

    const dispatch = useDispatch();
    const userData = useSelector((state: any) => state?.user);
    const Users = userData?.userData?.data;
    const intervalRef = useRef<any>(null);
    const { auther } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        dispatch(getAllUserWithoutPagination())
    }, [dispatch]);

    const membersOptions: Option[] = Users?.map((user: any) => ({
        label: `${user?.FirstName} ${user?.LastName}` || 'unknown',
        value: user?._id,
    }));
    const SubCategoryOptionsWithProjectOrStage: Option[] = [
        { label: 'Phone Call', value: 'phoneCall' },
        { label: 'Scheduling', value: 'scheduling' },
        { label: 'Internal Team Meeting', value: 'internalTeamMeeting' },
        { label: 'Training', value: 'training' },
        { label: 'Email Processing', value: 'emailProcessing' },
        { label: 'Email Processing/Scheduling', value: 'emailProcessingScheduling' },
        { label: 'MEP Paper SetUp', value: 'MEPPaperSetUp' },
        { label: 'Project Scheduling', value: 'projectScheduling' },
        { label: 'Internal Meeting', value: 'internalMeeting' },
        { label: 'BC & PS', value: 'bcPs' },
        { label: 'Technical Discussion', value: 'technicalDiscussion' },
        { label: 'Project Meeting Preparation', value: 'projectMeetingPreparation' },
        { label: 'BC (xrefs)', value: 'BC(xrefs)' },
        { label: 'PS (xrefs)', value: 'PS(xrefs)' },
        { label: 'Publishing', value: 'Publishing' },
        { label: 'Quality Control', value: 'qualityControl' },
        { label: 'Meeting Preparation', value: 'meetingPreparation' },
        { label: 'ProCore Management ', value: 'proCoreManagement' },
        { label: 'Project Review', value: 'projectReview' },
        { label: 'External Client Meeting', value: 'externalClientMeeting' },
        { label: 'RFI/Submittal', value: 'rfiSubmittal' },

    ];
    const SubCategoryOptionsWithoutProjectOrStage: Option[] = [
        { label: 'Phone Call', value: 'phoneCall' },
        { label: 'Scheduling', value: 'scheduling' },
        { label: 'Internal Team Meeting', value: 'internalTeamMeeting' },
        { label: 'Training', value: 'training' },
        { label: 'Publishing', value: 'Publishing' },
        { label: 'Quality Control', value: 'qualityControl' },
        { label: 'Meeting Preparation', value: 'meetingPreparation' },
        { label: 'ProCore Management ', value: 'proCoreManagement' },
        { label: 'Project Review', value: 'projectReview' },
        { label: 'Lunch Break', value: 'lunchBreak' },
        { label: 'TODO List', value: 'toDoList' },
        { label: 'MEP Paper SetUp', value: 'MEPPaperSetUp' },
        { label: 'Meeting recording view', value: 'meetingRecordingView' },
        { label: 'G-chat space threads view', value: 'gchatSpaceThreads' },
        { label: 'Daily Work-done Commit review', value: 'dailyCommitReview' },
        { label: 'Hours Report Review', value: 'hoursReportReview' },
        { label: 'Performance Review', value: 'performanceReview' },
        { label: 'BC (xrefs)', value: 'BC(xrefs)' },
        { label: 'PS (xrefs)', value: 'PS(xrefs)' },
        { label: 'Email Processing', value: 'emailProcessing' },
        { label: 'Project Scheduling', value: 'projectScheduling' },
        { label: 'General PM Tasks', value: 'generalPMTasks' },
        { label: 'Internal Meeting', value: 'internalMeeting' },
        { label: 'External Client Meeting', value: 'externalClientMeeting' },
        { label: 'Email Processing/Scheduling', value: 'emailProcessingScheduling' },
        { label: 'Email Processing', value: 'emailProcessing' },
        { label: 'Sales', value: 'sales' },
        { label: 'Non Design', value: 'nonDesign' },
        { label: 'RFI/Submittal', value: 'rfiSubmittal' },

    ];

    const formik = useFormik<startTimerFormValues>({
        enableReinitialize: true,
        initialValues: {
            id: timeTracking?._id || "",
            title: timeTracking?.title || '',
            projectId: timeTracking?.projectId?._id || null,
            stageId: timeTracking?.stageId?._id || null,
            taskId: timeTracking?.taskId?._id || null,
            members: timeTracking?.members?.map((item: any) => item?._id) || [],
            subCategory: timeTracking?.subCategory || '',
            description: '',
            startTime: timeTracking?.startTime || '',
            category: timeTracking?.category || '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required('Title is Required'),
            description: Yup.string().required('Description is required'),
            members: Yup.array().min(1, 'At least one member is required'),
            category: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {

            const payload = {
                ...values,
                category: timeTracking?.category,
                members: membersWithTime.map((member: any) => ({
                    _id: member._id,
                    timeTaken: member.timeTaken,
                })),
            };
            console.warn('payload', payload);

            dispatch(StopTracking(payload, values?.id))
            toast?.success("Spent time recorded successfully")

            formik.resetForm();
            toggle();
        },
    })
    const { handleSubmit, setFieldValue, values, handleBlur, touched, errors } = formik;

    const [membersWithTime, setMembersWithTime] = useState<any>([]);

    useEffect(() => {
        if (isOpen && timeTracking?.members) {
            const startTime = new Date(timeTracking?.startTime).getTime();

            const updateMembersTime = () => {
                const currentTime = Date.now();
                const updatedMembers = timeTracking?.members?.map((member: any) => {
                    const timeTakenInMillis = currentTime - startTime;
                    return {
                        _id: member._id,
                        name: `${member.FirstName} ${member.LastName}`,
                        timeTaken: timeTakenInMillis,
                    };
                });
                setMembersWithTime(updatedMembers);
            };
            intervalRef.current = setInterval(updateMembersTime, 1000);
            updateMembersTime();
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [isOpen, timeTracking?.members, timeTracking?.startTime]);
    useEffect(() => {

        const newMembers = values.members?.filter((id: any) => !membersWithTime?.some((member: any) => member._id === id));
        const existingMembers = membersWithTime?.filter((member: any) => values?.members.includes(member._id));
        const currentTime = Date.now();
        const startTime = new Date(timeTracking?.startTime).getTime();
        const timeTakenInMillis = currentTime - startTime;
        if (newMembers.length > 0) {
            const newMembersWithTime = newMembers?.map((id: any) => ({
                _id: id,
                name: membersOptions?.find((option) => option?.value === id)?.label || '',
                timeTaken: timeTakenInMillis,
            }));

            setMembersWithTime([...existingMembers, ...newMembersWithTime]);
        }
    }, [values.members, membersOptions, membersWithTime, timeTracking?.startTime]);

    const handleTimeTakenChange = (id: string, value: string) => {
        const [hours, minutes] = value.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) {
            console.warn("Invalid time input. Please enter time in HH:MM format.");
            return;
        }
        const timeInMillis = convertToMilliseconds(hours, minutes, 0);
        setMembersWithTime((prevState: any) =>
            prevState?.map((member: any) =>
                member._id === id ? { ...member, timeTaken: timeInMillis } : member
            )
        );
    };
    const filterOptions = (options: Option[], restrictedOptions: string[], allowedRoles: string[]) => {
        return options.filter(option => {
            const isRestricted = restrictedOptions.includes(option.label);
            const hasAllowedRole = auther?.Role?.some((role: any) =>
                allowedRoles.includes(role.RoleName)
            );
            if (isRestricted) {
                return hasAllowedRole;
            }
            return true;
        });
    };
    const getSubCategoryOptions = () => {
        const allowedRoles = ["Project Manager", "Project Worker", "Super Admin", "Region Admin"];
        const restrictedOptions = [
            "BC (xrefs)",
            "PS (xrefs)",
            "External Client Meeting"
        ];

        switch (selectedCategoryLevel) {
            case "project":
            case "stage":
                return filterOptions(SubCategoryOptionsWithProjectOrStage, restrictedOptions, allowedRoles);
            case "generalTimer":
                return filterOptions(SubCategoryOptionsWithoutProjectOrStage, [
                    "Email Processing",
                    "Project Scheduling",
                    "General PM Tasks",
                    "Internal Meeting",
                    "Email Processing/Scheduling"
                ], allowedRoles);
            case "task":
                return estimatedReason;
            default:
                return [];
        }
    };
    const currentSubCategoryOptions = getSubCategoryOptions();


    return (
        <Modal isOpen={isOpen} centered size='lg' toggle={toggle}>
            <ModalHeader toggle={toggle}>Stop Timer</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    <Flex>
                        <Box className='col-6 ps-2'>
                            <Inputform
                                label={"Title"}
                                Placeholder={"Enter Title"}
                                Name={"title"}
                                type='text'
                                Title_sx={{ color: "grey3" }}
                                touched={touched?.title}
                                asterisk={true}
                                errors={errors?.title}
                                values={values?.title}
                                onBlur={handleBlur}
                                OnChange={(event: any) => {
                                    const newName = event.target.value;
                                    setFieldValue("title", newName, true);
                                }}
                            />
                        </Box>
                        <Box className="col-6 ps-2 ">
                            <Inputform
                                label={"Description"}
                                Placeholder={"Enter Description..."}
                                Name={"description"}
                                Title_sx={{ color: "grey3" }}
                                asterisk={true}
                                touched={touched?.description}
                                errors={errors?.description}
                                values={values.description}
                                onBlur={handleBlur}
                                OnChange={(values: any) => {
                                    setFieldValue("description", values?.target?.value, true)
                                }}
                            />
                        </Box>

                    </Flex>
                    <Flex>
                        <Box className='col-6 ps-2'>
                            <Inputform
                                label={"Project"}
                                Placeholder='Select Project'
                                values={timeTracking?.projectId?.name}
                                options={projectOptions || []}
                                disabled={true}
                                onBlur={handleBlur}
                                Name='projectId'
                                sx={{ backgroundColor: '#a9a9a97a' }}
                                OnChange={(selectedProject: any) => {
                                    setFieldValue("projectId", selectedProject?.value || null, true);
                                }} />
                        </Box>
                        <Box className='col-6 ps-2'>
                            <Inputform
                                label={"Stage"}
                                Placeholder={`Select Stage`}
                                values={timeTracking?.stageId?.name}
                                options={stageOptions || []}
                                disabled={true}
                                onBlur={handleBlur}
                                sx={{ backgroundColor: '#a9a9a97a' }}
                                Name='stageId'
                                OnChange={(selectedStage: any) => {
                                    setFieldValue("stageId", selectedStage?.value || null, true);
                                }} />
                        </Box>

                    </Flex>
                    <Flex>
                        <Box className='col-6 ps-2'>
                            <Text variant="SelectLabel">Select Members</Text>
                            {membersOptions?.length > 0 && <MultiSelect
                                options={membersOptions}
                                value={(values?.members || [])
                                    .map((value: any) => membersOptions?.find((option) => option?.value === value))
                                    .filter((selectedOption: any) => selectedOption !== null && selectedOption !== undefined) as Option[]}
                                onChange={(selected: Option[]) => {
                                    const selectedValues = selected?.map((option) => option?.value);
                                    setFieldValue('members', selectedValues || []);
                                }}
                                labelledBy="Select" />}

                        </Box>

                        <Box className='col-6 ps-2'>
                            <FormSelect
                                label={"Level"}
                                placeholder='Select Level'
                                value={selectedCategoryLevel}
                                asterisk={true}
                                options={categoryLevel || []}
                                onBlur={handleBlur}

                                Name='level'
                                styles={{ color: "#000" }}
                                onChange={(selected: any) => setSelectedCategoryLevel(selected?.value || null)}

                            />
                        </Box>
                    </Flex>
                    <Flex>
                        <Box className='col-6 ps-2'>

                            <FormSelect
                                label={"Category"}
                                placeholder='Select Category'
                                value={values?.subCategory}
                                options={currentSubCategoryOptions || []}
                                // disabled={true}
                                onBlur={handleBlur}
                                Name='category'
                                styles={{ color: "#000" }}
                                onChange={(selectedsubCategory: any) => {
                                    setFieldValue("subCategory", selectedsubCategory?.value || '', true);
                                }} />
                        </Box>
                    </Flex>
                    <Box mt={3}>
                        <Text variant="SelectLabel">Members and Time Taken</Text>
                        {membersWithTime?.map((member: any) => {
                            const { hours, minutes } = msToTime(member.timeTaken);
                            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

                            return (
                                <Flex key={member._id} sx={{ alignItems: 'center', mb: 2 }}>
                                    <Text sx={{ width: '150px' }}>{member.name}</Text>
                                    <Inputform
                                        type='text'
                                        values={formattedTime}
                                        OnChange={(e: any) => handleTimeTakenChange(member._id, e.target.value)}
                                        sx={{ width: '100px' }}
                                        Placeholder="Enter Time (HH:MM)"
                                        Name={`member-${member._id}-timeTaken`}
                                    />
                                </Flex>
                            );
                        })}
                    </Box>
                    <Flex className="justify-content-end">
                        <Button type="submit">
                            Stop
                        </Button>
                    </Flex>
                </form>
            </ModalBody>

        </Modal>
    )
}

export default GeneralTimerStop
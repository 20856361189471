import { Box, Button, Flex, Heading, Image, Input, Label, Spinner, Text } from "theme-ui";
// import { emailVerify } from "../../../store/actions/login.action";
// import { ForgotFormValues, RootState } from "../../../../@types";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/logo 2 (1).svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import theme from "../../../theme";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import { forgetpassword } from "../../../store/actions/login.action";
import { RootState } from "../../../../@types";
import { useSelector } from "react-redux";
import { useState } from "react";
import openeye from "../../assets/images/Eye.svg";
import closeeye from "../../assets/images/Eyelashes 2D.svg";
interface ResetFormValues {
    userID?: string;
    token?: string;
    Password: string;
    confirmPassword: string;
}
const passwordRegex = /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,})\S$/;

const validationSchema = Yup.object({
    Password: Yup.string()
        .required('Please enter a new password')
        .matches(passwordRegex, "password must contain at least one uppercase letter, one lowercase letter, and one number"),
    confirmPassword: Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('Password')], 'The confirmation password does not match the password')
});

const ForgotPassword = () => {
    const navigate = useNavigate()
    const loader = useSelector((state: RootState) => state.auth.loader);
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const handleForgot = (values: ResetFormValues) => {
        // console.log("Forgot password form values:", payload);
        values["userID"] = searchParams.get("Id") || "";
        values["token"] = searchParams.get("token") || "";
        console.log(values, searchParams.get("Id"));

        dispatch(forgetpassword(values,
            (response: any) => {
                if (response) {
                    navigate("/login")
                }
            }))
    };

    const Formik = useFormik({
        initialValues: { Password: "", confirmPassword: "" },
        validationSchema: validationSchema,
        onSubmit: (values: ResetFormValues) => {
            handleForgot(values);
        },
    });

    return (
        <>
            <Flex
                sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
                <Image src={logo} alt="Logo" sx={{ width: ["70%", "30%", "20%"], height: 'auto' }} />
                <Heading as='h1' className="mt-5" sx={{ color: theme.colors?.secondary, lineHeight: 'heading' }}>Forgot Password</Heading>

                <Box sx={{ width: ['90%', '70%', '40%'], mt: 6, gap: "23px" }}>
                    <form onSubmit={Formik.handleSubmit}>
                        <Box mb={3}>
                            <Label htmlFor="Password" style={{ fontSize: "17px", fontWeight: '600' }}>New Password</Label>
                            {/* value={Formik.values.Password} */}
                            <div className="p-div">
                                <Input type={passwordVisible ? "text" : "password"} id="Password" name="Password" className='form-control' onChange={Formik.handleChange} onBlur={Formik.handleBlur} placeholder="Enter your new password"
                                    sx={{
                                        width: '100%', p: 2, mt: 1, border: '1px solid', borderColor: Formik.touched.Password && Formik.errors.Password ? `${theme?.colors?.red}` : 'red',
                                    }} />
                                <img style={{ display: `${Formik.values.Password?.length > 0 ? "block" : "none"}` }}
                                    src={passwordVisible ? openeye : closeeye}
                                    alt={passwordVisible ? "Hide password" : "Show password"}
                                    className="eye-icon"
                                    onClick={() => setPasswordVisible(!passwordVisible)} />
                            </div>
                            {Formik.touched.Password && Formik.errors.Password && (
                                <Text color="red"> {Formik.errors.Password} </Text>
                            )}
                        </Box>
                        <Box mb={3}>
                            <Label htmlFor="confirmPassword" style={{ fontSize: "17px", fontWeight: '600' }}>Confirm Password</Label>
                            {/* value={Formik.values.confirmPassword} */}
                            <div className="p-div">
                                <Input type={confirmPasswordVisible ? "text" : "password"} id="confirmPassword" name="confirmPassword" className='form-control' onChange={Formik.handleChange} onBlur={Formik.handleBlur} placeholder="Enter your confirm password"
                                    sx={{
                                        width: '100%', p: 2, mt: 1, border: '1px solid', borderColor: Formik.touched.confirmPassword && Formik.errors.confirmPassword ? `${theme?.colors?.red}` : 'red',
                                    }} />
                                <img style={{ display: `${Formik.values.confirmPassword?.length > 0 ? "block" : "none"}` }}
                                    src={confirmPasswordVisible ? openeye : closeeye}
                                    alt={confirmPasswordVisible ? "Hide password" : "Show password"}
                                    className="eye-icon"
                                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} />
                            </div>
                            {Formik.touched.confirmPassword && Formik.errors.confirmPassword && (
                                <Text sx={{ color: `${theme?.colors?.red}` }}> {Formik.errors.confirmPassword} </Text>
                            )}
                        </Box>
                        <Button type="submit" sx={{ width: '100%', bg: `${theme.colors?.primary}`, color: `${theme?.colors?.white}`, py: 2, borderRadius: '4px', cursor: 'pointer' }}>
                            {loader ? (<Spinner sx={{ color: theme.colors?.background, width: 3, p: 0, m: 0 }} />) : ("Submit")}
                        </Button>
                    </form>
                    <Box mt={3}>
                        <Link to="/login" style={{ textDecoration: 'none', color: `${theme.colors?.primary}` }}>
                            Back to Login
                        </Link>
                    </Box>
                </Box>
            </Flex>
        </>

    )
}

export default ForgotPassword
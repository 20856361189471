import { useMemo } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import theme from '../../../theme';


const DailyReport = ({
    data,
    loader,
    fixedColumn,
    className,
    TabelClassname,
}: any) => {
    const columns = useMemo(
        () => [
            { header: 'Sr.', accessorFn: (row: any, index: number) => index + 1 },

            {
                header: 'Project Name', accessorKey: 'projectName'
            },
            { header: 'Delivery Date', accessorKey: 'deliveryDate' },
            { header: 'Worker', accessorKey: 'worker' },
            { header: 'Project Type', accessorKey: 'projectType' },
            { header: 'Hour A Day?', accessorKey: 'hourADay' },
            { header: 'Promise', accessorKey: 'promise' },
            { header: 'Project Folder', accessorKey: 'projectFolder' },
            {
                header: 'Mechanical',
                columns: [
                    {
                        header: '8.5',
                        columns: [
                            { header: 'Worker', accessorKey: 'mechanical.worker' },
                            { header: 'Time', accessorKey: 'mechanical.time' },
                        ],
                    },
                ],
            },
            {
                header: 'Electrical',
                columns: [
                    {
                        header: '8.5',
                        columns: [
                            { header: 'Worker', accessorKey: 'electrical.worker' },
                            { header: 'Time', accessorKey: 'electrical.time' },
                        ],
                    },
                ],
            },
            {
                header: 'Plumbing',
                columns: [
                    {
                        header: '8.5',
                        columns: [
                            { header: 'Worker', accessorKey: 'plumbing.worker' },
                            { header: 'Time', accessorKey: 'plumbing.time' },
                        ],
                    },
                ],
            },
        ],
        []
    );

    const table = useReactTable({
        data: [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const commonPadding = 10;

    return (
        <div className='main-content'>
            <div className="table-responsive">
                <h5 className='p-3'>Daily Report</h5>
                <div style={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom: '10px' }}>
                    <div className={`${'tableNotFixHead'}`} style={{ borderRadius: 10, border: `1px solid ${theme.colors?.muted}` }}>
                        <table className={`${className + TabelClassname?.PrimaryTabel} mb-0 table-hover`}>
                            <thead style={{ backgroundColor: `${theme?.colors?.lightbackground}` }}>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id} colSpan={header.colSpan}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {!loader && (
                                    table.getRowModel().rows.map((row, rowIndex) => (
                                        <tr key={rowIndex}
                                            style={{
                                                overflow: 'hidden',

                                                borderBottom:
                                                    (rowIndex + 1) === table.getRowModel().rows?.length ? 'none' :
                                                        '1px solid #D3D5D9',
                                            }}
                                        >
                                            {row?.getVisibleCells()?.map((cell, cellIndex) => (
                                                <td className={cellIndex + " "} key={cell.id} style={{
                                                    padding: `10px`, display: "gird",
                                                    placeContent: "center",
                                                }}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>

                                            ))}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyReport;

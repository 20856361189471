import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge, Button, Image, MenuButton, Paragraph, Text } from "theme-ui";
import { GetTimeByProjectId, getHistoryByView } from "../../store/actions/project.action";
import activity from "../assets/images/Icon/activity.svg";
import notification1 from "../assets/images/Notification1.svg";
import clockleft from "../assets/images/clock-left.svg";
import spent from "../assets/images/Icon/spent.svg";
import ProjectAction from "./ProjectAction";
import UpdateStatusToAny from "../Actions/UpdateStatusToAny";
import ThreadModalPop from "../ThreadModalPop/ThreadModalPop";
import { bucketUrl } from "../../utils/global";
import HistoryAction from "./HistoryAction";
import { projectStatuslist } from "../../utils/constent";
import { RootState } from "../../../@types";
import theme from "../../theme";
import InfiniteScroll from "react-infinite-scroll-component";
import { AllActivities } from "../../utils/GenerateQueryString";
import ActivityHistoryModal from "../pages/demotable/ActivityHistoryModal";
import ActivityRevertHistory from "../pages/demotable/ActivityRevertHistory";
const ProjectSideActions: React.FC<any> = ({ isTracking, elapsedTime, timeTaken, projectId }) => {
    const { historyView, spentTime } = useSelector((state: any) => state.project);
    const [items, setItems] = useState<any[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [showAll, setShowAll] = useState(false);
    const { auther } = useSelector((state: RootState) => state.auth);
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

    const { getproject } = useSelector((state: any) => state.project);
    const is_Super_Admin = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "super admin");
    const allowRoles = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "super admin" || role.toLocaleLowerCase() === "Region Admin");
    const limit = 25;
    const next = pageNumber + 1;
    console.log('chk28', totalItems);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getHistoryByView(`projectId=${projectId}&page=${pageNumber}&limit=${limit}`));
    }, [dispatch, pageNumber]);

    useEffect(() => {
        if (historyView?.data?.length) {
            if (pageNumber === 1) {
                setItems(historyView?.data);
            } else {
                setItems((prevItems) => [...prevItems, ...historyView?.data]);
            }
            if (historyView?.count) {
                setTotalItems(historyView?.count);
            }

            if (historyView?.data?.length < limit || items.length >= historyView?.count) {
                setHasMore(false);
            }
        }
    }, [historyView?.data, pageNumber, projectId]);

    const fetchMoreData = () => {
        if (items?.length >= totalItems) {
            setHasMore(false);
            return;
        }
        const nextPage = pageNumber + 1;
        setPageNumber(nextPage);
        dispatch(getHistoryByView(`projectId=${projectId}&page=${nextPage}&limit=${limit}`));
    };

    const view = 'project';
    const [selectedTab, setSelectedTab] = useState('activity');
    const handleTabClick = (tab: any) => {
        setSelectedTab(tab);
    };
    useEffect(() => {
        if (selectedTab === 'activity') {
            dispatch(getHistoryByView(`projectId=${projectId}&page=${pageNumber}&limit=${limit}`));

        }
    }, [pageNumber, limit, selectedTab])
    useEffect(() => {
        dispatch(GetTimeByProjectId(projectId));
    }, [dispatch, getproject])
    useEffect(() => {
        if (isTracking) {
            setSelectedTab('spent');
        }
    }, [isTracking]);
    function convertMillisecondsToHoursMinutesSeconds(milliseconds: number) {
        let seconds = Math.floor(milliseconds / 1000);
        const days = Math.floor(seconds / 86400);
        seconds %= 86400;
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;
        return { days, hours, minutes, seconds };
    }

    const toggleModal = () => setModalOpen(!modalOpen);
    const toggleHistoryModal = () => setHistoryModalOpen(!historyModalOpen);
    return (
        <>
            <div className='col-md-3 p-3' style={{ maxHeight: "750px", borderLeft: `2px solid ${theme?.colors?.lightbackground}`, overflowY: "auto" }} >
                {selectedTab === 'activity' && (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <h5>Activity</h5>
                                <p className='text-muted'>All Activities</p>
                            </div>
                            <div>
                                {allowRoles && (<Image src={clockleft} alt={'History'} style={{ width: 25, marginRight: '15px', cursor: 'pointer' }} onClick={toggleHistoryModal} />)}

                                <Image src={notification1} alt={'History'} style={{ width: 30, cursor: 'pointer' }} onClick={toggleModal} />
                            </div>
                        </div>

                        <div>
                            <InfiniteScroll
                                dataLength={items?.length || 0}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={''}
                                height={600}
                                endMessage={<p ></p>}
                            >
                                {items?.map((history: any, historyIndex: number) => {
                                    const activityText = AllActivities(history?.action, history?.changes, history?.oldData, dataForTable?.data?.allProjectManagers, dataForTable?.data?.allTaskManagers, dataForTable?.data?.allProjectWorkers, dataForTable?.data?.allTaskWorkers, dataForTable?.data?.allHighlighters, dataForTable?.data?.allArchitects);
                                    if (!activityText) return null;
                                    return (

                                        <div key={historyIndex + 1} style={{ flexWrap: 'wrap' }}>
                                            <Paragraph style={{ gap: 2 }} variant="activityText"><strong>{history?.changes?.updatedBy || 'Someone'}</strong>
                                                {activityText}
                                            </Paragraph>
                                            <Paragraph sx={{ textAlign: "end", fontSize: "10px", color: "#D3D5D9", marginBottom: "0px" }}>{moment(history?.createdAt).format('MMM DD, LT')}</Paragraph ></div>)
                                })}
                                {/* {items?.map((history: any, index: number) => (
                                    <div key={index}>
                                        <Paragraph style={{ gap: 2 }} variant="activityText" className="">
                                            <strong>{history?.changes?.updatedBy || 'Someone'}</strong>{history?.action}
                                        </Paragraph>
                                        <Paragraph sx={{ textAlign: "end", fontSize: "10px", color: "#D3D5D9", marginBottom: "0px" }}>
                                            {moment(history?.createdAt).format('MMM DD, LT')}
                                        </Paragraph>
                                    </div>
                                ))} */}
                            </InfiniteScroll>
                            {/* {historyView?.length > 0 && historyView?.map((history: any) => <><Paragraph style={{ gap: 2 }} variant="activityText" className=""><strong>{history?.changes?.updatedBy || 'Someone'}</strong>{allActivities(history?.action, history?.changes)}</Paragraph><Paragraph sx={{ textAlign: "end", fontSize: "10px", color: "#D3D5D9", marginBottom: "0px" }}>{moment(history?.createdAt).format('MMM DD, LT')}</Paragraph ></>)} */}
                        </div>
                    </>
                )}
                {selectedTab === 'thread' && (
                    <div>
                        <h5>Chat section</h5>
                        <p className='text-muted'>All Chats</p>
                    </div>
                )}
                {selectedTab === 'spent' && (
                    <>
                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                            <div className="text-center">
                                <h5>Time Tracking</h5>
                                <p className='text-muted'>Spend history</p>
                            </div>
                            <MenuButton aria-label="Toggle Menu" />
                        </div>
                        <div className="text-center">
                            {isTracking !== null && <>
                                <p>Tracking Started</p>
                                <h1>{elapsedTime}</h1>
                            </>
                            }
                        </div>
                        <hr />

                        <div className="container-fluid">
                            <div className="row mb-4">
                                <div className="col-md-8"><h5>Last Actions<Badge variant='circle' mt={-3}>{spentTime?.length}</Badge></h5></div>
                                <div className="col-md-4">
                                    {spentTime?.length > 5 && (
                                        <div>
                                            {showAll ? (
                                                <Button variant="spentButton" onClick={() => setShowAll(false)}>Show Less</Button>
                                            ) : (
                                                <Button variant="spentButton" onClick={() => setShowAll(true)}>Show All</Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{ maxHeight: '675px', overflow: 'auto' }}>
                                {showAll ? (
                                    spentTime?.map((time: any, index: React.Key | null | undefined) => {
                                        const { hours, minutes, seconds, days } = convertMillisecondsToHoursMinutesSeconds(time?.timeTaken);
                                        const formattedDay = days > 0 ? `${days}d` : "";
                                        const formattedHours = hours > 0 ? `${hours}h` : "";
                                        const formattedMinutes = minutes > 0 ? `${minutes}m` : "";
                                        const formattedSeconds = seconds > 0 ? `${seconds}s` : "";

                                        return (
                                            <div className="spenttime" key={index}>
                                                <div className="conatainer-fluid">
                                                    <div className="row">
                                                        <div className="col-md-7 d-flex">
                                                            <Avatar src={`${bucketUrl}${time?.userId?.ProfileImage}`} alt="" variant="avatar" /> &nbsp;<p>{time?.userId?.FirstName} {time?.userId?.LastName}</p>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="d-flex">{formattedDay}{" "}{formattedHours}{" "}{formattedMinutes}{" "}{formattedSeconds}</p>
                                                            <p className="mt-0">{moment(time?.createdAt).format('DD-MM-YY')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    spentTime?.filter((time: { timeTaken: any; }) => time?.timeTaken)?.slice(0, 5)?.map((time: any, index: React.Key | null | undefined) => {
                                        const { hours, minutes, seconds, days } = convertMillisecondsToHoursMinutesSeconds(time?.timeTaken);
                                        const formattedDay = days > 0 ? `${days}d` : "";
                                        const formattedHours = hours > 0 ? `${hours}h` : "";
                                        const formattedMinutes = minutes > 0 ? `${minutes}m` : "";
                                        const formattedSeconds = seconds > 0 ? `${seconds}s` : "";

                                        return (
                                            <div className="spenttime" key={index}>
                                                <div className="conatainer-fluid">
                                                    <div className="row">
                                                        <div className="col-md-2 m-0 d-flex">
                                                            <Image src={`${bucketUrl}${time?.userId?.ProfileImage}`} alt="" style={{ width: "100%", height: "45%" }} variant="avatar" />
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="mb-0">{time?.userId?.FirstName} {time?.userId?.LastName}</p>
                                                            <p className="text-muted mt-0">{time?.description}</p>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="d-flex">{formattedDay}{" "}{formattedHours}{" "}{formattedMinutes}{" "}{formattedSeconds}</p>
                                                            <p className="mt-0">{moment(time?.createdAt)?.format('ll')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>

                        </div>
                    </>
                )}
                {selectedTab === 'action' && (
                    <div>
                        <h5>Actions</h5>
                        <p className='text-muted'>All Actions</p>
                    </div>
                )}
            </div >
            <ActivityHistoryModal
                isOpen={modalOpen}
                toggle={toggleModal}
                items={items}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                dataForTable={dataForTable}
            />
            <ActivityRevertHistory isOpen={historyModalOpen} toggle={toggleHistoryModal} items={items}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                dataForTable={dataForTable}
                view="Project"
                projectId={projectId}
                page={next}
                limit={limit}
            />
            <div className='text-center col-md-1 p-3' style={{ borderLeft: `2px solid ${theme?.colors?.lightbackground}` }}>
                <div onClick={() => handleTabClick('activity')}>
                    <button className='btn' style={{ backgroundColor: selectedTab === 'activity' ? `${theme?.colors?.lightbackground}` : 'inherit' }}>
                        <Image src={activity} alt='' style={{ fill: selectedTab === 'activity' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                    </button>
                </div>
                <Text className='mt-2 ' sx={{ cursor: 'pointer' }}>Activity</Text>

                <ThreadModalPop handleTabClick={handleTabClick} selectedTab={selectedTab} phaseId={projectId} level="project" threads={getproject?.threads} />
                <div onClick={() => handleTabClick('spent')} >
                    <button className='btn' style={{ backgroundColor: selectedTab === 'spent' ? `${theme?.colors?.lightbackground}` : 'inherit' }}>
                        <Image src={spent} alt='' style={{ fill: selectedTab === 'spent' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                    </button>
                    <p className='mt-1' style={{ cursor: 'pointer' }}>Spent History</p>
                </div>
                <HistoryAction selectedTab={selectedTab} projectId={projectId} view={view} />

                <ProjectAction selectedTab={selectedTab} projectId={projectId} />
                {is_Super_Admin && <UpdateStatusToAny actionLists={projectStatuslist} levelStatus={"project"} updateStatusId={projectId} keyName={"projectId"} />}
            </div>
        </>
    )
};
export default ProjectSideActions;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "theme-ui";
import { updateComment } from "../../../store/actions/frontApp.Action";


interface CommentProps {
    original: any
}
const CommentBox: React.FC<CommentProps> = ({ original }) => {
    const [Isopen, setIsopen] = useState(false);
    const [comment, setComment] = useState(original?.client_comment);
    const dispatch = useDispatch();
    const [focusedField, setFocusedField] = useState<string | null>(null);

    const handleOpen = () => {
        setIsopen(true);
        setFocusedField('comment');
    };
    const handleClose = () => {
        setIsopen(false)
        setFocusedField(null);
    }
    const handleSave = () => {
        const payload = {
            client_comment: comment
        }
        dispatch(updateComment(payload, original?.comment_id));
        setIsopen(false);
        setFocusedField(null);
    }

    return (
        <>
            {original?.client_comment ? <p onClick={handleOpen}> {original?.client_comment}</p> : <textarea className="form-control" onClick={handleOpen} />}

            <Modal isOpen={Isopen}>
                <ModalHeader aria-rowcount={12} aria-atomic={"true"} toggle={handleClose}>
                    Comment Box
                </ModalHeader>
                <ModalBody>
                    <p className="text-muted">Update info</p>
                    <Input
                        placeholder="Enter Comment"
                        className="form-control"
                        name="comment"
                        type="textarea"
                        rows={5}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        autoFocus={!focusedField || focusedField === 'comment'}
                    />
                </ModalBody>
                <ModalFooter>
                    {/* <Button variant="CancelButton" onClick={handleCloss}>Cancel</Button> */}
                    <Button variant="SaveButton" onClick={handleSave}>Save</Button>
                </ModalFooter>
            </Modal>
        </>

    )
}
export default CommentBox;

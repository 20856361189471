import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Image } from "theme-ui";
import plusIcon from "../../assets/images/Icon/plus.svg";
import crossIcon from "../../assets/images/Icon/cross.svg";
import { Overlay, Popover } from "react-bootstrap";
import { ActionModule } from "../../Actions/ActionModule/ActionModule";
import { ActionHourADay } from "../../Actions/ActionModule/ActionHourADay";
import SubmitReview from "../../Actions/ActionModule/SubmitReview";
import ActionBackgroundCheck from "../../Actions/ActionModule/ActionBackgroundCheck";
import ActionDeliverNotes from "../../Actions/ActionModule/ActionDeliverNotes";
import { ShowHideStageAction } from "../../../utils/CustomHandler";
import ActionConfirmation from "../../Actions/ActionModule/ActionConfirmation";
import theme from "../../../theme";

export default function StageActions(selectedTab: any) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<any>(null); // State to store selected highlight name
  const { highlighterByView, stagesView } = useSelector((state: any) => state.project);
  const [Open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const popoverRef = useRef<HTMLDivElement>(null);
  const [target, setTarget] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleHighlightClick = (highlight: any) => {
    setSelectedHighlight(highlight);
    setOpen(true);
    setIsPopoverOpen(false)
    if (highlight.name === "Deliver") {
      setShowModal(true);  // Set showModal to true if the highlight name is "Deliver"
    }
  };
  const togglePopover = (event: any) => {
    setIsPopoverOpen(!isPopoverOpen);
    setTarget(event.target);

  };
  const handleClickOutside = (event: any) => {
    if (!event.target.closest('.popover-body')) {
      if (!event.target.closest('.toggle-btn')) {
        setIsPopoverOpen(false);
      }
    }

  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const customProps = {
    selectedHighlight, Open, setOpen, actionObject: { projectId: stagesView?.projectId?._id, stageId: stagesView?._id, taskId: stagesView?.taskId?._id }, activeTab: 0, TaskView: stagesView?.MinTaskData
  }

  const [getFilterdHighliters, setGetFilterdHighliters] = useState<any>([])

  useEffect(() => {
    if (isPopoverOpen) {
      let filterdHighlights = ShowHideStageAction(highlighterByView, stagesView?.status)
      setGetFilterdHighliters(filterdHighlights)
    }
  }, [isPopoverOpen])

  return (
    <div ref={popoverRef}>
      <Overlay
        show={isPopoverOpen}
        target={target}
        placement="left"
      >
        <Popover style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }} id={`popover-positioned`}>

          <Popover.Body className="w-auto">
            {getFilterdHighliters?.map((highlight: any, index: number) => {

              return (
                <Box
                  key={index}
                  sx={{
                    color: highlight?.color, padding: "12px", borderRadius: "8px", cursor: 'pointer', '&:hover': {
                      backgroundColor: `${theme?.colors?.lightbackground}`
                    }
                  }}
                  onClick={(e: any) => {
                    handleHighlightClick(highlight);
                  }}
                >
                  {highlight?.icon && <img width={22} src={highlight?.icon} alt='' />} &nbsp;{highlight?.name}
                </Box>
              );

            })}
          </Popover.Body>
        </Popover>

      </Overlay >
      <button className='btn toggle-btn' style={{ backgroundColor: selectedTab?.selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit', padding: "0px" }} onClick={togglePopover}>
        {isPopoverOpen ? <Image src={crossIcon} alt='' style={{ fill: selectedTab?.selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
          : <Image src={plusIcon} alt='' style={{ fill: selectedTab?.selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />}

      </button>
      {selectedHighlight?.name === "Need Info" && <ActionModule {...customProps} keys={{ first: "isChecked", second: "isBlockByInfo" }} />}
      {selectedHighlight?.name === "New Info Added" && <ActionModule {...customProps} keys={{ first: "isChecked" }} handleHighlightClick={handleHighlightClick} />}
      {selectedHighlight?.name === "Delivery Notes" && <ActionModule {...customProps} keys={{ first: "isChecked", second: "isInternal" }} setShowModal={setShowModal} />}
      {selectedHighlight?.name === "Deliver" && <ActionModule {...customProps} keys={{ first: "isChecked", second: "isInternal" }} setShowModal={setShowModal} />}
      {selectedHighlight?.name === "Internal Review" && <ActionModule {...customProps} keys={{ first: "isChecked" }} />}
      {selectedHighlight?.name === "Blocked By Trade" && <ActionModule {...customProps} keys={{ first: "isChecked", second: "blockingTaskId" }} />}
      {selectedHighlight?.name === "Background Change" && <ActionBackgroundCheck {...customProps} keys={{ first: "isChecked" }} />}
      {selectedHighlight?.name === "Plan Check" && <ActionBackgroundCheck {...customProps} keys={{ first: "isChecked", second: "isInternal" }} />}
      {selectedHighlight?.name === "Revision" && <ActionBackgroundCheck {...customProps} keys={{ first: "isChecked", second: "isInternal" }} />}
      {selectedHighlight?.name === "Hour a Day" && <ActionHourADay {...customProps} keys={{ first: "blockingTaskId" }} />}
      {selectedHighlight?.name === "Final Review" && <ActionModule {...customProps} keys={{ first: "isChecked" }} />}
      {selectedHighlight?.name === "Task Done" && <SubmitReview {...customProps} keys={{ first: "isChecked" }} message={"Submit Your Internal Review"} actionName={"Internal Review"} />}
      {selectedHighlight?.name === "Ready for Final Review" && <SubmitReview {...customProps} keys={{ first: "isChecked" }} message={"Submit Your Final Review"} actionName={"Final Review"} />}
      {selectedHighlight?.name === "Completed" && <SubmitReview {...customProps} keys={{ first: "isChecked" }} message={"Mark Task as Completed"} actionName={"Completed"} />}
      {selectedHighlight?.name === "In Progress" && <SubmitReview {...customProps} keys={{ first: "isChecked" }} message={"Mark Task as In progress"} actionName={"Inprogress"} />}
      {selectedHighlight?.name === "Ready To Deliver" && <SubmitReview {...customProps} keys={{ first: "isChecked" }} message={"Mark Task as Completed"} actionName={"Completed"} />}
      {selectedHighlight?.name === "Waiting for Payment" && <ActionConfirmation {...customProps} />}
      {selectedHighlight?.name === "Out of State" && <ActionConfirmation {...customProps} />}
      {selectedHighlight?.name === "Narrarative of Changes" && <ActionConfirmation {...customProps} />}


      <ActionDeliverNotes setShowModal={setShowModal} showModal={showModal}  {...customProps} />

    </div >
  );
}

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { MediaProps } from 'reactstrap';
import { Button, Text } from 'theme-ui';
import * as Yup from 'yup';
import { CreateUserForm, RootState } from '../../../../@types';
import { mediaUpload } from '../../../store/actions/media.action';
import { getAllRoles, getAllUserWithoutPagination } from '../../../store/actions/user.action';
import theme from '../../../theme';
import { useNavigate } from 'react-router-dom';
import { ActionCreateByUrl } from '../../../store/actions/CommonAction';
const genders: string[] = ['Male', 'Female', 'Other'];
const validationSchema = Yup.object().shape({
  FirstName: Yup.string().required('First Name is required'),
  LastName: Yup.string().required('Last Name is required'),
  timeZone: Yup.string().required('Time zone is required'),
  userTeam: Yup.string().required('User Team is required'),
  // ReportingManager: Yup.string().required('Reporting Manager Name is required'),
  Email: Yup.string().email('Invalid email address').required('Email is required'),
  // Gender: Yup.string().required('Gender is required'),
  Role: Yup.array().of(Yup.string()).required('Role is required').min(1, 'At least one role must be selected'),
  // CanManageTo: Yup.array().of(Yup.string()).required('CanManageTo is required').min(1, 'At least one CanManageTo must be selected'),
  // TypeOfSkills: Yup.array().of(Yup.string()).required('Skills is required').min(1, 'At least one skills must be selected'),
  Password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character'
    ),
  PhoneNumber: Yup.string().required('Phone Number is Required').matches(
    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    "Only digits are allowed!!"
  ),
  RePassword: Yup.string()
    .oneOf([Yup.ref('Password')], 'Passwords must match')
    .required('Re-Enter Password is required'),
});

const CreateUser: React.FC = () => {
  const navigate = useNavigate();
  const roles = useSelector((state: RootState) => state.user.roles);
  const { uploadedMedia } = useSelector((state: MediaProps) => state?.media);
  // const media = useSelector((state: MediaProps) => state.data)
  // const { roles } = useSelector((state: RootState) => state.user);
  // console.log("mediaDatat", data);
  const skills: Option[] = [
    { label: 'Mechanical', value: 'Mechanical' },
    { label: 'Electrical', value: 'Electrical' },
    { label: 'Plumbing', value: 'Plumbing' },
    { label: 'Others', value: 'Others' },
  ];
  const userData = useSelector((state: any) => state?.user);


  const Users = userData?.userData?.data;
  const dispatch = useDispatch();
  const initialValues: CreateUserForm = {
    _id: '',
    FirstName: '',
    LastName: '',
    Gender: '',
    Email: '',
    Role: [],
    Password: '',
    RePassword: '',
    TypeOfSkills: [],
    ReportingManager: '',
    CanManageTo: [],
    ProfileImage: uploadedMedia || '',
    PhoneNumber: 0,
    timeZone: '',
    userTeam: '',
  };
  useEffect(() => {
    dispatch(getAllRoles());
    // dispatch(getAllUser(paginations))
    dispatch(getAllUserWithoutPagination())
  }, [dispatch]);
  const handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target.files as FileList)[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'user');
      formData.append('uploadType', 'File');
      dispatch(mediaUpload(formData));
    } else {
      console.warn('Profile Image not found!');
    }
  };
  const handleSubmit = async (payload: any) => {
    payload.ProfileImage = uploadedMedia || '';

    if (!payload.ReportingManager) {
      delete payload?.ReportingManager;
    }

    const { _id, ...payloadWithoutId } = payload;

    dispatch(ActionCreateByUrl({ Query: "users/CreateUser", obj: payloadWithoutId }, (res: any) => {
      navigate("/user");
    }));
  };
  const TypeOfSkills: Option[] = skills?.map((value) => ({
    label: value?.label,
    value: value?.value,
  }))
  const canManageToOptions: Option[] = Users?.map((user: any) => ({
    label: user?.FirstName || 'unknown',
    value: user?._id,
  }));
  const roleOptions: Option[] = roles?.data?.map((roles) => ({
    label: roles?.RoleName || 'unknown',
    value: roles?._id,
  }));
  const userTeam = [
    { label: 'PEC', value: 'PEC' },
    { label: 'QDC', value: 'QDC' },
  ]
  const timeZones = [
    { label: 'PST', value: 'America/Los_Angeles' },
    { label: 'IST', value: 'Asia/Calcutta' },
  ]
  return (
    <div className="main-content px-3" >

      <h1>Add User</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}

      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            {/* <div className='container-fluid'> */}
            <div className="row">
              <div className="col-md-4 mt-4">
                <label htmlFor="FirstName" className={`form-label mt-1 ${errors.FirstName && touched.FirstName ? 'is-invalid' : ''}`}>First Name*</label>
                <Field type="text" id="FirstName" name="FirstName" className="form-control" placeholder="First Name" />
                <ErrorMessage name="FirstName" component="div" className="text-danger" />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="LastName" className={`form-label mt-1 ${errors.LastName && touched.LastName ? 'is-invalid' : ''}`}>Last Name*</label>
                <Field type="text" id="LastName" name="LastName" className="form-control" placeholder="Last Name" />
                <ErrorMessage name="LastName" component="div" className="text-danger" />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="userEmail" className={`form-label mt-1 ${errors?.Email && touched?.Email ? 'is-invalid' : ''}`}>Email ID*</label>
                <Field type="text" id="userEmail" name="Email" className="form-control" placeholder="Email ID" autoComplete="off" />
                <ErrorMessage name="Email" component="div" className="text-danger" />
              </div>
              {/* <div className="col-md-4 mt-4">
                <label htmlFor="Project" className="form-label">Project*</label>
                <Field type="text" id="Project" name="Project" className="form-control" placeholder="Project" />
                <ErrorMessage name="Project" component="div" className="text-danger" />
              </div> */}
              <div className="col-md-4 mt-4">
                <label htmlFor="Gender" className="form-label">
                  Gender*
                </label>
                <Field
                  as="select"
                  id="Gender"
                  name="Gender"
                  className={`form-control `}
                  // onChange={(e) => setFieldValue('Gender', e.target.value)}
                  value={values.Gender}
                >
                  <option value="" label="Select Gender" />
                  {genders?.map((gender) => (
                    <option key={gender} value={gender} label={gender} />
                  ))}
                </Field>
                {/* <ErrorMessage name="Gender" component="div" className="text-danger" /> */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="Role" className="form-label">Role Name*</label>
                {roleOptions?.length > 0 && <MultiSelect
                  options={roleOptions}
                  value={values?.Role?.map((value: any) => roleOptions?.find((option) => option?.value === value) as Option)}
                  onChange={(selected: Option[]) => setFieldValue('Role', selected?.map((option) => option?.value))}
                  labelledBy="Select"
                />}
                {errors?.Role && touched?.Role ? (
                  <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                    {errors?.Role}
                  </Text>
                ) : (
                  null
                )}
                {/* <Field
                  as="select"
                  id="Role"
                  name="Role"
                  className="form-control"
                >
                  <option value="" label="Select Role" />
                  {roles && roles?.data.map((role: any) => (
                    <option key={role._id} value={role._id} label={role.RoleName} />
                  ))}
                </Field> */}
                {/* <ErrorMessage name="Role" component="div" className="text-danger" /> */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="PhoneNumber" className="form-label">Phone Number</label>
                <Field type="text" id="PhoneNumber" name="PhoneNumber" className="form-control" placeholder="Phone Number" />
                {/* <ErrorMessage name="PhoneNumber" component="div" className="text-danger" /> */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="ReportingManager" className="form-label">Reporting Manager</label>
                <Field
                  as="select"
                  id="ReportingManager"
                  name="ReportingManager"
                  className={`form-control`}
                >
                  <option value="" label="Select Reporting Manager" />
                  {Users?.map((user: any) => (
                    <option key={user?._id} value={user?._id} label={user?.FirstName} />
                  ))}
                </Field>
                {/* <ErrorMessage name="ReportingManager" component="div" className="text-danger" /> */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="CanManageTo" className="form-label">Can Manage to</label>
                {canManageToOptions?.length > 0 && <MultiSelect
                  options={canManageToOptions}
                  value={values?.CanManageTo?.map((value) => canManageToOptions?.find((option) => option?.value === value) as Option)}
                  onChange={(selected: Option[]) => setFieldValue('CanManageTo', selected?.map((option) => option?.value))}
                  labelledBy="Select"
                />}
                {/* <ErrorMessage name="CanManageTo" component="div" className="text-danger" /> */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="TypeOfSkills" className="form-label">Types of Skills</label>
                {TypeOfSkills?.length > 0 && <MultiSelect
                  options={TypeOfSkills}
                  value={values?.TypeOfSkills?.map((value) => TypeOfSkills.find((option) => option?.value === value) as Option)}
                  onChange={(selected: Option[]) => setFieldValue('TypeOfSkills', selected?.map((option) => option?.value))}
                  labelledBy="Select"
                />}
                {/* <ErrorMessage name="TypeOfSkills" component="div" className="text-danger" /> */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="userPassword" className="form-label">Enter Password*</label>
                <Field type="password" id="userPassword" name="Password" className="form-control" placeholder="Enter Password" />
                <ErrorMessage name="Password" component="div" className="text-danger" />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="RePassword" className="form-label">
                  Re-Enter Password*
                </label>
                <Field
                  type="password"
                  id="RePassword"
                  name="RePassword"
                  className="form-control"
                  placeholder="Re-Enter Password"
                />
                <ErrorMessage name="RePassword" component="div" className="text-danger" />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="userTeam" className="form-label">User Team*</label>
                <Field
                  as="select"
                  id="userTeam"
                  name="userTeam"
                  className={`form-control`}
                >
                  <option value="" label="Select User Team" />
                  {userTeam?.map((user: any, index: number) => (
                    <option key={index} value={user.value} label={user.label} />
                  ))}
                </Field>
                <ErrorMessage name="userTeam" component="div" className="text-danger" />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="timeZone" className="form-label">Time Zone*</label>
                <Field
                  as="select"
                  id="timeZone"
                  name="timeZone"
                  className={`form-control`}
                >
                  <option value="" label="Select Time Zone" />
                  {timeZones?.map((user: any, index: number) => (
                    <option key={index} value={user?.value} label={user?.label} />
                  ))}
                </Field>
                <ErrorMessage name="timeZone" component="div" className="text-danger" />
              </div>
              <div className='col-md-12 mt-4'><h2>System Field</h2></div>
              <div className='col-md-4 mt-4'>
                <label htmlFor='ProfileImage' className="form-label">Profile Image</label>
                <input
                  type="file"
                  id="ProfileImage"
                  name="ProfileImage"
                  onChange={handleProfileImageChange}
                  className={`form-control ${errors.ProfileImage && touched.ProfileImage ? 'is-invalid' : ''}`}
                />
              </div>
              <div className='col-md-4 mt-4'>
                <label htmlFor='Evaluation' className="form-label">Next Evaluation</label>
                <Field type="date" id="Evaluation" name="Evaluation" className="form-control" placeholder="Enter Date" />
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-md-4'></div>
              <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
              <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit'>Submit</Button></div>
            </div>
            {/* </div> */}
          </Form>)}
      </Formik>
    </div >
  );
};

export default CreateUser;

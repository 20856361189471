import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Badge, Box, Flex, Grid, Image, Text } from "theme-ui";
import * as Yup from "yup";
import { RootState } from "../../../@types";
import { getStageByProjectId } from "../../store/actions/project.action";
import { Inputform } from "../forms-component/InputForm";
import EditStageView from "../pages/StagesView/EditStageView";
import { customStyles, customStylesForDepartments } from "../pages/create project /StepTwo/CustomSelectStyles";
import AddStage from "./AddStage/Stepperform";
interface props {
    projectId: string
}
const StepTwoOnly: React.FC<props> = ({ projectId }) => {
    const userData = useSelector((state: RootState) => state.project);
    const { stagebyproject } = useSelector((state: any) => state.project);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const allStages = userData.createprojectMasterData?.allStages?.data || [];
    const allTask = userData.createprojectMasterData?.allTask?.data || [];
    const projectManagers = userData?.createprojectMasterData?.allTaskManager || [];

    const projectWorkers = userData.createprojectMasterData?.allTaskWorkers || [];
    const [isOpen, setIsOpen] = useState(false);
    const allStagesOptions = allStages?.map((stage) => ({
        value: stage?._id,
        label: stage?.name,
        color: stage?.color,
        backgroundColor: "#445adc",
        task: [],
        dueDate: '',
        promise: '',
        manager: '',
        worker: ''
    }));
    useEffect(() => {
        dispatch(getStageByProjectId(projectId))
    }, [])

    const allTaskOptions = allTask?.map((task) => ({
        value: task?._id,
        label: task?.name,
        color: task?.color,
        department: []
    }));

    const promise = [
        { label: "Customer", value: "Customer" },
        { label: "Personal", value: "Personal" },
        { label: "Hot Promise", value: "hotPromise" },

    ];
    const projectManagerOptions = projectManagers.map((manager: any) => ({
        value: manager?._id,
        label: `${manager?.FirstName} ${manager?.LastName}`
    }));
    const projectWorkerOptions = projectWorkers?.map((worker: any) => ({
        value: worker?._id,
        label: `${worker?.FirstName} ${worker?.LastName}`
    }));

    const validationSchema = Yup.object({
        stagesData: Yup.array()
            .min(1, "Select at least one stage")
            .of(
                Yup.object().shape({
                    task: Yup.array().min(1, "Select at least one task"),
                    dueDate: Yup.string().required("Due Date is required"),
                    promise: Yup.string().required("Promise is required"),
                    worker: Yup.string().required("Worker is required"),
                    manager: Yup.string().required("Manager is required"),
                })
            ),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema,
        initialValues: {

        },
        onSubmit: async (values, actions) => {

        },
    });
    const handleNextClick = () => {
        handleSubmit();
    };

    const { handleSubmit, setFieldValue, handleChange, handleBlur, touched, values, errors } = formik;
    const handleEditTask = (taskId: any) => {
        setIsOpen(true);
        navigate(`/edit-stages/${taskId}`);
    };
    return (
        <>
            <Box className="container-fluid">
                <Box className="container-fluid d-flex" style={{ justifyContent: 'space-between' }}>
                    <Text as={'h4'} mt={3} mb={2}>
                        Stages
                    </Text>
                    <AddStage />
                </Box>

                <form onSubmit={handleSubmit}>
                    {stagebyproject?.map((selectedStage: any, stageIndex: number) => {
                        const worker = projectWorkerOptions.find(option => option?.label)

                        const manager = projectManagerOptions.find(option => option?.label)

                        return (
                            <>
                                <Select
                                    isMulti
                                    classNamePrefix="an-simple-select"
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#F7EFE5',
                                        },
                                    })}
                                    options={allStagesOptions}
                                    value={allStagesOptions.find(option => option.label === selectedStage?.name)}
                                    aria-labelledby="Select"
                                    onChange={(selected) => { formik?.setFieldValue("stagesData", selected) }}
                                    styles={customStylesForDepartments}
                                    isDisabled={true}
                                />
                                {/* parent boxes */}
                                <Box key={selectedStage?._id} sx={{
                                    boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06);',
                                    borderRadius: '12px', p: 2, marginTop: 2
                                }}>

                                    <Box key={selectedStage?._id} sx={{ boxShadow: 'initial', p: 2, borderLeft: `4px solid ${selectedStage.color}`, }}>
                                        <Flex sx={{ alignItems: 'center', marginBottom: 2 }}>
                                            <Box sx={{ height: '100%', width: '4px', backgroundColor: selectedStage.color, marginRight: '8px' }} />
                                            <Box>
                                                <Flex as={'h3'} sx={{ color: selectedStage.color, marginBottom: 0, cursor: 'pointer', gap: "10px" }} onClick={() => handleEditTask(selectedStage?._id)}>
                                                    {selectedStage.name}
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', }}>
                                                        <h4 style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap', }}>
                                                            {
                                                                selectedStage?.highlighters?.map((badge: any) => (
                                                                    <Badge
                                                                        key={badge?._id}
                                                                        color={badge?.color}
                                                                        sx={{
                                                                            backgroundColor: 'transparent',
                                                                            borderRadius: "8px",
                                                                            gap: '3px',
                                                                            padding: '4px 8px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            border: `1px solid ${badge?.color}`,
                                                                        }}
                                                                    >
                                                                        {badge?.icon && <Image
                                                                            title={badge.name}
                                                                            src={badge?.icon}
                                                                            alt={badge?.name}
                                                                            width={14}
                                                                            height={14}
                                                                        />}
                                                                        <Text sx={{ color: `${badge?.color}` }}>{badge?.name}</Text>
                                                                    </Badge>
                                                                ))}

                                                        </h4>
                                                    </div>
                                                </Flex>
                                                <Text>
                                                    {selectedStage.status}
                                                </Text>
                                            </Box>
                                        </Flex>
                                        <Select
                                            isMulti
                                            classNamePrefix="an-simple-select"
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#F7EFE5',
                                                },
                                            })}
                                            options={allTaskOptions}

                                            value={selectedStage?.tasksNameList?.map((task: any) => ({
                                                value: task?._id,
                                                label: task?.name || "",
                                                color: task?.color || "#000",
                                            })) || []}
                                            aria-labelledby="Select"
                                            onChange={(selected) => {
                                                // Log the selected values for debugging

                                                setFieldValue(`stagesData[${stageIndex}].tasksNameList`, selected);
                                            }}
                                            styles={customStyles}
                                            isDisabled={true}
                                        />
                                        <Grid sx={{ gap: '1rem', marginTop: 3 }} columns={[1, 2, 2, 3, 4, 4]}>
                                            <Box>
                                                <Inputform
                                                    type="date"
                                                    label="Due Date"
                                                    onBlur={handleBlur}
                                                    Name={`stagesData[${stageIndex}].dueDate`}
                                                    OnChange={handleChange}
                                                    values={selectedStage?.dueDate?.split('T')[0] || ''}
                                                    Placeholder=""
                                                    disabled
                                                />
                                            </Box>
                                            <Box>
                                                <Text variant="SelectLabel">Promise:</Text>
                                                <Select
                                                    classNamePrefix="an-simple-select"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#F7EFE5',
                                                        },
                                                    })}
                                                    options={promise}
                                                    onBlur={handleBlur}
                                                    value={promise.find(option => option.label === selectedStage.promise)}
                                                    onChange={(selectedOption: any) => setFieldValue(`stagesData[${stageIndex}].promise`, selectedOption?.value || '')}
                                                    isDisabled={true}
                                                />
                                            </Box>
                                            <Box>
                                                <Text variant="SelectLabel">worker:</Text>
                                                <Select
                                                    classNamePrefix="an-simple-select"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#F7EFE5',
                                                        },
                                                    })}
                                                    placeholder={selectedStage?.worker?.FirstName || 'Select Worker'}

                                                    value={projectWorkerOptions.find(option => option.label === selectedStage.worker?.FirstName)}
                                                    options={[{ value: '', label: 'Select Worker' }, ...projectWorkerOptions]}
                                                    onBlur={handleBlur}
                                                    onChange={(selectedOption) =>
                                                        formik.setFieldValue(`stagesData[${stageIndex}].worker`, selectedOption?.value || '')}
                                                    isDisabled={true}
                                                />
                                            </Box>
                                            <Box>
                                                <Text variant="SelectLabel">manager:</Text>
                                                <Select
                                                    classNamePrefix="an-simple-select"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#F7EFE5',
                                                        },
                                                    })}
                                                    placeholder={selectedStage?.manager?.FirstName || "Select Project Manager"}
                                                    onBlur={handleBlur}
                                                    value={projectManagerOptions.find(option => option.label === selectedStage.manager?.FirstName)}
                                                    onChange={(selectedOption) =>
                                                        formik.setFieldValue(`stagesData[${stageIndex}].manager`, selectedOption?.value || '')}
                                                    options={[{ value: '', label: 'Select Manager' }, ...projectManagerOptions]}
                                                    isDisabled={true}
                                                />
                                            </Box>

                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                        )
                    })}
                </form>
            </Box>
            {isOpen && (
                <EditStageView

                />
            )}
        </>
    );
};

export default StepTwoOnly;

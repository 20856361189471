import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Box } from "theme-ui";
import { RoleObj, RootState } from "../../../@types";
import calender from "../assets/images/calendar-2.svg";
import notifications from "../assets/images/notification.svg";
import CustomArrow from "./Dropdown";
import { SearchBar } from "./SearchBar";
import { bucketUrl } from "../../utils/global";
import theme from "../../theme";

interface TopBarProps {
    collapsed?: boolean;
}


const truncateString = (str: string, num: number) => {
    if (str.length > num) {
        return str.slice(0, num) + '...';
    }
    return str;
};
const Topbar: React.FC<TopBarProps> = ({ collapsed }): JSX.Element => {
    const { auther } = useSelector((state: RootState) => state.auth);

    return (
        <>
            {/* <BrowserView> */}
            <div className={`navbar p-0 navbar-inverse navbar-fixed-top ${collapsed ? "collapsed" : ""}`}>
                <div className="container-fluid revstyle">
                    <div className="navbar-brand">
                        <SearchBar />
                    </div>
                    <div className="d-flex justify-content-end align-items-center nav-style" >
                        <img className="img" alt="calender" src={calender} style={{ margin: "0 10px" }} />
                        <img className="mask-group" alt="Notifications" src={notifications} style={{ margin: "0 10px" }} />
                        <div className="d-flex align-items-center">
                            {auther?.ProfileImage ? (
                                <Avatar
                                    width={40}
                                    height={40}
                                    variant="profile"
                                    src={`${bucketUrl}${auther?.ProfileImage}`}
                                    style={{ backgroundColor: "white" }}
                                />
                            ) : (
                                <Box
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: '50%',
                                        backgroundColor: theme?.colors?.primary || '#1976d2',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {auther?.FirstName?.charAt(0)}
                                </Box>
                            )}
                            <div className="user-info d-flex flex-column justify-content-center mx-1">
                                <div>{auther?.FirstName} {auther?.LastName || ""}</div>
                                <sub className="mb-1 wrap-after-four" title={auther?.Role?.map((r: RoleObj) => r.RoleName).join(", ") || ""}><b>{truncateString(auther?.Role?.map((r: RoleObj) => r.RoleName).join(", ") || "", 20)}</b></sub>
                                <div>{auther?.Email || ""}</div>
                            </div>
                        </div>
                        <CustomArrow />
                    </div>
                </div>
            </div>
            {/* </BrowserView>
            <MobileView>
                <div className="d-flex justify-content-end align-items-center" style={{ marginTop: '5%' }}>
                    <img className="img" alt="calender" src={calender} style={{ margin: "0 10px" }} />
                    <img className="mask-group" alt="Notifications" src={notifications} style={{ margin: "0 10px" }} />
                    <div className="d-flex align-items-center mt-1">
                        <Avatar src={auther?.ProfileImage} sx={{ backgroundColor: "white" }} />
                        <div className="user-info d-flex flex-column justify-content-center mx-1">
                            <div>{auther?.FirstName} {auther?.LastName || ""}</div>
                            <div>{auther?.Email || ""}</div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="navbar-brand">
                    <SearchBar />
                </div>
            </MobileView> */}
        </>
    );
};

export default Topbar;

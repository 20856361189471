import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getAllTodos, getAllUserWithoutPagination } from "../../../store/actions/user.action";
import { getApiEndPoint } from "../../../utils/GenerateQueryString";
import { ColumnDef } from "@tanstack/react-table";
import plus from "../../assets/images/Icon/plus.svg";
import { Divider, Input, Text } from "theme-ui";
import queryString from "query-string";
import { TabelHeadingTxt } from "../../Text/TabelCommonTxt";
import DataTable from "../../Tabels/Tabel";
import { ActionUpdateById } from "../../../store/actions/CommonAction";
import { usePreviousURL } from "../../../utils/CustomHandler";
import theme from "../../../theme";
import TableDeleteActions from "../Roles/TableDeleteActions";

const AllTodos: React.FC = () => {
    const { todos, loader, userData } = useSelector((state: any) => state.user);
    const { paginations } = useSelector((state: any) => state.pagination);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState<boolean>(true);
    const [checkedTodos, setCheckedTodos] = useState<{ [key: string]: boolean }>({});
    const [dueDates, setDueDates] = useState<{ [key: string]: Date | null }>({});
    const previousURL = usePreviousURL()


    useEffect(() => {
        dispatch(getAllUserWithoutPagination())
    }, [dispatch])

    useEffect(() => {
        let apiEndPoint = getApiEndPoint({ limit, currentPage }, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [], location?.pathname
        )
        dispatch(getAllTodos(apiEndPoint))
        setRefresh(false);
    }, [location?.search, refresh])

    useEffect(() => {
        if (todos?.data) {
            const initialCheckedState = todos?.data?.reduce((acc: any, todo: any) => {
                acc[todo._id] = todo.isCompelted;
                return acc;
            }, {});
            setCheckedTodos(initialCheckedState);

            const initialDueDates = todos?.data.reduce((acc: any, todo: any) => {
                acc[todo._id] = todo.todoDueDate?.split('T')[0] || '';
                return acc;
            }, {});
            setDueDates(initialDueDates);

        }
    }, [todos?.data]);
    const today = new Date().toISOString().split('T')[0];
    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row?.index + 1}</Text>
                )
            },
        },
        {
            accessorKey: "todoName",
            header: 'Todo Name',
            cell: ({ row }) => {
                console.log('chk78', row.original);

                return (
                    <Text onClick={() => { previousURL(`/edit-todo/${row?.original?._id}`) }} style={{ textDecoration: 'underline', color: `${theme.colors?.secondary}`, cursor: "pointer", fontWeight: 500 }}>
                        {row?.original?.
                            todoName
                            || ''}
                    </Text>)
            },
            enableColumnFilter: true,
            filterType: "textbox",

        },

        {
            header: 'Todo Assignee',
            accessorKey: "todoAssignee",
            cell: ({ row }) => {
                const todoMembers = row.original.todoAssignee;
                return (
                    <Text >
                        {todoMembers?.FirstName} {todoMembers?.LastName}
                    </Text>
                );
            },
            filterType: 'dropdown',
            options: userData?.data?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val._id, label: `${val.FirstName} ${val.LastName}` }))
        },

        {
            header: 'Todo  Checklist',
            accessorKey: "todoChecklistCount",
            cell: info => info.getValue(),
        },
        {
            header: 'Is Completed',
            accessorKey: 'isCompelted',
            cell: ({ row }) => {
                const todoId = row?.original?._id;
                return (
                    <input
                        type="checkbox"
                        checked={checkedTodos[todoId] ?? false}
                        onChange={() => handleCheckboxChange(todoId, !checkedTodos[todoId])}
                    />
                );
            },
        },
        {
            header: 'Due Date',
            accessorKey: 'todoDueDate',
            cell: ({ row }) => {
                const { todoDueDate } = row.original;
                const isDueDatePassed = todoDueDate && todoDueDate.split('T')[0] < today;

                return (
                    <div style={{ position: 'relative' }}>
                        <Input
                            type="date"
                            // label="Due Date"
                            onBlur={() => { }}
                            name={`todoDueDate`}
                            onChange={(event: any) => {
                                const newDate = event.target.value;
                                handleDueDateChange(row.original._id, newDate);
                            }}
                            value={todoDueDate?.split('T')[0] || ''}
                            sx={{
                                borderColor: isDueDatePassed ? 'red' : '#F7EFE5',
                                borderWidth: isDueDatePassed ? '2px' : '1px',
                                borderStyle: 'solid',
                                borderRadius: '12px',
                                color: todoDueDate === null || '' ? 'white' : (isDueDatePassed ? 'red' : 'black'),
                                appearance: 'textfield',
                                '::placeholder': {
                                    color: 'transparent'
                                },
                            }}
                        />

                    </div>
                );
            },
        },
        {
            header: 'Delete',
            accessorKey: "delete",
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <TableDeleteActions row={row} deleteMessage='Are you sure you want to delete this todo?' deleteApi={`todo/delete-todo/${row?.original?._id}`} toggleRefresh={() => setRefresh(true)} />
                )
            },
        },

    ], [todos?.data, checkedTodos, dueDates]);

    const handleDueDateChange = (todoId: string, newDate: string) => {
        const payload = {
            todoDueDate: newDate,
        };

        dispatch(ActionUpdateById({ Query: `/todo/edit-Todo/${todoId}`, obj: payload },
            (res: any) => {
                if (res.status === 200) {
                    setDueDates((prevDueDates: any) => ({
                        ...prevDueDates,
                        [todoId]: newDate,
                    }));
                }
                setRefresh(true);
            }
        ));
    };
    const handleCheckboxChange = (todoId: string, isChecked: boolean) => {

        const payload = {
            isCompleted: isChecked,
        };
        dispatch(ActionUpdateById({ Query: `/todo/edit-Todo/${todoId}`, obj: payload },
            (res: any) => {
                if (res.status === 200) {
                    setCheckedTodos(prev => ({
                        ...prev,
                        [todoId]: isChecked,
                    }));

                }
                setRefresh(true);

            }
        ));

    };
    const handleclick = () => {
        navigate("/create-todo", { state: { from: "todos" } })
    }

    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Todos' Count={todos?.count} BtnTxt='Create Todo' onClick={handleclick} src={plus} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                loader={loader}
                columns={columns}
                data={todos?.data || []}
                TotalLength={todos?.count || 15}
                perPageCount={limit || 15}
                currentPage={paginations.currentPage}
                onPerPageChange={(itemsPerPage) => {
                    if (searchParams?.size > 2) {
                        const parsedQuery = queryString.parse(location.search);

                        setSearchParams({
                            ...parsedQuery,
                            limit: itemsPerPage.toString(),
                        });
                    } else if (todos?.count < itemsPerPage) {
                        setSearchParams({
                            currentPage: '1',
                            limit: itemsPerPage.toString(),
                        });
                    }
                    else {
                        setSearchParams({
                            currentPage: currentPage.toString(),
                            limit: itemsPerPage.toString(),
                        });
                    }

                }}
                onPageChange={(value: string) => {
                    console.log(value, "onPageChange");
                    if (searchParams?.size > 2) {
                        const parsedQuery = queryString.parse(location.search);

                        setSearchParams({
                            ...parsedQuery,
                            currentPage: value.toString(),
                        });
                    } else {
                        setSearchParams({
                            currentPage: value.toString(),
                            limit: limit.toString(),
                        });
                    }

                }}

            />
        </div>
    )
}

export default AllTodos
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getTradeForChats } from "../../store/actions/chatAction";
import { Overlay, Popover } from "react-bootstrap";
import { Flex, Image, Text } from "theme-ui";
import thread from "../assets/images/Icon/thread.svg";
import theme from "../../theme";

export default function ThreadModalPop({ level, phaseId, selectedTab, threads }: any) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { tradeTask } = useSelector((state: any) => state.chats);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [target, setTarget] = useState(null);

  const togglePopover = (event: any) => {
    setIsPopoverOpen(!isPopoverOpen);
    setTarget(event.target);

  };

  const handleChat = (thredId: any) => {
    navigate(`/chat${pathname}/${thredId}`);
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(getTradeForChatsSuccess([]))
  //   }
  // }, [])

  const handleClickOutside = (event: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setIsPopoverOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={popoverRef} className="d-flex justify-content-center mt-2 p-2">
      <Overlay show={isPopoverOpen}
        target={target} placement="left" >
        <Popover id="popover-basic" className="p-1 gap-2 d-flex flex-column text-center ">
          {tradeTask?.length > 0 && tradeTask?.map((trade: any, index: any) => <Text key={index} onClick={() => handleChat(trade?.taskId?._id)} sx={{ minWidth: "100px", display: "block", cursor: "pointer", '&:hover': { background: `${theme?.colors?.lightbackground}` }, padding: "0.5rem", borderRadius: "8px" }}>{trade?.name}</Text>)}
        </Popover>
      </Overlay>
      <div onClick={(e: any) => {
        togglePopover(e);
      }} onMouseEnter={() => {
        if (phaseId) {
          dispatch(getTradeForChats(phaseId, level))
        }
      }} >
        <button className='btn ' style={{ backgroundColor: selectedTab === 'thread' ? `${theme?.colors?.lightbackground}` : 'inherit', display: "flex", gap: "5px" }}>
          <Image src={thread} alt='' style={{ fill: selectedTab === 'thread' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
          {threads?.length > 0 && <Flex>
            {threads?.map((thred: any) => !thred?.isAcknowledged && <div style={{ width: "8px", height: "8px", borderRadius: "50%", background: `${thred.color}` }} />)}
          </Flex>}
        </button>
        <Text className='mt-1' sx={{ cursor: 'pointer' }}>Thread</Text>
      </div>
    </div>
  )
}

import { CREATE_BY_URL, DELETE_BY_ID, UPDATE_BY_ID } from "../storeTypes";

const initialState: any = {
    loader: false,
};
const CommonReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_BY_URL: {
            return {
                ...state,
                loader: true,
            };
        }
        case UPDATE_BY_ID: {
            return {
                ...state,
                loader: true,
            };
        }
        case DELETE_BY_ID: {
            return {
                ...state,
                loader: true,
            };
        }

        default: {
            return state;
        }
    }
};

export default CommonReducer;

import { paginationProps } from "../../../@types";
import { SET_PAGINATION } from "../storeTypes";

const initialState: paginationProps = {
    loader: false,
    paginations: {
        limit: 15,
        currentPage: 1,
        searchUrl: null,
    },
};
const PaginationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_PAGINATION:
            return {
                ...state,
                loader: true,
                paginations: action?.payload
            };

        default:
            return state;
    }
};

export default PaginationReducer;

import { SyntheticEvent } from "react";
import { Box, Checkbox, Label, Text, ThemeUIStyleObject } from "theme-ui";

interface CheckBoxProps2 {
    title: String;
    className?: string;
    name?: string;
    values?: Array<string> | undefined;
    columns?: Array<number> | number;
    Option: Array<any> | undefined;
    checkedData?: Array<any> | undefined;
    sx?: ThemeUIStyleObject;
    my?: Array<number> | number;
    gap?: Array<number> | number;
    onChange?: (event: boolean | any, item: any) => void;
    // setFieldValue: () => void;
    disable?: boolean
}
export const CheckBox2: React.FC<CheckBoxProps2> = ({
    title = "",
    Option,
    sx,
    values,
    columns,
    my,
    onChange,
    className,
    name,
    disable,
    gap,
    checkedData
}) => {
    // console.log(Option, "check28");
    // useEffect(() => {
    //     Option?.((val) => {

    //     })
    // }, [])
    return (
        <Box className={className} my={5}>
            <div className="row">
                {Option?.map((itm: any, index: number) => {
                    return (
                        <div key={index} className="col-4 mb-3">
                            <Label>
                                <Checkbox
                                    name={name}
                                    multiple={true}
                                    onChange={(e: SyntheticEvent | any) => {
                                        if (onChange) {
                                            onChange(e?.target?.checked, itm);
                                        }
                                    }}
                                    disabled={disable}
                                    checked={checkedData?.includes(itm?._id)}
                                />
                                <Text variant='Raleway18Bold148lh' sx={{ fontWeight: "600", textAlign: "left" }}>
                                    {itm?.sopItem}
                                </Text>
                            </Label>
                        </div>
                    );
                })}
            </div>
        </Box>
    );
};

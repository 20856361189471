import { useSelector, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Box, Button, Divider, Flex, Paragraph, Text } from "theme-ui";
import DataTable from "../../Tabels/Tabel";
import queryString from "query-string";
import moment from "moment";
import { convertMsToTime, getApiEndPoint, msToTime } from "../../../utils/GenerateQueryString";
import { categoryTimer } from "../../../utils/constent";
import { RootState } from "../../../../@types";
import { getAllTeamsByMemberNoPagination, getAllTeamsByUserNoPagination } from "../../../store/actions/user.action";
import { MultiSelect } from "react-multi-select-component";
import { getGeneralTimeTracking } from "../../../store/actions/project.action";
interface Filter {
    type: any;
    value: string;
    key: string;
    caseSensitive: boolean;
    startDate?: string;
    endDate?: string;
}
const MyGeneralTimer = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { dataForTable } = useSelector((state: RootState) => state.masterData);
    const { generalTimeTracking, loader } = useSelector((state: any) => state.project);
    const { teamByLoggedInuser, membersTeam } = useSelector((state: any) => state.user);
    const auther = useSelector((state: any) => state.auth);
    const loggedInUserId = auther?.auther?._id;
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const limit = parseInt(searchParams.get("limit") || "15");
    const currentPage = parseInt(searchParams.get("currentPage") || "1");
    const [tableData, setTableData] = useState([]);
    const userData = useSelector((state: any) => state?.user);
    const Users = userData?.userData?.data;
    const userOptions = useMemo(() => {
        const users = userData?.userData?.data || [];
        return users.map((user: any) => ({
            value: user?._id,
            label: `${user?.FirstName} ${user?.LastName}`,
        }));
    }, [userData]);
    const workerOptions = Users?.filter((user: any) => user?._id === loggedInUserId)?.map((user: any) => ({
        value: user?._id,
        label: `${user?.FirstName} ${user?.LastName}`,
    }));
    const teamleader = teamByLoggedInuser?.data?.data || [];
    const teamMembers = membersTeam?.data?.data || [];
    const teamOptions = useMemo(() => {
        const mergedTeams = [...(teamleader || []), ...(teamMembers || [])];
        const uniqueTeams = mergedTeams.reduce((acc: any[], team: any) => {
            if (!acc.some((t) => t._id === team._id)) acc.push(team);
            return acc;
        }, []);
        return uniqueTeams.map((team: any) => ({
            value: team._id,
            label: team.teamName,
            members: team.members || [],
        }));
    }, [teamByLoggedInuser, membersTeam]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    console.log('chk65', selectedTeams);

    useEffect(() => {
        dispatch(getAllTeamsByUserNoPagination())
        dispatch(getAllTeamsByMemberNoPagination())
    }, [dispatch])
    useEffect(() => {
        if (!searchParams.has("userId") && loggedInUserId) {
            setSearchParams({
                ...Object.fromEntries(searchParams),
                userId: JSON.stringify([loggedInUserId]),
            });
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (generalTimeTracking) {
            setTableData(generalTimeTracking);
        }
    }, [generalTimeTracking]);

    const handleClearFilters = () => {

        const parsedQuery = queryString.parse(location.search);
        const newSearchParams: any = {
            userId: parsedQuery.userId,
            currentPage: "1",
            limit: "15",
        };
        Object.keys(parsedQuery).forEach(key => {
            if (key !== "userId") {
                delete newSearchParams[key];
            }
        });

        setSearchParams(newSearchParams);
        setTableData([]);
    };
    const handleApplyFilters = () => {
        let userIds = new Set();

        // Parse existing query parameters
        const parsedQuery = queryString.parse(location.search);
        console.log("chk105", parsedQuery);

        // If teams are selected, add all members of those teams to the Set (to ensure uniqueness)
        if (selectedTeams.length > 0) {
            selectedTeams.forEach((team: any) => {
                const teamMembers = team.members || [];
                teamMembers.forEach((member: any) => userIds.add(member._id));
            });
        } else {
            // If no team is selected, add only the logged-in user ID
            userIds.add(loggedInUserId);
        }

        // Convert Set back to an array
        const uniqueUserIds = Array.from(userIds);

        // Convert the array to a stringified format for the dynamic filter
        const dynamicFilters = JSON.stringify([
            {
                type: "array",
                value: uniqueUserIds,
                key: "userId",
                caseSensitive: false,
            },
        ]);

        // Update search params in the URL
        setSearchParams({
            ...parsedQuery,
            userId: JSON.stringify(uniqueUserIds),
            currentPage: "1",
            limit: "15",
        });

        // Prepare the API endpoint
        const apiEndPoint = getApiEndPoint(
            {
                limit,
                currentPage,
            },
            location.search,

            columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [],
            dynamicFilters
        );

        console.log("chk135 Final API Endpoint:", apiEndPoint, dynamicFilters);

        // Dispatch the API call
        dispatch(getGeneralTimeTracking(apiEndPoint));
    };

    const columns = useMemo(() => [
        {
            header: ' Sr.No',
            enableColumnFilter: false,
            accessorKey: "no",
            id: "no",
            cell: ({ row }: any) => {
                return (
                    <Text >{((currentPage - 1) * limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            header: 'Worker Name',
            accessorKey: "userId",
            cell: ({ row }: any) => {

                const userInfo = row.original?.userInfo;
                const user = Array.isArray(userInfo) && userInfo.length > 0 ? userInfo[0] : null;
                return (
                    <Text>
                        {user ? `${user.FirstName || ''} ${user.LastName || ''}`.trim() : '-'}
                    </Text>
                );
            },
            enableColumnFilter: true,
            filterType: 'multi-select-dropdown',
            options: workerOptions,
            defaultValue: workerOptions,
        },
        {
            header: 'Title',
            accessorKey: "title",
            cell: ({ row }: any) => {
                const title = row?.original?.title || '';
                return (
                    <Text>{title || '-'}</Text>
                )
            },
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Description',
            accessorKey: "description",
            cell: ({ row }: any) => {
                const desc = row?.original?.description || '';
                return (
                    <Text>{desc || '-'}</Text>
                )
            },
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Status',
            accessorKey: "currentStatus",
            cell: ({ row }: any) => {
                const status = row?.original?.currentStatus || '';
                return (
                    <Text>{status || '-'}</Text>
                )
            },
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Category',
            accessorKey: "category",
            options: categoryTimer,
            enableColumnFilter: true,
            filterType: 'dropdown',

        },
        {
            header: 'Subcategory',
            accessorKey: "subCategory",
            cell: (row: any) => {
                return row.getValue()
                    ?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match: string, index: number) =>
                        match.toUpperCase()
                    )
                    .replace(/\s+/g, '');
            },
            enableColumnFilter: true,
            filterType: "textbox"
        },
        {
            header: 'Project Name',
            accessorKey: "projectId",
            cell: ({ row }: any) => {
                const projectInfo = row?.original?.projectInfo;
                const project = Array?.isArray(projectInfo) && projectInfo?.length > 0 ? projectInfo?.[0] : null;
                return (
                    <Text>{project?.name || '-'}</Text>
                )
            },
            enableColumnFilter: true,
            filterType: 'dropdown',
            options: dataForTable?.data?.allProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name })),
        },
        {
            header: 'Stage Name',
            accessorKey: "stageId",
            cell: ({ row }) => {
                const stageInfo = row.original?.stageInfo;
                const stage = Array.isArray(stageInfo) && stageInfo.length > 0 ? stageInfo[0] : null;
                return (
                    <Text>{stage?.name || '-'}</Text>
                );
            },
            enableColumnFilter: true,
            filterType: 'dropdown',
            options: dataForTable?.data?.allStages?.map((val: { _id: string, name: string }) => ({ value: val.name, label: val.name })),
        },
        {
            header: 'Task Name',
            accessorKey: "taskId",
            cell: ({ row }) => {
                const taskInfo = row.original?.taskInfo;
                const task = Array.isArray(taskInfo) && taskInfo.length > 0 ? taskInfo[0] : null;
                return (
                    <Text>{task?.name || '-'}</Text>
                );
            },
            enableColumnFilter: true,
            filterType: 'textbox',
        },
        {
            header: 'Attendees',
            accessorKey: "members",
            cell: ({ row }) => {
                const members = row.original?.membersInfo || [];
                return (
                    <Text>
                        {members?.map((member: any) => `${member.FirstName} ${member.LastName}`).join(', ') || '-'}
                    </Text>
                );
            },
            enableColumnFilter: true,
            filterType: 'multi-select-dropdown',
            options: userOptions
        },
        {
            header: 'Spend Time',
            accessorKey: "timeTaken",
            cell: ({ row }: any) => {
                const spendTime = row.original.timeTaken;
                const spendTimeMs = spendTime ? +spendTime : 0;
                const formattedSpendTime = spendTimeMs ? `${msToTime(spendTimeMs).hours}:${msToTime(spendTimeMs).minutes}` : "00:00";
                return (
                    <Text variant='Poppins14Normal24lh'>
                        {formattedSpendTime}
                    </Text>
                );
            },
            enableColumnFilter: true,
            filterType: "estimateBox",
        },

        {
            header: 'Date-Time',
            accessorKey: "updatedAt",
            cell: ({ row }: any) => {
                const dueDateValue = row?.original?.updatedAt;
                console.log('chk275', dueDateValue);

                return (
                    <>
                        <Text variant='Poppins14Normal24lh'>
                            {dueDateValue ? moment(dueDateValue.split('T')[0]).format("MMM DD, YYYY") : ""}
                        </Text>

                        <Paragraph variant="Poppins14Normal24lh">
                            {dueDateValue ? moment(dueDateValue).format("hh:mm A") : ""}
                        </Paragraph>
                    </>
                );
            },
            filterType: 'timeRangeBox',
        },
    ], [currentPage, limit, dataForTable]);

    useEffect(() => {
        if (selectedTeams?.length === 0) {
            const apiEndPoint = getApiEndPoint(
                {
                    limit,
                    currentPage,
                    userId: loggedInUserId || "",
                },
                location.search,
                columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [],
                JSON.stringify([
                    {
                        type: "array",
                        value: JSON.stringify([loggedInUserId]),
                        key: "userId",
                        caseSensitive: false,
                    },
                ])
            );

            dispatch(getGeneralTimeTracking(apiEndPoint));
        }
    }, [location.search, limit, currentPage, columns]);
    return (
        <div className="main-content px-3">
            <Box className="d-flex justify-content-between align-items-center" sx={{ mb: 4 }}>
                <Box className="d-flex align-items-center">
                    <h2>My Timer Report</h2>
                    <Button
                        variant='PrimaryHeadBtn'
                        onClick={handleClearFilters}
                        className='toggle-btn'
                        sx={{ ml: 3 }}
                    >
                        Clear Filter
                    </Button>
                </Box>
            </Box>
            <Divider sx={{ color: "#D9D7DD" }} opacity={2} my={[3, 3, 4]} />
            <form>
                <Flex>
                    <div className="col-4 px-1">
                        <Text variant="SelectLabel">Teams</Text>
                        <MultiSelect
                            options={teamOptions || []}
                            value={selectedTeams || []}
                            onChange={setSelectedTeams}
                            labelledBy="Select"
                        />

                    </div>

                </Flex>
                <Button
                    className="mx-2 mt-2"
                    variant="SaveButton"
                    type="button"
                    onClick={handleApplyFilters} >
                    Apply
                </Button>
            </form>
            <Box className="mt-4 px-2 d-flex justify-content-end">
                <Box className="mr-2" variant='styles.summaryBox' sx={{ fontWeight: 500 }}>
                    Total Time: <Text>{
                        generalTimeTracking?.totalTimeTaken?.totalTime
                            ? convertMsToTime(generalTimeTracking?.totalTimeTaken?.totalTime)
                            : '00h:00m:00s'
                    }</Text>
                </Box>
            </Box>
            <Box className="mt-3 report-table-column">
                <DataTable
                    loader={loader}
                    columns={columns}
                    fixedColumn={true}
                    data={generalTimeTracking?.data || []}
                    TotalLength={generalTimeTracking?.count}
                    perPageCount={limit}
                    currentPage={currentPage}
                    onPerPageChange={(itemsPerPage) => {
                        const parsedQuery = queryString.parse(location.search);
                        setSearchParams({
                            ...parsedQuery,
                            limit: itemsPerPage.toString(),
                        });
                    }}
                    onPageChange={(value) => {
                        const parsedQuery = queryString.parse(location.search);
                        setSearchParams({
                            ...parsedQuery,
                            currentPage: value.toString(),
                        });
                    }}
                />
            </Box>
        </div>
    );
};

export default MyGeneralTimer;
import { EMAIL_VERIFY_FAILURE, EMAIL_VERIFY_SUCCESS, FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD__FAILURE, GET_EMAIL_VERIFY, GET_USER_BY_TOKEN, GET_USER_BY_TOKEN_SUCCESS, STORE_LOGIN, STORE_LOGIN_FAILURE, STORE_LOGIN_SUCCESS } from "../storeTypes";

export const loginUser = (payload, callBack) => {
    // console.log("payload--", payload);
    return {
        type: STORE_LOGIN,
        payload: payload,
        callBack
    }
};

export const loginUserSuccess = (payload) => {
    // console.log("payload--final", payload);
    return {
        type: STORE_LOGIN_SUCCESS,
        payload: payload,
    }
};
export const loginUserFailure = (payload) => {
    return {
        type: STORE_LOGIN_FAILURE,
        payload: payload
    }
}
export const emailVerify = (payload, callBack) => {
    // console.log("payload--", payload);
    return {
        type: GET_EMAIL_VERIFY,
        payload: payload,
        callBack
    }
};

export const emailVerifySuccess = (payload) => {
    // console.log("payload--final", payload);
    return {
        type: EMAIL_VERIFY_SUCCESS,
        payload: payload,
    }
};

export const emailVerifyFailure = (payload) => {
    return {
        type: EMAIL_VERIFY_FAILURE,
        payload: payload
    }
}

export const forgetpassword = (payload, callBack) => {
    // console.log("payload--", payload);
    return {
        type: FORGOT_PASSWORD,
        payload: payload,
        callBack
    }
};

export const forgotpasswordSuccess = (payload) => {
    // console.log("payload--final", payload);
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: payload,
    }
};

export const forgetPasswordFailure = (payload) => {
    return {
        type: FORGOT_PASSWORD__FAILURE,
        payload: payload
    }
}

export const getUserbyToken = (payload) => {
    return {
        type: GET_USER_BY_TOKEN,
        payload
    }
}
export const getUserbyTokenSuccess = (payload) => {
    // console.log("autheractionpayload", payload);
    return {
        type: GET_USER_BY_TOKEN_SUCCESS,
        payload
    }
}

// validationUtils.ts
import { withFormik } from 'formik';
import * as Yup from 'yup';

// Define Yup validation schema
const validationSchema = Yup.object().shape({
    taskName: Yup.string().required('Task Name is required'),
    taskType: Yup.string().required('Task Type is required'),
    sopItem: Yup.string().required('Sop Item is required'),
    default: Yup.boolean()
});

// Define the enhancer with Formik and Yup
export const withFormikValidation = withFormik({
    mapPropsToValues: () => ({ taskName: '', taskType: '', sopItem: '', default: false }),
    validationSchema,
    validateOnMount: true,
    handleSubmit: () => { },
});

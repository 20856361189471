import { CHAT_MESSAGE_FAIL, CREATE_CHAT, CREATE_CHAT_SUCCESS, DELETE_CHAT, EDIT_CHAT, EDIT_CHAT_IN_BULK, GET_ALL_CHATS, GET_ALL_CHATS_SUCCESS, GET_ALL_GENERAL_CHATS, GET_ALL_GENERAL_CHATS_SUCCESS, GET_TRADE_FOR_CHATS, GET_TRADE_FOR_CHATS_SUCCESS } from "../storeTypes"

export const createChatMessage = (payload) => {
  return {
    type: CREATE_CHAT,
    payload
  }
}
export const createChatMessageSuccess = (payload) => {
  return {
    type: CREATE_CHAT_SUCCESS,
    payload
  }
}

export const getChatsMessage = (payload, needLoader = true) => {
  return {
    type: GET_ALL_CHATS,
    payload,
    needLoader
  }
}

export const getChatsMessageSuccess = (payload) => {
  return {
    type: GET_ALL_CHATS_SUCCESS,
    payload
  }
}

export const getGeneralChatsMessage = (payload) => {
  return {
    type: GET_ALL_GENERAL_CHATS,
    payload
  }
}

export const getGeneralChatsMessageSuccess = (payload) => {
  return {
    type: GET_ALL_GENERAL_CHATS_SUCCESS,
    payload
  }
}

export const deleteChatsMessage = (id) => {
  return {
    type: DELETE_CHAT,
    id
  }
}

export const editChatsMessage = (payload, id) => {
  return {
    type: EDIT_CHAT,
    payload,
    id
  }
}
export const editChatsMessageInBulk = (payload) => {
  return {
    type: EDIT_CHAT_IN_BULK,
    payload,
  }
}

export const chatMessageFailure = (payload) => {
  return {
    type: CHAT_MESSAGE_FAIL,
    payload
  }
}

export const getTradeForChats = (id, level) => {
  return {
    type: GET_TRADE_FOR_CHATS,
    id,
    level
  }
}
export const getTradeForChatsSuccess = (payload) => {
  return {
    type: GET_TRADE_FOR_CHATS_SUCCESS,
    payload
  }
}


import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from "moment";

interface DatePickerProps {
    onChange: (value: any) => void;
    values: string;
}

export const DateRangeSelect: React.FC<DatePickerProps> = ({ onChange, values }) => {
    const [startDate, setStartDate] = useState<Date | null>();
    const [endDate, setEndDate] = useState<Date | null>(null);
    useEffect(() => {
        if (values) {
            const [start, end] = values.split("to");
            setStartDate(moment(start).toDate());
            setEndDate(moment(end).toDate());
        }
    }, [values]);

    const handleDateChange = (dates: [Date | null, Date | null] | null) => {
        if (Array.isArray(dates)) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
            const formattedValue = start && end ? `${moment(start).format("YYYY-MM-DD")} to ${moment(end).format("YYYY-MM-DD")}` : '';
            onChange(formattedValue);
        }
    };

    return (
        <ReactDatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            isClearable
            maxDate={undefined}
        // showMonthDropdown
        // showYearDropdown
        // dropdownMode="select"
        />
    );
};

import { toast } from 'react-hot-toast';
export const handleApiResponse = (status, response, Callback, isLogout = false, ShowToast = true) => {
    if (status) {
        console.log('chk4', status);

        // let errorMessage = response?.errors?.[0]?.message || response.message;

        switch (status) {
            case 200:
            case 201:
                // console.log('✅', response.data?.title || response?.message || 'done');
                if (ShowToast) {
                    toast.success(response.message);
                }
                Callback(response);
                break;
            case 204:
                // console.log('✅', response.data?.title || response?.message || '204');
                if (ShowToast) {
                    toast.success(response.message);
                }
                Callback(response);
                break;
            // case 400:
            case 404:
            case 500:
                console.error('🚨', response.data?.title || response?.message || '500');
                if (ShowToast) {
                    toast.error(response.message);
                }
                Callback(response);
                break;
            case 401:
                console.error('🚫', response.data?.title || response?.message || '401');
                if (isLogout) {
                    localStorage.removeItem('token');
                    window.location.reload();
                } else {
                    Callback(response);
                }
                break;
            case 429:
                toast.error('Too many requests, please try again later.');
                break;
            default:
                console.log('❗ 🚧', response.data?.title || response?.message || 'Unhandled response status:', response.status);
                break;
        }
    } else {
        console.log('❗ 🚧', 'Invalid or missing status in response:', response);
    }
};
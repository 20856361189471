/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { baseUrl } from '../utils/global';
import toast from 'react-hot-toast';

class NetworkClient {
  constructor() {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    let service = axios.create({
      headers,
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);

    service.interceptors.request.use(async (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['x-access-token'] = token;
      }
      config.baseURL = baseUrl;
      return config;
    });

    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = async (error) => {
    console.log("error -----", error);
    // toast.error(error.response.data.message)
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // console.log("error.response", error.response);
      localStorage.removeItem('token');
      if (!window.location.href.includes("forgot-password")) {
        window.location.replace('/login');
      }
    }
    if (error.response && (error.response.status === 400 || error.response.status === 500)) {

      toast.error(error?.response?.data?.errors?.[0].message || error?.response?.data?.message || "Unexpected Error!")
    }

    //TODO - need a global exception handling strategy at interceptor level.
    // Analytics may be pushed to separate domain.

    return Promise.reject(error);
  };

  get(path, headers) {
    return this.service.get(path, { headers }).then((response) => response);
  }

  patch(path, payload, headers) {
    return this.service
      .request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .then((response) => response);
  }

  post(path, payload, headers) {
    return this.service
      .request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .then((response) => response);
  }

  delete(path, payload, headers) {
    return this.service
      .request({
        method: 'DELETE',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .then((response) => response);
  }

  put(path, payload, headers) {
    return this.service
      .request({
        method: 'PUT',
        url: path,
        responseType: 'json',
        data: payload,
        headers: headers,
      })
      .then((response) => response);
  }
}

export default new NetworkClient();

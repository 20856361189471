import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePreviousURL } from '../../../utils/CustomHandler';
import theme from '../../../theme';

interface Highlighter {
    name: string;
    color: string;
}

interface ProjectNameProps {
    projectId: string;
    projectName: string;
    original: { highlighters: Highlighter[] };
}

export const ProjectName: React.FC<ProjectNameProps> = ({ original, projectId, projectName }) => {
    const navigate = useNavigate();
    const getGradientColor = () => {
        let colors = ["white", "white", "white", "white", "white"];
        let needInfoColor = "white";

        if (Array.isArray(original.highlighters)) {
            original.highlighters.forEach((highlighters) => {
                switch (highlighters.name) {
                    case "Hour a Day":
                        colors[0] = highlighters.color;
                        break;
                    case "Blocked by Info":
                        colors[1] = highlighters.color;
                        break;
                    case "On Hold":
                        colors[2] = highlighters.color;
                        break;
                    case "Project Setup":
                        colors[3] = highlighters.color;
                        break;
                    case "Need Info":
                        needInfoColor = highlighters.color;
                        break;
                    default:
                        break;
                }
            });
        }

        return `linear-gradient(to right, ${colors[0]} 0%, ${colors[0]} 20%, ${colors[1]} 20%, ${colors[1]} 40%, ${colors[2]} 40%, ${colors[2]} 60%, ${colors[3]} 60%, ${colors[3]} 80%, ${needInfoColor} 80%, ${needInfoColor} 100%)`;
    };


    // Custom
    const previousURL = usePreviousURL()
    const handleClick = () => {
        previousURL(`/edit-project/${projectId}`)
    };

    return (
        <div
            // readOnly
            onClick={handleClick}
            style={{
                textDecoration: 'underline',
                color: `${theme.colors?.secondary}`,
                cursor: "pointer",
                paddingTop: '7px',
                paddingBottom: '0px',
                position: 'relative',
                fontWeight: 500,
                textAlign: 'left',
                paddingLeft: '5px',
            }}
        >
            {projectName}
            <div
                style={{
                    position: 'sticky',
                    // bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '3px',
                    // top:'34px',
                    background: getGradientColor(),
                }}
            />
        </div>
    );
};

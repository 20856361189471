import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dots from "../../assets/images/Icon/dots.svg";
import { Overlay, Popover } from 'react-bootstrap';
import { Text } from "theme-ui";
import { useDispatch } from "react-redux";
import { getTradeForChats } from "../../../store/actions/chatAction";
import { useSelector } from "react-redux";
import { usePreviousURL } from "../../../utils/CustomHandler";
import theme from "../../../theme";

interface CommentBox {
    id: string;
    comments: any;
    level: string
}

const CommentsDialogBox: React.FC<CommentBox> = ({ id, comments, level }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { tradeTask } = useSelector((state: any) => state.chats);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [target, setTarget] = useState(null);

    const togglePopover = (event: any) => {
        setIsPopoverOpen(!isPopoverOpen);
        setTarget(event.target);

    };
    const previousURL = usePreviousURL()
    const handleChat = (thredId: any) => {
        previousURL(`/chat${pathname}/${id}/${thredId}`);
    };

    // const handleClickOutside = (event: MouseEvent) => {
    //     if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
    //         setIsPopoverOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);

    const handleClickOutside = (event: any) => {
        if (!event.target.closest('.popover-content')) {

            if (!event.target.closest('.toggle-btn')) {
                setIsPopoverOpen(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const openChatParticularThread = (thread: any) => {
        const threadData = tradeTask?.find((item: any) => item.name === thread?.threadName);
        handleChat(threadData?.taskId?._id)
    }

    return (
        <>
            <div ref={popoverRef} className="d-flex justify-content-center align-items-center" onMouseEnter={() => {
                if (id) {
                    dispatch(getTradeForChats(id, level))
                }
            }}>
                <Overlay show={isPopoverOpen}
                    target={target} placement="right" >
                    <Popover id="popover-basic" className="p-0  d-flex flex-column text-center popover-display popover-content">
                        {tradeTask?.length > 0 && tradeTask?.map((trade: any, index: number) => <Text key={index} onClick={() => {
                            handleChat(trade?.taskId?._id)
                        }} sx={{ minWidth: "100px", display: "block", cursor: "pointer", '&:hover': { background: `${theme?.colors?.lightbackground}` }, padding: "0.5rem", borderRadius: "8px" }}>{trade?.name}</Text>)}
                    </Popover>
                </Overlay>
                <div onClick={togglePopover} className="p-2 toggle-btn" style={{ marginRight: '20px', cursor: "pointer" }}>

                    <img src={dots} width={20} height={20} alt="dots" />
                </div>

                <div className="d-flex" style={{ cursor: "pointer" }}>
                    {comments?.threads
                        ?.filter((thread: any) => !thread?.isAcknowledged) // Only include threads where isAcknowledged is false
                        .map((thread: any, index: number) => {
                            // Always display threads if threadName is "All" or "PM"
                            if (thread?.threadName === "All" || thread?.threadName === "PM") {
                                return (
                                    <hr
                                        key={index}
                                        className="rotateHr"
                                        style={{
                                            backgroundColor: thread?.color,
                                            color: thread?.color,
                                        }}
                                        onClick={() => openChatParticularThread(thread)}
                                    />
                                );
                            }

                            // Display threads if threadName matches comments.name
                            if (thread?.threadName === comments?.taskId?.name) {
                                return (
                                    <hr
                                        key={index}
                                        className="rotateHr"
                                        style={{
                                            backgroundColor: thread?.color,
                                            color: thread?.color,
                                        }}
                                        onClick={() => openChatParticularThread(thread)}
                                    />
                                );
                            }

                            // Otherwise, don't render anything
                            return null;
                        })}
                </div>

            </div>

        </>
    );
};

export default CommentsDialogBox;

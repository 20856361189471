import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { Box, Button, Card, Flex, Label, Paragraph, Text } from 'theme-ui';
import timerIcon from '../../../assets/images/Icon/EstimatedIcon.svg';
import { msToTime } from '../../../../utils/GenerateQueryString';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Inputform } from '../../../forms-component/InputForm';
import { estimatedReason } from '../../../../utils/constent';
import FormSelect from '../../../forms-component/globalSelect';
import { changeEstimationTime } from '../../../../store/actions/project.action';
import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { TimeEstimateHistory } from '../TimeEstimateHistory';
import theme from '../../../../theme';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types';

export const EstimatedTimeModal = ({ estimatedOpen, estimatedClose, timeEstimate, isTracking, timeTaken }: any) => {
  const { hours: hoursEstimate, minutes: minutesEstimate, seconds: secondsEstimate } = msToTime(timeEstimate);
  const { taskId } = useParams<{ taskId: string }>();
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [tradeNameRef, setTradeNameRef] = useState<string>("");
  const timeSpent = msToTime(timeTaken);
  const { auther } = useSelector((state: RootState) => state.auth);


  useEffect(() => {
    if (!estimatedOpen) {
      setShowHistory(false);
    }
  }, [estimatedOpen]);

  const hoursToMilliseconds = (hours: any, minutes: any) => {
    return (hours * 3600 + minutes * 60) * 1000;
  }
  const allowedRoles = ["Project Manager", "Project Worker", "Super Admin", "Region Admin"];
  const restrictedOptions = ["BC (xrefs)", "PS (xrefs)", "External Client Meeting"];
  const filteredEstimatedReason = estimatedReason.filter((option) => {
    const isRestrictedOption = restrictedOptions.includes(option.label);

    // Check if user has any allowed role
    const hasAllowedRole = auther?.Role?.some((role: any) =>
      allowedRoles.includes(role.RoleName)
    );

    if (isRestrictedOption) {
      return hasAllowedRole;
    }

    return true; // Include non-restricted options
  });
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: "",
      reasonType: "",
      hoursValue: +hoursEstimate || 0,
      minValue: +minutesEstimate || 0,
    },
    validationSchema: Yup.object({
      description: Yup.string().required('Reason is required'),
      reasonType: Yup.string().required('Reason Type is required'),
      hoursValue: Yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "not valid"
      ),
      minValue: Yup.number().max(60, "Maximum value is 60")
    }),
    onSubmit: (value) => {
      const newTime = hoursToMilliseconds(value.hoursValue, value.minValue);
      const timeSpent = msToTime(timeTaken);

      const spentTime = `${timeSpent?.hours}:${timeSpent?.minutes}:${timeSpent?.seconds?.toString().padStart(2, '0')}`

      if (newTime <= timeTaken) {
        toast.error(`New estimated time must be greater than the time already spent.Your spent time is ${spentTime}`);
        return;
      }
      const payload = {
        newTime: hoursToMilliseconds(value.hoursValue, value.minValue),
        description: value.description,
        reasonType: value.reasonType,
      };

      dispatch(changeEstimationTime(payload, taskId));
      estimatedClose();
    },
  });

  const { handleSubmit, setFieldValue, values, handleBlur, touched, errors } = formik;

  const estimateTimeHandler = () => {
    if (isTracking) {
      toast.dismiss();
      toast.error("Time tracking in progress, So manage estimated time are disabled.", {
        duration: 4000,
      });
    }
  };

  return (
    <Modal isOpen={estimatedOpen} centered size='lg' toggle={estimatedClose}>
      <ModalHeader toggle={estimatedClose}>
        <Paragraph>
          <img src={timerIcon} alt='' className='mx-2' />
          <Text>Manage Estimated time</Text>
        </Paragraph>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody className='scrollable-modal-body text-center'>
          <Card sx={{ boxShadow: "none", border: `${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "12px 20px" }}>
            <Text sx={{ color: `${theme.colors?.muted}` }}>Estimated Time</Text>
            <Paragraph sx={{ fontSize: "32px", fontWeight: "400", mt: "1.5rem" }}>{`${hoursEstimate || "00"}:${minutesEstimate || "00"}:${secondsEstimate || "00"}`}</Paragraph>
            <Text sx={{ color: `${theme.colors?.muted}`, fontSize: "10px" }}>{`${hoursEstimate || "00"} Hours ${minutesEstimate || "00"} Minutes ${secondsEstimate || "00"} seconds`}</Text>
            <Flex sx={{ gap: "1rem", alignItems: "center", justifyContent: "center", mt: "1.5rem" }}>
              <Button
                type='button'
                variant='startButton'
                disabled={(values.minValue === 0 && values.hoursValue === 0)}
                sx={{ borderColor: `${theme?.colors?.black}`, color: `${theme?.colors?.black}`, padding: "8px 12px 8px 12px" }}
                onClick={() => {
                  if (values.minValue === 30) {
                    setFieldValue("minValue", 0);
                  } else if (values.minValue === 0) {
                    if (values.hoursValue > 0) {
                      setFieldValue("hoursValue", values.hoursValue - 1);
                    }
                    setFieldValue("minValue", values.minValue + 30);
                  } else {
                    setFieldValue("minValue", values.minValue - 30);
                  }
                }}>
                - 30 min <Text sx={{ color: `${theme?.colors?.primary}` }}>Subtract</Text>
              </Button>
              <Inputform
                label={""}
                Placeholder={`${hoursEstimate}`}
                Name={"hoursValue"}
                touched={touched?.hoursValue}
                errors={errors?.hoursValue}
                values={values.hoursValue}
                onBlur={handleBlur}
                type='number'
                sx={{ width: "63px" }}
                OnChange={(event: any) => {
                  const hoursValue = event.target.value;
                  setFieldValue("hoursValue", hoursValue, true);
                }}
              />:
              <Inputform
                label={""}
                Placeholder={`${minutesEstimate}`}
                Name={"minValue"}
                type='number'
                // touched={touched?.minValue}
                // errors={errors?.minValue}
                values={values.minValue}
                onBlur={handleBlur}
                sx={{ width: "63px" }}
                OnChange={(event: any) => {
                  const minValue = event.target.value;
                  setFieldValue("minValue", minValue, true);
                }}
              />
              <Button
                type='button'
                variant='startButton'
                sx={{ borderColor: `${theme?.colors?.black}`, color: `${theme?.colors?.black}`, padding: "8px 12px 8px 12px" }}
                onClick={() => {
                  if (values.minValue === 30) {
                    setFieldValue("hoursValue", +values.hoursValue + 1);
                    setFieldValue("minValue", 0);
                  } else {
                    setFieldValue("minValue", +values.minValue + 30);
                  }
                }}>
                + 30 min <Text sx={{ color: `${theme?.colors?.primary}` }}>Extend</Text>

              </Button>

            </Flex>
            <Box>
              {(errors.minValue && touched.minValue) && (
                <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: "5px", minHeight: "12px", lineHeight: '10px' }}>
                  {errors.minValue.toString()}
                </Text>
              )}
            </Box>
            <Box sx={{ mt: "1rem", textAlign: "start" }}>
              <Label sx={{ fontSize: '14px', fontWeight: 500 }} className={`form-label mt-1 ${errors.reasonType && touched.reasonType ? 'is-invalid' : ''}`}>
                Reason Type<span className="mx-1 text-danger">*</span>
              </Label>
              <FormSelect
                value={values?.reasonType}
                placeholder={"Select Reason Type"}
                options={filteredEstimatedReason || []}
                isMulti={false}
                touched={touched?.reasonType}
                errors={errors?.reasonType}
                onBlur={handleBlur}
                Name='reasonType'
                onChange={(values: any) => {
                  setFieldValue("reasonType", values?.value, true);
                }}
              />
            </Box>
            <Box sx={{ mt: "1rem", textAlign: "start" }}>
              <Inputform
                label={"Enter the Reason"}
                Placeholder={`Enter the reason...`}
                Name={"description"}
                touched={touched?.description}
                errors={errors?.description}
                asterisk={true}
                values={values.description}
                onBlur={handleBlur}
                OnChange={(event: any) => {
                  const description = event.target.value;
                  setFieldValue("description", description, true);
                }}
              />
            </Box>
          </Card>
        </ModalBody>
        <ModalFooter className='gap-2 justify-content-end p-3'>
          <Button variant='SaveButton' type={isTracking ? 'button' : 'submit'} onClick={estimateTimeHandler}>Send</Button>
          <Flex
            sx={{
              border: `1px solid ${theme.colors?.primary}`,
              borderRadius: "12px",
              mx: "1rem",
              mt: 2,
              padding: "14px",
              cursor: 'pointer',
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
            onClick={() => setShowHistory(!showHistory)}>
            <Text>History</Text>{showHistory ? <FaAngleUp className='text-end' /> : <FaAngleDown className='text-end' />}
          </Flex>
        </ModalFooter>
      </form>
      {showHistory && (
        <>
          <h5 className='ps-4 mt-2'>Time Estimate History</h5>
          <TimeEstimateHistory tradeNameRef={tradeNameRef} showHistory={showHistory} />
        </>
      )}
    </Modal>
  );
};

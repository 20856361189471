import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button, Flex, Text } from "theme-ui";
import { createActionHighlight, getActionHighlighterIds, getActionHighlighterIdsSuccess } from "../../../store/actions/action.Highlighters";
import { useSelector } from "react-redux";

export default function ActionIPRCompleted({ selectedHighlight, Open, setOpen, actionObject, message }: any) {
  const { createHighlight } = useSelector((state: any) => state.actionHighlight)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getActionHighlighterIds(actionObject, { name: selectedHighlight?.name }))
  }, [createHighlight])

  useEffect(() => {
    return () => {
      dispatch(getActionHighlighterIdsSuccess([]))
    }
  }, [])


  const SubmitActionIPRCompleted = () => {

    const payload = {
      actionType: selectedHighlight?.name,
      payload: {
        Description: []
      },
      ...actionObject
    }
    dispatch(createActionHighlight(payload))
    togglePopover()
  }
  const togglePopover = () => {
    setOpen(!Open);
  };

  return (
    <Modal isOpen={Open} scrollable toggle={togglePopover} centered className='w-100'>
      <ModalHeader toggle={togglePopover}> <Flex sx={{ gap: "0.5rem" }}><img src={selectedHighlight?.icon} width={"22px"} /><Text sx={{ color: selectedHighlight?.color }}>{selectedHighlight?.name?.toUpperCase()}</Text></Flex></ModalHeader>
      <ModalBody className='mb-0 pb-0'>
        <div style={{
          width: '100%',
          position: 'relative',
          zIndex: 1,
          backgroundColor: 'white',
          border: '1px solid #black',
          boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06);',
          borderRadius: '4px',
          padding: '20px 4px'
        }}>
          <Text className=" w-100" as="p">
            <strong>{message}</strong>
          </Text>
        </div>
      </ModalBody>
      <ModalBody className='d-flex justify-content-end gap-2'>
        <Button variant="CancelButton" onClick={togglePopover} >Cancel</Button>
        <Button variant="SaveButton" onClick={SubmitActionIPRCompleted} >Submit</Button>
      </ModalBody>
    </Modal >
  )
}

import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { Text } from "theme-ui";
import DataTable from "../../Tabels/Tabel";

const MonthlyReport = () => {
    const columns = useMemo<ColumnDef<any>[]>(() => [

        {
            header: 'Sr.',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{row.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "projectName",
            header: ' Name',

        },
        {
            accessorKey: "projectName",
            header: 'Janyary Rating Avg',

        },
        {
            accessorKey: "projectName",
            header: ' Name',

        },

    ], []);
    return (
        <>
            <div className='main-content'>
                <Text className="ps-3">Monthly Report</Text>
                <DataTable
                    columns={columns}
                    fixedColumn={true}
                    data={[]}
                    TotalLength={15}
                    showNavigation={false}
                    perPageCount={15}
                    currentPage={1}
                    onPerPageChange={() => { }}
                    onPageChange={() => { }}
                />
            </div>
        </>
    )
}

export default MonthlyReport
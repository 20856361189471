import React, { useState } from 'react';
import { Flex, Image, Text, Button } from 'theme-ui';
import deleteIcon from "../../assets/images/Icon/delete.svg";
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { ActionDeleteById } from '../../../store/actions/CommonAction';

interface CellComponentProps {
    row: any;
    deleteMessage: string;
    deleteApi: string;
    toggleRefresh: () => void;
}
const TableDeleteActions: React.FC<CellComponentProps> = ({ row, deleteMessage = "Are you sure you want to delete?", deleteApi, toggleRefresh }) => {
    const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
    const dispatch = useDispatch();

    const confirmDelete = () => {
        dispatch(ActionDeleteById({ Query: deleteApi }, (res: any) => {
            if (res.status) {
                setIsDeleteModelOpen(false);
                toggleRefresh();
            }
        }));
    };

    const cancelDelete = () => {
        setIsDeleteModelOpen(false);
    };
    return (
        <Flex sx={{ justifyContent: 'center' }}>
            <Flex
                sx={{ alignItems: 'center', cursor: 'pointer' }}
                onClick={() => setIsDeleteModelOpen(true)}
            >
                <Image sx={{ height: '28px', width: '28px' }} src={deleteIcon} />
            </Flex>

            <Modal isOpen={isDeleteModelOpen} toggle={cancelDelete} centered className='w-100'>
                <ModalBody className='mb-0 pb-0'>
                    <div style={{
                        width: '100%',
                        position: 'relative',
                        zIndex: 1,
                        backgroundColor: 'white',
                        border: '1px solid #black',
                        boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)',
                        borderRadius: '4px',
                        padding: '20px 4px'
                    }}>
                        <Text className="w-100" as="p">
                            <strong>{deleteMessage}</strong>
                        </Text>
                    </div>
                </ModalBody>
                <ModalBody className='d-flex'>
                    <Button onClick={confirmDelete} sx={{ marginRight: '10px', width: '50%' }}>Yes</Button>
                    <Button onClick={cancelDelete} sx={{ width: '50%' }}>No</Button>
                </ModalBody>
            </Modal>
        </Flex>)
}

export default TableDeleteActions
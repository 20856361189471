import { useFormik } from 'formik';
import queryString from 'query-string';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Label, Radio, Spinner, Text } from 'theme-ui';
import * as Yup from 'yup';
import { RootState } from '../../../../../@types';
import { createStepOne } from '../../../../store/actions/project.action';
import theme from '../../../../theme';
import { Inputform } from '../../../forms-component/InputForm';
import FormSelect from '../../../forms-component/globalSelect';
import MultiCard from './Multicard';
import FolderNumberDropdown from './folderNumberDropdown';


const StepOne: React.FC = () => {
  const dispatch = useDispatch();
  const { auther } = useSelector((state: RootState) => state.auth)
  const { createprojectMasterData, getproject, loader } = useSelector((state: RootState) => state.project);
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = queryString.parse(location.search) as any;
  const architects = createprojectMasterData?.allArchitect?.data || [];
  const projectManagers = createprojectMasterData?.allProjectManagers || [];
  const projectWorkers = createprojectMasterData?.allProjectWorkers || [];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: getproject?.name,
      architect: getproject?.architect || null,
      projectManager: getproject?.projectManager || null,
      projectWorker: getproject?.projectWorker,
      blueprint: getproject?.blueprint,
      quoteNumber: getproject?.quoteNumber,
      folderNumber: getproject?.folderNumber,
      clientAddress: getproject?.clientAddress,
      clientContactPerson: getproject?.clientContactPerson,
      clientEmail: getproject?.clientEmail,
      tmQuote: getproject?.tmQuote || false,
      createdBy: auther?._id,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      // architect: Yup.string().required('Architect is required'),
      // projectManager: Yup.string().required('Project Manager is required'),
      // projectWorker: Yup.string().required('Project Worker is required'),
      blueprint: Yup.string().required('Blueprint is required'),
      quoteNumber: Yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Only digits are allowed!!"
      ),
      clientContactPerson: Yup.string().matches(
        /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Only digits are allowed!!"
      ),
      folderNumber: Yup.string().required("folderNumber is required"),
      // tmQuote: Yup.boolean().required("tmQuote is required"),
    }),
    onSubmit: (values) => {
      dispatch(createStepOne(values, formik));
    },
  });
  const { handleSubmit, setFieldValue, handleBlur, touched, values, errors } = formik;
  const handleNextClick = () => {
    if (parsed?.id) {
      navigate('/create-project?step=2&id=' + parsed?.id)
      // handleSubmit()
    } else {
      handleSubmit()
    }
  };


  return (
    <div className='container-fluid'>
      <form onSubmit={handleSubmit}>
        <Box mb={3} sx={{ display: ['block', 'grid', 'grid'], gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'], gap: 3 }}>
          <Box className='mt-2'>
            <Inputform
              label={"Name"}
              Placeholder={"Project Name..."}
              Name={"name"}
              Title_sx={{ color: "grey3" }}
              touched={touched?.name}
              errors={errors?.name}
              values={values.name}
              asterisk={true}
              Max_Length={10}
              onBlur={handleBlur}
              OnChange={(values: any) => {
                setFieldValue("name", values?.target?.value, true)
              }}
            />
          </Box>
          <Box className='mt-2'>
            <FormSelect
              label={"Project Manager"}
              value={values?.projectManager}
              placeholder={"Select Project Manager"}
              options={projectManagers.map((projectManager) => ({
                label: `${projectManager?.FirstName} ${projectManager?.LastName}`,
                value: projectManager?._id
              })) || []}
              isMulti={false}
              DefultSelect='Select PM'
              touched={touched?.projectManager}
              errors={errors?.projectManager}
              onBlur={handleBlur}
              Name='projectManager'
              onChange={(values: any) => {
                setFieldValue("projectManager", values?.value, true);
              }}
            />
          </Box>
          <Box className='mt-2'>
            <FormSelect
              label={"Project Worker"}
              value={values?.projectWorker}
              placeholder={"Select Project Worker"}
              options={projectWorkers.map((projectWorker: { FirstName: any; LastName: string; _id: any; }) => ({
                label: `${projectWorker?.FirstName} ${projectWorker?.LastName}`,
                value: projectWorker?._id
              })) || []}
              isMulti={false}
              DefultSelect='Select Worker'
              touched={touched?.projectWorker}
              errors={errors?.projectWorker}
              onBlur={handleBlur}
              Name='projectWorker'
              onChange={(values: any) => {
                setFieldValue("projectWorker", values?.value, true);
              }}
            />
          </Box>
          <Box className='mt-2'>
            <FormSelect
              label={"Architect"}
              placeholder='Select Architect'
              value={values?.architect}
              options={architects.map((architect) => ({
                label: architect?.name,
                value: architect?._id
              })) || []}
              touched={touched?.architect}
              errors={errors?.architect}
              onBlur={handleBlur}
              Name='architect'
              onChange={(values: any) => {
                setFieldValue("architect", values?.value, true)
              }}
            />
          </Box>
          <Box className='mt-2'>
            <Inputform
              // mb={4}
              label={"Quote Number"}
              Placeholder={"Quote Number"}
              Name={"quoteNumber"}
              type='text'
              Title_sx={{ color: "grey3" }}
              touched={touched?.quoteNumber}
              errors={errors?.quoteNumber}
              values={values.quoteNumber}
              Max_Length={10}
              onBlur={handleBlur}
              OnChange={(event) => {
                // Filter out non-numeric characters
                const cleanedValue = event.target.value.replace(/[^\d]/g, ''); // Only digits (0-9) allowed
                setFieldValue("quoteNumber", cleanedValue, true);
              }}
              onKeyDown={(event) => {
                const charCode = event.which ? event.which : event.keyCode;
                if (charCode < 48 || charCode > 57) {
                  event.preventDefault();
                }
              }}
            />
          </Box>
          <Box className='mt-2'>
            <FolderNumberDropdown
              label={"Folder Number"}
              value={values?.folderNumber}
              touched={touched?.folderNumber}
              errors={errors?.folderNumber}
              onBlur={handleBlur}
              asterisk={true}
              onChange={(selectedFolderNumber) => {
                setFieldValue("folderNumber", selectedFolderNumber, true);
              }}
              disable={false}
            />
          </Box>
          <Box className='mt-2'>
            <Inputform
              label={"Client Address"}
              Placeholder={"Enter Address..."}
              Name={"clientAddress"}
              Title_sx={{ color: "grey3" }}
              touched={touched?.clientAddress}
              errors={errors?.clientAddress}
              values={values.clientAddress}
              asterisk={false}
              Max_Length={10}
              onBlur={handleBlur}
              OnChange={(values: any) => {
                setFieldValue("clientAddress", values?.target?.value, true)
              }}
            />
          </Box>
          <Box className='mt-2'>
            <Inputform
              label={"Client Email"}
              Placeholder={"Enter Email..."}
              Name={"clientEmail"}
              Title_sx={{ color: "grey3" }}
              touched={touched?.clientEmail}
              errors={errors?.clientEmail}
              values={values.clientEmail}
              asterisk={false}
              Max_Length={10}
              onBlur={handleBlur}
              OnChange={(values: any) => {
                setFieldValue("clientEmail", values?.target?.value, true)
              }}
            />
          </Box>
          <Box className='mt-2'>
            <Inputform
              label={"Client Contact "}
              Placeholder={"Enter Contact..."}
              Name={"clientContactPerson"}
              Title_sx={{ color: "grey3" }}
              touched={touched?.clientContactPerson}
              errors={errors?.clientContactPerson}
              values={values.clientContactPerson}
              asterisk={false}
              onBlur={handleBlur}
              OnChange={(values: any) => {

                setFieldValue("clientContactPerson", values?.target?.value, true);
              }}

            />
          </Box>
          <Box className='mt-2' sx={{ alignItems: 'center' }}>
            <Text variant='SelectLabel'>T&M Quote</Text>
            <Box sx={{ display: 'flex' }}>
              <Label>
                <Radio
                  name='tmquote'
                  value='true'
                  checked={values.tmQuote === true}
                  onChange={() => setFieldValue('tmQuote', true)}
                  onBlur={handleBlur}
                />
                Yes
              </Label>
              <Label>
                <Radio
                  name='tmquote'
                  value='false'
                  checked={values.tmQuote === false}
                  onChange={() => setFieldValue('tmQuote', false)}
                  onBlur={handleBlur}
                  defaultChecked
                />
                No
              </Label>
            </Box>
            {touched.tmQuote && errors.tmQuote && <p className='text-danger'>{errors.tmQuote}</p>}
          </Box>
        </Box>
        <MultiCard
          value={values.blueprint}
          onChange={(blueprint: string) => formik.setFieldValue('blueprint', blueprint)}
          labelledBy='Select Blueprint Template'
        />
        {touched.blueprint && errors.blueprint && <p className='text-danger'>{errors?.blueprint}</p>}
      </form>
      <Flex sx={{ justifyContent: "end", paddingBottom: '30px' }}>
        <Button disabled={loader} sx={{ background: theme.colors?.primary, padding: '12px 18px', width: "25%" }} onClick={handleNextClick}> {loader ? <Spinner size={30} sx={{ color: "#fff" }} /> : "Next"}</Button>
      </Flex>

    </div >
  );
};

export default StepOne;

import { Theme } from "theme-ui";

const theme: Theme = {
  breakpoints: ["640px", "832px", "1024px", "1280px"],
  space: [0, 8, 12, 16, 18, 24, 32, 40, 64, 128, 256],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  sizes: [0, 8, 16, 32, 64, 128, 256],
  colors: {
    text: '#C3ACD0',
    background: ['#7743DB', '#fff',],
    primary: '#7743DB',
    secondary: '#0A0A0A',
    InactiveBorder: "#F7EFE5",
    lightbackground: "#F7EFE5",
    ActiveBorder: '#7743DB',
    muted: ' #8F9195',
    grey: "#8D8D8D",
    lightgray: "#CCCCCC",
    brown: '#453521',
    lightbrown: '#C4AF95',
    danger: "#E54141",
    green: "green",
    musterd: "#CA8A04",
    warning: "#eac42a",
    white: '#FDFDFD',
    black: '#000',
    transparent: "transparent",
    red: "#FF0000",
    salmon: "#FF8484",
    whitesmoke: "#F6F6F6",
  },
  forms: {
    Checkbox: {
      border: '1px solid #7743DB',
      background: '#8d8d8d'
    },
    Textarea: {
      borderColor: '#F7EFE5',
    },
    FilterModal: {
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
      border: '1px solid #F7EFE5',
      borderRadius: 12,
      backgroundColor: "white",
      p: 3,
      outline: 'none'

    }
  },

  fonts: {
    body: 'Poppins',
    heading: 'Poppins',
    poppins: "Poppins"
  },
  text: {
    SelectLabel: {
      color: '#0A0A0A',
      fontFamily: "Poppins",
      fontSize: [12, 12, 12, 13, 14, 14],
      fontWeight: "500",
      lineHeight: "50px"
    },
    activityText: {
      color: '#8F9195',
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '120%',
      fontStyle: 'normal',
      display: 'flex',
      padding: '12px 8px',
      alignItems: 'center',
      gap: '12px',
      alignSelf: 'stretch',
      borderRadius: '12px',
      border: '0.5px solid var(--light-color, #F7EFE5)'
    },
    SenderName: {
      fontFamily: 'poppins',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left'
    },
    tableHeading: {
      color: '#020202',
      fontFamily: 'poppins',
      fontSize: 36,
      fontWeight: 600
    },
    Poppins16Normal25lh: {
      color: 'secondary',
      fontSize: 14,
      fontFamily: 'poppins',
      fontWeight: 500,
      lineHeight: '156.25%',
      textAlign: 'center',
      textTransform: 'capitalize'
    },
    Poppins14Normal24lh: {
      color: 'muted',
      fontSize: 14,
      fontFamily: 'poppins',
      fontWeight: 400,
      lineHeight: '171%',
      textAlign: 'center',
    },
    Poppins15Normal24lh: {
      color: 'muted',
      fontSize: 15,
      fontFamily: 'poppins',
      fontWeight: 500,
      lineHeight: '171%',
      textAlign: 'center',
    },
    Poppins16Bold16lh: {
      fontSize: [12, 13, 15, 16],
      fontFamily: 'poppins',
      lineHeight: '16px',
      fontWeight: 600
    },
    Poppins14Medium20lh: {
      fontSize: [12, 13, 14, 14],
      fontWeight: 500,
      lineHeight: '20px',
      fontFamily: 'poppins'
    },
    Poppins14Normal: {
      color: 'grey',
      fontSize: 14,
      fontFamily: 'poppins',
      fontWeight: 400,
      lineHeight: 'normal',
      textAlign: 'center',
    },
    Poppins16Bold: {
      color: '#FF8C00',
      fontSize: 15,
      fontFamily: 'poppins',
      fontWeight: 500,
      lineHeight: 'normal',
      textAlign: 'left',
    },
    Poppins36Normal35lh: {
      color: 'secondary',
      fontFamily: 'poppins',
      fontSize: [24, 28, 30, 32, 32],
      fontWeight: 600,
      lineHeight: '100%',
      textTransform: 'capitalize'
    },
    Poppins30Normal35lh: {
      color: 'secondary',
      fontFamily: 'poppins',
      fontSize: [24, 28, 30, 30, 30],
      fontWeight: 500,
      lineHeight: '100%',
      textTransform: 'capitalize'
    },

    bagde: {
      color: "#FDFDFD",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "16px",
      fontStyle: "normal",
      fontFamily: "poppins"
    }
  },
  buttons: {
    SaveButton: {
      borderRadius: '12px',
      padding: '8px 45px',
      backgroundColor: '#7743db',
      color: '#fff'
    },
    CancelButton: {
      borderRadius: '12px',
      padding: '8px 45px',
      color: '#8F9195',
      border: '1px solid #8F9195',
      backgroundColor: '#fff'
    },
    spentButton: {
      padding: '4px 8px 4px 8px',
      borderRadius: '12px',
      gap: '10px',
      backgroundColor: '#F7EFE5',
      border: 'none',
      color: '#7743DB'
    },
    timetrack: {
      borderRadius: '12px',
      padding: '6px 12px 6px 12px',
      gap: '8px',
      // width:'30%'
    },
    startButton: {
      borderRadius: '12px',
      padding: '6px 12px 6px 12px',
      gap: '8px',
      color: '#8F9195',
      backgroundColor: 'white',
      border: '1px solid #8F9195',
      // width:'0%'
    },
    stopButton: {
      borderRadius: '12px',
      padding: '6px 12px 6px 12px',
      gap: '8px',
      color: '#DB4343',
      backgroundColor: 'white',
      border: '1px solid #DB4343',
    },
    sendbutton: {
      display: 'flex',
      padding: '8px',
      alignItems: 'center',
      gap: '10px',
      borderRadius: '12px',
      background: "#f7efe5"
    },
    PrimaryHeadBtn: {
      color: "grey",
      backgroundColor: 'transparent',
      fontFamily: 'Poppins',
      borderRadius: 8,
      border: "1px solid #8D8D8D",
      fontWeight: 500,
      textAlign: "center",
      fontSize: [12, 13],
      transition: "background 0.3s",
      '&.active': {
        background: 'primary',
        color: '#fff',
        border: "1px solid #023E1A",
      },
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      background: '#fff'
    },
    compact: {
      padding: { 2: 2, 5: 2 },
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
    PaginationBoredercard: {
      backgroundColor: "transparent",
      borderRadius: "8px",
      justifyContent: 'center',
      border: "1px solid",
      borderColor: 'muted',
      p: 2,
    },
    fillterCardModal: {
      backgroundColor: 'white',
      p: 4,
      borderRadius: 12,
      border: ' 0.5px solid #F7EFE5',
      boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)'
    }
  },
  badges: {
    DropDownbedge: {
      border: "1px solid #F7EFE5",
      borderRadius: 20,
      position: 'absolute',
      marginTop: '10px',
      // ml: 3,
      backgroundColor: "InactiveBorder",
      color: 'secondary'
    },
    badgeCount: {
      border: "1px solid #F7EFE5",
      borderRadius: 20,
      position: 'relative',
      marginTop: '10px',
      // ml: 3,
      backgroundColor: "InactiveBorder",
      color: 'secondary'
    },
  },
  links: {

    bold: {
      fontWeight: 'bold',
      color: 'primary'
    },
    nav: {
      fontWeight: 'bold',
      color: 'black',
      textDecoration: 'none',

    }
  },
  images: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: '24px',
    },
    chatAvtar: {
      width: '28px',
      height: '28px'
    },
    // avatar: {
    //   // minWidth:'39%',
    //   width: '48px',
    //   height: '48px',
    //   borderRadius: "99999",
    // },
  },

  styles: {
    Button: {
      background: 'white',
      color: '#C3ACD0'
    },
    cards: {
      primary: {
        padding: 2,
        borderRadius: 4,
        boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)',
      },
      compact: {
        padding: 1,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'muted',
      },
    },
    root: {
      fontFamily: 'Poppins'
    },
    h1: {
      fontSize: '36px',
      fontWeight: 600,
      lineHeight: '56px',
      color: '#020202',
    },
    form: {
      width: "77%",
    },
    label: {
      fontWeight: "body",
      marginTop: 3,
      fontSize: 2,
      lineHeight: "body",
    },
    input: {
      border: "1px solid",
      borderColor: "primary",
      padding: "10px 14px 10px 14px",
    },
    errorFeedback: {
      marginTop: 3,
      color: "error", // Adjust color as needed
    },
    checkboxContainer: {
      alignItems: "center",
    },
    checkboxInput: {
      marginLeft: 2,
    },
    forgotPasswordLink: {
      color: "primary",
      marginLeft: "auto",
    },
    loginButton: {
      variant: "primary",
      width: "100%",
      borderRadius: "button",
      fontSize: 2,
      marginTop: 3,
    },
    signUpText: {
      top: 3,
      position: "relative",
      display: "flex",
    },
    summaryBox: {
      gap: ["0.1rem", "0.5rem", "0.15rem", "0.15rem"],
      alignItems: "center",
      border: "1px solid #8D8D8D",
      padding: ["8px 10px", "8px 10px", "8px 10px", "8px"],
      borderRadius: "8px"
    }
  },
  fontWeights: {
    heading: 700,
    body: 500
  },
  lineHeights: {
    heading: '38px',
    body: '28px'
  },
  radii: {
    default: '12px',
    card: '8px',
    button: "12px"
  },
};

export default theme;

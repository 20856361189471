import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Divider, Image, Text } from 'theme-ui';
import { IndeterminateCheckbox } from '../../CheckBox/CheckBox';
import DataTable from '../../Tabels/Tabel';
import { TabelHeadingTxt } from '../../Text/TabelCommonTxt';
import plus from "../../assets/images/Icon/plus.svg";
import theme from '../../../theme';

const Blueprint: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    const { masterData, tasksowMapping } = useSelector((state: any) => state.masterData);
    const { paginations } = useSelector((state: any) => state.pagination);
    const columns: ColumnDef<any>[] = [
        {
            accessorKey: "id",
            id: "select",
            enableColumnFilter: false,
            header: ({ table }) => (
                <div className="d-flex justify-content-center">
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                </div>
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />

            ),
        },
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <div className='d-flex p-1 text-center' style={{ justifyContent: 'space-between' }}>
                        <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row?.index + 1}</Text>
                    </div >
                )
            }
        },
        {
            accessorKey: "name",
            header: 'Name',
            cell: info => info.getValue(),
        },
        {
            header: 'Description',
            accessorKey: "description",
            cell: info => info.getValue(),
        },
        {
            header: 'Image',
            accessorKey: "image",
            cell: ({ row }) => {
                return (
                    <Link style={{ color: `${theme.colors?.secondary}`, borderColor: 'transparent' }} to={row.original.image}>
                        {/* <Image>{trimText(row.getValue("image"), 40)} */}
                        <Image src={row.getValue("image")} />
                    </Link>
                )
            }
        },

    ];
    // const handleclick = () => {
    //     navigate("/create-task-sow")
    // }
    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Blueprints' Count={tasksowMapping?.count || 15} BtnTxt='Create Blueprint' src={plus} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                columns={columns}
                data={masterData?.[0]?.values || []}
                TotalLength={masterData?.count || 15}
                perPageCount={paginations.limit}
                currentPage={paginations.currentPage}
                showNavigation={false}
                onPageChange={function (page: any): void {
                    throw new Error('Function not implemented.');
                }} onPerPageChange={function (value: string): void {
                    throw new Error('Function not implemented.');
                }} />
        </div>
    )
};

export default Blueprint;

import { call, put, takeLatest } from "redux-saga/effects";
import { chatMessageFailure, createChatMessageSuccess, getChatsMessageSuccess, getTradeForChatsSuccess } from "../actions/chatAction";
import { createChatMessageApi, deleteChatsMessageApi, editChatsMessageApi, editChatsMessageInBulkApi, getChatsMessageApi, getTradeForChatsApi } from "../api";
import { CREATE_CHAT, DELETE_CHAT, EDIT_CHAT, EDIT_CHAT_IN_BULK, GET_ALL_CHATS, GET_ALL_GENERAL_CHATS, GET_TRADE_FOR_CHATS } from "../storeTypes";
import toast from "react-hot-toast";

function* createChatMessageSaga(action) {
  try {
    const response = yield call(createChatMessageApi, action.payload);
    yield put(createChatMessageSuccess(response?.data))
  } catch (error) {
    yield put(chatMessageFailure(error))
  }
}

function* getChatsMessageSaga(action) {
  try {
    const response = yield call(getChatsMessageApi, action.payload);
    yield put(getChatsMessageSuccess(response?.data))
  } catch (error) {
    yield put(chatMessageFailure(error))
  }
}

function* clearChatsMessageSaga(action) {
  try {
    yield put(getChatsMessageSuccess(null))
  } catch (error) {
    // console.log(error);
  }
}

function* deleteChatsMessageSaga({ id }) {
  try {
    const response = yield call(deleteChatsMessageApi, id);
    yield put(createChatMessageSuccess(response?.data?.data))
  } catch (error) {
    yield put(chatMessageFailure(error))
  }
}


function* editChatsMessageSaga(action) {
  try {
    const response = yield call(editChatsMessageApi, action);
    yield put(createChatMessageSuccess(response?.data?.data))
    toast.success("chat update Successfully");
  } catch (error) {
    yield put(chatMessageFailure(error))
    toast.error("chat update unsuccessfull");
  }
}
function* editChatsMessageInBulkSaga(action) {
  try {
    const response = yield call(editChatsMessageInBulkApi, action);
    yield put(createChatMessageSuccess(response?.data?.data))
  } catch (error) {
    yield put(chatMessageFailure(error))
    toast.error("chat update unsuccessfull");
  }
}

function* getTradeForChatsSaga(action) {
  try {
    const response = yield call(getTradeForChatsApi, action);
    yield put(getTradeForChatsSuccess(response?.data?.data?.data))
  } catch (error) {
    yield put(chatMessageFailure(error))
  }
}
export function* chatMessageSaga() {
  yield takeLatest(CREATE_CHAT, createChatMessageSaga);
  yield takeLatest(GET_ALL_GENERAL_CHATS, clearChatsMessageSaga);
  yield takeLatest(GET_ALL_CHATS, getChatsMessageSaga);
  yield takeLatest(DELETE_CHAT, deleteChatsMessageSaga);
  yield takeLatest(EDIT_CHAT, editChatsMessageSaga);
  yield takeLatest(EDIT_CHAT_IN_BULK, editChatsMessageInBulkSaga);
  yield takeLatest(GET_TRADE_FOR_CHATS, getTradeForChatsSaga);
}

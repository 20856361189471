import axios from "axios";

export const saveFileAs = (data: any, fileName: any) => {
    if (!data.startsWith('data:')) {
        // alert(JSON.stringify(data, null, 3))
        axios({
            method: 'get',
            url: data,
            responseType: 'blob',
            withCredentials: false,
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response: any) {
            // console.log("response", response.data);
            downloadBlob(response.data, fileName)
        })
    } else {
        downloadBlob(dataURLtoBlob(data), fileName);
    }
};

export const downloadBlob = (blob: any, fileName: any) => {
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.setAttribute("href", url);
    anchor.setAttribute("download", fileName);
    anchor.click();

    URL.revokeObjectURL(url);
}

export const dataURLtoBlob = (dataURL: any) => {
    const [header, base64data] = dataURL.split(",");
    const mimeType = header.split(":")[1].split(";")[0];
    const byteString = atob(base64data);
    const len = byteString.length;
    const intArray = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        intArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([intArray], { type: mimeType });
};
import { CREATE_RATING, CREATE_RATING_SUCCESS, GET_MY_RATING, GET_MY_RATING_SUCCESS, GET_RATING_BY_ID, GET_RATING_BY_ID_SUCCESS, GET_REPORT_HISTORY, GET_REPORT_HISTORY_BY_ID, GET_REPORT_HISTORY_BY_ID_SUCCESS, GET_REPORT_HISTORY_SUCCESS } from "../storeTypes"
const initialState: any = {
    rating: undefined,
    MyRating: [{
        data: [],
        count: ''
    }],
    loader: false,
    reportHistory: null,
    reportHistoryById: null,

}
const RatingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_RATING_BY_ID:
            return {
                ...state,
                loader: true,
            }
        case GET_RATING_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                rating: action.payload
            }
        case CREATE_RATING:
            return {
                ...state,
                loader: true
            }
        case CREATE_RATING_SUCCESS:
            return {
                ...state,
                loader: false
            }
        case GET_MY_RATING:
            return {
                ...state,
                loader: true
            }
        case GET_MY_RATING_SUCCESS:
            return {
                ...state,
                loader: false,
                MyRating: action.payload
            }
        case GET_REPORT_HISTORY:
            return {
                ...state,
                loader: true
            }
        case GET_REPORT_HISTORY_SUCCESS:
            return {
                ...state,
                loader: false,
                reportHistory: action.payload
            }
        case GET_REPORT_HISTORY_BY_ID:
            return {
                ...state,
                loader: true
            }
        case GET_REPORT_HISTORY_BY_ID_SUCCESS:
            return {
                ...state,
                loader: false,
                reportHistoryById: action.payload
            }
        default:
            return state;
    }
}

export default RatingReducer

import React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Box, Card, Grid, Text, Textarea } from 'theme-ui';
import { ItemType, RootState } from '../../../../@types';
import { CheckBox2 } from '../../CheckBox/Project';
import "../../project-components/projectTab.css";
import FormSelect from '../../forms-component/globalSelect';
import { Inputform } from '../../forms-component/InputForm';

interface StageTaskDataProps {
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  values: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  errors: any;
  touched: any;
  SowChecklist: any[];
  sowfields: any[];
}


const AddStageTaskData: React.FC<StageTaskDataProps> = ({
  selectedIndex,
  setSelectedIndex,
  values,
  setFieldValue,
  handleBlur,
  errors,
  touched,
  SowChecklist,
  sowfields,
}) => {
  const { createprojectMasterData } = useSelector((state: RootState) => state.project);
  const projectManagers = createprojectMasterData?.allTaskManager || [];
  const projectWorkers = createprojectMasterData?.allTaskWorkers || [];

  // let sowMapping: any = []
  console.log(values?.task, "jjjjjjjjjjjjjjjjj");


  return (
    <Box>
      <Text as={'h4'} mt={3} mb={2}>
        Tasks
      </Text>
      <Tabs selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
        <TabList>

          {values?.stagesData[0]?.name && <Tab
            style={{
              // borderColor: '#ffffff00',
              color: values?.stagesData[0]?.color,
              borderRadius: '5px 5px 0 0',
              fontSize: '18px',
              fontWeight: 600,
              borderBottom: `3px solid ${values?.stagesData[0]?.color} !important`,
            }}
          >
            {values?.stagesData[0]?.name}
          </Tab>}
        </TabList>

        {/* {values?.stagesData?.map((stages: any, stageIndex: number) => ( */}
        <TabPanel>
          {/* {values?.stagesData[0]?.task?.map((department: any, departmentIndex: number) => { */}
          {values?.task?.map((department: any, departmentIndex: number) => {
            const ChecklistItem = SowChecklist?.filter((entry) => entry?.taskType === department?.id);
            const SowFields = sowfields?.filter((entry) => entry?.taskName === department?.id);

            // if (!department.sowMapping) {
            //   department.sowMapping = [];
            // }

            if (!values?.task[departmentIndex]?.sowMapping) {
              values.task[departmentIndex].sowMapping = [];
            }
            console.log(values?.task, "departmentIndex");

            return (
              <>
                <Card key={department?.id} mt={7} sx={{ width: '100%', borderRadius: '12px' }}>
                  <Box sx={{ borderLeft: `3px solid ${department?.color}`, paddingLeft: '24px' }}>
                    <Text as={'h3'} sx={{ color: department?.color }}>
                      {department?.name}
                    </Text>
                    <Box mt={3}>
                      <CheckBox2
                        title={''}
                        Option={ChecklistItem}
                        onChange={(event: any, item: ItemType) => {
                          if (event) {
                            if (!values?.task[departmentIndex]?.sowMapping?.includes(item?._id)) {
                              values?.task[departmentIndex]?.sowMapping.push(item?._id);
                            }
                            setFieldValue(`task[${departmentIndex}].description`, values?.target?.value, true);
                          } else {
                            console.log("---------else");

                            let indexToRemove = values?.task[departmentIndex]?.sowMapping?.indexOf(item?._id);
                            if (indexToRemove !== -1) {
                              values?.task[departmentIndex]?.sowMapping?.splice(indexToRemove, 1);
                            }
                          }
                        }}
                      />
                    </Box>

                    <Text mt={3} as={'h5'}>
                      Description
                    </Text>
                    <Textarea
                      name={`task[${departmentIndex}].description`}
                      value={values?.task?.[departmentIndex]?.description}
                      //value={(values?.stagesData?.[0] as { task?: Array<{ description?: string }> })?.task?.[departmentIndex]?.description}
                      onChange={(values) => {
                        setFieldValue(`task[${departmentIndex}].description`, values?.target?.value, true);
                      }}
                      onBlur={handleBlur}
                      rows={8}
                      sx={{ borderColor: 'InactiveBorder' }}
                    />

                    {errors.stagesData && touched.stagesData ? (
                      <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                        {(errors?.stagesData?.[0] as { task?: Array<{ description?: string }> })?.task?.[departmentIndex]?.description}
                      </Text>
                    ) : (
                      <Text variant="Inter15Regular30lh" sx={{ my: '5px', minHeight: '11px' }}></Text>
                    )}

                    <Grid mt={2} gap={2} columns={[1, 2, 3, 3, 4, 4]}>
                      {SowFields?.map((sowfield: any, index: number) => (
                        <Box key={sowfield?.fieldName || index}>
                          {sowfield?.type === 'select' && sowfield?.fieldName === 'Task Worker' ? (
                            <FormSelect
                              Name=''
                              options={projectWorkers?.map((projectWorker: { FirstName: string; LastName: string; _id: string; }) => ({
                                label: `${projectWorker?.FirstName} ${projectWorker?.LastName}`,
                                value: projectWorker?._id
                              })) || []}
                              label={sowfield?.fieldName}
                              placeholder={sowfield?.fieldName}
                              value={values?.task[departmentIndex]?.worker}
                              onBlur={handleBlur}
                              touched={touched?.stagesData?.[0]?.task?.[departmentIndex]?.worker}
                              errors={(errors?.stagesData?.[0] as { task?: Array<{ worker?: string }> })?.task?.[departmentIndex]?.worker}
                              onChange={(values: any) => {
                                // console.log("valuesvalues", values);
                                setFieldValue(`task[${departmentIndex}].worker`, values?.value);
                              }}
                            />
                          ) : sowfield?.type === 'select' && sowfield?.fieldName === 'Task Manager' ? (
                            <FormSelect
                              options={projectManagers.map((projectWorker: { FirstName: string; LastName: string; _id: string; }) => ({
                                label: `${projectWorker?.FirstName} ${projectWorker?.LastName}`,
                                value: projectWorker?._id
                              })) || []}
                              placeholder={sowfield?.fieldName}
                              label={sowfield?.fieldName}
                              onBlur={handleBlur}
                              touched={touched?.stagesData?.[0]?.task?.[departmentIndex]?.manager}
                              errors={(errors?.stagesData?.[0] as { task?: Array<{ manager?: string }> })?.task?.[departmentIndex]?.manager}
                              value={values?.task[departmentIndex]?.manager}
                              onChange={(values: any) => {
                                // console.log("valuesvalues", values);
                                setFieldValue(`task[${departmentIndex}].manager`, values?.value);
                              }}
                            />
                          ) : (
                            <Inputform
                              type={sowfield?.type}
                              label={sowfield?.fieldName}
                              Placeholder={sowfield?.fieldName}
                              touched={touched?.stagesData?.[0]?.task?.[departmentIndex]?.taskFields}
                              errors={(errors?.stagesData?.[0] as { task?: Array<{ taskFields?: string }> })?.task?.[departmentIndex]?.taskFields}
                              Name={`task[${departmentIndex}].taskFields[${sowfield?.fieldName}]`}
                              Title_sx={{ color: 'grey' }}
                              values={((values?.stagesData?.[0] as { task?: Array<{ taskFields?: Record<string, string> }> })?.task?.[departmentIndex]?.taskFields || {})[sowfield?.fieldName]}
                              onBlur={handleBlur}
                              OnChange={(values: any) => {
                                setFieldValue(
                                  `task[${departmentIndex}].taskFields[${sowfield?.fieldName}]`,
                                  values?.target?.value,
                                  true
                                );
                              }}
                            />
                          )}
                        </Box>
                      ))}
                    </Grid>

                  </Box>
                </Card>
              </>
            );
          })}
        </TabPanel>


      </Tabs>
    </Box>
  );
};

export default AddStageTaskData;

import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LuX } from 'react-icons/lu';
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Badge, Box, Button, Flex, Image, Label, Radio, Spinner, Switch, Text, Textarea } from 'theme-ui';
import * as Yup from 'yup';
import { MediaProps, RootState } from '../../../@types';
import { UpdateProjectAttachment, editProjectById, getHighlighterByView, getHighlighterByViewSuccess, getHistoryByView, getProjectById, getProjectByIdSuccess, getSowChecklist, getSowFields } from '../../store/actions/project.action';
import theme from '../../theme';
import { baseUrl } from '../../utils/global';
import { Inputform } from '../forms-component/InputForm';
import FormSelect from '../forms-component/globalSelect';
import FolderNumberDropdown from '../pages/create project /StepOne/folderNumberDropdown';
import ProjectSideActions from './ProjectSidebar';
import StepTwoOnly from './viewStages';
import StepThreeOnly from './viewTask';
import editIcon from "../../Components/assets/images/edit.svg";
import spent from "../../Components/assets/images/Icon/spent.svg";
import plyBtn from "../../Components/pages/TaskView/playBtn.svg";
import UploadMediaFile from '../UploadMediaFile/UploadMediaFile';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { ActionOnHold } from '../Actions/ActionModule/ActionOnHold';
import { ActionProjectSetup } from '../Actions/ActionModule/ActionProjectSetup';
import MarkDownParagraph from '../forms-component/MarkDownParagraph';
import { createActionHighlight, removeHighlightFromProject } from '../../store/actions/action.Highlighters';
import { statusColors } from '../../utils/constent';
import GeneralTimerStart from '../pages/TaskView/GeneralTimerModal/GeneralTimerStart';

const DoubleClickModal = ({ isOpen, toggle, highlight }: any) => {

    const dispatch = useDispatch();
    const { projectId } = useParams<{ projectId: string }>();

    const handleSwitchChange = () => {
        if (highlight?.name) {
            const payload = {

                highlighterName: highlight.name
            }
            dispatch(removeHighlightFromProject(payload, projectId,));
            toggle();
        }
    };
    return (

        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Acknowledgement</ModalHeader>
            <ModalBody>
                <Box><Switch sx={{ position: 'absolute', top: '17px', right: '8px' }} onChange={handleSwitchChange} /></Box>
                <Text>Are you sure you want to remove Highlighters?</Text>
            </ModalBody>

        </Modal>
    )
};

const EditModal: React.FC = () => {
    const location = useLocation();
    const { projectId } = useParams<{ projectId: string }>();
    const { getproject, createprojectMasterData, loader } = useSelector((state: RootState) => state.project);
    const { uploadedMedia } = useSelector((state: MediaProps) => state?.media);
    const [description, setDescription] = useState<string>('');
    const [projectStatus, setProjectStatus] = useState<keyof typeof statusColors | "">("");
    const architects = createprojectMasterData?.allArchitect?.data || [];
    const projectManagers = createprojectMasterData?.allProjectManagers || [];
    const projectWorkers = createprojectMasterData?.allProjectWorkers || [];
    const [highlighters, setHighlighters] = useState<object[]>([]);
    const [isModalOpen, setModalOpen] = useState<boolean>(true);
    const [isDoubleClickModalOpen, setDoubleClickModalOpen] = useState<boolean>(false);
    const [modalOpen, issetModalOpen] = useState<boolean>(false);
    const { auther } = useSelector((state: RootState) => state.auth);

    // const { stagebyproject } = useSelector(({ state }) => state);
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const debouncedDispatch = debounce(
        (fieldName: string, value: any) => {
            dispatch(editProjectById({ [fieldName]: value }, projectId, fieldName));
            dispatch(getHistoryByView(`projectId=${projectId}`))
        },
        500
    );
    const debounceRequest = useCallback((fieldName: string, value: any) => {
        debouncedDispatch(fieldName, value)
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        return () => {
            debouncedDispatch.cancel()
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (getproject.highlighters) {
            setHighlighters(getproject.highlighters)
        }

    }, [getproject.highlighters]);

    useEffect(() => {
        if (getproject?.projectSow?.description) {
            setDescription(getproject?.projectSow?.description || '');

        }
    }, [getproject?.projectSow?.description]);
    useEffect(() => {
        if (getproject?.status) {
            setProjectStatus(getproject?.status as keyof typeof statusColors);
        }
    }, [getproject?.status])

    const handleEditClick = () => {
        setIsEditing(true);
    };
    const handleSaveClick = () => {
        debounceRequest('projectSow.description', description);
        setIsEditing(false);

    };
    const handleCancelClick = () => {
        setDescription(getproject?.projectSow?.description || '');
        setIsEditing(false);
    };
    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };
    const toggleStartTimerModal = () => {
        issetModalOpen(!modalOpen);

    };
    const closeModal = () => {
        setModalOpen(false);
        navigate(-1)
    };
    const toggleDoubleClickModal = () => {
        setDoubleClickModalOpen(!isDoubleClickModalOpen);
    };
    useEffect(() => {
        dispatch(getProjectById(projectId));
        dispatch(getHighlighterByView("project"));
        dispatch(getSowChecklist());
        dispatch(getSowFields())
        return () => {
            dispatch(getProjectByIdSuccess([]));
            dispatch(getHighlighterByViewSuccess([]));
        }
    }, []);

    useEffect(() => {
        if (uploadedMedia !== null) {
            dispatch(getProjectById(projectId));
        }
    }, [uploadedMedia])
    const validationSchema = Yup.object().shape({
        architect: Yup.string().required('Architect is required'),
        projectManager: Yup.string().required('Project Manager is required'),
    });
    // const useFormFieldUpdater = () => {
    //     const formikContext = useFormikContext();
    //     if (!formikContext) {
    //         throw new Error("useFormikContext must be used within a Formik component");
    //     }
    //     return formikContext;
    // };
    const handleSseChange = (data: { [key: string]: any }) => {
        Object.keys(data)?.forEach((element: string) => {

            if (element === "highlighters") {
                handleHighlightersUpdate(data[element]);
            }
            if (element === "status") {
                setProjectStatus(data[element] as keyof typeof statusColors);
            }
            if (element === "projectSow.description") {
                setDescription(data[element]);
            }
            formik.setFieldValue(`${element}`, data[element] || "", true);
        });

    }
    const handleHighlightersUpdate = (data: any) => {
        setHighlighters((prev) => Array.isArray(data) ? [...data] : [])
    }
    // useEffect(() => {
    //     console.log('SSE useEffect executed');
    //     const eventSource = new EventSource(`${baseUrl}sse/${projectId}`);

    //     eventSource.onmessage = (event) => {
    //         try {
    //             const data = JSON.parse(event.data);
    //             console.log(data, "check108");
    //             handleSseChange(data);
    //             dispatch(UpdateProjectAttachment(data.attachments))
    //         } catch (error) {
    //             console.error('Error parsing SSE data:', error);
    //         }
    //     };

    //     eventSource.onerror = (error) => {
    //         console.error('SSE Error:', error);
    //         eventSource.close();
    //     };

    // return () => {
    //     eventSource.close();
    // };
    // }, []);

    const { data: eventSource } = useQuery({
        queryKey: ['projectSSE', projectId],
        queryFn: () => {
            const eventSource = new EventSource(`${baseUrl}sse/${projectId}`);
            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    handleSseChange(data);
                    dispatch(UpdateProjectAttachment(data.attachments))
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };

            eventSource.onerror = (error) => {
                console.error('SSE Error:', error);
                eventSource.close();
            };
            return eventSource;
        },
        refetchInterval: 60000
    })
    useEffect(() => {
        return () => {
            eventSource?.close();
        };
    }, [])

    const handleCheckboxChange = (event: any) => {
        const isOutOfStatePresent = getproject.highlighters.some(highlighter => highlighter.name === "Out of State");

        const isChecked = isOutOfStatePresent ? false : true;
        dispatch(createActionHighlight({
            actionType: "Out of State",
            projectId: projectId,
            isChecked: isChecked
        }));
        setFieldValue("outOfState", event.target.checked);
    };
    const formik = useFormik(

        {
            enableReinitialize: true,
            initialValues: {

                name: getproject?.name || '',
                description: getproject?.projectSow?.description || '',
                architect: getproject?.architect || '',
                projectManager: getproject?.projectManager || '',
                projectWorker: getproject?.projectWorker || '',
                folderNumber: getproject?.folderNumber || '',
                quoteNumber: getproject?.quoteNumber || '',
                clientAddress: getproject?.clientAddress,
                clientContactPerson: getproject?.clientContactPerson,
                clientEmail: getproject?.clientEmail,
                "Project #": getproject?.projectSow?.fields?.["Project #"],
                tmQuote: getproject?.tmQuote,
                "Spec Documents?": getproject?.projectSow?.fields?.["Spec Documents?"],
                outOfState: getproject?.highlighters?.some(highlighter => highlighter.name === "Out of State") || false,
            },
            onSubmit: () => { }
        }
    );
    const { handleSubmit, setFieldValue, handleBlur, touched, values, errors } = formik;

    // implement Highlight
    const [Open, setOpen] = useState(false);
    const [selectedHighlight, setSelectedHighlight] = useState<any>(null);
    const [highlight, setHighlight] = useState<any>(null);

    const handleHighlightClick = (highlight: any) => {
        if (clickTimeoutRef?.current) {
            clearTimeout(clickTimeoutRef?.current);
            clickTimeoutRef.current = null;
            const dblclickHighlights = ['Background Change Done', 'New Info Added', 'Revision', 'Plan Check', 'Need Info', 'Internal Review', 'Hour a Day',
                'Blocked By Trade', 'Todo Per Review', 'Blocked by Info', 'Final Review', 'Delivery Notes', 'Ready To Deliver', ' Deliver', 'Project Setup', 'Blocked By Trade Completed', 'Background Change InProgress', ' Waiting for Payment - FULL', ' Waiting for Payment - PRELIM', 'Waiting for Trade', 'Background Change']
            setHighlight(dblclickHighlights?.includes(highlight?.name) ? { ...highlight } : highlight);
            setDoubleClickModalOpen(true);

        }
        else {
            clickTimeoutRef.current = setTimeout(() => {
                clickTimeoutRef.current = null;
                const backgroundChangeGroups = ["On Hold", "Project Setup"];
                setSelectedHighlight(backgroundChangeGroups?.includes(highlight?.name) ? { ...highlight } : highlight);
                setOpen(true);
            }, 300);
        }

    };


    const cursorPointerGroups = ["On Hold", "Project Setup", "Hour a Day", "Internal Review", 'Todo Per Review', 'Blocked by Info', "Delivery Notes", 'Ready To Deliver', ' Deliver', "Blocked By Trade", 'Blocked By Trade Completed', ' Waiting for Payment - FULL', ' Waiting for Payment - PRELIM', 'Waiting for Trade', "Need Info", "New Info Added", "Final Review", "Plan Check", "Revision", "Background Change", "Background Change Done", "Background Change InProgress",];
    return (
        <>
            <Modal isOpen={isModalOpen} toggle={closeModal} scrollable centered style={{ maxWidth: "93%" }}>
                <ModalHeader className='d-table'>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', }}>
                        <h4 style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap', }}>
                            {getproject?.name} &nbsp;
                            {highlighters?.map((badge: any) => (
                                <Badge
                                    key={badge?._id}
                                    color={badge?.color}
                                    sx={{
                                        backgroundColor: 'transparent',
                                        borderRadius: "8px",
                                        gap: '3px',
                                        padding: '4px 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: `1px solid ${badge?.color}`,
                                        cursor: `${cursorPointerGroups.includes(badge.name) ? "pointer" : "default"}`
                                    }}
                                    onClick={(e: any) => {
                                        handleHighlightClick(badge);
                                    }}
                                >
                                    {badge?.icon && <Image
                                        title={badge.name}
                                        src={badge?.icon}
                                        alt={badge?.name}
                                        width={14}
                                        height={14}
                                    />}
                                    <Text sx={{ color: `${badge?.color}` }}>{badge?.name}</Text>
                                </Badge>
                            ))}

                        </h4>

                        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                            {getproject?.status !== 'Draft' && (
                                <Button variant="startButton" onClick={toggleStartTimerModal}>
                                    <img src={spent} alt="" /> Start Tracking <img src={plyBtn} alt="" />
                                </Button>
                            )}
                            {loader && <Spinner size={30} className='mx-2' />}
                            <LuX onClick={closeModal} style={{ cursor: 'pointer', marginLeft: 'auto' }} />

                        </div>

                    </div>
                    <Box >
                        <Badge
                            sx={{
                                backgroundColor: `${statusColors[projectStatus as keyof typeof statusColors] || `${theme?.colors?.muted}`}`,
                                border: `1px solid ${statusColors[projectStatus as keyof typeof statusColors] || `${theme?.colors?.muted}`}`, borderRadius: '8px',
                                padding: '4px 8px',
                                color: '',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            {projectStatus}
                        </Badge>
                        {/* <Text variant='Poppins16Normal25lh' sx={{ fontWeight: 'normal' }}>{projectStatus}</Text> */}
                        {getproject?.trelloId && <a target='_blank' href={`https://trello.com/c/${getproject?.trelloId}`} style={{
                            position: 'absolute',
                            right: "20px",
                            width: "150px",
                            background: "#7743db",
                            color: "#ffffff",
                            textDecoration: "none",
                            padding: "5px",
                            borderRadius: "5px",
                            textAlign: "center",
                            fontSize: "large"
                        }}>{"Go to Trello"}<IoLogOutOutline className='ms-2 fs-3' /></a>}
                    </Box>
                    <GeneralTimerStart isOpen={modalOpen} toggle={toggleStartTimerModal} autherId={auther?._id} projectId={projectId} />

                </ModalHeader>

                <ModalBody className='p-0' >
                    <Flex sx={{ flexDirection: 'row' }}>
                        <div className='col-md-8' >
                            <Box className="p-3">
                                <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Text variant="SelectLabel">Description</Text>
                                    {!isEditing && (
                                        <Image
                                            src={editIcon}
                                            sx={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                            onClick={handleEditClick}
                                        />
                                    )}
                                </Flex>
                                {isEditing ? (
                                    <>
                                        <Textarea
                                            // className="form-control"
                                            value={description}
                                            onChange={handleTextareaChange}
                                            rows={description.length > 30 ? 10 : 2}
                                            placeholder="Description..."
                                        />
                                        <Flex className="mt-3" sx={{ justifyContent: 'end', gap: '8px' }}>
                                            <Button
                                                sx={{
                                                    background: 'white',
                                                    color: theme?.colors?.primary,
                                                    border: '1px solid',
                                                    textAlign: 'center',
                                                    borderRadius: '12px',
                                                }}
                                                onClick={handleCancelClick}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                sx={{ background: theme?.colors?.primary, textAlign: 'center', borderRadius: '12px' }}
                                                onClick={handleSaveClick}
                                            >
                                                Save
                                            </Button>
                                        </Flex>
                                    </>
                                ) : (
                                    // <textarea
                                    //     className="form-control"
                                    //     value={description}
                                    //     readOnly
                                    //     rows={5}
                                    //     style={{ flexGrow: 1 }}
                                    // />
                                    <Box className='ps-2' sx={{ minHeight: `${description.length < 30 ? "72px" : "265px"}`, maxHeight: '400px', border: `2px solid ${theme?.colors?.lightbackground}`, overflowY: "auto", borderRadius: "4px", resize: 'vertical' }}>

                                        <MarkDownParagraph markdown={description} />
                                    </Box>
                                )}

                            </Box>
                            <Box className='p-3 mt-2' sx={{ display: ['block', 'grid', 'grid'], gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'], gap: 3 }}>
                                <Box >
                                    <Inputform
                                        label={"Name"}
                                        Placeholder={"Project Name..."}
                                        Name={"name"}
                                        Title_sx={{ color: "grey3" }}
                                        touched={touched?.name}
                                        errors={errors?.name}
                                        values={values.name}
                                        Max_Length={10}
                                        onBlur={handleBlur}
                                        OnChange={(event: any) => {
                                            const newName = event.target.value;
                                            setFieldValue("name", newName, true);
                                            debounceRequest('name', newName.trim())
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <FormSelect
                                        label={"Architect"}
                                        placeholder='Select Architect'
                                        value={values?.architect}
                                        options={architects.map((architect) => ({
                                            label: architect?.name,
                                            value: architect?._id
                                        })) || []}
                                        // disabled={true}
                                        touched={touched?.architect}
                                        errors={errors?.architect}
                                        onBlur={handleBlur}
                                        Name='architect'
                                        styles={{ color: "#000" }}
                                        onChange={(selectedArchitect: any) => {
                                            setFieldValue("architect", selectedArchitect?.value, true);
                                            debounceRequest('architect', selectedArchitect?.value);
                                        }} />
                                </Box>
                                <Box>
                                    <FormSelect
                                        label={"Project Manager"}
                                        value={values?.projectManager}
                                        placeholder={"Select Project Manager"}
                                        options={projectManagers.map((projectManager) => ({
                                            label: `${projectManager?.FirstName} ${projectManager?.LastName}`,
                                            value: projectManager?._id
                                        })) || []}
                                        isMulti={false}
                                        // disabled={true}
                                        DefultSelect='Select PM'
                                        touched={touched?.projectManager}
                                        errors={errors?.projectManager}
                                        onBlur={handleBlur}
                                        Name='projectManager'
                                        onChange={(selectedManager: any) => {
                                            setFieldValue("projectManager", selectedManager?.value, true);
                                            debounceRequest('projectManager', selectedManager?.value);
                                        }}
                                    // onChange={(values: any) => { setFieldValue("projectManager", values?.value, true); }}
                                    />
                                </Box>
                                <Box>
                                    <FormSelect
                                        label={"Project Worker"}
                                        value={values?.projectWorker}
                                        placeholder={"Select Project Worker"}
                                        options={projectWorkers.map((projectworker: any) => ({
                                            label: `${projectworker?.FirstName} ${projectworker?.LastName}`,
                                            value: projectworker?._id
                                        })) || []}
                                        isMulti={false}
                                        // disabled={true}
                                        DefultSelect='Select Worker'
                                        touched={touched?.projectWorker}
                                        errors={errors?.projectWorker}
                                        onBlur={handleBlur}
                                        Name='projectWorker'
                                        onChange={(selectedWorker: any) => {
                                            setFieldValue("projectWorker", selectedWorker?.value, true);
                                            debounceRequest('projectWorker', selectedWorker?.value);
                                        }}
                                    // onChange={(values: any) => { setFieldValue("projectWorker", values?.value, true) }}
                                    />
                                </Box>
                                <Box>
                                    <FolderNumberDropdown
                                        label={"Folder Number"}
                                        value={values?.folderNumber}
                                        touched={touched?.folderNumber}
                                        errors={errors?.folderNumber}
                                        onBlur={handleBlur}
                                        onChange={(selectedFolderNumber: any) => {
                                            setFieldValue("folderNumber", selectedFolderNumber, true);
                                            if (!loader) {
                                                debounceRequest('folderNumber', selectedFolderNumber);
                                            }
                                        }}
                                        // onChange={(selectedFolderNumber) => {
                                        //     setFieldValue("folderNumber", selectedFolderNumber, true);
                                        // }}
                                        disable={false}
                                    />

                                </Box>
                                <Box>
                                    <Inputform
                                        // mb={4}
                                        label={"Quote Number"}
                                        Placeholder={"Quote Number"}
                                        Name={"quoteNumber"}
                                        type='number'
                                        Title_sx={{ color: "grey3" }}
                                        touched={touched?.quoteNumber}
                                        errors={errors?.quoteNumber}
                                        values={values.quoteNumber}
                                        Max_Length={10}
                                        onBlur={handleBlur}
                                        OnChange={(event: any) => {
                                            const newQuote = event.target.value;
                                            setFieldValue("quoteNumber", newQuote, true);
                                            debounceRequest('quoteNumber', newQuote);
                                        }}
                                    // OnChange={(values: any) => {
                                    //     setFieldValue("quoteNumber", values?.target?.value, true)
                                    // }}
                                    // disabled
                                    />
                                </Box>
                                <Box >
                                    <Inputform
                                        label={"Client Address"}
                                        Placeholder={"Enter Address..."}
                                        Name={"clientAddress"}
                                        Title_sx={{ color: "grey3" }}
                                        touched={touched?.clientAddress}
                                        errors={errors?.clientAddress}
                                        values={values.clientAddress}
                                        Max_Length={10}
                                        onBlur={handleBlur}
                                        OnChange={(event: any) => {
                                            const newName = event.target.value;
                                            setFieldValue("clientAddress", newName, true);
                                            debounceRequest('clientAddress', newName.trim())
                                        }}
                                    />
                                </Box>
                                <Box >
                                    <Inputform
                                        label={"Client Email"}
                                        Placeholder={"Enter Email..."}
                                        Name={"clientEmail"}
                                        Title_sx={{ color: "grey3" }}
                                        touched={touched?.clientEmail}
                                        errors={errors?.clientEmail}
                                        values={values.clientEmail}
                                        Max_Length={10}
                                        onBlur={handleBlur}
                                        OnChange={(event: any) => {
                                            const newName = event.target.value;
                                            setFieldValue("clientEmail", newName, true);
                                            debounceRequest('clientEmail', newName.trim())
                                        }}
                                    />
                                </Box>
                                <Box >
                                    <Inputform
                                        label={"Client Contact "}
                                        Placeholder={"Enter Contact..."}
                                        Name={"clientContactPerson"}
                                        Title_sx={{ color: "grey3" }}
                                        touched={touched?.clientContactPerson}
                                        errors={errors?.clientContactPerson}
                                        values={values.clientContactPerson}
                                        Max_Length={10}
                                        onBlur={handleBlur}
                                        OnChange={(event: any) => {
                                            const newName = event.target.value;
                                            setFieldValue("clientContactPerson", newName, true);
                                            debounceRequest('clientContactPerson', newName.trim())
                                        }}
                                    />
                                </Box>
                                <>
                                    <Inputform
                                        label={"Out of State"}
                                        Placeholder={"Out of State"}
                                        type='checkbox'
                                        Name={"outOfState"}
                                        Title_sx={{ color: "grey3" }}
                                        values={values.outOfState}
                                        // Max_Length={10}
                                        // onBlur={handleBlur}
                                        OnChange={handleCheckboxChange}
                                    />
                                </>
                                <Box >
                                    <Inputform
                                        label={"Project #"}
                                        Placeholder={"Project #..."}
                                        Name={"Project #"}
                                        Title_sx={{ color: "grey3" }}
                                        touched={touched?.["Project #"]}
                                        errors={errors?.["Project #"]}
                                        values={values?.["Project #"]}
                                        // Max_Length={10}
                                        onBlur={handleBlur}
                                        OnChange={(event: any) => {
                                            const newName = event.target.value;
                                            setFieldValue("Project #", newName, true);
                                            debounceRequest('projectSow.fields.Project #', newName.trim())
                                        }}
                                    />
                                </Box>

                                <Box className='mt-2' sx={{ alignItems: 'center' }}>
                                    <Text variant='SelectLabel'>Spec Documents?</Text>
                                    <Box sx={{ display: 'flex' }}>
                                        <Label>
                                            <Radio
                                                name='Spec Documents?'
                                                value='true'
                                                checked={values?.["Spec Documents?"] === true}
                                                onChange={() => {
                                                    setFieldValue('Spec Documents?', true);
                                                    debounceRequest('projectSow.fields.Spec Documents?', true);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            Yes
                                        </Label>
                                        <Label>
                                            <Radio
                                                name='Spec Documents?'
                                                value='false'
                                                checked={values?.["Spec Documents?"] === false}
                                                onChange={() => {
                                                    setFieldValue('Spec Documents?', false);
                                                    debounceRequest('projectSow.fields.Spec Documents?', false);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            No
                                        </Label>
                                    </Box>
                                </Box>
                                <Box className='mt-2' sx={{ alignItems: 'center' }}>
                                    <Text variant='SelectLabel'>T&M Quote</Text>
                                    <Box sx={{ display: 'flex' }}>
                                        <Label>
                                            <Radio
                                                name='tmquote'
                                                value='true'
                                                checked={values.tmQuote === true}
                                                onChange={() => {
                                                    setFieldValue('tmQuote', true);
                                                    debounceRequest('tmQuote', true);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            Yes
                                        </Label>
                                        <Label>
                                            <Radio
                                                name='tmquote'
                                                value='false'
                                                checked={values.tmQuote === false}
                                                onChange={() => {
                                                    setFieldValue('tmQuote', false);
                                                    debounceRequest('tmQuote', false);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            No
                                        </Label>
                                    </Box>

                                    {touched.tmQuote && errors.tmQuote && <p className='text-danger'>{errors.tmQuote}</p>}
                                </Box>
                            </Box>
                            <Box>
                                <Tabs className='p-3'>
                                    <TabList>
                                        <Tab>Details</Tab>
                                        <Tab>Stages Lists</Tab>
                                        <Tab>Tasks Lists</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <UploadMediaFile mediaData={getproject?.attachments || []} typeId={{
                                            project_id: projectId || '',
                                        }} type="project" loader={loader} id={projectId} />
                                    </TabPanel>
                                    <TabPanel>
                                        <StepTwoOnly projectId={projectId ? projectId : ""} />
                                    </TabPanel>
                                    <TabPanel>
                                        <StepThreeOnly projectId={projectId || ''} />
                                    </TabPanel>
                                </Tabs>
                            </Box>
                        </div>
                        <ProjectSideActions isTracking={null} elapsedTime={"00:00:00"} timeTaken={undefined} projectId={projectId} />
                    </Flex >
                </ModalBody >
            </Modal >
            {selectedHighlight?.name === "On Hold" && <ActionOnHold selectedHighlight={selectedHighlight} Open={Open} setOpen={setOpen} actionObject={{ projectId: projectId }} />
            }
            {selectedHighlight?.name === "Project Setup" && <ActionProjectSetup selectedHighlight={selectedHighlight} Open={Open} setOpen={setOpen} actionObject={{ projectId: projectId }} />}
            <DoubleClickModal isOpen={isDoubleClickModalOpen} toggle={toggleDoubleClickModal} highlight={highlight} />

        </>
    );
};

export default EditModal;

import { useFormik } from "formik";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import { Box, Button, Flex, Spinner } from "theme-ui";
import * as Yup from 'yup';
import { RootState } from "../../../../@types";
import { createStepTwo, getProjectById } from "../../../store/actions/project.action";
import theme from "../../../theme";
import StagesTab from "../../pages/create project /StepThree/stagesTab";
interface props {
    next: any
}
const validationSchema = Yup.object({
    stagesData: Yup.array().of(
        Yup.object().shape({
            task: Yup.array().min(1, "Select at least one task").of(
                Yup.object().shape({
                    description: Yup.string().required("description is required"),
                    manager: Yup.string().required("manager is required"),
                    worker: Yup.string().required("worker is required"),
                    taskFields: Yup.object().required("this field is required")
                })
            ),
        })
    ),
    projectSow: Yup.object().shape({
        description: Yup.string().required("description is required"),
        projectSowMapping: Yup.array(),
        fields: Yup.object().required("this field is required"),
    }),
});
const AddTaskStep: React.FC<props> = ({ next }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { projectId } = useParams<{ projectId: string }>();
    const { SowChecklist, sowfields, getproject, isStageLoader } = useSelector((state: RootState) => state.project);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const parsed = queryString.parse(location.search) as any;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getProjectById(projectId));
    }, [])
    const formik = useFormik({
        // validationSchema,
        enableReinitialize: true,
        initialValues: {
            stagesData: (getproject?.stagesData || []).map((stage) => ({
                id: stage?.id,
                name: stage?.name,
                task: (stage?.task || []).map((department) => ({
                    id: department?.id,
                    name: department?.name,
                    color: department?.color,
                    sowMapping: department?.sowMapping,
                    description: department?.description,
                    manager: department?.manager,
                    worker: department?.worker,
                    timeEstimate: department?.timeEstimate,
                    taskFields: department?.taskFields,
                    taskWorker: department?.taskWorker,
                    taskManager: department?.taskManager
                })) || '',
                isIPR: stage?.isIPR,
                dueDate: stage?.dueDate,
                promise: stage?.promise,
                worker: stage?.worker,
                manager: stage?.manager,
                color: stage?.color,
            })),
            projectSow: {
                id: getproject?.projectSow?.id || 123,
                description: getproject?.projectSow?.description,
                projectSowMapping: getproject?.projectSow?.projectSowMapping,
                fields: getproject?.projectSow?.fields,
            },
        },
        onSubmit: (values, actions) => {
            const payload = {
                stagesData: (values.stagesData || []).map((stage, stageIndex) => ({
                    id: stage?.id,
                    name: stage?.name,
                    task: (stage?.task || []).map((task) => ({
                        id: task?.id,
                        name: task?.name,
                        color: task?.color,
                        isIPR: stage?.isIPR,
                        sowMapping: task?.sowMapping,
                        description: task?.description,
                        manager: task?.manager,
                        worker: task?.worker,
                        timeEstimate: task?.timeEstimate,
                        taskFields: task?.taskFields,
                    })),
                    dueDate: stage?.dueDate,
                    isIPR: stage?.isIPR,
                    promise: stage?.promise,
                    color: stage?.color,
                    worker: stage?.worker || null,
                    manager: stage?.manager || null,
                })),
                // projectSow: {
                //     projectSowMapping: values?.projectSow?.projectSowMapping,
                //     description: values.projectSow?.description,
                //     fields: values.projectSow?.fields,
                // },
                page: "step3",
            };
            if (!isStageLoader) {
                dispatch(createStepTwo(payload, projectId, formik,
                    (res: any) => {
                        // console.log("res--", res);

                        if (res.status === 200) {
                            toast.success("Stage added Successfully");
                            navigate("/project-view")
                        }
                    }
                ));
            }

        },
    });
    const handleNextClick = () => {
        // console.log(formik.isValid, "check117");
        if (formik.isValid) {
            formik.handleSubmit();
        } else {
            toast.error("Form is not valid. Please fill in all required fields.");
        }
    };
    const { handleSubmit, setFieldValue, handleBlur, touched, values, errors } = formik;
    useEffect(() => {
        if (SowChecklist?.length > 0 && values.stagesData?.length > 0) {
            setIsChecked(true)
        }
    }, [SowChecklist, values.stagesData, values.projectSow])
    useEffect(() => {
        if (isChecked) {
            let defaultSelectedData = SowChecklist.filter((val) => {
                return val.default;
            })
            values?.stagesData?.map((val) => {
                val.task?.map((task) => {
                    defaultSelectedData?.map((value) => {
                        if (value.taskType === task.id) {
                            task.sowMapping?.push(value._id)
                        }
                    }
                    );
                })
            })
        }
    }, [isChecked])
    // useEffect(() => {
    //     if (isChecked) {
    //         let defaultSelectedData = SowChecklist.filter((val) => val.default);

    //         formik.setValues((prevValues) => ({
    //             ...prevValues,
    //             projectSow: {
    //                 ...prevValues.projectSow,
    //                 projectSowMapping: [
    //                     ...(prevValues.projectSow.projectSowMapping || [])
    //                 ],
    //             },
    //         }));

    //         let taskSelectedId = defaultSelectedData?.filter((value) => value.taskType === null);

    //         if (taskSelectedId) {
    //             let actualTaskSowMappingIds = taskSelectedId.map((val) => val._id);

    //             // console.log(actualTaskSowMappingIds, "check177");

    //             formik.setValues((prevValues) => ({
    //                 ...prevValues,
    //                 projectSow: {
    //                     ...prevValues.projectSow,
    //                     projectSowMapping: [
    //                         ...(prevValues.projectSow.projectSowMapping || []),
    //                         ...actualTaskSowMappingIds,
    //                     ],
    //                 },
    //             }));
    //         }
    //     }
    // }, [isChecked]);
    const projectSowChecklist = SowChecklist?.filter((entry) => entry?.taskType === null);
    return (
        <form onSubmit={handleSubmit}>
            <Box className="container-fluid">
                {/* <Box sx={{}}>
                    <Card mt={7} sx={{ width: '100%', border: '1px solid #C3ACD0', borderRadius: '12px' }}>
                        {SowChecklist?.map((checklistItem, index) => {
                            if (checklistItem?.taskType === null) {
                                return (
                                    <Box key={index} mt={3}>
                                        <CheckBox3
                                            title={''}
                                            Option={projectSowChecklist}
                                            onChange={(event: any, item: ItemType) => {
                                                if (event) {
                                                    values?.projectSow?.projectSowMapping?.push(item?._id)
                                                } else {
                                                    let indexToRemove = values?.projectSow?.projectSowMapping?.indexOf(item?._id);
                                                    if (indexToRemove !== -1) {
                                                        values?.projectSow?.projectSowMapping?.splice(indexToRemove, 1);
                                                        // console.log("ID removed successfully");
                                                    } else {
                                                        // console.log("ID not found in the array");
                                                    }
                                                }
                                            }}
                                        />
                                    </Box>
                                );
                            }
                        })}

                        <Text mt={3} as={"h5"}>Project SOW</Text>
                        <Textarea
                            name={`projectSow.description`}
                            rows={8}
                            value={values?.projectSow?.description}
                            sx={{ borderColor: "InactiveBorder" }}
                            onChange={(values) => {
                                setFieldValue(`projectSow.description`, values?.target?.value, true)
                            }}
                        />
                        {errors?.projectSow && touched?.projectSow ? (
                            <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: "5px", minHeight: "11px", lineHeight: '10px' }}>
                                {errors?.projectSow?.description}
                            </Text>
                        ) : (
                            null
                        )}

                        <Grid mt={2} gap={2} columns={[4]}>
                            {sowfields?.map((sowfield, index) => (
                                <React.Fragment>
                                    {sowfield?.taskName === null && (
                                        <Inputform
                                            type={sowfield?.type}
                                            label={sowfield?.fieldName}
                                            errors={errors?.projectSow?.fields}
                                            touched={touched?.projectSow?.fields}
                                            Placeholder={sowfield?.fieldName}
                                            values={values?.projectSow?.fields?.[sowfield?.fieldName]}
                                            onBlur={handleBlur}
                                            OnChange={(values: any) => {
                                                if (sowfield?.type === "checkbox") {
                                                    setFieldValue(`projectSow.fields.${sowfield.fieldName}`, values?.target?.checked, true);
                                                } else {
                                                    setFieldValue(`projectSow.fields.${sowfield.fieldName}`, values?.target?.value, true);
                                                }
                                            }}
                                            Name={`getproject?.projectSow?.fields?.${sowfield.fieldName}`}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Card>
                </Box> */}
                <StagesTab
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    values={values}
                    setFieldValue={formik?.setFieldValue}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={formik?.touched}
                    SowChecklist={SowChecklist}
                    sowfields={sowfields}
                />
                <br />
                <Flex sx={{ justifyContent: "end", paddingBottom: '30px' }} className="mt-4">
                    <Button disabled={isStageLoader} sx={{ background: theme.colors?.primary, padding: '12px 18px', width: "25%" }} type="submit"> {isStageLoader ? <Spinner size={30} sx={{ color: "#fff" }} /> : "Finish"}</Button>
                </Flex>
            </Box>
        </form>
    );
};

export default AddTaskStep;

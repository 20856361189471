import React, { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';

interface RatingProps {
    count: number;
    rating?: number;
    onChange?: (newRating: number) => void;
    size?: number;
    activeColor?: string;
    disabled?: boolean;
}

const CustomRating: React.FC<RatingProps> = ({ count, rating = 1, onChange, size = 24, activeColor = "#ffd700", disabled = false }) => {
    const [currentRating, setCurrentRating] = useState(rating);
    useEffect(() => {
        setCurrentRating(rating);
    }, [rating]);
    const handleClick = (newRating: number) => {
        if (!disabled && onChange) {
            setCurrentRating(newRating);
            onChange(newRating);
        }
    };

    return (
        <div>
            {[...Array(count)]?.map((_, index) => (
                <FaStar
                    key={index}
                    size={size}
                    color={index < currentRating ? activeColor : "#d5d5d570"}

                    onClick={disabled ? undefined : () => handleClick(index + 1)}
                    style={{ cursor: disabled ? "not-allowed" : "pointer" }}
                />
            ))}
        </div>
    );
};

export default CustomRating;

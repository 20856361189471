import { useFormik } from "formik";
import queryString from "query-string";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { Box, Button, Flex, Grid, Spinner, Text } from "theme-ui";
import * as Yup from "yup";
import { RootState } from "../../../../@types";
import { createStepTwo, getAllProjectNoPagination, getProjectByIdSuccess } from "../../../store/actions/project.action";
import theme from "../../../theme";
import { Inputform } from "../../forms-component/InputForm";
import { customStyles, customStylesForDepartments } from "../../pages/create project /StepTwo/CustomSelectStyles";
interface props {
    next: any
}
const StageStep: React.FC<props> = ({ next }) => {
    const userData = useSelector((state: RootState) => state.project);
    const { getproject, projectData, isStageLoader } = useSelector((state: RootState) => state.project);
    // console.log("projectData", projectData);

    const location = useLocation();
    const parsed = queryString.parse(location.search) as any;
    const { projectId } = useParams<{ projectId: string }>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { createprojectMasterData } = useSelector((state: RootState) => state.project);
    const allStages = userData.createprojectMasterData?.allStages?.data || [];
    const allTask = userData.createprojectMasterData?.allTask?.data || [];
    const projectManagers = userData.createprojectMasterData?.allTaskManager || [];
    const projectWorkers = userData.createprojectMasterData?.allTaskWorkers || [];
    const allStagesOptions = allStages.map((stage) => ({ value: stage._id, label: stage.name, color: stage.color, backgroundColor: "#445adc", task: [], dueDate: '', promise: '', manager: '', worker: '', isIPR: stage.isIPR }));
    const allTaskOptions = allTask.map((stage: any) => ({ value: stage._id, label: stage.name, color: stage.color, department: [] }));
    const promise = [
        { label: "Customer", value: "Customer" },
        { label: "Personal", value: "Personal" },
        { label: "Hot Promise", value: "hotPromise" },

    ];
    const allProjects = projectData?.data?.map((project: any) => ({
        value: project?._id,
        label: project?.name
    }))
    const projectManagerOptions = projectManagers.map((manager: any) => ({
        value: manager._id,
        label: `${manager?.FirstName} ${manager?.LastName}`
    }));
    const projectWorkerOptions = projectWorkers.map((worker: any) => ({
        value: worker._id,
        label: `${worker?.FirstName} ${worker?.LastName}`

    }));
    useEffect(() => {
        dispatch(getAllProjectNoPagination())
        dispatch(getProjectByIdSuccess({}));
    }, [])

    const validationSchema = Yup.object({
        stagesData: Yup.array()
            .min(1, "Select at least one stage")
            .of(
                Yup.object().shape({
                    task: Yup.array().min(1, "Select at least one task"),
                    dueDate: Yup.string().required("Due Date is required"),
                    promise: Yup.string().required("Promise is required"),
                    worker: Yup.string().required("Worker is required"),
                    manager: Yup.string().required("Manager is required"),
                })
            ),
    });

    const formik = useFormik({
        enableReinitialize: true,
        // validationSchema,
        initialValues: {
            stagesData: [].flatMap((stage: any, index: number) => {
                const correspondingStage = (createprojectMasterData?.allStages?.data as { _id: string; color: string }[]).find((stagesData) => stagesData?._id === stage?.id);
                const taskColors = stage?.task?.map((task: any) => {
                    const correspondingTask = (createprojectMasterData?.allTask?.data as { _id: string; color: string }[]).find((t) => t?._id === task?.id);
                    return correspondingTask?.color || "#000000";
                });
                return {
                    value: stage?.id || '',
                    label: stage?.name || '',
                    color: correspondingStage?.color || "#000000",
                    task: stage?.task?.map((task: any, index: number) => ({
                        value: task?.id || '',
                        label: task?.name || '',
                        color: taskColors[index] || "#000000",
                        isIPR: stage?.isIPR || false,
                    })) || [],
                    dueDate: stage?.dueDate || '',
                    isIPR: stage?.isIPR || false,
                    promise: stage?.promise || '',
                    manager: stage?.manager || null,
                    worker: stage?.worker || null
                };
            }),
        },
        onSubmit: async (values, actions) => {

            const stagesData = values?.stagesData?.map((selectedStage: any, index) => {

                const taskData = selectedStage?.task?.map((selectedTask: any, index: number) => {

                    return {
                        id: selectedTask?.value,
                        name: selectedTask?.label,
                        color: selectedTask?.color,
                        isIPR: selectedStage?.isIPR,
                    };
                });

                return {
                    id: selectedStage?.value,
                    name: selectedStage?.label,
                    isIPR: selectedStage?.isIPR,
                    task: taskData,
                    dueDate: selectedStage?.dueDate,
                    worker: selectedStage?.worker || null,
                    manager: selectedStage?.manager || null,
                    promise: selectedStage?.promise,
                    color: selectedStage?.color
                };
            });

            if (!isStageLoader) {
                dispatch(createStepTwo({ stagesData, page: 'step2' }, projectId, formik,
                    (res: any) => {
                        if (res.status === 200) {
                            toast.success("Step Two completed Successfully");
                            next();
                            // navigate("/create-project?step=3&id=" + parsed?.id)
                        }
                    }
                ));

            }
        },

    });
    const handleNextClick = () => {
        handleSubmit();

    };

    const { handleSubmit, setFieldValue, handleChange, handleBlur, touched, values, errors } = formik;
    // console.log("allProjects", allProjects);

    return (

        <Box className="container-fluid">
            <form onSubmit={handleSubmit}>
                {!projectId &&
                    <p>
                        <label className="form-label">Select Project</label>
                        {/* <Select
                            classNamePrefix="an-simple-select"
                            value={formik?.values?.stagesData}
                            options={allProjects}
                            aria-labelledby="Select"
                            onChange={(selected) => { formik?.setFieldValue("stagesData", selected) }}
                            // styles={customStylesForDepartments}
                        /> */}
                    </p>
                }
                <label className="form-label">Select Stages</label>
                <Select
                    isMulti
                    classNamePrefix="an-simple-select"
                    value={formik?.values?.stagesData}
                    options={allStagesOptions}
                    aria-labelledby="Select"
                    onChange={(selected) => {
                        console.log('selected', selected);
                        formik?.setFieldValue("stagesData", selected)
                    }}
                    styles={customStylesForDepartments}
                />
                {touched.stagesData && errors.stagesData && (
                    <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                        {!(typeof errors.stagesData === 'object') ? errors.stagesData : ''}
                    </Text>
                )}
                {/* parent boxes */}

                {values?.stagesData?.map((selectedStage, stageIndex) => {
                    return (
                        <Box key={selectedStage?.value} sx={{
                            boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06);',
                            borderRadius: '12px', p: 2, marginTop: 2
                        }}>
                            <Box key={selectedStage?.value} sx={{ boxShadow: 'initial', p: 2, borderLeft: `4px solid ${selectedStage.color}`, }}>
                                <Flex sx={{ alignItems: 'center', marginBottom: 2 }}>
                                    <Box sx={{ height: '100%', width: '4px', backgroundColor: selectedStage.color, marginRight: '8px' }} />
                                    <Text as={'h3'} sx={{ color: selectedStage.color, marginBottom: 0 }}>
                                        {selectedStage.label}
                                    </Text>
                                </Flex>
                                <label className="form-label">Select Task</label>
                                <Select
                                    isMulti
                                    classNamePrefix="an-simple-select"
                                    options={allTaskOptions}
                                    value={formik?.values?.stagesData[stageIndex].task}
                                    aria-labelledby="Select"
                                    onChange={(selected) => {
                                        const updatedStages = formik.values.stagesData.map((stage, mapIndex) =>
                                            stageIndex === mapIndex ? { ...stage, task: selected } : stage);
                                        setFieldValue('stagesData', updatedStages);
                                    }}
                                    styles={customStyles}
                                />
                                {touched.stagesData && errors.stagesData && (errors.stagesData[stageIndex] as { task?: string })?.task && (
                                    <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                                        {(errors?.stagesData?.[stageIndex] as { task?: string })?.task}
                                    </Text>
                                )}

                                <Grid sx={{ gap: '1rem', marginTop: 3 }} columns={[1, 2, 2, 3, 4, 4]}>
                                    <Box>
                                        <Inputform
                                            type="date"
                                            label="Due Date"
                                            onBlur={handleBlur}
                                            Name={`stagesData[${stageIndex}].dueDate`}
                                            OnChange={handleChange}
                                            values={selectedStage?.dueDate?.split('T')[0] || ''}
                                            Placeholder=""
                                            touched={touched?.stagesData?.[stageIndex]?.dueDate}
                                            errors={(errors?.stagesData?.[stageIndex] as { dueDate?: string })?.dueDate}
                                        />
                                    </Box>
                                    <Box>
                                        <Text variant="SelectLabel">Promise:</Text>
                                        <Select
                                            classNamePrefix="an-simple-select"
                                            options={promise}
                                            onBlur={handleBlur}
                                            value={promise.find(option => option.value === selectedStage.promise)}
                                            onChange={(selectedOption: any) => setFieldValue(`stagesData[${stageIndex}].promise`, selectedOption?.value || '')}
                                        />
                                        {touched.stagesData && errors.stagesData && (errors.stagesData[stageIndex] as { promise?: string })?.promise && (
                                            <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                                                {(errors?.stagesData?.[stageIndex] as { promise?: string })?.promise}
                                            </Text>
                                        )}
                                    </Box>
                                    <Box>
                                        <Text variant="SelectLabel">worker:</Text>
                                        <Select
                                            classNamePrefix="an-simple-select"
                                            value={projectWorkerOptions.find(option => option.value === selectedStage.worker)}
                                            options={[{ value: '', label: 'Select Worker' }, ...projectWorkerOptions]}
                                            onBlur={handleBlur}
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue(`stagesData[${stageIndex}].worker`, selectedOption?.value || '')}
                                        />
                                        {touched.stagesData && errors.stagesData && (errors.stagesData[stageIndex] as { worker?: string })?.worker && (
                                            <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                                                {(errors?.stagesData?.[stageIndex] as { worker?: string })?.worker}
                                            </Text>
                                        )}
                                    </Box>
                                    <Box>
                                        <Text variant="SelectLabel">manager:</Text>
                                        <Select
                                            classNamePrefix="an-simple-select"
                                            placeholder="Select Project Manager"
                                            onBlur={handleBlur}
                                            value={projectManagerOptions.find(option => option.value === selectedStage.manager)}
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue(`stagesData[${stageIndex}].manager`, selectedOption?.value || '')}
                                            options={[{ value: '', label: 'Select Manager' }, ...projectManagerOptions]}
                                        />
                                        {touched.stagesData && errors.stagesData && (errors.stagesData[stageIndex] as { manager?: string })?.manager && (
                                            <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                                                {(errors?.stagesData?.[stageIndex] as { manager?: string })?.manager}
                                            </Text>
                                        )}
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    )
                })}
                <Flex sx={{ justifyContent: "end", paddingBottom: '30px' }} className="mt-4">
                    <Button disabled={isStageLoader} sx={{ background: theme.colors?.primary, padding: '12px 18px', width: "25%" }} type="submit" >{isStageLoader ? <Spinner size={30} sx={{ color: "#fff" }} /> : "Next"}</Button>
                </Flex>
            </form>
        </Box>
    );
};

export default StageStep;

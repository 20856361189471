import React from 'react';
import { Navigate } from 'react-router-dom';

const Protected: React.FC<any> = ({ children }) => {
  const isAuthenticated = localStorage.getItem('token');
  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }
  return <>{children}</>;
};

export default Protected;

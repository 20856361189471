
import { FRONT_APP, FRONT_APP_SUCCESS, UPDATE_COMMENT, UPDATE_COMMENT_SUCCESS, UPDATE_DT_PM, UPDATE_DT_PM_SUCCESS, UPDATE_FOLDER_NUMBER, UPDATE_FOLDER_NUMBER_SUCCESS, UPDATE_FRONTAPP_ROW } from "../storeTypes";

const initialState: any = {
    loader: false,
    frontAppData: []
}
const FrontAppReducer = (state = initialState, action: any) => {


    switch (action.type) {
        case FRONT_APP:
            return {
                ...state,
                loader: true,
            };
        case FRONT_APP_SUCCESS:
            return {
                ...state,
                loader: false,
                frontAppData: action?.payload,
            };
        case UPDATE_DT_PM:
            return {
                ...state,
            }
        case UPDATE_DT_PM_SUCCESS:
            const updatedFrontApp = state.frontAppData.data.map((value: any) => {
                if (value.comment_id === action?.id) {
                    if ("DT" in action?.payload?.data) {
                        return { ...value, DT: action?.payload?.data?.DT }
                    } else {
                        return { ...value, PM: action?.payload?.data.PM }
                    }
                } else {
                    return value
                }
            })
            return {
                ...state,
                frontAppData: { ...state?.frontAppData, data: updatedFrontApp }
            }
        case UPDATE_COMMENT:
            return {
                ...state,
                loader: true
            }
        case UPDATE_COMMENT_SUCCESS:
            return {
                ...state,
                loader: false,
                updateComment: action?.payload
            }

        case UPDATE_FOLDER_NUMBER:
            return {
                ...state,
                loader: false
            }
        case UPDATE_FOLDER_NUMBER_SUCCESS:
            return {
                ...state,
                loader: false,
                updateFolder: action?.payload
            }
        case UPDATE_FRONTAPP_ROW:
            let updatedFrontAppData = state?.frontAppData?.data?.map((value: any) => {
                return value._id === action?.payload?.id ? action?.payload.data : value
            })
            return {
                ...state,
                frontAppData: { ...state?.frontAppData, data: updatedFrontAppData },
            };
        default:
            return state;
    }
};

export default FrontAppReducer;

import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Box, Button, Card, Flex, Label, Spinner, Switch, Text } from "theme-ui";
import { Inputform } from "../../forms-component/InputForm";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { createActionHighlight, editActionHighlight, getActionHighlighterIds, getActionHighlighterIdsSuccess } from "../../../store/actions/action.Highlighters";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../@types";
import { rolePermissionsHighLighters } from "../../../utils/CustomHandler";
import CustomTooltip from "../../CustomTooltip";
import theme from "../../../theme";

export const ActionHourADay = ({ Open, setOpen, selectedHighlight,
  actionObject }: any) => {
  const { actionHighlights, createHighlight, loader } = useSelector((state: any) => state.actionHighlight)
  const { auther } = useSelector((state: RootState) => state.auth);
  const is_Task_Worker = auther?.Role?.map(role => role?.RoleName).every(role => role.toLocaleLowerCase() === "task worker")

  const dispatch = useDispatch();
  const hoursData = actionHighlights?.payload?.Description[0] || []

  const togglePopover = () => {
    setOpen(!Open);
  };
  useEffect(() => {
    dispatch(getActionHighlighterIds(actionObject, { name: selectedHighlight?.name }))
  }, [createHighlight])

  useEffect(() => {
    return () => {
      dispatch(getActionHighlighterIdsSuccess([]))
    }
  }, [])

  const formik = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        hoursADay: hoursData?.hoursADay,
        isChecked: hoursData?.isChecked,
        name: selectedHighlight?.name,
      },
      validationSchema: Yup.object({
        hoursADay: Yup.string().required('Check list item is required'),
      }),
      onSubmit: (value) => {
        if (value?.isChecked) {
          const updateData = {
            data: { isChecked: value?.isChecked, hoursADay: value?.hoursADay, },
            ...actionObject,
            actionType: selectedHighlight?.name,
            name: selectedHighlight?.name,
          }
          dispatch(editActionHighlight(updateData, actionHighlights?._id))
        } else {
          const payload = {
            actionType: selectedHighlight?.name,
            payload: {
              Description: [value],
            },
            ...actionObject,
          }
          dispatch(createActionHighlight(payload))
        }
        setOpen(false);
        formik.resetForm()
      }

    }
  );
  const { handleSubmit, setFieldValue, values, handleBlur, touched, errors } = formik;
  return (
    <Modal isOpen={Open} centered scrollable size='md' toggle={togglePopover}>

      <ModalHeader toggle={togglePopover}> <Flex sx={{ gap: "1rem" }}><img src={selectedHighlight?.icon} /><Text sx={{ color: selectedHighlight?.color }}>{selectedHighlight?.name?.toUpperCase()}</Text></Flex></ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <Card sx={{ boxShadow: "none", border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "12px 20px", position: "relative" }}>
            {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ?
              <Inputform
                label={"Hour Per Day"}
                Placeholder={"Enter hour per day..."}
                Name={"hoursADay"}
                type='number'
                Title_sx={{ color: "grey3" }}
                touched={touched?.hoursADay}
                errors={errors?.hoursADay}
                values={values.hoursADay}
                disabled={true}
              /> : <Inputform
                label={"Hour Per Day"}
                Placeholder={"Enter hour per day..."}
                Name={"hoursADay"}
                type='number'
                Title_sx={{ color: "grey3" }}
                touched={touched?.hoursADay}
                errors={errors?.hoursADay}
                values={values.hoursADay}
                Max_Length={10}
                onBlur={handleBlur}
                OnChange={(event: any) => {
                  const newName = event.target.value;
                  setFieldValue("hoursADay", newName, true);
                }}
              />}
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 4,
              }}>
              <Label htmlFor="onHold" sx={{ flex: 1 }}>
                Enbled
              </Label>
              <Box>
                {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? <CustomTooltip id={`${selectedHighlight?.name}onholdhouraday123`} title="Permission Denied"><Switch sx={{ cursor: "not-allowed" }} name="on-hold" id="onHold" checked={values?.isChecked ? false : true} /></CustomTooltip> :
                  <Switch name="on-hold" id="onHold" checked={values?.isChecked ? false : true}
                    onClick={(e: any) => setFieldValue("isChecked", e.target.checked ? false : true)} />}
              </Box>
            </Flex>
            {loader && <Box sx={{ position: "absolute", inset: "0", width: "100%", height: "100%", backgroundColor: 'rgba(255, 255, 255, 0.5)', display: "grid", placeContent: "center" }}><Spinner /></Box>}
          </Card>
        </ModalBody>
        <ModalFooter className='gap-2 justify-content-end'>
          {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? <CustomTooltip id={`${selectedHighlight?.name}onholdhouraday325`} title="Permission Denied">
            <Button variant='SaveButton' style={{ cursor: (is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? "not-allowed" : "pointer" }} type="button" >Send</Button>
          </CustomTooltip> :
            <Button variant='SaveButton' type="submit" >Send</Button>}
        </ModalFooter>
      </form>

    </Modal>
  )
}

import { Modal, ModalBody } from "reactstrap";
import { Button, Text } from "theme-ui";

export const DeletePopModule = ({ setIsDeleteModelOpen, updatedDataFun, isDeleteModelOpen, message }: any) => {
  const confirmDelete = () => {
    updatedDataFun()
    setIsDeleteModelOpen(false);
  };

  const cancelDelete = () => {
    setIsDeleteModelOpen(false);
  };
  return (
    <Modal isOpen={isDeleteModelOpen} toggle={cancelDelete} centered className='w-100'>
      <ModalBody className='mb-0 pb-0'>
        <div style={{
          width: '100%',
          position: 'relative',
          zIndex: 1,
          backgroundColor: 'white',
          border: '1px solid #black',
          boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06);',
          borderRadius: '4px',
          padding: '20px 4px',
          textAlign: "center"
        }}>
          <Text className=" w-100" as="p" >
            <strong>{message}</strong>
          </Text>
        </div>
      </ModalBody>
      <ModalBody className='d-flex'>
        <Button onClick={confirmDelete} sx={{ marginRight: '10px', width: '50%' }}>Yes</Button>
        <Button onClick={cancelDelete} sx={{ width: '50%' }}>No</Button>
      </ModalBody>
    </Modal >
  )
}

export const estimatedReason: any[] = [
  {
    value: "PC",
    label: "PC",
  },
  {
    value: "BC",
    label: "BC",
  },
  {
    value: "Revision",
    label: "Revision",
  },
  {
    value: "Initial Estimate",
    label: "Initial Estimate",
  },
  {
    value: "Internal Review",
    label: "Internal Review",
  },
  {
    value: "BC (xrefs)",
    label: "BC (xrefs)",
  },
  {
    value: "PS (xrefs)",
    label: "PS (xrefs)",
  },
  {
    value: "External Client Meeting",
    label: "External Client Meeting",
  },
  { value: 'rfiSubmittal', label: 'RFI/Submittal' },

]
export const categoryLevel: any = [
  {
    value: "project",
    label: "Project",
  },
  {
    value: "stage",
    label: "Stage",
  },
  {
    value: "task",
    label: "Task",
  },
  {
    value: "generalTimer",
    label: "General Timer",
  },
]
export const categoryTimer: any = [
  {
    value: "Project timer",
    label: "Project timer",
  },
  {
    value: "Stage timer",
    label: "Stage timer",
  },
  {
    value: "Task timer",
    label: "Task timer",
  },
  {
    value: "General timer",
    label: "General timer",
  },
]

export const projectStatuslist: string[] = [
  'On Hold',
  'IPR',
  'Req Time Estimate',
  'In Progress',
  'IPR Completed',
  'Ready To Deliver',
  'Delivered',
]
export const taskStatuslist: string[] = [
  'On Hold', 'Draft',
  'IPR',
  'IPR Completed',
  'Internal Review',
  'Final Review',
  'Submit Internal Review',
  'Submit Final Review',
  'Todo Per Review',
  'Ready To Deliver',
  'In Progress',
  'Delivered',
  'Pause',
  'Req Time Estimate'
]
export const stageStatuslist: string[] = [
  'On Hold',
  'Draft',
  'IPR',
  'IPR In Progress',
  'Req Time Estimate',
  'Req Time Estimate In Progress',
  'Pause',
  'In Progress',
  'IPR Completed',
  'Internal Review',
  'Todo Per Review',
  'Final Review',
  'Ready To Deliver',
  'Delivered',
]
export const statusColors = {
  'On Hold': '#FF5733',
  'Draft': '#FFC300',
  'IPR': '#28A745',
  'IPR In Progress': '#6BA779',
  'IPR Pause': '#256133',
  'Req Time Estimate': '#17A2B8',
  'Req Time Estimate In Progress': '#4EAAB8',
  'Pause': '#B39DDB',
  'In Progress': '#098A07',
  'Peer Review In Progress': '#9B59B6',
  'IPR Completed': '#2ECC71',
  'Internal Review': '#3498DB',
  'Internal Review In Progress': '#1E90FF',
  'Internal Review Pause': '#1d7aa5',
  'Todo Per Review': '#E67E22',
  'Todo Per Review In Progress': '#E68734',
  'Todo Per Review Pause': '#DC7D2A',
  'Final Review': '#F39C12',
  'Final Review Pause': '#f37712',
  'Final Review In Progress': '#F4AA33',
  'Ready To Deliver': '#1ABC9C',
  'Delivered': '#D35400',
}

export const teamData = [
  {
    teamName: "Team Name",
    projectName: "Project X",
    members: [
      { src: "https://via.placeholder.com/30", alt: "Member 1" },
      { src: "https://via.placeholder.com/30", alt: "Member 2" },
      { src: "https://via.placeholder.com/30", alt: "Member 3" }
    ]
  },
  {
    teamName: "Team Name",
    projectName: "Project Y",
    members: [
      { src: "https://via.placeholder.com/30", alt: "Member A" },
      { src: "https://via.placeholder.com/30", alt: "Member B" },
      { src: "https://via.placeholder.com/30", alt: "Member C" }
    ]
  },
  {
    teamName: "Team Name",
    projectName: "Project Y",
    members: [
      { src: "https://via.placeholder.com/30", alt: "Member A" },
      { src: "https://via.placeholder.com/30", alt: "Member B" },
      { src: "https://via.placeholder.com/30", alt: "Member C" }
    ]
  },
  {
    teamName: "Team Name",
    projectName: "Project Y",
    members: [
      { src: "https://via.placeholder.com/30", alt: "Member A" },
      { src: "https://via.placeholder.com/30", alt: "Member B" },
      { src: "https://via.placeholder.com/30", alt: "Member C" }
    ]
  },
];

export const teamDetails = [
  {
    teamName: "Team Alpha",
    projectName: "Project A",
    tasksCompleted: "Project name",
    yearsAtCompany: "4.5 years",
    workPending: "12,000 Hrs",
    workCompleted: "20,000 Hrs",
    rating: 4.2,
    ratingCount: 750,
    avatars: [
      "https://via.placeholder.com/30",
      "https://via.placeholder.com/30",
      "https://via.placeholder.com/30"
    ]
  },
  {
    teamName: "Team Beta",
    projectName: "Project B",
    tasksCompleted: "Project name",
    yearsAtCompany: "4.5 years",
    workPending: "12,000 Hrs",
    workCompleted: "20,000 Hrs",
    rating: 4.2,
    ratingCount: 750,
    avatars: [
      "https://via.placeholder.com/30",
      "https://via.placeholder.com/30",
      "https://via.placeholder.com/30"
    ]
  },
];
export const members = [
  { name: "Adela Parkson", projectName: "Active project name", imageUrl: "/api/placeholder/40/40" },
  { name: "Leighton Kramer", projectName: "Active project name", imageUrl: "/api/placeholder/40/40" },
  { name: "Jaylen Blackwell", projectName: "Active project name", imageUrl: "/api/placeholder/40/40" },
];
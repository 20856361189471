/* eslint-disable no-unused-expressions */
import { toast } from 'react-hot-toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getAllFrontappSucess, updateCommentSuccess, updateDTPMSuccess, updateFolderSuccess } from '../actions/frontApp.Action';
import { loginUserFailure } from '../actions/login.action';
import { GetAllFrontAppApi, updateCommentApi, updateDTPM, updateFolderApi } from '../api';
import { FRONT_APP, UPDATE_COMMENT, UPDATE_DT_PM, UPDATE_FOLDER_NUMBER } from '../storeTypes';

// users

function* getAllFrontappSaga(action) {
    try {
        const response = yield call(GetAllFrontAppApi, action?.payload);
        if (response.data.data) {
            yield put(getAllFrontappSucess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* updateDTPMSaga(action) {
    try {
        const response = yield call(updateDTPM, action);
        if (response?.data?.data) {
            yield put(updateDTPMSuccess(response?.data, action.id))
            toast.success(`${Object.keys(action?.payload)[0]} updated!`, { position: "top-center" });
        }
    } catch (error) {
    }
}
function* updateCommentSaga(action) {

    try {
        const response = yield call(updateCommentApi, action);
        if (response?.data?.data) {
            yield put(updateCommentSuccess(response?.data))
            yield call(getAllFrontappSaga)
            toast.success("Comment updated!", { position: "bottom-center" });
        }
    } catch (error) {
    }
}
function* updateFolderSaga(action) {

    try {
        const response = yield call(updateFolderApi, action);
        if (response?.data?.data) {
            yield put(updateFolderSuccess(response?.data))
            toast.success("Folder number updated!");
        }
    } catch (error) {
    }
}
export function* FrontAppSaga() {
    [
        yield takeLatest(FRONT_APP, getAllFrontappSaga),
        yield takeLatest(UPDATE_DT_PM, updateDTPMSaga),
        yield takeLatest(UPDATE_COMMENT, updateCommentSaga),
        yield takeLatest(UPDATE_FOLDER_NUMBER, updateFolderSaga)
    ];
}

import React from "react";
import { Box, Flex, Grid, Image, Select, Text } from "theme-ui";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import Arrow from "../assets/images/Icon/select.svg";
import { useSearchParams } from "react-router-dom";
import theme from "../../theme";
interface props {
    TotalCount?: number,
    onPageChange: (page: number) => void,
    onPerPageChange: (value: string) => void,
    TotalLength?: number,
    perPageCount?: number | any,
    CurrentPage: number,
}
const TablePagination: React.FC<props> = ({
    TotalCount,
    onPageChange,
    onPerPageChange,
    TotalLength = 0,
    perPageCount = 15,
    CurrentPage = 1
}) => {
    const [searchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });

    const limit = searchParams.get('limit') || 15;
    const currentPage = searchParams.get('currentPage') || 1;

    return (
        <Flex mt={5} sx={{ justifyContent: "center", alignItems: "center" }}>
            <Grid columns={[1, 1, 1, 3]} className="text-center">

                <Text as={"span"} variant="Poppins16Normal25lh" className="pagination-summary" sx={{ border: `1px solid ${theme.colors?.muted}` }}>
                    Displaying Result {' '} {`${((CurrentPage - 1) * perPageCount) + 1}-${CurrentPage * perPageCount > TotalLength ? TotalLength : CurrentPage * perPageCount} of ${TotalLength}`}
                </Text>
                <Box className="d-flex text-center align-content-center justify-content-center">
                    <Text variant="Poppins16Normal25lh" sx={{ fontWeight: 400 }} mt={2} className="mr-1">Rows per page:</Text>
                    <Select
                        className="ms-4"
                        defaultValue={limit}
                        arrow={
                            <Image src={Arrow} alt="" sx={{ ml: -22, alignSelf: 'center', pointerEvents: 'none' }} />
                        }
                        onChange={(e) => onPerPageChange(e.target.value)}>

                        {[15, 25, 50, 100]?.map((item, index) => {
                            return (
                                <option
                                    key={index}
                                    value={item}
                                    style={{ color: `${theme.colors?.secondary}`, fontWeight: 500 }}>
                                    {item}
                                </option>
                            )
                        })}
                    </Select>
                </Box>
                <ResponsivePagination
                    nextClassName="pagination"
                    pageItemClassName="pagination"
                    current={+currentPage || CurrentPage}
                    total={Math.ceil(TotalLength / perPageCount)}
                    maxWidth={32}
                    onPageChange={onPageChange}
                />
            </Grid>
        </Flex>
    );
};

export default TablePagination;

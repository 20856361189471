import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'theme-ui';
import dropdownIcon from "../assets/images/Icon/dropdownicon.svg";
import padding from "../assets/images/Icon/padding.svg";

export const Paddingset: React.FC = ({ }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [selectedPadding, setSelectedPadding] = useState(localStorage.getItem('tPadding') || 15);
    const popoverRef = useRef<HTMLDivElement>(null);

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen); // Toggle the state
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
            setIsPopoverOpen(false);
        }
    };

    const handlePaddingChange = (paddingValue: number) => {
        setSelectedPadding(paddingValue);
        localStorage.setItem('tPadding', paddingValue.toString());
        setIsPopoverOpen(false);
        const tableCells = document.querySelectorAll('.table-cell');
        tableCells.forEach(cell => {
            (cell as HTMLElement).style.padding = `${paddingValue}px`;
        });
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div ref={popoverRef} className='d-flex g-4 position-relative ' >
                <Button variant='PrimaryHeadBtn' onClick={togglePopover}>
                    <img src={padding} /> &nbsp;&nbsp;
                    {selectedPadding}px &nbsp;
                    <img src={dropdownIcon} />
                </Button>
                {isPopoverOpen && (
                    <div className="padding-popup" >
                        <div onClick={() => handlePaddingChange(2)} className={`paddings ${selectedPadding === 2 ? 'active-padding' : ''}`}>
                            <p className='paddingfont'>2px</p>
                        </div>
                        <div onClick={() => handlePaddingChange(4)} className={`paddings ${selectedPadding === 4 ? 'active-padding' : ''}`}>
                            <p className='paddingfont'>4px</p>
                        </div>
                        <div onClick={() => handlePaddingChange(8)} className={`paddings ${selectedPadding === 8 ? 'active-padding' : ''}`}>
                            <p className='paddingfont'>8px</p>
                        </div>
                        <div onClick={() => handlePaddingChange(12)} className={`paddings ${selectedPadding === 12 ? 'active-padding' : ''}`}>
                            <p className='paddingfont'>12px</p>
                        </div>
                        <div onClick={() => handlePaddingChange(16)} className={`paddings ${selectedPadding === 16 ? 'active-padding' : ''}`}>
                            <p className='paddingfont'>16px</p>
                        </div>
                    </div>
                )}
            </div>

        </>
    );
};

import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Paragraph, Text } from 'theme-ui';

import DataTable from '../../Tabels/Tabel';
import moment from 'moment';
import { msToTime } from '../../../utils/GenerateQueryString';


export const TimeEstimateHistory: React.FC<any> = () => {
    const { tasksView, loader } = useSelector((state: any) => state.project);

    const { paginations } = useSelector((state: any) => state.pagination);

    const columns = useMemo<ColumnDef<any>[]>(() => [

        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((paginations?.currentPage - 1) * paginations?.limit) + row.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "timeEstimate",
            header: 'Time Estimate',
            cell: ({ row }) => {
                const estimatedTime = row.original.timeEstimate;
                const estimatedMs = estimatedTime ? +estimatedTime * 60 * 60 * 1000 : 0;
                const formattedEstimatedTime = estimatedMs ? `${msToTime(estimatedMs).hours}:${msToTime(estimatedMs).minutes}` : "00:00";

                return (
                    <Text variant='Poppins14Normal24lh'>
                        {formattedEstimatedTime}
                    </Text>
                );
            },

        },
        {
            accessorKey: "description",
            header: 'Reason',
            cell: ({ row }) => {
                return (
                    <Paragraph variant='Poppins14Normal24lh'>
                        {row?.original?.description}
                    </Paragraph>
                )
            },

        },
        {
            header: 'Reason Type',
            accessorKey: "reasonType",
            cell: ({ row }) => {
                return (
                    <Paragraph variant='Poppins14Normal24lh'>
                        {row?.original?.reasonType}
                    </Paragraph>
                )
            },


        },
        {
            header: 'Update By',
            accessorKey: "updateBy",
            cell: ({ row }) => {
                return (
                    <Paragraph variant='Poppins14Normal24lh'>
                        {row?.original?.updateBy}
                    </Paragraph>
                )
            },
        },
        {
            header: 'Created At',
            accessor: "createdAt",
            cell: ({ row }) => {

                const createdAt = row?.original?.createdAt;
                return (
                    <Text variant='Poppins14Normal24lh'>
                        {createdAt ? moment(createdAt).format('MMM DD, LT') : ''}
                    </Text>
                );
            },

        }
    ], [tasksView]);
    return (
        <>
            <DataTable
                loader={loader}
                columns={columns}
                data={tasksView?.estimatedTimeHistory || []}
                TotalLength={0}
                perPageCount={0}
                showNavigation={false}
                currentPage={paginations?.currentPage}
                fixedColumn={true}
                withoutURLFilter={true}
                onPerPageChange={() => { }}
                onPageChange={() => { }}

            />
        </>
    )
}

import { useSelector } from "react-redux";
import { Avatar, Box, Checkbox, Flex, Label, Paragraph, Text, Textarea } from "theme-ui";
import { RootState } from "../../../../../@types";
import deleteIcon from "../../../../Components/assets/images/Icon/delete.svg";
import editIcon from "../../../../Components/assets/images/edit.svg";
import { useDispatch } from "react-redux";
import { deleteChatsMessage, editChatsMessage } from "../../../../store/actions/chatAction";
import { bucketUrl } from "../../../../utils/global";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { DeletePopModule } from "../../../DeletePopModule/DeletePopModule";
import theme from "../../../../theme";

export const ChatCard = ({ chat, index }: any) => {
  const { auther } = useSelector((state: RootState) => state.auth);
  const [updateChat, setUpdateChat] = useState<string>();
  const [updateRow, setUpdateRow] = useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<boolean>(true);
  const dispatch = useDispatch()


  const [rows, setRows] = useState(0);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const calculateRows = () => {
    if (textAreaRef.current) {
      const rowHeight = parseInt(getComputedStyle(textAreaRef.current).lineHeight, 10);
      const scrollHeight = textAreaRef.current.scrollHeight;
      setRows(Math.max(1, Math.round(scrollHeight / rowHeight)));
    }
  };

  useEffect(() => {

    calculateRows();

    window.addEventListener('resize', calculateRows);

    return () => window.removeEventListener('resize', calculateRows);
  }, [isEditing]);

  const handleFocus = () => {
    const textarea = textAreaRef.current;
    if (textarea) {
      const textLength = textarea.value.length;
      textarea.setSelectionRange(textLength, textLength);
    }
  };

  // console.log(updateChat?.length, "---------------------------------------", chat?.message.length,
  //   "--------------------------------", rows);
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<any>()

  const deleteHandler = () => {
    dispatch(deleteChatsMessage(currentId))
  }
  const Name = `${chat?.createdBy?.FirstName} ${chat?.createdBy?.LastName}`;

  return (
    <>
      <Flex sx={{ width: "85%", flexDirection: 'column', bg: `${theme?.colors?.lightbackground}`, gap: '1px', padding: '12px', borderRadius: '12px', ml: `${auther?._id === chat?.createdBy?._id && "auto"}` }}>
        <Flex sx={{ alignItems: 'center' }}>
          <Avatar src={`${bucketUrl}${chat?.createdBy?.ProfileImage}`} variant='chatAvtar' />
          <Box ml={2}>
            <Text variant='SenderName'>{Name}</Text>
          </Box>
        </Flex>
        <Flex
          sx={{
            color: `${theme.colors?.secondary}`,
            marginTop: '8px',
            borderLeft: "2px solid #16A34A",
            paddingLeft: "5px",
            paddingRight: "8px",
            alignItems: "center",
          }}>
          {chat?.acknowledgementRequired && <Label sx={{ width: "auto" }}>
            <Checkbox
              name="isAcknowledged"
              checked={chat?.isAcknowledged ? true : false}
              value={chat?.isAcknowledged || false}
              onChange={(e) => dispatch(editChatsMessage({ isAcknowledged: e.target.checked }, chat?._id))} />
          </Label>}
          <Box sx={{ width: "90%" }}>
            {/* <Input
              variant="PersonalDetailsInput"
              className="form-control"
              id={`chat${index}`}
              sx={{
                '&:disabled ': {
                  backgroundColor: "transparent !important",
                }, backgroundColor: "transparent !important", border: "none !important",
              }}
              placeholder={chat?.message}
              value={updateChat}
              disabled={isEditing}
              onChange={(e) => setUpdateChat(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  dispatch(editChatsMessage({ message: updateChat }, chat?._id))
                  setIsEditing(true);
                }
              }}
            /> */}
            {isEditing && <p style={{ overflow: 'hidden', resize: 'none', marginBottom: 0, wordWrap: "break-word" }}>{updateChat ? updateChat : chat?.message?.trim()}</p>}
            <Textarea
              variant="PersonalDetailsInput"
              ref={textAreaRef}
              // className="form-control"
              style={{ overflow: 'hidden', resize: 'none', display: (isEditing ? "none" : "block"), }}
              rows={rows <= 3 ? 1 : rows}
              id={`chat${index}`}
              sx={{
                '&:disabled ': {
                  backgroundColor: "transparent !important",
                }, backgroundColor: "transparent !important", border: "none !important",
              }}

              defaultValue={chat?.message?.trim()}
              value={updateChat}
              disabled={isEditing}
              onChange={(e) => setUpdateChat(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setUpdateRow(!updateRow)
                  calculateRows();
                  window.addEventListener('resize', calculateRows);
                }
              }}

              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  dispatch(editChatsMessage({ message: updateChat?.trim() }, chat?._id))
                  setIsEditing(true);
                }
              }}
              onFocus={handleFocus}
            />
          </Box>
          <Flex sx={{ marginLeft: "5px", gap: "1rem" }}>
            <Label htmlFor={`chat${index}`} sx={{ cursor: "pointer" }} onClick={() => setIsEditing(false)}><img src={editIcon} /></Label>
            <Text sx={{ cursor: "pointer" }} onClick={() => {
              setIsDeleteModelOpen(true)
              setCurrentId(chat?._id)
            }}>
              <img src={deleteIcon} width={"23px"} />
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Paragraph sx={{ mb: 3, textAlign: "end", color: "#D3D5D9", fontSize: "12px" }}>{moment(chat?.createdAt).calendar(null, {
        sameDay: '[Today], h:mma',
        nextDay: 'dddd, h:mma',
        nextWeek: 'dddd, h:mma',
        lastDay: 'dddd, h:mma',
        lastWeek: 'dddd, h:mma',
        sameElse: 'dddd, h:mma'
      })}</Paragraph>
      <DeletePopModule setIsDeleteModelOpen={setIsDeleteModelOpen} updatedDataFun={deleteHandler} isDeleteModelOpen={isDeleteModelOpen} message={`Are you sure you want to delete.`} />
    </>
  )
}

import { useFormik } from "formik";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box, Button, Flex, Text } from "theme-ui";
import * as Yup from 'yup';
import { Inputform } from "../../../forms-component/InputForm";
import FormSelect from "../../../forms-component/globalSelect";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getAllUserWithoutPagination } from "../../../../store/actions/user.action";
import { getStageByProjectId, StartTracking } from "../../../../store/actions/project.action";
import { RootState } from "../../../../../@types";
import { categoryLevel, estimatedReason } from "../../../../utils/constent";

export interface StartModalProps {
    isOpen: boolean;
    toggle: () => void;
    autherId?: string;
    projectId?: string;
    stageId?: string;
}
export interface startTimerFormValues {
    id?: string;
    title: string;
    projectId?: string | null;
    stageId?: string | null;
    taskId?: string | null;
    members: string[];
    subCategory: string;
    description?: string;
    startTime?: number;
    // reportedBy?: string;
    timeTaken?: number;
    category?: string;
}
export interface Option {
    label: string;
    value: string;
}

const GeneralTimerStart: React.FC<StartModalProps> = ({ isOpen, toggle, stageId, projectId, autherId }) => {
    const { dataForTable } = useSelector((state: any) => state.masterData);

    const projectOptions: Option[] = dataForTable?.data?.allProjects?.map((obj: any) => ({
        label: obj?.name,
        value: obj?._id,
    }));

    const dispatch = useDispatch();
    const userData = useSelector((state: any) => state?.user);
    const Users = userData?.userData?.data;
    const { auther } = useSelector((state: RootState) => state.auth);

    // const { projectId, stageId } = useParams();
    useEffect(() => {
        dispatch(getAllUserWithoutPagination())
    }, [dispatch]);

    const membersOptions: Option[] = Users?.map((user: any) => ({
        label: `${user?.FirstName} ${user?.LastName}` || 'unknown',
        value: user?._id,
    }));

    const SubCategoryOptionsWithProjectOrStage: Option[] = [
        { label: 'Phone Call', value: 'phoneCall' },
        { label: 'Scheduling', value: 'scheduling' },
        { label: 'Internal Team Meeting', value: 'internalTeamMeeting' },
        { label: 'Training', value: 'training' },
        { label: 'Email Processing', value: 'emailProcessing' },
        { label: 'Email Processing/Scheduling', value: 'emailProcessingScheduling' },
        { label: 'MEP Paper SetUp', value: 'MEPPaperSetUp' },
        { label: 'Project Scheduling', value: 'projectScheduling' },
        { label: 'Internal Meeting', value: 'internalMeeting' },
        { label: 'BC & PS', value: 'bcPs' },
        { label: 'Technical Discussion', value: 'technicalDiscussion' },
        { label: 'Project Meeting Preparation', value: 'projectMeetingPreparation' },
        { label: 'BC (xrefs)', value: 'BC(xrefs)' },
        { label: 'PS (xrefs)', value: 'PS(xrefs)' },
        { label: 'Publishing', value: 'Publishing' },
        { label: 'Quality Control', value: 'qualityControl' },
        { label: 'Meeting Preparation', value: 'meetingPreparation' },
        { label: 'ProCore Management ', value: 'proCoreManagement' },
        { label: 'Project Review', value: 'projectReview' },
        { label: 'External Client Meeting', value: 'externalClientMeeting' },
        { label: 'RFI/Submittal', value: 'rfiSubmittal' }
    ];
    const SubCategoryOptionsWithoutProjectOrStage: Option[] = [
        { label: 'Phone Call', value: 'phoneCall' },
        { label: 'Scheduling', value: 'scheduling' },
        { label: 'Internal Team Meeting', value: 'internalTeamMeeting' },
        { label: 'Training', value: 'training' },
        { label: 'Publishing', value: 'Publishing' },
        { label: 'Quality Control', value: 'qualityControl' },
        { label: 'Meeting Preparation', value: 'meetingPreparation' },
        { label: 'ProCore Management ', value: 'proCoreManagement' },
        { label: 'Project Review', value: 'projectReview' },
        { label: 'Lunch Break', value: 'lunchBreak' },
        { label: 'TODO List', value: 'toDoList' },
        { label: 'MEP Paper SetUp', value: 'MEPPaperSetUp' },
        { label: 'Meeting recording view', value: 'meetingRecordingView' },
        { label: 'G-chat space threads view', value: 'gchatSpaceThreads' },
        { label: 'Daily Work-done Commit review', value: 'dailyCommitReview' },
        { label: 'Hours Report Review', value: 'hoursReportReview' },
        { label: 'Performance Review', value: 'performanceReview' },
        { label: 'BC (xrefs)', value: 'BC(xrefs)' },
        { label: 'PS (xrefs)', value: 'PS(xrefs)' },
        { label: 'Project Scheduling', value: 'projectScheduling' },
        { label: 'General PM Tasks', value: 'generalPMTasks' },
        { label: 'Internal Meeting', value: 'internalMeeting' },
        { label: 'External Client Meeting', value: 'externalClientMeeting' },
        { label: 'Email Processing/Scheduling', value: 'emailProcessingScheduling' },
        { label: 'Email Processing', value: 'emailProcessing' },
        { label: 'RFI/Submittal', value: 'rfiSubmittal' },
        { label: 'Sales', value: 'sales' },
        { label: 'Non Design', value: 'nonDesign' },
    ];

    const [projectStages, setProjectStages] = useState<Option[]>([]);
    const { stagebyproject, stagesView } = useSelector((state: any) => state.project);
    // console.log('chk74', stagesView);
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    // const [allMembersSelected, setAllMembersSelected] = useState(false);
    const [selectedCategoryLevel, setSelectedCategoryLevel] = useState<string | null>(null);

    const toggleConfirmationModal = () => setConfirmationModalOpen(!isConfirmationModalOpen);

    useEffect(() => {
        if (isOpen && projectId) {
            dispatch(getStageByProjectId(projectId));
        }
    }, [dispatch, isOpen, projectId]);

    useEffect(() => {
        if (stagebyproject && Array.isArray(stagebyproject)) {
            const stageOptions: Option[] = stagebyproject.map((stage: any) => ({
                label: stage.name,
                value: stage._id,
            }));
            setProjectStages(stageOptions);
        }
    }, [stagebyproject]);

    useEffect(() => {
        if (isOpen) {
            if (stagesView?.projectId?._id) {
                formik.setFieldValue("projectId", stagesView.projectId._id, true);
            }
            if (stageId) {
                formik.setFieldValue("stageId", stageId, true);
            }

        }
    }, [isOpen, stagesView, stageId]);

    const formik = useFormik<startTimerFormValues>({
        initialValues: {
            title: '',
            projectId: projectId || null,
            stageId: stageId || null,
            taskId: null,
            members: [],
            subCategory: '',

            // reportedBy: autherId || '',
        },
        validationSchema: Yup.object({
            title: Yup.string().required('Title is Required'),
            // project: Yup.string().required('Required'),
            // stage: Yup.string().required('Required'),
            // task: Yup.string().required('Required'),
            members: Yup.array().of(Yup.string()).required('Member is required').min(1, 'At least one member is required'),
            subCategory: Yup.string().required('Category is required'),
        }),
        onSubmit: (values) => {
            if (values.members.length === membersOptions.length) {
                toggleConfirmationModal();
            } else {
                handleStartTracking(values);
            }
        },
    })
    const handleStartTracking = (values: startTimerFormValues) => {
        try {
            let category;
            if (values.projectId && values.stageId) {
                category = 'Stage timer';
            } else if (values.projectId) {
                category = 'Project timer';
            } else {
                category = 'General timer';
            }
            const payload = { ...values, category };
            dispatch(StartTracking(payload));
            formik.resetForm();
            toggle();
            // toggleConfirmationModal();
        } catch (error) {
            console.error('Error starting tracking:', error);
        }
    };
    const { handleSubmit, setFieldValue, values, handleBlur, touched, errors } = formik;
    const handleProjectChange = (selectedProject: any) => {
        setFieldValue("projectId", selectedProject?.value || null, true);
        setFieldValue("stageId", null, true);
        if (selectedProject?.value) {
            dispatch(getStageByProjectId(selectedProject?.value));
        } else {
            setProjectStages([]);
        }
    };
    const filterOptions = (options: Option[], restrictedOptions: string[], allowedRoles: string[]) => {
        return options.filter(option => {
            const isRestricted = restrictedOptions.includes(option.label);
            const hasAllowedRole = auther?.Role?.some((role: any) =>
                allowedRoles.includes(role.RoleName)
            );
            if (isRestricted) {
                return hasAllowedRole;
            }
            return true;
        });
    };
    const getSubCategoryOptions = () => {
        const allowedRoles = ["Project Manager", "Project Worker", "Super Admin", "Region Admin"];
        const restrictedOptions = [
            "BC (xrefs)",
            "PS (xrefs)",
            "External Client Meeting"
        ];

        switch (selectedCategoryLevel) {
            case "project":
            case "stage":
                return filterOptions(SubCategoryOptionsWithProjectOrStage, restrictedOptions, allowedRoles);
            case "generalTimer":
                return filterOptions(SubCategoryOptionsWithoutProjectOrStage, [
                    "Email Processing",
                    "Project Scheduling",
                    "General PM Tasks",
                    "Internal Meeting",
                    "Email Processing/Scheduling"
                ], allowedRoles);
            case "task":
                return estimatedReason;
            default:
                return [];
        }
    };

    const currentSubCategoryOptions = getSubCategoryOptions();

    return (
        <>
            <Modal isOpen={isOpen} centered size='lg' toggle={toggle}>
                <ModalHeader toggle={toggle}>Start Timer</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit}>
                        <Flex>
                            <Box className='col-6 ps-2'>
                                <Inputform
                                    label={"Title"}
                                    Placeholder={"Enter Title"}
                                    Name={"title"}
                                    type='text'
                                    Title_sx={{ color: "grey3" }}
                                    touched={touched?.title}
                                    asterisk={true}
                                    errors={errors?.title}
                                    values={values?.title}
                                    onBlur={handleBlur}
                                    OnChange={(event: any) => {
                                        const newName = event.target.value;
                                        setFieldValue("title", newName, true);
                                    }}
                                />
                            </Box>
                            <Box className='col-6 ps-2'>
                                <FormSelect
                                    label={"Project"}
                                    placeholder='Select Project'
                                    DefultSelect='Select Project'
                                    value={values?.projectId || null}
                                    options={projectOptions || []}
                                    isMulti={false}
                                    // disabled={true}
                                    onBlur={handleBlur}
                                    Name='projectId'
                                    styles={{ color: "#000" }}
                                    onChange={handleProjectChange}
                                />
                            </Box>
                        </Flex>
                        <Flex>
                            <Box className='col-6 ps-2'>
                                <FormSelect
                                    label={"Stage"}
                                    placeholder='Select Stage'
                                    value={values?.stageId || null}
                                    isMulti={false}
                                    options={projectStages || []}
                                    // disabled={true}
                                    onBlur={handleBlur}
                                    Name='stageId'
                                    styles={{ color: "#000" }}
                                    onChange={(selectedStage: any) => {
                                        setFieldValue("stageId", selectedStage?.value || null, true);
                                    }}
                                />
                            </Box>
                            <Box className='col-6 ps-2'>
                                <Text variant="SelectLabel">Select Members<span className="mx-1 text-danger">*</span></Text>
                                <MultiSelect
                                    options={membersOptions || []}
                                    value={values?.members?.map((value: any) => membersOptions?.find((option) => option?.value === value) as Option)}
                                    onChange={(selectedMembers: any) => {
                                        const members = selectedMembers?.map((item: any) => item.value);
                                        setFieldValue("members", members, true);
                                    }} labelledBy="Select"
                                />
                                {errors?.members && touched?.members && (
                                    <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                                        {errors?.members}
                                    </Text>
                                )}
                            </Box>
                        </Flex>
                        <Flex>
                            <Box className='col-6 ps-2'>
                                <FormSelect
                                    label={"Level"}
                                    placeholder='Select Level'
                                    value={selectedCategoryLevel}
                                    asterisk={true}
                                    options={categoryLevel || []}
                                    onBlur={handleBlur}

                                    Name='level'
                                    styles={{ color: "#000" }}
                                    onChange={(selected: any) => setSelectedCategoryLevel(selected?.value || null)}

                                />
                            </Box>
                            <Box className='col-6 ps-2'>
                                <FormSelect
                                    label={"Category"}
                                    placeholder='Select Category'
                                    value={values?.subCategory || null}
                                    asterisk={true}
                                    options={currentSubCategoryOptions || []}
                                    // disabled={true}
                                    onBlur={handleBlur}
                                    touched={touched?.subCategory}
                                    errors={errors?.subCategory}
                                    Name='subCategory'
                                    styles={{ color: "#000" }}
                                    onChange={(selectedsubCategory: any) => {
                                        setFieldValue("subCategory", selectedsubCategory?.value || '', true);
                                    }}
                                />
                            </Box>
                        </Flex>
                        <Flex className="justify-content-end">
                            <Button type="submit">
                                Start
                            </Button>
                        </Flex>
                    </form>
                </ModalBody>
            </Modal>
            <Modal isOpen={isConfirmationModalOpen} toggle={toggleConfirmationModal} centered>
                <ModalHeader toggle={toggleConfirmationModal}>Confirmation</ModalHeader>
                <ModalBody>
                    <Text>Are you sure you want to start tracking for all members?</Text>
                    <Flex className="justify-content-end">
                        <Button sx={{ mr: 2, cursor: 'pointer' }} onClick={() => {
                            handleStartTracking(values);
                            toggleConfirmationModal();
                        }}>Yes</Button>
                        <Button onClick={toggleConfirmationModal}>No</Button>
                    </Flex>
                </ModalBody>
            </Modal>
        </>
    )
}

export default GeneralTimerStart
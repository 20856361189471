import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'theme-ui';
import { RootState } from '../../../../../@types';
import { createRating, getCriteriabyTaskId } from '../../../../store/actions/Rating.Action';
import RatingReviewModal from './RatingReviewModal';

interface ReviewProps {
    data: any;
    // type: 'worker' | 'task';
    type?: 'worker' | 'task' | 'taskWorker' | 'taskManager' | 'projectManager' | 'projectWorker';
    onClose?: () => void;
    reviewid?: any;
}
const ReviewModal: React.FC<ReviewProps> = ({ data, type, reviewid }) => {
    const dispatch = useDispatch();
    const [open, setIsOpen] = useState(false);
    const { rating: RatingCriteria } = useSelector((state: any) => state.rating);
    const [lastUpdatedTwoWeeksAgo, setlastUpdatedTwoWeeksAgo] = useState(false);
    const [rating, setRating] = useState<number[]>([1, 1, 1, 1, 1]);
    const [comment, setComment] = useState<string[]>([]);
    const [ratingId, setRatingId] = useState('');
    const commentRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
    const { auther } = useSelector((state: RootState) => state.auth);

    const [showHistory, setShowHistory] = useState<boolean>(false);

    const handleRatingChange = (index: number, newRating: number) => {
        const updatedRatings = [...rating];
        updatedRatings[index] = newRating;
        setRating(updatedRatings);
    };

    const handleCommentChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedComments = [...comment];
        updatedComments[index] = event.target.value;
        setComment(updatedComments);
        const textarea = commentRefs.current[index];
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };
    const handleOpen = () => {
        setIsOpen(true);
        dispatch(getCriteriabyTaskId(data?.taskId?._id || 'null'));
    };

    useEffect(() => {
        const todayDate = new Date();
        let days = moment(todayDate).diff(moment(RatingCriteria?.updatedAt), "days", false);

        if (days <= 14) {
            setlastUpdatedTwoWeeksAgo(true)
        } else {
            setlastUpdatedTwoWeeksAgo(false)
        }

    }, [RatingCriteria?.updatedAt]);

    const toggle = () => setIsOpen(!open);

    const handleSave = () => {

        const criteria = RatingCriteria?.ratingCriteria?.map((ratingText: any, index: number) => ({
            id: ratingText?._id,
            name: ratingText?.ratingCriteria,
            rating: rating[index] || 0,
            comment: comment[index] || '',
        }));

        const payload = {
            workerName:
                type === 'worker'
                    ? data?.projectWorker?.FirstName
                    : type === 'taskWorker'
                        ? data?.worker?.FirstName
                        : type === 'taskManager'
                            ? data?.manager?.FirstName
                            : data?.worker?.FirstName,
            workerNameRef:
                type === 'worker'
                    ? data?.projectWorker?._id
                    : type === 'taskWorker'
                        ? data?.worker?._id
                        : type === 'taskManager'
                            ? data?.manager?._id
                            : data?.worker?._id,
            ratingGivenBy: auther?.FirstName,
            ratingGivenByRef: auther?._id,
            projectName: data?.projectId?.name || data?.name,
            projectNameRef: data?.projectId?._id || data?._id,
            tradeName: data?.name,
            tradeNameRef: data?.taskId?._id,
            projectPhase: {
                value: data?.stageId?._id,
                label: data?.stageId?.name,
            },
            criteria,
        };

        dispatch(createRating(payload));
        setComment([]);
        setRating([1, 1, 1, 1, 1]);
        setIsOpen(false);
    };



    return (
        <>
            {type === 'worker' && (
                <Text>
                    {data?.projectWorker?.FirstName} {data?.projectWorker?.LastName}
                    {auther?.CanManageTo?.length > 0 && auther?.CanManageTo?.map((worker: any, index: any) => (
                        worker?._id === data?.projectWorker?._id ? <FaStar key={index} style={{ cursor: 'pointer' }} color="#FFB721" onClick={handleOpen} /> : null
                    ))}
                </Text>
            )}
            {type === 'task' && (
                <Text>
                    {data?.worker?.FirstName} {data?.worker?.LastName}&nbsp;&nbsp;
                    {auther?.CanManageTo?.length > 0 && auther?.CanManageTo?.map((worker: any, index: any) => (
                        worker?._id === data?.worker?._id ? <FaStar key={index} style={{ cursor: 'pointer' }} color="#FFB721" onClick={handleOpen} /> : null
                    ))}
                    {/* <FaStar color="#FFB721" style={{ cursor: 'pointer' }} onClick={handleOpen} /> */}
                </Text>
            )}
            {type === 'taskManager' && (
                <Text>
                    {data?.manager?.FirstName} {data?.manager?.LastName}&nbsp;
                    {auther?.CanManageTo?.length > 0 && auther?.CanManageTo?.map((manager: any, index: any) => (
                        manager?._id === data?.manager?._id ? <FaStar key={index} style={{ cursor: 'pointer' }} color="#FFB721" onClick={handleOpen} /> : null
                    ))}
                    {/* <FaStar color="#FFB721" style={{ cursor: 'pointer' }} onClick={handleOpen} /> */}
                </Text>
            )}

            {type === 'taskWorker' && (
                <Text>
                    {data?.worker?.FirstName} {data?.worker?.LastName}&nbsp;
                    {auther?.CanManageTo?.length > 0 && auther?.CanManageTo?.map((worker: any, index: any) => (
                        worker?._id === data?.worker?._id ? <FaStar key={index} style={{ cursor: 'pointer' }} color="#FFB721" onClick={handleOpen} /> : null
                    ))}
                    {/* <FaStar color="#FFB721" style={{ cursor: 'pointer' }} onClick={handleOpen} /> */}
                </Text>
            )}
            <RatingReviewModal
                open={open}
                toggle={toggle}
                handleSave={handleSave}
                RatingCriteria={RatingCriteria || []}
                comment={comment}
                rating={rating}
                handleRatingChange={handleRatingChange}
                handlecommentChange={handleCommentChange}
                data={data}
                type={type}
                lastUpdatedTwoWeeksAgo={false}

            />

        </>
    );
};

export default ReviewModal;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllSubtask } from "../../store/actions/project.action";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Card, Divider, Flex, Link, Text } from "theme-ui";
import theme from "../../theme";
import moment from "moment";

const BcPcRevHistory = ({ Open, setOpen, actionObject }: any) => {
    const { AllSubtask } = useSelector((state: any) => state.project);

    const dispatch = useDispatch();
    const toggleHistoryPopover = () => {
        setOpen(!Open);
    };
    useEffect(() => {
        dispatch(getAllSubtask(actionObject))
    }, [actionObject?.stageId, actionObject?.stageDataId, actionObject?.projectId, actionObject?.taskId, dispatch]);
    const projectName = AllSubtask?.BCs?.[0]?.projectId?.name || AllSubtask?.PCs?.[0]?.projectId?.name || AllSubtask?.Revs?.[0]?.projectId?.name || AllSubtask?.allNewNeedInfoAdded?.[0]?.projectId?.name;


    return (
        <Modal isOpen={Open} centered scrollable size='xl' toggle={toggleHistoryPopover}>
            <ModalHeader toggle={toggleHistoryPopover}>{projectName}</ModalHeader>
            <ModalBody >
                <Card sx={{ boxShadow: "none", border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "12px 20px", position: "relative" }}>

                    {/* <Divider /> */}
                    {AllSubtask?.BCs?.length > 0 && (
                        <>
                            <Flex>
                                <Text variant="SelectLabel" sx={{ color: "#7AC555" }}>Background Change</Text>
                            </Flex>
                            {AllSubtask?.BCs?.map((bc: any, index: number) => (
                                <Card
                                    key={index}
                                    sx={{
                                        boxShadow: "1px",
                                        border: `1px solid ${theme?.colors?.lightbackground}`,
                                        borderRadius: "12px",
                                        padding: "12px 20px",
                                        marginBottom: "20px",
                                        position: "relative",
                                    }}
                                >
                                    {bc?.payload?.name && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Name:</Text> {`${bc?.payload?.name}. (${bc?.payload?.number})`}
                                        </Text>
                                    )}
                                    {bc?.payload?.description && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Description:</Text> {bc?.payload?.description}
                                        </Text>
                                    )}
                                    {bc?.payload?.emailLink && bc?.payload?.emailLink.length > 0 && (
                                        <Text as="div" variant="SelectLabel">
                                            Email Link:
                                            <Text variant="Poppins14Normal" className="ms-2">
                                                {bc?.payload?.emailLink.map((email: string, emailIndex: number) => (
                                                    <React.Fragment key={emailIndex}>
                                                        <Link
                                                            target="_blank"
                                                            href={email}
                                                            sx={{ textDecoration: "none", cursor: "pointer" }}
                                                        >
                                                            {email}
                                                        </Link>
                                                        {emailIndex < bc?.payload?.emailLink?.length - 1 && ", "}
                                                    </React.Fragment>
                                                ))}
                                            </Text>
                                        </Text>
                                    )}
                                    {bc?.createdAt && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Created Date:</Text> {moment(bc?.createdAt).format("YYYY-MM-DD")}
                                        </Text>
                                    )}
                                </Card>
                            ))}
                            <Divider />

                        </>
                    )}
                    {AllSubtask?.PCs?.length > 0 && (
                        <>
                            <Flex>
                                <Text variant="SelectLabel" sx={{ color: "#03AECC" }}>Plan Check</Text>
                            </Flex>
                            {AllSubtask?.PCs?.map((pc: any, index: number) => (
                                <Card
                                    key={index}
                                    sx={{
                                        boxShadow: "1px",
                                        border: `1px solid ${theme?.colors?.lightbackground}`,
                                        borderRadius: "12px",
                                        padding: "12px 20px",
                                        marginBottom: "20px",
                                        position: "relative",
                                    }}
                                >
                                    {pc?.payload?.name && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Name:</Text> {`${pc?.payload?.name}. (${pc?.payload?.number})`}
                                        </Text>
                                    )}
                                    {pc?.payload?.description && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Description:</Text> {pc?.payload?.description}
                                        </Text>
                                    )}
                                    {pc?.payload?.emailLink && pc?.payload?.emailLink.length > 0 && (
                                        <Text as="div" variant="SelectLabel">
                                            Email Link:
                                            <Text variant="Poppins14Normal" className="ms-2">
                                                {pc?.payload?.emailLink.map((email: string, emailIndex: number) => (
                                                    <React.Fragment key={emailIndex}>
                                                        <Link
                                                            target="_blank"
                                                            href={email}
                                                            sx={{ textDecoration: "none", cursor: "pointer" }}
                                                        >
                                                            {email}
                                                        </Link>
                                                        {emailIndex < pc?.payload?.emailLink.length - 1 && ", "}
                                                    </React.Fragment>
                                                ))}
                                            </Text>
                                        </Text>
                                    )}
                                    {pc?.createdAt && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Created Date:</Text> {moment(pc?.createdAt).format("YYYY-MM-DD")}
                                        </Text>
                                    )}
                                </Card>
                            ))}
                            <Divider />
                        </>
                    )}
                    {AllSubtask?.Revs?.length > 0 && (
                        <>
                            <Flex>
                                <Text variant="SelectLabel" sx={{ color: "#FF8FD4" }}>Revision</Text>
                            </Flex>
                            {AllSubtask?.Revs?.map((rev: any, index: number) => (
                                <Card
                                    key={index}
                                    sx={{
                                        boxShadow: "1 px",
                                        border: `1px solid ${theme?.colors?.lightbackground}`,
                                        borderRadius: "12px",
                                        padding: "12px 20px",
                                        marginBottom: "20px",
                                        position: "relative",
                                    }}
                                >
                                    {rev?.payload?.name && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Name:</Text> {`${rev?.payload?.name}. (${rev?.payload?.number})`}
                                        </Text>
                                    )}
                                    {rev?.payload?.description && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Description:</Text> {rev?.payload?.description}
                                        </Text>
                                    )}
                                    {rev?.payload?.emailLink && rev?.payload?.emailLink.length > 0 && (
                                        <Text as="div" variant="SelectLabel">
                                            Email Link:
                                            <Text variant="Poppins14Normal" className="ms-2">
                                                {rev?.payload?.emailLink.map((email: string, emailIndex: number) => (
                                                    <React.Fragment key={emailIndex}>
                                                        <Link
                                                            target="_blank"
                                                            href={email}
                                                            sx={{ textDecoration: "none", cursor: "pointer" }}
                                                        >
                                                            {email}
                                                        </Link>
                                                        {emailIndex < rev?.payload?.emailLink.length - 1 && ", "}
                                                    </React.Fragment>
                                                ))}
                                            </Text>
                                        </Text>
                                    )}
                                    {rev?.createdAt && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Created Date:</Text> {moment(rev?.createdAt).format("YYYY-MM-DD")}
                                        </Text>
                                    )}
                                </Card>
                            ))}
                            <Divider />

                        </>
                    )}
                    {AllSubtask?.allNewNeedInfoAdded?.length > 0 && (
                        <>
                            <Flex>
                                <Text variant="SelectLabel" sx={{ color: "#506079" }}>New Info Added</Text>
                            </Flex>
                            {AllSubtask?.allNewNeedInfoAdded?.map((nia: any, index: number) => (
                                <Card
                                    key={index}
                                    sx={{
                                        boxShadow: "1px",
                                        border: `1px solid ${theme?.colors?.lightbackground}`,
                                        borderRadius: "12px",
                                        padding: "12px 20px",
                                        marginBottom: "20px",
                                        position: "relative",
                                    }}
                                >
                                    {/* Display name from description array */}
                                    {nia?.payload?.Description?.length > 0 && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Name: </Text>
                                            {nia?.payload?.Description?.map((desc: any, descIndex: number) => (
                                                <Text key={descIndex}>
                                                    {desc?.name}
                                                    {descIndex < nia?.payload?.Description?.length - 1 && ", "}
                                                </Text>
                                            ))}
                                        </Text>
                                    )}

                                    {/* {nia?.payload?.emailLink && nia?.payload?.emailLink.length > 0 && (
                                            <Text as="div" variant="SelectLabel">
                                                Email Link:
                                                <Text variant="Poppins14Normal" className="ms-2">
                                                    {nia?.payload?.emailLink.map((email: string, emailIndex: number) => (
                                                        <React.Fragment key={emailIndex}>
                                                            <Link
                                                                target="_blank"
                                                                href={email}
                                                                sx={{ textDecoration: "none", cursor: "pointer" }}
                                                            >
                                                                {email}
                                                            </Link>
                                                            {emailIndex < nia?.payload?.emailLink.length - 1 && ", "}
                                                        </React.Fragment>
                                                    ))}
                                                </Text>
                                            </Text>
                                        )}
                         */}
                                    {nia?.createdAt && (
                                        <Text as="div" sx={{ lineHeight: "30px" }}>
                                            <Text variant="SelectLabel">Created Date:</Text> {moment(nia?.createdAt).format("YYYY-MM-DD")}
                                        </Text>
                                    )}
                                </Card>
                            ))}
                        </>
                    )}
                </Card>
            </ModalBody>
        </Modal>
    )
}

export default BcPcRevHistory
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Image, Text } from 'theme-ui';
import actions from "../../assets/images/action.svg";
import deleteImage from "../../assets/images/delete.svg";
import EditImage from "../../assets/images/edit.svg";
import StageView from '../../assets/images/StageView.svg';

import { Overlay, Popover } from 'react-bootstrap';
import { ActionDeleteById } from '../../../store/actions/CommonAction';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import theme from '../../../theme';
interface CellComponentProps {
    row: any;
    editTo: string;
    deleteMessage: string;
    deleteApi: string;
    toggleRefresh: () => void;
    showEdit?: boolean;
    showDelete?: boolean;
    showView?: boolean;
    viewTo?: any;
    onClick?: () => void;
}

const TableActions: React.FC<CellComponentProps> = ({ row, editTo, deleteMessage = "Are you sure you want to delete?", onClick, showEdit = true, showDelete = true, deleteApi, toggleRefresh, showView = false, viewTo }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [target, setTarget] = useState(null);
    const dispatch = useDispatch();
    const confirmDelete = () => {
        dispatch(ActionDeleteById({ Query: deleteApi }, (res: any) => {
            if (res.status) {
                setIsDeleteModelOpen(false)
                toggleRefresh();
            }
        }));
    };
    const cancelDelete = () => {
        setIsDeleteModelOpen(false);
    };
    const togglePopover = (event: any) => {
        setIsPopoverOpen(!isPopoverOpen);
        setTarget(event.target);

    };
    const handleClickOutside = (event: MouseEvent) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
            setIsPopoverOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <Flex sx={{ justifyContent: 'center' }}>
            <Box sx={{ position: 'relative' }} ref={popoverRef}>
                <Overlay
                    show={isPopoverOpen}
                    target={target}
                    placement='left' >
                    <Popover id="popover-basic" >
                        {showEdit && (
                            <Link to={editTo} style={{ textDecoration: 'none', color: `${theme.colors?.secondary}` }}>
                                <div className="text-hover" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={onClick}>
                                    <Image src={EditImage} />  &nbsp;&nbsp;&nbsp;Edit
                                </div>                            </Link>)}
                        {showDelete && (
                            <Text className="text-hover" as="p" sx={{ padding: '12px 40px', gap: '8px', cursor: "pointer" }} onClick={() => {
                                setIsOpen(false)
                                setIsDeleteModelOpen(true);
                            }}>

                                <div style={{ textDecoration: 'none', color: `${theme.colors?.secondary}` }} >
                                    <Image src={deleteImage} /> &nbsp;&nbsp;&nbsp;Delete
                                </div>
                            </Text>)}
                        {showView && (
                            <Link to={viewTo} style={{ textDecoration: 'none', color: `${theme.colors?.secondary}` }}>
                                <div className="text-hover " style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                    <Image src={StageView} />  &nbsp;&nbsp;&nbsp;View
                                </div>                            </Link>)}

                    </Popover>
                </Overlay>
                <Image src={actions} onClick={togglePopover} sx={{ cursor: "pointer" }} />
            </Box>
            <Modal isOpen={isDeleteModelOpen} toggle={cancelDelete} centered className='w-100'>
                <ModalBody className='mb-0 pb-0'>
                    <div style={{
                        width: '100%',
                        position: 'relative',
                        zIndex: 1,
                        backgroundColor: 'white',
                        border: '1px solid #black',
                        boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06);',
                        borderRadius: '4px',
                        padding: '20px 4px'
                    }}>
                        <Text className=" w-100" as="p">
                            <strong>{deleteMessage}</strong>
                        </Text>
                    </div>
                </ModalBody>
                <ModalBody className='d-flex'>
                    <Button onClick={confirmDelete} sx={{ marginRight: '10px', width: '50%' }}>Yes</Button>
                    <Button onClick={cancelDelete} sx={{ width: '50%' }}>No</Button>
                </ModalBody>
            </Modal >
        </Flex>

    );
};

export default TableActions;

import { Box, Button, Text } from "theme-ui";
import theme from "../../../../theme";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Inputform } from "../../../forms-component/InputForm";
import CreatableSelect from 'react-select/creatable';
import { FocusEventHandler, KeyboardEventHandler, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../@types";
import { getchecklistDataById } from "../../../../store/actions/masterData.action";
import { ActionCreateByUrl, ActionUpdateById } from "../../../../store/actions/CommonAction";
import * as Yup from 'yup';
import FormSelect from "../../../forms-component/globalSelect";

interface Option {
    label: string;
    value: string;
}
const components = {
    DropdownIndicator: null,
};

const AddEditChecklist: React.FC<any> = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { checklistId } = useParams();
    const { individualChecklist } = useSelector((state: RootState) => state?.masterData);
    const [inputValue, setInputValue] = useState('');
    const [checklistItems, setChecklistItems] = useState<readonly Option[]>([]);
    const [isCreating, setIsCreating] = useState(!checklistId);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            templateName: isCreating ? '' : (individualChecklist?.data?.templateName || ''),
            checklistItem: [],
            showInActions: "",
        },
        validationSchema: Yup.object().shape({
            templateName: Yup.string().required('Template Name is required'),
            checklistItem: Yup.array()
                .of(Yup.string().required('Each checklist item must be a non-empty string'))
                .min(1, 'At least one checklist item is required')
                .required('Checklist Item is required'),
            showInActions: Yup.string().required('Show in Actions is required'),
        }),

        onSubmit: (values, { resetForm }) => {
            if (checklistId) {
                dispatch(ActionUpdateById({ Query: `checklist-templates-routes/editChecklist/${checklistId}`, obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/checklists");
                        resetForm();
                    }
                }));
            } else {
                dispatch(ActionCreateByUrl({ Query: "checklist-templates-routes/createChecklistTemplates", obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/checklists");
                        resetForm();
                    }
                }));
            }

        },

    });

    const { handleSubmit, setFieldValue, setValues, values, handleBlur, touched, errors, resetForm, isValid, isSubmitting } = formik;

    useEffect((): void => {
        if (checklistId) {
            setIsCreating(false);

            dispatch(getchecklistDataById({ id: checklistId }))
        }
        else {
            setIsCreating(true);
            formik.resetForm({
                values: {
                    templateName: '',
                    checklistItem: [],
                    showInActions: "",
                },
            });
            setChecklistItems([]);
        }
    }, [checklistId])
    useEffect(() => {
        if (individualChecklist?.data && checklistId) {
            setValues({
                templateName: individualChecklist?.data?.templateName || '',
                checklistItem: individualChecklist?.data?.checklistItem || [],
                showInActions: individualChecklist?.data?.showInActions || [],
            });
            // setFieldValue('templateName', individualChecklist?.data?.templateName || '');
            const loadedChecklistItems = individualChecklist?.data?.checklistItem?.map((item: string) => ({
                label: item,
                value: item,
            })) || [];
            setChecklistItems(loadedChecklistItems);
            setFieldValue('checklistItem', loadedChecklistItems?.map((item: any) => item?.label));
        }
    }, [individualChecklist, setValues]);

    const createOption = (label: string) => ({
        label,
        value: label,
    });
    const showInActions = [
        { label: "Todos", value: "todos" },
        { label: "Subtasks", value: "subtasks" },

    ];
    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                const newOption = createOption(inputValue);

                setChecklistItems((prevItems) => {
                    const updatedItems = [...prevItems, newOption];
                    setFieldValue("checklistItem", updatedItems.map(item => item.label));
                    return updatedItems;
                });
                setInputValue('');
                event.preventDefault();
                break;
            default:
                break;
        }
    };
    const handleChecklistBlur: FocusEventHandler = (event: any) => {
        if (inputValue.trim() !== '') {

            const newOption = createOption(inputValue);
            setChecklistItems((prevItems) => {
                const updatedItems = [...prevItems, newOption];

                setFieldValue("checklistItem", updatedItems.map(item => item.label));
                return updatedItems;
            });
            setInputValue('');
        }
    };
    const handleChecklistFocus: FocusEventHandler = (event: any) => {
        if (inputValue.trim() !== '') {

            const newOption = createOption(inputValue);
            setChecklistItems((prevItems) => {
                const updatedItems = [...prevItems, newOption];

                setFieldValue("checklistItem", updatedItems.map(item => item.label));
                return updatedItems;
            });
            setInputValue('');
        }
    };
    const handleChange = (newValue: any) => {
        setChecklistItems(newValue);
        setFieldValue("checklistItem", newValue.map((item: any) => item.label));
    };
    return (
        <div className="main-content p-5">
            <form onSubmit={handleSubmit}>
                <h1>{checklistId ? "Edit" : "Add"} Checklist</h1>
                <div className="row">
                    <div className="col-md-6 mt-4">
                        <Inputform
                            label={"Template Name"}
                            Placeholder={"Enter template Name"}
                            Name={"templateName"}
                            type='text'
                            Title_sx={{ color: "grey3" }}
                            touched={touched?.templateName}
                            asterisk={true}
                            errors={errors?.templateName}
                            values={values?.templateName || ''}
                            Max_Length={10}
                            onBlur={handleBlur}
                            OnChange={(event: any) => {
                                const newName = event.target.value;
                                setFieldValue("templateName", newName, true);
                            }}
                        />
                    </div>
                    <div className="col-md-6 mt-4">
                        <Text variant="SelectLabel">
                            Checklist Items<span className="mx-1 text-danger">*</span>
                        </Text>
                        <CreatableSelect
                            components={{ DropdownIndicator: null }}
                            inputValue={inputValue}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={handleChange}
                            onInputChange={(newValue) => {
                                setInputValue(newValue);
                            }}
                            onBlur={handleChecklistBlur}
                            onKeyDown={handleKeyDown}
                            onFocus={handleChecklistFocus}
                            placeholder="Add checklist"
                            value={checklistItems}
                            styles={{
                                valueContainer: (base) => ({
                                    ...base,
                                    maxHeight: '150px',
                                    overflowY: 'auto',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    paddingRight: '30px',
                                }),
                                control: (base) => ({
                                    ...base,
                                    border: '1px solid #f7efe5',
                                    borderRadius: '12px',
                                    minHeight: '48px',
                                    flexWrap: 'wrap',
                                    display: 'flex',
                                    position: 'relative',
                                }),
                                clearIndicator: (base) => ({
                                    ...base,
                                    position: 'absolute',
                                    right: '10px',
                                    top: '25%',
                                    transform: 'translateY(-95%)',
                                    zIndex: 1,
                                    padding: '0 5px',
                                    cursor: 'pointer',
                                }),
                                multiValue: (base) => ({
                                    ...base,
                                }),
                                input: (base) => ({
                                    ...base,
                                    margin: '0', // Ensure there's no margin around the input
                                    padding: '0', // Ensure there's no padding inside the input
                                    transform: 'none', // Reset any transformation to prevent cursor misalignment
                                }),
                            }}
                        />
                        {errors?.checklistItem && touched?.checklistItem && (
                            <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                                {errors?.checklistItem}
                            </Text>
                        )}

                    </div>

                </div>
                <div className="row">
                    <Box className="col-md-6 mt-4">
                        <FormSelect
                            label={"Select Action"}
                            value={values?.showInActions}
                            placeholder={"Select Actions"}
                            options={showInActions || []}
                            isMulti={false}
                            // disabled={true}
                            DefultSelect='Select Todo Assignee'
                            touched={touched?.showInActions}
                            errors={errors?.showInActions}
                            asterisk={true}
                            onBlur={handleBlur}
                            Name='showInActions'
                            onChange={(teamLeader: any) => {
                                setFieldValue('showInActions', teamLeader?.value, true);
                            }}
                        />
                    </Box>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                    <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit'  >Submit</Button></div>
                </div>
            </form>
        </div>
    )
}

export default AddEditChecklist;
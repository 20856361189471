import png from '../Components/assets/images/upload-media-icon/png.svg'
import jpg from '../Components/assets/images/upload-media-icon/jpg.svg'
import xls from '../Components/assets/images/upload-media-icon/xls.svg'
import gif from '../Components/assets/images/upload-media-icon/gif.svg'
import pdf from '../Components/assets/images/upload-media-icon/pdf.svg'
import link from '../Components/assets/images/upload-media-icon/link.svg'



export const uploadIcon = (type: string) => {
  switch (type) {
    case 'png':
      return png;
    case 'jpg':
      return jpg;
    case 'jpeg':
      return jpg;
    case 'pdf':
      return pdf;
    case 'xls':
      return xls;
    case 'xlsx':
      return xls;
    case 'gif':
      return gif;
    case 'com':
      return link;
    default:
  }
}
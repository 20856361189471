import React, { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAllProjectMasterData, getProjectById, getSowChecklist, getSowFields } from "../../../store/actions/project.action";
import StageStep from './AddStagestep';
import AddTaskStep from './AddTaskstep';
import theme from "../../../theme";
interface props {
    next: any
}
const AddStageStepper: React.FC<props> = ({ next }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { projectId } = useParams<{ projectId: string }>();
    const [step, setStep] = useState(1);

    const dispatch = useDispatch();
    const stepLabels = ["Selected Stages", "Scope of work"];

    const getStepColors = (stepIndex: number) => {
        const isActive = (stepIndex + 1) === step;
        const isCompleted = (stepIndex + 1) < step;
        const backgroundColor = isActive ? "#7743DB" : isCompleted ? "#7743DB" : `${theme?.colors?.lightbackground}`;
        const textColor = backgroundColor === `${theme?.colors?.lightbackground}` ? "black" : "white";
        return { backgroundColor, textColor };
    };
    useEffect(() => {
        dispatch(getAllProjectMasterData());
        dispatch(getSowChecklist());
        dispatch(getSowFields());
    }, [dispatch])

    useEffect(() => {
        if (projectId) {
            dispatch(getProjectById(projectId));
        }
    }, [projectId, dispatch]);

    const handleNext = () => {
        setStep(2);
    };



    return (
        <>
            <Stepper activeStep={step}>
                {stepLabels.map((label, index) => (
                    <Step key={index}
                        style={{ backgroundColor: getStepColors(index).backgroundColor, color: getStepColors(index).textColor, pointerEvents: "none" }}
                        onClick={() => setStep(index + 1)}
                    />))}
            </Stepper>
            <div>
                {step === 1 && (
                    <div>
                        <StageStep next={handleNext} />
                    </div>
                )}
                {step === 2 && (
                    <div>
                        <AddTaskStep next={handleNext} />
                    </div>
                )}
            </div>

        </>
    );
};

export default AddStageStepper;

import { useFormik } from "formik";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import * as Yup from 'yup';
import { RootState } from "../../../@types";
import { createStepTwo, getStageByProjectId, getStagesById, getTaskByProjectId } from "../../store/actions/project.action";
import StagesTabView from "./stagesDataView";
const validationSchema = Yup.object({
    stagesData: Yup.array().of(
        Yup.object().shape({
            task: Yup.array().min(1, "Select at least one task").of(
                Yup.object().shape({
                    description: Yup.string().required("description is required"),
                    manager: Yup.string().required("manager is required"),
                    worker: Yup.string().required("worker is required"),
                    taskFields: Yup.object().required("this field is required")
                })
            ),
        })
    ),
    projectSow: Yup.object().shape({
        description: Yup.string().required("description is required"),
        projectSowMapping: Yup.array(),
        fields: Yup.object().required("this field is required"),
    }),
});
interface projectIdProps {
    projectId: string;
}
const StepThreeOnly: React.FC<projectIdProps> = ({ projectId }) => {

    const location = useLocation();
    const { stageId } = useParams<{ stageId: string }>();
    const dispatch = useDispatch();
    const { SowChecklist, sowfields, taskbyproject } = useSelector((state: RootState) => state.project);
    const { stagebyproject } = useSelector((state: any) => state.project);

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const parsed = queryString.parse(location.search) as any;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getTaskByProjectId(projectId));
        dispatch(getStageByProjectId(projectId))
        if (stageId) {
            dispatch(getStagesById(stageId));
        }
    }, [dispatch])
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: {

        },
        onSubmit: () => {
            const payload = {
                // stagesData: (values.stagesData || []).map((stage: { id: any; name: any; task: any; dueDate: any; promise: any; color: any; worker: any; manager: any; }, stageIndex: any) => ({
                //     id: stage?.id,
                //     name: stage?.name,
                //     task: (stage?.task || []).map((task: { id: any; name: any; color: any; sowMapping: any; description: any; manager: any; worker: any; timeEstimate: any; taskFields: any; _id: any; }) => ({
                //         id: task?.id,
                //         name: task?.name,
                //         color: task?.color,
                //         sowMapping: task?.sowMapping,
                //         description: task?.description,
                //         manager: task?.manager,
                //         worker: task?.worker,
                //         timeEstimate: task?.timeEstimate,
                //         taskFields: task?.taskFields,
                //         _id: task?._id
                //     })),
                //     dueDate: stage?.dueDate,
                //     promise: stage?.promise,
                //     color: stage?.color,
                //     worker: stage?.worker || null,
                //     manager: stage?.manager || null,
                // })),
                // projectSow: {
                //     projectSowMapping: values?.projectSow?.projectSowMapping,
                //     description: values.projectSow?.description,
                //     fields: values.projectSow?.fields,
                // },
                page: "step3",
            };

            dispatch(createStepTwo(payload, parsed?.id, formik,
                (res: any) => {
                    if (res.status === 200) {
                        toast.success("Project created Successfully");
                        navigate("/project-view")
                    }
                }
            ));
        },
    });
    // const handleNextClick = () => {
    //     if (formik.isValid) {
    //         formik.handleSubmit();
    //     } else {
    //         toast.error("Form is not valid. Please fill in all required fields.");
    //     }
    // };
    const { handleSubmit, handleBlur, errors } = formik;
    // useEffect(() => {
    //     if (SowChecklist?.length > 0 && values.stagesData?.length > 0) {
    //         setIsChecked(true)
    //     }
    // }, [SowChecklist, values.stagesData])
    useEffect(() => {
        if (isChecked) {
            // let defaultSelectedData = SowChecklist.filter((val) => val.default);

            formik.setValues((prevValues) => ({
                ...prevValues,
                // stagesData: prevValues.stagesData.map((val: { task: any[]; }) => ({
                //     ...val,
                //     task: val.task.map((task: { id: string; }) => {
                //         let taskSelectedId = defaultSelectedData?.filter((value) => value.taskType === task.id);
                //         let actualTaskSowMappingIds = taskSelectedId.map((val) => val._id);

                //         // console.log(actualTaskSowMappingIds, "check177");
                //         return {
                //             ...task,
                //             sowMapping: [...actualTaskSowMappingIds],
                //         };
                //     }),
                // })),
            }));
        }
    }, [isChecked]);
    useEffect(() => {
        if (isChecked) {
            let defaultSelectedData = SowChecklist.filter((val) => val.default);

            formik.setValues((prevValues) => ({
                ...prevValues,
                // projectSow: {
                //     ...prevValues.projectSow,
                //     projectSowMapping: [
                //         ...(prevValues.projectSow.projectSowMapping || [])
                //     ],
                // },
            }));

            let taskSelectedId = defaultSelectedData?.filter((value) => value.taskType === null);

            if (taskSelectedId) {
                // let actualTaskSowMappingIds = taskSelectedId.map((val) => val._id);
                formik.setValues((prevValues) => ({
                    ...prevValues,
                    projectSow: {
                        // ...prevValues.projectSow,
                        // projectSowMapping: [
                        //     ...(prevValues.projectSow.projectSowMapping || []),
                        //     ...actualTaskSowMappingIds,
                        // ],
                    },
                }));
            }
        }
    }, [isChecked]);

    // const projectSowChecklist = SowChecklist?.filter((entry) => entry?.taskType === null);
    return (
        <form onSubmit={handleSubmit}>
            <StagesTabView
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                values={taskbyproject}
                stagebyproject={stagebyproject}
                setFieldValue={formik?.setFieldValue}
                handleBlur={handleBlur}
                errors={errors}
                touched={formik?.touched}
                SowChecklist={SowChecklist}
                sowfields={sowfields}
                projectId={projectId}
            />
            <br />
            {/* <Flex sx={{ justifyContent: "end" }} className="mt-4">
                    <Button sx={{ background: theme.colors?.primary, padding: '12px 18px', width: "25%" }} onClick={handleNextClick} type="submit"> Finish</Button>
                </Flex> */}

        </form>
    );
};

export default StepThreeOnly;

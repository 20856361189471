import React from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Box, Card, Grid, Text, Textarea } from 'theme-ui';
import { ItemType, RootState } from '../../../../../@types';
import { CheckBox2 } from '../../../CheckBox/Project';
import { Inputform } from '../../../forms-component/InputForm';
import FormSelect from '../../../forms-component/globalSelect';
import "../../../project-components/projectTab.css";

interface StagesTabProps {
    selectedIndex: number;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
    values: any;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
    errors: any;
    touched: any;
    SowChecklist: any[];
    sowfields: any[];
}

const StagesTab: React.FC<StagesTabProps> = ({
    selectedIndex,
    setSelectedIndex,
    values,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    SowChecklist,
    sowfields,
}) => {
    const { createprojectMasterData } = useSelector((state: RootState) => state.project);
    const projectManagers = createprojectMasterData?.allProjectManagers || [];
    const projectWorkers = createprojectMasterData?.allTaskWorkers || [];

    return (
        <Box>
            <Text as={'h4'} mt={3} mb={2}>
                Stages
            </Text>
            <Tabs selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
                <TabList>
                    {values?.stagesData?.map((stage: any, index: number) => {
                        return (
                            <Tab
                                key={stage?._id}
                                style={{
                                    borderColor: selectedIndex === index ? '#ffffff00' : '',
                                    color: selectedIndex === index ? stage?.color : 'black',
                                    borderRadius: selectedIndex === index ? '5px 5px 0 0' : '',
                                    fontSize: selectedIndex === index ? '18px' : '',
                                    fontWeight: selectedIndex === index ? 600 : '',
                                    borderBottom: selectedIndex === index ? `3px solid ${stage?.color}` : '',
                                }}
                            >
                                {stage?.name}
                            </Tab>
                        );
                    })}

                </TabList>
                {values?.stagesData?.map((stages: any, stageIndex: number) => (
                    <TabPanel key={stages?.id}>
                        {stages?.task?.map((department: any, departmentIndex: number) => {
                            const ChecklistItem = SowChecklist?.filter((entry) => entry?.taskType === department?.id);
                            const SowFields = sowfields?.filter((entry) => entry?.taskName === department?.id);
                            return (
                                <Card key={departmentIndex} mt={7} sx={{ width: '100%', borderRadius: '12px' }}>
                                    <Box sx={{ borderLeft: `3px solid ${department?.color}`, paddingLeft: '24px' }}>
                                        <Text as={'h3'} sx={{ color: department?.color }}>
                                            {department?.name}
                                        </Text>
                                        <Box mt={3}>
                                            <CheckBox2
                                                title={''}
                                                Option={ChecklistItem}
                                                onChange={(event: any, item: ItemType) => {
                                                    if (event) {
                                                        department?.sowMapping?.push(item?._id)
                                                    } else {
                                                        let indexToRemove = department?.sowMapping?.indexOf(item?._id);
                                                        if (indexToRemove !== -1) {
                                                            department?.sowMapping?.splice(indexToRemove, 1);
                                                            // console.log("ID removed successfully");
                                                        } else {
                                                            // console.log("ID not found in the array");
                                                        }
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <Text mt={3} as={'h5'}>
                                            Description
                                        </Text>
                                        <Textarea
                                            name={`stagesData[${selectedIndex}].task[${departmentIndex}].description`}
                                            value={(values?.stagesData?.[stageIndex] as { task?: Array<{ description?: string }> })?.task?.[departmentIndex]?.description}
                                            onChange={(values) => {
                                                setFieldValue(`stagesData[${selectedIndex}].task[${departmentIndex}].description`, values?.target?.value, true);
                                            }}
                                            onBlur={handleBlur}
                                            rows={8}
                                            sx={{ borderColor: 'InactiveBorder' }}
                                        />

                                        {errors.stagesData && touched.stagesData ? (
                                            <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                                                {(errors?.stagesData?.[stageIndex] as { task?: Array<{ description?: string }> })?.task?.[departmentIndex]?.description}
                                            </Text>
                                        ) : (
                                            <Text variant="Inter15Regular30lh" sx={{ my: '5px', minHeight: '11px' }}></Text>
                                        )}
                                        <Grid mt={2} gap={2} columns={[1, 2, 3, 3, 4, 4]}>
                                            {SowFields?.map((sowfield: any, index: number) => (
                                                <Box key={index}>
                                                    {sowfield?.type === 'select' && sowfield?.fieldName === 'Task Worker' ? (
                                                        <FormSelect
                                                            Name=''
                                                            options={projectWorkers?.map((projectWorker: { FirstName: string; LastName: string; _id: string; }) => ({
                                                                label: `${projectWorker?.FirstName} ${projectWorker?.LastName}`,
                                                                value: projectWorker?._id
                                                            })) || []}
                                                            label={sowfield?.fieldName}
                                                            placeholder={sowfield?.fieldName}
                                                            value={department?.worker}
                                                            onBlur={handleBlur}
                                                            touched={touched?.stagesData?.[stageIndex]?.task?.[departmentIndex]?.worker}
                                                            errors={(errors?.stagesData?.[stageIndex] as { task?: Array<{ worker?: string }> })?.task?.[departmentIndex]?.worker}
                                                            onChange={(values: any) => {
                                                                // console.log("valuesvalues", values);
                                                                setFieldValue(`stagesData[${selectedIndex}].task[${departmentIndex}].worker`, values?.value);
                                                            }}
                                                        />
                                                    ) : sowfield?.type === 'select' && sowfield?.fieldName === 'Task Manager' ? (
                                                        <FormSelect
                                                            options={projectManagers.map((projectWorker: { FirstName: string; LastName: string; _id: string; }) => ({
                                                                label: `${projectWorker?.FirstName} ${projectWorker?.LastName}`,
                                                                value: projectWorker?._id
                                                            })) || []}
                                                            placeholder={sowfield?.fieldName}
                                                            label={sowfield?.fieldName}
                                                            onBlur={handleBlur}
                                                            touched={touched?.stagesData?.[stageIndex]?.task?.[departmentIndex]?.manager}
                                                            errors={(errors?.stagesData?.[stageIndex] as { task?: Array<{ manager?: string }> })?.task?.[departmentIndex]?.manager}
                                                            value={department?.manager}
                                                            onChange={(values: any) => {
                                                                // console.log("valuesvalues", values);
                                                                setFieldValue(`stagesData[${selectedIndex}].task[${departmentIndex}].manager`, values?.value);
                                                            }}
                                                        />
                                                    ) : (
                                                        <Inputform
                                                            type={sowfield?.type}
                                                            label={sowfield?.fieldName}
                                                            Placeholder={sowfield?.fieldName}
                                                            touched={touched?.stagesData?.[stageIndex]?.task?.[departmentIndex]?.taskFields}
                                                            errors={(errors?.stagesData?.[stageIndex] as { task?: Array<{ taskFields?: string }> })?.task?.[departmentIndex]?.taskFields}
                                                            Name={`stagesData[${selectedIndex}].task[${departmentIndex}].taskFields[${sowfield?.fieldName}]`}
                                                            Title_sx={{ color: 'grey' }}
                                                            values={((values?.stagesData?.[stageIndex] as { task?: Array<{ taskFields?: Record<string, string> }> })?.task?.[departmentIndex]?.taskFields || {})[sowfield?.fieldName]}
                                                            onBlur={handleBlur}
                                                            OnChange={(values: any) => {
                                                                setFieldValue(
                                                                    `stagesData[${selectedIndex}].task[${departmentIndex}].taskFields[${sowfield?.fieldName}]`,
                                                                    values?.target?.value,
                                                                    true
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Card>
                            );
                        })}
                    </TabPanel>
                ))}
            </Tabs>
        </Box>
    );
};

export default StagesTab;

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Divider, Text } from "theme-ui";
import MyTeam from "./Dashboard/MyTeam";

const DashboardNew = () => {
    const dispatch = useDispatch();
    const { generalTimeTracking } = useSelector((state: any) => state.project);
    console.log('chk10', generalTimeTracking);

    return (
        <div className="main-content">
            <div className="row">
                <div className='col-12 col-md-6 col-xl-6 mt-5 mt-xl-0'>
                    <Text className="px-2 ms-3" variant='Poppins36Normal35lh'>
                        {'Dashboard V2'}
                    </Text>
                </div>
            </div>
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <div className="row" style={{ backgroundColor: '#f7efe5' }}>
                {/* <div className="col-xl-4">
                    <GeneralTimerHistory />

                </div> */}
                <div className="col-xl-4">
                    <MyTeam />

                </div>


            </div>


        </div>
    )
}

export default DashboardNew
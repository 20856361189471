import './utils/consoleOverride';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RootState } from '../@types';
import './App.css';
import './Components/assets/css/tabel.css';
import CreateRole from './Components/pages/Roles/create-role';
import DeleteRole from './Components/pages/Roles/delete-role';
import EditRole from './Components/pages/Roles/edit-role';
import RolesTable from './Components/pages/Roles/role';
import Sidebar from './Components/pages/Sidebar';
import StagesView from './Components/pages/StagesView/stagesView';
import TaskView from './Components/pages/TaskView/TaskView';
import ChatConversation from './Components/pages/comments/chat';
import CreateProjectForm from './Components/pages/create project /CreateProjectForm';
import Dashboard from './Components/pages/dashboard';
import Demotable from './Components/pages/demotable';
// import Forgot from './Components/pages/forgotPassword';
import Login from './Components/pages/login';
import AddEditArchitect from './Components/pages/masterData/Architect/AddEditArchitect';
import ArchitectMasterData from './Components/pages/masterData/Architect/Architect';
import Blueprint from './Components/pages/masterData/BluePrint';
import AddEditStages from './Components/pages/masterData/Stages/AddEditStages';
import StagesMasteData from './Components/pages/masterData/Stages/StagesMasteData';
import Statuses from './Components/pages/masterData/Statuses';
import AddEditTaskField from './Components/pages/masterData/TaskField/AddEditTaskField';
import TaskFieldsMapping from './Components/pages/masterData/TaskField/TaskFieldsMapping';
import AddEditTaskSow from './Components/pages/masterData/TaskSow/AddEditTaskSow';
import TaskSowMasterData from './Components/pages/masterData/TaskSow/TaskSow';
import AddEditTaskTypeMasterData from './Components/pages/masterData/TaskTypeMasterData/AddEditTaskTypeMasterData';
import TaskTypeMasterData from './Components/pages/masterData/TaskTypeMasterData/TaskMasterData';
import SignUp from './Components/pages/signup';
import CreateUser from './Components/pages/users/createUser';
import DeleteUser from './Components/pages/users/deleteUser';
import EditUserForm from './Components/pages/users/editUser';
import UsersTable from './Components/pages/users/usertable';
import Private from './routes/PrivateRoute';
import Protected from './routes/PublicRoute';
import { getUserbyToken } from './store/actions/login.action';
import FrontAppListing from './Components/pages/FrontApp/FrontApp';
import RatingCriteria from './Components/pages/masterData/Rating/RatingCriteria';
import AddEditRatingCriteria from './Components/pages/masterData/Rating/AddEditRatingCriteria';
import TradingRatingCriteria from './Components/pages/masterData/TradingRating/TradingRatingCriteria';
import AddEditTradingRating from './Components/pages/masterData/TradingRating/AddEditTradingRating';
import MyReviews from './Components/pages/MyReview';
import EditModal from './Components/project-components/projectModal';
import EditStageView from './Components/pages/StagesView/EditStageView';
import EditTaskView from './Components/pages/TaskView/EditTaskView';
import FloatingTimeBox from './Components/FloatingTimeBox/FloatingTimeBox';
import ResetPassword from './Components/pages/auth/resetPassword';
import EmailVerify from './Components/pages/auth/emailVerify';
import ForgotPassword from './Components/pages/auth/forgotPassword';
import PrivateAdminRoute from './routes/PrivateAdminRoute';
import StagesViewTwo from './Components/pages/StagesView/stagesViewTwo';
import DailyReport from './Components/pages/ReportTemplate/DailyReport';
import MonthlyReport from './Components/pages/ReportTemplate/MonthlyReport';
import AdminReport from './Components/pages/ReportTemplate/AdminReport';
import ChecklistMasterdata from './Components/pages/masterData/Checklist/ChecklistMasterdata';
import AddEditChecklist from './Components/pages/masterData/Checklist/AddEditChecklist';
import UploadTypeMasterData from './Components/pages/masterData/UploadType/UploadTypeMasterData';
import AddEditUploadType from './Components/pages/masterData/UploadType/AddEditUploadType';
import Teams from './Components/pages/Teams/teams';
import AddEditTeams from './Components/pages/Teams/AddEditTeams';
import YourTeam from './Components/pages/Teams/YourTeam';
import AssignedTeam from './Components/pages/Teams/AssignedTeam';
import AllTodos from './Components/pages/Todo/AllTodos';
import AddEditTodos from './Components/pages/Todo/AddEditTodo';
import MyTodo from './Components/pages/Todo/MyTodo';
import AssignedTodo from './Components/pages/Todo/AssignedTodo';
import StagesViewSecond from './Components/pages/StagesView/stagesViewSecond';
import ProjectViewTwo from './Components/pages/demotable/projectViewTwo';
import GeneralTimerReport from './Components/pages/ReportTemplate/GeneralTimerReport';
import CompletedTodo from './Components/pages/Todo/CompletedTodo';
import MyReport from './Components/pages/ReportTemplate/MyReport';
import MyGeneralTimer from './Components/pages/ReportTemplate/MyGeneralTimer';
import DashboardNew from './Components/pages/DashboardNew';
import TeamDetailView from './Components/pages/Dashboard/TeamDetailView';
import ReviewModal from './Components/pages/demotable/Ratings';

function App() {
  const location = useLocation();
  const { auther } = useSelector((state: RootState) => state.auth);
  const authToken = localStorage.getItem("token")
  const dispatch = useDispatch();
  useEffect(() => {
    if (!!authToken) {
      <Navigate to="/dashboard" />
      dispatch(getUserbyToken(authToken))
    }
  }, [authToken])

  // Prevent component
  const preventComponent = ![
    '/login',
    '/signup',
    '/reset-password',
    "/forgot-password",
    "/password"
  ].includes(location.pathname);

  return (
    <>
      <Routes>
        <Route path="/*" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Protected><Login /></Protected>} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/dashboard" element={<Private><Sidebar /><Dashboard /></Private>} />
        <Route path="/dashboard-new" element={<Private><Sidebar /><DashboardNew /></Private>} />
        <Route path="/my-team" element={<Private><Sidebar /><TeamDetailView /></Private>} />
        <Route path="/dashboard-new" element={<Private><Sidebar /><DashboardNew /></Private>} />
        {/* <Route path="/my-team" element={<Private><Sidebar /><TeamDetailView /></Private>} /> */}
        {/* <Route path="/teams" element={<Private><Sidebar /><MyTeam /></Private>} />  */}

        <Route path="/create-user" element={<PrivateAdminRoute><Sidebar /><CreateUser /></PrivateAdminRoute>} />
        <Route path="/create-architect" element={<PrivateAdminRoute><Sidebar /><AddEditArchitect /></PrivateAdminRoute>} />
        <Route path="/edit-architect/:architectId" element={<PrivateAdminRoute><Sidebar /><AddEditArchitect /></PrivateAdminRoute>} />
        <Route path="/edit-role/:roleId" element={<PrivateAdminRoute><Sidebar /><EditRole /></PrivateAdminRoute>} />
        <Route path="/edit-user/:userId" element={<PrivateAdminRoute><Sidebar /><EditUserForm /></PrivateAdminRoute>} />
        <Route path="/delete-user/:userId" element={<PrivateAdminRoute><Sidebar /><DeleteUser /></PrivateAdminRoute>} />
        <Route path="/delete-role/:userId" element={<PrivateAdminRoute><Sidebar /><DeleteRole /></PrivateAdminRoute>} />
        <Route path="/create-project" element={<Private><Sidebar /> <CreateProjectForm /></Private>} />
        <Route path='/user' element={<PrivateAdminRoute><Sidebar /><UsersTable /></PrivateAdminRoute>} />
        <Route path='/role' element={<PrivateAdminRoute><Sidebar /><RolesTable /></PrivateAdminRoute>} />
        <Route path='/tabel' element={<Private><Sidebar /><Demotable /></Private>} />
        <Route path='/task-view' element={<Private><Sidebar /><TaskView /></Private>} />
        <Route path='/architect' element={<PrivateAdminRoute><Sidebar /><ArchitectMasterData /></PrivateAdminRoute>} />
        <Route path='/stages' element={<PrivateAdminRoute><Sidebar /><StagesMasteData /></PrivateAdminRoute>} />
        <Route path='/create-stage' element={<PrivateAdminRoute><Sidebar /><AddEditStages /></PrivateAdminRoute>} />
        <Route path='/edit-stage/:stageId' element={<PrivateAdminRoute><Sidebar /><AddEditStages /></PrivateAdminRoute>} />
        <Route path='/stage-view' element={<Private><Sidebar /><StagesView /></Private>} />
        <Route path='/stage-view-two' element={<Private><Sidebar /><StagesViewTwo /></Private>} />
        <Route path='/stages-view-second' element={<Private><Sidebar /><StagesViewSecond /></Private>} />

        <Route path='/project-view' element={<Private><Sidebar /><Demotable /></Private>} />
        <Route path='/project-view-two' element={<Private><Sidebar /><ProjectViewTwo /></Private>} />

        {/* <Route path='/project-type' element={<Private><Sidebar /><ProjectType /></Private>} /> */}
        <Route path='/blueprints' element={<PrivateAdminRoute><Sidebar /><Blueprint /></PrivateAdminRoute>} />
        <Route path='/statuses' element={<PrivateAdminRoute><Sidebar /><Statuses /></PrivateAdminRoute>} />
        <Route path='/task-sow' element={<PrivateAdminRoute><Sidebar /><TaskSowMasterData /></PrivateAdminRoute>} />
        <Route path='/create-task-sow' element={<PrivateAdminRoute><Sidebar /><AddEditTaskSow /></PrivateAdminRoute>} />
        <Route path='/create-checklist' element={<PrivateAdminRoute><Sidebar /><AddEditChecklist /></PrivateAdminRoute>} />
        <Route path='/checklists' element={<PrivateAdminRoute><Sidebar /><ChecklistMasterdata /></PrivateAdminRoute>} />
        <Route path="/edit-checklist/:checklistId" element={<PrivateAdminRoute><Sidebar /><AddEditChecklist /></PrivateAdminRoute>} />
        <Route path='/uploadTypes' element={<PrivateAdminRoute><Sidebar /><UploadTypeMasterData /></PrivateAdminRoute>} />
        <Route path='/create-upload-type' element={<PrivateAdminRoute><Sidebar /><AddEditUploadType /></PrivateAdminRoute>} />
        <Route path="/edit-upload-Types/:uploadtypeId" element={<PrivateAdminRoute><Sidebar /><AddEditUploadType /></PrivateAdminRoute>} />

        <Route path='/rating-criteria' element={<PrivateAdminRoute><Sidebar /><RatingCriteria /></PrivateAdminRoute>} />
        <Route path='/create-rating-criteria' element={<PrivateAdminRoute><Sidebar /><AddEditRatingCriteria /></PrivateAdminRoute>} />
        <Route path='/edit-rating-criteria/:ratingId' element={<PrivateAdminRoute><Sidebar /><AddEditRatingCriteria /></PrivateAdminRoute>} />
        <Route path='/trading-rating' element={<PrivateAdminRoute><Sidebar /><TradingRatingCriteria /></PrivateAdminRoute>} />
        <Route path='/create-trading-rating' element={<PrivateAdminRoute><Sidebar /><AddEditTradingRating /></PrivateAdminRoute>} />
        <Route path='/edit-trading-rating/:tradingId' element={<PrivateAdminRoute><Sidebar /><AddEditTradingRating /></PrivateAdminRoute>} />
        <Route path='/edit-task-sow/:taskSowId' element={<PrivateAdminRoute><Sidebar /><AddEditTaskSow /></PrivateAdminRoute>} />
        <Route path='/task-field' element={<PrivateAdminRoute><Sidebar /><TaskFieldsMapping /></PrivateAdminRoute>} />
        <Route path='/create-task-field' element={<PrivateAdminRoute><Sidebar /><AddEditTaskField /></PrivateAdminRoute>} />
        <Route path='/edit-task-field/:taskFieldId' element={<PrivateAdminRoute><Sidebar /><AddEditTaskField /></PrivateAdminRoute>} />
        <Route path='/task-type' element={<PrivateAdminRoute><Sidebar /><TaskTypeMasterData /></PrivateAdminRoute>} />
        <Route path='/create-task-type' element={<PrivateAdminRoute><Sidebar /><AddEditTaskTypeMasterData /></PrivateAdminRoute>} />
        <Route path='/edit-task-type/:taskTypeId' element={<PrivateAdminRoute><Sidebar /><AddEditTaskTypeMasterData /></PrivateAdminRoute>} />
        {/*<Route path='/tabel/:slug' element={<PrivateAdminRoute><Sidebar /><Demotable /></PrivateAdminRoute>} />*/}
        {/* <Route path='/edit-stages/:id' element={<PrivateAdminRoute><ActionBcPcChecklist /></PrivateAdminRoute>} /> */}

        <Route path='/create-role' element={<PrivateAdminRoute><Sidebar /><CreateRole /></PrivateAdminRoute>} />
        <Route path='/edit-project/:projectId' element={<Private><Sidebar /><EditModal /></Private>} />
        <Route path='/edit-stages/:stageId' element={<Private><Sidebar /><EditStageView /></Private>} />
        <Route path='/edit-task/:taskId' element={<Private><Sidebar /><EditTaskView /></Private>} />
        <Route path='/edit-reviewModal/:id' element={<Private><Sidebar /><ReviewModal data={undefined} /></Private>} />

        <Route path='/front-app' element={<Private><Sidebar /><FrontAppListing /></Private>} />
        <Route path='/my-review' element={<Private><Sidebar /><MyReviews /></Private>} />
        <Route path='/daily-report' element={<Private><Sidebar /><DailyReport /></Private>} />
        <Route path='/monthly-report' element={<Private><Sidebar /><MonthlyReport /></Private>} />
        <Route path='/admin-report' element={<Private><Sidebar /><AdminReport /></Private>} />
        <Route path='/my-report' element={<Private><Sidebar /><MyReport /></Private>} />
        <Route path='/my-generalreport' element={<Private><Sidebar /><MyGeneralTimer /></Private>} />

        <Route path='/generalTimer-report' element={<Private><Sidebar /><GeneralTimerReport /></Private>} />

        <Route path='/teams' element={<PrivateAdminRoute><Sidebar /><Teams /></PrivateAdminRoute>} />
        <Route path='/your-team' element={<Private><Sidebar /><YourTeam /></Private>} />
        <Route path='/assigned-team' element={<Private><Sidebar /><AssignedTeam /></Private>} />
        <Route path='/create-team' element={<PrivateAdminRoute><Sidebar /><AddEditTeams /></PrivateAdminRoute>} />
        <Route path="/edit-team/:teamId" element={<PrivateAdminRoute><Sidebar /><AddEditTeams /></PrivateAdminRoute>} />
        <Route path='/todos' element={<PrivateAdminRoute><Sidebar /><AllTodos /></PrivateAdminRoute>} />
        <Route path='/my-todo' element={<Private><Sidebar /><MyTodo /></Private>} />
        <Route path='/assigned-todo' element={<Private><Sidebar /><AssignedTodo /></Private>} />
        <Route path='/create-todo' element={<PrivateAdminRoute><Sidebar /><AddEditTodos /></PrivateAdminRoute>} />
        <Route path="/edit-todo/:todoId" element={<Private><Sidebar /><AddEditTodos /></Private>} />
        <Route path='/completed-todos' element={<Private><Sidebar /><CompletedTodo /></Private>} />

        {/* <Route path='/forgot-password' element={<Protected><Forgot /></Protected>} /> */}
        <Route path='/forgot-password' element={<EmailVerify />} />
        {/* <Route path='/reset-password' element={<Protected><Reset /></Protected>} /> */}
        <Route path='/forget/password' element={<Protected><ForgotPassword /></Protected>} />
        <Route path='/reset-password-test' element={<Private><Sidebar /><ResetPassword /></Private>} />
        <Route path='/chat/:redirect/:phaseId/:threadId' element={<Private><Sidebar /><ChatConversation /></Private>} />
      </Routes>
      <Toaster />

      {(authToken && preventComponent) && <FloatingTimeBox />}
    </>
  );
}
export default App

// validationUtils.ts
import { withFormik } from 'formik';
import * as Yup from 'yup';

// Define Yup validation schema
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    description: Yup.string().required(`Description is required`)
});

// Define the enhancer with Formik and Yup
export const withFormikValidation = withFormik({
    mapPropsToValues: () => ({ name: '', email: '', description: '' }),
    validationSchema,
    validateOnMount: true,
    handleSubmit: () => { },
});

import { ColumnDef } from '@tanstack/react-table';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Divider, Text } from 'theme-ui';
import FrontAppTable from '.';
import { UpdateFrontAppRow, getAllFrontapp } from '../../../store/actions/frontApp.Action';
import { getApiEndPoint } from '../../../utils/GenerateQueryString';
import { baseUrl } from '../../../utils/global';
import { TabelHeadingTxt } from '../../Text/TabelCommonTxt';
import Export from "../../assets/images/Icon/Export.svg";
import calender from "../../assets/images/calendar-2.svg";
import CheckboxInput from './Checkbox';
import CommentBox from './CommentBox';
import FolderNumber from './FolderNumber';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import { setPagination } from '../../../store/actions/Pagination.action';
import { LuRedoDot } from 'react-icons/lu';
import theme from '../../../theme';
// import { getAllFrontapp } from '../../../store/actions/frontApp.Action';
const FrontAppListing: React.FC = () => {
    type YourDataType = {
        tasksNameList: {
            _id: string;
            color: string;
            name: string;
            shortForm: string;
        }[];
    };
    const { frontAppData, loader } = useSelector((state: any) => state?.frontApp);
    const { paginations } = useSelector((state: any) => state.pagination);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // const [frontAppId, setFrontAppId] = useState<string>("");
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    useEffect(() => {
        setSearchParams({ currentPage: currentPage.toString(), limit: limit.toString() });
    }, []);
    useEffect(() => {
        dispatch(setPagination({ limit, currentPage }));
    }, [limit, currentPage]);

    useEffect(() => {
        const newSearchParams = { ...queryString.parse(location.search), currentPage, limit };
        navigate({ search: queryString.stringify(newSearchParams) });
    }, [currentPage, limit]);

    useEffect(() => {
        const apiEndPoint = getApiEndPoint({ limit, currentPage }, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
        dispatch(getAllFrontapp(apiEndPoint));
    }, [location.search, limit, currentPage]);
    const { data: eventSource } = useQuery({
        queryKey: ['frontAppSSE2'],
        queryFn: () => {
            const eventSource = new EventSource(`${baseUrl}sse/frontApp`);

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    dispatch(UpdateFrontAppRow(data))
                    if (data?.type === "Insert") {
                        toast((t) => (
                            <span>
                                New Message
                                <Text sx={{ margin: "0 10px", background: `${theme?.colors?.lightbackground}`, padding: "5px 8px", cursor: "pointer", borderRadius: "8px" }} onClick={() => {
                                    if (searchParams?.size > 2) {
                                        const parsedQuery = queryString.parse(location.search);

                                        setSearchParams({
                                            ...parsedQuery,
                                            currentPage: "1",
                                        });
                                    } else {
                                        setSearchParams({
                                            currentPage: "1",
                                            limit: limit.toString(),
                                        });
                                    }
                                    toast.remove();
                                }}>
                                    View
                                </Text>
                            </span>
                        ), { position: "top-center", duration: Infinity });
                    }
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };

            eventSource.onerror = (error) => {
                console.error('SSE Error:', error);
                eventSource.close();
            };

            return eventSource;
        },
        refetchInterval: 60000
    })
    useEffect(() => {
        return () => {
            eventSource?.close();
        };
    }, [])

    const CommentCell: React.FC<any> = ({ row }) => {
        const [expanded, setExpanded] = useState(false);
        const comment = row?.original?.comment;
        const truncatedComment = comment?.length > 15 ? comment.slice(0, 15) + '' : comment;

        const toggleExpanded = () => {
            setExpanded(!expanded);
        };

        const handleClick = () => {
            if (comment?.length > 15) {
                toggleExpanded();
            }
        };

        return (
            <div>
                <p onClick={handleClick} style={{ cursor: comment?.length > 15 ? 'pointer' : 'default' }}>

                    {expanded || comment?.length <= 15 ? comment : truncatedComment}
                    {comment?.length > 15 && !expanded && <span style={{ color: '#007FFF', marginLeft: '2px' }}>...</span>}
                </p>
            </div>
        );
    };

    const DToptions = [
        {
            value: "NotCompleted",
            label: "NotCompleted"
        },
        {
            value: "Completed",
            label: "Completed"
        }
    ]
    const PMoptions = [
        {
            value: "NotCompleted",
            label: "NotCompleted"
        },
        {
            value: "Completed",
            label: "Completed"
        }
    ]


    const columns = useMemo<ColumnDef<any>[]>(() => [

        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row?.index + 1}</Text>
                )
            },
        },
        {
            header: 'Subject',
            accessorKey: "cmt_subject",
            cell: ({ row }) => {

                return (
                    <p style={{ textAlign: 'left', paddingLeft: '5px' }}>{row?.original?.cmt_subject.slice(0, 20)}</p>
                )
            },
            filterType: "textbox"
        },
        {
            header: 'Folder',
            accessorKey: "folderNumber",
            cell: ({ row }) => {
                return (
                    <FolderNumber original={row?.original} />
                )
            },
            filterType: 'textbox',
        },
        {
            header: 'Tab Name',
            accessorKey: "tabname",
            cell: ({ row }) => {
                return (
                    <Text>
                        {row?.original?.tabname}
                    </Text>
                );
            },
            filterType: "textbox",
        },
        {
            header: 'Comment',
            accessorKey: "comment",
            cell: CommentCell,
            filterType: 'textbox',
        },
        {
            header: 'Comment Date',
            accessorKey: "converted_date",
            cell: ({ row }) => {

                return (
                    <div className='d-flex text-center p-2'><img src={calender} alt="calender" />&nbsp;{row?.original?.comment_date}</div>
                )
            },
            filterType: 'date',
        },
        {
            header: 'User Mail',
            accessorKey: "user_mail",
            cell: ({ row }) => {
                const email = row?.original?.user_mail;
                const mailtoLink = `mailto:${email}`;
                return (
                    <a
                        title={email}
                        href={mailtoLink}
                        className="text-truncate"
                        dangerouslySetInnerHTML={{ __html: email }}
                    />
                )
            },
            filterType: 'textbox',
        },
        {
            header: 'User Name',
            accessorKey: "username",
            cell: ({ row }) => {

                return (
                    <Text variant='Poppins14Normal24lh'>{row?.original?.username}</Text>
                );
            },
            filterType: 'textbox',
        },
        {
            header: 'Conversation',
            accessorKey: "conversation",
            cell: ({ row }) => {
                return (
                    <a href={row?.original?.cmt_conversation} target='_blank' className='text-decoration-none'><LuRedoDot className='me-1' />Link</a>
                    // <a href={row?.original?.cmt_conversation} >{row?.original?.cmt_conversation}</a>
                )
            },
            filterType: "textbox",
        },

        {
            header: 'Comment Box',
            accessorKey: 'client_comment',

            cell: ({ row }) => {
                return (
                    <CommentBox original={row?.original} />
                );

            },
            filterType: "textbox",

        },
        {
            header: 'DT',
            accessorKey: "DT",
            cell: ({ row }) => {
                return (
                    <CheckboxInput
                        originalRow={row?.original}
                        checkboxType="DT" />
                )
            },
            filterType: "dropdown",
            options: DToptions
        },
        {
            header: 'PM',
            accessorKey: "PM",
            cell: ({ row }) => {
                return (
                    <CheckboxInput
                        originalRow={row?.original}
                        checkboxType="PM" />
                )
            },
            filterType: "dropdown",
            options: PMoptions
        },
    ], [frontAppData, currentPage, limit]);

    return (
        <>
            <div className='main-content'>
                <TabelHeadingTxt Title='Front App' Count={frontAppData?.count} IsExportButtonShow={false} src={Export} BtnTxt='Export' url='/front-app' ml={'80px'} />
                <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
                <FrontAppTable
                    loader={loader}
                    columns={columns}
                    data={frontAppData?.data || []}
                    TotalLength={frontAppData?.count || 15}
                    perPageCount={paginations?.limit}
                    currentPage={paginations?.currentPage}
                    onPerPageChange={(itemsPerPage: string) => {
                        const parsedQuery = queryString?.parse(location.search);
                        setSearchParams({ ...parsedQuery, limit: itemsPerPage.toString() });
                    }}
                    onPageChange={(value) => {
                        const parsedQuery = queryString.parse(location.search);
                        setSearchParams({ ...parsedQuery, currentPage: value.toString() });
                    }} />
            </div>
        </>
    )
};

export default FrontAppListing;

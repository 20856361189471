import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LuChevronRight } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Badge, Box, Button, Flex, Grid, Image, Spinner, Switch, Text } from 'theme-ui';
import { ItemType, MediaProps, RootState } from '../../../../@types';
import { GetLatestTimeById, StartTracking, UpdateTaskAttachment, changeElapsedTime, editTaskById, getAllSubtaskSummary, getHighlighterByView, getHighlighterByViewSuccess, getHistoryByView, getTaskById, getTaskByIdSuccess } from '../../../store/actions/project.action';
import theme from '../../../theme';
import { baseUrl } from '../../../utils/global';
import { TaskviewCheckBox } from '../../CheckBox/TaskviewCheckBox';
import { Inputform } from '../../forms-component/InputForm';
import FormSelect from '../../forms-component/globalSelect';
import SideActions from '../../project-components/sideActions';
import plyBtn from "./playBtn.svg";
import stop from "./stop.svg";
import stopWatch from "./stopWatch.svg";
import stopWatchWhite from "./stopwatch-white.svg";
import UploadMediaFile from '../../UploadMediaFile/UploadMediaFile';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { EstimatedTimeModal } from './EstimatedTimeModal/EstimatedTimeModal';
import { useQuery } from '@tanstack/react-query';
import { getTimeDifference, millisecondsToTime, msToTime } from '../../../utils/GenerateQueryString';
import { debounce } from 'lodash';
import TimeClosePop from './TimeClosePop/TimeClosePop';
import editIcon from "../../../Components/assets/images/edit.svg";
import { ActionModule } from '../../Actions/ActionModule/ActionModule';
import { ActionHourADay } from '../../Actions/ActionModule/ActionHourADay';
import { removeHighlightFromTask } from '../../../store/actions/action.Highlighters';
import toast from 'react-hot-toast';
import ActionBackgroundCheck from '../../Actions/ActionModule/ActionBackgroundCheck';
import MarkDownParagraph from '../../forms-component/MarkDownParagraph';
import { IoLogOutOutline } from 'react-icons/io5';
import FolderNumberDropdown from '../create project /StepOne/folderNumberDropdown';
import { statusColors } from '../../../utils/constent';
interface editModelProps {
    onModelClose: () => void;
    taskId: string;
    isOpen: boolean;
}
const DoubleClickModalTask = ({ isOpen, toggle, highlight }: any) => {
    const dispatch = useDispatch();
    const { taskId } = useParams<{ taskId: string }>();
    const handleSwitchChangeTask = () => {
        if (highlight?.name) {
            const payload = {
                highlighterName: highlight?.name
            }
            dispatch(removeHighlightFromTask(payload, taskId,));
            toggle();
        }
    };
    return (

        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Acknowledgement</ModalHeader>
            <ModalBody>
                <Box><Switch sx={{ position: 'absolute', top: '17px', right: '8px' }} onChange={handleSwitchChangeTask} /></Box>
                <Text>Are you sure you want to remove Highlighters?</Text>
            </ModalBody>

        </Modal>
    )
};

const EditTaskView: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { taskId } = useParams<{ taskId: string }>();
    const { auther } = useSelector((state: RootState) => state.auth);
    const { createprojectMasterData, loader, tasksView, sowfields, SowChecklist, elapsedTime, stageAllTaskView, highlighters: Allhighlighters } = useSelector((state: any) => state.project);
    const { uploadedMedia } = useSelector((state: MediaProps) => state?.media);
    const stageId = tasksView?.stageDataId;
    const projectId = tasksView?.projectId?._id;
    const { Latesttime } = useSelector((state: RootState) => state.project);
    const [description, setDescription] = useState<string>('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setModalOpen] = useState(true);
    const [isDoubleClickModalOpen, setDoubleClickModalOpen] = useState<boolean>(false);
    const [isTracking, setIsTracking] = useState<any>(null);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
    // const [elapsedTime, setElapsedTime] = useState("00:00:00");
    const [timeTaken, setTimeTaken] = useState(0);
    const [highlighters, setHighlighters] = useState<object[]>([]);
    const [checkListItem, setCheckListItem] = useState<{ default: boolean, _id: string }[]>([]);
    const [estimatedOpen, setEstimatedOpen] = useState(false)
    const SowFields = sowfields?.filter((entry: any) => entry?.taskName === tasksView?.taskId?._id);
    const intervalRef = useRef<any>(null);
    const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [taskStatus, setTaskStatus] = useState<keyof typeof statusColors | "">("");
    const [taskDescription, setTaskDiscription] = useState("");
    const [actionObjects, setActionObjects] = useState<any>(null);
    const [spendTime, setSpendTime] = useState<any>(0);
    const [timeStatusColor, setTimeStatusColor] = useState<any>("");
    const projectManagers = createprojectMasterData?.allTaskManager || [];
    const projectWorkers = createprojectMasterData?.allTaskWorkers || [];
    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    useEffect(() => {
        if (tasksView?.status) {
            setTaskStatus(tasksView?.status);
        }
    }, [tasksView?.status])
    useEffect(() => {
        if (tasksView?.description) {
            setTaskDiscription(tasksView?.description);
        }
    }, [tasksView?.status])
    const estimatedClose = () => {
        setEstimatedOpen(false);
    };

    // const debouncedDispatch = debounce(
    //     (fieldName: string, value: any) => dispatch(editTaskById({ [fieldName]: value }, taskId, fieldName)),
    //     500)
    const debouncedDispatch = debounce(
        (fieldName: string, value: any) => {
            dispatch(editTaskById({ [fieldName]: value }, taskId, fieldName));
            dispatch(getHistoryByView(`taskId=${taskId}`))

        },
        500
    );
    const debounceRequest = useCallback((fieldName: string, value: any) => {
        debouncedDispatch(fieldName, value)
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        return () => {
            debouncedDispatch.cancel()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (projectId && stageId && taskId) {
            setActionObjects({ projectId, stageId, taskId });
        }
    }, [projectId, stageId, taskId]);
    useEffect(() => {
        dispatch(getTaskById(taskId));

    }, [dispatch, taskId]);
    useEffect(() => {
        if (actionObjects) {
            dispatch(getAllSubtaskSummary(actionObjects))
        }
    }, [actionObjects])
    useEffect(() => {
        if (uploadedMedia !== null || timeTaken) {
            dispatch(getTaskById(taskId));
        }
    }, [uploadedMedia, timeTaken])

    useEffect(() => {
        dispatch(getHighlighterByView("task"));
        return () => {
            dispatch(getHighlighterByViewSuccess([]));
            dispatch(getTaskByIdSuccess([]));
        }
    }, [])

    const handleEditClick = () => {
        setIsEditing(true);
    };
    const handleSaveClick = () => {
        debounceRequest('description', description);
        setIsEditing(false);
    };
    const handleCancelClick = () => {
        setDescription(tasksView?.description || '');
        setIsEditing(false);
    };
    useQuery({
        queryKey: ['taskView', taskId],
        queryFn: () => {
            dispatch(GetLatestTimeById(taskId))
            setModalOpen(true);
        },
        refetchOnWindowFocus: true,
        staleTime: 0
    })
    const closeModal = () => {
        navigate(-1)
        setModalOpen(false);
    };

    const toggleDoubleClickModal = () => {
        setDoubleClickModalOpen(!isDoubleClickModalOpen);
    };
    useEffect(() => {
        // Initialize checkedItems with initially checked items from tasksView.sowMapping
        if (tasksView && tasksView.highlighters) {
            setHighlighters(tasksView.highlighters);

        }
    }, [tasksView.highlighters, tasksView.sowMapping]);
    useEffect(() => {

        if (tasksView && tasksView.highlighters) {

            setDescription(tasksView.description || '');
        }
    }, [tasksView.description, tasksView.highlighters]);

    useEffect(() => {
        // console.log(SowChecklist, "check101");
        if (SowChecklist?.length > 0 && checkListItem?.length == 0) {
            setCheckListItem(SowChecklist?.filter((entry: any) => entry?.taskType === tasksView?.taskId?._id))
        }
    }, [SowChecklist, tasksView])
    useEffect(() => {
        // console.log(checkListItem, "check107");
        if (checkListItem?.length > 0) {
            let defaultSelectedCheckboxes;
            if (tasksView?.sowMapping?.length > 0) {
                defaultSelectedCheckboxes = tasksView?.sowMapping
            } else {
                defaultSelectedCheckboxes = checkListItem?.filter((value) => value?.default)?.map((val) => val._id)
            }
            // console.log(defaultSelectedCheckboxes, "check106", tasksView);
            setSelectedCheckboxes(defaultSelectedCheckboxes)
        }
    }, [checkListItem]);
    const handleCheckboxChange = (isChecked: boolean, item: ItemType) => {
        const itemId = item._id;
        let checkBox = [...selectedCheckboxes];
        if (isChecked) {
            checkBox.push(itemId)
        } else {
            checkBox = checkBox?.filter((val) => val !== itemId)
        }
        setSelectedCheckboxes(checkBox)
        dispatch(editTaskById({ "sowMapping": checkBox }, taskId, "sowMapping"))
    };

    const handleTextareaChange = (event: any) => {
        setDescription(event.target.value);
    };

    useEffect(() => {
        if (Latesttime?.startTime && taskId === Latesttime?.taskId && Latesttime?.userId?._id === auther?._id) {
            setIsTracking(true)
        } else {
            setIsTracking(false)
        }
    }, [Latesttime]);
    useEffect(() => {
        if (tasksView?.timeRemaining) {
            setSpendTime(tasksView.timeRemaining);
        }
    }, [tasksView?.timeRemaining])
    useEffect(() => {
        if (isTracking) {
            intervalRef.current = setInterval(() => {
                const newFormatTime = millisecondsToTime(getTimeDifference(Latesttime?.startTime));
                setSpendTime((time: any) => time - 1000)
                dispatch(changeElapsedTime(newFormatTime));
            }, 1000);
        } else if (intervalRef.current) {
            setIsTracking(false)
            clearInterval(intervalRef.current);
        }

        // Cleanup on unmount
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [isTracking]);
    useEffect(() => {
        if (spendTime) {
            timeStatuses(spendTime)
        }
    }, [spendTime])
    const timeStatuses = (spendTime: any) => {
        if (spendTime > 0) {
            const completed = Math.floor(spendTime / tasksView?.timeEstimate * 100);
            if (completed > 30) {
                setTimeStatusColor('#56F000')
            } else if (completed < 30 && completed > 15) {
                setTimeStatusColor('#FCE83A')
            } else if (completed < 15 && completed > 5) {
                setTimeStatusColor('#FFB302');
            } else if (completed < 5) {
                setTimeStatusColor('#FF3838');
            } else {
                console.log("Not a Color!");

            }
        }

    }

    const handleStartTracking = () => {
        const isEstimateRequired = tasksView?.status === "IPR" || tasksView?.status === "Req Time Estimate";
        const hasValidTime = tasksView?.timeEstimate > 0 && tasksView?.timeRemaining > 0;

        if (hasValidTime || (isEstimateRequired && tasksView?.timeEstimate <= 0 && tasksView?.timeRemaining <= 0)) {
            clearInterval(intervalRef.current);
            dispatch(changeElapsedTime("00:00:00"));
            const payload = {
                projectId: tasksView?.projectId?._id,
                stageId: tasksView?.stageDataId,
                taskId: tasksView?._id,
                category: 'Task timer'
            };

            dispatch(StartTracking(payload));
        } else {
            toast.error("To begin tracking, please add a time estimate.", {
                duration: 4000,
            });
        }
    };


    const handleStopTracking = () => {
        if (isTracking !== null) {
            clearInterval(intervalRef.current);
            setIsTracking(null);
        }
        openPopup();
    };
    const handleSseChange = (data: { [key: string]: any }) => {
        Object.keys(data)?.forEach((element: string) => {
            if (element === "sowMapping") {
                setSelectedCheckboxes(data[element]);
            }
            if (element === "status") {
                setTaskStatus(data[element] as keyof typeof statusColors);

                if (tasksView.status !== data[element]) {
                    dispatch(getTaskById(taskId));
                }
            }
            if (element === "description") {
                setTaskDiscription(data[element]);
            }
            if (element === "highlighters") {
                handleHighlightersUpdate(data[element]);
            }

            formik.setFieldValue(`${element}`, data[element], true);
        });
    }
    const handleHighlightersUpdate = (data: any) => {
        setHighlighters((prev) => Array.isArray(data) ? [...data] : [])
    }

    const { data: eventSource } = useQuery({
        queryKey: ['taskSSE', taskId],
        queryFn: () => {
            const eventSource = new EventSource(`${baseUrl}sse/${taskId}`);

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    // console.log(data, "check108");
                    handleSseChange(data);
                    dispatch(UpdateTaskAttachment(data.attachments))
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };

            eventSource.onerror = (error) => {
                console.error('SSE Error:', error);
                eventSource.close();
            };
            return eventSource;
        },
        refetchInterval: 60000
    })
    useEffect(() => {
        return () => {
            eventSource?.close();
            clearInterval(intervalRef.current);
        };
    }, [])

    const formik = useFormik(
        {
            enableReinitialize: true,
            initialValues: {
                description: tasksView?.description || "",
                manager: tasksView?.manager,
                name: tasksView?.name,
                folderNumber: tasksView?.projectId?.folderNumber,
                projectId: {
                    _id: tasksView?.projectId?._id,
                    name: tasksView?.projectId?.name
                },
                taskFields: tasksView?.taskFields,
                worker: tasksView?.worker,
                _id: tasksView?._id
            },
            onSubmit: () => { }
        }
    );
    const { handleSubmit, setFieldValue, handleBlur, touched, values, errors } = formik;
    const { hours, minutes, seconds } = msToTime(tasksView?.timeRemaining)

    // implement Highlight
    const [Open, setOpen] = useState(false);
    const [selectedHighlight, setSelectedHighlight] = useState<any>(null);
    const [highlight, setHighlight] = useState<any>(null);

    const handleHighlightClick = (highlight: any) => {
        if (clickTimeoutRef.current) {
            clearTimeout(clickTimeoutRef.current);
            clickTimeoutRef.current = null;
            const dblclickHighlights = ['Background Change', 'Background Change InProgress', 'Background Change Done', 'Revision', 'Plan Check',
                'New Info Added', 'Need Info', 'Internal Review', 'Todo Per Review', 'Final Review', 'Ready To Deliver',
                'Hour a Day', 'Blocked By Trade', 'Waiting for Trade', 'Blocked By Trade Completed', 'Blocked by Info',
                'Delivery Notes', ' Deliver', 'Project Setup', ' Waiting for Payment - FULL', ' Waiting for Payment - PRELIM',]
            setHighlight(dblclickHighlights?.includes(highlight?.name) ? { ...highlight } : highlight);
            setDoubleClickModalOpen(true);

        } else {
            clickTimeoutRef.current = setTimeout(() => {
                clickTimeoutRef.current = null;
                const backgroundChangeGroups = ["Background Change Done", "Background Change InProgress"];
                const blockedBytradeGroup = ["Blocked By Trade Completed", "Waiting for Trade"];
                const finalReview = tasksView?.highlighters.find((highlight: any) => highlight?.name === "Final Review")
                const todoPerReview = tasksView?.highlighters.find((highlight: any) => highlight?.name === "Todo Per Review")

                let newSelectedHighlight = { ...highlight };

                if ((finalReview?.name === "Final Review" && todoPerReview?.name === "Todo Per Review") && ["Todo Per Review",
                    "Final Review"].includes(highlight.name)) {
                    newSelectedHighlight = Allhighlighters?.find((item: any) => item?.name === "Final Review");
                } else if (highlight?.name === "Todo Per Review") {
                    newSelectedHighlight = Allhighlighters?.find((item: any) => item?.name === "Internal Review");
                } else {
                    newSelectedHighlight = backgroundChangeGroups.includes(highlight?.name)
                        ? { ...highlight, name: "Background Change" }
                        : blockedBytradeGroup.includes(highlight?.name)
                            ? { ...highlight, name: "Blocked By Trade" }
                            : highlight;
                }


                setSelectedHighlight(newSelectedHighlight);
                setOpen(true);
            }, 300);
        }
    };
    const taskIndex = stageAllTaskView?.findIndex((task: any) => task._id === taskId) + 1;

    const customProps_ = {
        selectedHighlight, Open, setOpen, actionObject: { projectId: tasksView?.projectId?._id, stageId: tasksView?.stageDataId, taskId: tasksView?._id }, activeTab: taskIndex, TaskView: stageAllTaskView?.map((el: any) => ({ id: el._id, ...el }))
    }
    const cursorPointerGroups = ["Hour a Day", "Internal Review", 'Todo Per Review', 'Blocked by Info', "Delivery Notes", 'Ready To Deliver', ' Deliver',
        "Blocked By Trade", 'Blocked By Trade Completed', ' Waiting for Payment - FULL', ' Waiting for Payment - PRELIM',
        'Waiting for Trade', "Need Info", "New Info Added", "Final Review", "Plan Check", "Revision", "Background Change", "Background Change Done", "Background Change InProgress",];
    const estimateTimeHandler = () => {
        if (isTracking) {
            toast.dismiss();
            toast.error("Time tracking in progress, So manage estimated time are disabled.", {
                duration: 4000,
            });
        } else {
            setEstimatedOpen(true)
        }

    }

    return (
        <>
            (
            <Modal isOpen={isModalOpen} scrollable toggle={closeModal} centered style={{ maxWidth: '93%' }}>
                <ModalHeader>
                    <Grid gap={2} columns={[2, '2.2fr auto']} sx={{ width: "100%" }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: "wrap",
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <Flex sx={{
                                gap: '8px',
                                alignItems: 'center',
                            }}>
                                <Link className='text-dark ' to={`/edit-project/${tasksView?.projectId?._id}`}>{tasksView?.projectId?.name}</Link>
                                <LuChevronRight />{' '}
                                <Link style={{ color: tasksView?.stageId?.color }} to={`/edit-stages/${tasksView?.stageDataId}`} >{tasksView?.stageId?.name}</Link>
                                <LuChevronRight />{' '}
                                <Link to={''} style={{ color: tasksView?.taskId?.color }}>{tasksView?.name}</Link>
                            </Flex>
                            <Flex sx={{
                                gap: '8px',
                                alignItems: 'center',
                            }}>
                                {highlighters?.map((badge: any) => (

                                    <Badge
                                        key={badge?._id}
                                        color={badge?.color}
                                        sx={{
                                            backgroundColor: 'transparent',
                                            borderRadius: "8px",
                                            gap: '3px',
                                            padding: '4px 8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            border: `1px solid ${badge?.color}`,
                                            cursor: `${cursorPointerGroups.includes(badge.name) ? "pointer" : "default"}`
                                        }}
                                        onClick={(e: any) => {
                                            handleHighlightClick(badge);
                                        }}
                                    >
                                        {badge?.icon && <Image
                                            title={badge.name}
                                            src={badge?.icon}
                                            alt={badge?.name}
                                            width={14}
                                            height={14}
                                        />}
                                        <Text sx={{ color: `${badge?.color}` }}>{badge?.name}</Text>
                                    </Badge>
                                ))}
                            </Flex>
                        </Box>
                        <Flex sx={{ justifyContent: 'end', gap: '0.5rem', alignItems: "center" }}>
                            <Button variant='startButton' onClick={estimateTimeHandler}>
                                <img src={stopWatch} alt='' />
                                {hours ? `${hours} hrs` : "00"} {minutes > 0 && `${minutes} min`} {seconds > 0 && `${seconds} sec`} pending/{msToTime(tasksView?.timeEstimate)?.hours || '00'} hrs {msToTime(tasksView?.timeEstimate)?.minutes || '00'} min<img src={plyBtn} alt='' />
                            </Button>
                            {(isTracking && Latesttime?.userId?._id === auther?._id) ?
                                <>
                                    <Button variant='stopButton' onClick={handleStopTracking}><img src={stop} className='me-2' />Stop</Button>
                                    <Button variant='timetrack'>
                                        <img src={stopWatchWhite} alt='' /> {elapsedTime}
                                    </Button>
                                </>
                                : <>
                                    <Button variant='startButton' onClick={handleStartTracking}>
                                        <img src={stopWatch} alt='' /> Start Tracking <img src={plyBtn} alt='' />
                                    </Button>
                                </>
                            }
                            {loader && <Spinner size={30} className='mx-2' />}
                            <button type="button" className="btn-close mx-2" aria-label="Close" onClick={closeModal}></button>

                        </Flex>
                    </Grid>
                    <Box >
                        <Badge
                            sx={{
                                backgroundColor: `${statusColors[taskStatus as keyof typeof statusColors] || `${theme?.colors?.muted}`}`,
                                border: `1px solid ${statusColors[taskStatus as keyof typeof statusColors] || `${theme?.colors?.muted}`}`,
                                borderRadius: '8px',
                                padding: '4px 8px',
                                color: 'white',
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            {taskStatus}
                        </Badge>
                        {/* <Text variant='Poppins16Normal25lh' sx={{ fontWeight: 'normal' }}>{taskStatus}</Text> */}

                        {tasksView?.trelloId && <a target='_blank' href={`https://trello.com/c/${tasksView?.trelloId}`} style={{
                            position: 'absolute',
                            right: "20px",
                            width: "150px",
                            background: "#7743db",
                            color: "#ffffff",
                            textDecoration: "none",
                            padding: "5px",
                            marginTop: "4px",
                            borderRadius: "5px",
                            textAlign: "center",
                            fontSize: "large"
                        }}>{"Go to Trello"}<IoLogOutOutline className='ms-2 fs-3' /></a>}
                    </Box>
                </ModalHeader>
                <ModalBody className="p-0">
                    <>
                        <Flex sx={{ flexDirection: 'row' }}>
                            <Box sx={{ width: '70%', maxHeight: '845px', overflow: 'auto' }}>
                                <Box className="p-3">
                                    <Box className="p-3">
                                        <TaskviewCheckBox
                                            selectedData={selectedCheckboxes}
                                            title={''}
                                            Option={checkListItem}
                                            onChange={(isChecked: boolean, item: ItemType) => handleCheckboxChange(isChecked, item)}
                                        />

                                    </Box>
                                </Box>
                                <Box className="p-3">
                                    <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Text variant="SelectLabel">Description</Text>
                                        {!isEditing && (
                                            <Image
                                                src={editIcon}
                                                sx={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                                onClick={handleEditClick}
                                            />
                                        )}
                                    </Flex>
                                    {isEditing ? (
                                        <>
                                            <textarea
                                                className="form-control"
                                                value={description}
                                                onChange={handleTextareaChange}
                                                placeholder="Description..."
                                                rows={description.length > 30 ? 10 : 2}
                                            />
                                            <Flex className="mt-3" sx={{ justifyContent: 'end', gap: '8px' }}>
                                                <Button
                                                    sx={{
                                                        background: 'white',
                                                        color: theme?.colors?.primary,
                                                        border: '1px solid',
                                                        textAlign: 'center',
                                                        borderRadius: '12px',
                                                    }}
                                                    onClick={handleCancelClick}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    sx={{ background: theme?.colors?.primary, textAlign: 'center', borderRadius: '12px' }}
                                                    onClick={handleSaveClick}
                                                >
                                                    Save
                                                </Button>
                                            </Flex>
                                        </>
                                    ) : (
                                        // <textarea
                                        //     className="form-control"
                                        //     value={description}
                                        //     readOnly
                                        //     rows={5}
                                        //     style={{ flexGrow: 1 }}
                                        // />
                                        <Box className='ps-2' sx={{ minHeight: `${description.length < 30 ? "72px" : "265px"}`, maxHeight: `${description.length > 30 ? "265px" : "72px"}`, border: "2px solid rgb(247, 239, 229)", overflowY: "auto", borderRadius: "4px" }}>

                                            <MarkDownParagraph markdown={description} />
                                        </Box>
                                    )}
                                </Box>

                                <Grid className="p-3"
                                    sx={{ gap: '1rem', marginTop: 3 }}
                                    columns={[1, 2, 2, 3, 4, 4]}
                                >

                                    {SowFields?.map((sowfield: any, index: number) => (
                                        <Box key={index}>
                                            {sowfield?.type === 'select' && sowfield?.fieldName === 'Task Worker' ? (
                                                <FormSelect
                                                    Name=''
                                                    options={projectWorkers?.map((projectWorker: { FirstName: string; LastName: string; _id: any; }) => ({
                                                        label: `${projectWorker?.FirstName} ${projectWorker?.LastName}`,
                                                        value: projectWorker?._id
                                                    })) || []}
                                                    label={sowfield?.fieldName}
                                                    placeholder={sowfield?.fieldName}
                                                    value={values?.worker?._id}
                                                    onBlur={handleBlur}
                                                    onChange={(selectedOption: any) => {
                                                        setFieldValue(`worker`, selectedOption?.value || '');
                                                        debounceRequest('worker', selectedOption?.value);
                                                    }}
                                                />
                                            ) : sowfield?.type === 'select' && sowfield?.fieldName === 'Task Manager' ? (
                                                <FormSelect
                                                    options={projectManagers.map((projectManager: { FirstName: any; LastName: string; _id: any; }) => ({
                                                        label: `${projectManager?.FirstName} ${projectManager?.LastName}`,
                                                        value: projectManager?._id
                                                    })) || []}
                                                    placeholder={sowfield?.fieldName}
                                                    label={sowfield?.fieldName}
                                                    onBlur={handleBlur}
                                                    value={values?.manager}
                                                    onChange={(selectedOption: any) => {
                                                        setFieldValue(`manager`, selectedOption?.value || '');
                                                        debounceRequest('manager', selectedOption?.value);
                                                    }}
                                                />
                                            ) : (
                                                <Inputform
                                                    type={sowfield?.type}
                                                    label={sowfield?.fieldName}
                                                    Placeholder={sowfield?.fieldName}
                                                    Name={`tasksView.taskFields[${sowfield?.fieldName}]`}
                                                    Title_sx={{ color: 'grey' }}
                                                    // values={values?.taskFields?.[0]?.[sowfield?.fieldName]}
                                                    values={values?.taskFields?.[0]?.[sowfield?.fieldName]}
                                                    // values={values?.taskFields && values?.taskFields[0][sowfield?.fieldName]}
                                                    onBlur={handleBlur}
                                                    OnChange={(event: any) => {
                                                        const newName = event.target.value;
                                                        const fieldName = `taskFields.0.${sowfield?.fieldName}`;
                                                        setFieldValue(fieldName, newName, true);
                                                        debounceRequest(fieldName, newName);
                                                    }}
                                                />


                                            )}
                                        </Box>
                                    ))}
                                    <Box>
                                        <FolderNumberDropdown
                                            label={"Folder Number"}
                                            value={values?.folderNumber}
                                            onBlur={handleBlur}
                                            onChange={(selectedFolderNumber: any) => {
                                                setFieldValue("folderNumber", selectedFolderNumber, true);
                                                if (!loader) {
                                                    debounceRequest('folderNumber', selectedFolderNumber);
                                                }
                                            }}

                                            disable={true}
                                        />

                                    </Box>
                                </Grid>
                                {/* <Tabs className="p-3"> */}
                                <Tabs className="p-3">
                                    <TabList>
                                        <Tab>Details</Tab>
                                    </TabList>
                                    <TabPanel>
                                        <UploadMediaFile mediaData={tasksView?.attachments || []} typeId={{
                                            task_id: taskId || '', stage_id: stageId || '', project_id: projectId || '',
                                        }} type="task" loader={loader} id={taskId} />
                                    </TabPanel>
                                </Tabs>

                            </Box>
                            <SideActions isTracking={isTracking} elapsedTime={elapsedTime} timeTaken={timeTaken} timeEstimate={tasksView?.timeEstimate} taskId={taskId} stageId={stageId} projectId={projectId} threads={tasksView.threads
                            } timeStatusColor={timeStatusColor} />
                        </Flex>
                    </>
                    <TimeClosePop isPopupOpen={isPopupOpen} closePopup={closePopup} setTimeTaken={setTimeTaken} />
                </ModalBody>

            </Modal >
            )
            <EstimatedTimeModal timeTaken={tasksView?.timeSpend} timeEstimate={tasksView?.timeEstimate || '00'} estimatedOpen={estimatedOpen} estimatedClose={estimatedClose} isTracking={isTracking} />
            {selectedHighlight?.name === "Hour a Day" && <ActionHourADay {...customProps_} keys={{ first: "blockingTaskId" }} />}
            {selectedHighlight?.name === "Internal Review" && <ActionModule {...customProps_} keys={{ first: "isChecked" }} />}
            {selectedHighlight?.name === "Delivery Notes" && <ActionModule {...customProps_} keys={{ first: "isChecked", second: "isInternal" }} />}
            {selectedHighlight?.name === "Blocked By Trade" && <ActionModule {...customProps_} keys={{ first: "isChecked", second: "blockingTaskId" }} />}
            {selectedHighlight?.name === "Need Info" && <ActionModule {...customProps_} keys={{ first: "isChecked", second: "isBlockByInfo" }} />}
            {selectedHighlight?.name === "New Info Added" && <ActionModule {...customProps_} keys={{ first: "isChecked" }} handleHighlightClick={handleHighlightClick} />}
            {selectedHighlight?.name === "Final Review" && <ActionModule {...customProps_} keys={{ first: "isChecked" }} />}
            {selectedHighlight?.name === "Todo Per Review" && <ActionModule {...customProps_} keys={{ first: "isChecked" }} />}
            {selectedHighlight?.name === "Plan Check" && <ActionBackgroundCheck {...customProps_} keys={{ first: "isChecked", second: "isInternal" }} showAddNewButton={false} />}
            {selectedHighlight?.name === "Revision" && <ActionBackgroundCheck {...customProps_} keys={{ first: "isChecked", second: "isInternal" }} showAddNewButton={false} />}
            {selectedHighlight?.name === "Background Change" && <ActionBackgroundCheck {...customProps_} keys={{ first: "isChecked" }} showAddNewButton={false} />}
            <DoubleClickModalTask isOpen={isDoubleClickModalOpen} toggle={toggleDoubleClickModal} highlight={highlight} />

        </>
    );
};

export default EditTaskView;

import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Box, Checkbox, Flex, Label, Text, Textarea } from 'theme-ui';
import { deleteActionHighlight, editActionHighlight } from '../../../store/actions/action.Highlighters';
import deleteIcon from "../../../Components/assets/images/Icon/delete.svg";
import editIcon from "../../../Components/assets/images/edit.svg";
import { FaMinus, FaPlus } from 'react-icons/fa';
import { DeletePopModule } from '../../DeletePopModule/DeletePopModule';
import AddCommentAction from './AddCommentAction';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RootState } from '../../../../@types';
import CustomTooltip from '../../CustomTooltip';
import { rolePermissionsHighLighters } from '../../../utils/CustomHandler';
import theme from '../../../theme';
import { useParams } from 'react-router-dom';

export const ActionCard = ({ index, action, selectedHighlight, keys, actionObject }: any) => {
  const { actionHighlights } = useSelector((state: any) => state.actionHighlight)
  const { stageAllTaskView } = useSelector((state: any) => state.project)
  const { auther } = useSelector((state: RootState) => state.auth);
  const is_Task_Worker = auther?.Role?.map(role => role?.RoleName).every(role => role.toLocaleLowerCase() === "task worker")
  const { stageId } = useParams<{ stageId: string }>();
  console.log('chk26', selectedHighlight);

  const dispatch = useDispatch();
  const [chatOpen, setChatOpen] = useState(false);
  const [selected, setSelected] = useState<number>();
  const [updateName, setUpdateName] = useState<string>();
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const blockedByTrade = stageAllTaskView?.map((taskView: any) => ({ label: taskView?.name, value: taskView?._id }))
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isStageMatch = stageId === actionHighlights?.stageId;


  const updateActionHighlight = (objKey: string, updatedValue: boolean) => {
    const payload = {
      name: action?.name,
      blockingTaskId: action?.blockingTaskId,
      ...actionObject,
      actionType: selectedHighlight?.name,

      data: {
        [objKey]: updatedValue,
      }
    }
    dispatch(editActionHighlight(payload, actionHighlights?._id))
  }
  const editActionHighlightName = () => {
    const payload = {
      name: action?.name,
      ...actionObject,
      data: {
        name: updateName,
      }
    }
    dispatch(editActionHighlight(payload, actionHighlights?._id))
  }

  const deleteCheckAction = () => {
    dispatch(deleteActionHighlight({ id: deleteData }, actionHighlights?._id))
  }
  useEffect(() => {
    if (action?.name) {
      setUpdateName(action?.name)
    }
  }, [action?.name])

  useEffect(() => {
    // chatOpen
    if (action?.comment?.length > 0) {
      setChatOpen(!chatOpen);
      setSelected(index);
    }
  }, [index])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px `;
      textareaRef.current.style.marginTop = "20px";
    }
  }, [updateName]);

  return (
    <Box sx={{ display: "grid", paddingLeft: "0.5rem", borderLeft: `2px solid ${selectedHighlight?.color}`, gap: "1rem", }} >
      <Flex sx={{ gap: "1rem", }}>
        <Flex sx={{
          background: `${theme?.colors?.whitesmoke}`,
          paddingLeft: "12px",
          paddingRight: "20px",
          borderRadius: "8px",
          gap: "10px",
          width: "100%",
          alignItems: "center",
          position: "relative",
          pointerEvents: 'auto'
          // pointerEvents: `${action[keys?.first] ? "none" : "auto"}`
        }}>
          {selectedHighlight?.name === "Subtask" &&
            ((is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>Permission Denied</Tooltip>}>

              <Label sx={{ cursor: "not-allowed", width: "auto" }}>
                <Checkbox
                  name={`${keys?.third}${index}`}
                  id={`${keys?.third}${index}`}
                  checked={action[keys?.third] || false}
                  disabled={true}
                />
              </Label>
            </OverlayTrigger> :
              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>Is Reviewed</Tooltip>}>

                <Label sx={{ cursor: "pointer", width: "auto" }}>
                  <Checkbox
                    name={`${keys?.third}${index}`}
                    id={`${keys?.third}${index}`}
                    checked={action[keys?.third] || false}
                    // disabled={action[keys?.third] || false}
                    value={action[keys?.third] || false}
                    onChange={(e) => updateActionHighlight(keys?.third, e.target.checked)}
                  />
                </Label>
              </OverlayTrigger>)}

          {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>Permission Denied</Tooltip>}>
            <Label sx={{ cursor: "not-allowed", width: "auto" }}>
              <Checkbox
                name={`${keys?.first}${index}`}
                id={`${keys?.first}${index}`}
                checked={action[keys?.first] || false}
                disabled={true}
              // value={action[keys?.first] || false}
              // onChange={(e) => updateActionHighlight(keys?.first, e.target.checked)}
              />
            </Label>
          </OverlayTrigger> :
            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>{keys?.first?.includes("isChecked") ? "Is Checked" : "Is done"}</Tooltip>}>
              <Label sx={{
                cursor:
                  !isStageMatch &&
                    ["Revision", "Background Change", "Plan Check"]?.includes(selectedHighlight?.name)
                    ? "not-allowed"
                    : "pointer",
                width: "auto"
              }}>
                <Checkbox
                  name={`${keys?.first}${index}`}
                  id={`${keys?.first}${index}`}
                  checked={action[keys?.first] || false}
                  disabled={
                    !isStageMatch &&
                    ["Revision", "Background Change", "Plan Check"]?.includes(selectedHighlight.name)
                  }                  // disabled={action[keys?.first] || false}
                  value={action[keys?.first] || false}
                  onChange={(e) => updateActionHighlight(keys?.first, e.target.checked)}
                />
              </Label>
            </OverlayTrigger>}

          {isStageMatch && (is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? <Textarea
            variant="PersonalDetailsInput"
            className="form-control"
            id={`checkList${index}`}
            sx={{
              '&:disabled ': {
                backgroundColor: `${theme?.colors?.transparent}`,
              }, backgroundColor: `${theme?.colors?.transparent}`, borderColor: `${theme?.colors?.transparent} !important`,
            }}
            value={updateName}
            disabled={false}
          /> :
            <Textarea
              ref={textareaRef}
              variant="PersonalDetailsInput"
              className="form-control"
              id={`checkList${index}`}
              disabled={!isStageMatch &&
                ["Revision", "Background Change", "Plan Check"]?.includes(selectedHighlight.name)}
              // disabled={true}
              sx={{
                '&:disabled ': {
                  backgroundColor: `${theme?.colors?.transparent}`,
                }, backgroundColor: `${theme?.colors?.transparent}`, borderColor: `${theme?.colors?.transparent} !important`,
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem'

              }}
              // placeholder={action?.name}
              value={updateName}
              // disabled={isEditing}
              onChange={(e) => setUpdateName(e.target.value)}
              onBlur={() => {
                editActionHighlightName();
                setIsEditing(true);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  editActionHighlightName();
                  setIsEditing(true);
                }
              }}
            />}


          <Flex sx={{ marginLeft: "auto", gap: "1rem" }}>
            {/* Conditionally render the edit icon */}
            {!(isStageMatch === false && ["Revision", "Background Change", "Plan Check"]?.includes(selectedHighlight?.name)) && (

              (is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? (
                <CustomTooltip id={`checkListdisabled${index}`} title={is_Task_Worker && "Permission Denied"}>
                  <Label htmlFor={`checkList${index}`} sx={{ cursor: "not-allowed" }}>
                    <img src={editIcon} alt="edit" />
                  </Label>
                </CustomTooltip>
              ) : (
                <Label
                  htmlFor={`checkList${index}`}
                  sx={{ cursor: "pointer" }}
                  onClick={() => setIsEditing(false)}
                >
                  <img src={editIcon} alt="edit" />
                </Label>
              )

            )}

            {/* Conditionally render the delete icon */}
            {!(isStageMatch === false && ["Revision", "Background Change", "Plan Check"]?.includes(selectedHighlight?.name)) &&
              (
                (is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-${index}`}>Permission Denied</Tooltip>}
                  >
                    <Text sx={{ cursor: "not-allowed", pointerEvents: "none" }}>
                      <img src={deleteIcon} alt="delete" width={"23px"} />
                    </Text>
                  </OverlayTrigger>
                ) : (
                  <Text
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsDeleteModelOpen(true);
                      setDeleteData(action?._id);
                    }}
                  >
                    <img src={deleteIcon} alt="delete" width={"23px"} />
                  </Text>
                )

              )}
          </Flex>


          {action[keys?.first] && <Box sx={{
            position: "absolute", inset: 0, height:           // pointerEvents: `${(action[keys?.first] && action[keys?.third]) ? "none" : "auto"}`
              "1px", background: `${theme?.colors?.black}`, top: "50%"
          }}></Box>}
        </Flex>
        {selectedHighlight?.name === "Blocked By Trade" ?
          <Box sx={{ width: "315px" }}>
            <Select
              classNamePrefix="an-simple-select"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#F7EFE5',
                },
              })}
              isSearchable={false}
              name='blockedByTrade'
              options={blockedByTrade || []}
              defaultValue={blockedByTrade?.find((option: any) => option.value === action.blockingTaskId)}
              placeholder={"Select the trade"}
              value={blockedByTrade?.find((option: any) => option.value === action.blockingTaskId)}
              onChange={(selectedOption: any) => {
                updateActionHighlight(keys?.second, selectedOption.value)
              }}
            />
          </Box> : (keys?.second && !['Background Change', 'Plan Check', 'Revision']?.includes(selectedHighlight?.name)) && <Flex sx={{ border: "1px solid #D3D5D9", padding: "4px", borderRadius: "8px", alignItems: "center", gap: "1rem" }}>
            <OverlayTrigger placement='auto' overlay={<Tooltip id="button-tooltip-2">{selectedHighlight?.name}</Tooltip>}>
              <Text>
                <img src={selectedHighlight?.icon} alt="" width={"23px"} />
              </Text>
            </OverlayTrigger>

            {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>Permission Denied</Tooltip>}>
              <Label sx={{ cursor: "not-allowed" }}>
                <Checkbox
                  name={`${keys?.second}${index}`}
                  checked={action[keys?.second] || false}
                  value={action[keys?.second]}
                  disabled
                // onChange={(e) => updateActionHighlight(keys.second, e.target.checked)}
                />
              </Label></OverlayTrigger> :
              <Label sx={{ cursor: "pointer" }}>
                <Checkbox
                  name={`${keys?.second}${index}`}
                  checked={action[keys?.second] || false}
                  value={action[keys?.second]}
                  onChange={(e) => updateActionHighlight(keys.second, e.target.checked)}
                />
              </Label>}
          </Flex>
        }
        {['Delivery Notes', 'Background Change', 'Plan Check', 'Revision', 'Subtask']?.indexOf(selectedHighlight?.name) === -1 && (
          <Box
            sx={{
              display: "grid",
              placeContent: "center",
              width: "45px",
              height: "40px",
              borderRadius: "50%",
              background: "#F3F5F6",
              cursor: "pointer"
            }}
            onClick={() => {
              setChatOpen(!chatOpen);
              setSelected(index);
            }}
          >
            {chatOpen && selected === index ? <FaMinus /> : <FaPlus />}
          </Box>
        )}

      </Flex >
      {chatOpen && selected === index ? <AddCommentAction actionId={actionHighlights?._id} nameDec={action?.name} keys={keys} commnets={action?.comment} actionObject={actionObject} /> : null}

      <DeletePopModule setIsDeleteModelOpen={setIsDeleteModelOpen} updatedDataFun={deleteCheckAction} isDeleteModelOpen={isDeleteModelOpen} message={`Are you sure you want to delete this ${selectedHighlight?.name}`} />

    </Box >
  )
}

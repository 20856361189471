import {
    ColumnDef, ColumnFiltersState, OnChangeFn, flexRender, getCoreRowModel, getFilteredRowModel, useReactTable
} from "@tanstack/react-table";
import { Box, Spinner, Text } from "theme-ui";
import { TabelClassname } from "../../../utils/Classname";
import TablePagination from "../../Tabels/Pagination";
import FilterPopup from "../../forms-component/filterPopup";
import theme from "../../../theme";
interface DataTableProps<T extends object> {
    data: T[];
    columns: ColumnDef<T>[];
    className?: string | undefined;
    onPageChange: (page: number | any) => void,
    onPerPageChange: (value: string) => void,
    currentPage?: any,
    TotalCount?: number;
    TotalLength?: any;
    perPageCount: number,
    showNavigation?: boolean;
    enableColumnFilters?: boolean,
    fixedColumn?: boolean;
    onColumnFiltersChange?: OnChangeFn<ColumnFiltersState> | any,
    loader?: boolean;
}
const FrontAppTable = <T extends object>({
    data,
    columns,
    className = '',
    onPageChange,
    currentPage,
    TotalCount,
    showNavigation = true,
    TotalLength,
    onPerPageChange,
    perPageCount,
    enableColumnFilters = true,
    onColumnFiltersChange,
    fixedColumn,
    loader = false
}: DataTableProps<T>) => {

    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        enableColumnFilters: enableColumnFilters,
        pageCount: TotalLength / perPageCount,
        manualPagination: true,
        enableFilters: true,
        enableGlobalFilter: true,
        getFilteredRowModel: getFilteredRowModel(),
        onColumnFiltersChange: onColumnFiltersChange,
    });
    const commonPadding = localStorage.getItem("tPadding") || '13px';

    // console.log("HelloTabel", loader, data);

    return (
        <div className="table-responsive">
            <Box as="div" sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <div className={`${fixedColumn ? "tableNotFixHead" : "tableFixHead"}`} style={{ borderRadius: 10, border: `1px solid ${theme.colors?.muted}` }}>
                    <table className={`${className + TabelClassname?.PrimaryTabel} mb-0 table-hover`}>
                        <thead className="position-relative">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header, index) => {
                                        const isSubjectColumn = header.column.columnDef.header === "Subject";
                                        const isCheckColumn = header.column.columnDef.header === "DT" || header.column.columnDef.header === "PM";
                                        const isConversationColumn = header.column.columnDef.header === "Conversation";
                                        return (
                                            <th className={`th align-middle border-top-0 border-bottom-0 custom-cs-th fixed-column-${index} ${isSubjectColumn ? 'th-subject-frontapp' : ''} ${isCheckColumn ? 'th-isCheckColumn-frontapp' : ''} ${isConversationColumn ? 'th-isConversationColumn-frontapp' : ''}`}
                                                key={index}
                                                style={{ backgroundColor: `${theme?.colors?.lightbackground}`, padding: `${commonPadding}px`, textAlign: 'center', minWidth: isSubjectColumn ? "100px !important" : "" }}>
                                                {header.column.getCanFilter() &&
                                                    <FilterPopup column={header.column.columnDef} id={`column${index}`} />}
                                                <Text variant='Poppins16Normal25lh' as={"span"} className="text-center">
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </Text>
                                            </th>
                                        )
                                    })}
                                </tr>
                            ))}

                        </thead>
                        <tbody>
                            {!loader && (
                                table.getRowModel().rows.map((row: any, rowIndex) => {

                                    return (
                                        <tr key={rowIndex} className={row?.original?.DT === "Completed" ? "DTcomplete" : "in-complete"} data-state={row.getIsSelected() && "selected"}
                                            style={{
                                                overflow: 'hidden',
                                                borderBottom:
                                                    (rowIndex + 1) === table.getRowModel().rows?.length ? 'none' :
                                                        '1px solid #D3D5D9',
                                            }}
                                        >
                                            {row.getVisibleCells().map((cell: any, cellIndex: any) => (
                                                <td className={(row?.original?.DT === "Completed" ? "DTcomplete" : "in-complete") + " " +
                                                    (row?.original?.PM === "Completed" ? "PMcomplete" : "in-complete") + " " +
                                                    "front-row-" + cellIndex} key={cell.id} style={{
                                                        padding: `${commonPadding}px`, display: "gird",
                                                        placeContent: "center",
                                                    }}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                        {loader &&
                            <Spinner sx={{ position: "absolute", top: "50%", left: "50%" }} />
                        }
                        {!loader && data?.length === 0 && (
                            <Text sx={{ position: "absolute", top: "50%", left: "50%" }}>No Data Found!</Text>
                        )}


                    </table>
                </div>
            </Box>
            {showNavigation ?
                <Box className="sticky-navigation">
                    <TablePagination
                        // className="sticky-navigation"
                        onPageChange={(page) => { onPageChange(page) }}
                        onPerPageChange={onPerPageChange}
                        TotalCount={TotalCount}
                        perPageCount={perPageCount}
                        TotalLength={TotalLength}
                        CurrentPage={currentPage}

                    /></Box> : null}
        </div>
    );
};

export default FrontAppTable;


import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Export from "../../assets/images/Icon/Export.svg";

import {
    ColumnDef, Row,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable
} from '@tanstack/react-table';

import {
    KeyboardSensor,
    MouseSensor,
    TouchSensor, type DragEndEvent, useSensor,
    useSensors, DndContext,
    closestCenter
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';

import StageByTaskView from './StageByTaskView';
// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Badge, Box, Button, Divider, Flex, Paragraph, Spinner, Text } from 'theme-ui';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TablePagination from '../../Tabels/Pagination';
import queryString from 'query-string';
import { TabelClassname } from '../../../utils/Classname';
import CommentPopup from '../demotable/CommentPopup';
import FilterPopup from '../../forms-component/filterPopup';
import { TabelHeadingTxt } from '../../Text/TabelCommonTxt';
import { useDispatch } from 'react-redux';
import { getApiEndPoint } from '../../../utils/GenerateQueryString';
import { GetAllStageView, GetAllStageViewSuccess, UpdateStageRow, getAllTaskViewByStage, getStageByCustomOrder } from '../../../store/actions/project.action';
import CommentsDialogBox from '../demotable/CommentSection';
import { useQuery } from '@tanstack/react-query';
import { baseUrl } from '../../../utils/global';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import moment from 'moment';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import clockIcon from "../../../Components/assets/images/Icon/Clock.svg";

import toast from 'react-hot-toast';
import { statusColors } from '../../../utils/constent';
import { StageName } from './StageName';
import theme from '../../../theme';
import GeneralTimerStart from '../TaskView/GeneralTimerModal/GeneralTimerStart';
import { RootState } from '../../../../@types';
import { getUserbyToken } from '../../../store/actions/login.action';
//remove duplicates from Url
const removeDuplicateQueryParams = (queryString: string) => {
    const params = new URLSearchParams(queryString);
    const uniqueParams = new URLSearchParams();
    for (const [key, value] of params) {
        uniqueParams.set(key, value);
    }
    return `?${uniqueParams.toString()}`;
}
// Cell Component
const RowDragHandleCell = ({ row }: { row: any }) => {
    const { attributes, listeners } = useSortable({
        id: row.id,
    })
    return (
        <button {...attributes} {...listeners} style={{ border: "none", backgroundColor: "#ffffff" }}>
            🟰 #{window.location.href?.includes("CustomOrder1") ? row.original?.order1 : row.original?.order2}
        </button>
    )
}

const DraggableRow = React.memo(({ row }: { row: Row<any> }) => {
    const commonPadding = useMemo(() => localStorage.getItem("tPadding") || '13px', []);

    const { transform, transition, setNodeRef, isDragging } = useSortable({
        id: row.original._id,
    });

    const style = useMemo(() => ({
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative' as const,
    }), [transform, transition, isDragging]);

    return (
        <tr ref={setNodeRef} style={style} data-state={row.getIsSelected() ? "selected" : undefined}>
            {row.getVisibleCells().map((cell, cellIndex) => (
                <td
                    key={cell.id}
                    className={`fixed-column-${cellIndex} table-cell`}
                    style={{
                        padding: `${commonPadding}px`,
                        display: "gird",
                        placeContent: "center",
                        width: cell.column.getSize(),
                    }}
                >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    );
});
// const MemoizedDraggableRow = React?.memo(DraggableRow, (prevProps, nextProps) => {
//     return prevProps.row === nextProps.row;
// });

const StagesViewSecond: React.FC = () => {
    const commonPadding = localStorage.getItem("tPadding") || '13px';
    type YourDataType = {
        tasksNameList: {
            _id: string;
            color: string;
            name: string;
            shortForm: string;
        }[];
    };
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const dispatch = useDispatch()
    const { stageView, loader, highlighters, timeTracking } = useSelector((state: any) => state.project);
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    const [actionOpen, setactionOpen] = useState(false);
    const [data, setData] = React.useState([])
    const [stageDataId, setStageDataId] = useState('');
    const parsedQuery = queryString.parse(location.search);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { auther } = useSelector((state: RootState) => state.auth);
    const authToken = localStorage.getItem("token")
    const toggleModal = () => {
        setModalOpen(!modalOpen);
        dispatch(getUserbyToken(authToken))
    };
    const handleClick = () => {
        setactionOpen(true)
    };
    useEffect(() => {
        if (stageDataId) {
            dispatch(getAllTaskViewByStage(stageDataId));
        }
    }, [stageDataId])
    const [isLoadingTrue, setIsLoadingTrue] = useState(false);
    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            id: 'drag-handle',
            header: 'No',
            accessorKey: "no",
            cell: ({ row }: any) => (
                <div onClick={() => setStageDataId(row?.original?._id)}>
                    <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row?.index + 1}</Text>
                    <Text {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: "pointer", margin: "0px 5px" },
                    }} >
                        {row.getIsExpanded() ? <FaAngleUp /> : <FaAngleDown />}
                    </Text>
                    {location?.search?.includes("CustomOrder") && <RowDragHandleCell row={row} />}
                </div>
            ),
            size: 60,
        },
        {
            header: 'Stage Name',
            accessorKey: "name",
            columns: [],
            cell: ({ row }) => {
                const dueDate = row?.original?.dueDate || '';
                const timeRemaining = row?.original?.timeRemaining || 0;
                return (
                    <StageName
                        stageId={row?.original?._id}
                        stageName={row?.original?.name || ''}
                        dueDate={dueDate}
                        timeRemaining={timeRemaining}
                    />
                );
            },
            filterType: "dropdown",
            options: dataForTable?.data?.allStages?.map((val: { _id: string, name: string }) => ({ value: val.name, label: val.name }))
        },
        {
            header: 'Project Name',
            accessorKey: "projectId",
            cell: ({ row }) => {
                return (
                    <Paragraph className='project-name-row' >{row.original?.projectId?.name}</Paragraph>
                )
            },
            filterType: 'dropdown',
            options: (() => {
                const allDeliveredStages = dataForTable?.data?.allDeliveredStages?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name })) || [];
                const allRTDStages = dataForTable?.data?.allRTDStages?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name })) || [];
                const allDraftStages = dataForTable?.data?.allDraftStages?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name })) || [];
                const allProjects = dataForTable?.data?.allProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name })) || [];

                // Merge all the stage arrays into one array
                const combinedStages = [...allDeliveredStages, ...allRTDStages, ...allDraftStages, ...allProjects];

                return combinedStages;
            })()
        },
        {
            header: 'Folder Number',
            accessorKey: "folderNumber",
            cell: ({ row }) => {
                return (
                    <Text >{row?.original?.projectId?.folderNumber}</Text>
                )
            },
        },
        {
            header: 'Highlighters',
            accessorKey: "highlighters",
            cell: ({ row }) => {
                const stages = row?.original?.highlighters;
                if (stages && stages?.length > 0) {
                    return (
                        <Flex sx={{ justifyContent: "center", flexWrap: 'wrap' }}>
                            {stages?.map((val: { color: string, title: string, _id: string, name: string }) => (
                                // <CommonSpan color={val.color} name={val.name} />
                                <Badge title={val.name} sx={{ color: `${val.color}`, border: `1px solid ${val.color}`, borderRadius: 6, backgroundColor: "transparent", margin: "5px" }} key={val?._id}>{val?.title}</Badge>
                            ))}
                        </Flex>
                    );
                } else {
                    return null;
                }
            },
            filterType: 'multi-select-dropdown',
            options: highlighters?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }))
        },
        {
            header: 'PM Manager',
            accessorKey: "manager",
            cell: ({ row }) => {
                return (
                    <Text >{row.original.manager?.FirstName} {row.original.manager?.LastName}</Text>

                    // <Text >{row.original.manager?.FirstName}</Text>
                )
            },
            filterType: 'dropdown',
            options: dataForTable?.data?.allProjectManagers?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val._id, label: `${val.FirstName} ${val.LastName}` }))
        },
        {
            header: 'PM Worker',
            accessorKey: "worker",
            cell: ({ row }) => {
                return (
                    <Text >{row.original.worker?.FirstName} {row.original.worker?.LastName}</Text>
                )
            },
            filterType: 'dropdown',
            options: dataForTable?.data?.allProjectWorkers?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val._id, label: `${val.FirstName} ${val.LastName}` }))
        },
        {
            header: 'Due date',
            accessorKey: "dueDate",
            cell: ({ row }) => {
                const dueDateValue = row.getValue("dueDate") as string;
                return (
                    <Text variant='Poppins14Normal24lh'>{dueDateValue ? moment(dueDateValue.split('T')[0]).format("MMM DD, YYYY") : ""}</Text>
                );
            },
            filterType: 'date',
        },
        {
            header: 'Status',
            accessorKey: "status",
            cell: ({ row }) => {
                type StatusType = keyof typeof statusColors;

                const status = row.original.status as StatusType;

                const color = statusColors[status] || '#000';

                return (
                    <Badge
                        title={status}
                        sx={{
                            color: `white`,
                            border: `1px solid ${color}`,
                            borderRadius: 6,
                            backgroundColor: `${color}`,
                            margin: "5px"
                        }}
                    >
                        {status}
                    </Badge>
                );
            },
            filterType: 'dropdown',
            options: dataForTable?.data?.status?.map((val: { value: string }) => ({ value: val.value, label: val.value }))
        },
        {
            header: 'Promise',
            accessorKey: "promise",
            cell: ({ row }) => {
                const promise = row?.original?.promise;
                const camelCasePromise = promise ? promise
                    .replace(/([a-z])([A-Z])/g, '$1 $2')
                    .replace(/\b\w/g, (char: string) => char.toUpperCase()) : "";

                const cellStyle = {
                    backgroundColor: promise === 'hotPromise' ? 'red' : 'transparent',
                    padding: '10px',
                    color: promise === 'hotPromise' ? 'white' : 'inherit',

                };
                return (
                    <div style={cellStyle}>{camelCasePromise}</div>
                )
            },
            // cell: info => info.getValue(),
            filterType: "textbox",
        },
        {
            header: 'Tasks Name list',
            accessorKey: "tasksNameList",
            cell: ({ row }) => {

                const taskList: YourDataType["tasksNameList"] = row.getValue("tasksNameList");
                return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '7px', marginTop: '7px' }}>
                        {taskList?.map((task: any, index: number) => {
                            type StatusType = keyof typeof statusColors;

                            const status = task.status as StatusType;
                            const color = statusColors[status] || '#000';
                            let timeInHours: string | null = null;

                            if (task.timeRemaining !== undefined) {
                                const totalMilliseconds = task.timeRemaining;

                                const hours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
                                const minutes = Math.floor((totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60));


                                timeInHours = `${hours}:${minutes.toString().padStart(2, '0')}`;
                            }

                            return (
                                <Box key={task._id + "-" + index} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '7px',
                                    padding: '2px 4px',
                                    backgroundColor: `${color}`,
                                    border: `1px solid ${theme.colors?.black}`,
                                    borderRadius: '6px',
                                    // justifyContent: 'space-evenly'
                                }}>
                                    <Badge
                                        title={task.name}
                                        sx={{
                                            backgroundColor: task.color,
                                            color: `${theme.colors?.white}`,
                                            border: `1px solid ${task.color}`,
                                            borderRadius: '4px',
                                            padding: '1px 4px',
                                            fontSize: '12px',
                                            gap: '4px',
                                            fontWeight: 500
                                        }}
                                    >
                                        {task.shortForm}
                                    </Badge>
                                    {task?.status && (
                                        <Badge
                                            title={task.status}
                                            sx={{
                                                backgroundColor: `${color}`,
                                                color: `${theme.colors?.white}`,
                                                border: `1px solid ${color}`,
                                                borderRadius: '4px',
                                                gap: '4px',
                                                fontWeight: 500,
                                                padding: '1px 4px',
                                                fontSize: '12px'
                                            }}
                                        >
                                            <Text
                                                sx={{
                                                    display: 'inline-block',
                                                    width: '6px',
                                                    height: '6px',
                                                    background: theme.colors?.white,
                                                    borderRadius: '50%',
                                                    marginRight: '4px',
                                                }}
                                            ></Text>
                                            {task.abtdStatus}
                                        </Badge>
                                    )}

                                    {timeInHours && (
                                        <Badge
                                            title={timeInHours}
                                            sx={{
                                                backgroundColor: `${theme?.colors?.muted}`,
                                                color: `${theme?.colors?.white}`,
                                                fontWeight: 500,
                                                border: `1px solid ${theme?.colors?.muted}`,
                                                borderRadius: '4px',
                                                padding: '1px 4px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '12px'
                                            }}
                                        >
                                            <img
                                                src={clockIcon}
                                                alt="clock-icon"
                                                style={{ marginRight: '4px', width: '12px', height: '12px' }}
                                            />
                                            {`${timeInHours}h`}
                                        </Badge>
                                    )}
                                </Box>
                            );
                        })
                        }
                    </Box >
                );
            },
        },
        {
            header: 'Comments',
            cell: ({ row }) => {
                return (
                    <div>
                        <CommentsDialogBox id={row?.original?._id} comments={row?.original} level="stage" />
                    </div>
                );

            },
        }

    ], [stageView.data, dataForTable]);
    const dataIds = useMemo(() => data?.map((item: any) => item?._id), [data]);
    const table = useReactTable({
        data,
        columns,
        getRowCanExpand: () => true,
        getCoreRowModel: getCoreRowModel(),
        // getRowId: row => row._id,
        getRowId: useCallback((row: any) => row?._id, []),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,

    })
    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active && over && active.id !== over?.id) {
            const oldData: any = data?.filter((act: any) => act?._id === active?.id);
            const newData: any = data.filter((ove: any) => ove?._id === over?.id);
            const oldDate = new Date(oldData[0]?.dueDate);
            const newDate = new Date(newData[0]?.dueDate);
            if (oldDate.getTime() === newDate.getTime()) {
                const oldIndex = dataIds.indexOf(active.id);
                const newIndex = dataIds.indexOf(over.id);
                dispatch(GetAllStageViewSuccess({ data: arrayMove(data, oldIndex, newIndex), count: stageView?.count }))
                let isCustOrder1 = location?.search?.includes("stat=CustomOrder1")

                const dispatchObj = {
                    id: oldData?.[0]?._id,
                    stageId2: newData?.[0]?._id,
                    oldOrder: oldData?.[0]?.[isCustOrder1 ? "order1" : "order2"],
                    newOrder: newData?.[0]?.[isCustOrder1 ? "order1" : "order2"],
                    view: isCustOrder1 ? "CustomOrder1" : "CustomOrder2"
                };

                dispatch(getStageByCustomOrder(dispatchObj, (res: any) => {
                    if (res?.status === 200) {
                        toast.success("Custom Order Updated Successfully.");
                        setIsLoadingTrue(!isLoadingTrue)
                    }
                }))
            } else {
                toast.error('Due dates are not equal')
            }
        }
    }
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    )
    useEffect(() => {
        if (stageView?.data?.length > 0) {
            setData(stageView?.data || [])
            let newData = stageView?.data?.map((item: any) => {
                return { ...item, _id: item?._id, };
            });
            setData(newData)
        } else {
            setData(stageView?.data || [])
        }

    }, [stageView?.data])

    useEffect(() => {
        let apiEndPoint = getApiEndPoint(
            { limit, currentPage },
            location?.search,
            columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [],
            location?.pathname
        );
        let manipulateStr = location?.search + apiEndPoint;
        const urlParams = new URLSearchParams(apiEndPoint);
        const isTrue = location?.search?.includes("istrue=true");

        if (isTrue) {
            urlParams.delete('dynamicFilter');
            manipulateStr = urlParams.toString();
            let finalQuery = removeDuplicateQueryParams(location?.search + "&" + manipulateStr);
            dispatch(GetAllStageView(finalQuery));
        } else {
            let queryParams = removeDuplicateQueryParams(apiEndPoint + location?.search?.replace("?", "&"));
            dispatch(GetAllStageView(queryParams));
        }
        // const fetchStageTimeSummery = () => {
        //     if (isTrue) {
        //         let finalQuery = removeDuplicateQueryParams(location?.search + "&" + manipulateStr);
        //         dispatch(getStageTimeSummery(finalQuery));
        //     } else {
        //         let queryParams = removeDuplicateQueryParams(apiEndPoint + location?.search?.replace("?", "&"));
        //         dispatch(getStageTimeSummery(queryParams));
        //     }
        // };

        // fetchStageTimeSummery();

        // const intervalId = setInterval(() => {
        //     fetchStageTimeSummery();
        // }, 30000);


        // return () => clearInterval(intervalId);
    }, [location?.search, limit, currentPage]);

    const { data: eventSource } = useQuery({
        queryKey: ['stageSSE'],
        queryFn: () => {
            const eventSource = new EventSource(`${baseUrl}sse/stage`);
            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    dispatch(UpdateStageRow(data))
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };
            eventSource.onerror = (error) => {
                console.error('SSE Error:', error);
                eventSource.close();
            };
            return eventSource;
        },
        refetchInterval: 60000
    })

    useEffect(() => {
        return () => {
            eventSource?.close();
        };
    }, [])
    interface SortableContextProps {
        dataIds: string[];
        table: any;
        stageView: any[]
    }
    const MemoizedSortableContext: React.FC<SortableContextProps> = React.memo(({ dataIds, table, stageView }) => {

        const memoizedRows = useMemo(() => {
            return table.getRowModel()?.rows?.map((row: any) => (
                <React.Fragment key={row?.original?._id}>
                    <DraggableRow row={row} key={row?.original?._id} />
                    {(row?.original?._id === stageDataId && row.getIsExpanded()) && (
                        <tr key={"exp-" + row?.original?._id}>
                            <td colSpan={row.getVisibleCells().length}>
                                <StageByTaskView fixedColumn={false} />
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            ));
        }, [dataIds, stageView]);

        return (
            <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                {memoizedRows}
            </SortableContext>
        );
    });

    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Stages view (V2)' Count={stageView?.count} IsExportButtonShow={false} src={Export} BtnTxt='Export' addText={'Add Stage'} handleClick={handleClick} limit={limit} currentPage={currentPage} columns={columns} url={"/stages-view-second"} ml={'60px'} summeryTime={false} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={(handleDragEnd)}
                sensors={sensors}
            >
                <div className="">
                    <Box as="div" sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
                        <div className={`${"tableFixHead"}`} style={{ borderRadius: 10, border: `1px solid ${theme.colors?.muted}` }}>
                            <table className={`${'' + TabelClassname?.PrimaryTabel} mb-0 table-hover`}>
                                <thead className="position-relative">
                                    {table.getHeaderGroups()?.map(headerGroup => {

                                        return (
                                            <tr
                                                key={headerGroup.id}
                                                className='trClassName'
                                            >
                                                {headerGroup.headers.map((header: any, index) => {
                                                    const isCommentsHeader = header.column.columnDef.header === "Comments";
                                                    const isSpecialColumn = ["Due date", "TM Worker", "TM Manager", "PM Manager", "PM Worker", "Promise", "Stage Name", "Comments", "Status", "Highlighters"].includes(header.column.columnDef.header);
                                                    const isTaskNameListHeader = header.column.columnDef.header === "Tasks Name list";
                                                    let headerID = header.getContext().column.id;

                                                    return (
                                                        <th
                                                            className={`th align-middle border-top-0 border-bottom-0 fixed-column-${index} ${(headerID && location?.search?.includes("CustomOrder")) ? "th_child" : ""} ${isSpecialColumn ? 'th-quote-number' : ''} ${isTaskNameListHeader ? 'th-task-list' : ''}`}
                                                            key={header.id}
                                                            colSpan={header.colSpan}
                                                            style={{ backgroundColor: `${theme?.colors?.lightbackground}`, padding: `${commonPadding}px`, textAlign: 'center' }}
                                                        >
                                                            {header.column.getCanFilter() && (
                                                                <FilterPopup column={header.column.columnDef} id={`column${index}`} />
                                                            )}

                                                            {!(header.isPlaceholder) && (
                                                                <Text variant='Poppins16Normal25lh' className=" position-relative">
                                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                                    {isCommentsHeader && <CommentPopup />}
                                                                </Text>
                                                            )}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}

                                </thead>
                                <tbody>
                                    <MemoizedSortableContext dataIds={dataIds} table={table} stageView={stageView?.data} />
                                </tbody>
                            </table>
                            {loader &&
                                <Spinner sx={{ position: "absolute", top: "50%", left: "50%" }} />
                            }
                            {!loader && data?.length === 0 && (
                                <Text sx={{ position: "absolute", top: "50%", left: "50%" }}>No Data Found!</Text>
                            )}
                        </div>
                    </Box>
                    <Box className="sticky-navigation">
                        <TablePagination

                            onPageChange={(value) => {
                                const parsedQuery = queryString.parse(location.search);
                                if (searchParams?.size > 2) {

                                    setSearchParams({
                                        ...parsedQuery,
                                        currentPage: value.toString(),
                                    });
                                } else {
                                    setSearchParams({
                                        ...parsedQuery,
                                        currentPage: value.toString(),
                                        limit: limit.toString(),
                                    });
                                }
                            }}
                            onPerPageChange={(itemsPerPage) => {
                                const parsedQuery = queryString.parse(location.search);
                                if (searchParams?.size > 2) {

                                    setSearchParams({
                                        ...parsedQuery,
                                        limit: itemsPerPage.toString(),
                                    });
                                } else if (stageView?.count < itemsPerPage) {
                                    setSearchParams({
                                        currentPage: '1',
                                        limit: itemsPerPage.toString(),
                                    });
                                }
                                else {
                                    setSearchParams({
                                        ...parsedQuery,
                                        currentPage: currentPage.toString(),
                                        limit: itemsPerPage.toString(),
                                    });
                                }
                            }}
                            perPageCount={limit || 15}
                            TotalLength={stageView?.count || 15}
                            CurrentPage={currentPage}

                        />
                    </Box>
                </div>
            </DndContext>
            {!timeTracking || Object?.keys(timeTracking).length === 0 ? (
                <Button
                    sx={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                        bg: 'primary',
                        color: 'white',
                        borderRadius: '50%',
                        width: 60,
                        height: 60,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 24,
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                        zIndex: 1000,
                        '&:hover': {
                            bg: 'secondary',
                        }
                    }}
                    onClick={toggleModal}
                >
                    <img
                        src={clockIcon}
                        alt="clock-icon"
                        style={{ width: '40px', height: '40px' }}
                    />
                </Button>
            ) : null}
            <GeneralTimerStart isOpen={modalOpen} toggle={toggleModal} autherId={auther?._id} />
        </div>
    )
}

export default StagesViewSecond
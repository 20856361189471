import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from 'yup';
import { addDescriptionActionHighlight, addDescriptionBulkActionHighlight, createActionHighlight, getActionHighlighterIds, getActionHighlighterIdsSuccess, getActionId, getActionIdSuccess, getAllTemplateChecklist } from "../../../store/actions/action.Highlighters";
import { Box, Button, Divider, Link, Paragraph, Spinner, Text, Textarea } from "theme-ui";
import { FaPlus } from "react-icons/fa";
import { ActionCard } from "./ActionCard";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from "../../../../@types";
import CustomTooltip from "../../CustomTooltip";
import { rolePermissionsHighLighters } from "../../../utils/CustomHandler";
import toast from "react-hot-toast";
import { IChecklistTemplate } from "../../project-components/sideActions";
import FormSelect from "../../forms-component/globalSelect";
import theme from "../../../theme";

export const ActionModuleTab = ({ actionObject, selectedHighlight, keys, children, actionId, handleHighlightClick }: any) => {
  const dispatch = useDispatch()
  const { actionHighlights, createHighlight, loader, bcActionDone, templateList } = useSelector((state: any) => state.actionHighlight)
  const { stagesView, tasksView } = useSelector((state: any) => state.project);
  const [highlighter, setHighlighter] = useState<any>(null);
  const { pathname } = useLocation();
  const { stageId } = useParams<{ stageId: string }>();
  const { auther } = useSelector((state: RootState) => state.auth);
  const [showWarning, setShowWarning] = useState(false);
  const is_Task_Worker = auther?.Role?.map(role => role?.RoleName).every(role => role.toLocaleLowerCase() === "task worker")
  const [selectedTemplates, setSelectedTemplates] = useState<any>();
  const templateOptions: any = templateList?.map((template: IChecklistTemplate) => ({
    label: template.templateName,
    value: template._id,
    checklistItem: template?.checklistItem
  }));
  const isStageMatch = stageId === actionHighlights?.stageId;
  const canAddTask =
    !(isStageMatch === false &&
      ["Revision", "Background Change", "Plan Check"]?.includes(selectedHighlight?.name));

  let objKeys = keys?.second !== undefined ? {
    name: '',
    [keys?.first]: false,
    [keys?.second]: false,
    comment: []
  } : {
    name: '',
    [keys?.first]: false,
    comment: []
  }
  useEffect(() => {

    if (actionId) {
      dispatch(getActionId(actionId))

    }
    else {
      dispatch(getActionHighlighterIds(actionObject, { name: selectedHighlight?.name }))
    }
    dispatch(getAllTemplateChecklist({ query: "subtasks" }))

  }, [createHighlight, bcActionDone, selectedHighlight])

  useEffect(() => {
    if (stagesView?.highlighters && pathname?.split('/')[1] === 'edit-stages') {
      setHighlighter(stagesView?.highlighters)
    }
    if (tasksView.highlighters && pathname?.split('/')[1] === 'edit-task') {
      setHighlighter(tasksView?.highlighters)
    }
  }, [stagesView?.highlighters, tasksView?.highlighters]);

  useEffect(() => {
    return () => {
      dispatch(getActionHighlighterIdsSuccess([]))
      dispatch(getActionIdSuccess([]))
      setHighlighter(null)
    }
  }, [])

  useEffect(() => {
    if (selectedHighlight?.name === 'New Info Added' && actionHighlights?.payload?.Description?.length > 0) {
      setShowWarning(true);
      if (stagesView?.highlighters) {
        setHighlighter(stagesView?.highlighters)
      }
      if (tasksView?.highlighters) {
        setHighlighter(tasksView?.highlighters)
      }
    } else {
      setShowWarning(false);
    }
  }, [selectedHighlight, actionHighlights, stagesView?.highlighters, tasksView?.highlighters]);


  const formik = useFormik(
    {
      enableReinitialize: true,
      initialValues: objKeys,
      validationSchema: Yup.object({
        name: Yup.string().required('Check list item is required'),
      }),
      onSubmit: (value: any) => {

        // if (!is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) {
        value["isReviewed"] = false;
        value["isDone"] = false;
        if (actionHighlights?._id) {
          const updateData = {
            data: value,
            ...actionObject,
            actionType: selectedHighlight?.name,
          }
          dispatch(addDescriptionActionHighlight(updateData, actionHighlights?._id))
        } else {
          const payload = {
            actionType: selectedHighlight?.name,
            payload: {
              Description: [value]
            },
            ...actionObject
          }
          dispatch(createActionHighlight(payload))
        }
        formik.resetForm()
        // }

      }
    }
  );
  const { handleSubmit, setFieldValue, values, handleBlur, touched, errors } = formik;
  const placeholderText = `Create ${selectedHighlight?.name?.toLowerCase()} subtask`;
  const [textareaValue, setTextareaValue] = useState(values.name);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [textareaValue]);

  const handleTemplateChange = (selected: any) => {
    const selectedTemplates = {
      _id: selected?.value?._id,
      name: selected?.value?.templateName,
    };

    if (actionHighlights?._id) {
      const data = selected?.checklistItem.map((template: any) => ({
        name: template,
        isChecked: false,
        isReviewed: false,
        isDone: false,
      }));

      const updateData = {
        data,
        ...actionObject,
        actionType: selectedHighlight?.name,
      };
      // Dispatch the action with the formatted payload
      dispatch(addDescriptionBulkActionHighlight(updateData, actionHighlights?._id));
    } else {
      const description = selected?.checklistItem.map((template: any) => ({
        name: template,
        isChecked: false,
        isBlockByInfo: false,
        comment: [],
        isReviewed: false,
        isDone: false,
      }));

      const payload = {
        actionType: selectedHighlight?.name,
        payload: {
          Description: description,
        },
        ...actionObject,
      };

      dispatch(createActionHighlight(payload));
    }

    setSelectedTemplates(selectedTemplates);
  };

  const handleTextareaChange = (event: any) => {
    const newValue = event.target.value;
    setTextareaValue(newValue);
    setFieldValue("name", newValue, true);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        {showWarning && (
          <Box sx={{ paddingX: "2rem", mt: "1.5rem" }}>
            <Paragraph sx={{ color: `${theme?.colors?.warning}` }}>Warning: Missing Information</Paragraph>
            <Paragraph>
              <Text sx={{ color: `${theme?.colors?.danger}` }}>Please check the "Need Info" section to avoid missing critical details required for completing this process.</Text>
              <Link sx={{ cursor: "pointer", mx: "8px" }} onClick={() => {
                const highlighte = highlighter?.find((highlighte: any) => highlighte?.name === 'Need Info');
                if (highlighte) {
                  handleHighlightClick(highlighte);
                } else {
                  toast.error("No need info added")
                }
              }}>Click here</Link>
            </Paragraph>
          </Box>
        )}
        {!(isStageMatch === false && ["Revision", "Background Change", "Plan Check"].includes(selectedHighlight?.name)) && (

          <Box sx={{ paddingX: "2rem", position: "relative" }}>
            {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? (
              <CustomTooltip id={placeholderText + 1213} title={is_Task_Worker && "Permission Denied"}>
                <Textarea
                  ref={textareaRef}
                  disabled={!isStageMatch || is_Task_Worker}
                  placeholder={placeholderText}
                  name={"name"}
                  className="form-control mt-4"
                  value={values.name}
                  onChange={handleTextareaChange}
                  onKeyDown={handleKeyDown}
                  rows={1}
                  sx={{ width: '100%', overflow: 'hidden', height: '48px' }}
                />
              </CustomTooltip>
            ) : (
              <Textarea
                ref={textareaRef}
                placeholder={placeholderText}
                name={"name"}
                className="form-control mt-4"
                value={values.name}
                onChange={handleTextareaChange}
                onKeyDown={handleKeyDown}
                rows={1}
                sx={{ width: '100%', overflow: 'hidden', height: '48px' }}
              />
            )}
          </Box>

        )}
        {canAddTask && (
          <Box className="mx-5 m-3">
            {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? (
              <CustomTooltip id={"AddTask12"} title={is_Task_Worker && "Permission Denied"}>
                <Button
                  type='button'
                  disabled={!isStageMatch || is_Task_Worker}
                  sx={{
                    borderRadius: "8px",
                    paddingLeft: "12px",
                    cursor: "not-allowed"
                  }}
                >
                  <FaPlus className="mx-1" />Add
                </Button>
              </CustomTooltip>
            ) : (
              <Button
                type="submit"
                sx={{ borderRadius: "8px", paddingLeft: "12px" }}
              >
                <FaPlus className="mx-1" />Add
              </Button>
            )}
          </Box>
        )}
      </form>
      <Divider sx={{ mt: "1.5rem" }} />
      <Box sx={{ display: "grid", paddingX: "2rem", gap: "1rem", position: "relative" }}>
        <Box className="row">
          <Box className="col-6">
            <Paragraph sx={{ fontSize: "18px", fontWeight: "600", mb: "0.5rem" }}>{"All the reply and conversations"}</Paragraph>
          </Box>
          <Box className="col-6">
            <FormSelect
              placeholder="Predefined Checklist"
              options={templateOptions}
              value={templateOptions?.find((option: any) => selectedTemplates?._id === option.value)}
              onChange={handleTemplateChange}
              disabled={!isStageMatch &&
                ["Revision", "Background Change", "Plan Check"]?.includes(selectedHighlight?.name)}
            />
          </Box>
          {['Background Change', 'Plan Check', 'Revision']?.includes(selectedHighlight?.name) &&
            (!is_Task_Worker && !rolePermissionsHighLighters.includes(selectedHighlight?.name)) && <Box className="col-6">
              {children}
            </Box>
          }
        </Box>

        {actionHighlights?.payload?.Description?.length > 0 && actionHighlights?.payload?.Description?.map((action: any, index: number) => {

          return <ActionCard key={index} index={index} action={{ ...action }} selectedHighlight={selectedHighlight} comment={false} keys={keys} actionObject={actionObject} />
        }
        )}
        {loader && <Box sx={{ position: "absolute", inset: "0", width: "100%", height: "100%", backgroundColor: 'rgba(255, 255, 255, 0.5)', display: "grid", placeContent: "center" }}><Spinner /></Box>}
      </Box>
    </>
  )
}

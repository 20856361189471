import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../../@types";
import { useEffect, useState } from "react";
import { Button } from "theme-ui";
import theme from "../../../../theme";
import * as Yup from 'yup';
import { ActionCreateByUrl, ActionUpdateById } from "../../../../store/actions/CommonAction";
import { Inputform } from "../../../forms-component/InputForm";
import { getUploadTypeById } from "../../../../store/actions/masterData.action";

const AddEditUploadType: React.FC<any> = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { uploadtypeId } = useParams();
    const { individualUploadType } = useSelector((state: RootState) => state?.masterData);
    const [isCreating, setIsCreating] = useState(!uploadtypeId);
    useEffect(() => {
        if (uploadtypeId) {
            setIsCreating(false);
            // Fetch the existing data if editing
            dispatch(getUploadTypeById({ id: uploadtypeId }));
        } else {
            setIsCreating(true);
            formik.resetForm({
                values: {
                    name: ''
                }
            });
        }
    }, [uploadtypeId]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: isCreating ? '' : (individualUploadType?.data?.name || '')
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(' Name is required'),

        }),

        onSubmit: (values, { resetForm }) => {
            const handleResponse = (res: any) => {
                if (res.status) {
                    resetForm();
                    navigate("/uploadTypes");
                }
            };
            if (uploadtypeId) {
                dispatch(ActionUpdateById({ Query: `/uploadTypes/edituploadTypes/${uploadtypeId}`, obj: values }, handleResponse));

            } else {
                dispatch(ActionCreateByUrl({ Query: "/uploadTypes/create-upload-type", obj: values }, handleResponse));

            }

        },

    });
    const { handleSubmit, setFieldValue, values, handleBlur, touched, errors, isValid, isSubmitting } = formik;
    return (
        <div className="main-content p-5">
            <form onSubmit={handleSubmit}>
                <h1>{uploadtypeId ? "Edit" : "Add"} Upload Type</h1>
                <div className="row">
                    <div className="col-md-6 mt-4">
                        <Inputform
                            label={"Upload Type "}
                            Placeholder={"Enter Type"}
                            Name={"name"}
                            type='text'
                            Title_sx={{ color: "grey3" }}
                            touched={touched?.name}
                            asterisk={true}
                            errors={errors?.name}
                            values={values?.name || ''}
                            Max_Length={10}
                            onBlur={handleBlur}
                            OnChange={(event: any) => {
                                const newName = event.target.value;
                                setFieldValue("name", newName, true);
                            }}
                        />
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                    <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' >Submit</Button></div>
                </div>
            </form>
        </div >)
}

export default AddEditUploadType
import NetworkClient from "./NetworkClient";

export const loginApi = (payload) => {
    return NetworkClient.post("Users/login", payload)
}
export const emailVerifyApi = (payload) => {
    return NetworkClient.post("users/forgetpassword", payload)
}

export const forgotPasswordApi = ({ userID, token, Password }) => {
    return NetworkClient.post(`/users/forgetPassword/${userID}/${token}`, { Password })
}

export const getUserbyTokenApi = (token) => {
    return NetworkClient.get(`users/get-user-by-token/${token}`)
}

// Users
// export const getAllUserApi = (page, limit) => {
//     return NetworkClient.get(`Users/getAllUser?page=${page}&limit=${limit}`)
// }
export const getAllUserApi = (obj) => {
    return NetworkClient.get(`Users/getAllUser${obj}`)
}
export const getAllUserWitoutPaginationApi = (payload) => {

    return NetworkClient.get(`Users/get-all-user-no-pagination?${payload}`);
};
export const createUserApi = (payload) => {
    return NetworkClient.post("users/CreateUser", payload)
}

export const EditUserApi = (payload) => {
    return NetworkClient.post("users/editUser", payload)
}

export const DeleteUserApi = (id) => {
    return NetworkClient.post(`users/deleteUser/${id}`)
}

export const EditUserbyId = (id) => {
    return NetworkClient.get(`users/get-user-by-id/${id}`)
}

//Teams
export const getAllTeamsApi = (obj) => {
    return NetworkClient.get(`teams/getAllTeams${obj}`)
}
// export const getTeamsByUserApi = (obj) => {
//     return NetworkClient.get(`teams/get-team-by-userId${obj}`)
// }
export const getAllTeamsByUserNopagination = () => {
    return NetworkClient.get(`teams/get-team-by-userId-no-pagination`)
}
export const getTeamsByUserApi = (obj) => {
    const url = obj ? `teams/get-team-by-userId${obj}` : 'teams/get-team-by-userId';
    return NetworkClient.get(url);
};
export const createTeamsApi = (payload) => {
    return NetworkClient.post("teams/CreateTeam", payload)
}
export const getTeambyId = (id) => {
    return NetworkClient.get(`teams/get-team-by-id/${id}`)
}
export const getAllUnassignedTeams = () => {
    return NetworkClient.get(`teams/get-all-unassigned-teams`)
}
export const getSubTeambyId = (id) => {
    return NetworkClient.get(`teams/get-all-subTeam-of-team/${id}`)
}
export const getMembersDetailByTeam = (id, params) => {
    console.log('chk63', params, id);

    const page = params?.currentPage || 1;
    const limit = params?.limit || 15;

    return NetworkClient.get(
        `task-data/dashboard/get-all-task-by-worker-manager-id/${id}?page=${page}&limit=${limit}`
    );
};
export const getTeamsByMemberApi = (obj) => {
    return NetworkClient.get(`teams/get-team-by-userId-from-members${obj}`)
}
export const getTeamsByMemberNoPaginationApi = () => {
    return NetworkClient.get(`teams/get-team-by-userId-from-members-no-pagination`)
}
//Todos
export const getAllTodosApi = (obj) => {
    return NetworkClient.get(`todo/getAllTodos${obj}`)
}
export const getTodobyId = (id) => {
    return NetworkClient.get(`todo/get-todo-by-id/${id}`)
}
export const getTodoByUserApi = (obj) => {
    return NetworkClient.get(`todo/get-todo-by-userId${obj}`)
}
export const getAssignedTodoApi = (obj) => {
    return NetworkClient.get(`todo/get-todo-by-userId-assigned${obj}`)
}
// Roles

export const getAllRolesApi = (obj) => {
    return NetworkClient.get(`roles/getAllRoles?page=${obj?.currentPage || 1}&limit=${obj?.limit || 15}`);
};

export const createRoleApi = (payload) => {
    return NetworkClient.post("roles/CreateRoles", payload)
}

export const EditRoleApi = (payload) => {
    return NetworkClient.post("roles/editRole", payload)
}

export const deleteRoleApi = (id) => {
    return NetworkClient.post(`roles/deleteRole/${id}`)
}
export const getRolebyIdApi = (id) => {
    return NetworkClient.get(`roles/get-role-by-id/${id}`)
}

// Create project masterdata

export const getAllprojectMasterDataApi = () => {
    return NetworkClient.get('project/get-project-master-data')
}
export const getAllprojectListApi = (obj) => {
    // console.log("obj", obj);
    return NetworkClient.get(`project/get-all-project-view${obj}`)
}
export const getAllBlueprintsApi = () => {
    return NetworkClient.get('master-data/getAll')
}
export const getSowChecklistApi = () => {
    return NetworkClient.get('task-sow/get-all-task-sow')
}
export const GetSowFieldsApi = () => {
    return NetworkClient.get('task-field-sow/get-all-task-field-sow')
}
export const changeEstimationTimeApi = (obj) => {
    return NetworkClient.patch(`/task-data/change-time-estimate/${obj.taskId}`, obj.payload)
}
// create project step one form


export const StepOneApi = (payload) => {
    return NetworkClient.post('project/create', payload)
}
export const StepTwoApi = (payload, params) => {
    return NetworkClient.post(`project/add-in-project/${params}`, payload)
}
export const GetProjectbyId = (id) => {
    return NetworkClient.get(`project/get-project-by-id/${id}`)
}



// Master Data Listing


export const getAllMasterDataForTableApi = () => {
    return NetworkClient.get(`/project/filter-master-data`)
}
export const getAllArchitectListigApi = (obj) => {
    return NetworkClient.get(`architect/get-all-architect${obj}`)
}
export const getAllChecklistListingApi = (obj) => {
    return NetworkClient.get(`checklist-templates-routes/getAll${obj}`)
}
export const getChecklistDataByIdApi = (obj) => {
    return NetworkClient.get(`checklist-templates-routes/get-checklist-by-id/${obj?.id}`)
}
export const getAllUploadTypeDataApi = (obj) => {
    return NetworkClient.get(`/uploadTypes/get-all-upload-types${obj}`)
}
export const getAllUploadTypeDataNoPaginationApi = () => {
    return NetworkClient.get(`/uploadTypes/get-all-upload-types-no-pagination`)
}
export const getAllUploadTypeByIdDataApi = (obj) => {
    return NetworkClient.get(`/uploadTypes/get-upload-type-by-id/${obj?.id}`)
}
// export const getChecklistDataByIdApi = (obj) => {
//     return NetworkClient.get(`architect/get-architect-by-id/${obj?.id}`)
// }
export const getAllStagesListigApi = (obj) => {
    return NetworkClient.get(`stages/get-all-project-stages?page=${obj?.currentPage || 1}&limit=${obj?.limit || 15}`)
}
export const getAllprojectTypeListigApi = (obj) => {
    return NetworkClient.get(`types/getAllRoles?page=${obj?.currentPage || 1}&limit=${obj?.limit || 15}`)
}
export const getAllTaskMapListigApi = (obj) => {
    return NetworkClient.get(`task-sow/get-all-task-sow-pagination?page=${obj?.currentPage || 1}&limit=${obj?.limit || 15}`)
}
export const getAllTaskFieldApi = (obj) => {
    return NetworkClient.get(`task-field-sow/get-all-task-field-sow-pagination?page=${obj?.currentPage || 1}&limit=${obj?.limit || 15}${obj?.query || ''}`, obj?.obj)
}
export const getAllTaskTypeApi = (obj) => {
    return NetworkClient.get(`task-types/get-all-task-types?page=${obj?.currentPage || 1}&limit=${obj?.limit || 15}`)
}

export const getAllMasterDataApi = (obj) => {
    return NetworkClient.get(`master-data/getAllByid${obj?.query || ''}`, obj?.obj)
}



// All Stage View

export const getAllStageViewApi = (obj) => {
    return NetworkClient.get(`stages-data/get-all-stage-view${obj}`)
}

// Media storage

export const uploadMedia = (payload) => {
    return NetworkClient.post('media/upload', payload, { "Content-Type": "multipart/form-data" })
}
export const deleteMedia = (obj) => {
    return NetworkClient.delete(`/media/delete-media/${obj?.id}`, obj?.payload)
}
export const getAllTaskViewApi = (obj) => {
    return NetworkClient.get(`task-data/get-all-task-view${obj}`)
}
export const getTimeSummeryApi = (obj) => {
    return NetworkClient.get(`task-data/time-summary${obj}`)
}
export const getStageTimeSummeryApi = (obj) => {
    return NetworkClient.get(`stages-data/v2-time-summary-stage-view${obj}`)
}

export const getTimeTrackingApi = (obj) => {
    return NetworkClient.get(`time-tracking/time-pop-up`)
}
export const getGeneralTimeTrackingApi = (obj) => {
    return NetworkClient.get(`time-tracking/get-all-general-timer${obj}`)
}


// Common Apis


export async function UpdateByIdApi(obj) {
    return NetworkClient.patch(`${obj?.Query || ''}`, obj?.obj);
}

export async function DeleteByIdApi(obj) {
    return NetworkClient.delete(`${obj?.Query || ''}`);
}
export async function CreateByUrlApi(obj) {
    return NetworkClient.post(`${obj?.Query || ''}`, obj?.obj);
}

//get all projects

export const getAllProjectsApi = () => {
    return NetworkClient?.get(`project/get-all-project-name-no-pagination`)
}


// architect get api
export const getArchitectDataByIdApi = (obj) => {
    return NetworkClient.get(`architect/get-architect-by-id/${obj?.id}`)
}

// stage get api
export const getStageDataByIdApi = (obj) => {
    return NetworkClient.get(`stages/get-stages-by-id/${obj?.id}`)
}

// task type api
export const getTaskTypeByIdApi = (obj) => {
    return NetworkClient.get(`task-types/get-task-type-by-id/${obj?.id}`)
}

// task type data without paginatation api
export const getTaskTypeDataWithout = () => {
    return NetworkClient.get(`task-types/get-all-task-types-no-pagination`)
}

// task sow api
export const getTaskSowByIdApi = (obj) => {
    return NetworkClient.get(`task-sow/get-task-sow-by-id/${obj?.id}`)
}

// task field sow api
export const getTaskFieldSowByIdApi = (obj) => {
    return NetworkClient.get(`task-field-sow/get-task-field-by-id/${obj?.id}`)
}

//Edit Project by id

export const editProjectByIdApi = (payload) => {
    // console.log("payload, Id",payload);
    const id = payload?.Id
    // console.log("id-----",id, payload?.payload);
    return NetworkClient.patch(`project/update-fields/${id}`, payload?.payload)
}

//Edit Stagesby Id

export const editStagesByIdApi = (payload) => {

    const id = payload?.Id
    return NetworkClient.patch(`stages-data/update-fields/${id}`, payload?.payload)
}

export const editTaskByIdApi = (payload) => {
    // console.log(payload, "check200");
    const id = payload?.Id
    return NetworkClient?.patch(`task-data/update-fields/${id}`, payload?.payload)
}
export const addTaskByIdApi = (payload) => {
    return NetworkClient?.patch(`/task-data/add-task-in-project/${payload?.Id}`, payload?.payload)
}
export const deleteTaskByIdApi = (taskId) => {
    return NetworkClient?.patch(`/task-data/delete-task/${taskId}`)
}
export const deleteMultipleTaskByIdApi = (obj) => {
    return NetworkClient?.patch(`/task-data/delete-multiple-task`, obj)
}
//getStagebyid

export const getStagesByIdApi = (id) => {
    // console.log("id--", id);
    return NetworkClient?.get(`stages-data/get-stage-by-id/${id}`)
}
export const getTaskByIdApi = (id) => {
    // console.log("id--", id);
    return NetworkClient?.get(`task-data/get-task-by-id/${id}`)
}

export const deleteStageByIdApi = (id) => {
    return NetworkClient?.patch(`/stages-data/v2-Delete-stage/${id}`)
}

//time tracking

export const StartTrackingApi = (payload) => {
    return NetworkClient?.post(`time-tracking/start-time`, payload?.payload)
}
export const StopTrackingApi = (id) => {
    const _id = id?.id;
    return NetworkClient?.patch(`time-tracking/stop-time/${_id}`, id?.payload)
}
export const GetTimeById = (id) => {
    return NetworkClient?.get(`time-tracking/get-all-time-by-id/${id}`)
}
export const GetLastTimeById = (id) => {
    return NetworkClient.get(`time-tracking/get-time-by-id/${id}`)
}
export const GetTimeByStageIdApi = (id) => {
    return NetworkClient.get(`time-tracking/get-time-by-stageId/${id}`)
}
export const GetTimeByProjectIdApi = (id) => {
    return NetworkClient.get(`time-tracking/get-time-by-projectId/${id}`)
}


//actions

export const NeedInfoApi = (id) => {
    // console.log("needinfo payload----", id)
    const _id = id?.Id;
    return NetworkClient?.post(`actions/task-action/${_id}`, id?.payload)
}

export const StageActionApi = (id) => {
    const _id = id?.Id;
    return NetworkClient?.post(`actions/stage-action/${_id}`, id?.payload)
}

export const ProjectActionApi = (id) => {
    const _id = id?.Id;
    return NetworkClient?.post(`actions/project-action/${_id}`, id?.payload)
}

//Highlighter

export const GetAllHighlighters = () => {
    return NetworkClient?.get(`highlighters/getAllHighlighters`)
}
export const getHighlighterByViewApi = (view) => {
    return NetworkClient?.get(`highlighters/get-highlighter-by-view/${view}`)
}

//History API
export const getHistoryByViewApi = (view) => {
    return NetworkClient?.get(`/global-history/get-history?${view}`)
}
export const getRevertHistoryByViewApi = (payload) => {
    return NetworkClient?.post(`/global-history/revert-history`, payload)
}
export const getAllRevertHistoryApi = (payload) => {
    return NetworkClient?.get(`/global-history/get-history-revert?${payload}`)
}
// FrontApp

export const GetAllFrontAppApi = (obj) => {
    return NetworkClient?.get(`frontApp-data/get-all-data${obj}`)
}
export const updateDTPM = (id) => {
    // console.log("updateid", id);
    const _id = id?.id
    return NetworkClient?.patch(`frontApp-data/update-DTPM/${_id}`, id?.payload)
}
export const updateCommentApi = (id) => {
    const _id = id?.id
    return NetworkClient?.patch(`frontApp-data/update-comment/${_id}`, id?.payload)
}
export const updateFolderApi = (id) => {
    const _id = id?.id
    return NetworkClient?.patch(`frontApp-data/update-folderNumber/${_id}`, id?.payload)
}

//Task by Project Id

export const TaskByProjectIdApi = (id) => {
    return NetworkClient?.get(`task-data/get-all-task-view-by-projectId/${id}`)
}

export const getAllTaskViewByStageIdApi = (id) => {
    return NetworkClient?.get(`task-data/get-all-task-view-by-stageId/${id}`)
}
//Get Template
export const GetAllTemplateApi = (obj) => {
    return NetworkClient?.get(`checklist-templates-routes/getAll?showInActions=${obj.query}`)
}

//Stage by ProjectId

export const stageByProjectIdApi = (id) => {
    return NetworkClient?.get(`stages-data/get-all-stage-by-projectId/${id}`)
}

//Stage updateCustomOrder 

export const stageByUpdateCustomOrder = (data) => {
    const { id, ...payload } = data;
    return NetworkClient?.patch(`stages-data/updateCustomOrder/${id}`, payload);
}

//Rating Criteria
export const getRatingCriteriaApi = (obj) => {
    return NetworkClient?.get(`rating-criteria/getAllCriteria${obj}`)
}

export const getRatingCriteriaIdApi = (obj) => {
    return NetworkClient.get(`rating-criteria/get-criteria-by-Id/${obj?.id}`)
}

//Ratings

export const getCriteriaByTaskId = (id) => {
    return NetworkClient?.get(`trading-rating-criteria/get-trading-rating-criteria-by-taskId/${id}`)
}

export const CreateRatingApi = (payload) => {
    return NetworkClient?.post(`report/create-report`, payload)
}
export const GetReportHistoryApi = (obj) => {
    return NetworkClient?.get(`report/report-history${obj}`)
}
export const GetReportHistoryByIdApi = (id) => {
    return NetworkClient?.get(`report/get-report-by-id/${id}`)
}
export const editReportByIdApi = (payload) => {
    // console.log(payload, "check200");
    const id = payload?.Id
    return NetworkClient?.patch(`report/edit-report/${id}`, payload?.payload)
}
export const GetMyReviewApi = (obj) => {
    return NetworkClient?.get(`report/report-history${obj}`)
}

export const getReportHistoryIdApi = (id, obj) => {
    return NetworkClient?.get(`report/report-history?tradeNameRef=${id}`)
}


//Trading Rating Criteria
export const getTradingRatingApi = (obj) => {
    return NetworkClient?.get(`trading-rating-criteria/getAllTradingCriteria${obj}`)
}

export const getTradingRatingIdApi = (obj) => {
    return NetworkClient.get(`trading-rating-criteria/get-trading-criteria-by-Id/${obj?.id}`)
}

// Action Highlighter

export const getActionHighlighterIdsApi = (payload, highlight) => {
    return NetworkClient.get(`/actions/get-all-action-by-ids?projectId=${payload?.projectId || null}&stageId=${payload?.stageId || null}&taskId=${payload?.taskId || null}&name=${highlight?.name}`)
}
export const getActioIdApi = (actionId) => {
    return NetworkClient.get(`/actions/get-action-by-id/${actionId}`)
}
export const getActionByNameApi = (payload, highlight) => {
    return NetworkClient.get(`/actions/get-all-action-by-name?projectId=${payload?.projectId || null}&stageId=${payload?.stageId || null}&taskId=${payload?.taskId || null}&actionType=${highlight?.name}`)
}
export const getBCPCRevApi = (projectId, highlight) => {
    return NetworkClient.get(`/actions/get-BC-PC-Rev-by-projectId/${projectId}?actionType=${highlight?.name}`)
}
export const getActionDeliverChecklistApi = (stageId) => {
    return NetworkClient.patch(`/actions/get-all-deliver-checklist/${stageId}`)
}
export const updateActionHighlighterApi = (payload, stageId) => {
    return NetworkClient.patch(`/actions/remove-HL-from-stage/${stageId}`, payload)
}
export const removeActionHighlighterApi = (payload, taskId) => {
    return NetworkClient.patch(`/actions/remove-HL-from-task/${taskId}`, payload)
}
export const removeActionHighlighterFromProjectApi = (payload, projectId) => {
    return NetworkClient.patch(`/actions/remove-HL-from-project/${projectId}`, payload)
}
export const createActionHighlighter = (payload) => {
    return NetworkClient.post(`/actions/create-action`, payload)
}
export const createBCDoneHighlighter = (payload, actionId) => {
    return NetworkClient.patch(`/actions/edit-in-payload/${actionId}`, payload)
}
export const editActionHighlighterApi = (obj) => {
    return NetworkClient.patch(`/actions/edit-in-description/${obj?.id}`, obj?.payload)
}
export const editActionTemplateApi = (obj) => {
    return NetworkClient.patch(`/actions/edit-in-payload/${obj?.id}`, obj?.payload)
}
export const addDescriptionActionHighlightApi = (obj) => {
    return NetworkClient.patch(`/actions/add-in-description/${obj?.id}`, obj?.payload)
}
export const addDescriptionBulkActionHighlightApi = (obj) => {
    return NetworkClient.patch(`/actions/add-in-description-bulk/${obj?.id}`, obj?.payload)
}
export const deleteActionHighlighter = (obj) => {
    return NetworkClient.delete(`/actions/delete-comment-of-action/${obj?.id}`, obj?.payload)
}
export const deleteMultipleActionHighlighter = (obj) => {
    return NetworkClient.delete(`/actions/delete-comment-of-action-multiple/${obj?.id}`, obj?.payload)
}

//Get All Action  Delivery List
export const getAllDeliveryListProjectViewApi = (id) => {
    return NetworkClient?.get(`actions/get-all-deliveries-project-view/${id}`)
}
export const getAllDeliveryListStageViewApi = (id) => {
    return NetworkClient?.get(`actions/get-all-deliveries-Stage-view/${id}`)
}
//Get All BC PC List
export const getAllBcPcHistoryApi = (id) => {
    return NetworkClient?.get(`actions/get-all-BC-PC-Rev/${id}`)
}

//Action comment
export const addCommentActionHighlightApi = (obj) => {
    return NetworkClient.patch(`/actions/add-message-under-checklist/${obj?.id}`, obj?.payload)
}
export const editCommentActionHighlightApi = (obj) => {
    return NetworkClient.patch(`/actions/edit-in-comment/${obj?.id}`, obj?.payload)
}
export const deleteCommentActionHighlighterApi = (obj) => {
    return NetworkClient.delete(`/actions/delete-in-comment/${obj?.id}`, obj?.payload)
}

//Chat API
export const createChatMessageApi = (obj) => {
    return NetworkClient.post(`/chat-system/create-chat`, obj)
}
export const getChatsMessageApi = (obj) => {
    return NetworkClient.get(`/chat-system/get-chat-by-id-and-thread?projectName=${obj?.projectId}&thread=${obj?.thread}&type=${obj?.type}&page=${obj?.pageNumber}&limit=4`)
}
export const deleteChatsMessageApi = (id) => {
    return NetworkClient.delete(`/chat-system/delete-chat/${id}`)
}
export const editChatsMessageApi = (obj) => {
    return NetworkClient.patch(`/chat-system/edit-chat-message/${obj?.id}`, obj?.payload)
}
export const editChatsMessageInBulkApi = (obj) => {
    return NetworkClient.patch(`/chat-system/edit-chat-message-in-bulk`, obj?.payload)
}
export const getTradeForChatsApi = ({ id, level }) => {
    return NetworkClient.get(`/chat-system/get-trade-for-chats/${id}?level=${level}`)
}

// All SubTasks

export const getAllSubTasks = ({ projectId, stageId, taskId }) => {
    return NetworkClient.get(`/actions/get-all-BC-PC-Rev-subtask/${projectId}/${stageId}/${taskId}`)
}
export const getAllSubTasksSummaryApi = ({ projectId, stageId, taskId }) => {
    return NetworkClient.get(`/stages-data/get-stage-summary/${projectId}/${stageId}/${taskId}`)
}
export const getAllSubTasksofSameTask = ({ projectId, stageId }) => {
    return NetworkClient.get(`/stages-data/get-subtask-of-all-same-task/${projectId}/${stageId}`)
}
export const getAllDuplicateSubtasks = (id) => {
    return NetworkClient?.get(`task-data/get-duplicate-task-subtask/${id}`)
}
//Update status actions for any 
export const updateActionStatusToAnyApi = (payload) => {
    return NetworkClient.patch(`/actions/update-to-any-status`, payload)
}

//Get Report 
export const GetReportApi = (obj) => {
    return NetworkClient?.get(`/report/create-excel-report${obj}`)
}
import { useLocation, useNavigate } from "react-router-dom";

interface StageTaskMapping {
  [status: string]: string[];
}

interface Highlighter {
  _id: string;
  name: string;
  color: string;
  icon: string;
  title: string;
  createdAt: string;
  updatedAt: string;
}

interface DataItem {
  value: string;
  label: string;
}

let StageObject: StageTaskMapping = {
  "IPR": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "IPR Completed": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "IPR Pause": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "Internal Review": ["Hour a Day", "Internal Review", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Final Review", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "Internal Review Pause": ["Hour a Day", "Internal Review", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Final Review", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "Final Review": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Plan Check", "Background Change", "Revision", "Final Review", "Waiting for Payment", "Narrarative of Changes"],
  "Final Review Pause": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Plan Check", "Background Change", "Revision", "Final Review", "Waiting for Payment", "Narrarative of Changes"],
  "Pause": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "In Progress": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "Peer Review In Progress": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "Todo Per Review": ["Hour a Day", "Internal Review", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "Ready To Deliver": ["Delivery Notes", "Deliver", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],
  "Delivered": ["Need Info", "Delivery Notes", "Plan Check", "Background Change", "Revision"],
  "Req Time Estimate": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Plan Check", "Background Change", "Revision", "Waiting for Payment", "Narrarative of Changes"],

}

let TaskObject: StageTaskMapping = {
  "IPR": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "IPR Completed", "Narrarative of Changes"],
  "IPR In Progress": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "IPR Completed", "Narrarative of Changes"],
  "IPR Completed": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Narrarative of Changes"],
  "IPR Pause": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "IPR Completed", "Narrarative of Changes"],
  "Internal Review": ["Ready for Final Review", "Hour a Day", "Internal Review", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Task Done", "Narrarative of Changes"],
  "Internal Review In Progress": ["Ready for Final Review", "Hour a Day", "Internal Review", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Task Done", "Narrarative of Changes"],
  "Internal Review Pause": ["Ready for Final Review", "Hour a Day", "Internal Review", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Task Done", "Narrarative of Changes"],
  "Final Review": ["Hour a Day", "Blocked By Trade", "Delivery Notes", "Need Info", "Final Review", "New Info Added", "Ready To Deliver", "Narrarative of Changes"],
  "Final Review Pause": ["Hour a Day", "Blocked By Trade", "Delivery Notes", "Need Info", "Final Review", "New Info Added", "Ready To Deliver", "Narrarative of Changes"],
  "Pause": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Task Done", "Narrarative of Changes"],
  "In Progress": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Narrarative of Changes"],
  "Peer Review In Progress": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Narrarative of Changes"],
  "Todo Per Review": ["Hour a Day", "Internal Review", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Task Done", "Final Review", "Narrarative of Changes"],
  "Todo Per Review Pause": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Task Done", "Narrarative of Changes"],
  "Todo Per Review In Progress": ["Hour a Day", "Blocked By Trade", "Need Info", "New Info Added", "Delivery Notes", "Task Done", "Narrarative of Changes"],
  "Ready To Deliver": ["Delivery Notes", "Narrarative of Changes"],
  "Delivered": ["Need Info", "Delivery Notes"]

}

export function filterByStatus<T extends Highlighter>(
  allHighlighters: T[],
  status: string,
  objectToFilter: StageTaskMapping
): T[] {
  const allowedNames = objectToFilter[status] || [];
  return allHighlighters?.filter(obj => allowedNames?.includes(obj.name));
}

export const ShowHideStageAction = (allHighlighters: Highlighter[], status: string) =>
  filterByStatus(allHighlighters, status, StageObject);

export const ShowHideTaskAction = (allHighlighters: Highlighter[], status: string) =>
  filterByStatus(allHighlighters, status, TaskObject);


// Give url history to current url
export const usePreviousURL = (): ((to: string) => void) => {

  const navigate = useNavigate()
  const location = useLocation();

  const handleNavigate = (to: string) => {
    navigate(to, { state: { from: location.search } });
  };

  return handleNavigate;

};

// remove duplicates from array by given keys
export const uniqueByLabel = (array: DataItem[], key: keyof DataItem): DataItem[] => {
  const UNIQUE_ = new Set<string>();
  return array.filter(item => {
    const k = item[key];
    return UNIQUE_?.has(k) ? false : UNIQUE_?.add(k);
  });
};

export const rolePermissionsHighLighters = [
  "Hour a Day",
  "Internal Review",
  "Final Review",
  "Plan Check",
  "Revision",
  "Background Change",
  "Ready for Final Review",
  "Ready To Deliver",
  "New Info Added",
  "On Hold",
  "Project Setup"
]


export function toConvertLocalDateFormat(dateString: any) {
  if (dateString) {
    const date: Date = new Date(dateString);
    const userTimeZone: any = new Intl.DateTimeFormat().resolvedOptions();
    const { locale }: any = userTimeZone;
    const dateTimeFormate = new Intl.DateTimeFormat(locale, userTimeZone).format(date);
    console.log("User Time zone", locale);
    console.log("DB Date time", dateString);
    console.log("Convert Local DateFormat", dateTimeFormate);

    return dateTimeFormate;
  } else {
    return "";

  }
}

export function formatDateString(dateString: string): string {
  if (dateString) {
    const [year, month, day] = dateString.split('T')[0].split('-');
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthAbbreviation = months[parseInt(month, 10) - 1];

    return `${monthAbbreviation} ${parseInt(day, 10)}, ${year}`;
  } else {
    return "";

  }
}


import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Box, Button, Flex, Image, Paragraph, Spinner, Text } from "theme-ui";
import { ActionCard } from "./ActionCard";
import { Inputform } from "../../forms-component/InputForm";
import warningIcon from "../../../Components/assets/images/Icon/Vector.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createActionHighlight, getAllDeliverChecklist } from "../../../store/actions/action.Highlighters";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import toast from "react-hot-toast";
interface FormValues {
    emailLink: string;
    deliveryDate: string;
}
const ActionDeliverNotes = ({ selectedHighlight, showModal, setShowModal, actionObject, keys, action }: any) => {
    const dispatch = useDispatch();
    const { stageId } = useParams<{ stageId: string }>();
    const { deliverChecklist, loader, actionHighlights } = useSelector((state: any) => state.actionHighlight)
    const [showConfirmation, setShowConfirmation] = useState(false);

    const togglePopover = () => {
        setShowModal(!showModal);
    };

    useEffect(() => {
        if (stageId) {
            dispatch(getAllDeliverChecklist(stageId));
        }
    }, [dispatch, actionHighlights]);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const formik = useFormik<FormValues>(
        {
            enableReinitialize: true,
            initialValues: {
                emailLink: "",
                deliveryDate: ""
            },
            validationSchema: Yup.object({

                emailLink: Yup.string().matches(emailRegex, "Invalid email").required("Email link is required"),
                deliveryDate: Yup.string().required('Delivery Date is required')
            }),
            onSubmit: (value) => { }
        }
    );

    const { handleSubmit, setFieldValue, values, handleBlur, touched, errors } = formik;

    const handleDeliverClick = () => {
        if (!values.emailLink || !values.deliveryDate) {
            formik.setTouched({
                emailLink: true,
                deliveryDate: true,
            });
            // toast.error("Please fill in all required fields.");
            return;
        }
        setShowConfirmation(true);
    };

    const handleConfirmDeliver = () => {
        const aggregatedPayload = deliverChecklist.map((item: any) => item.payload);
        const payload = {
            actionType: "Deliver",
            payload: {
                Description: aggregatedPayload.flatMap((item: any) => item.Description || []),
                emailLink: values.emailLink,
                deliveryDate: values.deliveryDate,
            },
            ...actionObject
        };
        dispatch(createActionHighlight(payload, (res: any) => {
            if (res.status === 200) toast.success(` Delivered successfully!`);

        }));
        setShowConfirmation(false);
        setShowModal(false);
        formik.resetForm();
        // toast.success('Delivered successfully!');
    };
    return (
        <>
            <Modal isOpen={showModal} scrollable toggle={togglePopover} size="xl" centered >
                <form onSubmit={handleSubmit}>
                    <ModalHeader toggle={togglePopover}> <Flex sx={{ gap: "0.5rem" }}><img width={22} src={selectedHighlight?.icon} alt="icon" /><Text sx={{ color: '#51E2B5' }}>{'DELIVER'}</Text></Flex></ModalHeader>
                    <ModalBody className="scrollable-modal-body">
                        <Box sx={{ display: "grid", paddingX: "2rem", gap: "1rem", position: "relative" }}>
                            <Paragraph sx={{ fontSize: "18px", fontWeight: "600", mb: "0.5rem" }}>{"All the reply and conversations"}</Paragraph>
                            {deliverChecklist?.map((deliver: any, index: number) => {
                                return deliver.payload?.Description?.map((action: any, index: number) => {
                                    return <ActionCard key={index} index={index} action={action} selectedHighlight={selectedHighlight} comment={false} keys={keys} actionObject={actionObject} />
                                });
                            })}
                            {loader && <Box sx={{ position: "absolute", inset: "0", width: "100%", height: "100%", backgroundColor: 'rgba(255, 255, 255, 0.5)', display: "grid", placeContent: "center" }}><Spinner /></Box>}

                        </Box>
                        <Flex className="mt-4 row" sx={{ paddingX: "2rem" }}>
                            <Box className="col-3">
                                <Inputform
                                    label={"Delivery Date"}
                                    Placeholder={"Date"}
                                    Name={"deliveryDate"}
                                    type="date"
                                    Title_sx={{ color: "grey3" }}
                                    touched={touched?.deliveryDate}
                                    errors={errors?.deliveryDate}
                                    values={values.deliveryDate}
                                    onBlur={handleBlur}
                                    OnChange={(event: any) => {
                                        const newName = event.target.value;
                                        setFieldValue("deliveryDate", newName);
                                    }}
                                />
                            </Box>
                            <Box className="col-3">
                                <Inputform
                                    label={"Email Link"}
                                    Placeholder={""}
                                    Name={"emailLink"}
                                    type='text'
                                    Title_sx={{ color: "grey3" }}
                                    touched={touched?.emailLink}
                                    errors={errors?.emailLink}
                                    values={values.emailLink}
                                    OnChange={(event: any) => {
                                        const newName = event.target.value;
                                        setFieldValue("emailLink", newName, true);
                                    }}
                                />
                            </Box>
                        </Flex>
                        <Box className="mt-5 row" sx={{ paddingX: "2rem" }}>
                            <Box >
                                <Image src={warningIcon} alt="" sx={{ width: "20px", marginRight: '0.5rem' }} />
                                <Text sx={{ color: '#F39C11', textAlign: 'left' }}>Warning</Text></Box>
                            <Text as='ol' sx={{ paddingX: "2rem", marginTop: 2 }}>

                                <Text variant="Poppins14Normal" as='li' sx={{ color: '#F39C11', textAlign: 'left' }}>Check questions in threads</Text>
                                <Text variant="Poppins14Normal" as='li' sx={{ color: '#F39C11', textAlign: 'left' }}>check need info.</Text>
                                <Text variant="Poppins14Normal" as='li' sx={{ color: '#F39C11', textAlign: 'left' }}>check blocked by info etc. </Text>
                                <Text variant="Poppins14Normal" as='li' sx={{ color: '#F39C11', textAlign: 'left' }}>++ If the all the delivery notes items are not checked then show a mandatory warning.</Text>

                            </Text>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="CancelButton" sx={{ mr: 2 }} onClick={togglePopover}>Cancel</Button>
                        <Button type="submit" variant="SaveButton" onClick={handleDeliverClick}>Deliver</Button>
                    </ModalFooter>
                </form>
            </Modal >
            <Modal isOpen={showConfirmation} toggle={() => setShowConfirmation(false)} size="sm" centered>
                <ModalHeader toggle={() => setShowConfirmation(false)}>Confirm Delivery</ModalHeader>
                <ModalBody>
                    <Text>Are you sure you want to deliver?</Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant="CancelButton" sx={{ mr: 2, cursor: 'pointer' }} onClick={() => setShowConfirmation(false)}>No</Button>
                    <Button type="button" variant="SaveButton" sx={{ cursor: 'pointer' }} onClick={handleConfirmDeliver}>Yes</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ActionDeliverNotes
import { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button, Flex, Text } from 'theme-ui';
import { ActionModuleTab } from './ActionModuleTab';

export const ActionModule = ({ selectedHighlight, Open, setOpen, actionObject, keys, activeTab, TaskView, setShowModal, handleHighlightClick }: any) => {
  const [tabIndex, setTabIndex] = useState(activeTab);

  const togglePopover = () => {
    setOpen(!Open);
  };

  const handleDeliverClick = () => {
    setShowModal(true);
    setOpen(!Open);
  };

  return (
    <Modal isOpen={Open} scrollable toggle={togglePopover} size="xl" centered >
      <ModalHeader toggle={togglePopover}> <Flex sx={{ gap: "0.5rem" }}>{selectedHighlight?.icon && <img width={22} src={selectedHighlight?.icon} alt='icon' />}<Text sx={{ color: selectedHighlight?.color }}>{selectedHighlight?.name?.toUpperCase()}</Text></Flex></ModalHeader>

      <ModalBody className="scrollable-modal-body">

        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Stage</Tab>
            {TaskView?.map((item: any) => <Tab key={item?.id}>{item?.name}</Tab>)}
          </TabList>
          <TabPanel>
            <ActionModuleTab
              actionObject={{ ...actionObject, taskId: null }}
              selectedHighlight={selectedHighlight}
              keys={keys}
              handleHighlightClick={handleHighlightClick}
            />
          </TabPanel>

          {TaskView?.map((item: any) =>
            <TabPanel key={item?.id}>
              <ActionModuleTab
                actionObject={{ ...actionObject, taskId: item.id }}
                selectedHighlight={selectedHighlight}
                keys={keys}
                handleHighlightClick={handleHighlightClick}
              />
            </TabPanel>)}
        </Tabs>
      </ModalBody>
      <ModalFooter>
        {(setShowModal && tabIndex === 0) && (
          <Button className='me-2 px-4' onClick={handleDeliverClick} >Deliver</Button>
        )}
      </ModalFooter>
    </Modal >
  )
}

import { useMemo } from 'react';
import { Badge, Flex, Spinner, Text } from 'theme-ui';
import { useSelector } from 'react-redux';
import { msToTime } from '../../../utils/GenerateQueryString';
import { formatDateString, usePreviousURL } from '../../../utils/CustomHandler';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import theme from '../../../theme';

export default function StageByTaskView({ fixedColumn }) {
    const { stageAllTaskView, stageLoader } = useSelector((state) => state.project);
    const previousURL = usePreviousURL()

    const columns = useMemo(() => [
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            id: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{row?.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "name",
            header: 'Task name',
            cell: ({ row }) => {
                return (
                    <Text onClick={() => { previousURL(`/edit-task/${row?.original?._id}`) }} style={{ textDecoration: 'underline', color: `${theme.colors?.secondary}`, cursor: "pointer", fontWeight: 500 }}>
                        {row?.original?.name || ''}
                    </Text>
                )
            },
            id: "taskName",
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Stage Name',
            accessorKey: "stageId",
            id: "stageName",
            cell: ({ row }) => {
                return (
                    <Text >{row.original.stageId?.name}</Text>
                )
            },
        },
        {
            header: 'Project Name',
            accessorKey: "projectId",
            id: "projectName",
            cell: ({ row }) => {
                return (
                    <Text className='project-name-row' >{row.original?.projectId?.name}</Text>
                )
            },
        },
        {
            header: 'Folder Number',
            accessorKey: "folderNumber",
            cell: ({ row }) => {
                return (
                    <Text >{row?.original?.projectId?.folderNumber}</Text>
                )
            },
        },
        {
            header: 'TM Manager',
            accessorKey: "manager",
            id: "taskManager",
            cell: ({ row }) => {
                return (
                    <>
                        <Text >{row.original.manager?.FirstName} {row.original.manager?.LastName}</Text>
                    </>
                )
            },
        },
        {
            header: 'TM Worker',
            accessorKey: "worker",
            id: 'taskWorker',
            cell: ({ row }) => {
                return (
                    <>
                        <Text >{row.original.worker?.FirstName} {row.original.worker?.LastName}</Text>
                    </>
                )
            },
        },
        {
            header: 'Highlighters',
            accessorKey: "highlighters",
            cell: ({ row }) => {
                const stages = row.original.highlighters;
                const HAD_ = row.original?.hoursADay
                if (stages && stages.length > 0) {
                    return (
                        <Flex sx={{ justifyContent: "center" }}>
                            {stages?.map((val) => (
                                <Badge title={val.name} sx={{ color: `${val.color}`, border: `1px solid ${val.color}`, borderRadius: 6, backgroundColor: "transparent", margin: "5px", position: "relative" }} key={val._id}>
                                    {Number(HAD_) > 0 && <Badge className="px-2" sx={{ position: "absolute", top: "-15px", right: "-11px", borderRadius: '15px', backgroundColor: '#0d7298' }}>
                                        {val.name === "Hour a Day" && HAD_}
                                    </Badge>}
                                    {val.title}</Badge>
                            ))
                            }
                        </Flex >
                    );
                } else {
                    return null;
                }
            },
        },
        {
            header: 'Estimate Time',
            accessorKey: "timeEstimate",
            id: 'timeEstimate',
            cell: ({ row }) => {
                const estimatedTime = row.original.timeEstimate;
                const formattedEstimatedTime = estimatedTime ? `${msToTime(estimatedTime).hours}:${msToTime(estimatedTime).minutes}` : "00:00";
                return (
                    <Text variant='Poppins14Normal24lh'>
                        {formattedEstimatedTime}
                    </Text>
                );
            },
        },
        {
            header: 'Remaining Time',
            accessorKey: "timeRemaining",
            id: 'timeRemaining',
            cell: ({ row }) => {
                const remainingTime = row.original.timeRemaining;
                const remainingMs = remainingTime ? remainingTime : 0;
                const formattedRemainingTime = remainingMs ? `${msToTime(remainingMs).hours}:${msToTime(remainingMs).minutes}` : "00:00";
                return (
                    <Text variant='Poppins14Normal24lh'>
                        {formattedRemainingTime}
                    </Text>
                );
            },
        },
        {
            header: 'Status',
            accessorKey: "status",
            id: 'status',
            cell: info => info.getValue(),
        },
        {
            header: 'Promise',
            accessorKey: "promise",
            cell: ({ row }) => {
                const promise = row.original.stageDataId.promise;
                return (
                    <Text variant='Poppins14Normal24lh'>
                        {promise}
                    </Text>
                );
            },

        },
        {
            header: 'Stage Due Date',
            accessorKey: "stageDueDate",
            cell: ({ row }) => {
                const dueDateValue = row.getValue("stageDueDate");
                return (
                    <Text variant='Poppins14Normal24lh'>{formatDateString(dueDateValue)}</Text>
                );
            },
        }
    ], [stageAllTaskView]);
    const table = useReactTable({
        data: stageAllTaskView,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })
    console.warn(stageAllTaskView);
    return (
        <>
            <div className={`${fixedColumn ? "tableNotFixHead" : "tableFixHead"}`} style={{ borderRadius: 10, border: `1px solid ${theme.colors?.muted}`, minHeight: "max-content" }}>
                <table className={`mb-0 table-hover`}>
                    <thead className="position-relative">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => {
                                    const isCommentsHeader = header.column.columnDef.header === "Comments";
                                    let specificClass = '';
                                    if (header.column.columnDef.header === "No. of Tasks") {
                                        specificClass = 'th-no-of-tasks';
                                    } else if (["Quote Number", "Estimate Time", "Remaining Time", "Project Worker", "Architect", "TM Worker", "Status", "Stage Name", "TM Manager", "Stage Due Date", "Comments"].includes(header.column.columnDef.header)) {
                                        specificClass = 'th-quote-number';
                                    }
                                    return (
                                        <th className={`th align-middle border-top-0 border-bottom-0 fixed-column-${index} ${specificClass}`}
                                            key={index}
                                            style={{ backgroundColor: `${theme?.colors?.lightbackground}`, fontSize: 14 }}>
                                            <Text variant='Poppins16Normal25lh' as={"span"} className="text-center position-relative">
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </Text>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}

                    </thead>
                    <tbody>
                        {!stageLoader && (
                            table.getRowModel().rows.map((row, rowIndex) => (
                                <tr key={rowIndex} data-state={row.getIsSelected() && "selected"}
                                    style={{
                                        overflow: 'hidden',
                                        borderBottom:
                                            (rowIndex + 1) === table.getRowModel().rows?.length ? 'none' :
                                                '1px solid #D3D5D9',
                                    }}
                                >
                                    {row.getVisibleCells().map((cell, cellIndex) => (
                                        <td className={"fixed-column-" + cellIndex + " table-cell"} key={cell.id} style={{
                                            display: "gird",
                                            placeContent: "center",
                                        }}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>

                                    ))}
                                </tr>
                            ))
                        )}

                    </tbody>
                </table>
                {stageLoader &&
                    <Spinner sx={{ position: "absolute", top: "50%", left: "50%" }} />
                }
            </div>
        </>
    )
}

import { FC, MouseEvent } from "react";
import { Button, Flex, Image, ThemeUIStyleObject } from "theme-ui";
import ChatIcon from "../assets/images/Icon/plus.svg";
interface Btnprops {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    title: string | undefined;
    Style?: Object;
    sx?: ThemeUIStyleObject;
    src?: string | any;
    // title: string;
}

export const HeaderBtn: FC<Btnprops> = ({
    title = "",
    onClick = () => null,
    sx,
    Style,
    src
}) => {
    return (
        <Flex>
            <Button onClick={onClick} variant="PrimaryHeadBtn" sx={sx} style={Style}>
                <Image mr={1}
                    src={src}
                    alt="export" />
                {title}
            </Button>
        </Flex>
    );
};


interface PrimayChatBtn {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    Style?: Object;
    sx?: ThemeUIStyleObject;
    chattitle?: string
    handleClick?: any
}

export const PrimayChatBtn: FC<PrimayChatBtn> = ({
    onClick = () => null,
    sx,
    Style,
    chattitle,
    handleClick
}) => {



    return (
        <Flex>
            <Button className="text-light" onClick={handleClick} variant="PrimaryHeadBtn" sx={sx} style={Style}>
                <Image src={ChatIcon} alt="export" /> {chattitle}
            </Button>
        </Flex>
    );
};

import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box, Card, Flex, Button, Label, Spinner, Switch, Text, Image, Textarea } from "theme-ui";
import { createActionHighlight, editActionHighlight, getActionHighlighterIds, getActionHighlighterIdsSuccess } from "../../../store/actions/action.Highlighters";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { RootState } from "../../../../@types";
import { rolePermissionsHighLighters } from "../../../utils/CustomHandler";
import CustomTooltip from "../../CustomTooltip";
import editIcon from "../../assets/images/edit.svg";
import MarkDownParagraph from "../../forms-component/MarkDownParagraph";
import theme from "../../../theme";

export const ActionProjectSetup = ({ Open, setOpen, selectedHighlight, actionObject }: any) => {
  const dispatch = useDispatch();
  const { auther } = useSelector((state: RootState) => state.auth);
  const is_Task_Worker = auther?.Role?.map(role => role?.RoleName).every(role => role.toLocaleLowerCase() === "task worker");

  const { actionHighlights, createHighlight, loader } = useSelector((state: any) => state.actionHighlight);
  const projectSetup = actionHighlights?.payload?.Description[0];
  const [description, setDescription] = useState<string>(projectSetup?.description || '');


  const [isOn, setIsOn] = useState(projectSetup?.isOn ?? true);
  const [isEditing, setIsEditing] = useState(false);


  const togglePopover = () => {
    setOpen(!Open);
  };

  useEffect(() => {
    dispatch(getActionHighlighterIds(actionObject, { name: "Project Setup" }));
  }, [dispatch, createHighlight]);

  useEffect(() => {
    if (projectSetup) {
      setDescription(projectSetup.description || '');
      setIsOn(projectSetup.isOn ?? true);
    }
  }, [projectSetup]);

  useEffect(() => {
    return () => {
      dispatch(getActionHighlighterIdsSuccess([]));
    };
  }, []);

  const handleSubmit = () => {
    if (actionHighlights?._id) {
      const updateData = {
        data: { isOn, description },
        ...actionObject,
        name: "projectSetup",
        actionType: selectedHighlight?.name,
      };
      dispatch(editActionHighlight(updateData, actionHighlights?._id, (res: any) => {
        if (res.status === 200) {
          setDescription(description)
          setIsEditing(false)
        }
      }));
    } else {
      const payload = {
        actionType: selectedHighlight?.name,
        ...actionObject,
        payload: {
          Description: [{
            isOn,
            description,
            name: "projectSetup",
          }]
        }
      };
      dispatch(createActionHighlight(payload, (res: any) => {
        if (res.status === 200) {
          setDescription(description)
          setIsEditing(false)
        }
      }));

    }


    togglePopover();
  };
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const textareaRef = useRef<any>(null);
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [description]);


  return (
    <Modal isOpen={Open} centered scrollable size='md' toggle={togglePopover}>
      <ModalHeader toggle={togglePopover}>
        <Flex sx={{ gap: "1rem" }}>
          <img src={selectedHighlight?.icon} />
          <Text sx={{ color: selectedHighlight?.color }}>{selectedHighlight?.name?.toUpperCase()}</Text>
        </Flex>
      </ModalHeader>
      <ModalBody >
        <Card sx={{ boxShadow: "none", border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "12px 20px", position: "relative" }}>
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', py: 4 }}>
            <Label htmlFor="projectSetup" sx={{ flex: 1 }}>
              {selectedHighlight?.name}
            </Label>
            <Box>
              {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ?
                <CustomTooltip id="on-hold" title="Permission Denied">
                  <Switch sx={{ cursor: "not-allowed" }} checked={isOn} value={"isEnbled"} name="on-hold" id="projectSetup" />
                </CustomTooltip> :
                <Switch checked={isOn} value={"isEnbled"} name="on-hold" id="projectSetup" onChange={(e: any) => setIsOn(e.target.checked)} />
              }
            </Box>
          </Flex>

          {/* Description Section */}
          <Box >
            <Flex sx={{ justifyContent: 'space-between' }}>
              <Text >Description</Text>
              {!isEditing && (
                <Image
                  src={editIcon}
                  sx={{ cursor: 'pointer', width: '24px', height: '24px' }}
                  onClick={handleEditClick}
                />
              )}
            </Flex>
            {isEditing ? (
              <>
                <Textarea
                  value={description}
                  onChange={handleTextareaChange}
                  rows={description.length > 30 ? 10 : 2}
                  placeholder="Description..."
                />

              </>
            ) : (
              <Box className='ps-2 mt-2' sx={{ minHeight: `${description.length < 20 ? "72px" : "245px"}`, maxHeight: '250px', border: "2px solid rgb(247, 239, 229)", overflowY: "auto", borderRadius: "4px", resize: 'vertical' }}>
                <MarkDownParagraph markdown={description} />
              </Box>
            )}
          </Box>
          <Flex sx={{ justifyContent: 'flex-end', py: 2 }}>
            <Button variant="SaveButton" onClick={handleSubmit}>Submit</Button>
          </Flex>

          {loader &&
            <Box sx={{ position: "absolute", inset: "0", width: "100%", height: "100%", backgroundColor: 'rgba(255, 255, 255, 0.5)', display: "grid", placeContent: "center" }}>
              <Spinner />
            </Box>
          }
        </Card>
      </ModalBody>
    </Modal>
  )
}

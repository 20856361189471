/* eslint-disable no-unused-expressions */
import { toast } from 'react-hot-toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginUserFailure } from '../actions/login.action';
import { createTeamSuccess, createUserSuccess, deleteRoleSuccess, deleteUserSucess, editRoleSuccess, editUserSuccess, getAllAssignedTodosSuccess, getAllRolesSucess, getAllTeamsByMemberNoPaginationSuccess, getAllTeamsByMemberSuccess, getAllTeamsByUserNoPaginationSuccess, getAllTeamsByUserSuccess, getAllTeamsSuccess, getAllTodosByUserSuccess, getAllTodosSuccess, getAllUnAssignedTeamsSuccess, getAllUserSucess, getAllUserWithoutPaginationSuccess, getMembersByTeamSuccess, getRolebyIdSuccess, getSubTeambyIdSuccess, getTeambyIdSuccess, getTodobyIdSuccess, getUserbyIdSuccess } from '../actions/user.action';
import { DeleteUserApi, EditRoleApi, EditUserApi, EditUserbyId, createRoleApi, createTeamsApi, createUserApi, deleteRoleApi, getAllRolesApi, getAllTeamsApi, getAllTeamsByUserNopagination, getAllTodosApi, getAllUnassignedTeams, getAllUserApi, getAllUserWitoutPaginationApi, getAssignedTodoApi, getMembersDetailByTeam, getRolebyIdApi, getSubTeambyId, getTeambyId, getTeamsByMemberApi, getTeamsByMemberNoPaginationApi, getTeamsByUserApi, getTodoByUserApi, getTodobyId } from '../api';
import { CREATE_ROLE, CREATE_TEAM, CREATE_USER, DELETE_ROLE, DELETE_USER, EDIT_ROLE, EDIT_USER, GET_ALL_ROLES, GET_ALL_SUB_TEAMS, GET_ALL_TEAMS, GET_ALL_TODOS, GET_ALL_UNASSIGNED_TEAMS, GET_ALL_USERS, GET_ALL_USERS_WITHOUT_PAGINATION, GET_ASSIGNED_TODOS, GET_MEMBERSINFO_BY_TEAM, GET_ROLE_BY_ID, GET_TEAM_BY_ID, GET_TEAMS_BY_MEMBERS, GET_TEAMS_BY_MEMBERS_NO_PAGINATION, GET_TEAMS_BY_USER, GET_TEAMS_BY_USER_NO_PAGINATION, GET_TODO_BY_ID, GET_TODO_BY_USER, GET_USER_BY_ID } from '../storeTypes';

// users

function* getAllUserSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getAllUserApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllUserSucess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllUserWithoutPaginationSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getAllUserWitoutPaginationApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllUserWithoutPaginationSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* createUserSaga(action) {
    try {
        const response = yield call(createUserApi, action.payload);
        // console.log("response", response);
        if (response.data.data) {
            yield put(createUserSuccess(response.data.data));

            // toast.success("User Created Successfuly!");
            // window.location.replace('/user');
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* deleteUserSaga(action) {
    try {
        const response = yield call(DeleteUserApi, action.payload);
        if (response.data.status === true) {
            yield put(deleteUserSucess(response.data.data));
            toast.success("User Deleted Successfuly!");
            window.location.replace("/user")
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* editUserSaga(action) {
    try {
        const response = yield call(EditUserApi, action.payload);
        if (response.data.data) {
            yield put(editUserSuccess(response.data.data));

            toast.success("edit user successfully!");
            // window.location.replace('/user');
        }
    } catch (error) {
        if (error?.response?.status === 403) {
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getUserbyId(action) {
    try {
        const response = yield call(EditUserbyId, action.payload);
        if (response.data.data) {
            yield put(getUserbyIdSuccess(response.data.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) { }
        else {
            yield put(loginUserFailure(error));
        }
    }
}

//Teams
function* getAllTeamsSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getAllTeamsApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllTeamsSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* getAllTeamsByUserSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getTeamsByUserApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllTeamsByUserSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* getAllTeamsByUserNoPaginationSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getAllTeamsByUserNopagination, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllTeamsByUserNoPaginationSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllUnAssignedTeamsSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getAllUnassignedTeams, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllUnAssignedTeamsSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* createTeamSaga(action) {
    try {
        const response = yield call(createTeamsApi, action.payload);
        // console.log("response", response);
        if (response.data.data) {
            yield put(createTeamSuccess(response.data.data));

            // toast.success("User Created Successfuly!");
            // window.location.replace('/user');
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getTeambyIdSaga(action) {
    try {
        const response = yield call(getTeambyId, action.payload);
        if (response.data.data) {
            yield put(getTeambyIdSuccess(response.data.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) { }
        else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getSubTeambyIdSaga(action) {
    try {
        const response = yield call(getSubTeambyId, action.payload);
        if (response.data.data) {
            yield put(getSubTeambyIdSuccess(response.data.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) { }
        else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getMemberDetailsbyTeamSaga(action) {
    try {
        // ✅ Destructure id and params from the action
        const { id, params } = action.payload;

        // ✅ Pass both id and params to the API function
        const response = yield call(getMembersDetailByTeam, id, params);

        if (response.data.data) {
            yield put(getMembersByTeamSuccess(response.data.data, id));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            // Handle forbidden error
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* getAllTeamsByMemberSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getTeamsByMemberApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllTeamsByMemberSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllTeamsByMemberNoPaginationSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getTeamsByMemberNoPaginationApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllTeamsByMemberNoPaginationSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}


//Todo
function* getAllTodosSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getAllTodosApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllTodosSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getTodobyIdSaga(action) {
    try {
        const response = yield call(getTodobyId, action.payload);
        if (response.data.data) {
            yield put(getTodobyIdSuccess(response.data.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) { }
        else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllTodosByUserSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getTodoByUserApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllTodosByUserSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllAssignedTodosSaga(action) {
    try {
        // const { page, limit } = action.payload;
        const response = yield call(getAssignedTodoApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllAssignedTodosSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
// Roles

function* getAllRoleSaga(action) {

    try {
        const response = yield call(getAllRolesApi, action.payload);
        if (response.data.data) {
            // console.log("response.data", response.data);
            // window.location.replace("/role")
            yield put(getAllRolesSucess(response.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* createRoleSaga(action) {
    try {
        const response = yield call(createRoleApi, action.payload);
        // console.log("response", response);
        if (response.data.data) {
            yield put(createUserSuccess(response.data.data));

            toast.success("Role Created Successfuly!");
            window.location.replace('/role');
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* deleteRoleSaga(action) {
    try {
        const response = yield call(deleteRoleApi, action.payload);
        // console.log("response", response);
        if (response.data.data) {
            yield put(deleteRoleSuccess(response.data.data));
            toast.success("Role Deleted Successfuly!");
            window.location.replace("/role")
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* editRoleSaga(action) {
    try {
        const response = yield call(EditRoleApi, action.payload);
        if (response.data.data) {
            yield put(editRoleSuccess(response.data.data));

            toast.success("edit Role successfully!");
            window.location.replace('/role');
        }
    } catch (error) {
        if (error?.response?.status === 403) {
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getRolebyId(action) {
    try {
        const response = yield call(getRolebyIdApi, action.payload);
        if (response.data.data) {
            yield put(getRolebyIdSuccess(response.data.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) { }
        else {
            yield put(loginUserFailure(error));
        }
    }
}

export function* UserSaga() {
    [
        yield takeLatest(GET_ALL_USERS, getAllUserSaga),

        yield takeLatest(GET_ALL_USERS_WITHOUT_PAGINATION, getAllUserWithoutPaginationSaga),
        yield takeLatest(CREATE_USER, createUserSaga),
        yield takeLatest(CREATE_ROLE, createRoleSaga),
        yield takeLatest(DELETE_USER, deleteUserSaga),
        yield takeLatest(EDIT_USER, editUserSaga),
        yield takeLatest(GET_USER_BY_ID, getUserbyId),
        yield takeLatest(GET_ALL_TEAMS, getAllTeamsSaga),
        yield takeLatest(GET_ALL_UNASSIGNED_TEAMS, getAllUnAssignedTeamsSaga),
        yield takeLatest(GET_TEAMS_BY_USER, getAllTeamsByUserSaga),
        yield takeLatest(GET_TEAMS_BY_USER_NO_PAGINATION, getAllTeamsByUserNoPaginationSaga),
        yield takeLatest(GET_TEAMS_BY_MEMBERS, getAllTeamsByMemberSaga),
        yield takeLatest(GET_TEAMS_BY_MEMBERS_NO_PAGINATION, getAllTeamsByMemberNoPaginationSaga),
        yield takeLatest(GET_TEAM_BY_ID, getTeambyIdSaga),
        yield takeLatest(GET_ALL_SUB_TEAMS, getSubTeambyIdSaga),
        yield takeLatest(GET_MEMBERSINFO_BY_TEAM, getMemberDetailsbyTeamSaga),
        yield takeLatest(GET_ALL_TODOS, getAllTodosSaga),
        yield takeLatest(GET_TODO_BY_ID, getTodobyIdSaga),
        yield takeLatest(GET_TODO_BY_USER, getAllTodosByUserSaga),
        yield takeLatest(GET_ASSIGNED_TODOS, getAllAssignedTodosSaga),

        yield takeLatest(CREATE_TEAM, createTeamSaga),
        yield takeLatest(GET_ALL_ROLES, getAllRoleSaga),
        yield takeLatest(DELETE_ROLE, deleteRoleSaga),
        yield takeLatest(EDIT_ROLE, editRoleSaga),
        yield takeLatest(GET_ROLE_BY_ID, getRolebyId)
    ];
}

import { CREATE_BY_URL, DELETE_BY_ID, UPDATE_BY_ID } from "../storeTypes";


export const ActionUpdateById = (payload, callBack) => {
    return {
        type: UPDATE_BY_ID,
        payload,
        callBack
    };
};
export const ActionDeleteById = (payload, callBack) => {
    return {
        type: DELETE_BY_ID,
        payload,
        callBack
    };
};
export const ActionCreateByUrl = (payload, callBack) => {
    return {
        type: CREATE_BY_URL,
        payload,
        callBack
    };
};

import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Paragraph, Text } from 'theme-ui';
import { setPagination } from '../../../../../store/actions/Pagination.action';
import { getReportHistory } from '../../../../../store/actions/Rating.Action';
import DataTable from '../../../../Tabels/Tabel';
import { FaStar } from 'react-icons/fa';
import { getApiEndPoint } from '../../../../../utils/GenerateQueryString';
import moment from 'moment';
import TableActions from '../../../Roles/TableActions';
import EditRatingModal from '../EditRatingModal';

interface ReviewProps {
    tradeNameRef: string;
    showHistory: boolean;
    handleEdit?: (rowData: any) => void;
}
export const ReportHistory: React.FC<ReviewProps> = ({ tradeNameRef, showHistory, handleEdit }) => {
    const { reportHistory, loader } = useSelector((state: any) => state.rating);
    const dispatch = useDispatch();
    const { paginations } = useSelector((state: any) => state.pagination);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);
    useEffect(() => {
        return () => {
            dispatch(setPagination({
                limit: 15,
                currentPage: 1,
            }))
        }
    }, [])
    useEffect(() => {
        if (tradeNameRef) {
            let apiEndPoint = getApiEndPoint({ limit: paginations?.limit, currentPage: paginations?.currentPage }, paginations?.searchUrl, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
            dispatch(getReportHistory(apiEndPoint))
        }
    }, [paginations]);

    const columns = useMemo<ColumnDef<any>[]>(() => [

        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((paginations?.currentPage - 1) * paginations?.limit) + row.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "projectName",
            header: 'Project Name',
            cell: ({ row }) => {
                return (
                    <p>{row?.original?.projectName}</p>
                )
            },
            filterType: "textbox"
        },
        {
            accessorKey: "tradeName",
            header: 'Trade Name',
            cell: ({ row }) => {
                return (
                    <p>{row?.original?.tradeName}</p>
                )
            },
            filterType: 'textbox',
        },
        {
            header: 'Worker',
            accessorKey: "workerName",
            id: 'taskWorker',
            cell: ({ row }) => {
                return (
                    <Text >{row?.original?.workerName}</Text>
                )
            },
            filterType: 'textbox',

        },
        {
            header: 'Phase',
            accessorKey: "projectPhaseLabel",
            cell: ({ row }) => {
                return (
                    <Text >{row?.original?.workerName}</Text>
                )
            },
            filterType: 'textbox',
        },
        {
            header: 'Review Date',
            accessorKey: "createdAt",
            cell: ({ row }) => {
                const reviewDate = row?.original?.createdAt;
                return (
                    <p>{reviewDate ? moment(reviewDate.split('T')[0]).format("MMM DD, YYYY") : ""}</p>
                )
            },
            filterType: 'date',
        },
        {
            header: 'Criteria',
            accessor: "criteria",
            cell: ({ row }) => {

                return (
                    <div >
                        {row?.original?.criteria?.map((criteria: any, index: any) => (
                            <Flex key={index} sx={{ borderTop: `${index !== 0 && "0.5px solid #D3D5D9"}`, paddingY: "5px" }}>
                                <Text sx={{ fontWeight: "600" }}>{criteria?.rating}</Text> <FaStar color="#FFB721" className='mx-2 mt-1' />
                                <Paragraph sx={{ textWrap: "nowrap" }}>{criteria?.name}</Paragraph>
                            </Flex>
                        ))}
                    </div>
                );
            },
            filter: 'textbox'
        },
        {
            header: 'Edit',
            accessorKey: "Accessor",
            enableColumnFilter: false,
            cell: ({ row }) => {
                console.log('chk135', row);

                return (
                    <TableActions row={row} editTo={``} showView={false} showDelete={false} deleteMessage={''} deleteApi={''} toggleRefresh={() => { }} onClick={() => {
                        setSelectedRowData(row.original);
                        setIsModalOpen(true);
                    }} />
                )
            },
        }
    ], [reportHistory]);
    return (
        <>
            <DataTable
                loader={loader}
                columns={columns}
                data={reportHistory?.data || []}
                TotalLength={reportHistory?.count || 15}
                perPageCount={paginations?.limit || 15}
                currentPage={paginations?.currentPage}
                fixedColumn={true}
                withoutURLFilter={true}
                onPerPageChange={(itemsPerPage: string) => {
                    dispatch(setPagination({
                        ...paginations,
                        limit: itemsPerPage
                    }))
                }}
                onPageChange={(value) => {
                    dispatch(setPagination({
                        ...paginations,
                        currentPage: value
                    }))
                }}
            />
            {isModalOpen && selectedRowData && (
                <EditRatingModal
                    open={isModalOpen}
                    toggle={toggleModal}
                    data={selectedRowData}
                    lastUpdatedTwoWeeksAgo={false}
                    columns={columns}
                />
            )}

        </>
    )
}

import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Text } from 'theme-ui';
import DataTable from '../../../Tabels/Tabel';
import TableActions from '../../users/TableActions';
import { TabelHeadingTxt } from '../../../Text/TabelCommonTxt';
import { useLocation, useNavigate } from 'react-router-dom';
import plus from "../../../assets/images/Icon/plus.svg";
import { getTradingRating } from '../../../../store/actions/Trading.Rating.Action';
import { setPagination } from '../../../../store/actions/Pagination.action';
import { getApiEndPoint } from '../../../../utils/GenerateQueryString';

const TradingRatingCriteria: React.FC = () => {
    const navigate = useNavigate();
    const { paginations } = useSelector((state: any) => state.pagination);
    const [refresh, setRrefresh] = useState<boolean>(false);
    const { tradingRatingData, loader } = useSelector((state: any) => state.trading);
    const dispatch = useDispatch()
    const location = useLocation();

    const handleclick = () => {
        navigate("/create-trading-rating")
    }

    const columns = useMemo<ColumnDef<any>[]>(() => [
        // {
        //     accessorKey: "id",
        //     enableColumnFilter: false,
        //     id: "select",
        //     header: ({ table }) => (
        //         <div className="d-flex justify-content-center">
        //             <IndeterminateCheckbox
        //                 {...{
        //                     checked: table.getIsAllRowsSelected(),
        //                     indeterminate: table.getIsSomeRowsSelected(),
        //                     onChange: table.getToggleAllRowsSelectedHandler(),
        //                 }}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }) => (
        //         <IndeterminateCheckbox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        // },
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((paginations?.currentPage - 1) * paginations?.limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "taskId.name",
            header: 'Task',
            cell: info => info.getValue(),
        },
        // {
        //     accessorKey: "ratingCriteria.ratingCriteria.length",
        //     header: 'Rating Criteria',
        //     cell: info => info.getValue(),
        // },
        {
            header: 'Accessor',
            accessorKey: "Accessor",
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <TableActions row={row} editTo={`/edit-trading-rating/${row?.original?._id}`} deleteMessage='Are you sure you want to delete this trading rating?' deleteApi={`trading-rating-criteria/delete-trading-criteria/${row?.original?._id}`} toggleRefresh={() => setRrefresh(true)} />
                )
            },
        }
    ], [tradingRatingData?.data]);
    useEffect(() => {
        return () => {
            dispatch(setPagination({
                limit: 15,
                currentPage: 1,
            }))
        }
    }, [])
    useEffect(() => {
        let apiEndPoint = getApiEndPoint(paginations, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
        dispatch(getTradingRating(apiEndPoint))
    }, [location?.search]);
    useEffect(() => {
        if (refresh) {
            let apiEndPoint = getApiEndPoint(paginations, "", columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
            dispatch(getTradingRating(apiEndPoint));
            setRrefresh(false);
        }
    }, [refresh])
    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Trading Rating Criteria' Count={tradingRatingData?.count || 15} BtnTxt='Create Trading Rating Criteria' src={plus} onClick={handleclick} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                loader={loader}
                columns={columns}
                data={tradingRatingData?.data || []}
                TotalLength={tradingRatingData?.count||15}
                perPageCount={paginations?.limit}
                currentPage={paginations?.currentPage}
                onPerPageChange={(itemsPerPage) => {
                    dispatch(setPagination({
                        limit: itemsPerPage,
                        currentPage: 1
                    }));
                    setRrefresh(true);
                }}
                onPageChange={(value) => {
                    dispatch(setPagination({
                        ...paginations,
                        ...{ currentPage: value }
                    }));
                    setRrefresh(true);
                }} />

        </div>
    )
};

export default TradingRatingCriteria;

import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface MarkdownProps {
    markdown: string;

}

const MarkDownParagraph: React.FC<MarkdownProps> = ({ markdown }) => {
    const renderers: Partial<Components> = {
        a: ({ href, children }) => (
            <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
            </a>
        ),
    };

    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={renderers}
        >
            {markdown}
        </ReactMarkdown>
    );
}

export default MarkDownParagraph
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomRating from "../../../CustomRating";
import { Box, Button, Flex, Text, Textarea } from "theme-ui";
import { getReportHistory, getReportHistoryById } from "../../../../store/actions/Rating.Action";
import moment from "moment";
import { FaStar } from "react-icons/fa";
import { ActionUpdateById } from "../../../../store/actions/CommonAction";
import { getApiEndPoint } from "../../../../utils/GenerateQueryString";

interface RatingModalProps {
    open: boolean;
    toggle: () => void;
    data: any;
    columns?: any;
    lastUpdatedTwoWeeksAgo?: boolean;
}
const EditRatingModal: React.FC<RatingModalProps> = ({ open, toggle, data, lastUpdatedTwoWeeksAgo, columns }) => {
    const dispatch = useDispatch();
    const { rating: RatingCriteria, reportHistoryById } = useSelector(
        (state: any) => state.rating
    );
    const { auther } = useSelector((state: any) => state.auth);
    const reviewId = data?._id;
    const { paginations } = useSelector((state: any) => state.pagination);

    const [rating, setRating] = useState<number[]>([]);
    const [comment, setComment] = useState<string[]>([]);
    const commentRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
    const suffixes = [
        "Cleanliness (Formatting, No overlapping) :",
        "Speed (Time to Completion) :",
        "Accuracy (Details, Correctness) :",
        "Comprehension (Understand Scope and Conditions) :",
        "PS Management (Task, New Info, Delivery Note Checklists) :\t",
    ];
    useEffect(() => {
        if (reviewId) {
            dispatch(getReportHistoryById(reviewId));
        }
    }, [dispatch, reviewId]);
    useEffect(() => {
        if (reportHistoryById?.data) {
            const existingData = reportHistoryById.data;

            const prefilledRatings: number[] = [];
            const prefilledComments: string[] = [];

            suffixes.forEach((suffix) => {
                const ratingKey = Object.keys(existingData).find(
                    (key) => key.includes(suffix) && !key.includes("_Comment")
                );
                const commentKey = `${ratingKey}_Comment`;

                prefilledRatings.push(ratingKey ? existingData[ratingKey] : 0);
                prefilledComments.push(commentKey ? existingData[commentKey] : "");
            });

            setRating(prefilledRatings);
            setComment(prefilledComments);
        }
    }, [reportHistoryById]);
    const handleRatingChange = (index: number, newRating: number) => {
        const updatedRatings = [...rating];
        updatedRatings[index] = newRating;
        setRating(updatedRatings);
    };
    const handleCommentChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const updatedComments = [...comment];
        updatedComments[index] = event.target.value;
        setComment(updatedComments);

        const textarea = commentRefs.current[index];
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };
    const handleSave = () => {

        const criteria = suffixes.map((suffix) => {
            const ratingKey = Object.keys(reportHistoryById?.data).find(
                (key) => key.includes(suffix) && !key.includes("_Comment")
            );
            const commentKey = `${ratingKey}_Comment`;

            return {
                // id: null,
                name: suffix,
                rating: rating[suffixes.indexOf(suffix)] || 0,
                comment: comment[suffixes.indexOf(suffix)] || "",
            };
        });

        const payload = {
            workerName: data?.workerName || "Unknown Worker",
            workerNameRef: data?.workerNameRef,
            ratingGivenBy: data?.ratingGivenBy || auther?.firstName,
            ratingGivenByRef: data?.ratingGivenByRef,
            projectName: data?.projectName || "Unknown Project",
            projectNameRef: data?.projectName_ref,
            tradeName: data?.tradeName || "Unknown Trade",
            tradeNameRef: data?.tradeNameRef,
            projectPhase: data?.projectPhaseLabel,
            criteria,
        };

        if (reviewId) {
            // Dispatch the update action
            dispatch(ActionUpdateById({ Query: `/report/edit-report/${reviewId}`, obj: payload }, (res: any) => {
                if (res.status) {
                    let apiEndPoint = getApiEndPoint({ limit: paginations?.limit, currentPage: paginations?.currentPage }, paginations?.searchUrl, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
                    dispatch(getReportHistory(apiEndPoint))
                }
            }

            ))
            toggle();
        }
    };


    return (
        <Modal isOpen={open} toggle={toggle} centered size="xl">
            <ModalHeader toggle={toggle}>Edit Ratings</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={8} className="pe-0 pb-3">
                        <Flex sx={{ justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                            <ModalBody>
                                {suffixes?.map((suffix, index) => (
                                    <div className="mb-4" key={index}>
                                        <h5>{suffix}</h5>
                                        <CustomRating
                                            count={5}
                                            rating={rating[index] || 0}
                                            onChange={(newRating: number) =>
                                                handleRatingChange(index, newRating)
                                            }
                                        />
                                        <Textarea
                                            className="form-control mt-2"
                                            placeholder="Enter comment here..."
                                            value={comment[index] || ""}
                                            rows={2}
                                            onChange={(event: any) => handleCommentChange(index, event)}
                                            ref={(el) => (commentRefs.current[index] = el)}
                                        />
                                    </div>
                                ))}
                            </ModalBody>

                            <ModalFooter className="gap-3">
                                <Button variant="CancelButton" onClick={toggle}>
                                    Cancel
                                </Button>
                                <Button variant="SaveButton" onClick={handleSave}>
                                    Save
                                </Button>
                            </ModalFooter>
                        </Flex>
                    </Col>
                    <Col sm={4} className="border-start p-4">
                        <div>Activity</div>
                        <p className="text-muted">All Activities</p>
                        <Box
                            className="border p-2"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">Project Name :</p>
                            <Text className="text-muted">{data?.projectName}</Text>
                        </Box>
                        <Box
                            className="border p-2 mt-3"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">Task Name :</p>
                            <Text className="text-muted">{data?.tradeName}</Text>
                        </Box>
                        <Box
                            className="border p-2 my-3"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">Stage Name :</p>
                            <Text className="text-muted">{data?.projectPhaseLabel}</Text>
                        </Box>
                        <Box
                            className="border p-2"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">Worker :</p>
                            <Text className="text-muted">{data?.workerName || '-'} </Text>
                        </Box>
                        <Box
                            className="border p-2 my-3"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">UpdatedAt :</p>
                            <Text className="text-muted">
                                {moment(RatingCriteria?.updatedAt).format('DD MMM YYYY, h:mm A')}
                                {lastUpdatedTwoWeeksAgo ? (
                                    <Text sx={{ cursor: 'pointer' }}>
                                        <FaStar color="#FFB721" />
                                    </Text>
                                ) : (
                                    <Text sx={{ cursor: 'pointer' }}>
                                        <FaStar color="#006D8F" />
                                    </Text>
                                )}
                            </Text>
                        </Box>
                    </Col>
                </Row>

            </ModalBody>
        </Modal>
    )
}

export default EditRatingModal
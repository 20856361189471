import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Label } from "theme-ui";
import * as Yup from "yup";
import { CreateRoleFormValues, RootState } from "../../../../@types";
import { createRole, getAllRoles } from "../../../store/actions/user.action";
import theme from "../../../theme";


const validationSchema = Yup.object({
    RoleName: Yup.string().required("Role Name is required"),
    description: Yup.string().required("Description is required"),
});

const CreateRole: React.FC = () => {
    const { roles } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleCreate = (values: CreateRoleFormValues) => {
        const { RoleName, description, permissions } = values;

        const permissionKeys = Object.keys(permissions).reduce((acc, entity) => {
            const actions = Object.keys((permissions as any)[entity]);
            const entityPermissions = actions.filter(
                (action) => (permissions as any)[entity][action]
            );
            return [...acc, ...entityPermissions.map((action) => `${entity}.${action}`)];
        }, [] as string[]);

        const payload = {
            RoleName: RoleName,
            Description: description,
            Permission: permissionKeys,
        };
        dispatch(createRole(payload))

    };
    const cancelDelete = () => {
        navigate('/role')
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);
                dispatch(getAllRoles());
            } finally {
                // setLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);
    const masterData = roles ? Array.from(new Set(roles?.data.flatMap((role: any) => role?.Permission.map((p: any) => p.split(".")[0])).filter((item: any) => item !== ""))) : [];

    const theadData = roles ? Array.from(new Set(roles?.data.flatMap((role: any) => role.Permission.map((p: any) => p.split(".")[1])).filter((item: any) => item !== ""))) : [];

    const initialValues: CreateRoleFormValues = {
        _id: '',
        RoleName: "",
        description: "",
        permissions: masterData.reduce((acc: Record<string, Record<string, boolean>>, entity: any) => {
            (acc as Record<string, Record<string, boolean>>)[entity] = theadData.reduce((actionsAcc: Record<string, boolean>, action: any) => {
                actionsAcc[action] = false;
                return actionsAcc;
            }, {} as Record<string, boolean>);
            return acc;
        }, {} as Record<string, Record<string, boolean>>),

    };



    return (
        <div className="main-content">
            <h1 style={{ margin: '12px' }}>Create New Role</h1>
            <hr />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleCreate}
            >
                <Form style={{ margin: '12px' }}>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="RoleName" className="form-label">Role Name*</label>
                            <Field type="text" id="RoleName" name="RoleName" className="form-control" placeholder="Role Name" required />
                            <ErrorMessage name="RoleName" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-9">
                            <label htmlFor="description" className="form-label">Description*</label>
                            <Field type="text" id="description" name="description" className="form-control" placeholder="Enter Description" required />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="mt-5">Permissions</h5>
                            <div className="table-responsive">
                                <table className="table table-bordered custom-table w-100 mt-2">
                                    <thead className="table-warning">
                                        <tr>
                                            <th>Master Table</th>
                                            {theadData.map((theadItem: any) => (
                                                <th key={theadItem}>{theadItem}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {masterData.map((masterItem: any) => (
                                            <tr key={masterItem}>
                                                <td>{masterItem}</td>
                                                {theadData.map((theadItem: any) => (
                                                    <td key={theadItem}>
                                                        <Label>
                                                            <Field
                                                                type="checkbox"
                                                                id={`${masterItem}-${theadItem}`}
                                                                name={`permissions.${masterItem}.${theadItem}`}
                                                                as={Checkbox}
                                                            />
                                                        </Label>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={cancelDelete}> Cancel </Button></div>
                        <div className='col-md-4'><Button type="submit" sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }}>Submit</Button></div>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

export default CreateRole;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Badge, Box, Card, Divider, Flex, Link, Text } from 'theme-ui';
import { getAllBcPcHistory } from '../../store/actions/masterData.action';
import moment from 'moment';
import theme from '../../theme';

const BCPCRevHistory = ({ Open, setOpen, actionObject }: any) => {
  const { bcpcHistory } = useSelector((state: any) => state.masterData);
  const dispatch = useDispatch();

  const projectName = bcpcHistory?.data?.BCs?.[0]?.projectId?.name || bcpcHistory?.data?.PCs?.[0]?.projectId?.name || bcpcHistory?.data?.Revs?.[0]?.projectId?.name;
  const stageColor = bcpcHistory?.data?.BCs?.[0]?.stageId?.color || bcpcHistory?.data?.PCs?.[0]?.stageId?.color || bcpcHistory?.data?.Revs?.[0]?.stageId?.color;
  const stageName = bcpcHistory?.data?.BCs?.[0]?.stageId?.name || bcpcHistory?.data?.PCs?.[0]?.stageId?.name || bcpcHistory?.data?.Revs?.[0]?.stageId?.name;

  const togglePopover = () => {
    setOpen(!Open);
  };
  useEffect(() => {
    dispatch(getAllBcPcHistory(actionObject?.stageId || actionObject?.stageDataId))
  }, [actionObject?.stageId, actionObject?.stageDataId, dispatch]);



  return (
    <Modal isOpen={Open} centered scrollable size='xl' toggle={togglePopover}>
      <ModalHeader toggle={togglePopover}>{projectName}</ModalHeader>
      <ModalBody >
        <Card sx={{ boxShadow: "none", border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "12px 20px", position: "relative" }}>
          <Flex >
            <Badge sx={{
              color: stageColor ? stageColor : 'black',
              border: stageColor ? `1px solid ${stageColor}` : '1px solid black',
              borderRadius: 6,
              backgroundColor: "transparent",
              margin: "5px",
              fontSize: '20px'
            }}>
              {stageName}
            </Badge>
          </Flex>
          <Divider />
          {bcpcHistory?.data?.BCs && (
            <>
              <Flex >
                <Text variant="SelectLabel" sx={{ color: '#7AC555' }}>Background Change</Text>
              </Flex>
              {bcpcHistory?.data?.BCs?.map((bc: any, index: number) => (
                <Box key={index} sx={{ listStyleType: 'none' }}>
                  {bc?.payload?.name && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Name:</Text> {`${bc?.payload?.name}. (${bc?.payload?.number})`}</Text>}
                  {bc?.payload?.description && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Description:</Text> {bc?.payload?.description}</Text>}
                  {bc?.payload?.emailLink && bc?.payload?.emailLink.length > 0 && (
                    <Text as='li' variant="SelectLabel">
                      Email Link:
                      <Text variant="Poppins14Normal" className='ms-2'>
                        {bc?.payload?.emailLink.map((email: string, emailIndex: number) => (
                          <React.Fragment key={emailIndex}>
                            <Link
                              target='_blank'
                              href={email}
                              sx={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                              {email}
                            </Link>
                            {emailIndex < bc?.payload?.emailLink.length - 1 && ', '}
                          </React.Fragment>
                        ))}
                      </Text>
                    </Text>
                  )}
                  {bc?.createdAt && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Created Date:</Text>  {moment(bc?.createdAt).format('YYYY-MM-DD')}</Text>}
                </Box>
              ))}
            </>
          )}
          <Divider />
          {bcpcHistory?.data?.PCs && (
            <>
              <Flex >
                <Text variant="SelectLabel" sx={{ color: '#03AECC' }}>Plan Check</Text>
              </Flex>
              {bcpcHistory?.data?.PCs?.map((pc: any, index: number) => (
                <Box key={index} sx={{ listStyleType: 'none' }}>
                  {pc?.payload?.name && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Name:</Text> {`${pc?.payload?.name}. (${pc?.payload?.number})`}</Text>}
                  {pc?.payload?.description && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Description:</Text> {pc?.payload?.description}</Text>}
                  {pc?.payload?.emailLink && pc?.payload?.emailLink.length > 0 && (
                    <Text as='li' variant="SelectLabel">
                      Email Link:
                      <Text variant="Poppins14Normal" className='ms-2'>
                        {pc?.payload?.emailLink.map((email: string, emailIndex: number) => (
                          <React.Fragment key={emailIndex}>
                            <Link
                              target='_blank'
                              href={email}
                              sx={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                              {email}
                            </Link>
                            {emailIndex < pc?.payload?.emailLink.length - 1 && ', '}
                          </React.Fragment>
                        ))}
                      </Text>
                    </Text>
                  )}
                  {pc?.createdAt && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Created Date:</Text>  {moment(pc?.createdAt).format('YYYY-MM-DD')}</Text>}
                </Box>
              ))}
            </>
          )}
          <Divider />
          {bcpcHistory?.data?.Revs && (
            <>
              <Flex >
                <Text variant="SelectLabel" sx={{ color: '#FF8FD4' }}>Revision</Text>
              </Flex>
              {bcpcHistory?.data?.Revs?.map((rev: any, index: number) => (
                <Box key={index} sx={{ listStyleType: 'none' }}>
                  {rev?.payload?.name && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Name:</Text> {`${rev?.payload?.name}. (${rev?.payload?.number})`}</Text>}
                  {rev?.payload?.description && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Description:</Text> {rev?.payload?.description}</Text>}
                  {rev?.payload?.emailLink && rev?.payload?.emailLink.length > 0 && (
                    <Text as='li' variant="SelectLabel">
                      Email Link:
                      <Text variant="Poppins14Normal" className='ms-2'>
                        {rev?.payload?.emailLink.map((email: string, emailIndex: number) => (
                          <React.Fragment key={emailIndex}>
                            <Link
                              target='_blank'
                              href={email}
                              sx={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                              {email}
                            </Link>
                            {emailIndex < rev?.payload?.emailLink.length - 1 && ', '}
                          </React.Fragment>
                        ))}
                      </Text>
                    </Text>
                  )}
                  {rev?.createdAt && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Created Date:</Text>  {moment(rev?.createdAt).format('YYYY-MM-DD')}</Text>}
                </Box>
              ))}
            </>
          )}
        </Card>
      </ModalBody>
    </Modal>
  )
}

export default BCPCRevHistory
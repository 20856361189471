import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Card, Flex, Link, Text } from "theme-ui";
import { uploadIcon } from "../../helper/uploadType";
import downloadIcon from "../../Components/assets/images/Icon/download.svg";
import deleteIcon from "../../Components/assets/images/Icon/delete.svg";
import { bucketUrl } from "../../utils/global";
import plusIcon from "../../Components/assets/images/Icon/plus.svg";
import { Col, Row } from "react-bootstrap";
import { Inputform } from "../forms-component/InputForm";
import { useFormik } from "formik";
import { mediaUpload, mediaUploadDelete } from "../../store/actions/media.action";
import { Modal, ModalBody } from "reactstrap";
import * as Yup from 'yup';
import { saveFileAs } from "../../utils/download";
import FormSelect from "../forms-component/globalSelect";
import { useSelector } from "react-redux";
import { getUploadTypeNoPagination } from "../../store/actions/masterData.action";
import theme from "../../theme";
import moment from "moment";

const fileTypes = ["JPEG", "PNG", "GIF", "PDF", "JPG", "XLS", "XLSX"];
interface Option {
  label: string;
  value: string;
}
export default function UploadMediaFile({ typeId, mediaData, type, loader, id }: any) {

  const dispatch = useDispatch();
  const [uploadType, setUploadType] = useState("file");
  const [uploadError, setUploadError] = useState<string>("")
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const { uploadCategory } = useSelector((state: any) => state.masterData);

  const uploadTypeOptions: Option[] = uploadCategory?.data?.map((obj: any) => ({
    label: obj?.name,
    value: obj?.name,
  }));

  const [fileName, setFileName] = useState<any>({});

  const urlRegex = /^(https?|ftp|http):\/\/[^\s/$.?#].[^\s]*$/ig;

  useEffect(() => {

    dispatch(getUploadTypeNoPagination())
  }, [dispatch]);
  const formik = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        file: "",
        description: "",
        fileType: "",
        date: "",
      },
      validationSchema: Yup.object().shape({
        file: uploadType === "URL" ? Yup.string().matches(urlRegex, 'Invalid URL format').required('This URL is required') : Yup.mixed().required('This file is required'),
        fileType: Yup.string().required('File type is required'),
        date: Yup.string().required('Date is required'),
      }),
      onSubmit: async (value) => {
        const { file, description, fileType, date } = value;
        const formData = new FormData();

        if (file) {
          if (typeof file === "string") {
            formData.append('file', value?.file);
            formData.append('type', type);
            formData.append('data', JSON.stringify({ ...typeId, description, fileType, date }));
            formData.append('uploadType', 'URL');

            dispatch(mediaUpload(formData));
          }

          formik.resetForm()
        } else {
          // setUploadError("This file is required")
        }

      }
    }
  );
  const { handleSubmit, setFieldValue, handleBlur, values, errors, touched } = formik;

  const handleProfileImageChange = (event: any) => {
    let isType = false
    const files = event.currentTarget.files;

    const isFileTypeAllowed = (file: any) => {
      const fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1).toUpperCase();
      return fileTypes.includes(fileExtension);
    };

    Array.from(files).forEach((file: any) => {

      if (isFileTypeAllowed(file)) {
        isType = true

        const formData = new FormData();
        formData.append(`file`, file);
        formData.append('type', type);
        formData.append('data', JSON.stringify(typeId));
        formData.append('uploadType', 'File');
        dispatch(mediaUpload(formData));

      } else {
        isType = false
      }
    });

    if (isType) {
      setUploadError("")
    }
    else {
      setUploadError(`Please Upload the ${fileTypes}`)
    }
  };

  const confirmDelete = () => {
    const deleteData = { file: fileName.file, _id: fileName._id, type: type };
    dispatch(mediaUploadDelete(deleteData, id));
    setIsDeleteModelOpen(false);
  };

  const cancelDelete = () => {
    setIsDeleteModelOpen(false);
  };

  const handleClick = async (d: any) => {
    const imageUrl = await d.d;
    let fileName = imageUrl.split("/").pop().split(".")[0];

    saveFileAs(imageUrl, fileName)
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="container">
        <Row className="gap-3">
          <Box>
            <Text sx={{ fontSize: "14px", fontWeight: "500" }}>Attachments</Text>
          </Box>
          {mediaData?.length > 0 && mediaData?.map((imageUrl: any, index: any) => {

            const fileName = imageUrl.file
            const ex = imageUrl?.file?.substr(imageUrl.file.lastIndexOf('.') + 1)

            const fileUrl = imageUrl?.uploadType === "File" ? `${bucketUrl}${imageUrl.file}` : imageUrl.file;

            return (
              <Col xs="auto" key={index} className="p-0">

                <Card sx={{ borderRadius: "12px", border: "1px solid #D3D5D9", padding: "16px", width: "280px" }}>
                  <Box>
                    {imageUrl?.type?.length > 0 && (
                      <p style={{ fontWeight: 500, textAlign: 'center', color: '#7743DB' }}>
                        Type: <Text variant='Poppins16Normal25lh' sx={{ fontWeight: 'normal', color: '#7743DB' }}>{imageUrl?.type}</Text>
                      </p>
                    )}
                  </Box>
                  <Flex sx={{ justifyContent: 'center', alignItems: "center", gap: "10px", mt: 2 }}>
                    <img src={imageUrl?.uploadType === "URL" ? uploadIcon("com") : uploadIcon(ex)} alt={ex} width="30px" height="30px" />
                    <Link href={fileUrl} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', wordWrap: 'break-word' }}>
                      <Box sx={{ textAlign: 'left', color: 'black', overflowWrap: 'break-word' }}>
                        <p className="card-text">{fileName}</p>
                        {imageUrl?.description?.length > 0 && (
                          <p className="card-title" style={{ fontWeight: 500, textAlign: 'left' }}>
                            Description: <Text variant='Poppins16Normal25lh' sx={{ fontWeight: 'normal' }}>{imageUrl?.description}</Text>
                          </p>

                        )}
                        <>
                          {imageUrl?.date?.length > 0 && (
                            <p className="card-title" style={{ fontWeight: 500, textAlign: 'left' }}>
                              Date: <Text variant='Poppins16Normal25lh' sx={{ fontWeight: 'normal' }}>{imageUrl?.date ? moment(imageUrl?.date?.split('T')[0]).format("MMM DD, YYYY") : ""}</Text>
                            </p>

                          )}</>
                      </Box>
                    </Link>
                  </Flex>
                  <Flex sx={{ gap: "10px", justifyContent: "end" }}>
                    {imageUrl?.uploadType === "File" && (
                      <div style={{ cursor: "pointer" }} onClick={() => handleClick({ d: `${bucketUrl + imageUrl.file}` })}>
                        <img src={downloadIcon} alt={"download"} />
                      </div>
                    )}
                    <Box sx={{ cursor: "pointer" }} onClick={(e) => {
                      e.preventDefault();
                      setFileName({ file: imageUrl?.file, _id: imageUrl?._id });
                      setIsDeleteModelOpen(true);
                    }}>
                      <img src={deleteIcon} alt={"delete pic"} />
                    </Box>
                  </Flex>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <Flex sx={{ justifyContent: "space-between", mt: "3rem" }}>
        <Box>
          <Text sx={{ fontSize: "14px", fontWeight: "500" }}>Upload new attachments</Text>
        </Box>
        <Flex sx={{ gap: "0.8rem" }}>
          <img src={uploadIcon("com")} alt={"link display"} style={{ cursor: "pointer" }} onClick={() => setUploadType("URL")} />
          {/* <img src={zoomIcon} alt={"zoom display"} style={{ cursor: "pointer" }} /> */}
          <img src={plusIcon} alt={"zoom display"} style={{ cursor: "pointer" }} onClick={() => setUploadType("file")} />

        </Flex>
      </Flex>
      {
        uploadType === "file" ? <> <input
          multiple
          id="fileInput"
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => handleProfileImageChange(e)}
        />
          <label htmlFor="fileInput" className="d-block">
            <Box
              // onDrop={handleDrop}
              // onDragOver={handleDragOver}
              sx={{
                border: `2px solid ${theme?.colors?.lightbackground}`,
                padding: '40px 14px',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: loader ? "not-allowed" : 'pointer',
                borderRadius: '12px',

              }}

            >
              {"Drop your files here to upload"}
            </Box>
          </label>
          {uploadError && <Box sx={{ color: "red", mt: "0.5rem" }}>{uploadError}</Box>}
        </>
          : <><Flex sx={{ alignItems: "end", gap: "1rem" }}>
            <Box className="col-3">
              <FormSelect
                label={"Upload Type"}
                value={values?.fileType}
                placeholder={"Select Upload Type"}
                options={uploadTypeOptions || []}
                isMulti={false}
                // touched={touched?.type}
                // errors={errors?.type}
                onBlur={handleBlur}
                Name='fileType'
                onChange={(values: any) => {
                  console.log('value', values);

                  setFieldValue("fileType", values?.value, true);
                }}
              />

            </Box>
            <Box>
              <Inputform
                type="date"
                label="Date"
                onBlur={handleBlur}
                Name={`date`}
                asterisk={true}
                touched={touched?.date}
                errors={errors?.date}
                OnChange={(event: any) => {
                  const newDate = event.target.value;
                  setFieldValue("date", newDate || null, true);

                }}
                values={values?.date?.split('T')[0] || ''}
                Placeholder=""
              />

            </Box>
            <Inputform
              label={"URL"}
              Placeholder={"Enter URL to upload"}
              Name={"file"}
              type="url"
              asterisk={true}
              Title_sx={{ color: "grey3" }}
              values={uploadType === "URL" ? values?.file : ""}
              onBlur={handleBlur}
              // errors={errors?.file}
              // touched={touched?.file}
              OnChange={(event: any) => {
                const newName = event.target.value;
                setFieldValue("file", newName, true);
              }}

            />
            <Inputform
              label={"Description"}
              Placeholder={"Enter description"}
              Name={"description"}
              type="text"
              Title_sx={{ color: "grey3" }}
              values={uploadType === "URL" ? values?.description : ""}
              onBlur={handleBlur}
              OnChange={(event: any) => {
                const newDescription = event.target.value;
                setFieldValue("description", newDescription, true);
              }}
            />
            <Box >
              <Button disabled={loader} type="submit">{"Upload"}</Button>
            </Box>
          </Flex>
            {uploadError && <Box sx={{ display: "block", color: "red", mt: "0.5rem" }}>{uploadError}</Box>}
            {errors?.file && touched?.file ? (
              <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                {errors?.file}
              </Text>
            ) : (
              <Text variant="Inter15Regular30lh" sx={{ my: '5px', minHeight: '11px' }}></Text>
            )}
          </>
      }
      <Modal isOpen={isDeleteModelOpen} toggle={cancelDelete} centered className='w-100'>
        <ModalBody className='mb-0 pb-0'>
          <div style={{
            width: '100%',
            position: 'relative',
            zIndex: 1,
            backgroundColor: 'white',
            border: '1px solid #black',
            boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06);',
            borderRadius: '4px',
            padding: '20px 4px'
          }}>
            <Text className=" w-100" as="p">
              <strong>{"Are you sure you want to delete this Media?"}</strong>
            </Text>
          </div>
        </ModalBody>
        <ModalBody className='d-flex'>
          <Button onClick={confirmDelete} sx={{ marginRight: '10px', width: '50%' }}>Yes</Button>
          <Button onClick={cancelDelete} sx={{ width: '50%' }}>No</Button>
        </ModalBody>
      </Modal >
    </form >
  )
}



import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Flex, Grid, Heading, Image, Label, NavLink, Spinner, Text } from "theme-ui";
import * as Yup from "yup";
import { loginUser } from "../../store/actions/login.action";
import loginimage from "../assets/images/crop-handworkers-making-measures 1.png";
import logoImage from "../assets/images/logo 2 (1).svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../@types";
import theme from '../../theme';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("This field is required"),
  password: Yup.string().min(8, "Password must be at least 8 characters").required("This field is required"),
});

const Login: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state: RootState) => state.auth);

  return (
    <>
      <Grid gap={2} columns={[1, 1, 1, 2, 2]}>
        <Box>
          <Image src={logoImage} alt="logo" sx={{ width: "40%", height: "auto", padding: '23px' }} />
          <Flex sx={{ flexDirection: "column", alignItems: "center", gap: 4, marginTop: '9%', marginBottom: '7%' }}>
            <Heading as="h1" sx={{ color: `${theme.colors?.secondary}`, lineHeight: "heading" }}>
              Sign in to your account
            </Heading>
            <Text sx={{ color: `${theme.colors?.text}`, lineHeight: "body" }}>Start your journey with us</Text>
            <Formik
              initialValues={{ email: "", password: "", rememberMe: false }}
              validationSchema={LoginSchema}
              onSubmit={(values, actions) => {
                const { email, password } = values;
                const payload = {
                  Email: email,
                  Password: password,
                };
                dispatch(loginUser(payload,
                  (Response: any) => {
                    if (Response.status === 200) { }
                    else { }
                  }
                ));
              }}>
              {({ errors, touched }) => (
                <Form style={{ width: '77%' }}>
                  <Label htmlFor="email" sx={{ fontWeight: "body", marginTop: 3, fontSize: 2, lineHeight: "body" }}>
                    Email:
                  </Label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    disabled={loader}
                    placeholder="Enter your email"
                    className={`form-control mt-1 ${errors.email && touched.email ? "is-invalid" : ""}`}
                    sx={{ border: "1px solid", borderColor: `${theme.colors?.primary}`, padding: "10px 14px 10px 14px" }}
                  />
                  <ErrorMessage name="email" component="div" className="invalid-feedback" />

                  <Label htmlFor="password" sx={{ fontWeight: "body", marginTop: 3, fontSize: 2, lineHeight: "body" }}>
                    Password:
                  </Label>
                  <Field
                    type="password"
                    id="password"
                    name="password"
                    disabled={loader}
                    placeholder="Enter your password"
                    className={`form-control mt-1 ${errors.password && touched.password ? "is-invalid" : ""}`}
                    sx={{ marginBottom: 3, border: `${theme.colors?.primary}`, padding: "10px 14px 10px 14px" }}
                  />
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />

                  <Flex className="d-flex mt-2" sx={{ flexDirection: ["column", "row"], alignItems: ["start", "center"] }}>
                    {/* <Flex sx={{ flexDirection: ["row", "row"], alignItems: "center", width: ["100%", "50%"], marginBottom: [2, 0] }}>
                      <Field className="form-check-input" type="checkbox" id="rememberMe" name="rememberMe" />
                      <Label htmlFor="rememberMe" className="form-check-label" sx={{ marginLeft: [1, 2], marginTop: [0, 0] }}>
                        Remember me
                      </Label>
                    </Flex> */}
                    <NavLink href="/forgot-password" sx={{ color: `${theme.colors?.primary}`, marginLeft: [0, "auto"], display: "block", fontWeight: "body", marginTop: [2, 0] }}>
                      Forgot your password?
                    </NavLink>
                  </Flex>

                  <Button
                    type="submit"
                    disabled={loader}
                    padding={[2, 4, 2, 4]}
                    sx={{ variant: "primary", width: "100%", borderRadius: "button", fontSize: 2, marginTop: 3 }}
                  >
                    {loader ? (<Spinner height={25} sx={{ color: theme.colors?.background, width: 3 }} />) : ("Sign In")}
                  </Button>
                  {/* <Text sx={{ top: 3, position: "relative", display: "flex" }}>
                    Don’t have an account?{" "} */}
                  {/* <NavLink href="#" sx={{ color: "primary" }}>
                      Sign up
                    </NavLink> */}
                  {/* </Text> */}
                </Form>
              )}
            </Formik>
          </Flex>
        </Box>
        <Box>
          <Image src={loginimage} alt="login-image" sx={{ width: "100%", minHeight: "auto" }} />
        </Box>
      </Grid>
    </>
  );
};

export default Login;

import { ColumnDef } from '@tanstack/react-table';
import React from 'react';
import { useSelector } from 'react-redux';
import DataTable from '../../Tabels/Tabel';
import { TabelHeadingTxt } from '../../Text/TabelCommonTxt';
import { Divider } from 'theme-ui';
import { useNavigate } from 'react-router-dom';
import plus from "../../assets/images/Icon/plus.svg";

const Statuses: React.FC = () => {
    const navigate = useNavigate();

    const { masterData, tasksowMapping } = useSelector((state: any) => state.masterData);
    const { paginations } = useSelector((state: any) => state.pagination);
    const columns: ColumnDef<any>[] = [
        // {
        //     accessorKey: "id",
        //     id: "select",
        //     enableColumnFilter: false,
        //     header: ({ table }) => (
        //         <div className="d-flex justify-content-center">
        //             <IndeterminateCheckbox
        //                 {...{
        //                     checked: table.getIsAllRowsSelected(),
        //                     indeterminate: table.getIsSomeRowsSelected(),
        //                     onChange: table.getToggleAllRowsSelectedHandler(),
        //                 }}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }) => (
        //         <IndeterminateCheckbox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        // },
        {
            accessorKey: "value",
            header: 'Name',
            cell: info => info.getValue(),
        },

    ];

    // console.log("masterDatamasterData", masterData);

    // const handleclick = () => {
    //     navigate("/create-task-sow")
    // }
    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Status' Count={tasksowMapping?.count || 15} BtnTxt='Create Status' src={plus} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                columns={columns}
                data={masterData?.[0]?.values || []}
                TotalLength={masterData?.count || 15}
                perPageCount={paginations.limit}
                currentPage={paginations.currentPage}
                showNavigation={false}
                onPerPageChange={(itemsPerPage) => {
                    //dispatch(setPagination({
                    //    limit: itemsPerPage,
                    //    currentPage: 1
                    //}));
                }}
                onPageChange={(value) => {
                    //dispatch(setPagination({
                    //    ...paginations,
                    //    ...{ currentPage: value }
                    //}));
                }} />
        </div>
    )
};

export default Statuses;

import React, { useState } from "react";
import searchIcon from "../assets/images/search.svg";
import { baseUrl } from '../../utils/global';

export const SearchBar = (): JSX.Element => {
  const [searchText, setSearchText] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const hostname = window.location.hostname;

  return (
    <div className="frame">
      {
        (
          hostname === "localhost" || hostname === "staging-v2.priority-proengc.com" ||
          baseUrl == "https://staging-apis-v2.priority-proengc.com/"
        ) &&
        <div style={{
          position: 'fixed',
          zIndex: 1000,
          background: 'red',
          color: 'white',
          fontSize: '20px',
          animation: 'blinker 1s linear infinite'
        }}>
          THIS IS A <b>{process.env.REACT_APP_ENV}</b> ENVIRONMENT. ANY CHANGES HAPPENED HERE ARE NOT CONSIDERED AS ACTUAL WORK
        </div>
      }
      <div className="div">
        <img className="search-normal" alt="Search normal" src={searchIcon} />
        <input
          type="text"
          className={`text-wrapper form-control ${isFocused ? 'focused' : ''}`}
          placeholder="Search for anything..."
          value={searchText}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled
        />
      </div>
    </div>
  );
};

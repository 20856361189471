import { useEffect, useRef, useState } from "react";
import { FaCircle } from "react-icons/fa";
import { LuAlertCircle } from "react-icons/lu";
import { Text } from "theme-ui";
import theme from "../../../theme";
export default function CommentPopup() {
    const [IsOpen, setIsOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);

    const handleClick = () => {
        setIsOpen(!IsOpen)
    }
    const handleClickOutside = (event: MouseEvent) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <Text ref={popoverRef}>
            <LuAlertCircle color="#7743DB" onClick={handleClick} className="ms-2" />
            {IsOpen && <>
                <div className="comment-info-popup">
                    New Comments
                    <div className="d-flex gap-3 align-items-baseline" style={{ color: `${theme?.colors?.salmon}` }}>
                        <FaCircle style={{ fill: `${theme?.colors?.salmon} !important` }} />
                        <p className="mt-3" style={{ color: `${theme?.colors?.salmon}` }}>All</p>
                    </div>
                    <div className="d-flex gap-3 align-items-baseline" style={{ color: `${theme.colors?.text}` }}>
                        <FaCircle style={{ fill: '#C3ACD0 !important' }} />
                        <p>PM</p>
                    </div>
                    <div className="d-flex gap-3 align-items-baseline" style={{ color: '#1ADB61' }}>
                        <FaCircle style={{ fill: '#1ADB61 !important' }} />
                        <p>Mechanical</p>
                    </div>
                    <div className="d-flex gap-3 align-items-baseline" style={{ color: '#FFB721' }}>
                        <FaCircle style={{ fill: '#FFB721 !important' }} />
                        <p>Electrical</p>
                    </div>
                    <div className="d-flex gap-3 align-items-baseline" style={{ color: '#2563EB' }}>
                        <FaCircle style={{ fill: '#2563EB !important' }} />
                        <p>Plumbing</p>
                    </div>
                </div>
            </>}
        </Text>
    )
}

import { authProps } from "../../../@types";
import { EMAIL_VERIFY_FAILURE, EMAIL_VERIFY_SUCCESS, FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD__FAILURE, GET_EMAIL_VERIFY, GET_USER_BY_TOKEN, GET_USER_BY_TOKEN_SUCCESS, STORE_LOGIN, STORE_LOGIN_FAILURE, STORE_LOGIN_SUCCESS } from "../storeTypes";

const initialState: authProps = {
    loader: false,
    auther: {
        _id: '',
        FirstName: '',
        LastName: '',
        Email: '',
        ProfileImage: '',
        CanManageTo: '',
        Role: [],
    }
};
const LoginReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case STORE_LOGIN:
            return {
                ...state,
                loader: true,
                // auther: null
            };
        case STORE_LOGIN_SUCCESS:
            return {
                ...state,
                loader: false,
                // auther: action.payload,
            };
        case STORE_LOGIN_FAILURE:
            return {
                ...state,
                loader: false,
                // auther: action.payload,
            };
        case GET_EMAIL_VERIFY:
            return {
                ...state,
                loader: true,
            }
        case EMAIL_VERIFY_SUCCESS:
            return {
                ...state,
                loader: false,
            }
        case EMAIL_VERIFY_FAILURE:
            return {
                ...state,
                loader: false,
            };
        case FORGOT_PASSWORD:
            return {
                ...state,
                loader: true,
                // auther: null
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loader: false,
                // auther: action.payload
            }
        case FORGOT_PASSWORD__FAILURE:
            return {
                ...state,
                loader: false,
                // auther: action.payload,
            };
        case GET_USER_BY_TOKEN:
            return {
                ...state,
                loader: true,
            }
        case GET_USER_BY_TOKEN_SUCCESS:
            return {
                ...state,
                loader: false,
                auther: action.payload
            }
        default:
            return state;
    }
};

export default LoginReducer;

/* eslint-disable no-unused-expressions */
import { call, put, takeLatest } from 'redux-saga/effects';
import { loginUserFailure } from '../actions/login.action';
import { deleteMultipleTaskSuccess, deleteStageSuccess, deleteTaskSuccess, getAllArchitectMasterDataSuccess, getAllBcPcHistorySuccess, getAllDeliveriesProjectViewSuccess, getAllDeliveriesStageViewSuccess, getAllMasterDataForTableSuccess, getAllMasterDataSuccess, getAllStagesMasterDataSuccess, getAllTaskFieldsMasterDataSuccess, getAllTaskMappingMasterDataSuccess, getAllTaskTypesMasterDataSuccess, getAllchecklistMasterDataSuccess, getAllprojectTypeMasterDataSucces, getArchitectDataByIdSuccess, getRatingCriteriaByIdSuccess, getRatingCriteriaSuccess, getStageDataByIdSuccess, getTaskFieldSowDataByIdSuccess, getTaskSowDataByIdSuccess, getTaskTypeDataByIdSuccess, getTaskTypeDataWithoutPaginatationSuccess, getUploadTypeByIdSuccess, getUploadTypeNoPaginationSuccess, getUploadTypeSuccess, getchecklistDataByIdSuccess } from '../actions/masterData.action';
import { deleteMultipleTaskByIdApi, deleteStageByIdApi, deleteTaskByIdApi, getAllArchitectListigApi, getAllBcPcHistoryApi, getAllChecklistListingApi, getAllDeliveryListProjectViewApi, getAllDeliveryListStageViewApi, getAllMasterDataApi, getAllMasterDataForTableApi, getAllStagesListigApi, getAllTaskFieldApi, getAllTaskMapListigApi, getAllTaskTypeApi, getAllUploadTypeByIdDataApi, getAllUploadTypeDataApi, getAllUploadTypeDataNoPaginationApi, getAllprojectTypeListigApi, getArchitectDataByIdApi, getChecklistDataByIdApi, getRatingCriteriaApi, getRatingCriteriaIdApi, getStageDataByIdApi, getTaskFieldSowByIdApi, getTaskSowByIdApi, getTaskTypeByIdApi, getTaskTypeDataWithout } from '../api';
import { DELETE_MULTIPLE_TASK, DELETE_STAGE, DELETE_TASK, GET_ALL_ARCHITECT_MASTERDATA, GET_ALL_BCPC_HISTORY, GET_ALL_CHECKLIST_TEMPLATES, GET_ALL_CHECKLIST_TEMPLATES_BY_ID, GET_ALL_DELIVERIES_PROJECTVIEW, GET_ALL_DELIVERIES_STAGEVIEW, GET_ALL_MASTERDATA_FOR_TABLE, GET_ALL_MASTER_DATA, GET_ALL_PROJECT_TYPE_MASTERDATA, GET_ALL_STAGES_MASTERDATA, GET_ALL_TASK_FIELDS_MASTERDATA, GET_ALL_TASK_MAPPING_MASTERDATA, GET_ALL_TASK_TYPE_MASTERDATA, GET_ARCHITECT_BY_ID, GET_RATING_CRITERIA, GET_RATING_CRITERIA_BY_ID, GET_STAGE_BY_ID, GET_TASK_FIELDS_BY_ID, GET_TASK_MAPPING_BY_ID, GET_TASK_TYPE_BY_ID, GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION, GET_UPLOAD_TYPE, GET_UPLOAD_TYPE_BY_ID, GET_UPLOAD_TYPE_NO_PAGINATION } from '../storeTypes';
import toast from 'react-hot-toast';


function* getAllMasterDataForTable() {
    try {
        const response = yield call(getAllMasterDataForTableApi);
        if (response?.data?.data) {
            yield put(getAllMasterDataForTableSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllArchitectData(action) {
    try {
        const response = yield call(getAllArchitectListigApi, action.payload);
        // console.log("getAllArchitectListigApi", response?.data);
        if (response?.data?.data) {
            yield put(getAllArchitectMasterDataSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getArchitectDataById(action) {
    try {
        const response = yield call(getArchitectDataByIdApi, action.payload);
        // console.log("getAllArchitectListigApi", response?.data);
        if (response?.status === 200) {
            yield put(getArchitectDataByIdSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllChecklistData(action) {
    try {
        const response = yield call(getAllChecklistListingApi, action.payload);
        // console.log("getAllArchitectListigApi", response?.data);
        if (response?.data?.data) {
            yield put(getAllchecklistMasterDataSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getChecklistDataById(action) {
    try {
        const response = yield call(getChecklistDataByIdApi, action.payload);
        // console.log("getAllArchitectListigApi", response?.data);
        if (response?.status === 200) {
            yield put(getchecklistDataByIdSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getStageDataById(action) {
    try {
        const response = yield call(getStageDataByIdApi, action.payload);
        // console.log("getAllArchitectListigApi", response?.data);
        if (response?.status === 200) {
            yield put(getStageDataByIdSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getTaskTypeDataById(action) {
    try {
        const response = yield call(getTaskTypeByIdApi, action.payload);
        // console.log("getAllArchitectListigApi", response?.data);
        if (response?.status === 200) {
            yield put(getTaskTypeDataByIdSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* deleteTask(action) {
    try {
        const response = yield call(deleteTaskByIdApi, action.payload);
        if (response.data.data) {
            yield put(deleteTaskSuccess(response.data.data));
            toast.success("Task Deleted Successfuly!");
            window.location.replace('/task-view')
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* deleteMultipleTask(action) {
    try {
        const response = yield call(deleteMultipleTaskByIdApi, action.payload);
        if (response.data.data) {
            yield put(deleteMultipleTaskSuccess(response.data.data));
            toast.success("Tasks Deleted Successfuly!");
            // window.location.replace('/task-view')
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* deleteStage(action) {
    try {
        const response = yield call(deleteStageByIdApi, action.payload);
        if (response.data.data) {
            yield put(deleteStageSuccess(response.data.data));
            toast.success("Stage Deleted Successfuly!");
            window.location.replace('/stage-view-two')
        }
    } catch (error) {
        if (error?.response?.status === 403 || error?.response?.status === 400) {
            // toast.error(error.response?.message)
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getTaskFieldSowDataById(action) {
    try {
        const response = yield call(getTaskFieldSowByIdApi, action.payload);
        // console.log("getAllArchitectListigApi", response?.data);
        if (response?.status === 200) {
            yield put(getTaskFieldSowDataByIdSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getTaskTypeDataWithoutPaginatation(action) {
    try {
        const response = yield call(getTaskTypeDataWithout);
        // console.log("getAllArchitectListigApi", response?.data);
        if (response?.status === 200) {
            yield put(getTaskTypeDataWithoutPaginatationSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getTaskSowDataById(action) {
    try {
        const response = yield call(getTaskSowByIdApi, action.payload);
        // console.log("getAllArchitectListigApi", response?.data);
        if (response?.status === 200) {
            yield put(getTaskSowDataByIdSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllStaegsData(action) {
    try {
        const response = yield call(getAllStagesListigApi, action.payload);
        // console.log("getAllStagesMasterData", response?.data);
        if (response?.data?.data) {
            yield put(getAllStagesMasterDataSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* getAllProjectTypegsData(action) {
    try {
        const response = yield call(getAllprojectTypeListigApi, action.payload);
        // console.log("getAllProjectTypegsData", response?.data);
        if (response?.data?.data) {
            yield put(getAllprojectTypeMasterDataSucces(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* getAllTaskMappingsData(action) {
    try {
        const response = yield call(getAllTaskMapListigApi, action.payload);
        // console.log("getAllProjectTypegsData", response?.data);
        if (response?.data?.data) {
            yield put(getAllTaskMappingMasterDataSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllTaskFieldData(action) {
    try {
        const response = yield call(getAllTaskFieldApi, action.payload);
        // console.log("getAllTaskFieldApi", response?.data);
        if (response?.data?.data) {
            yield put(getAllTaskFieldsMasterDataSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* getAllDeliverylistProjectView(action) {
    try {
        const response = yield call(getAllDeliveryListProjectViewApi, action.payload);
        // console.log("getAllTaskFieldApi", response?.data);
        if (response?.data?.data) {
            yield put(getAllDeliveriesProjectViewSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* getAllDeliverylistStageView(action) {
    try {
        const response = yield call(getAllDeliveryListStageViewApi, action.payload);
        // console.log("getAllTaskFieldApi", response?.data);
        if (response?.data?.data) {
            yield put(getAllDeliveriesStageViewSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllBcPcHistory(action) {
    try {
        const response = yield call(getAllBcPcHistoryApi, action.payload);
        // console.log("getAllTaskFieldApi", response?.data);
        if (response?.data?.data) {
            yield put(getAllBcPcHistorySuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllTaskTypeData(action) {
    try {
        const response = yield call(getAllTaskTypeApi, action.payload);
        // console.log("getAllTaskTypesMasterDataSuccess", response?.data);
        if (response?.data?.data) {
            yield put(getAllTaskTypesMasterDataSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllBluePrintData(action) {
    try {
        const response = yield call(getAllMasterDataApi, action?.payload);
        // console.log("getAllBluePrintDataSuccess", response?.data);
        if (response?.data?.data) {
            yield put(getAllMasterDataSuccess(response?.data?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* getAllRatingCriteriaSaga(action) {
    try {
        const response = yield call(getRatingCriteriaApi, action?.payload);
        if (response?.data?.data) {
            yield put(getRatingCriteriaSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}

function* getRatingCriteriaByIdSaga(action) {
    try {
        const response = yield call(getRatingCriteriaIdApi, action.payload);
        if (response?.status === 200) {
            yield put(getRatingCriteriaByIdSuccess(response?.data?.data[0]));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllUploadTypeSaga(action) {
    try {
        const response = yield call(getAllUploadTypeDataApi, action?.payload);
        if (response?.data?.data) {
            yield put(getUploadTypeSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllUploadTypeNoPaginationSaga(action) {
    try {
        const response = yield call(getAllUploadTypeDataNoPaginationApi, action?.payload);
        if (response?.data?.data) {
            yield put(getUploadTypeNoPaginationSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
function* getAllUploadTypeByIdSaga(action) {
    try {
        const response = yield call(getAllUploadTypeByIdDataApi, action.payload);
        if (response?.status === 200) {
            yield put(getUploadTypeByIdSuccess(response?.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
            yield put(loginUserFailure(error));
        }
    }
}
export function* getAllMaterdataSaga() {
    yield takeLatest(GET_ALL_MASTERDATA_FOR_TABLE, getAllMasterDataForTable)
    yield takeLatest(GET_ALL_ARCHITECT_MASTERDATA, getAllArchitectData)
    yield takeLatest(GET_ARCHITECT_BY_ID, getArchitectDataById)
    yield takeLatest(GET_ALL_CHECKLIST_TEMPLATES, getAllChecklistData)
    yield takeLatest(GET_ALL_CHECKLIST_TEMPLATES_BY_ID, getChecklistDataById)
    yield takeLatest(GET_STAGE_BY_ID, getStageDataById)
    yield takeLatest(GET_TASK_TYPE_BY_ID, getTaskTypeDataById)
    yield takeLatest(DELETE_TASK, deleteTask)
    yield takeLatest(DELETE_MULTIPLE_TASK, deleteMultipleTask)
    yield takeLatest(DELETE_STAGE, deleteStage)
    yield takeLatest(GET_TASK_FIELDS_BY_ID, getTaskFieldSowDataById)
    yield takeLatest(GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION, getTaskTypeDataWithoutPaginatation)
    yield takeLatest(GET_TASK_MAPPING_BY_ID, getTaskSowDataById)
    yield takeLatest(GET_ALL_STAGES_MASTERDATA, getAllStaegsData)
    yield takeLatest(GET_ALL_PROJECT_TYPE_MASTERDATA, getAllProjectTypegsData)
    yield takeLatest(GET_ALL_TASK_MAPPING_MASTERDATA, getAllTaskMappingsData)
    yield takeLatest(GET_ALL_TASK_FIELDS_MASTERDATA, getAllTaskFieldData)
    yield takeLatest(GET_ALL_TASK_TYPE_MASTERDATA, getAllTaskTypeData)
    yield takeLatest(GET_ALL_MASTER_DATA, getAllBluePrintData)
    yield takeLatest(GET_RATING_CRITERIA, getAllRatingCriteriaSaga)
    yield takeLatest(GET_RATING_CRITERIA_BY_ID, getRatingCriteriaByIdSaga)
    yield takeLatest(GET_UPLOAD_TYPE, getAllUploadTypeSaga)
    yield takeLatest(GET_UPLOAD_TYPE_NO_PAGINATION, getAllUploadTypeNoPaginationSaga)
    yield takeLatest(GET_UPLOAD_TYPE_BY_ID, getAllUploadTypeByIdSaga)
    yield takeLatest(GET_ALL_DELIVERIES_PROJECTVIEW, getAllDeliverylistProjectView)
    yield takeLatest(GET_ALL_DELIVERIES_STAGEVIEW, getAllDeliverylistStageView)
    yield takeLatest(GET_ALL_BCPC_HISTORY, getAllBcPcHistory)


}

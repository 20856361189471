import { Avatar, Box, Card, Flex, Text } from "theme-ui";
export interface memberData {
    src: string;
    alt: string;
}
export interface team {
    teamName?: any;
    projectName?: string;
    memberData?: memberData[];
}

const Teamcard: React.FC<team> = ({ teamName, projectName, memberData }) => {

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '10px',
                    boxShadow: '10px  10px 10px 5pxrgba(209, 173, 138, 0.12)',
                    blurshadow: 40,
                    p: 3,
                    bg: 'white',
                    maxWidth: '300px',
                    maxHeight: '160px',
                    cursor: 'pointer'
                }}
            >
                <Box>
                    <Text
                        sx={{
                            fontSize: '10px',
                            fontWeight: 500,
                            color: '#453521',
                            whiteSpace: 'nowrap', // Prevent text from breaking into a new line
                            overflow: 'hidden', // Ensure overflow is hidden
                            textOverflow: 'ellipsis', // Add ellipsis if the text exceeds the container
                            maxWidth: '100px', // Set a max width to activate ellipsis if needed
                            cursor: 'pointer', // Pointer cursor for better UX
                        }}
                        title={teamName} // Tooltip with the full name
                    >
                        {teamName?.slice(0, 10)}{teamName?.length > 10 ? '...' : ''}
                    </Text>
                    {/* <Paragraph sx={{ fontSize: 1 }}>{projectName}</Paragraph> */}
                </Box>

                <Flex>
                    {memberData?.slice(0, 5).map((members: any, index: number) => {
                        const firstName = members?.userId?.FirstName || "";
                        const avatarFallback = firstName.charAt(0);

                        return members.src ? (
                            <Avatar
                                key={index}
                                src={members.src}
                                alt="icon"
                                sx={{
                                    width: 30,
                                    height: 30,
                                    marginRight: '-10px',
                                    border: '2px solid white',
                                    borderRadius: '50%',
                                }}
                            />
                        ) : (
                            <div
                                key={index}
                                style={{
                                    width: 30,
                                    height: 30,
                                    marginRight: '-10px',
                                    border: '2px solid white',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#7743DB',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {avatarFallback}
                            </div>
                        );
                    })}
                </Flex>
            </Card>
        </>
    )
}

export default Teamcard
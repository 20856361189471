import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Image, Text } from 'theme-ui';
import { RootState } from '../../../../../@types';
import theme from '../../../../theme';

interface SelectableImageBoxProps {
  _id: string;
  name: string;
  imageUrl: string;
  isSelected: boolean;
  onSelect: (_id: string) => void;
}

interface MultiCardProps {
  value: string;
  onChange: (selectedBlueprint: string) => void;
  labelledBy: string;
}

const SelectableImageBox: React.FC<SelectableImageBoxProps> = ({ _id, name, imageUrl, isSelected, onSelect }) => (
  <div
    style={{
      position: 'relative',
      border: isSelected ? `2px solid ${theme.colors?.primary}` : `2px solid  ${theme?.colors?.lightbackground}`,
      padding: '2%',
      cursor: 'pointer',
      borderRadius: '12px',
    }}
    onClick={() => onSelect(_id)}
  >
    <Image src={imageUrl} alt={`Image ${_id}`} style={{ width: '100%', borderRadius: '8px' }} />
    <Text className='box-text' sx={{ textTransform: "capitalize" }}>{name}</Text>
  </div>
);

const MultiCard: React.FC<MultiCardProps> = ({ value, onChange }) => {
  const blueprint = useSelector((state: RootState) => state.project.createprojectMasterData);
  return (
    <Box>
      <div className='row'>
        <Text variant='SelectLabel' className='col-md-12 mt-4 mb-4'>Please select the suitable blueprint template from below: <span className="mx-1 text-danger">*</span></Text>
      </div>
      <Box>
        <Grid columns={[1, 2, 3, 3, 4]} gap={2}>
          {((blueprint?.allBlueprints?.data?.[0] as any)?.values || []).map((box: { _id: string; name: string; image: string }) => (
            <SelectableImageBox
              key={box._id}
              _id={box._id}
              name={box.name}
              imageUrl={box.image}
              isSelected={value === box.name}
              onSelect={() => onChange(box.name)}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default MultiCard;

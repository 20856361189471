import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { MediaProps } from 'reactstrap';
import { Button, Label, Text } from 'theme-ui';
import * as Yup from 'yup';
import { RootState } from '../../../../@types';
import { mediaUpload } from '../../../store/actions/media.action';
import { getAllRoles, getAllUserWithoutPagination, getUserbyId } from '../../../store/actions/user.action';
import theme from '../../../theme';
import { bucketUrl } from '../../../utils/global';
import { ActionUpdateById } from '../../../store/actions/CommonAction';

const genders: string[] = ['Male', 'Female', 'Other'];
const validationSchema = Yup.object().shape({
  FirstName: Yup.string().required('First Name is required'),
  LastName: Yup.string().required('Last Name is required'),
  timeZone: Yup.string().required('Time zone is required'),
  userTeam: Yup.string().required('User Team is required'),
  Email: Yup.string().email('Invalid email address').required('Email is required'),
  // Gender: Yup.string().required('Gender is required'),
  Role: Yup.array().of(Yup.string()).required('Role is required').min(1, 'At least one role must be selected'),
  // CanManageTo: Yup.array().of(Yup.string()).required('CanManageTo is required').min(1, 'At least one CanManageTo must be selected'),
  // TypeOfSkills: Yup.array().of(Yup.string()).required('Skills is required').min(1, 'At least one skills must be selected'),
  PhoneNumber: Yup.string().matches(
    /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    "Only digits are allowed!!"
  ),
});

const EditUserForm: React.FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const { uploadedMedia } = useSelector((state: MediaProps) => state?.media);
  const skills: Option[] = [
    { label: 'Mechanical', value: '123456789012345678901234' },
    { label: 'Electrical', value: '423456789012345678901234' },
    { label: 'Plumbing', value: '323456789012345678901234' },
    { label: 'Others', value: '523456789012345678901234' },
  ];
  const userData = useSelector((state: any) => state?.user);


  const Users = userData?.userData?.data;
  // const users = useSelector((state: RootState) => state.user.users);
  const { usersbyId } = useSelector((state: any) => state.user)

  // const user = users.find((u) => u._id === userId);
  const { roles } = useSelector((state: RootState) => state.user);
  // console.log("usersbyId=", usersbyId);
  const userTeam = [
    { label: 'PEC', value: 'PEC' },
    { label: 'QDC', value: 'QDC' },
  ]
  const timeZones = [
    { label: 'PST', value: 'America/Los_Angeles' },
    { label: 'IST', value: 'Asia/Calcutta' },
  ]
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserbyId(userId));
    dispatch(getAllRoles());
    dispatch(getAllUserWithoutPagination())
  }, [dispatch, userId]);
  const handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target.files as FileList)[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('folder', 'profileImage');
      dispatch(mediaUpload(formData));
    } else {
      console.warn('Profile Image not found!');
    }
  };
  const initialValues = {
    _id: usersbyId?._id || '',
    FirstName: usersbyId?.FirstName || '',
    LastName: usersbyId?.LastName || '',
    PhoneNumber: usersbyId?.PhoneNumber || 0,
    Role: usersbyId?.Role?.map((r: any) => r._id) || [],
    ReportingManager: usersbyId?.ReportingManager || '',
    TypeOfSkills: usersbyId?.TypeOfSkills || [],
    Gender: usersbyId?.Gender || '',
    CanManageTo: usersbyId?.CanManageTo?.map((item: any) => item?._id) || [],
    Email: usersbyId?.Email || '',
    Password: usersbyId?.Password || '',
    RePassword: usersbyId?.Password || '',
    ProfileImage: usersbyId?.ProfileImage || uploadedMedia,
    userTeam: usersbyId.userTeam || '',
    timeZone: usersbyId.timeZone || '',
  };

  const handleSubmit = (payload: any) => {
    // console.log("values payload", payload);
    if (uploadedMedia) {
      payload.ProfileImage = uploadedMedia;
    }
    if (!payload?.ReportingManager) {
      delete payload?.ReportingManager;
    }
    const { Password, RePassword, ...payloadWithoutId } = payload;

    dispatch(ActionUpdateById({ Query: `users/editUser/${userId}`, obj: payloadWithoutId }, (res: any) => {
      navigate("/user")
    }));
  };

  const canManageToOptions: Option[] = Users?.map((user: any) => ({
    label: user?.FirstName,
    value: user?._id,
  }));
  const roleOptions: Option[] = roles?.data?.map((roles) => ({
    label: roles?.RoleName,
    value: roles?._id,
  }));
  return (
    <div className="main-content p-5" >
      <h1>Edit User
        {/* {usersbyId?.ProfileImage && <Avatar width={40} height={40} variant="profile" src={`${bucketUrl}${usersbyId?.ProfileImage}`} style={{ backgroundColor: "white" }} />} */}
      </h1>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize={true}>
        {({ errors, touched, setFieldValue, values }) => (
          <Form>
            <div className="row">
              <div className="col-md-4 mt-4">
                <Label htmlFor="FirstName" className={`form-label mt-1 ${errors.FirstName && touched.FirstName ? 'is-invalid' : ''}`}>First Name*</Label>
                <Field type="text" id="FirstName" name="FirstName" className="form-control" placeholder="First Name" />
                <ErrorMessage name="FirstName" component="div" className="text-danger" />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="LastName" className={`form-label mt-1 ${errors.LastName && touched.LastName ? 'is-invalid' : ''}`}>Last Name*</label>
                <Field type="text" id="LastName" name="LastName" className="form-control" placeholder="Last Name" />
                <ErrorMessage name="LastName" component="div" className="text-danger" />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="Email" className={`form-label mt-1 ${errors.Email && touched.Email ? 'is-invalid' : ''}`}>Email ID*</label>
                <Field type="text" id="Email" name="Email" className="form-control" placeholder="Email ID" />
                <ErrorMessage name="Email" component="div" className="text-danger" />
              </div>
              {/* <div className="col-md-4 mt-4">
                <label htmlFor="Project" className="form-label">Project*</label>
                <Field type="text" id="Project" name="Project" className="form-control" placeholder="Project" />
                <ErrorMessage name="Project" component="div" className="text-danger" />
              </div> */}
              <div className="col-md-4 mt-4">
                <label htmlFor="Gender" className="form-label">Gender*</label>
                <Field as="select" id="Gender" name="Gender" className={`form-control `}
                  // onChange={(e) => setFieldValue('Gender', e.target.value)}
                  value={values.Gender} >
                  <option value="" label="Select Gender" />
                  {genders.map((gender) => (<option key={gender} value={gender} label={gender} />))}
                </Field>
                {/* <ErrorMessage name="Gender" component="div" className="text-danger" /> */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="Role" className="form-label">Role Name*</label>
                {roleOptions.length > 0 && <MultiSelect
                  options={roleOptions || []}
                  value={values?.Role?.map((value: any) => roleOptions?.find((option) => option?.value === value) as Option)}
                  onChange={(selected: Option[]) => setFieldValue('Role', selected?.map((option) => option?.value))}
                  labelledBy="Select"
                />}
                {errors?.Role && touched?.Role ? (
                  <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                    {'At least one role must be selected'}
                  </Text>
                ) : (
                  null
                )}
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="PhoneNumber" className="form-label">Phone Number</label>
                <Field type="text" id="PhoneNumber" name="PhoneNumber" className="form-control" placeholder="Phone Number" />
                {/* <ErrorMessage name="PhoneNumber" component="div" className="text-danger" /> */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="ReportingManager" className="form-label">Reporting Manager</label>
                <Field as="select" id="ReportingManager" name="ReportingManager" className="form-control" >
                  <option value="" label="Select Reporting Manager" />
                  {Users?.map((user: any) => (
                    <option
                      key={user?._id}
                      value={user?._id}
                      label={user?.FirstName}
                      selected={user?._id === values?.ReportingManager} />))}
                </Field>
                {/* <ErrorMessage name="Reporting Manager" component="div" className="text-danger" /> */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="CanManageTo" className="form-label">Can Manage to</label>
                {canManageToOptions?.length > 0 && <MultiSelect
                  options={canManageToOptions}
                  value={(values?.CanManageTo || [])
                    .map((value: any) => canManageToOptions?.find((option) => option?.value === value))
                    .filter((selectedOption: any) => selectedOption !== null && selectedOption !== undefined) as Option[]}
                  onChange={(selected: Option[]) => {
                    const selectedValues = selected?.map((option) => option?.value);
                    setFieldValue('CanManageTo', selectedValues || []);
                  }}
                  labelledBy="Select" />}
                {/* {errors?.CanManageTo && touched?.CanManageTo ? (
                  <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                    {errors?.CanManageTo}
                  </Text>
                ) : (
                  null
                )} */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="TypeOfSkills" className="form-label">Types of Skills</label>
                {skills.length > 0 && <MultiSelect
                  options={skills}
                  value={values?.TypeOfSkills.map((skill: any) => ({ label: skill, value: skill }))}
                  onChange={(selected: Option[]) => setFieldValue('TypeOfSkills', selected?.map(option => option?.label))}
                  labelledBy="Select" />}
                {/* {errors?.TypeOfSkills && touched?.TypeOfSkills ? (
                  <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                    {errors?.TypeOfSkills}
                  </Text>
                ) : (
                  null
                )} */}
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="userTeam" className="form-label">User Team*</label>
                <Field
                  as="select"
                  id="userTeam"
                  name="userTeam"
                  className={`form-control`}
                >
                  <option value="" label="Select User Team" />
                  {userTeam?.map((user: any, index: number) => (
                    <option key={index} value={user.value} label={user.label} />
                  ))}
                </Field>
                <ErrorMessage name="userTeam" component="div" className="text-danger" />
              </div>
              <div className="col-md-4 mt-4">
                <label htmlFor="timeZone" className="form-label">Time Zone*</label>
                <Field
                  as="select"
                  id="timeZone"
                  name="timeZone"
                  className={`form-control`}
                >
                  <option value="" label="Select Time Zone" />
                  {timeZones?.map((user: any, index: number) => (
                    <option key={index} value={user?.value} label={user?.label} />
                  ))}
                </Field>
                <ErrorMessage name="timeZone" component="div" className="text-danger" />
              </div>
              <div className='col-md-12 mt-4'><h2>System Field</h2></div>
              <div className='col-md-4 mt-4'>
                <label htmlFor='ProfileImage' className="form-label">Profile Image</label>
                <input
                  type="file"
                  id="ProfileImage"
                  name="ProfileImage"
                  onChange={handleProfileImageChange}
                  className={`form-control ${errors.ProfileImage && touched.ProfileImage ? 'is-invalid' : ''}`}
                />
                {usersbyId?.ProfileImage && <a target='_blank' href={bucketUrl + usersbyId?.ProfileImage}>{usersbyId?.ProfileImage.slice(usersbyId?.ProfileImage.length - 21, usersbyId?.ProfileImage.length)}</a>}
              </div>
              <div className='col-md-4 mt-4'>
                <label htmlFor='Evaluation' className="form-label">Next Evaluation</label>
                <Field type="date" id="Evaluation" name="Evaluation" className="form-control" placeholder="Enter Date" />
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-md-4'></div>
              <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
              <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit'>Submit</Button></div>
            </div>
          </Form>)}
      </Formik>
    </div >
  );
};

export default EditUserForm;

import { ErrorMessage, Field, FormikProps } from 'formik';
import { withFormikValidation } from './enhancer/AddEditStagesEnhancer';
import { Box, Button, Checkbox, Label } from 'theme-ui';
import theme from '../../../../theme';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getStageDataById } from '../../../../store/actions/masterData.action';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types';
import { ActionCreateByUrl, ActionUpdateById } from '../../../../store/actions/CommonAction';


const AddEditStages: React.FC<FormikProps<any>> = ({
    values,
    errors,
    touched,
    handleSubmit,
    setValues,
    validateForm,
    setFieldValue,
    isValid,
    isSubmitting
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { stageId } = useParams();
    const { individualStage } = useSelector((state: RootState) => state?.masterData);
    const [isNeedToValidate, setIsNeedToValidate] = useState<boolean>(false);
    // console.log(individualStage, "check31");

    useEffect((): void => {
        if (stageId) {
            dispatch(getStageDataById({ id: stageId }))
        }
    }, [stageId])
    useEffect(() => {
        if (stageId && individualStage?.status) {
            setValues({
                ...values,
                name: individualStage?.data?.name,
                color: individualStage?.data?.color,
                description: individualStage?.data?.description,
                isIPR: individualStage?.data?.isIPR || false,
            })
            setIsNeedToValidate(true);
        }
    }, [individualStage?.data])
    useEffect(() => {
        if (isNeedToValidate) {
            validateForm();
        }
    }, [isNeedToValidate])
    const handleArchitectData = async (): Promise<void> => {
        handleSubmit();
        if (isValid) {
            stageId ?
                dispatch(ActionUpdateById({ Query: `stages/edit-stage/${stageId}`, obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/stages")
                    }
                }))
                :
                dispatch(ActionCreateByUrl({ Query: "stages/create-stages", obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/stages")
                    }
                }));
        }
    }
    return (
        <div className="main-content p-5">
            <h1>{stageId ? "Edit" : "Add"} Stage</h1>
            <div className="row">
                <div className="col-md-4 mt-4">
                    <Label htmlFor="name" className={`form-label mt-1 ${errors.name && touched.name ? 'is-invalid' : ''}`}>First Name*</Label>
                    <Field type="text" id="name" name="name" className="form-control" placeholder="Stage Name" />
                    <ErrorMessage name="name" component="div" className="text-danger" />
                </div>
                <div className="col-md-4 mt-3">
                    <label htmlFor="description" className={`form-label mt-1 ${errors.description && touched.description ? 'is-invalid' : ''}`}>Description*</label>
                    <Field type="text" id="description" name="description" className="form-control" placeholder="Stage Description" />
                    <ErrorMessage name="description" component="div" className="text-danger" />
                </div>
                <div className="col-md-4 mt-3">
                    <label htmlFor="color" className={`form-label mt-1 ${errors.color && touched.color ? 'is-invalid' : ''}`}>Color*</label>
                    <Box className="text-center d-flex">
                        <Field type="color" id="color" name="color" className="form-control" placeholder="Stage color" />
                    </Box>
                    <ErrorMessage name="color" component="div" className="text-danger" />
                </div>
                <div className="col-md-6 mt-3">
                    <Label
                        htmlFor="isIPR"
                        className={`form-label mt-1 ${errors.isIPR && touched.isIPR ? 'is-invalid' : ''
                            }`}
                    >
                        isIPR *
                        <Checkbox
                            name="isIPR"
                            id="isIPR"
                            checked={values.isIPR}
                            onChange={(e) => setFieldValue('isIPR', e.target.checked)}
                        />
                    </Label>
                    <ErrorMessage name="isIPR" component="div" className="text-danger" />
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-md-4'></div>
                <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' onClick={handleArchitectData} disabled={isSubmitting}>Submit</Button></div>
            </div>
        </div >
    );
}

export default withFormikValidation(AddEditStages);
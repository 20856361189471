import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import Export from "../../assets/images/Icon/Export.svg";
import {
    ColumnDef, Row,
    flexRender,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table';

import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    type DragEndEvent,
    type UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Badge, Box, Divider, Flex, Paragraph, Spinner, Text } from 'theme-ui';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TablePagination from '../../Tabels/Pagination';
import queryString from 'query-string';
import { TabelClassname } from '../../../utils/Classname';
import CommentPopup from '../demotable/CommentPopup';
import FilterPopup from '../../forms-component/filterPopup';
import { TabelHeadingTxt } from '../../Text/TabelCommonTxt';
import { useDispatch } from 'react-redux';
import { getApiEndPoint } from '../../../utils/GenerateQueryString';
import { GetAllStageView, UpdateStageRow, getStageByCustomOrder } from '../../../store/actions/project.action';
import CommentsDialogBox from '../demotable/CommentSection';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import { baseUrl } from '../../../utils/global';
import { toConvertLocalDateFormat, usePreviousURL } from '../../../utils/CustomHandler';
import { FolderOption } from '../../../../@types';
import { statusColors } from '../../../utils/constent';
import theme from '../../../theme';

//remove duplicates from Url
const removeDuplicateQueryParams = (queryString: string) => {
    const params = new URLSearchParams(queryString);
    const uniqueParams = new URLSearchParams();

    for (const [key, value] of params) {
        uniqueParams.set(key, value);
    }

    return `?${uniqueParams.toString()}`;
}


// Cell Component
const RowDragHandleCell = ({ row }: { row: any }) => {
    const { attributes, listeners } = useSortable({
        id: row.id,
    })

    return (
        <button {...attributes} {...listeners} style={{ border: "none", backgroundColor: "#ffffff" }}>
            🟰 #{window.location.href?.includes("CustomOrder1") ? row.original?.order1 : row.original?.order2}
        </button>
    )
}

// Row Component
const DraggableRow = ({ row }: { row: Row<any> }) => {
    const commonPadding = localStorage.getItem("tPadding") || '13px';
    const { transform, transition, setNodeRef, isDragging } = useSortable({
        id: row.original.userId,
    })

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
    }
    return (

        <tr ref={setNodeRef} style={style} data-state={row.getIsSelected() && "selected"}>
            {row.getVisibleCells().map((cell: any, cellIndex: number) => (
                <td key={cell.id} className={"fixed-column-" + cellIndex + " table-cell"} style={{
                    padding: `${commonPadding}px`, display: "gird",
                    placeContent: "center",
                    width: cell.column.getSize()
                }}>
                    {/* <td key={cell.id} style={{ width: cell.column.getSize() }}> */}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    )
}

// Table Component
const StagesView: React.FC = () => {
    const commonPadding = localStorage.getItem("tPadding") || '13px';

    type YourDataType = {
        tasksNameList: {
            _id: string;
            color: string;
            name: string;
            shortForm: string;
        }[];
    };

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { stageView, loader, highlighters } = useSelector((state: any) => state.project);
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    const [actionOpen, setactionOpen] = useState(false);
    const [data, setData] = React.useState([])
    const parsedQuery = queryString.parse(location.search);


    const handleClick = () => {
        setactionOpen(true)
    };

    // Custom
    const previousURL = usePreviousURL()

    const generateFolderOptions = (): FolderOption[] => {
        const startMonth = 1609;
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        // console.log('Current Year:', currentYear);
        // console.log('Current Month:', currentMonth);
        const folderOptions: FolderOption[] = [];
        for (let year = 16; year <= currentYear; year++) {
            const start = (year === 16) ? startMonth % 100 : 1;
            const end = (year === currentYear) ? currentMonth + 2 : 12;
            for (let month = start; month <= end; month++) {
                const formattedOption = `projects_${year.toString().padStart(2, '0')}${month.toString().padStart(2, '0')}`;
                const optionObject: FolderOption = { label: formattedOption, value: formattedOption };
                folderOptions.push(optionObject);
                if (year === currentYear && month === currentMonth) {
                    optionObject.defaultSelected = true;
                }
            }
        }
        folderOptions.sort((a, b) => a.label.localeCompare(b.label));
        // console.log('folderOptions:', folderOptions);
        return folderOptions;
    };
    type StatusType = keyof typeof statusColors;

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            id: 'drag-handle',
            header: 'No',
            accessorKey: "no",
            // enableColumnFilter: false,
            cell: ({ row }: any) => (
                <>
                    <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row?.index + 1}</Text>
                    {location?.search?.includes("CustomOrder") && <RowDragHandleCell row={row} />}
                </>
            ),
            size: 60,
        },
        {
            header: 'Stage Name',
            accessorKey: "name",

            cell: ({ row }) => {

                return (
                    <div onClick={() => { previousURL(`/edit-stages/${row?.original?._id}`) }}>
                        <Text style={{ textDecoration: 'underline', color: '#0A0A0A', cursor: "pointer", fontWeight: 500 }}>
                            {row?.original?.name || ''}
                        </Text>
                    </div>

                )
            },
            filterType: "dropdown",
            options: dataForTable?.data?.allStages?.map((val: { _id: string, name: string }) => ({ value: val.name, label: val.name }))

        },
        {
            header: 'Project Name',
            accessorKey: "projectId",
            cell: ({ row }) => {
                return (
                    <Paragraph className='project-name-row' >{row.original?.projectId?.name}</Paragraph>
                )
            },
            filterType: 'dropdown',
            options: (() => {
                if (parsedQuery?.status == "Delivered") {
                    return dataForTable?.data?.allDeliveredProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                } else if (parsedQuery?.status == "Ready To Deliver") {
                    return dataForTable?.data?.allRTDProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                } else if (parsedQuery?.status == "Draft") {
                    return dataForTable?.data?.allDraftProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                } else {
                    return dataForTable?.data?.allProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                }
            })()
        },
        {
            header: 'Folder Number',
            accessorKey: "folderNumber",
            cell: ({ row }) => {
                return (
                    <Text >{row?.original?.projectId?.folderNumber}</Text>
                )
            },
            // filterType: 'dropdown',
            // options: generateFolderOptions(),
        },

        {
            header: 'Highlighters',
            accessorKey: "highlighters",
            cell: ({ row }) => {
                const stages = row.original.highlighters;
                if (stages && stages?.length > 0) {
                    return (
                        <Flex sx={{ justifyContent: "center" }}>
                            {stages?.map((val: { color: string, title: string, _id: string, name: string }) => (
                                // <CommonSpan color={val.color} name={val.name} />
                                <Badge title={val.name} sx={{ color: `${val.color}`, border: `1px solid ${val.color}`, borderRadius: 6, backgroundColor: "transparent", margin: "5px" }} key={val._id}>{val.title}</Badge>
                            ))}
                        </Flex>
                    );
                } else {
                    return null;
                }
            },
            filterType: 'multi-select-dropdown',
            options: highlighters?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }))
        },
        {
            header: 'PM Manager',
            accessorKey: "manager",
            cell: ({ row }) => {
                return (
                    <Text >{row.original.manager?.FirstName} {row.original.manager?.LastName}</Text>

                    // <Text >{row.original.manager?.FirstName}</Text>
                )
            },
            filterType: 'dropdown',
            options: dataForTable?.data?.allProjectManagers?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val._id, label: `${val.FirstName} ${val.LastName}` }))
        },
        {
            header: 'PM Worker',
            accessorKey: "worker",
            cell: ({ row }) => {
                return (
                    <Text >{row.original.worker?.FirstName} {row.original.worker?.LastName}</Text>
                )
            },
            filterType: 'dropdown',
            options: dataForTable?.data?.allProjectWorkers?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val._id, label: `${val.FirstName} ${val.LastName}` }))
        },
        {
            header: 'Due date',
            accessorKey: "dueDate",
            cell: ({ row }) => {
                const dueDateValue = row.getValue("dueDate") as string;
                return (
                    // <Text variant='Poppins14Normal24lh'>{dueDateValue && moment(dueDateValue).format("ll")}</Text>
                    <Text variant='Poppins14Normal24lh'>{toConvertLocalDateFormat(dueDateValue)}</Text>
                );
            },
            filterType: 'date',
        },
        {
            header: 'Status',
            accessorKey: "status",
            cell: ({ row }) => {
                const status = row.original.status as StatusType;

                const color = statusColors[status] || '#000';

                return (
                    <Badge
                        title={status}
                        sx={{
                            color: `${color}`,
                            border: `1px solid ${color}`,
                            borderRadius: 6,
                            backgroundColor: "transparent",
                            margin: "5px"
                        }}
                    >
                        {status}
                    </Badge>
                );
            },
            filterType: "dropdown",
            options: dataForTable?.data?.status?.map((val: { value: string }) => ({ value: val.value, label: val.value }))
        },
        {
            header: 'Promise',
            accessorKey: "promise",
            cell: info => info.getValue(),
            filterType: "textbox",
        },
        {
            header: 'Tasks Name list',
            accessorKey: "tasksNameList",
            cell: ({ row }) => {
                const taskList: YourDataType["tasksNameList"] = row.getValue("tasksNameList");
                return (
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }} >
                        {taskList?.map((task: any, index: number) => {
                            return (
                                <Badge title={task.name} key={task._id} sx={{ backgroundColor: task.color, color: "white", border: `1px solid ${task.color}`, borderRadius: 6 }}>
                                    {task.shortForm}
                                </Badge>
                            )
                        })}
                    </Box>
                );
            },
        },
        {
            header: 'Comments',
            cell: ({ row }) => {
                return (
                    <div>
                        <CommentsDialogBox id={row?.original?._id} comments={row?.original} level="stage" />
                    </div>
                );

            },
        }

    ], [stageView.data, dataForTable]);


    const dataIds = React.useMemo<UniqueIdentifier[]>(
        () => data?.map(({ userId }) => userId),
        [data]
    )

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.userId,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    })

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            const oldData: any = data.filter((act: any) => act.userId === active.id);
            const newData: any = data.filter((ove: any) => ove.userId === over.id);
            const oldDate = new Date(oldData[0]?.dueDate);
            const newDate = new Date(newData[0]?.dueDate);
            if (oldDate.getTime() === newDate.getTime()) {

                setData((data: any) => {
                    const oldIndex = dataIds.indexOf(active.id);
                    const newIndex = dataIds.indexOf(over.id);
                    return arrayMove(data, oldIndex, newIndex);
                });

                let isCustOrder1 = location?.search?.includes("stat=CustomOrder1")

                const dispatchObj = {
                    id: oldData?.[0]?.userId,
                    oldOrder: oldData?.[0]?.[isCustOrder1 ? "order1" : "order2"],
                    newOrder: newData?.[0]?.[isCustOrder1 ? "order1" : "order2"],
                    view: isCustOrder1 ? "CustomOrder1" : "CustomOrder2"
                };

                dispatch(getStageByCustomOrder(dispatchObj, (res: any) => {
                    if (res?.status === 200) {
                        toast.success("Custom Order Updated Successfully.");
                    }
                }))

            } else {
                toast.error('Due dates are not equal')
            }
        }
    }

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    )

    useEffect(() => {
        if (stageView?.data?.length > 0) {

            let newData = stageView?.data?.map((item: any) => {
                return { ...item, userId: item._id, _id: item._id };
            });
            setData(newData)
        } else {
            setData(stageView?.data)
        }
    }, [stageView?.data])

    useEffect(() => {
        let apiEndPoint = getApiEndPoint({ limit, currentPage }, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
        let manuplateStr = location?.search + apiEndPoint;
        const urlParams = new URLSearchParams(apiEndPoint);
        const isTrue = location?.search?.includes("istrue=true")
        if (isTrue) {
            urlParams.delete('dynamicFilter');
            manuplateStr = urlParams.toString();
            dispatch(GetAllStageView(location?.search + "&" + manuplateStr))
        } else {
            let queryParams = removeDuplicateQueryParams(apiEndPoint + location?.search?.replace("?", "&"))
            dispatch(GetAllStageView(queryParams))
        }

    }, [location?.search, limit, currentPage]);


    const { data: eventSource } = useQuery({
        queryKey: ['stageSSE'],
        queryFn: () => {
            const eventSource = new EventSource(`${baseUrl}sse/stage`);

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    dispatch(UpdateStageRow(data))
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };

            eventSource.onerror = (error) => {
                console.error('SSE Error:', error);
                eventSource.close();
            };
            return eventSource;
        },
        refetchInterval: 60000
    })

    useEffect(() => {
        return () => {
            eventSource?.close();
        };
    }, [])

    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Stages view' Count={stageView?.count} IsExportButtonShow={false} src={Export} BtnTxt='Export' addText={'Add Stage'} handleClick={handleClick} limit={limit} currentPage={currentPage} columns={columns} url={"/stage-view"} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={handleDragEnd}
                sensors={sensors}
            >
                <div className="">
                    <Box as="div" sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
                        <div className={`${"tableFixHead"}`} style={{ borderRadius: 10, border: '1px solid #8F9195' }}>
                            <table className={`${'' + TabelClassname?.PrimaryTabel} mb-0 table-hover`}>
                                <thead className="position-relative">
                                    {table.getHeaderGroups().map(headerGroup => {
                                        // Check if there is any "Project Name" column in this headerGroup

                                        return (
                                            <tr
                                                key={headerGroup.id}
                                                className='trClassName'
                                            >
                                                {headerGroup.headers.map((header: any, index) => {
                                                    const isCommentsHeader = header.column.columnDef.header === "Comments";
                                                    const isSpecialColumn = ["Due date", "TM Worker", "TM Manager", "Stage Name", "Comments", "Status"].includes(header.column.columnDef.header);
                                                    let headerID = header.getContext().column.id;

                                                    return (
                                                        <th
                                                            className={`th align-middle border-top-0 border-bottom-0 fixed-column-${index} ${(headerID && location?.search?.includes("CustomOrder")) ? "th_child" : ""} ${isSpecialColumn ? 'th-quote-number' : ''}`}
                                                            key={header.id}
                                                            colSpan={header.colSpan}
                                                            style={{ backgroundColor: `${theme?.colors?.lightbackground}`, padding: `${commonPadding}px`, textAlign: 'center' }}
                                                        >
                                                            {header.column.getCanFilter() && (
                                                                <FilterPopup column={header.column.columnDef} id={`column${index}`} />
                                                            )}

                                                            {!(header.isPlaceholder) && (
                                                                <Text variant='Poppins16Normal25lh' className=" position-relative">
                                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                                    {isCommentsHeader && <CommentPopup />}
                                                                </Text>
                                                            )}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}

                                </thead>
                                <tbody>
                                    {(!loader && data?.length > 0) && (<SortableContext
                                        items={dataIds}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        {table.getRowModel().rows.map(row => (
                                            <DraggableRow key={row.id} row={row} />
                                        ))}
                                    </SortableContext>)}
                                </tbody>
                            </table>
                            {loader &&
                                <Spinner sx={{ position: "absolute", top: "50%", left: "50%" }} />
                            }
                            {!loader && data?.length === 0 && (
                                <Text sx={{ position: "absolute", top: "50%", left: "50%" }}>No Data Found!</Text>
                            )}
                        </div>
                    </Box>
                    <Box className="sticky-navigation">
                        <TablePagination
                            // className="sticky-navigation"
                            onPageChange={(value) => {
                                const parsedQuery = queryString.parse(location.search);
                                if (searchParams?.size > 2) {

                                    setSearchParams({
                                        ...parsedQuery,
                                        currentPage: value.toString(),
                                    });
                                } else {
                                    setSearchParams({
                                        ...parsedQuery,
                                        currentPage: value.toString(),
                                        limit: limit.toString(),
                                    });
                                }
                            }}
                            onPerPageChange={(itemsPerPage) => {
                                const parsedQuery = queryString.parse(location.search);
                                if (searchParams?.size > 2) {

                                    setSearchParams({
                                        ...parsedQuery,
                                        limit: itemsPerPage.toString(),
                                    });
                                } else if (stageView?.count < itemsPerPage) {
                                    setSearchParams({
                                        currentPage: '1',
                                        limit: itemsPerPage.toString(),
                                    });
                                }
                                else {
                                    setSearchParams({
                                        ...parsedQuery,
                                        currentPage: currentPage.toString(),
                                        limit: itemsPerPage.toString(),
                                    });
                                }
                            }}
                            perPageCount={limit || 15}
                            TotalLength={stageView?.count || 15}
                            CurrentPage={currentPage}

                        />
                    </Box>
                </div>
            </DndContext>
        </div>
    )
}

export default StagesView;

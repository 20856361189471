/* eslint-disable no-unused-expressions */
import { call, put, takeLatest } from 'redux-saga/effects';
import { createRatingSuccess, getCriteriabyTaskIdSuccess, getMyReviewsSuccess, getReportHistoryByIdSuccess, getReportHistorySuccess } from "../actions/Rating.Action";
import { CreateRatingApi, GetMyReviewApi, GetReportHistoryApi, GetReportHistoryByIdApi, getCriteriaByTaskId } from '../api';
import { CREATE_RATING, GET_MY_RATING, GET_RATING_BY_ID, GET_REPORT_HISTORY, GET_REPORT_HISTORY_BY_ID } from "../storeTypes";
import toast from 'react-hot-toast';

function* getRatingCriteriaSaga(action) {
    try {
        yield put(getCriteriabyTaskIdSuccess([]));
        const response = yield call(getCriteriaByTaskId, action.payload);
        if (response.data.data) {
            yield put(getCriteriabyTaskIdSuccess(response.data.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        } else {
        }
    }
}
function* createRatingSaga(action) {
    try {
        yield put(createRatingSuccess())
        const response = yield call(CreateRatingApi, action.payload);
        if (response.data.data) {

            console.log('chk29', response);

            yield put(createRatingSuccess(response.data))
            if (response?.status === 200)
                toast.success('Rating saved successfully');
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        }
    }
}

function* getMyReviewSaga(action) {
    try {
        const response = yield call(GetMyReviewApi, action.payload);
        if (response.data.data) {
            yield put(getMyReviewsSuccess(response.data));

        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        }
    }
}

function* getReportHistorySaga(action) {
    try {
        const response = yield call(GetReportHistoryApi, action.payload);
        if (response.data.data) {
            yield put(getReportHistorySuccess(response.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        }
    }
}
function* getReportHistoryByIdSaga(action) {
    try {
        const response = yield call(GetReportHistoryByIdApi, action.payload);
        if (response.data.data) {
            yield put(getReportHistoryByIdSuccess(response.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
        }
    }
}
export function* RatingsSaga() {
    [
        yield takeLatest(GET_RATING_BY_ID, getRatingCriteriaSaga),
        yield takeLatest(CREATE_RATING, createRatingSaga),
        yield takeLatest(GET_MY_RATING, getMyReviewSaga),
        yield takeLatest(GET_REPORT_HISTORY, getReportHistorySaga),
        yield takeLatest(GET_REPORT_HISTORY_BY_ID, getReportHistoryByIdSaga)

    ];
}

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateFolder } from "../../../store/actions/frontApp.Action";

interface FolderProps {
    original: any
}

const FolderNumber: React.FC<FolderProps> = ({ original }) => {
    const [Isopen, setIsopen] = useState(false);
    const [folder, setFolder] = useState(original?.folderNumber);
    const dispatch = useDispatch();

    const handleOpen = () => {
        setIsopen(true);
    }

    const handleCloss = () => {
        setIsopen(false);
    }

    const handleSave = () => {
        const payload = {
            folderNumber: folder
        }
        dispatch(updateFolder(payload, original?.comment_id));
        setIsopen(false);
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSave();
        }
    }

    return (
        <>
            <input
                type="number"
                className="form-control"
                placeholder="Folder Name"
                value={folder}
                onChange={(e) => setFolder(e.target.value)}
                onKeyPress={handleKeyPress}
            />
        </>
    )
}

export default FolderNumber;

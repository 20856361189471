import { CREATE_RATING, CREATE_RATING_SUCCESS, GET_MY_RATING, GET_MY_RATING_SUCCESS, GET_RATING_BY_ID, GET_RATING_BY_ID_SUCCESS, GET_REPORT_HISTORY, GET_REPORT_HISTORY_BY_ID, GET_REPORT_HISTORY_BY_ID_SUCCESS, GET_REPORT_HISTORY_SUCCESS } from "../storeTypes"

export const getCriteriabyTaskId = (payload) => {
    return {
        type: GET_RATING_BY_ID,
        payload: payload
    }
}
export const getCriteriabyTaskIdSuccess = (payload) => {
    return {
        type: GET_RATING_BY_ID_SUCCESS,
        payload: payload
    }
}
export const createRating = (payload, callBack) => {
    return {
        type: CREATE_RATING,
        payload: payload,
        callBack
    }
}
export const createRatingSuccess = (payload) => {
    return {
        type: CREATE_RATING_SUCCESS,
        payload: payload
    }
}
export const getMyReviews = (payload) => {
    return {
        type: GET_MY_RATING,
        payload: payload
    }
}
export const getMyReviewsSuccess = (payload) => {
    return {
        type: GET_MY_RATING_SUCCESS,
        payload: payload
    }
}

//Report History
export const getReportHistory = (payload) => {
    return {
        type: GET_REPORT_HISTORY,
        payload: payload,

    }
}
export const getReportHistorySuccess = (payload) => {
    return {
        type: GET_REPORT_HISTORY_SUCCESS,
        payload: payload
    }
}

export const getReportHistoryById = (payload) => {
    return {
        type: GET_REPORT_HISTORY_BY_ID,
        payload: payload,

    }
}
export const getReportHistoryByIdSuccess = (payload) => {
    return {
        type: GET_REPORT_HISTORY_BY_ID_SUCCESS,
        payload: payload
    }
}


import React from 'react';

import './WelcomeScreen.css';
import { Box, Container, Grid, Heading, Image, Link, Text } from 'theme-ui';

import frontapp from "../assets/images/Icon/frontapp.svg";
import ProjectView from '../assets/images/Project.svg';
import StageView from '../assets/images/StageView.svg';
import TaskView from '../assets/images/TaskView.svg';

const quotes = [
  "The best way to get started is to quit talking and begin doing.",
  "The pessimist sees difficulty in every opportunity. The optimist sees opportunity in every difficulty.",
  "Don't let yesterday take up too much of today.",
  "You learn more from failure than from success. Don’t let it stop you. Failure builds character.",
  "It's not whether you get knocked down, it's whether you get up.",
  "People who are crazy enough to think they can change the world, are the ones who do.",
  "Failure will never overtake me if my determination to succeed is strong enough.",
  "We may encounter many defeats but we must not be defeated.",
  "Knowing is not enough; we must apply. Wishing is not enough; we must do.",
  "Imagine your life is perfect in every respect; what would it look like?",
  "We generate fears while we sit. We overcome them by action.",
  "Whether you think you can or think you can’t, you’re right.",
  "Security is mostly a superstition. Life is either a daring adventure or nothing.",
  "The man who has confidence in himself gains the confidence of others.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "Creativity is intelligence having fun.",
  "What you lack in talent can be made up with desire, hustle and giving 110% all the time.",
  "Do what you can with all you have, wherever you are.",
  "Develop an ‘Attitude of Gratitude’. Say thank you to everyone you meet for everything they do for you.",
  "You are never too old to set another goal or to dream a new dream.",
  "To see what is right and not do it is a lack of courage.",
  "Reading is to the mind, as exercise is to the body.",
  "Fake it until you make it! Act as if you had all the confidence you require until it becomes your reality.",
  "The future belongs to the competent. Get good, get better, be the best!",
  "For every reason it’s not possible, there are hundreds of people who have faced the same circumstances and succeeded.",
  "Things work out best for those who make the best of how things work out.",
  "A room without books is like a body without a soul.",
  "I think goals should never be easy, they should force you to work, even if they are uncomfortable at the time.",
  "One of the lessons that I grew up with was to always stay true to yourself and never let what somebody else says distract you from your goals.",
  "Today’s accomplishments were yesterday’s impossibilities.",
  "The only way to do great work is to love what you do.",
  "You don’t have to be great to start, but you have to start to be great.",
  "Success is not in what you have, but who you are.",
  "Fall seven times and stand up eight.",
  "Some people dream of success while others wake up and work.",
  "If you can’t explain it simply, you don’t understand it well enough.",
  "The only place where success comes before work is in the dictionary.",
  "Too many of us are not living our dreams because we are living our fears.",
  "I find that the harder I work, the more luck I seem to have.",
  "The way to get started is to quit talking and begin doing.",
  "Success is walking from failure to failure with no loss of enthusiasm.",
  "Successful people do what unsuccessful people are not willing to do. Don’t wish it were easier; wish you were better.",
  "I attribute my success to this: I never gave or took any excuse.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "People who are crazy enough to think they can change the world, are the ones who do.",
  "Failure will never overtake me if my determination to succeed is strong enough.",
  "We may encounter many defeats but we must not be defeated.",
  "Knowing is not enough; we must apply. Wishing is not enough; we must do.",
  "Imagine your life is perfect in every respect; what would it look like?",
  "We generate fears while we sit. We overcome them by action.",
  "Whether you think you can or think you can’t, you’re right.",
  "Security is mostly a superstition. Life is either a daring adventure or nothing.",
  "The man who has confidence in himself gains the confidence of others.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "Creativity is intelligence having fun.",
  "What you lack in talent can be made up with desire, hustle and giving 110% all the time.",
  "Do what you can with all you have, wherever you are.",
  "Develop an ‘Attitude of Gratitude’. Say thank you to everyone you meet for everything they do for you.",
  "You are never too old to set another goal or to dream a new dream.",
  "To see what is right and not do it is a lack of courage.",
  "Reading is to the mind, as exercise is to the body.",
  "Fake it until you make it! Act as if you had all the confidence you require until it becomes your reality.",
  "The future belongs to the competent. Get good, get better, be the best!",
  "For every reason it’s not possible, there are hundreds of people who have faced the same circumstances and succeeded.",
  "Things work out best for those who make the best of how things work out.",
  "A room without books is like a body without a soul.",
  "I think goals should never be easy, they should force you to work, even if they are uncomfortable at the time.",
  "One of the lessons that I grew up with was to always stay true to yourself and never let what somebody else says distract you from your goals.",
  "Today’s accomplishments were yesterday’s impossibilities.",
  "The only way to do great work is to love what you do.",
  "You don’t have to be great to start, but you have to start to be great.",
  "Success is not in what you have, but who you are.",
  "Fall seven times and stand up eight.",
  "Some people dream of success while others wake up and work.",
  "If you can’t explain it simply, you don’t understand it well enough.",
  "The only place where success comes before work is in the dictionary.",
  "Too many of us are not living our dreams because we are living our fears.",
  "I find that the harder I work, the more luck I seem to have.",
  "The way to get started is to quit talking and begin doing.",
  "Success is walking from failure to failure with no loss of enthusiasm.",
  "Successful people do what unsuccessful people are not willing to do. Don’t wish it were easier; wish you were better.",
  "I attribute my success to this: I never gave or took any excuse.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "People who are crazy enough to think they can change the world, are the ones who do.",
  "Failure will never overtake me if my determination to succeed is strong enough.",
  "We may encounter many defeats but we must not be defeated.",
  "Knowing is not enough; we must apply. Wishing is not enough; we must do.",
  "Imagine your life is perfect in every respect; what would it look like?",
  "We generate fears while we sit. We overcome them by action.",
  "Whether you think you can or think you can’t, you’re right.",
  "Security is mostly a superstition. Life is either a daring adventure or nothing.",
  "The man who has confidence in himself gains the confidence of others.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "Creativity is intelligence having fun.",
  "What you lack in talent can be made up with desire, hustle and giving 110% all the time.",
  "Do what you can with all you have, wherever you are.",
  "Develop an ‘Attitude of Gratitude’. Say thank you to everyone you meet for everything they do for you.",
  "You are never too old to set another goal or to dream a new dream.",
  "To see what is right and not do it is a lack of courage.",
  "Reading is to the mind, as exercise is to the body.",
  "Fake it until you make it! Act as if you had all the confidence you require until it becomes your reality.",
  "The future belongs to the competent. Get good, get better, be the best!",
  "For every reason it’s not possible, there are hundreds of people who have faced the same circumstances and succeeded.",
  "Things work out best for those who make the best of how things work out.",
  "A room without books is like a body without a soul.",
  "I think goals should never be easy, they should force you to work, even if they are uncomfortable at the time.",
  "One of the lessons that I grew up with was to always stay true to yourself and never let what somebody else says distract you from your goals.",
  "Today’s accomplishments were yesterday’s impossibilities.",
  "The only way to do great work is to love what you do.",
  "You don’t have to be great to start, but you have to start to be great.",
  "Success is not in what you have, but who you are.",
  "Fall seven times and stand up eight.",
  "Some people dream of success while others wake up and work.",
  "If you can’t explain it simply, you don’t understand it well enough.",
  "The only place where success comes before work is in the dictionary.",
  "Too many of us are not living our dreams because we are living our fears.",
  "I find that the harder I work, the more luck I seem to have.",
  "The way to get started is to quit talking and begin doing.",
  "Success is walking from failure to failure with no loss of enthusiasm.",
  "Successful people do what unsuccessful people are not willing to do. Don’t wish it were easier; wish you were better.",
  "I attribute my success to this: I never gave or took any excuse.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "People who are crazy enough to think they can change the world, are the ones who do.",
  "Failure will never overtake me if my determination to succeed is strong enough.",
  "We may encounter many defeats but we must not be defeated.",
  "Knowing is not enough; we must apply. Wishing is not enough; we must do.",
  "Imagine your life is perfect in every respect; what would it look like?",
  "We generate fears while we sit. We overcome them by action.",
  "Whether you think you can or think you can’t, you’re right.",
  "Security is mostly a superstition. Life is either a daring adventure or nothing.",
  "The man who has confidence in himself gains the confidence of others.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "Creativity is intelligence having fun.",
  "What you lack in talent can be made up with desire, hustle and giving 110% all the time.",
  "Do what you can with all you have, wherever you are.",
  "Develop an ‘Attitude of Gratitude’. Say thank you to everyone you meet for everything they do for you.",
  "You are never too old to set another goal or to dream a new dream.",
  "To see what is right and not do it is a lack of courage.",
  "Reading is to the mind, as exercise is to the body.",
  "Fake it until you make it! Act as if you had all the confidence you require until it becomes your reality.",
  "The future belongs to the competent. Get good, get better, be the best!",
  "For every reason it’s not possible, there are hundreds of people who have faced the same circumstances and succeeded.",
  "Things work out best for those who make the best of how things work out.",
  "A room without books is like a body without a soul.",
  "I think goals should never be easy, they should force you to work, even if they are uncomfortable at the time.",
  "One of the lessons that I grew up with was to always stay true to yourself and never let what somebody else says distract you from your goals.",
  "Today’s accomplishments were yesterday’s impossibilities.",
  "The only way to do great work is to love what you do.",
  "You don’t have to be great to start, but you have to start to be great.",
  "Success is not in what you have, but who you are.",
  "Fall seven times and stand up eight.",
  "Some people dream of success while others wake up and work.",
  "If you can’t explain it simply, you don’t understand it well enough.",
  "The only place where success comes before work is in the dictionary.",
  "Too many of us are not living our dreams because we are living our fears.",
  "I find that the harder I work, the more luck I seem to have.",
  "The way to get started is to quit talking and begin doing.",
  "Success is walking from failure to failure with no loss of enthusiasm.",
  "Successful people do what unsuccessful people are not willing to do. Don’t wish it were easier; wish you were better.",
  "I attribute my success to this: I never gave or took any excuse.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "People who are crazy enough to think they can change the world, are the ones who do.",
  "Failure will never overtake me if my determination to succeed is strong enough.",
  "We may encounter many defeats but we must not be defeated.",
  "Knowing is not enough; we must apply. Wishing is not enough; we must do.",
  "Imagine your life is perfect in every respect; what would it look like?",
  "We generate fears while we sit. We overcome them by action.",
  "Whether you think you can or think you can’t, you’re right.",
  "Security is mostly a superstition. Life is either a daring adventure or nothing.",
  "The man who has confidence in himself gains the confidence of others.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "Creativity is intelligence having fun.",
  "What you lack in talent can be made up with desire, hustle and giving 110% all the time.",
  "Do what you can with all you have, wherever you are.",
  "Develop an ‘Attitude of Gratitude’. Say thank you to everyone you meet for everything they do for you.",
  "You are never too old to set another goal or to dream a new dream.",
  "To see what is right and not do it is a lack of courage.",
  "Reading is to the mind, as exercise is to the body.",
  "Fake it until you make it! Act as if you had all the confidence you require until it becomes your reality.",
  "The future belongs to the competent. Get good, get better, be the best!",
  "For every reason it’s not possible, there are hundreds of people who have faced the same circumstances and succeeded.",
  "Things work out best for those who make the best of how things work out.",
  "A room without books is like a body without a soul.",
  "I think goals should never be easy, they should force you to work, even if they are uncomfortable at the time.",
  "One of the lessons that I grew up with was to always stay true to yourself and never let what somebody else says distract you from your goals.",
  "Today’s accomplishments were yesterday’s impossibilities.",
  "The only way to do great work is to love what you do.",
  "You don’t have to be great to start, but you have to start to be great.",
  "Success is not in what you have, but who you are.",
  "Fall seven times and stand up eight.",
  "Some people dream of success while others wake up and work.",
  "If you can’t explain it simply, you don’t understand it well enough.",
  "The only place where success comes before work is in the dictionary.",
  "Too many of us are not living our dreams because we are living our fears.",
  "I find that the harder I work, the more luck I seem to have.",
  "The way to get started is to quit talking and begin doing.",
  "Success is walking from failure to failure with no loss of enthusiasm.",
  "Successful people do what unsuccessful people are not willing to do. Don’t wish it were easier; wish you were better.",
  "I attribute my success to this: I never gave or took any excuse.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "People who are crazy enough to think they can change the world, are the ones who do.",
  "Failure will never overtake me if my determination to succeed is strong enough.",
  "We may encounter many defeats but we must not be defeated.",
  "Knowing is not enough; we must apply. Wishing is not enough; we must do.",
  "Imagine your life is perfect in every respect; what would it look like?",
  "We generate fears while we sit. We overcome them by action.",
  "Whether you think you can or think you can’t, you’re right.",
  "Security is mostly a superstition. Life is either a daring adventure or nothing.",
  "The man who has confidence in himself gains the confidence of others.",
  "The only limit to our realization of tomorrow is our doubts of today.",
  "Creativity is intelligence having fun.",
  "What you lack in talent can be made up with desire, hustle and giving 110% all the time.",
  "Do what you can with all you have, wherever you are.",
  "Develop an ‘Attitude of Gratitude’. Say thank you to everyone you meet for everything they do for you.",
  "You are never too old to set another goal or to dream a new dream.",
  "To see what is right and not do it is a lack of courage.",
  "Reading is to the mind, as exercise is to the body.",
];

const icons = [
  { src: ProjectView, text: 'Projects', link: '/project-view' },
  { src: StageView, text: 'Stages', link: '/stage-view-two' },
  { src: TaskView, text: 'Tasks', link: '/task-view' },
  { src: frontapp, text: 'To-Do List', link: '/front-app?currentPage=1&limit=15' },
];


const FeatureGrid = () => {
  return (
    <Container sx={{ py: 4 }}>
      <Grid gap={4} columns={[1, 2, 4]}>
        {icons.map((icon, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              bg: 'boxBackground',
              p: 3,
              borderRadius: 4,
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '4px',
                height: '100%',
                bg: 'grey',
                boxShadow: '2px 0 6px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <Image src={icon.src} alt={`Icon ${index + 1}`} sx={{ m: 2, width: '32px', height: '32px' }} />
            <Link href={icon.link} className="nav-label">{icon.text}</Link>
          </Box>
        ))}
      </Grid>
    </Container>
  );
};

const QuoteComponent: React.FC = () => {
  const [quote, setQuote] = React.useState('');

  React.useEffect(() => {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);
  }, []);

  return (
    <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '90vh', textAlign: 'center', py: 4 }}>
      <Box sx={{ bg: 'background', p: 4, borderRadius: 4 }}>
        <Heading as="h1" sx={{ variant: 'text.heading', mb: 1 }}>
          Welcome to PEC Priority Sheets!
        </Heading>
        <Text sx={{ variant: 'text.quote' }}>
          {quote}
        </Text>
        <FeatureGrid />
      </Box>
    </Container>
  );
};


const DashboardComponent: React.FC = () => {
  return (
    <div className="main-content" >
      <QuoteComponent />

    </div>
  )
};

export default DashboardComponent;

import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { LuFilter } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Flex, Label, Spinner, Text } from "theme-ui";
import { generateQueryString } from "../../utils/GenerateQueryString";
import Export from "../assets/images/Icon/Export.svg";
import filtercancel from "../assets/images/Icon/InputCancel.svg";
import FormSelect from "./globalSelect";
import { DateRangeSelect } from "./dateRangeSelect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPagination } from "../../store/actions/Pagination.action";
import { getAllMasterDataForTable } from "../../store/actions/masterData.action";
import theme from "../../theme";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import toast from "react-hot-toast";

interface columnProps {
    column: any;
    id: string;
    withoutURLFilter?: boolean;
}
const FilterPopup: React.FC<columnProps> = ({ column, id, withoutURLFilter }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { paginations } = useSelector((state: any) => state.pagination);
    const { loader } = useSelector((state: any) => state.masterData);
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState<string>("");
    const [minValue, setMinValue] = useState<string>("");
    const [maxValue, setMaxValue] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [startDate, endDate] = dateRange;
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [dropdownOptions, setDropDownOptions] = useState<any[]>([]);
    const [filterValue, setfilterValue] = useState(false)
    const [searchFields, setSearchFields] = useState<Array<[string, string]>>([])

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const modal = document.getElementById(id);
            const isClickInsideModal = modal?.contains(event.target as Node);

            if (!isClickInsideModal && isOpen) {

                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleOutsideClick);

        return () => {
            // Clean up the event listener on component unmount
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [isOpen]);

    useEffect(() => {
        // Parse the query string into an object
        const parsedQuery: any = { ...queryString.parse(location.search), currentPage: "1" };
        parsedQuery["istrue"] = false;

        const keyValueArray: Array<[string, string]> = Object?.entries(parsedQuery)
            .filter(([key, value]) => key && value !== null)
            .map(([key, value]) => [key, value as string]);

        setSearchFields(keyValueArray);
        if (parsedQuery[column?.accessorKey]) {
            setInputValue(parsedQuery[column?.accessorKey] as string || "")
        }
        if (column?.filterType === "estimateBox") {
            let optionValue: string[] = parsedQuery[column?.accessorKey]?.split("to");
            setMinValue(optionValue?.[0] || "0");
            setMaxValue(optionValue?.[1] || "0");
        }

        // if (column?.filterType === "dropdown" || column?.filterType === "multi-select-dropdown") {
        //     let optionValue = column?.options;
        //     // console.log(optionValue, "optionValue");
        //     setDropDownOptions(optionValue);
        // }

        if (isOpen && column?.header?.toLowerCase() === "project name") {
            dispatch(getAllMasterDataForTable());
        }
        if (column?.filterType === "timeRangeBox") {
            const timeValues: string[] = parsedQuery[column?.accessorKey]?.split("to");
            setStartTime(timeValues?.[0] || "");
            setEndTime(timeValues?.[1] || "");
        }
    }, [isOpen])

    useEffect(() => {
        if (column?.filterType === "dropdown" || column?.filterType === "multi-select-dropdown") {
            let optionValue = column?.options;
            if (Array?.isArray(optionValue)) {
                setDropDownOptions(optionValue);
            } else {
                setDropDownOptions([]);
            }
        }
    }, [isOpen, column?.options])

    // useEffect(() => {
    //     if (column?.filterType === "dropdown") {
    //         handlefilterApply();
    //     }
    // }, [inputValue])

    const handleClearInput = () => {
        // Parse the current query parameters from the URL
        const parsedQuery = withoutURLFilter ? queryString.parse(paginations?.searchUrl) : queryString?.parse(location?.search);
        parsedQuery["istrue"] = "true"
        // Find the query parameter corresponding to your column's accessor key
        const columnKey = column?.accessorKey;
        const columnValue = parsedQuery[columnKey];
        if (columnValue !== undefined) {
            // If the column's key exists in the URL, set its value to an empty string
            parsedQuery[columnKey] = "";

            // Convert the object into an array of key-value pairs
            const keyValueArray: Array<[string, string]> = Object.entries(parsedQuery)
                .filter(([key, value]) => key && value !== null)
                .map(([key, value]) => [key, value as string]);

            // Update the searchFields state
            setSearchFields(keyValueArray);

            // Generate the new query string
            const urlValue = generateQueryString(...keyValueArray);
            if (withoutURLFilter) {

                dispatch(setPagination({
                    ...paginations,
                    searchUrl: urlValue
                }))

            } else {
                // Navigate to the updated URL
                navigate(`${location?.pathname}${urlValue}`);
            }
        }
        setInputValue("");
        setStartTime("");
        setEndTime("");
        setIsOpen(false);
    };
    const togglePopup = () => setIsOpen(!isOpen);

    const handlefilterApply = (inputValue: string) => {
        setfilterValue(true);
        // Parse the current query parameters from the URL

        const parsedQuery = queryString?.parse(location.search);

        // Find the query parameter corresponding to your column's accessor key
        const columnKey = column?.accessorKey;
        const columnValue = parsedQuery[columnKey];

        // Create a copy of the searchFields array to avoid mutating state directly
        const updatedSearchFields = [...searchFields];

        if (columnValue !== undefined) {
            // If the column's value already exists in the URL, update it
            const columnIndex = updatedSearchFields?.findIndex(([key]) => key === columnKey);
            if (columnIndex !== -1) {
                updatedSearchFields[columnIndex] = [columnKey, inputValue];
            } else {
                // If the column's value doesn't exist in the URL, add a new key-value pair
                updatedSearchFields?.push([columnKey, inputValue]);
            }
        } else {
            // If the column's key doesn't exist in the URL, add a new key-value pair
            updatedSearchFields?.push([columnKey, inputValue]);
        }
        // console.log(updatedSearchFields, "check100");

        // Generate the new query string
        const urlValue = generateQueryString(...updatedSearchFields);

        // Update the searchFields state
        setSearchFields(updatedSearchFields);
        // console.log(`${location?.pathname}${urlValue}`, "checkboth", urlValue, column?.filterType);

        if (withoutURLFilter) {
            dispatch(setPagination({
                ...paginations,
                searchUrl: urlValue
            }))

        } else {
            navigate(`${location?.pathname}${urlValue}`);
        }
        // Navigate to the updated URL
        setIsOpen(false);
    };
    const blanckKeyOption = ['manager', 'worker']
    const formatISODateTime = (date: string, startTime: string, endTime: string) => {
        const startDateTime = moment(`${date}T${startTime}`).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
        const endDateTime = moment(`${date}T${endTime}`).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
        return `${startDateTime}to${endDateTime}`;
    };
    return (
        <div id={id} style={{ display: "inline-block" }}>
            {column?.filterType === "dropdown" || column?.filterType === "number" || column?.filterType === "multi-select-dropdown" || column?.filterType === "textbox" || column?.filterType === "date" || column?.filterType === "estimateBox" || column?.filterType === "timeRangeBox" ? <LuFilter onClick={togglePopup} className={(location?.search?.includes(column?.accessorKey) || paginations?.searchUrl?.includes(column?.accessorKey)) ? "blue-color" : ""} /> : <></>}
            {isOpen && (
                <Box sx={{
                    position: 'absolute',
                    gap: '8px',
                    zIndex: '1',
                    borderRadius: '12px',
                    border: `0.5px solid ${theme?.colors?.lightbackground}`,
                    background: `${theme?.colors?.white}`,
                    boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06)',
                    minWidth: '189px',
                    padding: '12px 8px',
                    right: '5%',
                    top: '100%',
                    fontSize: '14px',
                }}
                >
                    <Text className="filter-name">
                        <LuFilter /> Filter
                    </Text>
                    {loader ? <Spinner height={25} sx={{ color: '#7743DB', width: 3 }} /> :
                        column?.filterType === "textbox" || column?.filterType === "number" ?
                            <Text className="mt-2" sx={{ position: 'relative' }}>
                                <input
                                    className="form-control mt-2"
                                    type={column?.filterType === "number" ? "number" : "textbox"}
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter" && inputValue) {
                                            handlefilterApply(inputValue);
                                        }
                                    }}
                                />
                                {inputValue && (
                                    <>
                                        <button
                                            onClick={handleClearInput}
                                            title="Clear Input"
                                            style={{
                                                position: 'absolute',
                                                right: '8px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                background: 'none',
                                                border: 'none',
                                                cursor: 'pointer',
                                                padding: '0',
                                            }}
                                        >
                                            <img alt="" src={filtercancel} />
                                        </button>
                                        <button
                                            onClick={() => handlefilterApply(inputValue)}
                                            title="Clear Input"
                                            style={{
                                                position: 'absolute',
                                                right: '40px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                background: 'none',
                                                border: 'none',
                                                cursor: 'pointer',
                                                padding: '0',
                                            }}
                                        >
                                            <img alt="" src={Export} />
                                        </button>
                                    </>
                                )}
                            </Text> :
                            column?.filterType === "date" ?
                                <Box>
                                    <DateRangeSelect
                                        onChange={(value) => {
                                            if (value) {
                                                handlefilterApply(value)
                                                setInputValue(value);
                                            }
                                        }}
                                        values={inputValue ? inputValue : ""}
                                    />
                                </Box> :
                                column?.filterType === "estimateBox" ?
                                    <Box>
                                        <Flex sx={{ position: 'relative', gap: "10px" }}>
                                            <Box>
                                                <Label>Min:</Label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    value={minValue}
                                                    onChange={(e) => setMinValue(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter" && minValue) {
                                                            handlefilterApply(`${minValue}to${maxValue}`);
                                                        }
                                                    }}
                                                /></Box>
                                            <Box>
                                                <Label>Max:</Label>
                                                <input
                                                    className="form-control"
                                                    type={"number"}
                                                    value={maxValue}
                                                    onChange={(e) => setMaxValue(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter" && maxValue) {
                                                            handlefilterApply(`${minValue}to${maxValue}`);
                                                        }
                                                    }}
                                                /></Box>
                                        </Flex>

                                        {/* {(minValue && maxValue) && (
                                            <>
                                                <button
                                                    onClick={handleClearInput}
                                                    title="Clear Input"
                                                    style={{
                                                        position: 'absolute',
                                                        right: '8px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        background: 'none',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        padding: '0',
                                                    }}
                                                >
                                                    <img alt="" src={filtercancel} />
                                                </button>
                                                <button
                                                    onClick={() => handlefilterApply(`${minValue}to${maxValue}`)}
                                                    title="Clear Input"
                                                    style={{
                                                        position: 'absolute',
                                                        right: '40px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        background: 'none',
                                                        border: 'none',
                                                        cursor: 'pointer',
                                                        padding: '0',
                                                    }}
                                                >
                                                    <img alt="" src={Export} />
                                                </button>
                                            </>
                                        )} */}
                                    </Box>
                                    :
                                    column.filterType === "timeRangeBox" ? (
                                        <Box>
                                            <Flex sx={{ position: "relative", flexDirection: "column", gap: "10px" }}>
                                                <Box>
                                                    <Label>Date:</Label>
                                                    <ReactDatePicker
                                                        selectsRange
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(update) => {
                                                            setDateRange(update as [Date | null, Date | null]);
                                                        }}
                                                        shouldCloseOnSelect={false}
                                                        isClearable
                                                        placeholderText="Select date range"
                                                        className="form-control"
                                                        dateFormat="yyyy-MM-dd"
                                                    />
                                                    {/* <input
                                                        className="form-control"
                                                        type="date"
                                                        
                                                        value={selectedDate}
                                                        onChange={(e) => setSelectedDate(e.target.value)}
                                                    /> */}
                                                </Box>
                                                <Flex sx={{ gap: "10px" }}>
                                                    <Box>
                                                        <Label>Start Time:</Label>
                                                        <input
                                                            className="form-control"
                                                            type="time"
                                                            value={startTime}
                                                            onChange={(e) => setStartTime(e.target.value)}
                                                        // onKeyDown={(e) => {
                                                        //     if (e.key === "Enter" && startDate && startTime) {
                                                        //         const formattedValue = formatISODateTime(
                                                        //             moment(startDate).format("YYYY-MM-DD"),
                                                        //             startTime,
                                                        //             endTime
                                                        //         );
                                                        //         handlefilterApply(formattedValue);
                                                        //     }
                                                        // }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Label>End Time:</Label>
                                                        <input
                                                            className="form-control"
                                                            type="time"
                                                            value={endTime}
                                                            onChange={(e) => setEndTime(e.target.value)}
                                                        // onKeyDown={(e) => {
                                                        //     if (e.key === "Enter" && endTime) {
                                                        //         const formattedValue = formatISODateTime(
                                                        //             moment(endDate).format("YYYY-MM-DD"),
                                                        //             startTime,
                                                        //             endTime
                                                        //         );
                                                        //         handlefilterApply(formattedValue);
                                                        //     }
                                                        // }}
                                                        />
                                                    </Box>
                                                </Flex>
                                                <Button
                                                    onClick={() => {
                                                        if (startDate && endDate && startTime && endTime) {
                                                            const startDateTimeISO = moment(`${moment(startDate).format("YYYY-MM-DD")}T${startTime}`).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
                                                            const endDateTimeISO = moment(`${moment(endDate).format("YYYY-MM-DD")}T${endTime}`).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

                                                            const formattedValue = `${startDateTimeISO}to${endDateTimeISO}`;

                                                            handlefilterApply(formattedValue);

                                                        }
                                                        else {
                                                            toast.error('Please select both date range and start/end time.');

                                                        }
                                                    }}
                                                >
                                                    Apply Filter
                                                </Button>

                                            </Flex>
                                        </Box>
                                    )
                                        :
                                        <>
                                            <div style={{ position: "absolute", top: "0", right: "0" }}>
                                                {/* {column?.filterType === "multi-select-dropdown" &&
                                            <button
                                                onClick={() => handlefilterApply(inputValue)}
                                                title="Apply Input"
                                                style={{
                                                    position: 'relative',
                                                    right: '38px',
                                                    top: '18%',
                                                    transform: 'translateY(-50%)',
                                                    background: 'none',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    padding: '0',
                                                }}
                                            >
                                                <img alt="" src={Export} style={{ marginTop: "55px" }} />
                                            </button>} */}
                                            </div>
                                            {<FormSelect
                                                value={column?.filterType === "multi-select-dropdown" ? inputValue ? JSON.parse(inputValue) : "" : inputValue}
                                                placeholder={`${column?.header}`}
                                                autoFocus={true}
                                                options={blanckKeyOption?.includes(column?.accessorKey) ? [{ value: 'null', label: "(Blank)" }, ...dropdownOptions] : dropdownOptions || []}
                                                isMulti={column?.filterType === "multi-select-dropdown" ? true : false}
                                                // DefultSelect='Select PM'
                                                // Name='projectManager'
                                                onChange={(values: any) => {
                                                    // console.log(values, "checkvalues");
                                                    if (column?.filterType === "multi-select-dropdown") {
                                                        if (values?.length === 0) {
                                                            handleClearInput();
                                                        } else {
                                                            let urlIds = values?.map((val: { label: string, value: string }) => {
                                                                return val?.value
                                                            })
                                                            urlIds = JSON?.stringify(urlIds);

                                                            handlefilterApply(urlIds)
                                                            setInputValue(urlIds)

                                                        }
                                                    }
                                                    else {

                                                        handlefilterApply(values?.value)
                                                        setInputValue(values?.value);
                                                    }
                                                }}
                                            />}
                                        </>
                    }
                    {column?.filterType === "date" ?
                        <button
                            onClick={handleClearInput}
                            title="Clear Input"
                            style={{
                                position: 'absolute',
                                right: '5%',
                                top: '4%',
                                transform: 'translate(-5%,-4%)',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0',
                            }}
                        >
                            <img alt="" src={filtercancel} />
                        </button> :
                        <button
                            onClick={handleClearInput}
                            title="Clear Input"
                            style={{
                                position: 'absolute',
                                right: '8px',
                                top: '18%',
                                transform: 'translateY(-50%)',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0',
                            }}
                        >
                            <img alt="" src={filtercancel} />
                        </button>
                    }
                </Box >
            )}
        </div>
    );
};

export default FilterPopup;
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { Box, Button, Divider, Flex, Paragraph, Text } from "theme-ui";
import FormSelect from "../../forms-component/globalSelect";
import { usersProps } from "../../../../@types";
import { ColumnDef } from "@tanstack/react-table";
import { convertMsToTime, getApiEndPoint, msToTime } from "../../../utils/GenerateQueryString";
import DataTable from "../../Tabels/Tabel";
import queryString from "query-string";
import moment from "moment";
import 'react-datepicker/dist/react-datepicker.css';
import { getGeneralTimeTracking } from "../../../store/actions/project.action";
import { getAllUserWithoutPagination } from "../../../store/actions/user.action";
import { categoryTimer } from "../../../utils/constent";

export interface generalTimerValue {
    userId?: { value: string }[] | null;
    userTeam?: string | null;
    createdAt?: string | null;

}
const GeneralTimerReport = () => {

    const [selectedUserTeam, setSelectedUserTeam] = useState<string | null>(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const { generalTimeTracking, loader } = useSelector((state: any) => state.project);

    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");

    const userData = useSelector((state: any) => state?.user);

    const Users = userData?.userData?.data;

    const [tableData, setTableData] = useState([]);
    const userOptions = Users?.map((user: any) => ({
        value: user?._id,
        label: `${user?.FirstName} ${user?.LastName}`,
        userTeam: user?.userTeam
    }));

    useEffect(() => {

        let apiEndPoint = getApiEndPoint(
            { limit, currentPage },
            location.search,
            columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || []
        );

        dispatch(getGeneralTimeTracking(apiEndPoint));

    }, [location.search, limit, currentPage]);

    useEffect(() => {
        if (generalTimeTracking) {
            setTableData(generalTimeTracking);
        }
    }, [generalTimeTracking]);

    const handleClearFilters = () => {
        setSelectedUserTeam(null);

        formik.resetForm();

        setSearchParams({
            currentPage: "1",
            limit: "15",
        });
        setTableData([]);
    };

    const formik = useFormik<generalTimerValue>({
        initialValues: {
            userTeam: '',
            createdAt: '',
            userId: [],
        },
        onSubmit: (values, { setSubmitting }) => {

            setSubmitting(false);
        },

    });

    const filteredWorkerOptions = selectedUserTeam
        ? userOptions?.filter((user: usersProps) => user?.userTeam === selectedUserTeam)
        : userOptions;

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            header: ' Sr.No',
            enableColumnFilter: false,
            accessorKey: "no",
            id: "no",
            cell: ({ row }) => {
                return (
                    <Text >{((currentPage - 1) * limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            header: 'Worker',
            accessorKey: "userId",
            cell: ({ row }) => {

                const userInfo = row.original?.userInfo;
                const user = Array.isArray(userInfo) && userInfo?.length > 0 ? userInfo[0] : null;
                return (
                    <Text>
                        {user ? `${user.FirstName || ''} ${user.LastName || ''}`.trim() : '-'}
                    </Text>
                );
            },
            enableColumnFilter: true,
            filterType: 'multi-select-dropdown',
            options: userOptions

        },
        {
            header: 'Title',
            accessorKey: "title",
            cell: ({ row }: any) => {
                const title = row?.original?.title || '';
                return (
                    <Text>{title || '-'}</Text>
                )
            },
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Description',
            accessorKey: "description",
            cell: ({ row }: any) => {
                const desc = row?.original?.description || '';
                return (
                    <Text>{desc || '-'}</Text>
                )
            },
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Status',
            accessorKey: "currentStatus",
            cell: ({ row }: any) => {
                const status = row?.original?.currentStatus || '';
                return (
                    <Text>{status || '-'}</Text>
                )
            },
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Category',
            accessorKey: "category",
            options: categoryTimer,
            enableColumnFilter: true,
            filterType: 'dropdown',

        },
        {
            header: 'Sub Category',
            accessorKey: "subCategory",
            cell: (row: any) => {
                return row.getValue()
                    ?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match: string, index: number) =>
                        match.toUpperCase()
                    )
                    .replace(/\s+/g, '');
            },
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Project Name',
            accessorKey: "projectId",
            cell: ({ row }) => {
                const projectInfo = row?.original?.projectInfo;
                const project = Array?.isArray(projectInfo) && projectInfo?.length > 0 ? projectInfo?.[0] : null;
                return (
                    <Text>{project?.name || '-'}</Text>
                )
            },
            enableColumnFilter: true,
            filterType: 'dropdown',
            options: dataForTable?.data?.allProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name })),
        },
        {
            header: 'Stage Name',
            accessorKey: "stageId",
            cell: ({ row }) => {
                const stageInfo = row.original?.stageInfo;
                const stage = Array.isArray(stageInfo) && stageInfo.length > 0 ? stageInfo[0] : null;
                return (
                    <Text>{stage?.name || '-'}</Text>
                );
            },
            enableColumnFilter: true,
            filterType: 'dropdown',
            options: dataForTable?.data?.allStages?.map((val: { _id: string, name: string }) => ({ value: val.name, label: val.name })),
        },
        {
            header: 'Task Name',
            accessorKey: "taskId",
            cell: ({ row }) => {
                const taskInfo = row.original?.taskInfo;
                const task = Array.isArray(taskInfo) && taskInfo.length > 0 ? taskInfo[0] : null;
                return (
                    <Text>{task?.name || '-'}</Text>
                );
            },
            enableColumnFilter: true,
            filterType: 'textbox',
        },
        {
            header: 'Attendees Name',
            accessorKey: "members",
            cell: ({ row }) => {
                const members = row.original?.membersInfo || [];
                return (
                    <Text>
                        {members?.map((member: any) => `${member.FirstName} ${member.LastName}`).join(', ') || '-'}
                    </Text>
                );
            },
            enableColumnFilter: true,
            filterType: 'multi-select-dropdown',
            options: userOptions
        },
        {
            header: 'Spend Time',
            accessorKey: "timeTaken",
            id: 'timeTaken',
            cell: ({ row }) => {
                const spendTime = row.original.timeTaken;
                const spendTimeMs = spendTime ? +spendTime : 0;
                const formattedSpendTime = spendTimeMs ? `${msToTime(spendTimeMs).hours}:${msToTime(spendTimeMs).minutes}` : "00:00";
                return (
                    <Text variant='Poppins14Normal24lh'>
                        {formattedSpendTime}
                    </Text>
                );
            },
            enableColumnFilter: true,
            filterType: "estimateBox",
        },
        // {
        //     header: 'Date',
        //     accessorKey: "updatedAt",
        //     cell: ({ row }) => {
        //         const dueDateValue = row?.original?.createdAt;
        //         return (
        //             <Text variant='Poppins14Normal24lh'>{dueDateValue ? moment(dueDateValue.split('T')[0]).format("MMM DD, YYYY") : ""}</Text>
        //         );
        //     },
        //     filterType: 'date',

        // },
        {
            header: 'Date-Time',
            accessorKey: "updatedAt",
            cell: ({ row }) => {
                const dueDateValue = row?.original?.updatedAt;
                console.log('chk275', dueDateValue);

                return (
                    <>
                        <Text variant='Poppins14Normal24lh'>
                            {dueDateValue ? moment(dueDateValue.split('T')[0]).format("MMM DD, YYYY") : ""}
                        </Text>

                        <Paragraph variant="Poppins14Normal24lh">
                            {dueDateValue ? moment(dueDateValue).format("hh:mm A") : ""}
                        </Paragraph>
                    </>
                );
            },
            filterType: 'timeRangeBox',

        },

    ], [tableData, generalTimeTracking, dataForTable, filteredWorkerOptions]);
    return (
        <div className="main-content px-3">
            <Box className="d-flex justify-content-between align-items-center" sx={{ mb: 4 }}>
                <Box className="d-flex align-items-center">
                    <h2>General Timer Report</h2>
                    <Button
                        variant='PrimaryHeadBtn'
                        onClick={handleClearFilters}
                        className='toggle-btn'
                        sx={{ ml: 3 }}
                    >
                        Clear Filter
                    </Button>
                </Box>

            </Box>
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <form onSubmit={formik.handleSubmit}>
                <Flex>
                    <div className="col-4 px-1">
                        <FormSelect
                            label={"User Team"}
                            value={selectedUserTeam ? { value: selectedUserTeam, label: selectedUserTeam } : null}
                            placeholder={"Select User Team"}
                            options={[
                                { value: "QDC", label: "QDC" },
                                { value: "PEC", label: "PEC" },
                            ]}
                            isMulti={false}
                            onChange={(selectedOption: any) => {

                                setSelectedUserTeam(selectedOption.value);

                                const dynamicFilter = [
                                    {
                                        type: "string",
                                        value: selectedOption.value,
                                        key: "userTeam",
                                        caseSensitive: false,
                                    },
                                ];

                                const query = `dynamicFilter=${encodeURIComponent(
                                    JSON.stringify(dynamicFilter)
                                )}`;


                                dispatch(getAllUserWithoutPagination(query));
                            }}
                        />

                    </div>

                </Flex>
                <Box className="mt-4 px-2 d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                        <Box className="mr-2" variant='styles.summaryBox' sx={{ fontWeight: 500 }}>Total Time:
                            <Text>
                                {generalTimeTracking?.totalTimeTaken?.totalTime
                                    ? convertMsToTime(generalTimeTracking?.totalTimeTaken?.totalTime)
                                    : '00h:00m:00s'}
                            </Text>
                        </Box>
                        {/* <Button className="mx-2" variant="SaveButton" type='submit'>Preview</Button> */}
                    </div>
                </Box>
            </form>
            <Box className="mt-3 report-table-column">
                <DataTable
                    loader={loader}
                    columns={columns}
                    fixedColumn={true}
                    data={generalTimeTracking?.data || []}
                    TotalLength={generalTimeTracking?.count}
                    perPageCount={limit || 15}
                    currentPage={currentPage || 1}
                    // showNavigation={false}
                    onPerPageChange={(itemsPerPage) => {
                        const parsedQuery = queryString.parse(location.search);
                        if (searchParams?.size > 2) {

                            setSearchParams({
                                ...parsedQuery,
                                limit: itemsPerPage.toString(),
                            });
                        } else if (generalTimeTracking?.count < itemsPerPage) {
                            setSearchParams({
                                currentPage: '1',
                                limit: itemsPerPage.toString(),
                            });
                        }
                        else {
                            setSearchParams({
                                ...parsedQuery,
                                currentPage: currentPage.toString(),
                                limit: itemsPerPage.toString(),
                            });
                        }

                    }}
                    onPageChange={(value) => {
                        const parsedQuery = queryString.parse(location.search);
                        if (searchParams?.size > 2) {

                            setSearchParams({
                                ...parsedQuery,
                                currentPage: value.toString(),
                            });
                        } else {
                            setSearchParams({
                                ...parsedQuery,
                                currentPage: value.toString(),
                                limit: limit.toString(),
                            });
                        }

                    }}
                />
            </Box>
            {/* <GeneralTimerHistory /> */}
        </div>
    )
}

export default GeneralTimerReport
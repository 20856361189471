import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, Text } from "theme-ui";
import { createActionHighlight } from "../../../store/actions/action.Highlighters";

const ActionConfirmation = ({ Open, setOpen, selectedHighlight, actionObject }: any) => {
    const dispatch = useDispatch();
    const togglePopover = () => {
        setOpen(!Open);
    };
    const handleSubmit = () => {
        const payload = {
            actionType: selectedHighlight?.name,
            ...actionObject,
        }
        dispatch(createActionHighlight(payload, (res: any) => {
            if (res.status === 200) {
                togglePopover();
            }
        }))

    }
    return (
        <>
            <Modal isOpen={Open} toggle={togglePopover} size="sm" centered>
                <ModalHeader toggle={togglePopover}>Confirm Highlighter</ModalHeader>
                <ModalBody>
                    <Text>Are you sure you want to Add <Text sx={{ fontWeight: 500 }}>{selectedHighlight?.name} </Text>highlighter?</Text>
                </ModalBody>
                <ModalFooter>
                    <Button variant="CancelButton" sx={{ mr: 2, cursor: 'pointer' }} onClick={togglePopover}>No</Button>
                    <Button type="button" variant="SaveButton" sx={{ cursor: 'pointer' }} onClick={handleSubmit}>Yes</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ActionConfirmation
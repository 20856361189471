import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import { Paddingset } from '../Button/PaddingSet';
import { HeaderBtn } from '../Button/TabelHeadBtn';
import Export from "../assets/images/Icon/Export.svg";
import { SummeryTime } from './SummeryTime';
import { DropDownURL } from '../Button/DropDownURL';
import ClearTableFilter from '../Button/ClearTableFilter';
import { TaskDropDownFilter } from '../Button/TaskDropDownFilter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../@types';
interface TabelHeadingprops {
    Title?: string | undefined;
    BtnTxt?: string | undefined;
    Count?: string | number;
    IsBtnShow?: boolean;
    IsExportButtonShow?: boolean;
    IsCreateButtonShow?: boolean;
    sx?: ThemeUIStyleObject;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    src?: string;
    addText?: string;
    handleClick?: any;
    summeryTime?: boolean;
    limit?: any;
    columns?: any;
    currentPage?: any;
    url?: string;
    ml?: string;
}

export const TabelHeadingTxt: React.FC<TabelHeadingprops> = ({
    Title = '',
    Count = '',
    BtnTxt,
    IsBtnShow = false,
    IsExportButtonShow = true,
    IsCreateButtonShow = false,
    sx,
    onClick,
    addText,
    src,
    handleClick,
    summeryTime,
    limit,
    columns,
    ml,
    url
}) => {
    // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const isStageView = pathname === "/stage-view"
    const isStageViewTwo = pathname === "/stage-view-two"
    const isTaskView = pathname === "/task-view"
    const isYourTeamView = pathname === "/your-team";
    const isYourTodoView = pathname === "/my-todo";

    const allowedRolesForCreateTeam = ["Super Admin", "Project Manager"];
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };
    const { auther } = useSelector((state: RootState) => state.auth);
    console.log('chk61', auther);
    const hasRole = auther?.Role?.some(r => allowedRolesForCreateTeam.includes(r.RoleName));
    console.log('chk61', hasRole);


    // const handleClickOutside = (event: any) => {
    //     if (!event.target.closest('.popover-content')) {

    //         if (!event.target.closest('.toggle-btn')) {
    //             setIsPopoverOpen(false);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);

    // let [searchParams, setSearchParams] = useSearchParams();
    // const addQueryStr = (str: string) => {
    // setSearchParams(str)
    // }

    let urlArray = [{ name: "Default", url: "" }, { name: "Custom Order 1", url: `?currentPage=1&stat=${encodeURIComponent('CustomOrder1')}&sortField=${encodeURIComponent('dueDate,order1')}&istrue=${encodeURIComponent('true')}&sortOrder=${encodeURIComponent('asc,asc')}` }, { name: "Custom Order 2", url: "currentPage=1&stat=CustomOrder2&sortField=dueDate,order2&istrue=true&sortOrder=asc,asc&" }, { name: "Delivered", url: "?currentPage=1&status=Delivered" }, { name: "Ready To Deliver", url: "?currentPage=1&status=Ready To Deliver" }, { name: "Draft", url: "?currentPage=1&status=Draft" }]

    if (pathname === '/task-view') {
        urlArray = urlArray.filter(item => !item.name.startsWith("Custom Order"));
    }
    const customDropdownProps = { isPopoverOpen, togglePopover, setIsPopoverOpen, urlArray }

    return (
        // <Flex sx={{ justifyContent: 'space-between' }}>
        <div className='container-fluid'>
            <div className='row'>
                <div className=' d-flex' style={{ justifyContent: 'space-between' }}>
                    {/* <div className=''> */}
                    <Text as={"li"} variant='Poppins36Normal35lh' style={{ textAlign: "start" }}>
                        {Title}
                        <Badge as={"span"} className="badge " variant='DropDownbedge'>{Count}</Badge>
                        {/*<FaChevronDown onClick={togglePopover} style={{ marginLeft: '60px', width: '19px' }} />
                        {isPopoverOpen && (
                            <div className="popover-content" style={{ position: 'fixed', zIndex: 99999999, }}>
                                <Text as={"p"}>Mechanical</Text>
                                <Text as={"p"}>Electrical</Text>
                                <Text as={"p"}>Plumbing</Text>
                            </div>
                        )} */}
                        {(isStageView ||
                            isTaskView || isStageViewTwo) && <DropDownURL customDropdownProps={customDropdownProps} />}
                        {url && <ClearTableFilter url={url} ml={ml} />}

                    </Text>
                    {/* </div> */}
                    {/* <div className=''> */}
                    <Flex sx={{ gap: 3 }}>
                        {isStageViewTwo && <TaskDropDownFilter />}
                        {IsBtnShow &&
                            <HeaderBtn src={Export} onClick={() => {
                                navigate('/create-project')
                            }} title={'Add Project'} />
                        }
                        {summeryTime && <SummeryTime />}
                        <Paddingset />
                        {IsExportButtonShow && (
                            <HeaderBtn title={BtnTxt} onClick={onClick} src={src} sx={{ padding: '1px 6px' }} />
                        )}
                        {IsCreateButtonShow && isYourTeamView && hasRole && (
                            <HeaderBtn title={BtnTxt} onClick={onClick} src={src} sx={{ padding: '1px 6px' }} />
                        )}
                        {/* {addText && <AddStage/>} */}
                    </Flex>
                    {/* </div> */}
                </div>
            </div >
        </div >
        // </Flex >
    );
};

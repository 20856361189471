import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Checkbox, Flex, Input, Label, Text } from 'theme-ui';
import { deleteActionHighlight, editActionHighlight } from '../../../store/actions/action.Highlighters';
import editIcon from "../../../Components/assets/images/edit.svg";
import deleteIcon from "../../../Components/assets/images/Icon/delete.svg";

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MdDeleteForever, MdDownloadDone, MdRateReview } from "react-icons/md";
import { getAllSubtasksOfSameTask } from '../../../store/actions/project.action';
import { DeletePopModule } from '../../DeletePopModule/DeletePopModule';

export const ActionCard = ({ index, action, selectedHighlight, keys, actionObject, parentData, parentName, isOthersTab = false, isShowDeleteCheckbox = true, actionId, inputWidth = "33%", handleDeleteCheckboxChange, deleteIdList = [], parentId }: any) => {
  const dispatch = useDispatch();
  const [updateName, setUpdateName] = useState<string>();
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const { _id, highlighterId } = parentData
  const [isDeleteChecked, setIsDeleteChecked] = useState<boolean[]>([]);
  console.log('chk19', parentData);

  useEffect(() => {
    setIsDeleteChecked(new Array(action?.length).fill(false));
  }, [action.length]);

  const updateActionHighlight = (objKey: string, updatedValue: boolean) => {
    const payload = {
      name: action?.name,
      blockingTaskId: action?.blockingTaskId,
      ...actionObject,
      actionType: selectedHighlight,
      data: {
        [objKey]: updatedValue,
      }
    }
    dispatch(editActionHighlight(payload, actionId || parentData?._id))
    dispatch(getAllSubtasksOfSameTask(actionObject));

  }
  const editActionHighlightName = () => {
    const payload = {
      name: action?.name,
      ...actionObject,
      actionType: selectedHighlight,
      data: {
        name: updateName,
      }
    }
    dispatch(editActionHighlight(payload, actionId || parentData?._id))
  }

  // const deleteCheckAction = () => {
  //   deleteData.forEach(() => {
  //     dispatch(deleteActionHighlight({ id: deleteData }, action?._id || parentData._id));
  //   });
  //   dispatch(getAllSubtasksOfSameTask(actionObject));
  //   setDeleteData([]);
  //   setIsDeleteChecked(new Array(action.length).fill(false));
  // }
  const deleteCheckAction = () => {
    dispatch(deleteActionHighlight({ id: deleteData }, actionId))
  }
  useEffect(() => {
    if (action?.name) {
      setUpdateName(action?.name)
    }
  }, [action?.name])

  const abbreviations: any = { Revision: "REV", Subtask: "SUB", "New Need Info Added": "NI", "Blocked By Trade": "BT" };
  const displayName = abbreviations[parentName] || parentName;

  return (
    <Box sx={{ display: "grid", paddingLeft: "0.5rem", borderLeft: `2px solid ${selectedHighlight?.color}`, gap: "1rem", }} >
      <Flex sx={{ gap: "1rem", }}>
        <Flex sx={{
          background: "#F6F6F6",
          paddingLeft: "12px",
          paddingRight: "20px",
          marginTop: "10px",
          borderRadius: "8px",
          paddingTop: "2px",
          gap: "10px",
          width: "100%",
          alignItems: "center",
          position: "relative",
          pointerEvents: 'auto'
        }}>
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>Is Reviewed</Tooltip>}>

            <Label sx={{ cursor: "pointer", width: "auto", flexDirection: 'column', marginBottom: "15px" }}>
              <MdRateReview className="mx-1" style={{ color: "rgb(202 138 4)" }} />
              <Checkbox
                name={`${keys?.third}${index}`}
                id={`${keys?.third}${index}`}
                checked={action[keys?.third] || false}
                // disabled={action[keys?.third] || false}
                value={action[keys?.third] || false}
                onChange={(e) => updateActionHighlight(keys?.third, e.target.checked)}
              />
            </Label>
          </OverlayTrigger>
          {/* } */}

          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>Is done</Tooltip>}>
            <Label sx={{ cursor: "pointer", width: "auto", flexDirection: 'column', marginBottom: "15px", }}>
              <MdDownloadDone className="mx-1 text-success" />
              <Checkbox
                name={`${keys?.first}${index}`}
                id={`${keys?.first}${index}`}
                checked={action[keys?.first] || false}
                // disabled={action[keys?.first] || false}
                value={action[keys?.first] || false}
                onChange={(e) => updateActionHighlight(keys?.first, e.target.checked)}
              />
            </Label>
          </OverlayTrigger>
          {parentName && (
            <Text className="textWrap_" style={{ marginRight: "11px" }}>
              ({displayName + (parentData?.payload?.number ? " " + parentData?.payload?.number : "")})
            </Text>
          )}
          <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-${index}`}>{updateName}</Tooltip>}>
            <Input
              variant="PersonalDetailsInput"
              className="form-control"
              id={`checkList${index}`}
              sx={{
                '&:disabled ': {
                  backgroundColor: "transparent",
                }, backgroundColor: "transparent", borderColor: "transparent !important"
              }}
              value={updateName}
              // disabled={isEditing}
              onChange={(e) => setUpdateName(e.target.value)}
              onBlur={() => {
                editActionHighlightName();
                setIsEditing(true);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  editActionHighlightName();
                  setIsEditing(true);
                }
              }}
            />
          </OverlayTrigger>
          <Flex sx={{ marginLeft: "auto", gap: "1rem" }}>
            <Label htmlFor={`checkList${index}`} sx={{ cursor: "pointer" }} onClick={() => setIsEditing(false)}>
              <img src={editIcon} alt="edit" />
            </Label>
            {isOthersTab && (
              <Text sx={{ cursor: "pointer" }} onClick={() => {
                setIsDeleteModelOpen(true);
                setDeleteData(action?._id);
              }}>
                <img src={deleteIcon} alt="delete" width={"23px"} />
              </Text>
            )}
          </Flex>
          {isShowDeleteCheckbox && (
            <Flex sx={{ cursor: 'pointer', flexDirection: "column", alignItems: "center", mb: 3, ml: 4 }}>
              <Label sx={{ cursor: "pointer", marginRight: '3px' }} >
                <MdDeleteForever size={23} color='#D70040' />
              </Label>
              <input type="checkbox"
                name={`delete${index}`}
                id={`delete${index}`}
                checked={deleteIdList?.includes(action?._id) || false}
                onChange={(e) => handleDeleteCheckboxChange(index, e.target.checked, action?._id, action)}
              />
            </Flex>
          )}

          {(action[keys?.first] && action[keys?.third]) && <Box sx={{ position: "absolute", inset: 0, height: "1px", background: "#000", top: "50%" }}></Box>}
        </Flex>
      </Flex >

      <DeletePopModule setIsDeleteModelOpen={setIsDeleteModelOpen} updatedDataFun={deleteCheckAction} isDeleteModelOpen={isDeleteModelOpen} message={`Are you sure you want to delete this `} />

    </Box >
  )
}

import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LuChevronRight } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Badge, Box, Button, Flex, Grid, Image, Spinner, Switch, Text } from 'theme-ui';
import * as Yup from 'yup';
import { MediaProps, RootState } from '../../../../@types';
import { mediaUpload } from '../../../store/actions/media.action';
import { UpdateStageAttachment, editStageById, getHighlighterByView, getHighlighterByViewSuccess, getHistoryByView, getSowChecklist, getSowFields, getStagesById, getStagesByIdSuccess } from '../../../store/actions/project.action';
import { baseUrl } from '../../../utils/global';
import { Inputform } from '../../forms-component/InputForm';
import FormSelect from '../../forms-component/globalSelect';
import StepThreeOnly from '../../project-components/viewTask';
import StageSideActions from './StageSidebar';
import UploadMediaFile from '../../UploadMediaFile/UploadMediaFile';
import { useQuery } from '@tanstack/react-query';
import { ActionHourADay } from '../../Actions/ActionModule/ActionHourADay';
import { ActionModule } from '../../Actions/ActionModule/ActionModule';
import ActionBackgroundCheck from '../../Actions/ActionModule/ActionBackgroundCheck';
import { removeHighlightFromStage } from '../../../store/actions/action.Highlighters';
import { debounce } from 'lodash';
import FolderNumberDropdown from '../create project /StepOne/folderNumberDropdown';
import { statusColors } from '../../../utils/constent';
import spent from "../../assets/images/Icon/spent.svg";
import plyBtn from "../../pages/TaskView/playBtn.svg";

import theme from '../../../theme';
import GeneralTimerStart from '../TaskView/GeneralTimerModal/GeneralTimerStart';

const DoubleClickModal = ({ isOpen, toggle, highlight }: any) => {

    const dispatch = useDispatch();
    const { stageId } = useParams<{ stageId: string }>();

    const handleSwitchChange = () => {
        if (highlight?.name) {
            const payload = {

                highlighterName: highlight.name
            }
            dispatch(removeHighlightFromStage(payload, stageId,));
            toggle();
        }
    };
    return (

        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Acknowledgement</ModalHeader>
            <ModalBody>
                <Box><Switch sx={{ position: 'absolute', top: '17px', right: '8px' }} onChange={handleSwitchChange} /></Box>
                <Text>Are you sure you want to remove Highlighters?</Text>
            </ModalBody>

        </Modal>
    )
};

const EditStageView: React.FC = () => {
    const { stageId } = useParams<{ stageId: string }>();
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const { auther } = useSelector((state: RootState) => state.auth);
    console.log('chk66', dataForTable);

    const { getproject, createprojectMasterData, loader, stagesView } = useSelector(
        (state: any) => state.project
    );

    const projectId = stagesView?.projectId?._id;

    const { uploadedMedia } = useSelector((state: MediaProps) => state?.media);
    const [isModalOpen, setModalOpen] = useState<boolean>(true);
    const [isDoubleClickModalOpen, setDoubleClickModalOpen] = useState<boolean>(false);
    const [modalOpen, issetModalOpen] = useState<boolean>(false);
    const [description, setDescription] = useState(stagesView?.description);
    const [stageStatus, setStageStatus] = useState<keyof typeof statusColors | "">("");
    const [highlighters, setHighlighters] = useState<object[]>([]);
    const projectManagers = createprojectMasterData?.allTaskManager || [];
    const projectWorkers = createprojectMasterData?.allTaskWorkers || [];
    const promise = [
        { label: "Select Promise", value: "" },
        { label: "Customer", value: "Customer" },
        { label: "Personal", value: "Personal" },
        { label: "Hot Promise", value: "hotPromise" },

    ];
    const projectManagerOptions = dataForTable?.data?.allProjectManagers?.map((manager: any) => ({
        value: manager?._id,
        label: `${manager?.FirstName} ${manager?.LastName}`
    }));

    const projectWorkerOptions = dataForTable?.data?.allProjectWorkers?.map((worker: any) => ({
        value: worker?._id,
        label: `${worker?.FirstName} ${worker?.LastName}`
    }));

    // const projectManagerOptions = useMemo(() => {
    //     return projectManagers?.map((manager: any) => ({
    //         value: manager?._id,
    //         label: `${manager?.FirstName} ${manager?.LastName}`,
    //     }));
    // }, [projectManagers]);

    // const projectWorkerOptions = useMemo(() => {
    //     return projectWorkers?.map((worker: any) => ({
    //         value: worker?._id,
    //         label: `${worker?.FirstName} ${worker?.LastName}`,
    //     }));
    // }, [projectWorkers]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const debouncedDispatch = debounce(
        (fieldName: string, value: any) => {
            dispatch(editStageById({ [fieldName]: value }, stageId, fieldName));
            dispatch(getHistoryByView(`stageId=${stageId}`))

        },
        500
    );
    const debounceRequest = useCallback((fieldName: string, value: any) => {
        debouncedDispatch(fieldName, value)
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        return () => {
            debouncedDispatch.cancel()
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        dispatch(getStagesById(stageId));
        // dispatch(getHistoryByView(`stageId=${stageId}`))
        dispatch(getSowChecklist());
        dispatch(getSowFields());

    }, [dispatch, stageId]);

    const validationSchema = Yup.object().shape({
        architect: Yup.string().required('Architect is required'),
        projectManager: Yup.string().required('Project Manager is required'),
    });
    useEffect(() => {
        if (stagesView && stagesView.highlighters) {
            setHighlighters(stagesView.highlighters)
        }
    }, [stagesView.highlighters]);

    const toggleStartTimerModal = () => {
        issetModalOpen(!modalOpen);
    };
    const closeModal = () => {
        navigate(-1)
        setModalOpen(false);
    };

    const toggleDoubleClickModal = () => {
        setDoubleClickModalOpen(!isDoubleClickModalOpen);
    };
    useEffect(() => {
        if (uploadedMedia !== null) {
            dispatch(getStagesById(stageId));
        }
    }, [uploadedMedia])
    useEffect(() => {
        if (stagesView?.status) {
            setStageStatus(stagesView?.status as keyof typeof statusColors);
        }
    }, [stagesView])
    useEffect(() => {
        dispatch(getHighlighterByView("stage"));
        return () => {
            dispatch(getHighlighterByViewSuccess([]));
            dispatch(getStagesByIdSuccess([]));
        }
    }, [])

    const handleSseChange = (data: { [key: string]: any }) => {
        Object.keys(data)?.forEach((element: string) => {
            if (element === "highlighters") {
                handleHighlightersUpdate(data[element]);
            }
            if (element === "status") {
                setStageStatus(data[element] as keyof typeof statusColors);

                if (stagesView.status !== data[element]) {
                    dispatch(getStagesById(stageId));

                }
            }
            formik.setFieldValue(`${element}`, data[element], true);
        });
    }
    const handleHighlightersUpdate = (data: any) => {
        setHighlighters((prev) => Array.isArray(data) ? [...data] : [])
    }


    const formik = useFormik(
        {
            enableReinitialize: true,
            initialValues: {
                dueDate: stagesView?.dueDate || null,
                manager: stagesView?.manager || null,
                name: stagesView?.name,
                projectId: {
                    _id: stagesView?.projectId?._id,
                    name: stagesView?.projectId?.name
                },
                promise: stagesView?.promise || '',
                folderNumber: stagesView?.projectId?.folderNumber,
                tasksNameList: [],
                worker: stagesView?.worker || null,
                _id: stagesView?._id
            },
            validationSchema: validationSchema,
            onSubmit: () => { }
        }
    );
    const { handleSubmit, setFieldValue, handleBlur, touched, values, errors } = formik;

    const handleProfileImageChange = useCallback((event: any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('type', 'project');
                const data = {
                    stage_id: stageId
                };
                formData.append('data', JSON.stringify(data));
                dispatch(mediaUpload(formData));
            } catch (error) {
                console.error('An error occurred during file upload:', error);
            }
        } else {
            console.warn('Profile Image not found!');
        }
    }, []);
    const handleDrop = useCallback((event: any) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        if (files.length > 0) {
            handleProfileImageChange(files);
        } else {
            console.warn('No files dropped!');
        }
    }, [handleProfileImageChange]);
    const handleDragOver = useCallback((event: any) => {
        event.preventDefault();
    }, []);

    // implement Highlight
    const [Open, setOpen] = useState(false);
    const [selectedHighlight, setSelectedHighlight] = useState<any>(null);
    const [highlight, setHighlight] = useState<any>(null);

    const handleHighlightClick = (highlight: any) => {
        if (clickTimeoutRef?.current) {
            clearTimeout(clickTimeoutRef?.current);
            clickTimeoutRef.current = null;
            const dblclickHighlights = ['Background Change Done', 'New Info Added', 'Revision', 'Plan Check', 'Need Info', 'Internal Review', 'Hour a Day',
                'Blocked By Trade', 'Todo Per Review', 'Blocked by Info', 'Final Review', 'Delivery Notes', 'Ready To Deliver', ' Deliver', 'Project Setup', 'Blocked By Trade Completed', 'Background Change InProgress', ' Waiting for Payment - FULL', ' Waiting for Payment - PRELIM', 'Waiting for Trade', 'Background Change']
            setHighlight(dblclickHighlights?.includes(highlight?.name) ? { ...highlight } : highlight);
            setDoubleClickModalOpen(true);
            // if (highlight?.name === "Background Change Done" || highlight?.name === "New Info Added" || highlight?.name === "Revision") {
            //     setHighlight(highlight);
            //     setDoubleClickModalOpen(true);
            // }
        } else {
            clickTimeoutRef.current = setTimeout(() => {
                clickTimeoutRef.current = null;
                const backgroundChangeGroups = ["Background Change Done", "Background Change InProgress"];
                setSelectedHighlight(backgroundChangeGroups?.includes(highlight?.name) ? { ...highlight, name: "Background Change" } : highlight);
                setOpen(true);
            }, 300);
        }
    };

    const customProps_ = {
        selectedHighlight, Open, setOpen, actionObject: { projectId: stagesView?.projectId?._id, stageId: stagesView?._id, taskId: stagesView?.taskId?._id }, activeTab: 0, TaskView: stagesView?.MinTaskData
    }
    const cursorPointerGroups = ["Project Setup", "Hour a Day", "Internal Review", 'Todo Per Review', 'Blocked by Info', "Delivery Notes", 'Ready To Deliver', ' Deliver', "Blocked By Trade", 'Blocked By Trade Completed', ' Waiting for Payment - FULL', ' Waiting for Payment - PRELIM', 'Waiting for Trade', "Need Info", "New Info Added", "Final Review", "Plan Check", "Revision", "Background Change", "Background Change Done", "Background Change InProgress",];



    const { data: eventSource } = useQuery({
        queryKey: ['stageSSE', stageId],
        queryFn: () => {
            const eventSource = new EventSource(`${baseUrl}sse/${stageId}`);

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    handleSseChange(data);
                    dispatch(UpdateStageAttachment(data.attachments))
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };

            eventSource.onerror = (error) => {
                eventSource.close();
            };
            return eventSource;
        },
        refetchInterval: 60000
    })
    useEffect(() => {
        return () => {
            eventSource?.close();
        };
    }, [])

    return (
        <>
            <Modal isOpen={isModalOpen} toggle={closeModal} scrollable centered style={{ maxWidth: '93%' }}>
                <ModalHeader toggle={closeModal}>
                    <Grid
                        columns={[2, '1fr auto']}
                        sx={{
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <h4
                            style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Link className="text-dark" to={`/edit-project/${stagesView?.projectId?._id}`}>
                                {stagesView?.projectId?.name}
                            </Link>
                            &nbsp; <LuChevronRight />{' '}
                            <Link style={{ color: stagesView?.color }} to={''}>
                                {stagesView?.name}
                            </Link>
                            {highlighters?.map((badge: any) => (
                                <Badge
                                    key={badge?._id}
                                    color={badge?.color}
                                    sx={{
                                        backgroundColor: 'transparent',
                                        borderRadius: '8px',
                                        gap: '3px',
                                        padding: '4px 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: `1px solid ${badge?.color}`,
                                        cursor: `${cursorPointerGroups.includes(badge?.name) ? 'pointer' : 'default'}`,
                                    }}
                                    onClick={(e: any) => {
                                        handleHighlightClick(badge);
                                    }}
                                >
                                    {badge?.icon && (
                                        <Image title={badge?.name} src={badge?.icon} alt={badge?.name} width={14} height={14} />
                                    )}
                                    <Text sx={{ color: `${badge?.color}` }}>{badge?.name}</Text>
                                </Badge>
                            ))}
                        </h4>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                            <Button variant="startButton" onClick={toggleStartTimerModal}>
                                <img src={spent} alt="" /> Start Tracking <img src={plyBtn} alt="" />
                            </Button>
                            <div>
                                {loader && <Spinner size={30} className="mx-2" />}
                            </div>
                        </div>
                    </Grid>

                    <Box>
                        <Badge
                            sx={{
                                backgroundColor: `${statusColors[stageStatus as keyof typeof statusColors] || `${theme?.colors?.muted}`
                                    }`,
                                border: `1px solid ${statusColors[stageStatus as keyof typeof statusColors] || `${theme?.colors?.muted}`
                                    }`,
                                borderRadius: '8px',
                                padding: '4px 8px',
                                color: '#fff',
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            {stageStatus}
                        </Badge>
                    </Box>
                    <GeneralTimerStart isOpen={modalOpen} toggle={toggleStartTimerModal} autherId={auther?._id} stageId={stagesView?._id} projectId={stagesView?.projectId?._id} />

                </ModalHeader>


                <ModalBody className="p-0">
                    <Flex sx={{ flexDirection: 'row' }}>
                        <Box sx={{ width: '70%', pr: 3 }}>
                            {/* <Box className="p-3">
                                <Text variant="SelectLabel">Description</Text>
                                <textarea
                                    className="form-control"
                                    value={getproject?.projectSow?.description}
                                    placeholder="project sow..."
                                    rows={5}

                                />
                            </Box> */}
                            <Grid className="p-3"
                                sx={{ gap: '1rem', marginTop: 3 }}
                                columns={[1, 2, 2, 3, 4, 4]}
                            >
                                <Box>
                                    <Inputform
                                        type="date"
                                        label="Due Date"
                                        onBlur={handleBlur}
                                        Name={`dueDate`}
                                        OnChange={(event: any) => {
                                            const newDate = event.target.value;
                                            setFieldValue("dueDate", newDate || null, true);
                                            debounceRequest('dueDate', newDate || null);
                                        }}
                                        values={values?.dueDate?.split('T')[0] || ''}
                                        Placeholder=""
                                    />
                                    {/* <ErrorMessage
                                        name={`dueDate`}
                                        component="div"
                                        className="text-danger"
                                    /> */}
                                </Box>
                                <Box>
                                    <FormSelect
                                        label={"Promise:"}
                                        value={values?.promise || ''}
                                        placeholder={"Select Promise"}
                                        options={promise || []}
                                        isMulti={false}
                                        // disabled={true}
                                        DefultSelect='Select Promise'
                                        touched={touched?.promise}
                                        errors={errors?.promise}
                                        onBlur={handleBlur}
                                        Name='promise'
                                        onChange={(selectedWorker: any) => {
                                            setFieldValue("promise", selectedWorker?.value || '', true);
                                            debounceRequest('promise', selectedWorker?.value || '');
                                        }}
                                    // onChange={(values: any) => { setFieldValue("projectWorker", values?.value, true) }}
                                    />
                                </Box>

                                <Box>
                                    {dataForTable?.data?.allProjectWorkers && <FormSelect
                                        label={"Worker:"}
                                        value={values?.worker || null}
                                        placeholder={"Select Worker"}
                                        options={[
                                            { value: '', label: 'Select Worker' },
                                            ...projectWorkerOptions,
                                        ] || []}
                                        isMulti={false}
                                        // disabled={true}
                                        DefultSelect='Select Worker'
                                        touched={touched?.worker}
                                        errors={errors?.worker}
                                        onBlur={handleBlur}
                                        Name='worker'
                                        onChange={(selectedWorker: any) => {
                                            setFieldValue("worker", selectedWorker?.value || null, true);
                                            debounceRequest('worker', selectedWorker?.value || null);
                                        }}
                                    // onChange={(values: any) => { setFieldValue("projectWorker", values?.value, true) }}
                                    />}
                                </Box>

                                <Box>
                                    {dataForTable?.data?.allProjectManagers && <FormSelect
                                        label={"Manager:"}
                                        value={values?.manager || null}
                                        placeholder={"Select Manager"}
                                        options={[
                                            { value: '', label: 'Select Manager' },
                                            ...projectManagerOptions,
                                        ] || []}
                                        isMulti={false}
                                        // disabled={true}
                                        DefultSelect='Select Manager'
                                        touched={touched?.manager}
                                        errors={errors?.manager}
                                        onBlur={handleBlur}
                                        Name='manager'
                                        onChange={(selectedWorker: any) => {
                                            setFieldValue("manager", selectedWorker?.value || null, true);
                                            debounceRequest('manager', selectedWorker?.value || null);
                                        }}
                                    // onChange={(values: any) => { setFieldValue("projectWorker", values?.value, true) }}
                                    />}
                                </Box>
                                <Box>
                                    <FolderNumberDropdown
                                        label={"Folder Number"}
                                        value={values?.folderNumber}
                                        onBlur={handleBlur}
                                        onChange={(selectedFolderNumber: any) => {
                                            setFieldValue("folderNumber", selectedFolderNumber, true);
                                            if (!loader) {
                                                debounceRequest('folderNumber', selectedFolderNumber);
                                            }
                                        }}
                                        // onChange={(selectedFolderNumber) => {
                                        //     setFieldValue("folderNumber", selectedFolderNumber, true);
                                        // }}
                                        disable={true}
                                    />

                                </Box>
                                {/* <Box>
                                    <Text variant="SelectLabel">manager:</Text>
                                    <Select
                                        placeholder="Select Project Manager"
                                        onBlur={handleBlur}
                                        value={projectManagerOptions.find(
                                            (option) => option.value === values.manager
                                        )}
                                        onChange={(selectedOption) => {
                                            setFieldValue(`manager`, selectedOption?.value || '');
                                            updateField('manager', selectedOption?.value);
                                        }}
                                        options={[
                                            { value: '', label: 'Select Manager' },
                                            ...projectManagerOptions,
                                        ]}
                                    />
                                </Box> */}

                            </Grid>
                            {/* <Tab>Stages Lists</Tab> */}
                            <Tabs className="p-3">
                                <TabList>
                                    <Tab id='details'>Details</Tab>
                                    <Tab id='taskList'>Tasks Lists</Tab>
                                </TabList>
                                <TabPanel>
                                    <UploadMediaFile mediaData={stagesView?.attachments || []} typeId={{
                                        stage_id: stageId || '', project_id: projectId || '',
                                    }} type="stage" loader={loader} id={stageId} />
                                </TabPanel>
                                <TabPanel>
                                    <StepThreeOnly projectId={stagesView?.projectId?._id} />
                                </TabPanel>
                            </Tabs>
                        </Box>
                        <StageSideActions isTracking={null} elapsedTime={"00:00:00"} timeTaken={undefined} threads={stagesView?.threads} />
                    </Flex>
                </ModalBody>
            </Modal>

            {selectedHighlight?.name === "Hour a Day" && <ActionHourADay {...customProps_} keys={{ first: "blockingTaskId" }} />}
            {selectedHighlight?.name === "Internal Review" && <ActionModule {...customProps_} keys={{ first: "isChecked" }} />}
            {selectedHighlight?.name === "Delivery Notes" && <ActionModule {...customProps_} keys={{ first: "isChecked", second: "isInternal" }} />}
            {selectedHighlight?.name === "Deliver" && <ActionModule {...customProps_} keys={{ first: "isChecked", second: "isInternal" }} />}
            {selectedHighlight?.name === "Blocked By Trade" && <ActionModule {...customProps_} keys={{ first: "isChecked", second: "blockingTaskId" }} />}
            {selectedHighlight?.name === "New Info Added" && <ActionModule {...customProps_} keys={{ first: "isChecked" }} handleHighlightClick={handleHighlightClick} />}
            {selectedHighlight?.name === "Need Info" && <ActionModule {...customProps_} keys={{ first: "isChecked", second: "isBlockByInfo" }} />}
            {selectedHighlight?.name === "Final Review" && <ActionModule {...customProps_} keys={{ first: "isChecked" }} />}
            {selectedHighlight?.name === "Plan Check" && <ActionBackgroundCheck {...customProps_} keys={{ first: "isChecked", second: "isInternal" }} />}
            {selectedHighlight?.name === "Revision" && <ActionBackgroundCheck {...customProps_} keys={{ first: "isChecked", second: "isInternal" }} />}
            {selectedHighlight?.name === "Background Change" && <ActionBackgroundCheck {...customProps_} keys={{ first: "isChecked" }} />}
            {selectedHighlight?.name === "Waiting for Payment" && <ActionModule {...customProps_} />}

            <DoubleClickModal isOpen={isDoubleClickModalOpen} toggle={toggleDoubleClickModal} highlight={highlight} />

        </>
    );
};

export default EditStageView;

import { call, put, takeLatest } from "redux-saga/effects";
import { mediaUploadFail, mediaUploadSuccess } from "../actions/media.action";
import { deleteMedia, uploadMedia } from "../api";
import { DELETE_MEDIA_UPLOAD, MEDIA_UPLOAD } from "../storeTypes";

function* mediaUploadSaga(action) {
    try {
        const response = yield call(uploadMedia, action?.payload);
        if (response.data.status === true) {
            // action.callBack(response.data);
            yield put(mediaUploadSuccess(response?.data.data));
        }
    } catch (error) {
        if (error?.response?.status === 403) {
            console.error('Token not provided or expired');
            yield put(mediaUploadFail(error));
        } else {
            yield put(mediaUploadFail(error));
        }
    }
}
function* mediaDeleteSaga(action) {
    try {
        const response = yield call(deleteMedia, action);
        if (response.data.status === true) {
            yield put(mediaUploadSuccess(response?.data.data));
        }
    } catch (error) {
        yield put(mediaUploadFail(error));
    }
}
export function* MediaSaga() {
    yield takeLatest(MEDIA_UPLOAD, mediaUploadSaga);
    yield takeLatest(DELETE_MEDIA_UPLOAD, mediaDeleteSaga);
}

/** @jsxImportSource theme-ui */
import { useEffect, useRef, useState } from 'react';
import { LuX } from 'react-icons/lu';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Box, Flex, Paragraph, Spinner, Text } from 'theme-ui';
import { SearchBar } from '../../SearchBar';
import { ChatCard } from '../ChatCard/ChatCard';
import { useDispatch } from 'react-redux';
import { getProjectById, getProjectByIdSuccess, getStagesById, getTaskById } from '../../../../store/actions/project.action';
import { useSelector } from 'react-redux';
import ChatFooter from '../ChatCard/ChatFooter';
import { editChatsMessageInBulk, getChatsMessage, getGeneralChatsMessage } from '../../../../store/actions/chatAction';
import InfiniteScroll from 'react-infinite-scroll-component';
import theme from '../../../../theme';

const ChatConversation: React.FC = () => {
  const location = useLocation()
  const [isModalOpen, setModalOpen] = useState(true);
  const [typeOfMassage, setTypeOfMassage] = useState("GENERAL");
  const { phaseId, threadId, redirect } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { chats, chat, loader } = useSelector((state: any) => state.chats);
  const { getproject, stagesView, tasksView } = useSelector((state: any) => state.project);
  const [pageNumber, setPageNumber] = useState(1)
  const [isTrue, setIsTrue] = useState(true);
  // console.log(getproject, "getproject", stagesView);
  const { tradeTask } = useSelector((state: any) => state.chats);
  let intervalToFetchMessages: any = null

  useEffect(() => {
    if (phaseId) {
      if (redirect === "stage-view-two" || redirect === "stage-view") {
        dispatch(getStagesById(phaseId));
      } else if (redirect === "edit-task" || redirect === "task-view") {
        dispatch(getTaskById(phaseId));
      } else {
        dispatch(getProjectById(phaseId));
      }
    }

  }, [dispatch]);

  useEffect(() => {
    if (stagesView?.projectId?._id) {
      dispatch(getProjectById(stagesView?.projectId?._id));
    } else if (tasksView?.projectId?._id) {
      dispatch(getProjectById(tasksView?.projectId?._id));
    }
  }, [tasksView, stagesView])

  useEffect(() => {
    return () => {
      dispatch(getProjectByIdSuccess([]));
    }
  }, [])

  const tabData = (text: string) => {
    // dispatch(getGeneralChatsMessage())
    setPageNumber(1)
    if (getproject?._id) {
      if (text === "GENERAL") {
        dispatch(getChatsMessage({ pageNumber: 1, projectId: getproject?._id, thread: threadId, type: "GENERAL" }, false));

      } else {
        dispatch(getChatsMessage({ pageNumber: 1, projectId: getproject?._id, thread: threadId, type: "QUESTION" }, false));
      }
    }

  }

  // useEffect(() => {
  //   dispatch(getChatsMessage({ pageNumber: 1, projectId: phaseId, thread: threadId, type: typeOfMassage }));
  // }, [dispatch, chat, typeOfMassage]);

  useEffect(() => {
    tabData(typeOfMassage)
  }, [chat, getproject?._id])

  useEffect(() => {
    dispatch(getGeneralChatsMessage())
    clearInterval(intervalToFetchMessages)
    if (getproject?._id) {
      intervalToFetchMessages = setInterval(() => {
        if (typeOfMassage === "GENERAL") {
          dispatch(getChatsMessage({ pageNumber: 1, projectId: getproject?._id, thread: threadId, type: "GENERAL" }, false));

        } else {
          dispatch(getChatsMessage({ pageNumber: 1, projectId: getproject?._id, thread: threadId, type: "QUESTION" }, false));
        }
      }, 5000)
    }
    return () => {
      clearInterval(intervalToFetchMessages)
    }
  }, [typeOfMassage, getproject?._id])

  const previousUrl = location.state?.from;
  const closeModal = () => {
    setModalOpen(false);
    navigate(-1);
  };

  const fetchMoreData = () => {
    if (getproject?._id) {
      dispatch(getChatsMessage({ pageNumber: pageNumber + 1, projectId: getproject?._id, thread: threadId, type: typeOfMassage }));
    }
    setPageNumber(pageNumber + 1);
  };

  const messagesFirst = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const scrollToBottom = () => {

    if (messagesFirst.current) {
      messagesFirst.current.scrollTop = 300
    }

  };
  const isAcknowledgedBulkUpdates = () => {
    const isAcknowledgedList = chats?.data?.filter((chat: any) => !chat.isAcknowledged && !chat?.acknowledgementRequired)?.map((chat: any) => chat._id)
    const payload = {
      _id: isAcknowledgedList,
      payload: { isAcknowledged: true }
    }
    if (payload?._id?.length > 0) {
      dispatch(editChatsMessageInBulk(payload))
    }
    setIsTrue(false);
  }
  useEffect(() => {
    if (chats?.data && isTrue) {
      return () => {
        isAcknowledgedBulkUpdates();
      }
    }
  }, [chats?.data])
  return (
    <>
      {isModalOpen && (
        <Modal isOpen={true} centered scrollable style={{ maxWidth: '70%', justifyContent: 'space-between' }}>
          <ModalHeader className='d-block'>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <h2>Comments</h2>
              <SearchBar />
              <LuX onClick={closeModal} style={{ cursor: 'pointer' }} />
            </div>
          </ModalHeader>
          <ModalBody sx={{ overflowX: 'hidden', padding: "0px" }}>
            <Flex sx={{ flexDirection: 'row' }}>
              <Box sx={{ width: '70%' }}>
                <Tabs>
                  <TabList>
                    <Tab onClick={() => {
                      setTypeOfMassage("GENERAL")
                      tabData("GENERAL")
                    }}>General</Tab>
                    <Tab onClick={() => {
                      setTypeOfMassage("QUESTION")
                      tabData("QUESTION")
                    }}>Question</Tab>
                  </TabList>
                  <TabPanel>
                    {/* <ModalBody id="modal-content" style={{ height: '500px', padding: "1rem", overflowY: "auto" }}> */}

                    {/* <InfiniteScroll
                        dataLength={chats?.data?.length || 0}
                        next={fetchMoreData}
                        hasMore={chats?.data?.length !== chats?.count}
                        loader={<Box sx={{ textAlign: "center" }}><Spinner /></Box>}
                        inverse={true}
                        scrollableTarget="modal-content"
                      // scrollableTarget="reverseScrollDiv"
                      >
                        {chats?.data?.length > 0 && chats?.data?.slice().reverse().map((item: any, index: number) 
                          => <ChatCard key={index} index={index} chat={item} />)}
                      </InfiniteScroll> */}

                    <div
                      id="scrollableDiv"
                      className='modal-body'
                      style={{
                        height: "500px",
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                      }}
                    >
                      {/* The scroll bar always on the bottom*/}
                      {loader && <Box sx={{ textAlign: "center" }}><Spinner /></Box>}
                      <InfiniteScroll
                        dataLength={chats?.data?.length || 0}
                        next={fetchMoreData}
                        style={{ display: 'flex', flexDirection: 'column-reverse' }}
                        hasMore={chats?.data?.length !== chats?.count}
                        loader={<Box sx={{ textAlign: "center" }}><Spinner /></Box>}
                        inverse={true}
                        scrollableTarget="scrollableDiv"
                      >
                        {chats?.data?.length > 0 && chats?.data?.map((item: any, index: number) => <ChatCard key={index} index={index} chat={item} />)}
                        <div ref={messagesFirst}></div>
                      </InfiniteScroll>


                    </div>
                    {/* </ModalBody> */}
                    <ModalFooter className='d-block'>
                      <ChatFooter typeOfMassage={typeOfMassage} />
                    </ModalFooter>
                  </TabPanel>
                  <TabPanel>
                    <div id="modal-content-question"
                      className='modal-body'
                      style={{
                        height: '500px',
                        padding: "1rem",
                        overflowY: "auto",
                        display: 'flex',
                        flexDirection: 'column-reverse',
                      }}>
                      {loader && <Box sx={{ textAlign: "center" }}><Spinner /></Box>}
                      <InfiniteScroll
                        dataLength={chats?.data?.length || 0}
                        next={fetchMoreData}
                        style={{ display: 'flex', flexDirection: 'column-reverse' }}
                        hasMore={chats?.data?.length !== chats?.count}
                        loader={<Box sx={{ textAlign: "center" }}><Spinner /></Box>}
                        inverse={true}
                        scrollableTarget="modal-content-question"
                      >
                        {chats?.data?.length > 0 && chats?.data?.map((item: any, index: number) => <ChatCard key={index} index={index} chat={item} />)}
                        <div ref={messagesFirst}></div>
                      </InfiniteScroll>
                    </div>
                    <ModalFooter className='d-block'>
                      <ChatFooter typeOfMassage={typeOfMassage} />
                    </ModalFooter>
                  </TabPanel>
                </Tabs>
              </Box>

              <Box sx={{ width: '30%', borderLeft: `1px solid ${theme?.colors?.lightbackground}`, padding: "2rem" }}>

                <Text sx={{ fontWeight: "600", fontSize: "18px" }}>Thread:</Text>
                <Text className='text-muted mx-2'>{tradeTask?.find((item: any) => item?.taskId?._id === threadId)?.name}</Text>

                <Paragraph sx={{ fontWeight: "600", fontSize: "18px" }}>Project Name:</Paragraph>
                <Paragraph className='text-muted mb-1'>{!(redirect === "stage-view-two") ? getproject?.name : stagesView?.projectId?.name}</Paragraph>

                <Text sx={{ fontWeight: "600", fontSize: "18px" }}>Folder Number:</Text>
                <Text className='text-muted mx-2 mb-3'>{!(redirect === "stage-view-two") ? getproject?.folderNumber : stagesView?.projectId?.folderNumber}</Text>

              </Box>

            </Flex>

          </ModalBody>

        </Modal >

      )}
    </>
  );
};

export default ChatConversation;

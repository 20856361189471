/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Text } from "theme-ui";
import { getAllMasterData, getAllMasterDataForTable } from '../../store/actions/masterData.action';
import { getAllHighlighters, getAllProjectMasterData, getSowChecklist, getSowFields } from '../../store/actions/project.action';
import frontapp from "../assets/images/Icon/frontapp.svg";
import ProjectView from '../assets/images/Project.svg';
import StageView from '../assets/images/StageView.svg';
import TaskView from '../assets/images/TaskView.svg';
import team from '../assets/images/teamLogo2.svg';
import dropdownArrow from "../assets/images/arrow.svg";
import dropdownArrowUp from "../assets/images/arrow-up.svg";
import DashboardIcon from '../assets/images/grid.svg';
import logo from '../assets/images/logo 2.png';
import menu from '../assets/images/menucollapse.svg';
import settings from '../assets/images/settings.svg';
import Task from '../assets/images/task-square.svg';
import Topbar from './topbar';
import { RootState } from '../../../@types';
import { useSelector } from 'react-redux';

export const allowedRolesForAdministration = ["Super Admin", "Project Worker", "Project Manager", "Region Admin"];
export const allowedRolesForArchitect = ["Super Admin", "Project Worker", "Project Manager"];

const masterDataRoutes = [
  '/architect',
  '/stages',
  '/task-sow',
  '/task-field',
  '/task-type',
  '/blueprints',
  '/statuses',
  '/trading-rating',
  '/rating-criteria'
];
const settingsRoutes = [
  '/user',
  '/role',
];

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [showMasterDataDropdown, setShowMasterDataDropdown] = useState(false);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [showTeamsDropdown, setShowTeamsDropdown] = useState(false);
  const [showTodosDropdown, setShowTodosDropdown] = useState(false);
  const [showViewsDropdown, setShowViewsDropdown] = useState(false);
  const [showReportDropdown, setShowReportDropdown] = useState(false);

  const { auther } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCollapsed(true);
      } else if (window.innerWidth <= 1024) {
        setCollapsed(true);
      } else {
        setCollapsed(false)
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    if (masterDataRoutes.some(route => location.pathname.startsWith(route))) {
      setShowMasterDataDropdown(true);
    } else {
      setShowMasterDataDropdown(false);
    }
    if (settingsRoutes.some(route => location.pathname.startsWith(route))) {
      setShowSettingsDropdown(true);
    } else {
      setShowSettingsDropdown(false);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    if (![...masterDataRoutes, ...settingsRoutes].some(route => location.pathname.startsWith(route))) {
      setShowMasterDataDropdown(false);
      setShowSettingsDropdown(false)
    }

  }, [location]);

  const handleToggle = () => {
    setCollapsed(!collapsed);
    setShowMasterDataDropdown(false);
    setShowSettingsDropdown(false);
  };

  const handleMasterDataDropdownToggle = () => {
    setShowMasterDataDropdown(!showMasterDataDropdown);
    setShowSettingsDropdown(false);
    setCollapsed(false);

  };

  const handleTeamsDropdownToggle = () => {
    setShowTeamsDropdown(!showTeamsDropdown);
    setCollapsed(false);
  };
  const handleViewsDropdownToggle = () => {
    setShowViewsDropdown(!showViewsDropdown);
    setCollapsed(false);
  };
  const handleReportDropdownToggle = () => {
    setShowReportDropdown(!showReportDropdown);
    setCollapsed(false);
  };
  const handleTodosDropdownToggle = () => {
    setShowTodosDropdown(!showTodosDropdown);
    setCollapsed(false);
  };
  const handleSettingsDropdownToggle = () => {
    setShowSettingsDropdown(!showSettingsDropdown);
    setShowMasterDataDropdown(false);
    setCollapsed(false);

  };

  const dispatch = useDispatch()
  const key = localStorage.getItem("key")
  useEffect(() => {
    dispatch(getAllMasterData({ query: `?key=${key}` }))
  }, [key])

  useEffect(() => {
    dispatch(getAllMasterDataForTable());
    dispatch(getAllProjectMasterData());
    dispatch(getSowChecklist());
    dispatch(getSowFields());
    dispatch(getAllHighlighters())
  }, [])

  const hasAllowedRole = auther?.Role?.some(r => allowedRolesForAdministration.includes(r.RoleName));
  const hasArchitectRole = auther?.Role?.some(r => allowedRolesForArchitect.includes(r.RoleName));
  const isSuperAdmin = auther?.Role?.some(r => r?.RoleName === "Super Admin");
  const isRegionAdmin = auther?.Role?.some(r => r?.RoleName === "Region Admin");
  const isAllowedReport = !auther?.Role?.some(
    r => r?.RoleName === "Super Admin" || r?.RoleName === "Region Admin"
  );
  return (
    <>
      <Topbar collapsed={collapsed} />
      <nav className={`navbar-primary ${collapsed ? 'collapsed' : ''}`}>
        <div className="d-flex btn-expand-collapse ps-3" >
          <img src={logo} alt="logo" className="logocoll" />
          <img src={menu} alt="menu" width="35px" onClick={handleToggle} />
        </div>
        <ul className="navbar-primary-menu">
          <li className={`DashboardSide ${location.pathname === '/dashboard' ? 'active' : ''}`}>
            <Link to="/dashboard" className="DashboardSide">
              <img src={DashboardIcon} alt="Dashboard Icon" />
              <span className="nav-label">Dashboard</span>
            </Link>
          </li>
          {(isSuperAdmin || isRegionAdmin) &&
            <li className={`DashboardSide ${location.pathname === '/dashboard-new' ? 'active' : ''}`}>
              <Link to="/dashboard-new" className="DashboardSide">
                <img src={DashboardIcon} alt="Dashboard Icon" />
                <span className="nav-label"> Dashboard (V2)</span>
              </Link>
            </li>
          }
          <li className={`DashboardSide ${location.pathname === '/project-view' ? 'active' : ''}`}>
            <Link to="/project-view" className="DashboardSide">
              <img src={ProjectView} alt="Role Icon" />
              <span className="nav-label">Project view </span>
            </Link>
          </li>
          {/* <li className={`DashboardSide ${location.pathname === '/project-view-two' ? 'active' : ''}`}>
            <Link to="/project-view-two" className="DashboardSide">
              <img src={ProjectView} alt="Role Icon" />
              <span className="nav-label">Project view (V2)</span>
            </Link>
          </li> */}
          {/* <li className={`DashboardSide ${location.pathname === '/stage-view' ? 'active' : ''}`}>
            <Link to="/stage-view" className="DashboardSide">
              <img src={StageView} alt="Role Icon" />
              <span className="nav-label">Stages view </span>
            </Link>
          </li> */}
          <li className={`DashboardSide ${location.pathname === '/stage-view-two' ? 'active' : ''}`}>
            <Link to="/stage-view-two" className="DashboardSide">
              <img src={StageView} alt="Role Icon" />
              <span className="nav-label">Stages view </span>
            </Link>
          </li>
          {/* <li className={`DashboardSide ${location.pathname === '/stages-view-second' ? 'active' : ''}`}>
            <Link to="/stages-view-second" className="DashboardSide">
              <img src={StageView} alt="Role Icon" />
              <span className="nav-label">Stages view (V2)</span>
            </Link>
          </li> */}
          <li className={`DashboardSide ${location.pathname === '/task-view' ? 'active' : ''}`}>
            <Link to="/task-view" className="DashboardSide">
              <img src={TaskView} alt="Role Icon" />
              <span className="nav-label">Task view </span>
            </Link>
          </li>
          <li style={{ cursor: "pointer" }}>
            <a className='DashboardSide' onClick={handleViewsDropdownToggle}>
              <img src={ProjectView} alt="Teams Icon" />
              <span className="nav-label">Unfiltered View</span>
              {!collapsed && <img className='mt-2 text-end' src={showViewsDropdown ? dropdownArrowUp : dropdownArrow} />}
            </a>
            {showViewsDropdown && (
              <ul className="submenu" style={{ display: "grid", gap: "5px" }}>

                <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/project-view-two' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/project-view-two" className='w-100'>
                    <Text variant='Poppins14Normal'>Project View</Text>
                  </Link>
                </Text>

                <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/stages-view-second' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/stages-view-second" className='w-100'>
                    <Text variant='Poppins14Normal'>Stage View</Text>
                  </Link>
                </Text>

              </ul>
            )}
          </li>
          <li className={`DashboardSide ${location.pathname === '/my-review' ? 'active' : ''}`}>
            <Link to="/my-review" className="DashboardSide">
              <img src={TaskView} alt="Role Icon" />
              <span className="nav-label">My Review</span>
            </Link>
          </li>

          <li className={`DashboardSide ${location.pathname === '/front-app' ? 'active' : ''}`}>
            <Link to="/front-app" className="DashboardSide">
              <img src={frontapp} alt="Notification" />
              <span className="nav-label">Front App</span>
            </Link>
          </li>

          <li style={{ cursor: "pointer" }}>
            <a className='DashboardSide' onClick={handleReportDropdownToggle}>
              <img src={TaskView} alt="Teams Icon" />
              <span className="nav-label">Report</span>
              {!collapsed && <img className='mt-2 text-end' src={showReportDropdown ? dropdownArrowUp : dropdownArrow} />}
            </a>
            {showReportDropdown && (
              <ul className="submenu" style={{ display: "grid", gap: "5px" }}>
                <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/my-report' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/my-report" className='w-100'>
                    <Text variant='Poppins14Normal'>My Report</Text>
                  </Link>
                </Text>
                {/* {isAllowedReport && ( */}
                <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/my-generalreport' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/my-generalreport" className='w-100'>
                    <Text variant='Poppins14Normal'>General Timer History</Text>
                  </Link>
                </Text>
                {/* )} */}
                {(isSuperAdmin || isRegionAdmin) &&
                  <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/admin-report' ? 'active' : ''}`} style={{ width: "auto" }}>
                    <Link to="/admin-report" className='w-100'>
                      <Text variant='Poppins14Normal'>Admin Report</Text>
                    </Link>
                  </Text>
                }
                {(isSuperAdmin || isRegionAdmin) &&
                  <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/generalTimer-report' ? 'active' : ''}`} style={{ width: "auto" }}>
                    <Link to="/generalTimer-report" className='w-100'>
                      <Text variant='Poppins14Normal'>General Timer Report</Text>
                    </Link>
                  </Text>
                }

                {/* <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/my-general-report' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/my-general-report" className='w-100'>
                    <Text variant='Poppins14Normal'>My General Report</Text>
                  </Link>
                </Text> */}

              </ul>
            )}
          </li>

          {/* {isSuperAdmin &&
            <li className={`DashboardSide ${location.pathname === '/admin-report' ? 'active' : ''}`}>
              <Link to="/admin-report" className="DashboardSide">
                <img src={TaskView} alt="Role Icon" />
                <span className="nav-label">Report</span>
              </Link>
            </li>
          } */}
          <li style={{ cursor: "pointer" }}>
            <a className='DashboardSide' onClick={handleTeamsDropdownToggle}>
              <img src={team} style={{ width: '30px', height: '30px' }} alt="Teams Icon" />
              <span className="nav-label">Teams</span>
              {!collapsed && <img className='mt-2 text-end' src={showTeamsDropdown ? dropdownArrowUp : dropdownArrow} />}
            </a>
            {showTeamsDropdown && (
              <ul className="submenu" style={{ display: "grid", gap: "5px" }}>
                {(isSuperAdmin || isRegionAdmin) &&
                  <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/teams' ? 'active' : ''}`} style={{ width: "auto" }}>
                    <Link to="/teams" className='w-100'>
                      <Text variant='Poppins14Normal'>All Teams</Text>
                    </Link>
                  </Text>
                }
                <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/your-team' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/your-team" className='w-100'>
                    <Text variant='Poppins14Normal'>My Team</Text>
                  </Link>
                </Text>
                <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/assigned-team' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/assigned-team" className='w-100'>
                    <Text variant='Poppins14Normal'>Assigned Team</Text>
                  </Link>
                </Text>
              </ul>
            )}
          </li>
          <li style={{ cursor: "pointer" }}>
            <a className='DashboardSide' onClick={handleTodosDropdownToggle}>
              <img src={Task} alt="Teams Icon" />
              <span className="nav-label">Todo</span>
              {!collapsed && <img className='mt-2 text-end' src={showTeamsDropdown ? dropdownArrowUp : dropdownArrow} />}
            </a>
            {showTodosDropdown && (
              <ul className="submenu" style={{ display: "grid", gap: "5px" }}>
                {(isSuperAdmin || isRegionAdmin) &&
                  <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/todos' ? 'active' : ''}`} style={{ width: "auto" }}>
                    <Link to="/todos" className='w-100'>
                      <Text variant='Poppins14Normal'>All Todos</Text>
                    </Link>
                  </Text>
                }
                <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/your-team' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/my-todo" className='w-100'>
                    <Text variant='Poppins14Normal'>My Todo</Text>
                  </Link>
                </Text>
                <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/assigned-todo' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/assigned-todo" className='w-100'>
                    <Text variant='Poppins14Normal'>Assigned Todo</Text>
                  </Link>
                </Text>
                <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/completed-todos' ? 'active' : ''}`} style={{ width: "auto" }}>
                  <Link to="/completed-todos" className='w-100'>
                    <Text variant='Poppins14Normal'>Completed Todo</Text>
                  </Link>
                </Text>
              </ul>
            )}
          </li>
          {
            hasAllowedRole &&
            <>
              <li style={{ cursor: "pointer" }}>
                <a className='DashboardSide' onClick={handleMasterDataDropdownToggle} >
                  <img src={Task} alt="Tasks" />
                  <span className="nav-label">Master Data</span>
                  {!collapsed && <img className='mt-2 text-end' src={showMasterDataDropdown ? dropdownArrowUp : dropdownArrow} />}
                </a>
                {showMasterDataDropdown && (
                  <ul className="submenu"
                    onClick={(event: any) => {
                      //setLey(event.target.textContent)
                      const value = event.target.textContent
                      localStorage.setItem('key', event.target.textContent);
                      //setLey(value)
                    }} style={{ display: "grid", gap: "5px" }}>
                    {hasArchitectRole && (
                      <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/architect' ? 'active' : ''}`} style={{ width: "auto" }}>
                        <Link to="/architect" className='w-100'>
                          <Text variant='Poppins14Normal'>
                            Architect
                          </Text>
                        </Link>
                      </Text>)}
                    {isSuperAdmin && (
                      <>
                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/stages' ? 'active' : ''}`} style={{ width: "auto" }}>

                          <Link to="/stages" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Stages
                            </Text>
                          </Link>

                        </Text>
                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/checklists' ? 'active' : ''}`} style={{ width: "auto" }}>

                          <Link to="/checklists" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Checklist
                            </Text>
                          </Link>

                        </Text>
                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/uploadTypes' ? 'active' : ''}`} style={{ width: "auto" }}>

                          <Link to="/uploadTypes" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Upload Type
                            </Text>
                          </Link>

                        </Text>
                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/rating-criteria' ? 'active' : ''}`} style={{ width: "auto" }}>
                          <Link to="/rating-criteria" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Rating Criteria
                            </Text>
                          </Link>
                        </Text>
                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/trading-rating' ? 'active' : ''}`} style={{ width: "auto" }}>
                          <Link to="/trading-rating" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Trading Rating Criteria
                            </Text>
                          </Link>
                        </Text>
                        {/* <Text as={"li"} variant='Poppins14Normal'>
                                        <Link to="/project-type" className="DashboardSide">
                                            <Text variant='Poppins14Normal'>
                                                Project
                                            </Text>
                                        </Link>
                                    </Text> */}
                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/task-sow' ? 'active' : ''}`} style={{ width: "auto" }}>
                          <Link to="/task-sow" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Task Sow
                            </Text>
                          </Link>
                        </Text>
                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/task-field' ? 'active' : ''}`} style={{ width: "auto" }}>
                          <Link to="/task-field" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Task Fields Sow
                            </Text>
                          </Link>
                        </Text>
                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/task-type' ? 'active' : ''}`} style={{ width: "auto" }}>
                          <Link to="/task-type" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Task Type
                            </Text>
                          </Link>
                        </Text>

                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/blueprints' ? 'active' : ''}`} style={{ width: "auto" }}>
                          <Link to="/blueprints" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Blueprints
                            </Text>
                          </Link>
                        </Text>

                        <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/statuses' ? 'active' : ''}`} style={{ width: "auto" }}>
                          <Link to="/statuses" className='w-100'>
                            <Text variant='Poppins14Normal'>
                              Statuses
                            </Text>
                          </Link>
                        </Text>
                      </>
                    )}
                  </ul>
                )}
              </li>

            </>
          }

          {(isSuperAdmin || isRegionAdmin) &&
            <li style={{ cursor: "pointer" }}>
              <a className='DashboardSide' onClick={handleSettingsDropdownToggle} >
                <img src={settings} alt="Tasks" />
                <span className="nav-label">Settings</span>
                {!collapsed && <img className='mt-2 text-end' src={showSettingsDropdown ? dropdownArrowUp : dropdownArrow} />}
              </a>
              {showSettingsDropdown && (
                <ul className="submenu" style={{ display: "grid", gap: "5px" }}>
                  {(isSuperAdmin || isRegionAdmin) && (
                    <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/user' ? 'active' : ''}`} style={{ width: "auto" }}>
                      <Link to="/user" className='w-100'>
                        <Text variant='Poppins14Normal'>Users</Text>
                      </Link>
                    </Text>
                  )}
                  {isSuperAdmin &&
                    <Text as={"li"} variant='Poppins14Normal' className={`DashboardSide ${location.pathname === '/role' ? 'active' : ''}`} style={{ width: "auto" }}>
                      <Link to="/role" className='w-100'>
                        <Text variant='Poppins14Normal'>Role</Text>
                      </Link>
                    </Text>
                  }
                </ul>
              )}
            </li>

          }
          <span style={{ position: 'absolute', bottom: "10px", left: "20px" }}>{process.env.REACT_APP_ENV}</span>

        </ul>
      </nav>
    </>
  );
};

export default Sidebar;

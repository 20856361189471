// validationUtils.ts
import { withFormik } from 'formik';
import * as Yup from 'yup';

// Define Yup validation schema
const validationSchema = Yup.object().shape({
    taskName: Yup.string().required('Task Name is required'),
    type: Yup.string().required('Task Type is required'),
    fieldName: Yup.string().required('Field Name is required')
});

// Define the enhancer with Formik and Yup
export const withFormikValidation = withFormik({
    mapPropsToValues: () => ({ taskName: '', type: '', fieldName: '' }),
    validationSchema,
    validateOnMount: true,
    handleSubmit: () => { },
});

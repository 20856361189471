import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box, Card, Flex, Label, Spinner, Switch, Text } from "theme-ui";
import { createActionHighlight, editActionHighlight, getActionHighlighterIds, getActionHighlighterIdsSuccess } from "../../../store/actions/action.Highlighters";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../../../@types";
import { rolePermissionsHighLighters } from "../../../utils/CustomHandler";
import CustomTooltip from "../../CustomTooltip";
import theme from "../../../theme";

export const ActionOnHold = ({ Open, setOpen, selectedHighlight,
  actionObject }: any) => {
  const dispatch = useDispatch();
  const { actionHighlights, createHighlight, loader } = useSelector((state: any) => state.actionHighlight)
  const isOnholder = actionHighlights?.payload?.Description[0]
  const { auther } = useSelector((state: RootState) => state.auth);
  const is_Task_Worker = auther?.Role?.map(role => role?.RoleName).every(role => role.toLocaleLowerCase() === "task worker")


  const togglePopover = () => {
    setOpen(!Open);
  };

  useEffect(() => {
    dispatch(getActionHighlighterIds(actionObject, { name: "On Hold" }))
  }, [dispatch, createHighlight])
  useEffect(() => {
    return () => {
      dispatch(getActionHighlighterIdsSuccess([]))
    }
  }, [])

  const createActionOnHold = (value: any) => {
    if (value) {
      const payload = {
        actionType: selectedHighlight?.name,
        ...actionObject,
        payload: {
          Description: [{
            isOnhold: value,
            name: "onHold",
          }]
        }
      }
      dispatch(createActionHighlight(payload))
    } else {
      const updateData = {
        data: { isOnhold: value },
        ...actionObject,
        name: "onHold",
        actionType: selectedHighlight?.name,
      }
      dispatch(editActionHighlight(updateData, actionHighlights?._id))
    }
  }

  return (
    <Modal isOpen={Open} centered scrollable size='md' toggle={togglePopover}>

      <ModalHeader toggle={togglePopover}> <Flex sx={{ gap: "1rem" }}><img src={selectedHighlight?.icon} /><Text sx={{ color: selectedHighlight?.color }}>{selectedHighlight?.name?.toUpperCase()}</Text></Flex></ModalHeader>
      <ModalBody className='text-center'>
        <Card sx={{ boxShadow: "none", border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "12px 20px", position: "relative" }}>

          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              py: 4,
            }}>
            <Label htmlFor="onHold" sx={{ flex: 1 }}>
              On Hold
            </Label>
            <Box>
              {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? <CustomTooltip id="on-hold" title="Permission Denied">
                <Switch sx={{ cursor: "not-allowed" }} checked={isOnholder?.isOnhold ? true : false} value={"isEnbled"} name="on-hold" id="onHold" /></CustomTooltip> :
                <Switch checked={isOnholder?.isOnhold ? true : false} value={"isEnbled"} name="on-hold" id="onHold" onClick={(e: any) => createActionOnHold(e.target.checked)} />
              }
            </Box>
          </Flex>
          {loader && <Box sx={{ position: "absolute", inset: "0", width: "100%", height: "100%", backgroundColor: 'rgba(255, 255, 255, 0.5)', display: "grid", placeContent: "center" }}><Spinner /></Box>}
        </Card>
      </ModalBody>

    </Modal>
  )
}

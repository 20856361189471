import {
    ColumnDef, ColumnFiltersState, OnChangeFn, flexRender, getCoreRowModel, getFilteredRowModel, useReactTable
} from "@tanstack/react-table";
import { Box, Spinner, Text } from "theme-ui";
import { TabelClassname } from "../../utils/Classname";
import FilterPopup from "../forms-component/filterPopup";
import CommentPopup from "../pages/demotable/CommentPopup";
import TablePagination from "./Pagination";
import theme from "../../theme";
interface DataTableProps<T extends object> {
    data: T[];
    columns: ColumnDef<T>[];
    className?: string | undefined;
    onPageChange: (page: number | any) => void,
    onPerPageChange: (value: string) => void,
    currentPage?: any,
    TotalCount?: number;
    TotalLength?: any;
    perPageCount: number,
    showNavigation?: boolean;
    enableColumnFilters?: boolean,
    onColumnFiltersChange?: OnChangeFn<ColumnFiltersState> | any,
    loader?: boolean;
    fixedColumn?: boolean;
    withoutURLFilter?: boolean;
}
const DataTable = <T extends object>({
    data,
    columns,
    className = '',
    onPageChange,
    currentPage,
    TotalCount,
    showNavigation = true,
    TotalLength,
    onPerPageChange,
    perPageCount,
    enableColumnFilters = true,
    onColumnFiltersChange,
    loader = false,
    fixedColumn,
    withoutURLFilter
}: DataTableProps<T>) => {

    const commonPadding = localStorage.getItem("tPadding") || '13px';
    const table = useReactTable({
        data: data,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        enableColumnFilters: enableColumnFilters,
        pageCount: TotalLength / perPageCount,
        manualPagination: true,
        enableFilters: true,
        enableGlobalFilter: true,
        getFilteredRowModel: getFilteredRowModel(),
        onColumnFiltersChange: onColumnFiltersChange,
    });


    return (
        <div className="table-responsive">
            <Box as="div" sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <div className={`${fixedColumn ? "tableNotFixHead" : "tableFixHead"}`} style={{ borderRadius: 10, border: `1px solid ${theme.colors?.muted}` }}>
                    <table className={`${className + TabelClassname?.PrimaryTabel} mb-0 table-hover`}>
                        <thead className="position-relative">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header: any, index) => {
                                        const isCommentsHeader = header.column.columnDef.header === "Comments";

                                        let specificClass = '';
                                        if (["Created At"].includes(header.column.columnDef.header)) {
                                            specificClass = 'th-no-of-tasks';
                                        } else if (["Description", "Name"].includes(header.column.columnDef.header)) {
                                            specificClass = 'th-quote-number';
                                        } else if (["Version", "Major/Minor", "Link"].includes(header.column.columnDef.header)) {
                                            specificClass = 'th-vc-pc-re';
                                        }
                                        return (
                                            <th className={`th align-middle border-top-0 border-bottom-0 fixed-column-${index} ${specificClass}`}
                                                key={index}
                                                style={{ backgroundColor: `${theme?.colors?.lightbackground}`, padding: `${commonPadding}px`, fontSize: 14 }}>
                                                {header.column.getCanFilter() &&
                                                    <FilterPopup column={header.column.columnDef} id={`column${index}`} withoutURLFilter={withoutURLFilter} />}
                                                <Text variant='Poppins16Normal25lh' as={"span"} className="text-center position-relative">
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {isCommentsHeader && <CommentPopup />}
                                                </Text>
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}

                        </thead>
                        <tbody>
                            {!loader && (
                                table.getRowModel().rows.map((row, rowIndex) => (
                                    <tr key={rowIndex} data-state={row.getIsSelected() && "selected"}
                                        style={{
                                            overflow: 'hidden',

                                            borderBottom:
                                                (rowIndex + 1) === table.getRowModel().rows?.length ? 'none' :
                                                    '1px solid #D3D5D9',
                                        }}
                                    >
                                        {row.getVisibleCells().map((cell, cellIndex) => (
                                            <td className={"fixed-column-" + cellIndex + " table-cell"} key={cell.id} style={{
                                                padding: `${commonPadding}px`, display: "gird",
                                                placeContent: "center",
                                            }}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>

                                        ))}
                                    </tr>
                                ))
                            )}

                        </tbody>

                    </table>
                    {loader &&
                        <Spinner sx={{ position: "absolute", top: "50%", left: "50%" }} />
                    }
                    {!loader && data?.length === 0 && (
                        <Text sx={{ position: "absolute", top: "50%", left: "50%" }}>No Data Found!</Text>
                    )}
                </div>
            </Box>
            {showNavigation ?
                <Box className="sticky-navigation">
                    <TablePagination
                        // className="sticky-navigation"
                        onPageChange={(page) => { onPageChange(page) }}
                        onPerPageChange={onPerPageChange}
                        TotalCount={TotalCount}
                        perPageCount={perPageCount}
                        TotalLength={TotalLength}
                        CurrentPage={currentPage}

                    /></Box> : null}
        </div>
    );
};

export default DataTable;

import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Box, Button, Flex, Text } from "theme-ui";
import * as Yup from "yup";
import { RootState } from "../../../../@types";
import theme from "../../../theme";
import FormSelect from '../../forms-component/globalSelect';
import { useDispatch } from 'react-redux';
import AddStageTaskData from './AddStageTaskData';
import { useLocation } from 'react-router-dom';
import { addTaskById } from '../../../store/actions/project.action';
import moment from 'moment';

export const AddTaskModalBox = () => {
  const [open, setIsOpen] = useState(false);
  const { pathname } = useLocation()
  const userData = useSelector((state: RootState) => state.project);
  const dispatch = useDispatch();
  const { SowChecklist, stagesView, sowfields, getproject, taskbyproject, stagebyproject } = useSelector((state: RootState) => state.project);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const allStages = stagebyproject?.map((stage) => ({
    value: stage?._id,
    label: `${stage?.name} - ${moment(stage?.createdAt).format('MMM DD')}`
    , duedate: stage?.
      dueDate
  }));

  const projectId = stagebyproject?.[0]?.projectId?._id;
  const allTask = userData?.createprojectMasterData?.allTask?.data || [];

  const allTaskOptions = allTask?.map((stage) => ({ value: stage?._id, label: stage?.name, color: stage?.color, department: [] }));

  const validationSchema = Yup.object({
    stagesData: Yup.array().of(
      Yup.object().shape({
        task: Yup.array().min(1, "Select at least one task").of(
          Yup.object().shape({
            description: Yup.string().required("description is required"),
            manager: Yup.string().required("manager is required"),
            worker: Yup.string().required("worker is required"),
            taskFields: Yup.object().required("this field is required")
          })
        ),
      })
    ),
  });
  const toggle = () => { setIsOpen(false); }

  const [isStageError, setIsStageError] = useState(false)

  const handleOpen = () => {

    setIsOpen(true)
  }
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      stagesData: (getproject?.stagesData || []).map((stage) => ({
        id: stage?.id,
        name: stage?.name,
        dueDate: stage?.dueDate,
        stageDataId: taskbyproject?.find(v => v?._id === stage?.id)?.stageDataId || "",
        // task: (stage?.task || []).map((department) => ({
        //   id: department?.id || '',
        //   name: department?.name || '',
        //   color: department?.color || '',
        //   sowMapping: department?.sowMapping || '',
        //   description: department?.description || '',
        //   manager: department?.manager || '',
        //   worker: department?.worker || '',
        //   timeEstimate: department?.timeEstimate || '',
        //   taskFields: department?.taskFields || '',
        //   taskWorker: department?.taskWorker || '',
        //   taskManager: department?.taskManager
        // })) || [],

        promise: stage?.promise,
        worker: stage?.worker,
        manager: stage?.manager,
      })),
      allTask: [],
      task: [{
        id: '',
        name: '',
        color: '',
        sowMapping: [],
        description: '',
        manager: '',
        worker: '',
        timeEstimate: '',
        taskFields: '',
        taskWorker: '',
        taskManager: ''
      }],

    },

    onSubmit: (values) => {

      const { stagesData, task }: any = values;

      const stageDataId = stagebyproject?.find(v => v?._id === stagesData[0]?.id)?._id;
      const stagemasterData = stagebyproject?.find(v => v?._id === stagesData[0]?.id)?.stageId;
      const taskData = [{ ...stagesData[0], id: stagemasterData, task, stageDataId: stageDataId }]

      values.stagesData = [{ ...stagesData[0], task, stageDataId: stageDataId }];

      if (values?.stagesData[0]?.id) {
        setIsStageError(false);
        if (taskData) {
          dispatch(addTaskById({ stagesData: taskData }, projectId));
          formik.resetForm();
          toggle();
        }
      } else {
        setIsStageError(true);
      }

    },
  });

  const handleNextClick = () => {
    handleSubmit();

  };
  const { handleSubmit, setFieldValue, handleChange, handleBlur, touched, values, errors, setValues } = formik;
  const handleSelectChange = (val: any) => {

    const tasks = val?.map((tsk: any) => tsk.value)

    const updatedTask = val?.map((value: any) => {
      const isValueExist = values.task?.find((taskValue) => taskValue.id === value.value)
      return isValueExist ? isValueExist : { id: value.value, name: value.label, color: value?.color }
    })
    setValues({ ...values, allTask: tasks, task: updatedTask })
  }

  useEffect(() => {
    if (open) {
      setIsStageError(false);
    }

  }, [open])
  const [isStageValue, setIsStageValue] = useState(true)

  return (
    <div>
      {!pathname.includes("/edit-project") && <Button variant="SaveButton" onClick={handleOpen}>Add Task</Button>}
      <Modal isOpen={open} toggle={toggle} size='xl' centered scrollable>
        <ModalHeader toggle={toggle}>Add Task</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Box className='mt-2'>
              <FormSelect
                label={"Select Stages"}
                value={values?.stagesData[0]?.id}
                placeholder={"Select Stages"}
                options={allStages || []}
                isMulti={false}
                DefultSelect='Select Stages'
                onBlur={handleBlur}
                Name='stagesData[0].id'
                onChange={(values: any) => {
                  console.log('chk176', values);

                  setFieldValue("stagesData[0].id", values?.value, true);
                  setFieldValue("stagesData[0].name", values?.label, true);
                  setFieldValue("stagesData[0].dueDate", values?.duedate, true); // Set dueDate

                  setIsStageError(false);
                  setIsStageValue(true)
                }}
              />
              {isStageError && <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                {"Stage is required"}
              </Text>}
            </Box>
            <Box className='mt-2'>

              <FormSelect
                // disabled={isStageValue}
                disabled={values?.stagesData[0]?.id ? false : true}
                label={"Select Task"}
                value={values?.allTask}
                placeholder={"Select Tasks"}
                options={allTaskOptions || []}
                isMulti={true}
                DefultSelect='Select Tasks'
                onBlur={handleBlur}
                Name='allTask'
                onChange={(val: any) => handleSelectChange(val)}

              />
            </Box>

            {values?.allTask.length > 0 && < AddStageTaskData
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              values={values}
              setFieldValue={formik?.setFieldValue}
              handleBlur={handleBlur}
              errors={errors}
              touched={formik?.touched}
              SowChecklist={SowChecklist}
              sowfields={sowfields}
            />}
            <Flex sx={{ justifyContent: "end", paddingBottom: '30px' }} className="mt-4">
              <Button sx={{ background: theme.colors?.primary, padding: '12px 18px', width: "25%" }} type="submit" onSubmit={handleNextClick} >Submit</Button>
            </Flex>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}

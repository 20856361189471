import React, { useRef, useState } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Box, Button, Flex, Text, Textarea } from 'theme-ui';
import { FaAngleDown, FaAngleUp, FaStar } from 'react-icons/fa';
import moment from 'moment';
import { ReportHistory } from './ReportHistory/ReportHistory';
import CustomRating from '../../../CustomRating';

interface RatingReviewModalProps {
    open: boolean;
    toggle: () => void;
    RatingCriteria: any;
    rating?: any;
    comment: string[];
    handleSave?: any;
    handleRatingChange: (index: number, newRating: number) => void;
    handlecommentChange: (index: number, event: React.ChangeEvent<HTMLInputElement>) => void;
    data: any;
    type?: string;
    lastUpdatedTwoWeeksAgo?: boolean;
}
const RatingReviewModal: React.FC<RatingReviewModalProps> = ({
    open,
    toggle,
    RatingCriteria,
    rating,
    comment,
    handleRatingChange,
    handleSave,
    handlecommentChange,
    data,
    type,
    lastUpdatedTwoWeeksAgo,
}) => {
    const [showHistory, setShowHistory] = useState(false);
    const commentRefs = useRef<any[]>([]);

    const ratingCount = rating || [1, 1, 1, 1, 1];
    return (
        <Modal toggle={toggle} isOpen={open} backdrop="static" size="xl" centered scrollable>
            <ModalHeader toggle={toggle}>Rating Review</ModalHeader>
            <ModalBody>
                <Row>
                    <Col sm={8} className="pe-0 pb-3">
                        <Flex sx={{ justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                            <ModalBody>
                                {RatingCriteria?.ratingCriteria?.length > 0 &&
                                    RatingCriteria?.ratingCriteria?.map((ratingText: any, index: number) => (
                                        <div className="mb-4" key={index}>
                                            <h5>{ratingText?.ratingCriteria}</h5>
                                            <CustomRating
                                                count={5}
                                                rating={ratingCount[index] || 0}
                                                onChange={(newRating: number) => handleRatingChange(index, newRating)}
                                                disabled={false}
                                            />
                                            <Textarea
                                                className="form-control mt-2"
                                                placeholder="enter comment here..."
                                                value={comment[index] || ''}
                                                rows={2}
                                                onChange={(event: any) => handlecommentChange(index, event)}
                                                ref={(el) => (commentRefs.current[index] = el)}
                                                style={{ overflow: 'hidden' }}
                                            />
                                        </div>
                                    ))}
                            </ModalBody>
                            <ModalFooter className="gap-3">
                                <Button variant="CancelButton" onClick={toggle}>
                                    Cancel
                                </Button>
                                <Button variant="SaveButton" onClick={handleSave}>
                                    Save
                                </Button>
                                <Flex
                                    sx={{
                                        border: '1px solid #7743DB',
                                        borderRadius: '12px',
                                        mx: '1rem',
                                        padding: '14px',
                                        cursor: 'pointer',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                    onClick={() => setShowHistory(!showHistory)}
                                >
                                    <Text>History</Text>
                                    {showHistory ? <FaAngleUp className="text-end" /> : <FaAngleDown className="text-end" />}
                                </Flex>
                            </ModalFooter>
                        </Flex>
                    </Col>
                    <Col sm={4} className="border-start p-4">
                        <div>Activity</div>
                        <p className="text-muted">All Activities</p>
                        <Box
                            className="border p-2"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">Project Name :</p>
                            <Text className="text-muted">{type === 'worker' ? data?.name : data?.projectId?.name}</Text>
                        </Box>
                        <Box
                            className="border p-2 mt-3"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">Task Name :</p>
                            <Text className="text-muted">{data?.name}</Text>
                        </Box>
                        <Box
                            className="border p-2 my-3"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">Stage Name :</p>
                            <Text className="text-muted">{type === 'worker' ? data?.stagesNameList?.[0]?.name : data?.stageId?.name}</Text>
                        </Box>
                        <Box
                            className="border p-2"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">Worker :</p>
                            <Text className="text-muted">{type === 'worker' ? `${data?.projectWorker?.FirstName} ${data?.projectWorker?.LastName}` : `${data?.worker?.FirstName || '-'} ${data?.worker?.LastName || '-'}`}</Text>
                        </Box>
                        <Box
                            className="border p-2 my-3"
                            sx={{ borderRadius: '12px', borderColor: 'lightgray' }}
                        >
                            <p className="mb-0">UpdatedAt :</p>
                            <Text className="text-muted">
                                {moment(RatingCriteria?.updatedAt).format('DD MMM YYYY, h:mm A')}
                                {lastUpdatedTwoWeeksAgo ? (
                                    <Text sx={{ cursor: 'pointer' }}>
                                        <FaStar color="#FFB721" />
                                    </Text>
                                ) : (
                                    <Text sx={{ cursor: 'pointer' }}>
                                        <FaStar color="#006D8F" />
                                    </Text>
                                )}
                            </Text>
                        </Box>
                    </Col>
                </Row>
                {showHistory && (
                    <ModalBody className="border-top">
                        <h5>Rating Review</h5>
                        <ReportHistory tradeNameRef={data?.taskId?._id} showHistory={showHistory} />
                    </ModalBody>
                )}
            </ModalBody>
        </Modal>
    )
}

export default RatingReviewModal
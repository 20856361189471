import { ErrorMessage, Field, FormikProps } from 'formik';
import { withFormikValidation } from './enhancer/AddEditTaskTypeMasterDataEnhancer';
import { Box, Button, Label } from 'theme-ui';
import theme from '../../../../theme';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTaskTypeDataById } from '../../../../store/actions/masterData.action';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types';
import { ActionCreateByUrl, ActionUpdateById } from '../../../../store/actions/CommonAction';


const AddEditTaskTypeMasterData: React.FC<FormikProps<any>> = ({
    values,
    errors,
    touched,
    handleSubmit,
    setValues,
    validateForm,
    isValid,
    isSubmitting
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { taskTypeId } = useParams();
    const { individualTaskType } = useSelector((state: RootState) => state?.masterData);
    const [isNeedToValidate, setIsNeedToValidate] = useState<boolean>(false);

    // console.log(individualTaskType, "check31");

    useEffect((): void => {
        if (taskTypeId) {
            dispatch(getTaskTypeDataById({ id: taskTypeId }))
        }
    }, [taskTypeId])
    useEffect(() => {
        if (taskTypeId && individualTaskType?.status) {
            setValues({
                ...values,
                name: individualTaskType?.data?.name,
                color: individualTaskType?.data?.color
            })
            setIsNeedToValidate(true);
        }
    }, [individualTaskType?.data])
    useEffect(() => {
        if (isNeedToValidate) {
            validateForm();
        }
    }, [isNeedToValidate])
    const handleArchitectData = async (): Promise<void> => {
        handleSubmit();
        if (isValid) {
            taskTypeId ?
                dispatch(ActionUpdateById({ Query: `task-types/edit-task-type/${taskTypeId}`, obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/task-type")
                    }
                }))
                :
                dispatch(ActionCreateByUrl({ Query: "task-types/create-task-types", obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/task-type")
                    }
                }));
        }
    }
    return (
        <div className="main-content p-5">
            <h1>{taskTypeId ? "Edit" : "Add"} Task Type</h1>
            <div className="row">
                <div className="col-md-4 mt-4">
                    <Label htmlFor="name" className={`form-label mt-1 ${errors.name && touched.name ? 'is-invalid' : ''}`}>First Name*</Label>
                    <Field type="text" id="name" name="name" className="form-control" placeholder="Task Type Name" />
                    <ErrorMessage name="name" component="div" className="text-danger" />
                </div>
                <div className="col-md-4 mt-3">
                    <label htmlFor="color" className={`form-label mt-1 ${errors.color && touched.color ? 'is-invalid' : ''}`}>Color*</label>
                    <Box className="text-center d-flex">
                        <Field type="color" id="color" name="color" className="form-control" placeholder="Task Type color" />
                    </Box>
                    <ErrorMessage name="color" component="div" className="text-danger" />
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-md-4'></div>
                <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' onClick={handleArchitectData} disabled={isSubmitting}>Submit</Button></div>
            </div>
        </div >
    );
}

export default withFormikValidation(AddEditTaskTypeMasterData);
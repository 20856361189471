import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button, Flex, Text } from "theme-ui";
import { createActionHighlight, editActionHighlight, getActionHighlighterIds, getActionHighlighterIdsSuccess } from "../../../store/actions/action.Highlighters";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { rolePermissionsHighLighters } from "../../../utils/CustomHandler";
import CustomTooltip from "../../CustomTooltip";
import { RootState } from "../../../../@types";

export default function SubmitReview({ selectedHighlight, Open, setOpen, actionObject, message, actionName }: any) {
  const { actionHighlights, createHighlight } = useSelector((state: any) => state.actionHighlight)
  const { auther } = useSelector((state: RootState) => state.auth);
  const is_Task_Worker = auther?.Role?.map(role => role?.RoleName).every(role => role.toLocaleLowerCase() === "task worker")

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getActionHighlighterIds(actionObject, { name: selectedHighlight?.name }))
  }, [createHighlight])

  useEffect(() => {
    return () => {
      dispatch(getActionHighlighterIdsSuccess([]))
    }
  }, [])


  // const SubmitReview = () => {

  //   const createFun = () => {
  //     const payload = {
  //       actionType: selectedHighlight?.name,
  //       payload: {
  //         Description: [{
  //           name: actionName,
  //           isChecked: false,
  //           comment: []
  //         }]
  //       },
  //       ...actionObject
  //     }
  //     dispatch(createActionHighlight(payload))
  //     togglePopover()
  //   }

  //   if (selectedHighlight?.name !== "Ready To Deliver") {

  //     if (actionHighlights?._id) {
  //       const updateData = {
  //         data: {
  //           name: actionName,
  //           isChecked: false,
  //           comment: []
  //         },
  //         ...actionObject,
  //         actionType: selectedHighlight?.name,
  //       }
  //       dispatch(editActionHighlight(updateData, actionHighlights?._id))
  //       togglePopover()

  //     } else {
  //       createFun()
  //     }

  //   } else {
  //     createFun()
  //   }

  // }


  const SubmitReview = () => {
    const createPayload = (name: string, comment = []) => ({
      actionType: selectedHighlight?.name,
      payload: {
        Description: [{
          name,
          isChecked: false,
          comment
        }]
      },
      ...actionObject
    });

    if (selectedHighlight?.name === "Ready To Deliver") {
      dispatch(createActionHighlight(createPayload(actionName),
        (res: any) => {
          if (res.status === 200) toast.success(`Successfully ${selectedHighlight?.name}.`);
        }));
      togglePopover();

      return;
    }

    if (actionHighlights?._id) {
      const updateData = {
        data: createPayload(actionName).payload.Description[0],
        ...actionObject,
        actionType: selectedHighlight?.name
      };

      dispatch(editActionHighlight(updateData, actionHighlights?._id,
        (res: any) => {
          if (res.status === 200) toast.success(`Successfully ${selectedHighlight?.name}.`);
        }));
    } else {
      dispatch(createActionHighlight(createPayload(actionName),
        (res: any) => {
          if (res.status === 200) toast.success(`Successfully ${selectedHighlight?.name}.`);
        }));
    }

    togglePopover();

  }

  const togglePopover = () => {
    setOpen(!Open);
  };

  return (
    <Modal isOpen={Open} scrollable toggle={togglePopover} centered className='w-100'>
      <ModalHeader toggle={togglePopover}> <Flex sx={{ gap: "0.5rem" }}><img src={selectedHighlight?.icon} width={"22px"} /><Text sx={{ color: selectedHighlight?.color }}>{selectedHighlight?.name?.toUpperCase()}</Text></Flex></ModalHeader>
      <ModalBody className='mb-0 pb-0'>
        <div style={{
          width: '100%',
          position: 'relative',
          zIndex: 1,
          backgroundColor: 'white',
          border: '1px solid #black',
          boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06);',
          borderRadius: '4px',
          padding: '20px 4px'
        }}>
          <Text className=" w-100" as="p">
            <strong>{message}</strong>
          </Text>
        </div>
      </ModalBody>
      <ModalBody className='d-flex justify-content-end gap-2'>
        <Button variant="CancelButton" onClick={togglePopover} >Cancel</Button>
        {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? <CustomTooltip id={selectedHighlight?.name + "RFFR"} title="Permission Denied">
          <Button variant="SaveButton" style={{ cursor: (is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? "not-allowed" : "pointer" }} >Submit</Button>
        </CustomTooltip> :
          <Button variant="SaveButton" onClick={SubmitReview} >Submit</Button>}
      </ModalBody>
    </Modal >
  )
}
import { ColumnDef } from '@tanstack/react-table';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Badge, Button, Divider, Flex, Paragraph, Text } from 'theme-ui';
import { GetAllTaskView, UpdateTaskRow, getTimeSummery } from '../../../store/actions/project.action';
import { getApiEndPoint, msToTime } from '../../../utils/GenerateQueryString';
import { baseUrl } from '../../../utils/global';
import DataTable from '../../Tabels/Tabel';
import { TabelHeadingTxt } from '../../Text/TabelCommonTxt';
import Delete from "../../assets/images/trashnew.svg";
import { useQuery } from '@tanstack/react-query';
import { usePreviousURL } from '../../../utils/CustomHandler';
import ReviewModal from '../demotable/Ratings';
import { FolderOption, RootState } from '../../../../@types';
import moment from 'moment';
import { statusColors } from '../../../utils/constent';
import clockIcon from "../../../Components/assets/images/Icon/Clock.svg";
import theme from '../../../theme';
import GeneralTimerStart from './GeneralTimerModal/GeneralTimerStart';
import { deleteMultipleTask } from '../../../store/actions/masterData.action';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import toast from 'react-hot-toast';
import CommentsDialogBox from '../demotable/CommentSection';

const TaskView: React.FC = () => {
    const { taskView, loader, highlighters, timeTracking } = useSelector((state: any) => state.project);
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { auther } = useSelector((state: RootState) => state.auth);
    const [istaskOpen, setIstaskOpen] = useState<boolean>(false);
    const [taskId, setTaskId] = useState<string>("");
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");
    const parsedQuery = queryString.parse(location.search);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    // Custom
    const previousURL = usePreviousURL()
    // useEffect(() => {
    //     setSearchParams({
    //         currentPage: "1",
    //         limit: "15",
    //     });
    // }, [])

    const generateFolderOptions = (): FolderOption[] => {
        const startMonth = 1609;
        const currentYear = new Date().getFullYear() % 100;
        const currentMonth = new Date().getMonth() + 1;
        // console.log('Current Year:', currentYear);
        // console.log('Current Month:', currentMonth);
        const folderOptions: FolderOption[] = [];
        for (let year = 16; year <= currentYear; year++) {
            const start = (year === 16) ? startMonth % 100 : 1;
            const end = (year === currentYear) ? currentMonth + 2 : 12;
            for (let month = start; month <= end; month++) {
                const formattedOption = `projects_${year.toString().padStart(2, '0')}${month.toString().padStart(2, '0')}`;
                const optionObject: FolderOption = { label: formattedOption, value: formattedOption };
                folderOptions.push(optionObject);
                if (year === currentYear && month === currentMonth) {
                    optionObject.defaultSelected = true;
                }
            }
        }
        folderOptions.sort((a, b) => a.label.localeCompare(b.label));
        // console.log('folderOptions:', folderOptions);
        return folderOptions;
    };
    const handleSelectTask = (taskId: string, isChecked: boolean) => {
        setSelectedRows(prev => {
            if (isChecked) {
                return [...prev, taskId];
            } else {
                return prev.filter(id => id !== taskId);
            }
        });
    };
    const handleDeleteSelectedTasks = () => {
        if (selectedRows.length > 0) {
            setShowConfirmation(true);
        }
        else {
            toast.error("No tasks selected!!")
        }
    };

    const cancelDeleteTask = () => {
        setShowConfirmation(false);
    };
    const handleConfirmDeleteSelectedTasks = () => {
        if (selectedRows.length > 0) {
            const payload = { taskIds: selectedRows };
            let apiEndPoint = getApiEndPoint(
                { limit, currentPage },
                location.search,
                columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || []
            );
            dispatch(deleteMultipleTask(payload));
            setSelectedRows([]);
            dispatch(GetAllTaskView(apiEndPoint))
            setShowConfirmation(false);

        }

    }

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            header: 'Select',
            accessorKey: "select",
            cell: ({ row }) => {
                const isChecked = selectedRows.includes(row.original._id);
                return (
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => handleSelectTask(row.original._id, e.target.checked)}
                    />
                );
            },
        },
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            id: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "name",
            header: 'Task name',
            cell: ({ row }) => {
                // console.log("rowdata---", row?.original);

                return (
                    <Text onClick={() => { previousURL(`/edit-task/${row?.original?._id}`) }} style={{ textDecoration: 'underline', color: `${theme.colors?.secondary}`, cursor: "pointer", fontWeight: 500 }}>
                        {row?.original?.name || ''}
                    </Text>

                )
            },
            id: "taskName",
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Stage Name',
            accessorKey: "stageId",
            id: "stageName",
            cell: ({ row }) => {
                return (
                    <Text >{row.original.stageId?.name}</Text>
                )
            },
            enableColumnFilter: true,
            filterType: 'dropdown',
            options: dataForTable?.data?.allStages?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }))
        },
        {
            header: 'Project Name',
            accessorKey: "projectId",
            id: "projectName",
            cell: ({ row }) => {
                return (
                    <Paragraph className='project-name-row' >{row.original?.projectId?.name}</Paragraph>
                )
            },
            enableColumnFilter: true,
            filterType: 'dropdown',
            options: (() => {
                if (parsedQuery?.status == "Delivered") {
                    return dataForTable?.data?.allDeliveredTask?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                } else if (parsedQuery?.status == "Ready To Deliver") {
                    return dataForTable?.data?.allRTDTask?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                } else if (parsedQuery?.status == "Draft") {
                    return dataForTable?.data?.allDraftTask?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                } else {
                    return dataForTable?.data?.allProjects?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }));
                }
            })()
        },
        {
            header: 'Folder Number',
            accessorKey: "folderNumber",
            cell: ({ row }) => {
                return (
                    <Text >{row?.original?.projectId?.folderNumber}</Text>
                )
            },
            // filterType: 'dropdown',
            // options: generateFolderOptions(),
        },
        {
            header: 'TM Manager',
            accessorKey: "manager",
            id: "manager",
            cell: ({ row }) => {
                return (
                    <>
                        {/* <Text >{row.original.manager?.FirstName} {row.original.manager?.LastName}</Text> */}
                        <ReviewModal data={row?.original} type="taskManager" />
                    </>
                )
            },
            enableColumnFilter: true,
            filterType: 'dropdown',
            options: dataForTable?.data?.allTaskManagers?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val?._id, label: `${val?.FirstName} ${val?.LastName}` }))
        },
        {
            header: 'TM Worker',
            accessorKey: "worker",
            id: 'taskWorker',
            cell: ({ row }) => {
                return (
                    <>
                        {/* <Text >{row.original.worker?.FirstName} {row.original.worker?.LastName}</Text> */}
                        <ReviewModal data={row?.original} type="taskWorker" />
                    </>
                )
            },
            // enableColumnFilter: true,
            filterType: 'multi-select-dropdown',
            options: dataForTable?.data?.allTaskWorkers?.map((val: { _id: string, FirstName: string, LastName: string }) => ({ value: val?._id, label: `${val?.FirstName} ${val?.LastName}` }))
        },
        {
            header: 'Highlighters',
            accessorKey: "highlighters",
            cell: ({ row }) => {
                const stages = row.original.highlighters;
                const HAD_ = row.original?.hoursADay
                if (stages && stages.length > 0) {
                    return (
                        <Flex sx={{ justifyContent: "center", flexWrap: 'wrap' }}>
                            {stages?.map((val: { color: string, title: string, _id: string, name: string }) => (
                                <Badge title={val.name} sx={{ color: `${val.color}`, border: `1px solid ${val.color}`, borderRadius: 6, backgroundColor: "transparent", margin: "5px", position: "relative" }} key={val._id}>
                                    {Number(HAD_) > 0 && <Badge className="px-2" sx={{ position: "absolute", top: "-15px", right: "-11px", borderRadius: '15px', backgroundColor: '#0d7298' }}>
                                        {val.name === "Hour a Day" && HAD_}
                                    </Badge>}
                                    {val.title}</Badge>
                            ))
                            }
                        </Flex >
                    );
                } else {
                    return null;
                }
            },
            filterType: 'multi-select-dropdown',
            options: highlighters?.map((val: { _id: string, name: string }) => ({ value: val._id, label: val.name }))
        },
        {
            header: 'Estimate Time',
            accessorKey: "timeEstimate",
            id: 'timeEstimate',
            // cell: info => info.getValue(),
            cell: ({ row }) => {
                const estimatedTime = row.original.timeEstimate;
                const estimatedMs = estimatedTime ? +estimatedTime * 60 * 60 * 1000 : 0;
                const formattedEstimatedTime = estimatedMs ? `${msToTime(estimatedMs).hours}:${msToTime(estimatedMs).minutes}` : "00:00";

                return (
                    <Text variant='Poppins14Normal24lh'>
                        {formattedEstimatedTime}
                    </Text>
                );
            },
            enableColumnFilter: true,
            filterType: "estimateBox",
        },
        {
            header: 'Remaining Time',
            accessorKey: "timeRemaining",
            id: 'timeRemaining',
            // cell: info => info.getValue(),
            cell: ({ row }) => {
                const remainingTime = row.original.timeRemaining;
                const remainingMs = remainingTime ? +remainingTime : 0;
                const formattedRemainingTime = remainingMs ? `${msToTime(remainingMs).hours}:${msToTime(remainingMs).minutes}` : "00:00";

                return (
                    <Text variant='Poppins14Normal24lh'>
                        {formattedRemainingTime}
                    </Text>
                );
            },
            enableColumnFilter: true,
            filterType: "estimateBox",
        },
        {
            header: 'Status',
            accessorKey: "status",
            id: 'status',
            cell: ({ row }) => {
                type StatusType = keyof typeof statusColors;

                const status = row.original.status as StatusType;

                const color = statusColors[status] || '#000';

                return (
                    <Badge
                        title={status}
                        sx={{
                            color: `white`,
                            border: `1px solid ${color}`,
                            borderRadius: 6,
                            backgroundColor: `${color}`,
                            margin: "5px"
                        }}
                    >
                        {status}
                    </Badge>
                );
            },
            filterType: 'dropdown',
            options: dataForTable?.data?.status?.map((val: { value: string }) => ({ value: val.value, label: val.value }))
        },
        {
            header: 'Promise',
            accessorKey: "promise",
            cell: ({ row }) => {
                const promise = row?.original?.promise;
                const camelCasePromise = promise ? promise
                    .replace(/([a-z])([A-Z])/g, '$1 $2')
                    .replace(/\b\w/g, (char: string) => char.toUpperCase()) : "";

                const cellStyle = {
                    backgroundColor: promise === 'hotPromise' ? 'red' : 'transparent',
                    padding: '10px',
                    color: promise === 'hotPromise' ? 'white' : 'inherit',

                };
                return (
                    <div style={cellStyle}>{camelCasePromise}</div>
                )

            }
        },
        {
            header: 'Stage Due Date',
            accessorKey: "stageDueDate",
            cell: ({ row }) => {
                const dueDateValue = row.getValue("stageDueDate") as string;
                return (
                    <Text variant='Poppins14Normal24lh'>{dueDateValue ? moment(dueDateValue.split('T')[0]).format("MMM DD, YYYY") : ""}</Text>
                    // <Text variant='Poppins14Normal24lh'>{toConvertLocalDateFormat(dueDateValue)}</Text>
                );
            },
            filterType: 'date',
        },
        {
            header: 'Comments',
            cell: ({ row }) => {
                return (
                    <div>
                        <CommentsDialogBox id={row?.original?._id} comments={row?.original} level="task" />
                    </div>
                );

            },
        }
        // {
        //     header: 'action',
        //     accessorKey: "action",
        //     id: 'action',
        //     cell: info => info.getValue(),
        // },
    ], [taskView?.length, dataForTable, selectedRows]);

    useEffect(() => {
        let apiEndPoint = getApiEndPoint(
            { limit, currentPage },
            location.search,
            columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || []
        );

        dispatch(GetAllTaskView(apiEndPoint));
        dispatch(getTimeSummery(apiEndPoint));


    }, [location?.search, limit, currentPage]);

    useEffect(() => {
        let queryValueInObj = queryString.parse(location.search);
        // console.log(queryValueInObj["id"], "check254");
        if (queryValueInObj["id"]) {
            let idValue: string = Array.isArray(queryValueInObj["id"])
                ? queryValueInObj["id"][0] || ""
                : queryValueInObj["id"] || "";
            // setIsOpen(true);
            setTaskId(idValue);
        }
    }, [location.search]);


    const { data: eventSource } = useQuery({
        queryKey: ['taskSSE'],
        queryFn: () => {
            const eventSource = new EventSource(`${baseUrl}sse/task`);

            eventSource.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    dispatch(UpdateTaskRow(data))
                } catch (error) {
                    console.error('Error parsing SSE data:', error);
                }
            };

            eventSource.onerror = (error) => {
                console.error('SSE Error:', error);
                eventSource.close();
            };
            return eventSource;
        },
        refetchInterval: 60000
    })
    useEffect(() => {
        return () => {
            eventSource?.close();
        };
    }, [])
    useEffect(() => {
        let apiEndPoint = getApiEndPoint(
            { limit, currentPage },
            location.search,
            columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || []
        );
        const intervalId = setInterval(() => {
            dispatch(getTimeSummery(apiEndPoint));
        }, 30000);

        return () => clearInterval(intervalId);
    }, [location.search]);
    return (
        <>
            <div className='main-content'>
                <TabelHeadingTxt Title='Tasks view' src={Delete} onClick={handleDeleteSelectedTasks} Count={taskView?.count} IsExportButtonShow={true} BtnTxt='Delete Task' addText='Add Task' summeryTime={true} url={'/task-view'} />
                <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
                <DataTable
                    loader={loader}
                    columns={columns}
                    data={taskView?.data || []}
                    TotalLength={taskView?.count || 15}
                    perPageCount={limit || 15}
                    currentPage={currentPage || 1}
                    onPerPageChange={(itemsPerPage: string) => {
                        const parsedQuery = queryString.parse(location.search);

                        if (searchParams?.size > 2) {

                            setSearchParams({
                                ...parsedQuery,
                                limit: itemsPerPage.toString(),
                            });
                        } else if (taskView?.count < itemsPerPage) {
                            setSearchParams({
                                currentPage: '1',
                                limit: itemsPerPage.toString(),
                            });
                        }
                        else {
                            setSearchParams({
                                ...parsedQuery,
                                currentPage: currentPage.toString(),
                                limit: itemsPerPage.toString(),
                            });
                        }
                    }}
                    onPageChange={(value) => {
                        const parsedQuery = queryString.parse(location.search);

                        if (searchParams?.size > 2) {

                            setSearchParams({
                                ...parsedQuery,
                                currentPage: value.toString(),
                            });
                        } else {

                            setSearchParams({
                                ...parsedQuery,
                                currentPage: value.toString(),
                                limit: limit.toString(),
                            });
                        }
                    }} />

            </div>
            <Modal isOpen={showConfirmation} toggle={cancelDeleteTask} size="sm" centered>
                <ModalHeader toggle={cancelDeleteTask}>Confirm Deletion</ModalHeader>
                <ModalBody>
                    {`Are you sure you want to delete this selected tasks?`}
                </ModalBody>
                <ModalFooter>
                    <Button variant="CancelButton" sx={{ mr: 2, cursor: 'pointer' }} onClick={cancelDeleteTask}>No</Button>
                    <Button type="button" variant="SaveButton" sx={{ cursor: 'pointer' }} onClick={handleConfirmDeleteSelectedTasks}>Yes</Button>
                </ModalFooter>
            </Modal>
            {!timeTracking || Object?.keys(timeTracking).length === 0 ? (

                <Button
                    sx={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                        bg: 'primary',
                        color: 'white',
                        borderRadius: '50%',
                        width: 60,
                        height: 60,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 24,
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                        zIndex: 1000,
                        '&:hover': {
                            bg: 'secondary',
                        }
                    }}
                    onClick={toggleModal}
                >
                    <img
                        src={clockIcon}
                        alt="clock-icon"
                        style={{ width: '40px', height: '40px' }}
                    />
                </Button>
            ) : null}
            <GeneralTimerStart isOpen={modalOpen} toggle={toggleModal} autherId={auther?._id} />
        </>
    )
};

export default TaskView;

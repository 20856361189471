import { FRONT_APP, FRONT_APP_SUCCESS, UPDATE_COMMENT, UPDATE_COMMENT_SUCCESS, UPDATE_DT_PM, UPDATE_DT_PM_SUCCESS, UPDATE_FOLDER_NUMBER, UPDATE_FOLDER_NUMBER_SUCCESS, UPDATE_FRONTAPP_ROW } from "../storeTypes";

export const getAllFrontapp = (payload,) => {
    return {
        type: FRONT_APP,
        payload: payload,
        // Callback
    }
};
export const getAllFrontappSucess = (payload) => {
    // console.log("frontapppayload", payload);
    return {
        type: FRONT_APP_SUCCESS,
        payload: payload,
    }
};
export const UpdateFrontAppRow = (payload) => {
    return {
        type: UPDATE_FRONTAPP_ROW,
        payload: payload,
    }
}
export const updateDTPM = (payload, id) => {
    return {
        type: UPDATE_DT_PM,
        payload,
        id
    }
}
export const updateDTPMSuccess = (payload, id) => {
    return {
        type: UPDATE_DT_PM_SUCCESS,
        payload: payload,
        id
    }
}

export const updateComment = (payload, id) => {
    return {
        type: UPDATE_COMMENT,
        payload,
        id
    }
}
export const updateCommentSuccess = (payload) => {
    // console.log("Updatepayload", payload);
    return {
        type: UPDATE_COMMENT_SUCCESS,
        payload: payload
    }
}
export const updateFolder = (payload, id) => {
    return {
        type: UPDATE_FOLDER_NUMBER,
        payload,
        id
    }
}
export const updateFolderSuccess = (payload) => {
    // console.log("Updatepayload", payload);
    return {
        type: UPDATE_FOLDER_NUMBER_SUCCESS,
        payload: payload
    }
}

import InfiniteScroll from "react-infinite-scroll-component";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AllActivities } from "../../../utils/GenerateQueryString";
import moment from "moment";
import { Paragraph } from "theme-ui";
import { useMemo, useState } from "react";

interface ActivityModalProps {
    isOpen: boolean;
    toggle: () => void;
    items?: any[];
    hasMore?: any;
    fetchMoreData?: any;
    dataForTable?: any;
    title?: string;
}
const ActivityHistoryModal: React.FC<ActivityModalProps> = ({ isOpen, toggle, items, hasMore, fetchMoreData, dataForTable, title = "All Activities" }) => {

    const [totalItems, setTotalItems] = useState(0);
    // const [hasMore, setHasMore] = useState(true);
    const uniqueItems = useMemo(() => {
        const seen = new Set();
        return items?.filter((history: any) => {
            const identifier = history?.changes?.updatedAt || history?.changes?.updateObject?.updatedAt; // You can use other identifiers like history.id
            if (seen.has(identifier)) {
                return false;
            } else {
                seen.add(identifier);
                return true;
            }
        });
    }, [items]);
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" centered>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <InfiniteScroll
                    dataLength={uniqueItems?.length || 0}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<p></p>}
                    height={600}
                    endMessage={<p></p>}
                >
                    {uniqueItems?.map((history: any, index: number) => {
                        const activityText = AllActivities(
                            history?.action,
                            history?.changes,
                            history?.oldData,
                            dataForTable?.data?.allProjectManagers,
                            dataForTable?.data?.allTaskManagers,
                            dataForTable?.data?.allProjectWorkers,
                            dataForTable?.data?.allTaskWorkers,
                            dataForTable?.data?.allHighlighters,
                            dataForTable?.data?.allArchitects,
                            true
                        );
                        if (!activityText) return null;
                        return (
                            <div key={index} style={{ flexWrap: 'wrap' }}>
                                <Paragraph style={{ gap: 2 }} variant="activityText"><strong>{history?.changes?.updatedBy || history?.changes?.updateObject?.updatedBy || 'Someone'}</strong>
                                    {activityText}
                                </Paragraph>
                                <Paragraph sx={{ textAlign: "end", fontSize: "10px", color: "#D3D5D9", marginBottom: "0px" }}>{moment(history?.createdAt).format('MMM DD, LT')}</Paragraph >
                            </div>

                        );
                    })}
                </InfiniteScroll>
            </ModalBody>
            {/* <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter> */}
        </Modal>)
}

export default ActivityHistoryModal
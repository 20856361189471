import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Text, ThemeUIStyleObject } from 'theme-ui';
import { HeaderBtn } from '../Button/TabelHeadBtn';
import Export from "../assets/images/Icon/Export.svg";
import { Paddingset } from '../Button/PaddingSet';
import { HighlighterPopup } from '../pages/Highlighter/Highlighterpopup';
import { DropDownURL } from '../Button/DropDownURL';
import ClearTableFilter from '../Button/ClearTableFilter';
interface TabelHeadingprops {
    Title?: string | undefined;
    BtnTxt?: string | undefined;
    Count?: string | number;
    IsBtnShow?: boolean;
    sx?: ThemeUIStyleObject;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    src?: string;
    url?: string;
    ml?: string;
}

export const ProjectViewHeader: React.FC<TabelHeadingprops> = ({
    Title = '',
    Count = '',
    BtnTxt,
    IsBtnShow = false,
    sx,
    onClick,
    src,
    url,
    ml
}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isProjectView = pathname === "/project-view"

    // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    // const popoverRef = useRef<HTMLDivElement>(null);
    // const togglePopover = () => {
    //     setIsPopoverOpen(!isPopoverOpen);
    // };

    // const handleClickOutside = (event: MouseEvent) => {
    //     if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
    //         setIsPopoverOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const handleClickOutside = (event: any) => {
        if (!event.target.closest('.popover-content')) {

            if (!event.target.closest('.toggle-btn')) {
                setIsPopoverOpen(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    let urlArray = [{ name: "Default", url: "" }, { name: "On Hold", url: "?currentPage=1&status=On Hold" }, { name: "Delivered", url: "?currentPage=1&status=Delivered" }, { name: "Ready To Deliver", url: "?currentPage=1&status=Ready To Deliver" }, { name: "Draft", url: "?currentPage=1&status=Draft" }]
    const customDropdownProps = { isPopoverOpen, togglePopover, setIsPopoverOpen, urlArray }


    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 col-md-6 col-xl-6 mt-5 mt-xl-0'>
                    <Text as={"li"} variant='Poppins36Normal35lh'>
                        {Title}
                        <Badge as={"span"} className="badge" variant='DropDownbedge'>{Count}</Badge>
                        {/* <FaChevronDown onClick={togglePopover} style={{ marginLeft: '60px', width: '19px' }} />
                            {isPopoverOpen && (
                                <div className="popover-content" style={{ position: 'fixed', zIndex: 99999999, }}>
                                    <ul className="headermenu">
                                        <li>
                                            <Link to="/project-view" className="DashboardSide">
                                                <img className='mt-3' src={dot} />
                                                Project View
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/stage-view" className="DashboardSide">
                                                <img className='mt-3' src={dot} />
                                                Stages View
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/task-view" className="DashboardSide">
                                                <img className='mt-3' src={dot} />
                                                Tasks View
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )} */}

                        {/* <FaChevronDown onClick={togglePopover} style={{ marginLeft: '60px', width: '19px', cursor: "pointer" }} className='toggle-btn' /> */}
                        {/* {isPopoverOpen && (
                            <div className="popover-content" style={{ position: 'fixed', zIndex: 99999999, }}>
                                <ul className="headermenu" onClick={togglePopover}>
                                    <li>
                                        <Link to="/project-view" className="DashboardSide" >
                                            <img className='mt-3' src={dot} />
                                            Default
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/project-view?currentPage=1&status=OnHold" className="DashboardSide" >
                                            <img className='mt-3' src={dot} />
                                            OnHold
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/project-view?currentPage=1&status=Delivered" className="DashboardSide" >
                                            <img className='mt-3' src={dot} />
                                            Delivered
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )} */}

                        {isProjectView && <DropDownURL customDropdownProps={customDropdownProps} />}
                        {url && <ClearTableFilter url={url} ml={ml} />}
                    </Text>
                </div>
                <div className="col-12 col-xl-6 col-md-6 mt-3 mt-xl-0 mt-md-5">
                    <div className='d-flex  gap-2 justify-content-end'>
                        <HighlighterPopup />
                        {IsBtnShow &&
                            <HeaderBtn src={Export} sx={{ padding: '0px 8px' }} onClick={() => {
                                navigate('/create-project')
                            }} title={'Add Project'} />
                        }
                        <Paddingset />
                        {/* <HeaderBtn title={BtnTxt} onClick={onClick} src={src} sx={{ padding: '1px 6px' }} /> */}
                        {/* <PrimayChatBtn sx={{ backgroundColor: '#7743DB' }} /> */}
                    </div>
                </div>
            </div >
        </div >
    );
};

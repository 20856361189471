import { ErrorMessage, Field, FormikProps } from 'formik';
import { withFormikValidation } from './enhancer/AddEditArchitectEnhancer';
import { Button, Label } from 'theme-ui';
import theme from '../../../../theme';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getArchitectDataById } from '../../../../store/actions/masterData.action';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types';
import { ActionCreateByUrl, ActionUpdateById } from '../../../../store/actions/CommonAction';


const AddEditArchitect: React.FC<FormikProps<any>> = ({
    values,
    errors,
    touched,
    handleSubmit,
    setValues,
    validateForm,
    isValid,
    isSubmitting
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { architectId } = useParams();
    const { individualArchitect } = useSelector((state: RootState) => state?.masterData);
    const { architect } = useSelector((state: RootState) => state?.masterData);
    const [isNeedToValidate, setIsNeedToValidate] = useState<boolean>(false);
    // console.log(individualArchitect, "checkboth", architect);

    useEffect((): void => {
        if (architectId) {
            dispatch(getArchitectDataById({ id: architectId }))
        }
    }, [architectId])
    useEffect(() => {
        if (architectId && individualArchitect?.status) {
            setValues({
                ...values,
                name: individualArchitect?.data?.name,
                email: individualArchitect?.data?.email,
                description: individualArchitect?.data?.description
            })
            setIsNeedToValidate(true);
        }
    }, [individualArchitect?.data])
    useEffect(() => {
        if (isNeedToValidate) {
            validateForm();
        }
    }, [isNeedToValidate])
    const handleArchitectData = async (): Promise<void> => {
        handleSubmit();
        if (isValid) {
            architectId ?
                dispatch(ActionUpdateById({ Query: `architect/edit-architect/${architectId}`, obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/architect")
                    }
                }))
                :
                dispatch(ActionCreateByUrl({ Query: "architect/create-architect", obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/architect")
                    }
                }));
        }
    }
    return (
        <div className="main-content  p-5">
            <h1> {architectId ? "Edit" : "Add"} Architect</h1>
            <div className="row">
                <div className="col-md-4 mt-4">
                    <Label htmlFor="name" className={`form-label mt-1 ${errors.name && touched.name ? 'is-invalid' : ''}`}>First Name*</Label>
                    <Field type="text" id="name" name="name" className="form-control" placeholder="Architect Name" />
                    <ErrorMessage name="name" component="div" className="text-danger" />
                </div>
                <div className="col-md-4 mt-4">
                    <label htmlFor="email" className={`form-label mt-1 ${errors.email && touched.email ? 'is-invalid' : ''}`}>Email ID*</label>
                    <Field type="text" id="email" name="email" className="form-control" placeholder="Architect Email ID" />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                </div>
                <div className="col-md-4 mt-3">
                    <label htmlFor="description" className={`form-label mt-1 ${errors.description && touched.description ? 'is-invalid' : ''}`}>Description*</label>
                    <Field type="text" id="description" name="description" className="form-control" placeholder="Architect Description" />
                    <ErrorMessage name="description" component="div" className="text-danger" />
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-md-4'></div>
                <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' onClick={handleArchitectData} disabled={isSubmitting}>Submit</Button></div>
            </div>
        </div >
    );
}

export default withFormikValidation(AddEditArchitect);
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Box, Button, Label } from "theme-ui";
import { StopTracking } from "../../../../store/actions/project.action";
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import { Inputform } from "../../../forms-component/InputForm";

export default function TimeClosePop({ isPopupOpen, closePopup, setTimeTaken }: any) {
    const dispatch = useDispatch()
    const { taskId } = useParams<{ taskId: string }>();
    const validationSchema = Yup.object().shape({
        description: Yup.string().required('Description is required'),
    });
    const formik = useFormik(
        {
            validationSchema,
            enableReinitialize: true,
            initialValues: {
                description: "",
                category: 'Task timer'
            },
            onSubmit: (value) => {

                // Dispatch the action with the updated payload
                dispatch(StopTracking(value, taskId, (res: any) => {
                    if (res.status === 200) {
                        const timeTakenValue = res?.data?.data?.timeTaken || 0;
                        setTimeTaken(timeTakenValue);
                    }
                }));
                // Close the popup after sending the description
                closePopup();
                formik.resetForm()
            }
        }
    );
    const { handleSubmit, setFieldValue, handleBlur, touched, values, errors } = formik;
    return (
        <Modal isOpen={isPopupOpen}>
            <form onSubmit={handleSubmit}>
                <ModalHeader className='border-bottom-0 pb-0' ><Label className='fw-bold '>Description</Label></ModalHeader>
                <ModalBody >
                    <Inputform
                        label={""}
                        Placeholder={"Enter Description..."}
                        Name={"description"}
                        Title_sx={{ color: "grey3" }}
                        touched={touched?.description}
                        errors={errors?.description}
                        values={values.description}
                        Max_Length={10}
                        onBlur={handleBlur}
                        OnChange={(values: any) => {
                            setFieldValue("description", values?.target?.value, true)
                        }}
                    />
                    <Box className="text-end">
                        <Button type="submit" mt={5}>Save</Button>
                    </Box>
                </ModalBody>
            </form>
        </Modal>

    )
}

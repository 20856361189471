import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Image } from "theme-ui";
import plusIcon from "../assets/images/Icon/plus.svg";
import crossIcon from "../assets/images/Icon/cross.svg";
import { projectAction } from "../../store/actions/project.action";
import { Overlay, Popover } from "react-bootstrap";
import { ActionOnHold } from "../Actions/ActionModule/ActionOnHold";
import { ActionProjectSetup } from "../Actions/ActionModule/ActionProjectSetup";
import theme from "../../theme";
import ActionConfirmation from "../Actions/ActionModule/ActionConfirmation";

export default function ProjectAction({ selectedTab, projectId }: any) {
    const [textareaValue, setTextareaValue] = useState("");
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [selectedHighlight, setSelectedHighlight] = useState<any>(null);
    const { highlighterByView } = useSelector((state: any) => state.project);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [target, setTarget] = useState(null);
    const [Open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextareaValue(event.target.value);
    };


    const handleSend = () => {
        const payload = {
            actionType: selectedHighlight?.name,
            payload: {
                description: textareaValue
            }
        }
        dispatch(projectAction(payload, projectId))
        setOpen(false);
        setTextareaValue("")
        setSelectedHighlight(null)
    };

    // Function to handle click on highlight name
    const handleHighlightClick = (highlight: any) => {
        setSelectedHighlight(highlight);
        setOpen(true);
        setIsPopoverOpen(!isPopoverOpen)
    };
    const togglePopover = (event: any) => {
        setIsPopoverOpen(!isPopoverOpen);
        setTarget(event.target);

    };
    const handleClickOutside = (event: any) => {

        if (!event.target.closest('.popover-body')) {

            if (!event.target.closest('.toggle-btn')) {
                setIsPopoverOpen(false);
            }
        }

    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div ref={popoverRef}>
            <Overlay
                show={isPopoverOpen}
                target={target}
                placement="left"
            >
                <Popover style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }} id={`popover-positioned`} >
                    <Popover.Body className="w-auto">
                        {highlighterByView?.map((highlight: any, index: number) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        color: highlight?.color, padding: "12px", borderRadius: "8px", cursor: 'pointer', '&:hover': {
                                            backgroundColor: `${theme?.colors?.lightbackground}`
                                        }
                                    }}
                                    onClick={(e: any) => {
                                        togglePopover(e)
                                        handleHighlightClick(highlight);
                                    }}
                                >
                                    {highlight?.icon && <img width={22} src={highlight?.icon} alt='' />} &nbsp;{highlight?.name}
                                </Box>
                            );
                        })}
                    </Popover.Body>
                </Popover>
            </Overlay >
            <button className='btn toggle-btn' style={{ backgroundColor: selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit', padding: "0px" }} onClick={togglePopover}>
                {isPopoverOpen ? <Image src={crossIcon} alt='' style={{ fill: selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                    : <Image src={plusIcon} alt='' style={{ fill: selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />}
                {/* <br />
                    {selectedHighlight && (<img src={selectedHighlight?.icon} />)} */}
            </button>
            {selectedHighlight?.name === "On Hold" && <ActionOnHold selectedHighlight={selectedHighlight} Open={Open} setOpen={setOpen} actionObject={{ projectId: projectId }} />}
            {selectedHighlight?.name === "Project Setup" && <ActionProjectSetup selectedHighlight={selectedHighlight} Open={Open} setOpen={setOpen} actionObject={{ projectId: projectId }} />}
            {selectedHighlight?.name === "Waiting for CAD" && <ActionConfirmation selectedHighlight={selectedHighlight} Open={Open} setOpen={setOpen} actionObject={{ projectId: projectId }} />}

        </div >
    );
}

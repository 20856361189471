import { Box, Button, Flex, Image, Input, Label, Spinner, Text } from "theme-ui";
import { emailVerify } from "../../../store/actions/login.action";
import { ForgotFormValues, RootState } from "../../../../@types";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo 2 (1).svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import theme from "../../../theme";
import * as Yup from "yup";


const validationSchema = Yup.object({
    Email: Yup.string().email('Invalid Email format').required('Email is required'),
});

const EmailVerify = () => {
    const navigate = useNavigate()
    const loader = useSelector((state: RootState) => state.auth.loader);
    const dispatch = useDispatch();
    const handleForgot = (value: ForgotFormValues) => {
        // console.log("Forgot password form values:", payload);
        dispatch(emailVerify(value,
            (response: any) => {
                if (response) {
                    navigate("/login")
                }
            }));
    };

    const Formik = useFormik({
        initialValues: {
            Email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values: ForgotFormValues) => {
            handleForgot(values);
        },
    });

    return (
        <>
            <Flex
                sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
                <Image src={logo} alt="Logo" sx={{ width: ["70%", "30%", "20%"], height: 'auto' }} />

                <Box sx={{ width: ['90%', '70%', '40%'], mt: 6, gap: "23px" }}>
                    <form onSubmit={Formik.handleSubmit}>
                        <Box mb={3}>
                            <Label htmlFor="Email" style={{ fontSize: "17px", fontWeight: '600' }}>Email</Label>
                            <Input type="Email" id="Email" name="Email" className='form-control' onChange={Formik.handleChange} onBlur={Formik.handleBlur} value={Formik.values.Email} placeholder="Enter your Email"
                                sx={{
                                    width: '100%', p: 2, mt: 1, border: '1px solid', borderColor: Formik.touched.Email && Formik.errors.Email ? `${theme?.colors?.red}` : 'red',
                                }} />
                            {Formik.touched.Email && Formik.errors.Email && (
                                <Text sx={{ color: `${theme?.colors?.red}`, mt: 1 }}> {Formik.errors.Email} </Text>
                            )}
                        </Box>
                        <Button type="submit" sx={{ width: '100%', bg: `${theme.colors?.primary}`, color: `${theme?.colors?.white}`, py: 2, borderRadius: '4px', cursor: 'pointer' }}>
                            {loader ? (<Spinner sx={{ color: theme.colors?.background, width: 3, p: 0, m: 0 }} />) : ("Forgot Password")}
                        </Button>
                    </form>
                    <Box mt={3}>
                        <Link to="/login" style={{ textDecoration: 'none', color: `${theme.colors?.primary}` }}>
                            Back to Login
                        </Link>
                    </Box>
                </Box>
            </Flex>
        </>

    )
}

export default EmailVerify
import { FocusEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Box, Button, Card, Flex, Text } from 'theme-ui';
import { Inputform } from '../../forms-component/InputForm';
import { MultiSelect } from 'react-multi-select-component';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllTaskViewByStageId } from '../../../store/actions/project.action';
import { useSelector } from 'react-redux';
import { createActionHighlight, createActionHighlightSuccess, getActionHighlighterIds, getAllTemplateChecklist } from '../../../store/actions/action.Highlighters';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormSelect from '../../forms-component/globalSelect';
import CreatableSelect from 'react-select/creatable';
import toast from 'react-hot-toast';
import theme from '../../../theme';

interface Option {
    label: string;
    value: string;
}
interface createTemplate {
    name: string;
    description: string;
    emailLink: string[];
    delta?: string;
    majorMinor: string;
    templateId: string[];
    taskId: string[];
    isDone: boolean,
}

const components = {
    DropdownIndicator: null,
};

const createOption = (label: string) => ({
    label,
    value: label,
});
const CreateBC = ({ selectedHighlight, Open, setOpen, actionObject }: any) => {
    const [showActionModuleModal, setShowActionModuleModal] = useState(false);
    const { stageId } = useParams<{ stageId: string }>();
    const dispatch = useDispatch();
    const { stageAllTaskView } = useSelector((state: any) => state.project);
    const [inputValue, setInputValue] = useState('');
    const [emailList, setEmailList] = useState<readonly Option[]>([]);

    useEffect(() => {
        dispatch(getAllTemplateChecklist({ query: "subtasks" }))
    }, [dispatch])
    useEffect(() => {
        if (stageId) {
            dispatch(getAllTaskViewByStageId(stageId))
        }
    }, [stageId, dispatch])
    useEffect(() => {
        dispatch(getActionHighlighterIds(actionObject, { name: selectedHighlight?.name }))
    }, [])

    const togglePopover = () => {
        setOpen(!Open);
    };
    const majorMinor = [
        { label: "Major", value: "MAJOR" },
        { label: "Minor", value: "MINOR" },
    ]
    const formik = useFormik<createTemplate>(
        {
            enableReinitialize: true,
            initialValues: {
                name: "",
                emailLink: [],
                description: "",
                delta: "",
                majorMinor: "",
                taskId: [],
                templateId: [],
                isDone: false,
            },
            validationSchema: Yup.object({
                name: Yup.string()
                    .required('Name is required')
                    .test('no-whitespace', 'Name cannot be just whitespace', (value) => value.trim() !== ''),
                emailLink: Yup.array().of(Yup.string().matches(
                    /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                    'Invalid URL'
                )).required('Url is required').min(1, 'URL is required')
                ,
                description: Yup.string().required('Description is required')
                    .test('no-whitespace', 'Description cannot be just whitespace', (value) => value.trim() !== ''),
                taskId: Yup.array().of(Yup.string()).required('Tasks is required').min(1, 'At least one task must be selected'),
                // templateId: Yup.array().of(Yup.string()).required('Template is required'),
                majorMinor: Yup.string().required('Major/Minor is required'),
            }),
            onSubmit: (value) => {
                const payloadvalue = {
                    actionType: selectedHighlight?.name,
                    payload: {
                        name: value?.name,
                        description: value?.description,
                        delta: value?.delta,
                        taskId: value?.taskId,
                        majorMinor: value?.majorMinor,
                        emailLink: value?.emailLink,
                        templateId: value?.templateId || [],
                        Description: [],
                        isDone: false,
                    },
                    projectId: actionObject?.projectId,
                    stageId: actionObject?.stageId,
                    taskId: actionObject?.taskId || null,
                }
                dispatch(createActionHighlight(payloadvalue, (res: any) => {
                    if (res.status === 200) toast.success(`${selectedHighlight?.name} added successfully.`);
                }));
                // setOpen(false);
                formik.resetForm();
                setEmailList([]);

            }

        }
    );
    const { handleSubmit, setFieldValue, values, handleBlur, touched, errors } = formik;
    const taskIdSet = new Set<string>();
    const uniqueTasks = stageAllTaskView?.filter((obj: any) => {
        const taskId = obj.taskId?._id;
        const isUnique = taskId && !taskIdSet?.has(taskId);
        if (isUnique) {
            taskIdSet?.add(taskId);
        }
        return isUnique;
    });
    const taskOptions: Option[] = uniqueTasks?.map((obj: any) => ({
        label: obj.taskId.name,
        value: obj?._id,
    }));

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setEmailList((prev) => [...prev, createOption(inputValue)]);
                const emailLink = emailList?.map(item => item.label)
                setFieldValue("emailLink", [...emailLink, inputValue])
                setInputValue('');
        }
    };
    const handleOnBlur: FocusEventHandler = (event: any) => {
        if (inputValue.trim() !== '') {
            setEmailList((prev) => [...prev, createOption(inputValue)]);
            const emailLink = emailList?.map(item => item.label)
            setFieldValue("emailLink", [...emailLink, inputValue]);
            setInputValue('');
        }
    };

    const handleFocus: FocusEventHandler = (event: any) => {
        if (inputValue.trim() !== '') {
            setEmailList((prev) => [...prev, createOption(inputValue)]);
            const emailLink = emailList?.map(item => item.label)
            setFieldValue("emailLink", [...emailLink, inputValue]);
            setInputValue('');
        }
    };


    useEffect(() => {
        return () => {
            dispatch(createActionHighlightSuccess([]))
        }
    }, [])
    return (
        <Modal isOpen={Open} scrollable size="xl" centered >
            <ModalHeader toggle={togglePopover}> <Flex sx={{ gap: "0.5rem" }}><img width={22} src={selectedHighlight?.icon} alt="" /><Text sx={{ color: selectedHighlight?.color }}>{selectedHighlight?.name?.toUpperCase()}</Text></Flex></ModalHeader>

            <form onSubmit={handleSubmit}>
                <ModalBody scrollable className="px-1 overflow-x-hidden">
                    <Card sx={{ boxShadow: "none", border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "12px 20px", position: "relative" }}>
                        <Flex>
                            <Box className='col-12 ps-2'>
                                <Inputform
                                    label={"Name"}
                                    Placeholder={"Enter Name"}
                                    Name={"name"}
                                    type='text'
                                    Title_sx={{ color: "grey3" }}
                                    touched={touched?.name}
                                    asterisk={true}
                                    errors={errors?.name}
                                    values={values?.name}
                                    Max_Length={10}
                                    onBlur={handleBlur}
                                    OnChange={(event: any) => {
                                        const newName = event.target.value;
                                        setFieldValue("name", newName, true);
                                    }}
                                />
                            </Box>
                        </Flex>

                        <Flex>
                            <Box className='col-12 ps-2'>
                                <Text variant="SelectLabel">Enter Description<span className="mx-1 text-danger">*</span></Text>
                                <textarea
                                    className="form-control"
                                    name={'description'}

                                    value={values?.description}
                                    placeholder="Enter Description"
                                    rows={5}
                                    onChange={(e) => setFieldValue('description', e.target.value)}
                                    onBlur={handleBlur}
                                />
                                {errors?.description && touched?.description ? (
                                    <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                                        {errors?.description}
                                    </Text>
                                ) : (
                                    <Text variant="Inter15Regular30lh" sx={{ my: '5px', minHeight: '11px' }}></Text>
                                )}
                            </Box>
                        </Flex>

                        <Box className='col-12'>
                            <Text variant="SelectLabel">Select Task(s)<span className="mx-1 text-danger">*</span></Text>
                            <MultiSelect
                                options={taskOptions}
                                value={values?.taskId?.map((value: string) => taskOptions?.find((option) => option?.value === value) as Option)}
                                onChange={(selected: Option[]) => setFieldValue('taskId', selected?.map((option) => option?.value))}
                                labelledBy="Select"
                            />
                            {errors?.taskId && touched?.taskId && (
                                <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                                    {errors?.taskId}
                                </Text>
                            )}
                        </Box>
                        <Box className='col-12 ps-2'>
                            <Text variant="SelectLabel">URL Link<span className="mx-1 text-danger">*</span></Text>
                            <CreatableSelect
                                components={{ DropdownIndicator: null }}
                                inputValue={inputValue}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={(newValue) => {
                                    setEmailList(newValue)
                                    setFieldValue("emailLink", newValue?.map(item => item?.label))
                                }}
                                onInputChange={(newValue) => {
                                    setInputValue(newValue)
                                }}
                                onBlur={handleOnBlur}
                                onKeyDown={handleKeyDown}
                                onFocus={handleFocus}
                                placeholder=""
                                value={emailList}
                                styles={{
                                    valueContainer: (base) => ({
                                        ...base,
                                        maxHeight: '90px',
                                        overflowY: 'auto',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        paddingRight: '30px',
                                    }),
                                    control: (base) => ({
                                        ...base,
                                        border: '1px solid #f7efe5',
                                        borderRadius: '12px',
                                        minHeight: '48px',
                                        flexWrap: 'wrap',
                                        display: 'flex',
                                        position: 'relative',
                                    }),
                                    clearIndicator: (base) => ({
                                        ...base,
                                        position: 'absolute',
                                        right: '10px',
                                        top: '45%',
                                        transform: 'translateY(-95%)',
                                        zIndex: 1,
                                        padding: '0 5px',
                                        cursor: 'pointer',
                                    }),
                                    multiValue: (base) => ({
                                        ...base,
                                        // marginRight: '5px',
                                    }),
                                }}
                            />
                            {errors?.emailLink && touched?.emailLink && (
                                <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                                    {errors?.emailLink}
                                </Text>
                            )}
                        </Box>
                        <Flex className="row" >

                            <Box className='col-6'>
                                <Inputform
                                    label={"Delta"}
                                    Placeholder={""}
                                    Name={"delta"}
                                    type='text'
                                    Title_sx={{ color: "grey3" }}
                                    values={values?.delta}
                                    Max_Length={8}
                                    onBlur={handleBlur}
                                    OnChange={(event: any) => {
                                        const newName = event.target.value;
                                        setFieldValue("delta", newName, true);
                                    }}
                                />
                            </Box>

                            <Box className='col-6'>
                                <FormSelect
                                    label={"Major/Minor"}
                                    value={values?.majorMinor}
                                    placeholder={"Select Major/Minor"}
                                    options={majorMinor || []}
                                    isMulti={false}
                                    // disabled={true}
                                    DefultSelect='Select Major/Minor'
                                    touched={touched?.majorMinor}
                                    errors={errors?.majorMinor}
                                    asterisk={true}
                                    onBlur={handleBlur}
                                    Name='majorMinor'
                                    onChange={(majorMinor: any) => {
                                        setFieldValue("majorMinor", majorMinor?.value, true);
                                    }}
                                />
                            </Box>
                        </Flex>
                    </Card>
                </ModalBody>
                <ModalFooter>
                    {/* <Button variant="CancelButton" type='button' className='me-2 px-5' onClick={togglePopover} >Cancel</Button> */}
                    <Button variant="SaveButton" type='submit' >+ Create</Button>
                </ModalFooter>
            </form>

        </Modal>
    )
}

export default CreateBC
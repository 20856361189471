/* eslint-disable no-unused-expressions */
import { toast } from 'react-hot-toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { capitalize } from '../../utils/GenerateQueryString';
import { GetAllStageViewSuccess, GetAllTaskViewSuccess, GetLatestTimeByIdSuccess, GetProjectListSuccess, GetTimeByIdSuccess, GetTimeByProjectIdSuccess, GetTimeByStageIdSuccess, StartTrackingSuccess, StopTrackingSuccess, addTaskByIdSuccess, ProjectFail, createStepOneSuccess, createStepTwoSuccess, editProjectByIdSuccess, editStageByIdSuccess, getAllBlueprintSuccess, getAllHighlightersSuccess, getAllProjectMasterDataSuccess, getAllProjectNoPaginationSuccess, getProjectByIdSuccess, getSowChecklistSuccess, getSowFieldsSuccess, getStageByProjectIdSuccess, getStagesByIdSuccess, getTaskByIdSuccess, getTaskByProjectIdSuccess, needInfoSuccess, projectActionSuccess, stageActionSuccess, getAllTaskViewByStageIdSuccess, getHighlighterByViewSuccess, getHistoryByViewSuccess, getTaskById, getTimeSummerySuccess, getTimeTrackingSuccess, GetLatestTimeById, getTimeTracking, getStageByCustomOrderSuccess, getAllSubtaskSuccess, getAllSubtaskFailure, getAllSubtaskSummarySuccess, getAllSubtaskSummaryFailure, updateActionStatusToAnySuccess, getAllSubtasksOfSameTaskSuccess, getAllSubtasksOfSameTaskFailure, getGeneralTimeTrackingSuccess, getAllDuplicateSubtasksSuccess, getAllDuplicateSubtasksFailure, getRevertHistoryByViewSuccess, getAllRevertHistorySuccess } from '../actions/project.action';
import { GetAllHighlighters, GetLastTimeById, GetProjectbyId, GetSowFieldsApi, GetTimeById, GetTimeByProjectIdApi, GetTimeByStageIdApi, NeedInfoApi, ProjectActionApi, StageActionApi, StartTrackingApi, StepOneApi, StepTwoApi, StopTrackingApi, TaskByProjectIdApi, addTaskByIdApi, changeEstimationTimeApi, editProjectByIdApi, editStagesByIdApi, editTaskByIdApi, getAllBlueprintsApi, getAllDuplicateSubtasks, getAllProjectsApi, getAllRevertHistoryApi, getAllStageViewApi, getAllSubTasks, getAllSubTasksSummaryApi, getAllSubTasksofSameTask, getAllTaskViewApi, getAllTaskViewByStageIdApi, getAllprojectListApi, getAllprojectMasterDataApi, getGeneralTimeTrackingApi, getHighlighterByViewApi, getHistoryByViewApi, getRevertHistoryByViewApi, getSowChecklistApi, getStageTimeSummeryApi, getStagesByIdApi, getTaskByIdApi, getTimeSummeryApi, getTimeTrackingApi, stageByProjectIdApi, stageByUpdateCustomOrder, updateActionStatusToAnyApi } from '../api';
import { ADD_TASK_BY_ID, ALL_HIGHLIGHTERS, CHANGE_ESTIMATION_TIME, CREATE_PROJECT_ONE, CREATE_PROJECT_TWO, EDIT_PROJECT_BY_ID, EDIT_STAGE_BY_ID, EDIT_TASK_BY_ID, GET_ALL_BLUEPRINT, GET_ALL_DUPLICATE_SUBTASK, GET_ALL_PROJECT, GET_ALL_PROJECT_MASTERDATA, GET_ALL_PROJECT_NO_PAGINATION, GET_ALL_REVERT_HISTORY, GET_ALL_SUBTASK, GET_ALL_SUBTASK_SAME_TASK, GET_ALL_SUBTASK_SUMMARY, GET_ALL_TASK_BY_STAGE, GET_ALL_TASK_BY_STAGE_ID, GET_ALL_TASK_VIEW, GET_GENERAL_TIME_TRACKING, GET_HIGHLIGHT_BY_VIEW, GET_HISTORY_BY_VIEW, GET_LATEST_TIME_BY_ID, GET_PROJECT_BY_ID, GET_REVERT_HISTORY, GET_SOW_CHECKLIST, GET_SOW_FIELDS, GET_STAGES_BY_ID, GET_STAGE_BY_PROJECT_ID, GET_STAGE_TIME_SUMMERY, GET_STAGE_VIEW, GET_TASK_BY_ID, GET_TASK_BY_PROJECT_ID, GET_TIME_BY_ID, GET_TIME_BY_PROJECT_ID, GET_TIME_BY_STAGE_ID, GET_TIME_SUMMERY, GET_TIME_TRACKING, NEED_INFO, PROJECT_ACTION, SET_STAGE_BY_UPDATE_CUSTOM_ORDER, STAGE_ACTION, START_TRACKING, STOP_TRACKING, UPDATE_ACTION_STATUS_TO_ANY } from '../storeTypes';

function* getAllProjectMasterData() {
    try {
        const response = yield call(getAllprojectMasterDataApi);
        if (response.status === 200) {
            yield put(getAllProjectMasterDataSuccess(response.data.data));
        }
    } catch (error) {

        yield put(ProjectFail(error));
    }
}
function* getAllBlueprint() {
    try {
        const response = yield call(getAllBlueprintsApi);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllBlueprintSuccess(response.data.data));
        }
    } catch (error) {

        yield put(ProjectFail(error));
    }
}

function* getAllProjectdata() {
    try {
        const response = yield call(getAllProjectsApi);
        if (response.data.data) {
            yield put(getAllProjectNoPaginationSuccess(response.data.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}

function* getAllProjectListData(action) {
    try {
        const response = yield call(getAllprojectListApi, action.payload);
        if (response?.status === 200) {
            yield put(GetProjectListSuccess(response?.data?.data));
        }
    } catch (error) {

        yield put(ProjectFail(error));
    }
}
function* getAllTaskViewData(action) {
    try {
        const response = yield call(getAllTaskViewApi, action?.payload);
        if (response?.data?.data) {
            yield put(GetAllTaskViewSuccess(response?.data?.data));
        }
    } catch (error) {

        yield put(ProjectFail(error));
    }
}
function* getTimeSummerySaga(action) {
    try {
        const response = yield call(getTimeSummeryApi, action?.payload);
        if (response?.status === 200) {
            yield put(getTimeSummerySuccess(response?.data?.data));
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getStageTimeSummerySaga(action) {
    try {
        const response = yield call(getStageTimeSummeryApi, action?.payload);
        if (response?.status === 200) {
            yield put(getTimeSummerySuccess(response?.data?.data || ''));
        }
    } catch (error) {
        yield put(getTimeSummerySuccess(""));
        yield put(ProjectFail(error));
    }
}

function* getAllStageViewData(action) {
    try {
        const response = yield call(getAllStageViewApi, action?.payload);
        if (response?.data?.data) {
            yield put(GetAllStageViewSuccess(response?.data?.data));
        }
    } catch (error) {

        yield put(ProjectFail(error));
    }
}
function* createProjectStepone(action) {
    const { formik } = action;
    try {
        const response = yield call(StepOneApi, action.payload);
        if (response?.data?.data) {
            window.location.href = "/create-project?step=2&id=" + response.data.data?._id
            yield put(createStepOneSuccess(response.data.data));
            toast.success('Step One created successfully');
        } else {
            toast.error("can't create")

        }
    } catch (error) {

        if (error?.response?.data?.code === 400 && error?.response?.data?.errors) {
            error.response.data.errors.forEach(apiError => {
                console.log("apiError", apiError.field, apiError.message);
                formik.setFieldError(apiError.field, apiError.message);
            });
            yield put(ProjectFail(error));
        } else {
            // toast.error("Error creating Step One");
            yield put(ProjectFail(error));
        }
    }
}
function* stepTwoData(action) {
    const { payload, params, formik } = action;
    try {
        const response = yield call(StepTwoApi, payload, params);

        if (response?.data?.data) {
            action?.Callback(response);
            // window.location.href = "/form?step=3&id=" + params
            yield put(createStepTwoSuccess(response.data.data));


            // toast.success('Step Two completed successfully');
        }
    } catch (error) {
        if (error?.response?.data?.code === 400 && error?.response?.data?.errors) {
            error.response.data.errors.forEach(apiError => {
                console.log("apiError", apiError.field, apiError.message);
                formik.setFieldError(apiError.field, apiError.message);
            });
            yield put(ProjectFail(error));
        }
        yield put(ProjectFail(error));
    }
}
function* GetProjectbyIdsaga(action) {
    try {
        const response = yield call(GetProjectbyId, action.payload);
        if (response?.data?.data) {
            yield put(getProjectByIdSuccess(response.data.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getStagesByIdSaga(action) {
    try {
        const response = yield call(getStagesByIdApi, action?.payload);
        if (response?.data?.data) {
            yield put(getStagesByIdSuccess(response?.data?.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getTaskByIdSaga({ taskId }) {
    try {
        const response = yield call(getTaskByIdApi, taskId);
        if (response?.data?.data) {
            yield put(getTaskByIdSuccess(response?.data?.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getTaskByProjectIdSaga(action) {
    try {
        const response = yield call(TaskByProjectIdApi, action?.payload);
        if (response?.data?.data) {
            yield put(getTaskByProjectIdSuccess(response?.data?.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getAllTaskViewByStageIdSaga(action) {
    try {
        const response = yield call(getAllTaskViewByStageIdApi, action?.id);
        if (response?.data?.data) {
            yield put(getAllTaskViewByStageIdSuccess(response?.data?.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getStagesByProjectIdSaga(action) {
    try {
        const response = yield call(stageByProjectIdApi, action?.payload);
        if (response?.data?.data) {
            yield put(getStageByProjectIdSuccess(response?.data?.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}

function* getStagesByUpdateCustomOrder(action) {
    let { funC } = action
    try {
        const response = yield call(stageByUpdateCustomOrder, action?.payload);
        if (response?.data?.data) {
            funC(response)
            yield put(getStageByCustomOrderSuccess(response?.data?.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* GetChecklistSow(action) {
    try {
        const response = yield call(getSowChecklistApi, action.payload);
        if (response?.data?.data) {
            yield put(getSowChecklistSuccess(response.data.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* GetSowFields(action) {
    try {
        const response = yield call(GetSowFieldsApi, action.payload);
        if (response?.data?.data) {
            yield put(getSowFieldsSuccess(response.data.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* UpdateProjectDetailsSaga(action) {
    try {
        const response = yield call(editProjectByIdApi, action);
        if (response?.data) {
            yield put(editProjectByIdSuccess(response.data.data))
            toast.success(`${capitalize(action?.fieldName)} updated successfully`, { position: "bottom-left", duration: 5000 });
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}

function* UpdateStagesDataSaga(action) {
    try {
        const response = yield call(editStagesByIdApi, action);

        if (response?.data) {
            yield put(editStageByIdSuccess(response.data.data))
            toast.success(`${capitalize(action?.fieldName)} updated successfully`, { position: "bottom-left", duration: 5000 });
        }
    } catch (error) {
        yield put(ProjectFail(error));
        console.log('UpdateByIdSaga Error :- ', error);
    }
}
function* UpdateTaskDataSaga(action) {
    try {
        const response = yield call(editTaskByIdApi, action);
        if (response?.data) {
            yield put(editStageByIdSuccess(response.data.data))
            toast.success(`${capitalize(action?.fieldName)} updated successfully`, { position: "bottom-left", duration: 5000 });
        }
    } catch (error) {
        yield put(ProjectFail(error.response.data.errors));
        toast.error(`${error.response.data.errors[0].message}`, { position: "bottom-left", duration: 5000 });
    }
}
function* addTaskDataSaga(action) {
    try {
        const response = yield call(addTaskByIdApi, action);
        if (response?.data) {
            yield put(addTaskByIdSuccess(response.data.data))
            yield call(getTaskByProjectIdSaga, { payload: action.Id })
            toast.success(`Add Task successfully`);
        }
    } catch (error) {
        console.log('add By Id Saga Error :- ', error);
        yield put(ProjectFail(error));
    }
}
function* StartTrackingSaga(action) {
    try {
        const response = yield call(StartTrackingApi, action);
        if (response?.data) {
            yield put(StartTrackingSuccess(response?.data?.data))
            if (action?.payload.taskId != null) {
                yield put(GetLatestTimeById(action.payload.taskId))
            }
            yield put(getTimeTracking())
            toast.success("Start time recorded successfully!")
        }

    } catch (error) {
        if (error.response.data.code === 409) {
            toast.error(error?.response?.data.message || "Something went wrong")
        }
        yield put(ProjectFail(error));
    }
}
function* StopTrackingSaga(action) {
    try {
        const response = yield call(StopTrackingApi, action);
        if (response?.data) {

            yield put(StopTrackingSuccess(response?.data?.data))
            yield put(GetLatestTimeById(action.id))
            yield put(getTimeTracking())
            action?.Callback(response);
            if (response?.status === 200) {
                toast?.success("Spent time recorded successfully")
            }

        }
    } catch (error) {
        console.log("error---", error)
        yield put(ProjectFail(error));
    }
}
function* NeedInfoSaga(action) {
    try {
        const response = yield call(NeedInfoApi, action);
        if (response?.data) {
            action?.Callback(response);
            yield put(needInfoSuccess(response?.data?.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* StageActionSaga(action) {
    try {
        const response = yield call(StageActionApi, action);
        if (response?.data) {
            yield put(stageActionSuccess(response?.data?.data))
        }
    } catch (error) {
    }
}
function* ProjectActionSaga(action) {
    try {
        const response = yield call(ProjectActionApi, action);
        if (response?.data) {
            yield put(projectActionSuccess(response?.data?.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* GetTimebyIdsaga(action) {
    try {
        const response = yield call(GetTimeById, action.payload);
        if (response?.data?.data) {
            yield put(GetTimeByIdSuccess(response.data))
        }
    } catch (error) {

        yield put(ProjectFail(error));
    }
}
function* GetTimebyStageIdsaga(action) {
    try {
        const response = yield call(GetTimeByStageIdApi, action.payload);
        if (response?.data?.data) {
            yield put(GetTimeByStageIdSuccess(response.data.data))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* GetTimebyProjectIdsaga(action) {
    try {
        const response = yield call(GetTimeByProjectIdApi, action.payload);
        if (response?.data?.data) {
            yield put(GetTimeByProjectIdSuccess(response.data.data))
        }
    } catch (error) {

        yield put(ProjectFail(error));
    }
}
function* GetLastTimeByIdSaga(action) {
    try {
        const response = yield call(GetLastTimeById, action?.payload);
        if (response?.status === 200) {
            yield put(GetLatestTimeByIdSuccess(response?.data?.data[0] || []))
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getAllHighlightersSaga() {
    try {
        const response = yield call(GetAllHighlighters);
        if (response.data.data) {
            // console.log("response.data", response.data);
            yield put(getAllHighlightersSuccess(response.data.data));
        }
    } catch (error) {

        yield put(ProjectFail(error));
    }
}
function* getHighlighterByViewSaga(action) {
    try {
        const response = yield call(getHighlighterByViewApi, action?.view);
        if (response.data.data) {
            yield put(getHighlighterByViewSuccess(response.data.data));
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getHistoryByViewSaga(action) {
    try {
        const response = yield call(getHistoryByViewApi, action?.payload);
        if (response.data.data) {
            yield put(getHistoryByViewSuccess(response.data));
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getRevertHistoryByViewSaga(action) {
    try {
        const response = yield call(getRevertHistoryByViewApi, action?.payload);
        if (response.data.data) {
            yield put(getRevertHistoryByViewSuccess(response.data));
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getAllRevertHistorySaga(action) {
    try {
        const response = yield call(getAllRevertHistoryApi, action?.payload);
        if (response.data.data) {
            yield put(getAllRevertHistorySuccess(response.data));
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* changeEstimationTimeSaga(action) {
    try {
        const response = yield call(changeEstimationTimeApi, action);
        if (response.status === 200) {
            yield put(getTaskById(action.taskId));
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getTimeTrackingSaga() {
    try {
        const response = yield call(getTimeTrackingApi);
        if (response.status === 200) {
            yield put(getTimeTrackingSuccess(response.data.data));
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}

function* getGeneralTimeTrackingSaga({ payload }) {
    try {
        const response = yield call(getGeneralTimeTrackingApi, payload);
        if (response.status === 200) {
            yield put(getGeneralTimeTrackingSuccess(response.data.data));
        }
    } catch (error) {
        yield put(ProjectFail(error));
    }
}
function* getAllSubtaskSaga({ payload }) {
    try {
        const response = yield call(getAllSubTasks, payload);
        if (response.status === 200) {
            yield put(getAllSubtaskSuccess(response.data.data));
        }
    } catch (error) {
        yield put(getAllSubtaskFailure(error));
    }
}
function* getAllSubtaskSummarySaga({ payload }) {
    try {
        const response = yield call(getAllSubTasksSummaryApi, payload);
        if (response.status === 200) {
            yield put(getAllSubtaskSummarySuccess(response.data.data));
        }
    } catch (error) {
        yield put(getAllSubtaskSummaryFailure(error));
    }
}

function* getAllSubtaskSameTaskSaga({ payload }) {
    try {
        const response = yield call(getAllSubTasksofSameTask, payload);
        if (response.status === 200) {
            yield put(getAllSubtasksOfSameTaskSuccess(response.data.data));
        }
    } catch (error) {
        yield put(getAllSubtasksOfSameTaskFailure(error));
    }
}
function* getAllDuplicateSubtaskSaga({ taskId }) {
    try {
        const response = yield call(getAllDuplicateSubtasks, taskId);
        if (response.status === 200) {
            yield put(getAllDuplicateSubtasksSuccess(response.data));
        }
    } catch (error) {
        yield put(getAllDuplicateSubtasksFailure(error));
    }
}
function* updateActionStatusToAnySaga({ payload }) {
    try {
        const response = yield call(updateActionStatusToAnyApi, payload);
        if (response.status === 200) {
            yield put(updateActionStatusToAnySuccess(response.data.data));
        }
    } catch (error) {
        yield put(getAllSubtaskSummaryFailure(error));
    }
}
export function* getAllProjectMaterdataSaga() {
    yield takeLatest(GET_ALL_PROJECT_MASTERDATA, getAllProjectMasterData);
    yield takeLatest(GET_ALL_BLUEPRINT, getAllBlueprint);
    yield takeLatest(CREATE_PROJECT_ONE, createProjectStepone);
    yield takeLatest(CREATE_PROJECT_TWO, stepTwoData);
    yield takeLatest(GET_PROJECT_BY_ID, GetProjectbyIdsaga);
    yield takeLatest(GET_SOW_CHECKLIST, GetChecklistSow);
    yield takeLatest(GET_SOW_FIELDS, GetSowFields)
    yield takeLatest(GET_STAGE_VIEW, getAllStageViewData)
    yield takeLatest(GET_ALL_PROJECT, getAllProjectListData)
    yield takeLatest(GET_ALL_TASK_VIEW, getAllTaskViewData)
    yield takeLatest(EDIT_PROJECT_BY_ID, UpdateProjectDetailsSaga)
    yield takeLatest(GET_STAGES_BY_ID, getStagesByIdSaga)
    yield takeLatest(EDIT_STAGE_BY_ID, UpdateStagesDataSaga)
    yield takeLatest(EDIT_TASK_BY_ID, UpdateTaskDataSaga)
    yield takeLatest(ADD_TASK_BY_ID, addTaskDataSaga)
    yield takeLatest(GET_TASK_BY_ID, getTaskByIdSaga)
    yield takeLatest(START_TRACKING, StartTrackingSaga)
    yield takeLatest(STOP_TRACKING, StopTrackingSaga)
    yield takeLatest(GET_TIME_BY_ID, GetTimebyIdsaga)
    yield takeLatest(GET_LATEST_TIME_BY_ID, GetLastTimeByIdSaga)
    yield takeLatest(GET_TIME_BY_STAGE_ID, GetTimebyStageIdsaga)
    yield takeLatest(GET_TIME_BY_PROJECT_ID, GetTimebyProjectIdsaga)
    yield takeLatest(NEED_INFO, NeedInfoSaga)
    yield takeLatest(STAGE_ACTION, StageActionSaga)
    yield takeLatest(PROJECT_ACTION, ProjectActionSaga)
    yield takeLatest(ALL_HIGHLIGHTERS, getAllHighlightersSaga)
    yield takeLatest(GET_TASK_BY_PROJECT_ID, getTaskByProjectIdSaga)
    yield takeLatest(GET_STAGE_BY_PROJECT_ID, getStagesByProjectIdSaga)
    yield takeLatest(GET_ALL_PROJECT_NO_PAGINATION, getAllProjectdata)
    yield takeLatest(GET_ALL_TASK_BY_STAGE_ID, getAllTaskViewByStageIdSaga)
    yield takeLatest(GET_ALL_TASK_BY_STAGE, getAllTaskViewByStageIdSaga)
    yield takeLatest(GET_HIGHLIGHT_BY_VIEW, getHighlighterByViewSaga)
    yield takeLatest(GET_HISTORY_BY_VIEW, getHistoryByViewSaga)
    yield takeLatest(GET_REVERT_HISTORY, getRevertHistoryByViewSaga)
    yield takeLatest(GET_ALL_REVERT_HISTORY, getAllRevertHistorySaga)
    yield takeLatest(CHANGE_ESTIMATION_TIME, changeEstimationTimeSaga)
    yield takeLatest(GET_TIME_SUMMERY, getTimeSummerySaga)
    yield takeLatest(GET_STAGE_TIME_SUMMERY, getStageTimeSummerySaga)
    yield takeLatest(GET_TIME_TRACKING, getTimeTrackingSaga)
    yield takeLatest(GET_GENERAL_TIME_TRACKING, getGeneralTimeTrackingSaga)
    yield takeLatest(SET_STAGE_BY_UPDATE_CUSTOM_ORDER, getStagesByUpdateCustomOrder)
    yield takeLatest(GET_ALL_SUBTASK, getAllSubtaskSaga)
    yield takeLatest(GET_ALL_SUBTASK_SUMMARY, getAllSubtaskSummarySaga)
    yield takeLatest(GET_ALL_SUBTASK_SAME_TASK, getAllSubtaskSameTaskSaga)
    yield takeLatest(GET_ALL_DUPLICATE_SUBTASK, getAllDuplicateSubtaskSaga)
    yield takeLatest(UPDATE_ACTION_STATUS_TO_ANY, updateActionStatusToAnySaga)

}
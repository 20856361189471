import { rolesProps, usersProps } from "../../../@types";
import { CREATE_TEAM, CREATE_TEAM_SUCCESS, CREATE_USER, CREATE_USER_SUCCESS, DELETE_ROLE, DELETE_ROLE_SUCCESS, DELETE_USER, DELETE_USER_SUCCESS, EDIT_ROLE, EDIT_ROLE_SUCCESS, EDIT_USER, EDIT_USER_SUCCESS, GET_ALL_ROLES, GET_ALL_ROLES_SUCCESS, GET_ALL_SUB_TEAMS, GET_ALL_SUB_TEAMS_SUCCESS, GET_ALL_TEAMS, GET_ALL_TEAMS_SUCCESS, GET_ALL_TODOS, GET_ALL_TODOS_SUCCESS, GET_ALL_UNASSIGNED_TEAMS, GET_ALL_UNASSIGNED_TEAMS_SUCCESS, GET_ALL_USERS, GET_ALL_USERS_SUCESS, GET_ALL_USERS_WITHOUT_PAGINATION, GET_ALL_USERS_WITHOUT_PAGINATION_SUCESS, GET_ASSIGNED_TODOS, GET_ASSIGNED_TODOS_SUCCESS, GET_MEMBERSINFO_BY_TEAM, GET_MEMBERSINFO_BY_TEAM_SUCCESS, GET_ROLE_BY_ID, GET_ROLE_BY_ID_SUCCESS, GET_TEAM_BY_ID, GET_TEAM_BY_ID_SUCCESS, GET_TEAMS_BY_MEMBER_NO_PAGINATION_SUCCESS, GET_TEAMS_BY_MEMBER_SUCCESS, GET_TEAMS_BY_MEMBERS, GET_TEAMS_BY_MEMBERS_NO_PAGINATION, GET_TEAMS_BY_USER, GET_TEAMS_BY_USER_NO_PAGINATION, GET_TEAMS_BY_USER_NO_PAGINATION_SUCCESS, GET_TEAMS_BY_USER_SUCCESS, GET_TODO_BY_ID, GET_TODO_BY_ID_SUCCESS, GET_TODO_BY_USER, GET_TODO_BY_USER_SUCCESS, GET_USER_BY_ID, GET_USER_BY_ID_SUCCESS } from "../storeTypes";

const initialState: any = {
  loader: true,
  users: {
    count: 15,
    data: []
  },
  // userData: {
  //   data: []
  // },
  roles: {
    count: 15,
    data: []
  },
  teams: {
    count: 15,
    data: []
  },
  todos: {
    count: 15,
    data: []
  },
  currentPage: 1,
  totalPages: 1,
  teamByUser: null,
  teamByLoggedInuser: null,
  teamByMember: null,
  assignedTodos: null,
  membersTeam: null,
  subteams: null,
  unAssignedTeams: null,
  teamById: {},
  todoById: {},
  membersInfo: {},
  todoByUser: null,
  rolesbyId: {} as rolesProps,
  usersbyId: {} as usersProps
};
const UserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        loader: true
      };
    case GET_ALL_USERS_SUCESS:
      return {
        ...state,
        loader: false,
        users: action.payload
      };
    case GET_ALL_USERS_WITHOUT_PAGINATION:
      return {
        ...state,
        loader: true
      };
    case GET_ALL_USERS_WITHOUT_PAGINATION_SUCESS:
      return {
        ...state,
        loader: false,
        userData: action.payload
      };
    case CREATE_USER:
      return {
        ...state,
        loader: true,
        // users: action.payload
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loader: false,
        users: action.payload
      }
    case DELETE_USER:
      return {
        ...state,
        loader: true,
        // users: action.payload
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loader: false,
        // users: action.payload
      }
    case DELETE_ROLE:
      return {
        ...state,
        loader: true,
        // users: action.payload
      }
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loader: false,
        // users: action.payload
      }
    case EDIT_USER:
      return {
        ...state,
        loader: true,
      }
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        loader: false,
        users: action.payload
      }
    case GET_USER_BY_ID:
      return {
        ...state,
        loader: true
      }
    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loader: false,
        usersbyId: action.payload
      }
    case GET_ROLE_BY_ID:
      return {
        ...state,
        loader: true
      }
    case GET_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        loader: false,
        rolesbyId: action.payload
      }
    case GET_ALL_ROLES:
      return {
        ...state,
        loader: true,
      }
    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        loader: false,
        roles: action.payload
      }
    case EDIT_ROLE:
      return {
        ...state,
        loader: true,
      }
    case EDIT_ROLE_SUCCESS:
      return {
        ...state,
        loader: false,
        roles: action.payload
      }
    case GET_ALL_TEAMS:
      return {
        ...state,
        loader: true
      };
    case GET_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        loader: false,
        teams: action.payload
      };
    case GET_ALL_UNASSIGNED_TEAMS:
      return {
        ...state,
        loader: true
      };
    case GET_ALL_UNASSIGNED_TEAMS_SUCCESS:
      return {
        ...state,
        loader: false,
        unAssignedTeams: action.payload
      };
    case GET_ALL_SUB_TEAMS:
      return {
        ...state,
        loader: true
      };
    case GET_ALL_SUB_TEAMS_SUCCESS:
      return {
        ...state,
        loader: false,
        subTeams: action.payload
      };
    case GET_TEAMS_BY_USER:
      return {
        ...state,
        loader: true
      };
    case GET_TEAMS_BY_USER_SUCCESS:
      return {
        ...state,
        loader: false,
        teamByUser: action.payload
      };
    case GET_TEAMS_BY_USER_NO_PAGINATION:
      return {
        ...state,
        loader: true
      };
    case GET_TEAMS_BY_USER_NO_PAGINATION_SUCCESS:
      return {
        ...state,
        loader: false,
        teamByLoggedInuser: action.payload
      };
    case GET_TEAMS_BY_MEMBERS:
      return {
        ...state,
        loader: true
      };
    case GET_TEAMS_BY_MEMBER_SUCCESS:
      return {
        ...state,
        loader: false,
        teamByMember: action.payload
      };
    case GET_TEAMS_BY_MEMBERS_NO_PAGINATION:
      return {
        ...state,
        loader: true
      };
    case GET_TEAMS_BY_MEMBER_NO_PAGINATION_SUCCESS:
      return {
        ...state,
        loader: false,
        membersTeam: action.payload
      };
    case GET_MEMBERSINFO_BY_TEAM:
      return {
        ...state,
        loader: true
      };
    case GET_MEMBERSINFO_BY_TEAM_SUCCESS:
      return {
        ...state,
        loader: false,
        membersInfo: action.payload
      };
    case CREATE_TEAM:
      return {
        ...state,
        loader: true,
        // users: action.payload
      }
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loader: false,
        teams: action.payload
      }
    case GET_TEAM_BY_ID:
      return {
        ...state,
        loader: true
      }
    case GET_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        loader: false,
        teamById: action.payload
      }
    case GET_ALL_TODOS:
      return {
        ...state,
        loader: true
      };
    case GET_ALL_TODOS_SUCCESS:
      return {
        ...state,
        loader: false,
        todos: action.payload
      };
    case GET_TODO_BY_ID:
      return {
        ...state,
        loader: true
      }
    case GET_TODO_BY_ID_SUCCESS:
      return {
        ...state,
        loader: false,
        todoById: action.payload
      }
    case GET_TODO_BY_USER:
      return {
        ...state,
        loader: true
      };
    case GET_TODO_BY_USER_SUCCESS:
      return {
        ...state,
        loader: false,
        todoByUser: action.payload
      };
    case GET_ASSIGNED_TODOS:
      return {
        ...state,
        loader: true
      };
    case GET_ASSIGNED_TODOS_SUCCESS:
      return {
        ...state,
        loader: false,
        assignedTodos: action.payload
      };
    default:
      return state;
  }
};

export default UserReducer;

import { call, takeEvery } from 'redux-saga/effects';

import { handleApiResponse } from '../../utils/HandelResponse';
import { CREATE_BY_URL, DELETE_BY_ID, UPDATE_BY_ID } from '../storeTypes';
import { CreateByUrlApi, DeleteByIdApi, UpdateByIdApi } from '../api';

const File = 'commonSaga'
function* UpdateByIdSaga(action) {
    try {
        const response = yield call(UpdateByIdApi, action.payload);
        handleApiResponse(response.status, response?.data, action?.callBack, true)
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
        console.log(File + 'UpdateByIdSaga Error :- ', error);
    }
}
function* DeleteByIdSaga(action) {
    try {
        const response = yield call(DeleteByIdApi, action.payload);
        handleApiResponse(response.status, response?.data, action?.callBack, true)
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
        console.log(File + 'DeleteByIdSaga Error :- ', error);
    }
}
function* CreateByUrlSaga(action) {
    try {
        const response = yield call(CreateByUrlApi, action.payload);
        handleApiResponse(response.status, response?.data, action?.callBack, true)
    } catch (error) {
        handleApiResponse(error?.response.status, error?.response?.data, action?.callBack, true)
        console.log(File + 'CreateByUrlSaga Error :- ', error);
    }
}

export function* commonSaga() {
    return [
        yield takeEvery(UPDATE_BY_ID, UpdateByIdSaga),
        yield takeEvery(DELETE_BY_ID, DeleteByIdSaga),
        yield takeEvery(CREATE_BY_URL, CreateByUrlSaga),
    ]
}

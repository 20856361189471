import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { getTaskByProjectId } from "../../../store/actions/project.action";
import { useSelector } from "react-redux";
import { Box, Text } from "theme-ui";
import { ActionCard } from "../../Actions/ActionModule/SubTaskActionCard";

interface PrelimTaskModalProps {
    isOpen: boolean;
    toggle: () => void;
    projectId: string;
    stageId: string;
    keys?: any;
}

const PrelimTaskModal: React.FC<PrelimTaskModalProps> = ({ isOpen, toggle, projectId, stageId, keys }) => {
    const dispatch = useDispatch();
    const { taskbyproject, allSubtaskSametask } = useSelector((state: any) => state.project);
    console.log('check20', taskbyproject);


    useEffect(() => {
        if (projectId) {
            dispatch(getTaskByProjectId(projectId));
        }
    }, [dispatch, projectId]);


    const matchedTasks = taskbyproject && taskbyproject?.length > 0 && allSubtaskSametask && allSubtaskSametask?.length > 0
        ? allSubtaskSametask.reduce((acc: any[], subtask: any) => {

            const matchedTask = taskbyproject?.find((task: any) => task?._id === subtask?.taskId);
            if (matchedTask) {
                acc.push({
                    taskName: matchedTask.name,
                    taskColor: matchedTask.color,

                    subtaskInfo: subtask?.payload?.Description.map((desc: any) => ({
                        ...desc,
                        actionType: subtask?.actionType, actionId: subtask?._id

                    }))
                });
            }
            return acc;
        }, [])
        : [];
    let uniqueKeyCounter = 0;
    let ActionObject: any = { projectId: projectId, stageId: stageId }

    return (
        <Modal centered scrollable size='xl' isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}> List of Other Subtasks</ModalHeader>
            <ModalBody>
                {matchedTasks?.length > 0 ? (
                    <>
                        {matchedTasks?.map((match: any, index: number) => {


                            if (match?.subtaskInfo?.length > 0) {
                                return (
                                    <div key={index}>

                                        <Text className="px-3" variant="Poppins16Bold" sx={{ color: match?.taskColor, fontSize: 17 }}> {match?.taskName}</Text>


                                        {match?.subtaskInfo?.map((subtask: any, subtaskIndex: number) => {

                                            return (
                                                <Box className='my-2'>
                                                    <ActionCard
                                                        key={subtaskIndex}
                                                        index={subtaskIndex}
                                                        action={subtask}
                                                        comment={false}
                                                        selectedHighlight={subtask?.actionType}
                                                        keys={{ first: "isDone", third: "isReviewed" }}
                                                        actionObject={ActionObject}
                                                        parentData={subtask}
                                                        inputWidth={"100%"}
                                                        actionId={subtask.actionId}
                                                    />
                                                </Box>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        })}
                    </>
                ) : (
                    null
                )}
            </ModalBody>
        </Modal>
    );
};

export default PrelimTaskModal;

(function () {
    const isLocalhost = window.location.hostname === 'localhost';

    if (!isLocalhost) {
        console.log = function () { };
        console.warn = function () { };
        // console.error = function () { };
        console.info = function () { };
        console.debug = function () { };
        console.clear = function () { };
        console.assert = function () { };
        console.count = function () { };
        console.countReset = function () { };
        console.group = function () { };
        console.groupEnd = function () { };
        console.groupCollapsed = function () { };
        console.time = function () { };
        console.timeEnd = function () { };
        console.timeLog = function () { };
        console.trace = function () { };
        console.table = function () { };
        console.dir = function () { };
        console.dirxml = function () { };
        console.profile = function () { };
        console.profileEnd = function () { };
        console.exception = function () { };
        console.markTimeline = function () { };
        console.timeStamp = function () { };
        // console.memory = {};
    }
})();

import { ErrorMessage, Field, FormikProps } from 'formik';
import { withFormikValidation } from './enhancer/AddEditRatingCriteriaEnhancer';
import { Button, Label } from 'theme-ui';
import theme from '../../../../theme';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types';
import { ActionCreateByUrl, ActionUpdateById } from '../../../../store/actions/CommonAction';
import { getRatingCriteriaById } from '../../../../store/actions/masterData.action';


const AddEditRatingCriteria: React.FC<FormikProps<any>> = ({
    values,
    errors,
    touched,
    handleSubmit,
    setValues,
    validateForm,
    isValid,
    isSubmitting,
    handleBlur,
    setFieldValue
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ratingId } = useParams();
    const { individualRatingCriteria } = useSelector((state: RootState) => state?.masterData);
    const [isNeedToValidate, setIsNeedToValidate] = useState<boolean>(false);

    useEffect((): void => {
        if (ratingId) {
            dispatch(getRatingCriteriaById({ id: ratingId }))
        }
    }, [ratingId])



    useEffect(() => {
        if (ratingId && individualRatingCriteria) {
            setValues({
                ...values,
                ratingCriteria: individualRatingCriteria?.ratingCriteria,
            })
            setIsNeedToValidate(true);
        }
    }, [individualRatingCriteria])

    useEffect(() => {
        if (isNeedToValidate) {
            validateForm();
        }
    }, [isNeedToValidate])

    const handleArchitectData = async (): Promise<void> => {
        handleSubmit();
        if (isValid) {
            ratingId ?
                dispatch(ActionUpdateById({ Query: `/rating-criteria/edit-criteria/${ratingId}`, obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/rating-criteria")
                    }
                }))
                :
                dispatch(ActionCreateByUrl({ Query: "rating-criteria/createCriteria", obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/rating-criteria")
                    }
                }));
        }
    }

    return (
        <div className="main-content p-5">
            <h1>{ratingId ? "Edit" : "Add"} Rating Criteria</h1>
            <div className="row">
                <div className="col-md-6 mt-4">
                    <Label htmlFor="ratingCriteria" className={`form-label mt-1 ${errors.ratingCriteria && touched.ratingCriteria ? 'is-invalid' : ''}`}>Rating Criteria*</Label>
                    <Field type="text" id="ratingCriteria" name="ratingCriteria" className="form-control" placeholder="Rating Criteria" />
                    <ErrorMessage name="ratingCriteria" component="div" className="text-danger" />
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' onClick={handleArchitectData} disabled={isSubmitting}>Submit</Button></div>
            </div>
        </div >
    );
}

export default withFormikValidation(AddEditRatingCriteria);
import { GET_TRADING_RATING, GET_TRADING_RATING_BY_ID, GET_TRADING_RATING_BY_ID_SUCCESS, GET_TRADING_RATING_SUCCESS, TRADING_RATING_FAIL } from "../storeTypes"


export const getTradingRating = (payload) => {
    return {
        type: GET_TRADING_RATING,
        payload
    }
}

export const getTradingRatingSuccess = (payload) => {
    return {
        type: GET_TRADING_RATING_SUCCESS,
        payload: payload
    }
}

export const getTradingRatingId = (payload) => {
    return {
        type: GET_TRADING_RATING_BY_ID,
        payload: payload
    }
}
export const getTradingRatingIdSuccess = (payload) => {
    return {
        type: GET_TRADING_RATING_BY_ID_SUCCESS,
        payload: payload
    }
}

export const tradingRatingFail = (payload) => {
    return {
        type: TRADING_RATING_FAIL,
        payload: payload
    }
}

import { CREATE_ROLE, CREATE_ROLE_SUCCESS, CREATE_TEAM, CREATE_TEAM_SUCCESS, CREATE_USER, CREATE_USER_SUCCESS, DELETE_ROLE, DELETE_ROLE_SUCCESS, DELETE_USER, DELETE_USER_SUCCESS, EDIT_ROLE, EDIT_ROLE_SUCCESS, EDIT_USER, EDIT_USER_SUCCESS, GET_ALL_ROLES, GET_ALL_ROLES_SUCCESS, GET_ALL_SUB_TEAMS, GET_ALL_SUB_TEAMS_SUCCESS, GET_ALL_TEAMS, GET_ALL_TEAMS_SUCCESS, GET_ALL_TODOS, GET_ALL_TODOS_SUCCESS, GET_ALL_UNASSIGNED_TEAMS, GET_ALL_UNASSIGNED_TEAMS_SUCCESS, GET_ALL_USERS, GET_ALL_USERS_SUCESS, GET_ALL_USERS_WITHOUT_PAGINATION, GET_ALL_USERS_WITHOUT_PAGINATION_SUCESS, GET_ASSIGNED_TODOS, GET_ASSIGNED_TODOS_SUCCESS, GET_MEMBERSINFO_BY_TEAM, GET_MEMBERSINFO_BY_TEAM_SUCCESS, GET_ROLE_BY_ID, GET_ROLE_BY_ID_SUCCESS, GET_TEAM_BY_ID, GET_TEAM_BY_ID_SUCCESS, GET_TEAMS_BY_MEMBER_NO_PAGINATION_SUCCESS, GET_TEAMS_BY_MEMBER_SUCCESS, GET_TEAMS_BY_MEMBERS, GET_TEAMS_BY_MEMBERS_NO_PAGINATION, GET_TEAMS_BY_USER, GET_TEAMS_BY_USER_NO_PAGINATION, GET_TEAMS_BY_USER_NO_PAGINATION_SUCCESS, GET_TEAMS_BY_USER_SUCCESS, GET_TODO_BY_ID, GET_TODO_BY_ID_SUCCESS, GET_TODO_BY_USER, GET_TODO_BY_USER_SUCCESS, GET_USER_BY_ID, GET_USER_BY_ID_SUCCESS } from "../storeTypes";

export const getAllUser = (payload) => {
    return {
        type: GET_ALL_USERS,
        payload: payload,
    }
};
export const getAllUserSucess = (payload) => {
    return {
        type: GET_ALL_USERS_SUCESS,
        payload: payload,
    }
};
export const getAllUserWithoutPagination = (payload) => {
    return {
        type: GET_ALL_USERS_WITHOUT_PAGINATION,
        payload
    }
};
export const getAllUserWithoutPaginationSuccess = (payload) => {
    return {
        type: GET_ALL_USERS_WITHOUT_PAGINATION_SUCESS,
        payload
    }
};
export const createUser = (payload) => {
    return {
        type: CREATE_USER,
        payload: payload,
    }
};
export const createUserSuccess = (payload) => {
    return {
        type: CREATE_USER_SUCCESS,
        payload: payload,
    }
};
export const deleteUser = (id) => {
    return {
        type: DELETE_USER,
        payload: id
    }
}
export const deleteUserSucess = (id) => {
    return {
        type: DELETE_USER_SUCCESS,
        payload: id
    }
}
export const editUser = (payload, id) => {
    // console.log("id---", id);
    return {
        type: EDIT_USER,
        payload: payload,
        // id: id
    }
}
export const editUserSuccess = (payload, id) => {
    return {
        type: EDIT_USER_SUCCESS,
        payload: payload,
        // id: id
    }
}
export const getUserbyId = (id) => {
    return {
        type: GET_USER_BY_ID,
        payload: id
    }
}
export const getUserbyIdSuccess = (id) => {
    return {
        type: GET_USER_BY_ID_SUCCESS,
        payload: id
    }
}

export const createRole = (payload) => {
    return {
        type: CREATE_ROLE,
        payload: payload
    }
}
export const createRoleSuccess = (payload) => {
    return {
        type: CREATE_ROLE_SUCCESS,
        payload: payload
    }
}
export const getAllRolesSucess = (payload) => {
    return {
        type: GET_ALL_ROLES_SUCCESS,
        payload: payload
    }
}
export const getAllRoles = (payload) => {
    return {
        type: GET_ALL_ROLES,
        payload: payload,
    };
}
export const editRole = (payload) => {
    return {
        type: EDIT_ROLE,
        payload: payload
    }
}
export const editRoleSuccess = (payload) => {
    return {
        type: EDIT_ROLE_SUCCESS,
        payload: payload
    }
}
export const deleteRole = (id) => {
    return {
        type: DELETE_ROLE,
        payload: id
    }
}
export const deleteRoleSuccess = (id) => {
    return {
        type: DELETE_ROLE_SUCCESS,
        payload: id
    }
}
export const getRolebyId = (id) => {
    return {
        type: GET_ROLE_BY_ID,
        payload: id
    }
}
export const getRolebyIdSuccess = (id) => {
    return {
        type: GET_ROLE_BY_ID_SUCCESS,
        payload: id
    }
}

export const getAllTeams = (payload) => {
    return {
        type: GET_ALL_TEAMS,
        payload: payload,
    }
};
export const getAllTeamsSuccess = (payload) => {
    return {
        type: GET_ALL_TEAMS_SUCCESS,
        payload: payload,
    }
};

export const getAllTeamsByUser = (payload) => {
    return {
        type: GET_TEAMS_BY_USER,
        payload: payload,
    }
};
export const getAllTeamsByUserSuccess = (payload) => {
    return {
        type: GET_TEAMS_BY_USER_SUCCESS,
        payload: payload,
    }
};
export const getAllTeamsByUserNoPagination = (payload) => {
    return {
        type: GET_TEAMS_BY_USER_NO_PAGINATION,
        payload: payload,
    }
};
export const getAllTeamsByUserNoPaginationSuccess = (payload) => {
    return {
        type: GET_TEAMS_BY_USER_NO_PAGINATION_SUCCESS,
        payload: payload,
    }
};
export const getAllUnAssignedTeams = (payload) => {
    return {
        type: GET_ALL_UNASSIGNED_TEAMS,
        payload: payload,
    }
};
export const getAllUnAssignedTeamsSuccess = (payload) => {
    return {
        type: GET_ALL_UNASSIGNED_TEAMS_SUCCESS,
        payload: payload,
    }
};
export const createTeam = (payload) => {
    return {
        type: CREATE_TEAM,
        payload: payload
    }
}
export const createTeamSuccess = (payload) => {
    return {
        type: CREATE_TEAM_SUCCESS,
        payload: payload
    }
}
export const getAllTeamsByMember = (payload) => {
    return {
        type: GET_TEAMS_BY_MEMBERS,
        payload: payload,
    }
};
export const getAllTeamsByMemberSuccess = (payload) => {
    return {
        type: GET_TEAMS_BY_MEMBER_SUCCESS,
        payload: payload,
    }
};
export const getAllTeamsByMemberNoPagination = (payload) => {
    return {
        type: GET_TEAMS_BY_MEMBERS_NO_PAGINATION,
        payload: payload,
    }
};
export const getAllTeamsByMemberNoPaginationSuccess = (payload) => {
    return {
        type: GET_TEAMS_BY_MEMBER_NO_PAGINATION_SUCCESS,
        payload: payload,
    }
};
export const getTeambyId = (id) => {
    return {
        type: GET_TEAM_BY_ID,
        payload: id
    }
}
export const getTeambyIdSuccess = (id) => {
    return {
        type: GET_TEAM_BY_ID_SUCCESS,
        payload: id
    }
}
export const getSubTeambyId = (id) => {
    return {
        type: GET_ALL_SUB_TEAMS,
        payload: id
    }
}
export const getSubTeambyIdSuccess = (id) => {
    return {
        type: GET_ALL_SUB_TEAMS_SUCCESS,
        payload: id
    }
}
export const getMembersByTeam = (id, params) => {
    return {
        type: GET_MEMBERSINFO_BY_TEAM,
        payload: {
            id,
            params,
        },
    };
};
export const getMembersByTeamSuccess = (payload, id) => {
    return {
        type: GET_MEMBERSINFO_BY_TEAM_SUCCESS,
        payload,
        id
    }
}
export const getAllTodos = (payload) => {
    return {
        type: GET_ALL_TODOS,
        payload: payload,
    }
};
export const getAllTodosSuccess = (payload) => {
    return {
        type: GET_ALL_TODOS_SUCCESS,
        payload: payload,
    }
};
export const getTodobyId = (id) => {
    return {
        type: GET_TODO_BY_ID,
        payload: id
    }
}
export const getTodobyIdSuccess = (id) => {
    return {
        type: GET_TODO_BY_ID_SUCCESS,
        payload: id
    }
}
export const getAllTodosByUser = (payload) => {
    return {
        type: GET_TODO_BY_USER,
        payload: payload,
    }
};
export const getAllTodosByUserSuccess = (payload) => {
    return {
        type: GET_TODO_BY_USER_SUCCESS,
        payload: payload,
    }
};
export const getAllAssignedTodos = (payload) => {
    return {
        type: GET_ASSIGNED_TODOS,
        payload: payload,
    }
};
export const getAllAssignedTodosSuccess = (payload) => {
    return {
        type: GET_ASSIGNED_TODOS_SUCCESS,
        payload: payload,
    }
};
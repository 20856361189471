import { useNavigate } from "react-router-dom";
import { Box, Card, Flex, Paragraph, Text } from "theme-ui";

export interface memberdata {
    name: string;
    projectName: string;
    imageUrl?: string;
}
const MemberProfileCard: React.FC<memberdata> = ({ name, projectName, imageUrl }) => {
    const getFirstLetter = (name: string) => (name ? name.charAt(0).toUpperCase() : '');
    const navigate = useNavigate();

    return (
        <Card sx={{
            borderRadius: '16px', padding: '14px 16px', mb: 2, boxShadow: '0px  10px 10px 5px rgba(209, 173, 138, 0.12)', cursor: 'pointer'
        }} onClick={() => {
            navigate('/my-team')
        }}>
            <Flex sx={{ alignItems: 'center', p: 3 }}>
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt={name}
                        style={{
                            width: 46,
                            height: 46,
                            borderRadius: '50%',
                            marginRight: '16px',
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            width: 46,
                            height: 46,
                            borderRadius: '50%',
                            backgroundColor: 'primary',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '16px',
                        }}
                    >
                        <Text sx={{ color: 'white', fontWeight: 'bold', fontSize: 16 }}>
                            {getFirstLetter(name)}
                        </Text>
                    </Box>
                )}
                <Box sx={{ flexGrow: 1 }}>
                    <Text sx={{ fontWeight: 'bold', fontSize: '16px', color: '#453521' }}>{name}</Text>
                    <Paragraph sx={{ fontSize: 1, color: '#C4AF95' }}>{projectName}</Paragraph>
                </Box>
                {/* <IconButton aria-label="More options">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <circle cx="12" cy="12" r="1" color="#C4AF95" />
                        <circle cx="12" cy="5" r="1" color="#C4AF95" />
                        <circle cx="12" cy="19" r="1" color="#C4AF95" />
                    </svg>
                </IconButton> */}
            </Flex>
        </Card>
    )
}

export default MemberProfileCard
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Badge, Card, Flex, Link, Text } from 'theme-ui';
import { getAllDeliveriesProjectView, getAllDeliveriesStageView } from "../../store/actions/masterData.action";
import { useSelector } from "react-redux";
import theme from "../../theme";

const DeliveryHistory = ({ Open, setOpen, selectedHighlight, actionObject, stageId }: any) => {
  const { deliveryListProject } = useSelector((state: any) => state.masterData);
  const { deliveryListStage } = useSelector((state: any) => state.masterData);
  const ProjectName = deliveryListProject?.data?.[0]?.projectId?.name || deliveryListStage?.data?.[0]?.projectId?.name;
  const dispatch = useDispatch();

  const togglePopover = () => {
    setOpen(!Open);
  };
  useEffect(() => {
    if (actionObject?.projectId) {
      dispatch(getAllDeliveriesProjectView(actionObject.projectId));
    } else if (stageId) {
      dispatch(getAllDeliveriesStageView(stageId));
    }
  }, [actionObject?.projectId, stageId, dispatch]);

  const groupDeliveries = (data: any) => {
    return data?.reduce((acc: any, delivery: any) => {
      const stageName = delivery.stageId.name;
      const stageColor = delivery.stageId.color;
      if (!acc[stageName]) {
        acc[stageName] = { color: stageColor, deliveries: [] };
      }
      acc[stageName].deliveries.push(delivery);
      return acc;
    }, {});
  };

  const groupedDeliveries = groupDeliveries(deliveryListProject?.data);
  const groupedDeliveriesStage = groupDeliveries(deliveryListStage?.data);

  const displayGroupedDeliveries = stageId ? groupedDeliveriesStage : groupedDeliveries;

  return (
    <Modal isOpen={Open && selectedHighlight === 'DeliveryHistory'} centered scrollable size='xl' toggle={togglePopover}>
      <ModalHeader toggle={togglePopover}><h4>{ProjectName}</h4></ModalHeader>
      <ModalBody>
        {displayGroupedDeliveries && Object.keys(displayGroupedDeliveries).map((stageName) => (
          <Card key={stageName} sx={{ boxShadow: "none", border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "12px 20px", position: "relative", marginBottom: '18px' }}>
            <Flex>
              <Badge sx={{ color: `${displayGroupedDeliveries[stageName].color}`, border: `1px solid ${displayGroupedDeliveries[stageName].color}`, borderRadius: 6, backgroundColor: "transparent", margin: "5px", fontSize: '20px' }} >{stageName}</Badge>

            </Flex>
            <ol style={{ marginTop: '2px' }}>
              {displayGroupedDeliveries[stageName]?.deliveries?.map((delivery: any, index: number) => (
                <li key={index} style={{ marginBottom: '10px' }}>
                  <ul style={{ listStyleType: 'none' }}>
                    {delivery?.payload?.Description?.map((desc: any, descIndex: number) => (
                      <li key={descIndex}>
                        {desc?.name && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Name:</Text> {desc?.name}</Text>}
                      </li>
                    ))}
                    <li>
                      {delivery?.payload?.deliveryDate && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Delivery Date:</Text> {delivery?.payload?.deliveryDate}</Text>}
                    </li>
                    <li>
                      {delivery?.payload?.emailLink && <Text as='li'><Text variant="SelectLabel" sx={{ lineHeight: '30px' }}>Email Link:</Text> <Link sx={{ textDecoration: 'none' }}>{delivery?.payload?.emailLink}</Link></Text>}
                    </li>
                  </ul>
                </li>
              ))}
            </ol>
          </Card>
        ))}
      </ModalBody>
    </Modal>
  );
};

export default DeliveryHistory;

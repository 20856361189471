/* eslint-disable no-unused-expressions */
import { call, put, takeLatest } from 'redux-saga/effects';
import { getTradingRatingIdSuccess, getTradingRatingSuccess, tradingRatingFail } from '../actions/Trading.Rating.Action';
import { GET_TRADING_RATING, GET_TRADING_RATING_BY_ID } from '../storeTypes';
import { getTradingRatingApi, getTradingRatingIdApi } from '../api';


function* getTradingRatingSaga(action) {
    try {
        const response = yield call(getTradingRatingApi,action?.payload);
        if (response.data.data) {
            yield put(getTradingRatingSuccess(response.data));
        }
    } catch (error) {
        yield put(tradingRatingFail(error));
    }
}


function* getTradingRatingIdSaga(action) {
    try {
        const response = yield call(getTradingRatingIdApi, action.payload);
        if (response.data.data) {
            yield put(getTradingRatingIdSuccess(response.data.data[0]));
        }
    } catch (error) {
        yield put(tradingRatingFail(error));
    }
}


export function* TradingRatingsSaga() {
    [
        yield takeLatest(GET_TRADING_RATING, getTradingRatingSaga),
        yield takeLatest(GET_TRADING_RATING_BY_ID, getTradingRatingIdSaga),
    ];
}

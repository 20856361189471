import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Divider, Text } from 'theme-ui';
import { RootState } from '../../../../@types';
import { getAllRoles } from '../../../store/actions/user.action';
import DataTable from '../../Tabels/Tabel';
import { TabelHeadingTxt } from '../../Text/TabelCommonTxt';
import plus from "../../assets/images/Icon/plus.svg";
import TableActions from './TableActions';
import queryString from 'query-string';
import { generateQueryString } from '../../../utils/GenerateQueryString';
const Role: React.FC = () => {
    const { roles, loader } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const { paginations } = useSelector((state: any) => state.pagination);
    const params = queryString.parse(window.location.search);
    const [refresh, setRefresh] = useState<boolean>(true);

    const Limit = params?.limit
    const page = params.page
    const Query = generateQueryString(['page', page || 1], ['limit', Limit || 15]);

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllRoles({ query: Query }))
    }, [])
    const handleclick = () => {
        navigate("/create-role")
    }
    const columns: ColumnDef<any>[] = [
        // {
        //     accessorKey: "id",
        //     id: "select",
        //     enableColumnFilter: false,
        //     header: ({ table }) => (
        //         <div className="d-flex justify-content-center">
        //             <IndeterminateCheckbox
        //                 {...{
        //                     checked: table.getIsAllRowsSelected(),
        //                     indeterminate: table.getIsSomeRowsSelected(),
        //                     onChange: table.getToggleAllRowsSelectedHandler(),
        //                 }}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }) => (
        //         <IndeterminateCheckbox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        // },
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((paginations?.currentPage - 1) * paginations?.limit) + row?.index + 1}</Text>
                )
            },
        },
        {
            accessorKey: "RoleName",
            header: 'Role Name',
            cell: info => info.getValue(),
        },
        {
            header: 'Description',
            accessorKey: "Description",
            id: 'action'
            //cell: info => info.getValue(),
        },
        {
            header: 'Accessor',
            accessorKey: "Accessor",
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <TableActions row={row} editTo={`/edit-role/${row?.original?._id}`}deleteMessage='Are you sure you want to delete this Role?' deleteApi={`users/deleteRole/${row?.original?._id}`}toggleRefresh={() => setRefresh(true)}/>
                )
            },
        }
    ];
    const limitString: string = Array.isArray(Limit) ? '' : Limit || '15';
    const Page: string = Array.isArray(page) ? '' : page || '1';
    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Role' Count={roles?.count} BtnTxt='Create Role' src={plus} onClick={handleclick} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                columns={columns}
                loader={loader}
                data={roles?.data || []}
                TotalLength={roles?.count || 15}
                perPageCount={parseInt(limitString)}
                currentPage={parseInt(Page)}
                onPerPageChange={(itemsPerPage) => {
                    var newp = params
                    newp.limit = itemsPerPage
                    window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                }}
                onPageChange={(value) => {
                    var newp = params
                    newp.page = value
                    window.location.href = window.location.pathname + "?" + queryString.stringify(newp)
                }} />
        </div>

    )
};

export default Role;

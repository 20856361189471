import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import { Button, Text } from 'theme-ui';
import { deleteRole, deleteUser } from '../../../store/actions/user.action';

const DeleteRole: React.FC = () => {
    const { userId } = useParams<{ userId: string }>();
    // console.log("userId",userId);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const confirmDelete = () => {
        dispatch(deleteRole(userId));
    };

    const cancelDelete = () => {
        navigate('/role')
    };

    return (
        <Modal isOpen={true} toggle={cancelDelete} centered className='w-100'>
            <ModalBody className='mb-0 pb-0'>
                <div style={{
                    width: '100%',
                    position: 'relative',
                    zIndex: 1,
                    backgroundColor: 'white',
                    border: '1px solid #black',
                    boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06);',
                    borderRadius: '4px',
                    padding: '20px 4px'
                }}>
                    <Text className=" w-100" as="p">
                        <strong>Are you sure you want to delete this Role?</strong>
                    </Text>
                </div>
            </ModalBody>
            <ModalBody className='d-flex'>
                <Button onClick={confirmDelete} sx={{ marginRight: '10px', width: '50%' }}>Yes</Button>
                <Button onClick={cancelDelete} sx={{ width: '50%' }}>No</Button>
            </ModalBody>
        </Modal >
    );
};

export default DeleteRole;

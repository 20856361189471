import { useNavigate } from 'react-router-dom';
import { Button } from 'theme-ui';

export default function ClearTableFilter({ url, ml }) {
    const navigate = useNavigate();
    return (
        <Button variant='PrimaryHeadBtn' onClick={() => navigate(url)} className='toggle-btn' sx={{ marginLeft: `${ml ? ml : '10px'}` }}>
            Clear Filter
        </Button>
    )
}

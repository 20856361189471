import { ACTION_HIGHLIGHTS_FAIL, ADD_COMMENT_ACTION_HIGHLIGHTS, ADD_DESCRIPTION_ACTION_HIGHLIGHTS, ADD_DESCRIPTION_BULK_ACTION_HIGHLIGHTS, CREATE_ACTION_HIGHLIGHTS, CREATE_ACTION_HIGHLIGHTS_SUCCESS, CREATE_BC_DONE_HIGHLIGHTS, CREATE_BC_DONE_HIGHLIGHTS_SUCCESS, DELETE_ACTION__MULTIPLE_HIGHLIGHTS, DELETE_ACTION_HIGHLIGHTS, DELETE_ACTION_HIGHLIGHTS_SUCCESS, DELETE_ACTION_MULTIPLE_HIGHLIGHTS_SUCCESS, DELETE_COMMENT_ACTION_HIGHLIGHTS, EDIT_ACTION_HIGHLIGHTS, EDIT_ACTION_HIGHLIGHTS_SUCCESS, EDIT_ACTION_PAYLOAD, EDIT_ACTION_PAYLOAD_SUCCESS, EDIT_COMMENT_ACTION_HIGHLIGHTS, GET_ACTION_BY_ID, GET_ACTION_BY_ID_SUCCESS, GET_ACTION_HIGHLIGHTS_BY_IDS, GET_ACTION_HIGHLIGHTS_BY_IDS_SUCCESS, GET_ALL_ACTION_BY_NAME, GET_ALL_ACTION_BY_NAME_SUCCESS, GET_ALL_BC_PC_REV_STAGES, GET_ALL_BC_PC_REV_STAGES_SUCCESS, GET_ALL_DELIVER_CHECKLIST, GET_ALL_DELIVER_CHECKLIST_SUCCESS, GET_ALL_TEMPLATES, GET_ALL_TEMPLATES_SUCCESS, GET_EXCEL_REPORT, GET_EXCEL_REPORT_SUCCESS, REMOVE_HIGHLIGHTER, REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW, REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW_SUCCESS, REMOVE_HIGHLIGHTER_FROM_TASKVIEW, REMOVE_HIGHLIGHTER_FROM_TASKVIEW_SUCCESS, REMOVE_HIGHLIGHTER_SUCCESS } from "../storeTypes"


export const getActionHighlighterIds = (payload, highlight) => {
  return {
    type: GET_ACTION_HIGHLIGHTS_BY_IDS,
    payload,
    highlight
  }
}

export const getActionHighlighterIdsSuccess = (payload) => {
  return {
    type: GET_ACTION_HIGHLIGHTS_BY_IDS_SUCCESS,
    payload
  }
}
export const getActionId = (actionId) => {
  return {
    type: GET_ACTION_BY_ID,
    actionId
  }
}

export const getActionIdSuccess = (payload) => {
  return {
    type: GET_ACTION_BY_ID_SUCCESS,
    payload
  }
}
export const getActionByName = (payload, highlight) => {
  return {
    type: GET_ALL_ACTION_BY_NAME,
    payload,
    highlight
  }
}

export const getActionByNameSuccess = (payload) => {
  return {
    type: GET_ALL_ACTION_BY_NAME_SUCCESS,
    payload
  }
}
export const getBcPcRevStages = (payload, highlight) => {
  return {
    type: GET_ALL_BC_PC_REV_STAGES,
    payload,
    highlight
  }
}

export const getBcPcRevStagesSuccess = (payload) => {
  return {
    type: GET_ALL_BC_PC_REV_STAGES_SUCCESS,
    payload
  }
}
export const getAllDeliverChecklist = (payload) => {
  return {
    type: GET_ALL_DELIVER_CHECKLIST,
    payload,

  }
}
export const getAllDeliverChecklistSuccess = (payload) => {
  return {
    type: GET_ALL_DELIVER_CHECKLIST_SUCCESS,
    payload,

  }
}

export const removeHighlightFromStage = (payload, stageId) => {
  return {
    type: REMOVE_HIGHLIGHTER,
    payload,
    stageId
  }
}
export const removeHighlightFromStageSuccess = (payload) => {
  return {
    type: REMOVE_HIGHLIGHTER_SUCCESS,
    payload,

  }
}
export const removeHighlightFromTask = (payload, taskId) => {
  return {
    type: REMOVE_HIGHLIGHTER_FROM_TASKVIEW,
    payload,
    taskId
  }
}
export const removeHighlightFromTaskSuccess = (payload) => {
  return {
    type: REMOVE_HIGHLIGHTER_FROM_TASKVIEW_SUCCESS,
    payload,

  }
}

export const removeHighlightFromProject = (payload, projectId) => {
  return {
    type: REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW,
    payload,
    projectId
  }
}
export const removeHighlightFromProjectSuccess = (payload) => {
  return {
    type: REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW_SUCCESS,
    payload,

  }
}
export const getAllTemplateChecklist = (payload) => {
  return {
    type: GET_ALL_TEMPLATES,
    payload,

  }
}
export const getAllTemplateChecklistSuccess = (payload) => {
  return {
    type: GET_ALL_TEMPLATES_SUCCESS,
    payload,

  }
}
export const createActionHighlight = (payload, callback) => {
  return {
    type: CREATE_ACTION_HIGHLIGHTS,
    payload,
    callback
  }
}

export const createActionHighlightSuccess = (payload) => {
  return {
    type: CREATE_ACTION_HIGHLIGHTS_SUCCESS,
    payload,
  }
}

export const editActionHighlight = (payload, id, callback) => {
  return {
    type: EDIT_ACTION_HIGHLIGHTS,
    payload,
    id,
    callback
  }
}
export const editActionPayload = (payload, id) => {
  return {
    type: EDIT_ACTION_PAYLOAD,
    payload,
    id
  }
}
export const editActionPayloadSuccess = (payload) => {
  return {
    type: EDIT_ACTION_PAYLOAD_SUCCESS,
    payload
  }
}
export const addDescriptionActionHighlight = (payload, id) => {
  return {
    type: ADD_DESCRIPTION_ACTION_HIGHLIGHTS,
    payload,
    id
  }
}
export const addDescriptionBulkActionHighlight = (payload, id) => {
  return {
    type: ADD_DESCRIPTION_BULK_ACTION_HIGHLIGHTS,
    payload,
    id
  }
}
export const editActionHighlightSuccess = (payload) => {
  return {
    type: EDIT_ACTION_HIGHLIGHTS_SUCCESS,
    payload
  }
}
export const deleteActionHighlight = (payload, id) => {
  return {
    type: DELETE_ACTION_HIGHLIGHTS,
    payload,
    id
  }
}

export const deleteActionHighlightSuccess = (payload) => {
  return {
    type: DELETE_ACTION_HIGHLIGHTS_SUCCESS,
    payload
  }
}
export const deleteMultipleActionHighlight = (payload, id) => {
  return {
    type: DELETE_ACTION__MULTIPLE_HIGHLIGHTS,
    payload,
    id
  }
}

export const deleteMultipleActionHighlightSuccess = (payload) => {
  return {
    type: DELETE_ACTION_MULTIPLE_HIGHLIGHTS_SUCCESS,
    payload
  }
}


export const actionHighlightFail = (payload) => {
  return {
    type: ACTION_HIGHLIGHTS_FAIL,
    payload
  }
}

export const addCommentActionHighlight = (payload, id) => {
  return {
    type: ADD_COMMENT_ACTION_HIGHLIGHTS,
    payload,
    id
  }
}
export const editCommentActionHighlight = (payload, id) => {
  return {
    type: EDIT_COMMENT_ACTION_HIGHLIGHTS,
    payload,
    id
  }
}
export const deleteCommentActionHighlight = (payload, id) => {
  return {
    type: DELETE_COMMENT_ACTION_HIGHLIGHTS,
    payload,
    id
  }
}

export const createBCDoneHighlight = (payload, actionId) => {
  return {
    type: CREATE_BC_DONE_HIGHLIGHTS,
    actionId,
    payload
  }
}

export const createBCDoneHighlightSuccess = (payload) => {
  return {
    type: CREATE_BC_DONE_HIGHLIGHTS_SUCCESS,
    payload
  }
}

export const getExcelReport = (payload) => {
  return {
    type: GET_EXCEL_REPORT,
    payload
  }
}
export const getExcelReportSuccess = (payload) => {
  return {
    type: GET_EXCEL_REPORT_SUCCESS,
    payload
  }
}
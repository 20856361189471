import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Divider, Text } from 'theme-ui';
import { getAllRoles, getAllUser, getAllUserWithoutPagination } from '../../../store/actions/user.action';
import DataTable from '../../Tabels/Tabel';
import { TabelHeadingTxt } from '../../Text/TabelCommonTxt';
import plus from "../../assets/images/Icon/plus.svg";
import TableActions from './TableActions';
import queryString from 'query-string';
import { getApiEndPoint } from '../../../utils/GenerateQueryString';
const Users: React.FC = () => {
    const { users, loader, roles, userData } = useSelector((state: any) => state.user);
    const { paginations } = useSelector((state: any) => state.pagination);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");

    // const Query = generateQueryString(['page', paginations?.currentPage || 1], ['limit', paginations?.limit || 15]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState<boolean>(true);
    // useEffect(() => {
    //     dispatch(getAllUser({
    //         currentPage,
    //         limit
    //     }))
    //     setRefresh(false);
    // }, [refresh, searchParams, location?.search, limit, currentPage])
    useEffect(() => {
        let apiEndPoint = getApiEndPoint({ limit, currentPage }, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])

        dispatch(getAllUser(apiEndPoint))
        setRefresh(false);
    }, [refresh, location?.search, limit, currentPage])

    useEffect(() => {
        let roleObj = { currentPage: 1, limit: 1000 }
        dispatch(getAllRoles(roleObj));
        dispatch(getAllUserWithoutPagination())
    }, [])

    const handleclick = () => {
        navigate("/create-user")
    }

    const reportingManager_ = userData?.data?.map((element: { _id: string, FirstName: string, LastName: string }, index: number) => ({ value: element._id, label: `${element.FirstName} ${element.LastName} ` }));

    // useEffect(() => {
    //     return () => {
    //         dispatch(setPagination({
    //             limit: 15,
    //             currentPage: 1,
    //         }))
    //     }
    // }, [])

    const columns = useMemo<ColumnDef<any>[]>(() => [
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((currentPage - 1) * limit) + row?.index + 1}</Text>
                )
            },
        },
        {
            accessorKey: "FirstName",
            header: 'First Name',
            cell: info => info.getValue(),
            enableColumnFilter: true,
            filterType: "textbox",
        },
        {
            header: 'Last Name',
            accessorKey: "LastName",
            id: 'action',
            cell: info => info.getValue(),
            filterType: "textbox",
        },
        {
            header: 'Email',
            accessorKey: "Email",
            cell: info => info.getValue(),
            filterType: "textbox",
        },

        {
            header: 'Role',
            accessorKey: "Role",
            cell: ({ row }) => {
                const roles = row.original.Role.map((role: any) => role.RoleName).join(', ');
                return <Text variant='Poppins16Normal25lh' sx={{ fontWeight: 400 }}>{roles}</Text>;
            },
            filterType: 'multi-select-dropdown',
            options: roles?.data?.map((val: { _id: string, RoleName: string }) => ({ value: val._id, label: val.RoleName }))

        },
        {
            header: 'Reporting Manager',
            accessorKey: "ReportingManager",
            cell: info => info.getValue(),
            enableColumnFilter: true,
            filterType: "dropdown",
            options: reportingManager_
        },
        {
            header: 'Phone Number',
            accessorKey: "PhoneNumber",
            cell: info => info.getValue(),
        },
        {
            header: 'Accessor',
            accessorKey: "Accessor",
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <TableActions row={row} editTo={`/edit-user/${row?.original?._id}`} deleteMessage='Are you sure you want to delete this user?' deleteApi={`users/deleteUser/${row?.original?._id}`} toggleRefresh={() => setRefresh(true)} />
                )
            },
        }
    ], [users?.data]);


    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Users' Count={users?.count} BtnTxt='Create User' src={plus} onClick={handleclick} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                loader={loader}
                columns={columns}
                data={users?.data || []}
                TotalLength={users?.count || 15}
                perPageCount={limit || 15}
                currentPage={paginations.currentPage}
                // onPerPageChange={(itemsPerPage) => {
                //     dispatch(setPagination({
                //         limit: itemsPerPage,
                //         currentPage: 1
                //     }));
                // }}
                // onPageChange={(value) => {
                //     dispatch(setPagination({
                //         ...paginations,
                //         ...{ currentPage: value }
                //     }))
                // }}

                onPerPageChange={(itemsPerPage) => {
                    if (searchParams?.size > 2) {
                        const parsedQuery = queryString.parse(location.search);

                        setSearchParams({
                            ...parsedQuery,
                            limit: itemsPerPage.toString(),
                        });
                    } else if (users?.count < itemsPerPage) {
                        setSearchParams({
                            currentPage: '1',
                            limit: itemsPerPage.toString(),
                        });
                    }
                    else {
                        setSearchParams({
                            currentPage: currentPage.toString(),
                            limit: itemsPerPage.toString(),
                        });
                    }

                }}
                onPageChange={(value: string) => {
                    console.log(value, "onPageChange");

                    if (searchParams?.size > 2) {
                        const parsedQuery = queryString.parse(location.search);

                        setSearchParams({
                            ...parsedQuery,
                            currentPage: value.toString(),
                        });
                    } else {
                        setSearchParams({
                            currentPage: value.toString(),
                            limit: limit.toString(),
                        });
                    }

                }}

            />
        </div>

    )
};

export default Users;

import { Divider, Text } from "theme-ui";
import { TabelHeadingTxt } from "../../../Text/TabelCommonTxt";
import plus from "../../../assets/images/Icon/plus.svg";
import DataTable from "../../../Tabels/Tabel";
import { useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TableActions from "../../Roles/TableActions";
import { setPagination } from "../../../../store/actions/Pagination.action";
import { getApiEndPoint } from "../../../../utils/GenerateQueryString";
import { getAllchecklistMasterData } from "../../../../store/actions/masterData.action";

const ChecklistMasterdata = () => {
    const navigate = useNavigate();
    const { paginations } = useSelector((state: any) => state.pagination);
    const [refresh, setRrefresh] = useState<boolean>(false);
    const { loader, checklists } = useSelector((state: any) => state.masterData);
    console.log('chk20', checklists?.data);

    const dispatch = useDispatch()
    const location = useLocation();

    useEffect(() => {
        return () => {
            dispatch(setPagination({
                limit: 15,
                currentPage: 1,
            }))
        }
    }, [])
    useEffect(() => {
        const showInActionsValue = "all"
        let apiEndPoint = getApiEndPoint(paginations, location.search, columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
        const completeURL = `${apiEndPoint}&showInActions=${showInActionsValue}`
        dispatch(getAllchecklistMasterData(completeURL))
    }, [location?.search, dispatch]);
    useEffect(() => {
        if (refresh) {
            let apiEndPoint = getApiEndPoint(paginations, "", columns?.map((val: any) => ({ accessorKey: val.accessorKey, filterType: val.filterType })) || [])
            dispatch(getAllchecklistMasterData(apiEndPoint));
            setRrefresh(false);
        }
    }, [refresh])
    const handleclick = () => {
        navigate("/create-checklist")
    }
    const columns = useMemo<ColumnDef<any>[]>(() => [

        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((paginations?.currentPage - 1) * paginations?.limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "templateName",
            header: 'Template',
            cell: info => info.getValue(),
        },
        {
            accessorKey: "checklist",
            header: 'Checklist',
            cell: ({ row }) => {

                return (
                    <Text variant='Poppins14Normal24lh' sx={{ color: '#000' }}>{row?.original?.checklistItem?.length}</Text>
                )
            }
        },
        {
            accessorKey: "showInActions",
            header: 'Checklist Category',

        },
        {
            header: 'Accessor',
            accessorKey: "Accessor",
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <TableActions row={row} editTo={`/edit-checklist/${row?.original?._id}`} deleteMessage='Are you sure you want to delete this template?' deleteApi={`checklist-templates-routes/deleteChecklist/${row?.original?._id}`} toggleRefresh={() => setRrefresh(true)} />
                )
            },
        }
    ], [checklists?.data]);
    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Checklist' Count={checklists?.count || 15} BtnTxt='Create Checklist' onClick={handleclick} src={plus} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                loader={loader}
                columns={columns}
                data={checklists?.data || []}
                TotalLength={checklists?.count || 15}
                perPageCount={paginations?.limit}
                currentPage={paginations?.currentPage}
                onPerPageChange={(itemsPerPage) => {
                    dispatch(setPagination({
                        limit: itemsPerPage,
                        currentPage: 1
                    }));
                    setRrefresh(true);
                }}
                onPageChange={(value) => {
                    dispatch(setPagination({
                        ...paginations,
                        ...{ currentPage: value }
                    }));
                    setRrefresh(true);
                }} />
        </div>
    )
}

export default ChecklistMasterdata
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge, Box, Button, Image, MenuButton, Paragraph, Spinner, Text } from "theme-ui";
import { GetTimeByStageId, getAllSubtasksOfSameTask, getHistoryByView } from "../../../store/actions/project.action";
import activity from "../../assets/images/Icon/activity.svg";
import spent from "../../assets/images/Icon/spent.svg";
import StageActions from "./StageActions";
import { useParams } from "react-router-dom";
import ThreadModalPop from "../../ThreadModalPop/ThreadModalPop";
import deleteIcon from "../../assets/images/Icon/delete.svg";
import notification1 from "../../assets/images/Notification1.svg";
import clockleft from "../../assets/images/clock-left.svg";
import { AllActivities } from "../../../utils/GenerateQueryString";
import { bucketUrl } from "../../../utils/global";
import HistoryAction from "../../project-components/HistoryAction";
import { RootState } from "../../../../@types";
import UpdateStatusToAny from "../../Actions/UpdateStatusToAny";
import { stageStatuslist } from "../../../utils/constent";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deleteStage } from "../../../store/actions/masterData.action";
import theme from "../../../theme";
import TaskView from '../../assets/images/TaskView.svg';
import PrelimTaskModal from "../TaskView/PrelimTaskModal";
import InfiniteScroll from "react-infinite-scroll-component";
import ActivityHistoryModal from "../demotable/ActivityHistoryModal";
import ActivityRevertHistory from "../demotable/ActivityRevertHistory";
const StageSideActions: React.FC<any> = ({ isTracking, elapsedTime, timeTaken, threads }) => {
    // console.log("takentime----", timeTaken);
    const { auther } = useSelector((state: RootState) => state.auth);
    const { stageId } = useParams<{ stageId: string }>();
    const { dataForTable } = useSelector((state: any) => state.masterData);
    const { historyView, spentTime, stagesView } = useSelector((state: any) => state.project);
    const [showAll, setShowAll] = useState(false);
    const [items, setItems] = useState<any[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const limit = 25;
    const is_Super_Admin = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "super admin");
    const is_Project_Manager = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "project manager");
    const is_Project_Worker = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "project worker");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [taskmodalOpen, setIsTaskModalOpen] = useState<boolean>(false);
    let actionObject: any = { projectId: stagesView?.projectId?._id, stageId: stageId }
    const projectId = stagesView?.projectId;
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('activity');
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);
    const allowRoles = auther?.Role?.map(role => role?.RoleName).some(role => role.toLocaleLowerCase() === "super admin" || role.toLocaleLowerCase() === "Region Admin");

    const handleTabClick = (tab: any) => {
        setSelectedTab(tab);
    };

    useEffect(() => {
        dispatch(getHistoryByView(`stageId=${stageId}&page=${pageNumber}&limit=${limit}`));
    }, [dispatch, pageNumber]);

    useEffect(() => {
        if (historyView?.data?.length) {
            if (pageNumber === 1) {
                setItems(historyView?.data);
            } else {
                setItems((prevItems) => [...prevItems, ...historyView?.data]);
            }

            if (historyView?.count) {
                setTotalItems(historyView?.count);
            }

            if (items?.length + historyView?.data?.length >= historyView?.count) {
                setHasMore(false);
            }
        }
    }, [historyView?.data, pageNumber, stageId]);

    const fetchMoreData = () => {
        if (!hasMore || items?.length >= totalItems) {
            return;
        }
        const nextPage = pageNumber + 1;
        setPageNumber(nextPage);
        dispatch(getHistoryByView(`stageId=${stageId}&page=${nextPage}&limit=${limit}`));
    };
    useEffect(() => {
        dispatch(GetTimeByStageId(stageId));
        // dispatch(getHistoryByView(`stageId=${stageId}`))

        // dispatch(GetLatestTimeById(taskId))
    }, [dispatch, stagesView])
    useEffect(() => {
        if (isTracking) {
            setSelectedTab('spent');
        }
    }, [isTracking]);

    const handleSubtaskTabClick = (tab: any) => {
        setSelectedTab(tab);

    };
    useEffect(() => {
        if (selectedTab === 'Other Subtasks') {
            if (stagesView?.projectId?._id && stagesView?._id) {
                const actionObject = {
                    projectId: stagesView.projectId._id,
                    stageId: stagesView._id,
                };
                dispatch(getAllSubtasksOfSameTask(actionObject));
            }
            toggleModal();
        }
    }, [selectedTab, dispatch])
    useEffect(() => {
        if (selectedTab === 'activity') {
            dispatch(getHistoryByView(`stageId=${stageId}&page=${pageNumber}&limit=${limit}`));

        }
    }, [pageNumber, limit, selectedTab])
    const toggleModal = () => {
        setIsTaskModalOpen(!taskmodalOpen);

    };
    function convertMillisecondsToHoursMinutesSeconds(milliseconds: number) {
        let seconds = Math.floor(milliseconds / 1000);
        const days = Math.floor(seconds / 86400);
        seconds %= 86400;
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;
        return { days, hours, minutes, seconds };
    }

    const handleDeleteStage = () => {
        setShowConfirmation(true);
    };

    const confirmDeleteStage = () => {
        dispatch(deleteStage(stageId));
        setShowConfirmation(false);
    };

    const cancelDeleteStage = () => {
        setShowConfirmation(false);
    };

    const toggleActivityModal = () => setModalOpen(!modalOpen);
    const toggleHistoryModal = () => setHistoryModalOpen(!historyModalOpen);

    return (
        <>
            <div className='col-md-3 p-3' style={{ maxHeight: "750px", borderLeft: `2px solid ${theme?.colors?.lightbackground}`, overflowY: "auto" }} >
                {selectedTab === 'activity' && (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <h5>Activity</h5>
                                <p className='text-muted'>All Activities</p>
                            </div>
                            <div>
                                {allowRoles && (<Image src={clockleft} alt={'History'} style={{ width: 25, marginRight: '15px', cursor: 'pointer' }} onClick={toggleHistoryModal} />)}

                                <Image src={notification1} alt={'History'} style={{ width: 30, cursor: 'pointer' }} onClick={toggleActivityModal} />
                            </div>

                        </div>
                        <div>
                            <InfiniteScroll
                                dataLength={items?.length || 0}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={<Box sx={{ textAlign: "center" }}><Spinner /></Box>}
                                height={550}
                                endMessage={<p ></p>}

                            >
                                {items?.map((history: any, historyIndex: number) => {
                                    const activityText = AllActivities(history?.action, history?.changes, history?.oldData, dataForTable?.data?.allProjectManagers, dataForTable?.data?.allTaskManagers, dataForTable?.data?.allProjectWorkers, dataForTable?.data?.allTaskWorkers, dataForTable?.data?.allHighlighters, dataForTable?.data?.allArchitects);
                                    if (!activityText) return null;
                                    return (
                                        <div key={historyIndex + 1} style={{ flexWrap: 'wrap' }}>
                                            <Paragraph style={{ gap: 2 }} variant="activityText"><strong>{history?.changes?.updatedBy || 'Someone'}</strong>
                                                {activityText}
                                            </Paragraph>
                                            <Paragraph sx={{ textAlign: "end", fontSize: "10px", color: "#D3D5D9", marginBottom: "0px" }}>{moment(history?.createdAt).format('MMM DD, LT')}</Paragraph ></div>)
                                })}
                            </InfiniteScroll>

                        </div>
                    </>
                )}
                {selectedTab === 'thread' && (
                    <div>
                        <h5>Chat section</h5>
                        <p className='text-muted'>All Chats</p>
                    </div>
                )}
                {selectedTab === 'spent' && (
                    <>
                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                            <div className="text-center">
                                <h5>Time Tracking</h5>
                                <p className='text-muted'>Spend history</p>
                            </div>
                            <MenuButton aria-label="Toggle Menu" />
                        </div>
                        <div className="text-center">
                            {isTracking !== null && <>
                                <p>Tracking Started</p>
                                <h1>{elapsedTime}</h1>
                            </>
                            }
                        </div>
                        <hr />

                        <div className="container-fluid">
                            <div className="row mb-4">
                                <div className="col-md-8"><h5>Last Actions<Badge variant='circle' mt={-3}>{spentTime?.length}</Badge></h5></div>
                                <div className="col-md-4">
                                    {spentTime.length > 5 && (
                                        <div>
                                            {showAll ? (
                                                <Button variant="spentButton" onClick={() => setShowAll(false)}>Show Less</Button>
                                            ) : (
                                                <Button variant="spentButton" onClick={() => setShowAll(true)}>Show All</Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div style={{ maxHeight: '675px', overflow: 'auto' }}>
                                {showAll ? (
                                    spentTime?.filter((time: { timeTaken: any; }) => time?.timeTaken).map((time: any, index: React.Key | null | undefined) => {
                                        const { hours, minutes, seconds, days } = convertMillisecondsToHoursMinutesSeconds(time?.timeTaken);
                                        const formattedDay = days > 0 ? `${days}d` : "";
                                        const formattedHours = hours > 0 ? `${hours}h` : "";
                                        const formattedMinutes = minutes > 0 ? `${minutes}m` : "";
                                        const formattedSeconds = seconds > 0 ? `${seconds}s` : "";

                                        return (
                                            <div className="spenttime" key={index}>
                                                <div className="conatainer-fluid">
                                                    <div className="row">
                                                        <div className="col-md-7 d-flex">
                                                            <Avatar src={`${bucketUrl}${time?.userId?.ProfileImage}`} alt="" variant="avatar" /> &nbsp;<p>{time?.userId?.FirstName} {time?.userId?.LastName}</p>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="d-flex">{formattedDay}{" "}{formattedHours}{" "}{formattedMinutes}{" "}{formattedSeconds}</p>
                                                            <p className="mt-0">{moment(time?.createdAt).format('ll')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    spentTime?.filter((time: { timeTaken: any; }) => time?.timeTaken).slice(0, 5).map((time: any, index: React.Key | null | undefined) => {
                                        const { hours, minutes, seconds, days } = convertMillisecondsToHoursMinutesSeconds(time?.timeTaken);
                                        const formattedDay = days > 0 ? `${days}d` : "";
                                        const formattedHours = hours > 0 ? `${hours}h` : "";
                                        const formattedMinutes = minutes > 0 ? `${minutes}m` : "";
                                        const formattedSeconds = seconds > 0 ? `${seconds}s` : "";

                                        return (
                                            <div className="spenttime" key={index}>
                                                <div className="conatainer-fluid">
                                                    <div className="row">
                                                        <div className="col-md-2 m-0 d-flex">
                                                            {time?.userId?.ProfileImage && <Image src={`${bucketUrl}${time?.userId?.ProfileImage}`} alt="" style={{ width: "100%", height: "45%" }} variant="avatar" />}
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="mb-0">{time?.userId?.FirstName} {time?.userId?.LastName}</p>
                                                            <p className="text-muted mt-0">{time?.description}</p>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <p className="d-flex">{formattedDay}{" "}{formattedHours}{" "}{formattedMinutes}{" "}{formattedSeconds}</p>
                                                            <p className="mt-0">{moment(time?.createdAt).format('ll')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>

                        </div>
                    </>
                )}
                {selectedTab === 'action' && (
                    <div>
                        <h5>Actions</h5>
                        <p className='text-muted'>All Actions</p>
                    </div>
                )}
            </div>
            <ActivityHistoryModal
                isOpen={modalOpen}
                toggle={toggleActivityModal}
                items={items}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                dataForTable={dataForTable}
            />
            <ActivityRevertHistory isOpen={historyModalOpen} toggle={toggleHistoryModal} items={items}
                hasMore={hasMore}
                fetchMoreData={fetchMoreData}
                dataForTable={dataForTable}
                view="Stage"
                stageId={stageId}
            />
            <div className='text-center col-md-1 p-3' style={{ borderLeft: `2px solid ${theme?.colors?.lightbackground}` }}>
                <div onClick={() => handleTabClick('activity')}>
                    <button className='btn' style={{ backgroundColor: selectedTab === 'activity' ? `${theme?.colors?.lightbackground}` : 'inherit' }}>
                        <Image src={activity} alt='' style={{ fill: selectedTab === 'activity' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                    </button>
                </div>
                <Text className='mt-2' sx={{ cursor: 'pointer' }}>Activity</Text>

                <ThreadModalPop handleTabClick={handleTabClick} selectedTab={selectedTab} phaseId={stageId} level="stage" threads={threads} />
                <div onClick={() => handleTabClick('spent')} >
                    <button className='btn ' style={{ backgroundColor: selectedTab === 'spent' ? `${theme?.colors?.lightbackground}` : 'inherit' }}>
                        <Image src={spent} alt='' style={{ fill: selectedTab === 'spent' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                    </button>
                    <p className='mt-1' style={{ cursor: 'pointer' }}>Spent History</p>
                </div>
                <HistoryAction selectedTab={selectedTab} stageId={stageId} projectId={projectId?._id} view={"project-view"} />

                <StageActions selectedTab={selectedTab} stageId={stageId} />

                <>


                    <div onClick={() => handleSubtaskTabClick('Other Subtasks')}>
                        <button className='btn toggle-btn mt-3' >
                            <Image src={TaskView} alt='' />

                        </button>
                        <Box sx={{ color: `${theme?.colors?.secondary}` }}>Other Subtasks</Box>
                    </div>

                    <PrelimTaskModal isOpen={taskmodalOpen} toggle={toggleModal} projectId={stagesView?.projectId?._id} stageId={stagesView?._id} keys={{ first: "isDone", third: "isReviewed" }}
                    />
                </>
                {is_Super_Admin && <UpdateStatusToAny actionLists={stageStatuslist} levelStatus={"stage"} updateStatusId={stageId} keyName={"stageId"} />}
                {is_Project_Worker || is_Project_Manager || is_Super_Admin ? (
                    <Box sx={{ alignItems: 'center', cursor: 'pointer' }} className="mt-5">
                        <img src={deleteIcon} alt='Delete' onClick={handleDeleteStage} style={{ height: '25px', width: '25px' }} />
                    </Box>) : null}
            </div>
            <Modal isOpen={showConfirmation} toggle={cancelDeleteStage} size="sm" centered>
                <ModalHeader toggle={cancelDeleteStage} >Confirm Deletion</ModalHeader>
                <ModalBody>
                    {` Are you sure you want to delete ${stagesView?.name} stage?`}
                </ModalBody>
                <ModalFooter>
                    <Button variant="CancelButton" sx={{ mr: 2, cursor: 'pointer' }} onClick={cancelDeleteStage}>No</Button>
                    <Button type="button" variant="SaveButton" sx={{ cursor: 'pointer' }} onClick={confirmDeleteStage}>Yes</Button>
                </ModalFooter>

            </Modal>
        </>
    )
};
export default StageSideActions;

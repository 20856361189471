import { Box, Button, Flex, Image, Paragraph, Text } from "theme-ui";
import startIcon from "../assets/images/Icon/startIcon.svg";
import stopIcon from "../assets/images/Icon/stopIcon.svg";
import trackingIcon from "../assets/images/Icon/trackingIcon.svg";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { changeElapsedTime, getTimeTracking } from "../../store/actions/project.action";
import { getTimeDifference, millisecondsToTime } from "../../utils/GenerateQueryString";
import { RootState } from "../../../@types";
import toast from "react-hot-toast";
import theme from "../../theme";
import GeneralTimerStop from "../pages/TaskView/GeneralTimerModal/GeneralTimerStop";

export default function FloatingTimeBox() {
  const { elapsedTime, timeTracking, Latesttime } = useSelector((state: any) => state.project);
  console.log('chk19', timeTracking);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { auther } = useSelector((state: RootState) => state.auth);
  const intervalRef = useRef<any>(null);
  const reminderRef = useRef<any>(null);

  const [isTracking, setIsTracking] = useState<boolean>(false);
  const [reminderShown, setReminderShown] = useState<boolean>(false);
  const { pathname } = useLocation();

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    if (timeTracking?.startTime && auther?._id === timeTracking?.userId) {
      setIsTracking(true)
    } else {
      setIsTracking(false)
      clearInterval(intervalRef.current)
      clearInterval(reminderRef.current);
    }
  }, [timeTracking]);

  useEffect(() => {
    if (isTracking) {
      intervalRef.current = setInterval(() => {
        const newFormatTime = millisecondsToTime(getTimeDifference(timeTracking?.startTime));
        dispatch(changeElapsedTime(newFormatTime));
      }, 1000);


      reminderRef.current = setInterval(() => {
        toast(
          (t) => (
            <div >
              <Paragraph variant="Poppins16Bold" >Time tracking is currently In progress!⏳</Paragraph>
              <Box className="mt-2">
                <Button
                  sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                  onClick={() => {
                    toast.dismiss(t.id);
                    setReminderShown(true);
                  }}
                >
                  OK
                </Button></Box>

            </div>
          ),
          { duration: Infinity }
        );
      }, 3600000); // 3600000 ms = 1 hour

    } else {
      setIsTracking(false);
      clearInterval(intervalRef.current);
      clearTimeout(reminderRef.current);
    }


    // Cleanup on unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (reminderRef.current) {
        clearTimeout(reminderRef.current);
      }
    };
  }, [isTracking]);

  useEffect(() => {
    dispatch(getTimeTracking())
  }, [dispatch, Latesttime])

  const goToTrackingTask = (() => {
    let goTo = `/edit-task/${timeTracking?.taskId?._id}`
    if (pathname !== goTo) {
      navigate(`edit-task/${timeTracking?.taskId?._id}`)
    }

  })

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      {!!timeTracking && (
        <Flex
          sx={{
            position: "fixed",
            bottom: timeTracking?.category === 'Task timer' ? "50px" : "7px",
            left: timeTracking?.category === 'Task timer' ? "50%" : "20%",
            borderRadius: "12px",
            padding: "1rem",
            background: `${theme.colors?.secondary}`,
            zIndex: "999900",
            color: "#fff",
            gap: "10px",
            transform: "translateX(-50%)",
            cursor: "pointer",
          }}
          onClick={() => {
            if (timeTracking?.category === 'Task timer') {
              goToTrackingTask();
            } else {
              toggleModal();
            }
          }}
        >
          <Text>
            <Image src={startIcon} />
          </Text>
          <Text>
            <Image src={stopIcon} />
          </Text>
          <Flex sx={{ alignItems: 'center', gap: "10px" }}>
            <Text>
              <Image src={trackingIcon} />
            </Text>
            <Text>{elapsedTime}{timeTracking?.category !== 'task timer' && '>'}</Text>
            <Text
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "150px",
                display: "inline-block",
                cursor: "pointer",
              }}
              title={
                timeTracking?.category === 'Task timer'
                  ? `${timeTracking?.projectId?.name} > ${timeTracking?.stageId?.name} > ${timeTracking?.taskId?.name}`
                  : timeTracking?.title
              }
            >
              {timeTracking?.category === 'Task timer'
                ? `${timeTracking?.projectId?.name} > ${timeTracking?.stageId?.name} > ${timeTracking?.taskId?.name}`.length > 10
                  ? `${`${timeTracking?.projectId?.name} > ${timeTracking?.stageId?.name} > ${timeTracking?.taskId?.name}`.slice(0, 10)}...`
                  : `${timeTracking?.projectId?.name} > ${timeTracking?.stageId?.name} > ${timeTracking?.taskId?.name}`
                : timeTracking?.title?.length > 15
                  ? `${timeTracking?.title.slice(0, 15)}...`
                  : timeTracking?.title}
            </Text>
          </Flex>
        </Flex>
      )}
      <GeneralTimerStop isOpen={modalOpen} toggle={toggleModal} projectId={timeTracking?.projectId?._id} stageId={timeTracking?.stageId?._id} />
    </>
  )
}

import { FormikProps } from 'formik';
import { withFormikValidation } from './enhancer/AddEditTradingRatingEnhancer';
import { Box, Button } from 'theme-ui';
import theme from '../../../../theme';

import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types';
import { ActionCreateByUrl, ActionUpdateById } from '../../../../store/actions/CommonAction';
import { getRatingCriteria, getTaskTypeDataWithoutPaginatation } from '../../../../store/actions/masterData.action';
import FormSelect from '../../../forms-component/globalSelect';
import { getTradingRatingId } from '../../../../store/actions/Trading.Rating.Action';
import { getApiEndPoint } from '../../../../utils/GenerateQueryString';
import { setPagination } from '../../../../store/actions/Pagination.action';

interface Option {
    label: string;
    value: string;
}
const AddEditTradingRating: React.FC<FormikProps<any>> = ({
    values,
    errors,
    touched,
    handleSubmit,
    setValues,
    validateForm,
    isValid,
    isSubmitting,
    handleBlur,
    setFieldValue
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { tradingId } = useParams();
    const { tradingRating } = useSelector((state: any) => state.trading);
    const [isNeedToValidate, setIsNeedToValidate] = useState<boolean>(false);
    const { taskTypeWithoutPaginatationData, ratingCriteria } = useSelector((state: RootState) => state?.masterData);
    const [taskTypeOptions, setTaskTypeOptions] = useState<{ value: string, label: string }[]>([])
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams({
        currentPage: "1",
        limit: "15",
    });
    const limit = parseInt(searchParams.get('limit') || "15");
    const currentPage = parseInt(searchParams.get('currentPage') || "1");

    
    useEffect((): void => {
        if (tradingId) {
            dispatch(getTradingRatingId({ id: tradingId }))
        }
    }, [tradingId])

    useEffect(() => {
        dispatch(getTaskTypeDataWithoutPaginatation())
       
    }, [dispatch])
    useEffect(() => {
        return () => {
            dispatch(setPagination({
                limit: 15,
                currentPage: 1,
            }))
        }
    }, [])
    useEffect(() => {
            let apiEndPoint = getApiEndPoint({ limit, currentPage }, location.search ) 
            dispatch(getRatingCriteria(apiEndPoint))
    }, [location?.search, limit, currentPage]);
    useEffect((): void => {
        if (taskTypeWithoutPaginatationData?.status) {
            let optionValue = taskTypeWithoutPaginatationData?.data?.map((val: any) => { return { value: val._id, label: val.name } })
            setTaskTypeOptions(optionValue);
        }
    }, [taskTypeWithoutPaginatationData])


    useEffect(() => {
        if (tradingId && tradingRating) {
            const ratingCriteria = tradingRating?.ratingCriteria?.map((val: any) => val._id)
            setValues({
                ...values,
                ratingCriteria: ratingCriteria,
                taskId: tradingRating?.taskId?._id,
            })
            setIsNeedToValidate(true);
        }
    }, [tradingRating])

    useEffect(() => {
        if (isNeedToValidate) {
            validateForm();
        }
    }, [isNeedToValidate])

    const handleArchitectData = async (): Promise<void> => {

        handleSubmit();
        if (isValid) {
            tradingId ?
                dispatch(ActionUpdateById({ Query: `trading-rating-criteria/edit-trading-criteria/${tradingId}`, obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/trading-rating")
                    }
                }))
                :
                dispatch(ActionCreateByUrl({ Query: "trading-rating-criteria/createTradingCriteria", obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/trading-rating")
                    }
                }));
        }
    }

    return (
        <div className="main-content p-5">
            <h1>{tradingId ? "Edit" : "Add"}Trading Rating Criteria</h1>
            <Box className='mt-2'>
                <FormSelect
                    label={"Task Type"}
                    value={values?.taskId}
                    placeholder={"Select Task Type"}
                    options={taskTypeOptions || []}
                    isMulti={false}
                    DefultSelect='Select Task Type'
                    touched={touched?.taskId}
                    errors={errors?.taskId}
                    onBlur={handleBlur}
                    Name='taskId'
                    onChange={(values: any) => {
                        setFieldValue("taskId", values?.value, true);
                    }}
                />
            </Box>
            <Box className='mt-2'>
                <FormSelect
                    label={"Rating Criteria"}
                    value={values?.ratingCriteria}
                    placeholder={"Select Rating Criteria"}
                    options={ratingCriteria?.data?.map((rating: any) => ({
                        label: rating?.ratingCriteria,
                        value: rating?._id
                    })) || []}
                    isMulti={true}
                    DefultSelect='Select Rating Criteria'
                    touched={touched?.ratingCriteria}
                    errors={errors?.ratingCriteria}
                    onBlur={handleBlur}
                    Name='ratingCriteria'
                    onChange={(values: any) => {
                        const rating = values?.map((v: any) => v.value)
                        // console.log(rating, "check126");
                        setFieldValue("ratingCriteria", rating);
                    }}
                />
            </Box>
            <div className='row mt-5'>
                <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' onClick={handleArchitectData} disabled={isSubmitting}>Submit</Button></div>
            </div>
        </div >
    );
}

export default withFormikValidation(AddEditTradingRating);
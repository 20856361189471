import { useFormik } from "formik";
import queryString from "query-string";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Box, Button, Flex, Grid, Spinner, Text } from "theme-ui";
import * as Yup from "yup";
import { RootState } from "../../../../../@types";
import { createStepTwo } from "../../../../store/actions/project.action";
import theme from "../../../../theme";
import { Inputform } from "../../../forms-component/InputForm";
import { customStyles, customStylesForDepartments } from './CustomSelectStyles';
const StepTwo = ({ setSteps }: any) => {
  const userData = useSelector((state: RootState) => state.project);
  const { getproject } = useSelector((state: RootState) => state.project);
  const location = useLocation();
  const parsed = queryString.parse(location.search) as any;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createprojectMasterData, loader } = useSelector((state: RootState) => state.project);
  const allStages = userData.createprojectMasterData?.allStages?.data || [];
  const allTask = userData.createprojectMasterData?.allTask?.data || [];
  const projectManagers = createprojectMasterData?.allProjectManagers || [];
  const projectWorkers = createprojectMasterData?.allProjectWorkers || [];

  // const projectManagers = userData.createprojectMasterData?.allTaskManager || [];
  // const projectWorkers = userData.createprojectMasterData?.allTaskWorkers || [];

  const allStagesOptions = allStages.map((stage) => ({ value: stage._id, label: stage.name, color: stage.color, backgroundColor: "#445adc", task: [], dueDate: '', promise: '', manager: '', worker: '', isIPR: stage?.isIPR }));
  const allTaskOptions = allTask.map((stage) => ({ value: stage._id, label: stage.name, color: stage.color, department: [] }));
  const promise = [
    { label: "Customer", value: "Customer" },
    { label: "Personal", value: "Personal" },
    { label: "Hot Promise", value: "hotPromise" },
  ];
  const projectManagerOptions = projectManagers?.map((manager) => ({
    value: manager._id,
    label: `${manager.FirstName} ${manager.LastName}`,
  }));
  const projectWorkerOptions = projectWorkers?.map((worker: any) => ({
    value: worker._id,
    label: `${worker.FirstName} ${worker.LastName}`,
  }));
  const validationSchema = Yup.object({
    stagesData: Yup.array()
      .min(1, "Select at least one stage")
      .of(
        Yup.object().shape({
          task: Yup.array().min(1, "Select at least one task"),
          dueDate: Yup.string().required("Due Date is required"),
          promise: Yup.string().required("Promise is required"),
          // worker: Yup.string().required("Worker is required"),
          // manager: Yup.string().required("Manager is required"),
        })
      ),
  });
  useEffect(() => {
    if (getproject) {
      setSteps(getproject?.stagesData?.length > 0)
    }

  }, [getproject])

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      stagesData: (getproject?.stagesData || []).flatMap((stage: any) => {
        const correspondingStage = (createprojectMasterData?.allStages?.data as { _id: string; color: string }[]).find((stagesData) => stagesData?._id === stage?.id);
        const taskColors = stage?.task?.map((task: any) => {
          const correspondingTask = (createprojectMasterData?.allTask?.data as { _id: string; color: string }[]).find((t) => t?._id === task?.id);
          return correspondingTask?.color || `${theme?.colors?.black}`;
        });
        return {
          value: stage?.id || '',
          label: stage?.name || '',
          color: correspondingStage?.color || `${theme?.colors?.black}`,
          task: stage?.task?.map((task: any, index: number) => ({
            value: task?.id || '',
            label: task?.name || '',
            color: taskColors[index] || `${theme?.colors?.black}`
          })) || [],
          isIPR: stage?.isIPR || false,
          dueDate: stage?.dueDate || '',
          promise: stage?.promise || '',
          manager: stage?.manager || null,
          worker: stage?.worker || null
        };
      }),
    },
    onSubmit: async (values, actions) => {
      const stagesData = values?.stagesData?.map((selectedStage) => ({
        id: selectedStage?.value,
        name: selectedStage?.label,
        task: selectedStage?.task?.map((selectedTask: any) => ({
          id: selectedTask?.value,
          name: selectedTask?.label,
          color: selectedTask?.color,
          isIPR: selectedStage?.isIPR,
        })),
        dueDate: selectedStage?.dueDate,
        isIPR: selectedStage?.isIPR,
        worker: selectedStage?.worker || null,
        manager: selectedStage?.manager || null,
        promise: selectedStage?.promise,
        color: selectedStage?.color
      }));


      dispatch(createStepTwo({ stagesData, page: 'step2' }, parsed?.id, formik,
        (res: any) => {
          if (res.status === 200) {
            toast.success("Step Two completed Successfully");
            navigate("/create-project?step=3&id=" + parsed?.id)
          }
        }
      ));
    },
  });
  const handleNextClick = () => {
    handleSubmit();
  };

  const { handleSubmit, setFieldValue, handleChange, handleBlur, touched, values, errors, setValues } = formik;

  const handleSelectChange = (selected: any) => {

    setValues((prevValues) => ({
      ...prevValues,
      stagesData: selected.map((value: any, index: number) => {


        return {
          ...value,
          worker: (value.worker || getproject?.projectWorker) ?? null,
          manager: (value.manager || getproject?.projectManager) ?? null,
        };
      }),
    }));
  }

  return (

    <Box className="container-fluid">
      <form onSubmit={handleSubmit}>
        <label className="form-label">Select Stages <span className="mx-1 text-danger">*</span></label>
        <Select
          isMulti
          classNamePrefix="an-simple-select"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#F7EFE5',
            },
          })}
          value={formik?.values?.stagesData}
          options={allStagesOptions}
          aria-labelledby="Select"
          onChange={(selected) => handleSelectChange(selected)}
          styles={customStylesForDepartments}
        />
        {touched.stagesData && errors.stagesData && (
          <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
            {!(typeof errors.stagesData === 'object') ? errors.stagesData : ''}
          </Text>
        )}
        {/* parent boxes */}

        {values?.stagesData?.map((selectedStage, stageIndex) => {
          return (
            <Box key={selectedStage?.value} sx={{
              boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.06);',
              borderRadius: '12px', p: 2, marginTop: 2
            }}>
              <Box key={selectedStage?.value} sx={{ boxShadow: 'initial', p: 2, borderLeft: `4px solid ${selectedStage.color}`, }}>
                <Flex sx={{ alignItems: 'center', marginBottom: 2 }}>
                  <Box sx={{ height: '100%', width: '4px', backgroundColor: selectedStage.color, marginRight: '8px' }} />
                  <Text as={'h3'} sx={{ color: selectedStage.color, marginBottom: 0 }}>
                    {selectedStage.label}
                  </Text>
                </Flex>
                <label className="form-label">Select Task <span className="mx-1 text-danger">*</span></label>
                <Select
                  classNamePrefix="an-simple-select"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#F7EFE5',
                    },
                  })}
                  isMulti
                  options={allTaskOptions}
                  value={formik?.values?.stagesData[stageIndex].task}
                  aria-labelledby="Select"
                  onChange={(selected) => {
                    const updatedStages = formik.values.stagesData.map((stage, mapIndex) =>
                      stageIndex === mapIndex ? { ...stage, task: selected } : stage);
                    setFieldValue('stagesData', updatedStages);
                  }}
                  styles={customStyles}
                />
                {touched.stagesData && errors.stagesData && (errors.stagesData[stageIndex] as { task?: string })?.task && (
                  <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                    {(errors?.stagesData?.[stageIndex] as { task?: string })?.task}
                  </Text>
                )}

                <Grid sx={{ gap: '1rem', marginTop: 3 }} columns={[1, 2, 2, 3, 4, 4]}>
                  <Box>
                    <Inputform
                      type="date"
                      label="Due Date:"
                      onBlur={handleBlur}
                      Name={`stagesData[${stageIndex}].dueDate`}
                      OnChange={handleChange}
                      values={selectedStage?.dueDate?.split('T')[0] || ''}
                      Placeholder=""
                      asterisk={true}
                      max={"9999-12-31"}
                      touched={touched?.stagesData?.[stageIndex]?.dueDate}
                      errors={(errors?.stagesData?.[stageIndex] as { dueDate?: string })?.dueDate}
                    />
                  </Box>
                  <Box>
                    <Text variant="SelectLabel">Promise: <span className="mx-1 text-danger">*</span></Text>
                    <Select
                      classNamePrefix="an-simple-select"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#F7EFE5',
                        },
                      })}
                      options={promise}
                      onBlur={handleBlur}
                      value={promise.find(option => option.value === selectedStage.promise)}
                      onChange={(selectedOption: any) => setFieldValue(`stagesData[${stageIndex}].promise`, selectedOption?.value || '')}
                    />
                    {touched.stagesData && errors.stagesData && (errors.stagesData[stageIndex] as { promise?: string })?.promise && (
                      <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                        {(errors?.stagesData?.[stageIndex] as { promise?: string })?.promise}
                      </Text>
                    )}
                  </Box>
                  <Box>
                    <Text variant="SelectLabel">Worker:</Text>
                    <Select
                      classNamePrefix="an-simple-select"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#F7EFE5',
                        },
                      })}
                      value={projectWorkerOptions.find((option: any) => option.value === selectedStage.worker)}
                      options={[{ value: '', label: 'Select Worker' }, ...projectWorkerOptions]}
                      onBlur={handleBlur}
                      onChange={(selectedOption) =>
                        formik.setFieldValue(`stagesData[${stageIndex}].worker`, selectedOption?.value || '')}
                    />
                    {touched.stagesData && errors.stagesData && (errors.stagesData[stageIndex] as { worker?: string })?.worker && (
                      <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                        {(errors?.stagesData?.[stageIndex] as { worker?: string })?.worker}
                      </Text>
                    )}
                  </Box>
                  <Box>
                    <Text variant="SelectLabel">Manager:</Text>
                    <Select
                      classNamePrefix="an-simple-select"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: '#F7EFE5',
                        },
                      })}
                      placeholder="Select Project Manager"
                      onBlur={handleBlur}
                      value={projectManagerOptions.find(option => option.value === selectedStage.manager)}
                      onChange={(selectedOption) =>
                        formik.setFieldValue(`stagesData[${stageIndex}].manager`, selectedOption?.value || '')}
                      options={[{ value: '', label: 'Select Manager' }, ...projectManagerOptions]}
                    />
                    {touched.stagesData && errors.stagesData && (errors.stagesData[stageIndex] as { manager?: string })?.manager && (
                      <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                        {(errors?.stagesData?.[stageIndex] as { manager?: string })?.manager}
                      </Text>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          )
        })}
      </form>
      <Flex sx={{ justifyContent: "end", paddingBottom: '30px' }} className="mt-4">
        <Button disabled={loader} sx={{ background: theme.colors?.primary, padding: '12px 18px', width: "25%" }} onClick={handleNextClick}> {loader ? <Spinner size={30} sx={{ color: "#fff" }} /> : "Next"}</Button>
      </Flex>
    </Box>
  );
};

export default StepTwo;

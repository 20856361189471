import { SyntheticEvent } from "react";
import { Box, Checkbox, Grid, Label, Text, ThemeUIStyleObject } from "theme-ui";

interface CheckBoxProps2 {
    title: String;
    className?: string;
    name?: string;
    values?: Array<string> | undefined;
    columns?: Array<number> | number;
    Option: Array<any> | undefined;
    sx?: ThemeUIStyleObject;
    my?: Array<number> | number;
    gap?: Array<number> | number;
    onChange?: (event: boolean | any, item: any) => void;
}
export const CheckBox3: React.FC<CheckBoxProps2> = ({
    Option,
    columns,
    onChange,
    className,
    name,
    gap
}) => {
    return (
        <Box className={className} my={5} >
            <Grid my={[4, 4, 3, 3, 4, 5]} gap={gap} columns={columns}>
                {Option?.map((itm: any, index: number) => {
                    return (
                        <Label key={index}>
                            <Checkbox
                                name={name}
                                multiple={true}
                                onChange={(e: SyntheticEvent | any) => {
                                    // console.log('eterere', e?.target?.checked);
                                    // taskMapping

                                    if (onChange) {
                                        onChange(e?.target?.checked, itm);
                                    }
                                }}
                                defaultChecked={itm?.default}
                            />
                            <Text sx={{ textAlign: "left" }}>{itm?.sopItem}</Text>
                            {/* <Text variant='Raleway18Bold148lh' sx={{ fontWeight: "600", textAlign: "left" }}>{itm?.sopItem}</Text> */}
                        </Label>
                    )
                })}
            </Grid>
        </Box>
    );
};

import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from 'yup';
import { Box, Flex, Image, Input, Label, Radio, Spinner } from "theme-ui";
import { Inputform } from "../../../forms-component/InputForm";
import attchment from '../../../assets/images/Icon/attachments.svg';
import sendbutton from '../../../assets/images/Icon/send.svg';
import { Overlay, Popover } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { mediaUpload } from "../../../../store/actions/media.action";
import toast from "react-hot-toast";
import { createChatMessage } from "../../../../store/actions/chatAction";


export default function ChatFooter({ typeOfMassage }: any) {
  const { phaseId, threadId } = useParams();
  const { getproject } = useSelector((state: any) => state.project);
  const { uploadedMedia } = useSelector((state: any) => state.media);
  const { loader, taskCreateLoader } = useSelector((state: any) => state.chats);
  const dispatch = useDispatch()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const popoverRef = useRef<HTMLDivElement>(null);
  const [target, setTarget] = useState(null);

  const togglePopover = (event: any) => {
    setIsPopoverOpen(!isPopoverOpen);
    setTarget(event.target);
  };

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
  //     setIsPopoverOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleClickOutside = (event: any) => {
    if (!event.target.closest('.popover-content')) {

      if (!event.target.closest('.toggle-btn')) {
        setIsPopoverOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formik = useFormik(
    {
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        message: Yup.string().required('Message is required'),
      }),
      initialValues: {
        message: "",
        acknowledgementRequired: false,
        type: typeOfMassage,
        projectName: getproject?._id,
        folderNumber: getproject?.folderNumber,
        attachment: "",
        thread: threadId,
        isAcknowledged: false,
      },
      onSubmit: (value: any) => {
        const formData = new FormData();
        const { attachment } = value
        formData.append('message', value.message);
        formData.append('acknowledgementRequired', value?.acknowledgementRequired);
        formData.append('type', value.type);
        formData.append('projectName', value.projectName);
        formData.append('folderNumber', value.folderNumber);

        if (attachment) {
          if (typeof attachment === "object") {
            formData.append('attachment', attachment);
          } else {
            formData.append('attachment', value?.attachment);
          }
        }
        dispatch(createChatMessage(value));
        setIsPopoverOpen(!isPopoverOpen)
        formik.resetForm()
      }
    }
  );
  const { handleSubmit, setFieldValue, handleBlur, touched, values, errors } = formik;
  const handleAttechmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files as FileList);
    if (files.length) {
      setUploadedFiles(files);
      files.forEach((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'chat');
        formData.append('uploadType', 'File');
        dispatch(mediaUpload(formData));
      });
      toast.success('Files uploaded successfully');
    } else {
      console.warn('No files found!');
    }
  };

  useEffect(() => {
    if (localStorage.getItem('acknowledgementRequired') === "true") {
      setFieldValue("acknowledgementRequired", true);
    } else {
      setFieldValue("acknowledgementRequired", false);
    }
  }, [])
  return (
    <form onSubmit={handleSubmit}>
      <Flex ref={popoverRef} sx={{ alignItems: "center" }}>
        <Box sx={{ width: "100%" }}>
          <Inputform
            label={""}
            Placeholder={"Type your message..."}
            Name={"message"}
            Title_sx={{ color: "grey3" }}
            touched={touched?.message}
            errors={errors?.message}
            values={values.message}
            onBlur={handleBlur}
            autoFocus={true}
            OnChange={(event: any) => {
              const newName = event.target.value;
              setFieldValue("message", newName, true);
            }}
          />
        </Box>
        <Input type='file' id="uploadFile" className='d-none' onChange={(e: any) => handleAttechmentChange(e)} multiple />
        <Label htmlFor='uploadFile' sx={{ width: "auto", mx: "5px", cursor: "pointer" }}>
          <Image src={attchment} />
        </Label>
        <Overlay show={isPopoverOpen}
          target={target} placement="top">
          <Popover id="popover-basic" className="p-2 gap-2 d-flex flex-column popover-content" style={{ maxWidth: 'max-content' }}>
            <Label>Select any option</Label>
            <Label sx={{ padding: "12px 8px", cursor: "pointer", borderRadius: "8px", '&:hover': { background: "#FFFBF5" } }}>
              <Radio
                name='acknowledgementRequired'
                // value={`${values.acknowledgementRequired}`}
                checked={localStorage.getItem('acknowledgementRequired') === "true" ? true : false}
                onClick={(e: any) => {
                  setFieldValue("acknowledgementRequired", true);
                  setIsPopoverOpen(!isPopoverOpen)
                  localStorage.setItem("acknowledgementRequired", "true")
                  handleSubmit()
                }}
              />
              Send with Acknowledgement
            </Label>
            <Label sx={{ padding: "12px 8px", cursor: "pointer", borderRadius: "8px", '&:hover': { background: "#FFFBF5" } }}>
              <Radio
                name='acknowledgementRequired'
                // value={`${values.acknowledgementRequired}`}
                checked={localStorage.getItem('acknowledgementRequired') === "false" ? true : false}
                onClick={(e: any) => {
                  setFieldValue("acknowledgementRequired", false);
                  setIsPopoverOpen(!isPopoverOpen)
                  localStorage.setItem("acknowledgementRequired", "false")
                  handleSubmit()
                }}
              // onChange={(e: any) => {
              //   setFieldValue("acknowledgementRequired", false);
              //   setIsPopoverOpen(!isPopoverOpen)
              //   localStorage.setItem("acknowledgementRequired", "false")
              //   handleSubmit()
              // }}
              />
              Send without Acknowledgement
            </Label>
          </Popover>
        </Overlay>
        {!taskCreateLoader ? <Image src={sendbutton} className="toggle-btn" style={{ width: '24px', height: '24px' }} onClick={togglePopover} /> : <Spinner />}
      </Flex>
    </form>
  )
}
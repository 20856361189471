import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Button } from 'theme-ui';
import dropdownIcon from "../assets/images/Icon/dropdownicon.svg";
import dot from "../assets/images/Icon/dot.svg";
import queryString from 'query-string';

// new

interface DropDownURLProps {
    customDropdownProps?: any;
    name?: string;
    url?: string
}

export const DropDownURL = ({ customDropdownProps }: DropDownURLProps) => {
    const location = useLocation();
    const currentUrl = window.location.pathname.split('/')[1];
    const { isPopoverOpen, togglePopover, setIsPopoverOpen, urlArray } = customDropdownProps
    const [selectedValue, setSelectedValue] = useState(localStorage.getItem(currentUrl) || "Default");
    const popoverRef = useRef<HTMLDivElement>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const parsedQuery = queryString.parse(location.search);

    const addQueryStr = (str: string, dropValue: string) => {
        if (dropValue === "Default") {
            setSearchParams(str)
        } else {
            const parsedQuerys = queryString.parse(str);
            let newUrl: any = {};
            if (parsedQuerys.status) {

                newUrl = { ...parsedQuerys, status: parsedQuerys?.status, stat: "" }
            } else {
                const { status, ...restparsedQuery } = parsedQuery;
                newUrl = { ...parsedQuerys, ...restparsedQuery, stat: parsedQuerys?.stat, sortField: parsedQuerys?.sortField }
            }
            const queryStrings = Object.keys(newUrl)
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(`${newUrl[key]}`)}`)
                .join('&');
            setSearchParams(queryStrings)
        }
        setSelectedValue(dropValue);
        localStorage.setItem(currentUrl.toString(), dropValue.toString());
        setIsPopoverOpen(false);
    }


    const handleClickOutside = (event: any) => {
        if (!event.target.closest('.popover-content')) {

            if (!event.target.closest('.toggle-btn')) {
                setIsPopoverOpen(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        const urlLocation = window.location.href;
        if (currentUrl.toString() === "stage-view") {
            if (urlLocation.split("/").pop() === "stage-view") {
                localStorage.setItem(currentUrl.toString(), "Default");
                setSelectedValue("Default");
            } else if (urlLocation.includes("stat=CustomOrder1")) {
                localStorage.setItem(currentUrl.toString(), "CustomOrder1");
                setSelectedValue("CustomOrder1");
            } else if (urlLocation.includes("stat=CustomOrder2")) {
                localStorage.setItem(currentUrl.toString(), "CustomOrder2");
                setSelectedValue("CustomOrder2");
            } else if (urlLocation.includes("status=Delivered")) {
                localStorage.setItem(currentUrl.toString(), "Delivered");
                setSelectedValue("Delivered");
            }
            else if (urlLocation.includes("status=Ready To Deliver")) {
                localStorage.setItem(currentUrl.toString(), "Ready To Deliver");
                setSelectedValue("Ready To Deliver");
            }
            else if (urlLocation.includes("status=Draft")) {
                localStorage.setItem(currentUrl.toString(), "Draft");
                setSelectedValue("Draft");
            }
        } if (currentUrl.toString() === "stage-view-two") {
            if (urlLocation.split("/").pop() === "stage-view-two") {
                localStorage.setItem(currentUrl.toString(), "Default");
                setSelectedValue("Default");
            } else if (urlLocation.includes("stat=CustomOrder1")) {
                localStorage.setItem(currentUrl.toString(), "CustomOrder1");
                setSelectedValue("CustomOrder1");
            } else if (urlLocation.includes("stat=CustomOrder2")) {
                localStorage.setItem(currentUrl.toString(), "CustomOrder2");
                setSelectedValue("CustomOrder2");
            } else if (urlLocation.includes("status=Delivered")) {
                localStorage.setItem(currentUrl.toString(), "Delivered");
                setSelectedValue("Delivered");
            }
            else if (urlLocation.includes("status=Ready To Deliver")) {
                localStorage.setItem(currentUrl.toString(), "Ready To Deliver");
                setSelectedValue("Ready To Deliver");
            }
            else if (urlLocation.includes("status=Draft")) {
                localStorage.setItem(currentUrl.toString(), "Draft");
                setSelectedValue("Draft");
            }
        }
        else if (urlLocation.split("/").pop() === "task-view") {
            if (urlLocation.split("/").pop() === "task-view") {
                localStorage.setItem(currentUrl.toString(), "Default");
                setSelectedValue("Default");
            } else if (urlLocation.includes("status=Delivered")) {
                localStorage.setItem(currentUrl.toString(), "Delivered");
                setSelectedValue("Delivered");
            } else if (urlLocation.includes("status=Ready To Deliver")) {
                localStorage.setItem(currentUrl.toString(), "Ready To Deliver");
                setSelectedValue("Ready To Deliver");
            }
            else if (urlLocation.includes("status=Draft")) {
                localStorage.setItem(currentUrl.toString(), "Draft");
                setSelectedValue("Draft");
            }

        } else if (currentUrl.toString() === "project-view") {
            if (urlLocation.split("/").pop() === "project-view") {
                localStorage.setItem(currentUrl.toString(), "Default");
                setSelectedValue("Default");
            } else if (urlLocation.includes("status=OnHold")) {
                localStorage.setItem(currentUrl.toString(), "OnHold");
                setSelectedValue("OnHold");
            } else if (urlLocation.includes("status=Delivered")) {
                localStorage.setItem(currentUrl.toString(), "Delivered");
                setSelectedValue("Delivered");
            } else if (urlLocation.includes("status=Ready To Deliver")) {
                localStorage.setItem(currentUrl.toString(), "Ready To Deliver");
                setSelectedValue("Ready To Deliver");
            }
            else if (urlLocation.includes("status=Draft")) {
                localStorage.setItem(currentUrl.toString(), "Draft");
                setSelectedValue("Draft");
            }
        }

    }, [location.search])

    return (
        <>
            {/* <div ref={popoverRef} className='d-flex g-4 position-relative ' > */}
            <Button variant='PrimaryHeadBtn' onClick={togglePopover} className='toggle-btn' sx={{ marginLeft: "60px" }}>
                {selectedValue}
                <img src={dropdownIcon} style={{ marginLeft: "10px" }} />
            </Button>
            {isPopoverOpen && (
                <div className="popover-content" style={{ position: 'fixed', zIndex: 99999999 }}>
                    <ul className="headermenu" onClick={togglePopover}>
                        {urlArray.map((element: DropDownURLProps, index: number) => {
                            let { name, url } = element
                            return (
                                <li key={index} className={`${selectedValue === name ? "active-dropdown" : ""}`}>
                                    <p onClick={() => addQueryStr(url || "", name || "")} className="DashboardSide custom-des-a" >
                                        <img className='mt-3' src={dot} />
                                        {name}
                                    </p>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
            {/* </div> */}

        </>
    );
};

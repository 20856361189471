import { ErrorMessage, Field, FormikProps } from 'formik';
import { withFormikValidation } from './enhancer/AddEditTaskSowEnhancer';
import { Button, Checkbox, Label } from 'theme-ui';
import theme from '../../../../theme';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTaskSowDataById, getTaskTypeDataWithoutPaginatation } from '../../../../store/actions/masterData.action';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../@types';
import { ActionCreateByUrl, ActionUpdateById } from '../../../../store/actions/CommonAction';
import FormSelect from '../../../forms-component/globalSelect';


const AddEditStages: React.FC<FormikProps<any>> = ({
    values,
    errors,
    touched,
    handleSubmit,
    setValues,
    validateForm,
    isValid,
    isSubmitting,
    handleBlur,
    setFieldValue
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { taskSowId } = useParams();
    const { individualTaskSow, taskTypeWithoutPaginatationData } = useSelector((state: RootState) => state?.masterData);
    const [isNeedToValidate, setIsNeedToValidate] = useState<boolean>(false);
    const [taskTypeOptions, setTaskTypeOptions] = useState<{ value: string, label: string }[]>([])
    // console.log(individualTaskSow, "check31");

    useEffect((): void => {
        dispatch(getTaskTypeDataWithoutPaginatation())
        if (taskSowId) {
            dispatch(getTaskSowDataById({ id: taskSowId }))
        }
    }, [taskSowId])
    useEffect((): void => {
        // console.log(taskTypeWithoutPaginatationData, "check39");
        if (taskTypeWithoutPaginatationData?.status) {
            let optionValue = taskTypeWithoutPaginatationData?.data?.map((val: any) => { return { value: val._id, label: val.name } })
            setTaskTypeOptions(optionValue);
        }
    }, [taskTypeWithoutPaginatationData])
    useEffect(() => {
        if (taskSowId && individualTaskSow?.status) {
            setValues({
                ...values,
                taskName: individualTaskSow?.data?.taskName,
                taskType: individualTaskSow?.data?.taskType,
                sopItem: individualTaskSow?.data?.sopItem,
                default: individualTaskSow?.data?.default,
            })
            setIsNeedToValidate(true);
        }
    }, [individualTaskSow?.data])
    useEffect(() => {
        if (isNeedToValidate) {
            validateForm();
        }
    }, [isNeedToValidate])
    // console.log(taskTypeOptions, "check63");
    const handleArchitectData = async (): Promise<void> => {
        handleSubmit();
        // console.log(isValid, "check68", errors, values);
        if (isValid) {
            taskSowId ?
                dispatch(ActionUpdateById({ Query: `task-sow/edit-task-sow/${taskSowId}`, obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/task-sow")
                    }
                }))
                :
                dispatch(ActionCreateByUrl({ Query: "task-sow/create-task-sow", obj: values }, (res: any) => {
                    if (res.status) {
                        navigate("/task-sow")
                    }
                }));
        }
    }
    // console.log(values, "check83");

    return (
        <div className="main-content p-5">
            <h1>{taskSowId ? "Edit" : "Add"} Task Sow</h1>
            <div className="row">
                <div className="col-md-6 mt-4">
                    <Label htmlFor="taskName" className={`form-label mt-1 ${errors.taskName && touched.taskName ? 'is-invalid' : ''}`}>Task Name*</Label>
                    <Field type="text" id="taskName" name="taskName" className="form-control" placeholder="Task Name" />
                    <ErrorMessage name="taskName" component="div" className="text-danger" />
                </div>
                <div className="col-md-6 mt-3">
                    <Label htmlFor="sopItem" className={`form-label mt-1 ${errors.sopItem && touched.sopItem ? 'is-invalid' : ''}`}>Sop Item*</Label>
                    <Field type="text" id="sopItem" name="sopItem" className="form-control" placeholder="Sop Item" />
                    <ErrorMessage name="sopItem" component="div" className="text-danger" />
                </div>
                <div className="col-md-6 mt-3">
                    <Label htmlFor="taskType" className={`form-label mt-1 ${errors.taskType && touched.taskType ? 'is-invalid' : ''}`}>Task Type*</Label>
                    <FormSelect
                        value={values?.taskType}
                        placeholder={"Select Task Type"}
                        options={taskTypeOptions || []}
                        isMulti={false}
                        DefultSelect='Select Task Type'
                        touched={touched?.taskType}
                        errors={errors?.taskType}
                        onBlur={handleBlur}
                        Name='taskType'
                        onChange={(values: any) => {
                            // console.log(values, "check100")
                            setFieldValue("taskType", values?.value, true);
                            // setValues({ ...values, taskType: values?.value })
                        }}
                    />
                </div>
                <div className="col-md-6 mt-3">
                    <Label htmlFor='default' className={`form-label mt-1 ${errors.default && touched.default ? 'is-invalid' : ''}`}>
                        Default*  <Checkbox name='default' id='default' checked={values.default} onChange={(e) => {
                            if (e.target.checked) {
                                setFieldValue("default", true, true);
                            } else {
                                setFieldValue("default", false);
                            }
                        }} />
                    </Label>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-md-4'></div>
                <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={() => navigate(-1)}> Cancel </Button></div>
                <div className='col-md-4'><Button sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} type='submit' onClick={handleArchitectData} disabled={isSubmitting}>Submit</Button></div>
            </div>
        </div >
    );
}

export default withFormikValidation(AddEditStages);
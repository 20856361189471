export const STORE_LOGIN = "STORE_LOGIN";
export const STORE_LOGIN_SUCCESS = "STORE_LOGIN_SUCCESS";
export const GET_EMAIL_VERIFY = "GET_EMAIL_VERIFY";
export const EMAIL_VERIFY_SUCCESS = "EMAIL_VERIFY_SUCCESS";
export const EMAIL_VERIFY_FAILURE = "EMAIL_VERIFY_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD__FAILURE = "FORGOT_PASSWORD__FAILURE";

export const STORE_LOGIN_FAILURE = "STORE_LOGIN_FAILURE";
export const GET_USER_BY_TOKEN = "GET_USER_BY_TOKEN";
export const GET_USER_BY_TOKEN_SUCCESS = "GET_USER_BY_TOKEN_SUCCESS";

// user
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCESS = "GET_ALL_USERS_SUCESS";
export const GET_ALL_USERS_WITHOUT_PAGINATION = "GET_ALL_USERS_WITHOUT_PAGINATION";
export const GET_ALL_USERS_WITHOUT_PAGINATION_SUCESS = "GET_ALL_USERS_WITHOUT_PAGINATION_SUCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER = "EDIT_USER";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const CREATE_USER_SUCCESS = " CREATE_USER_SUCCESS";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";

//teams
export const GET_ALL_TEAMS = "GET_ALL_TEAMS";
export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS";
export const GET_ALL_SUB_TEAMS = "GET_ALL_SUB_TEAMS";
export const GET_ALL_SUB_TEAMS_SUCCESS = "GET_ALL_SUB_TEAMS_SUCCESS";
export const GET_TEAMS_BY_USER = "GET_TEAMS_BY_USER";
export const GET_TEAMS_BY_USER_SUCCESS = "GET_TEAMS_BY_USER_SUCCESS";
export const GET_ALL_UNASSIGNED_TEAMS = "GET_ALL_UNASSIGNED_TEAMS";
export const GET_ALL_UNASSIGNED_TEAMS_SUCCESS = "GET_ALL_UNASSIGNED_TEAMS_SUCCESS";
export const GET_TEAMS_BY_USER_NO_PAGINATION = "GET_TEAMS_BY_USER_NO_PAGINATION";
export const GET_TEAMS_BY_USER_NO_PAGINATION_SUCCESS = "GET_TEAMS_BY_USER_NO_PAGINATION_SUCCESS";
export const GET_TEAMS_BY_MEMBERS = "GET_TEAMS_BY_MEMBERS";
export const GET_TEAMS_BY_MEMBER_SUCCESS = "GET_TEAMS_BY_MEMBER_SUCCESS";
export const GET_TEAMS_BY_MEMBERS_NO_PAGINATION = "GET_TEAMS_BY_MEMBERS_NO_PAGINATION";
export const GET_TEAMS_BY_MEMBER_NO_PAGINATION_SUCCESS = "GET_TEAMS_BY_MEMBER_NO_PAGINATION_SUCCESS";
export const CREATE_TEAM = "CREATE_TEAM";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const GET_TEAM_BY_ID = "GET_TEAM_BY_ID";
export const GET_TEAM_BY_ID_SUCCESS = "GET_TEAM_BY_ID_SUCCESS";
export const GET_MEMBERSINFO_BY_TEAM = "GET_MEMBERSINFO_BY_TEAM";
export const GET_MEMBERSINFO_BY_TEAM_SUCCESS = "GET_MEMBERSINFO_BY_TEAM_SUCCESS";

//Todo
export const GET_ALL_TODOS = "GET_ALL_TODOS";
export const GET_ALL_TODOS_SUCCESS = "GET_ALL_TODOS_SUCCESS";
export const GET_TODO_BY_ID = "GET_TODO_BY_ID";
export const GET_TODO_BY_ID_SUCCESS = "GET_TODO_BY_ID_SUCCESS";
export const GET_TODO_BY_USER = "GET_TODO_BY_USER";
export const GET_TODO_BY_USER_SUCCESS = "GET_TODO_BY_USER_SUCCESS";
export const GET_ASSIGNED_TODOS = "GET_ASSIGNED_TODOS";
export const GET_ASSIGNED_TODOS_SUCCESS = "GET_ASSIGNED_TODOS_SUCCESS";
// role
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE = "EDIT_ROLE"
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE = "DELETE_ROLE"
export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = " CREATE_ROLE_SUCCESS";
export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS"

//create project
export const GET_ALL_PROJECT_MASTERDATA = "GET_ALL_PROJECT_MASTERDATA";
export const GET_ALL_PROJECT_MASTERDATA_SUCCESS = "GET_ALL_PROJECT_MASTERDATA_SUCCESS";
export const GET_ALL_BLUEPRINT = "GET_ALL_BLUEPRINT";
export const GET_ALL_PROJECT = "GET_ALL_PROJECT"
export const UPDATE_PROJECT_ROW = "UPDATE_PROJECT_ROW"
export const UPDATE_PROJECT_ATTACHMENT = "UPDATE_PROJECT_ATTACHMENT"
export const UPDATE_STAGE_ROW = "UPDATE_STAGE_ROW"
export const UPDATE_STAGE_ATTACHMENT = "UPDATE_STAGE_ATTACHMENT"
export const UPDATE_FRONTAPP_ROW = "UPDATE_FRONTAPP_ROW"
export const UPDATE_TASK_ROW = "UPDATE_TASK_ROW"
export const CHANGE_ESTIMATION_TIME = "CHANGE_ESTIMATION_TIME"
export const UPDATE_TASK_ATTACHMENT = "UPDATE_TASK_ATTACHMENT"
export const GET_ALL_PROJECT_SUCCESS = "GET_ALL_PROJECT_SUCCESS"
export const GET_ALL_BLUEPRINT_SUCCESS = "GET_ALL_BLUEPRINT_SUCCESS"
export const CREATE_PROJECT_FAIL = "CREATE_PROJECT_FAIL"
export const CREATE_PROJECT_ONE = "CREATE_PROJECT_ONE"
export const CREATE_PROJECT_ONE_SUCCESS = "CREATE_PROJECT_ONE_SUCCESS"
export const CREATE_PROJECT_TWO = "CREATE_PROJECT_TWO"
export const CREATE_PROJECT_TWO_SUCCESS = "CREATE_PROJECT_TWO_SUCCESS"
export const GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID"
export const GET_PROJECT_BY_ID_SUCCESS = "GET_PROJECT_BY_ID_SUCCESS"
export const GET_SOW_CHECKLIST = "GET_SOW_CHECKLIST"
export const GET_SOW_CHECKLIST_SUCCESS = "GET_SOW_CHECKLIST_SUCCESS"
export const GET_SOW_FIELDS = "GET_SOW_FIELDS"
export const GET_SOW_FIELDS_SUCCESS = "GET_SOW_FIELDS_SUCCESS"
export const EDIT_PROJECT_BY_ID = "EDIT_PROJECT_BY_ID"
export const EDIT_PROJECT_BY_ID_SUCCESS = "EDIT_PROJECT_BY_ID_SUCCESS"
export const EDIT_STAGE_BY_ID = "EDIT_STAGE_BY_ID"
export const EDIT_STAGE_BY_ID_SUCCESS = "EDIT_STAGE_BY_ID_SUCCESS"
export const EDIT_TASK_BY_ID = "EDIT_TASK_BY_ID"
export const EDIT_TASK_BY_ID_SUCCESS = "EDIT_TASK_BY_ID_SUCCESS"
export const DELETE_TASK = "DELETE_TASK"
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS"
export const DELETE_MULTIPLE_TASK = "DELETE_MULTIPLE_TASK"
export const DELETE_MULTIPLE_TASK_SUCCESS = "DELETE_MULTIPLE_TASK_SUCCESS"
export const DELETE_STAGE = "DELETE_STAGE"
export const DELETE_STAGE_SUCCESS = "DELETE_STAGE_SUCCESS"

export const ADD_TASK_BY_ID = "ADD_TASK_BY_ID"
export const ADD_TASK_BY_ID_SUCCESS = "ADD_TASK_BY_ID_SUCCESS"
export const GET_STAGES_BY_ID = "GET_STAGES_BY_ID"
export const GET_STAGES_BY_ID_SUCCESS = "GET_STAGES_BY_ID_SUCCESS"
export const GET_TASK_BY_ID = "GET_TASK_BY_ID"
export const GET_TASK_BY_ID_SUCCESS = "GET_TASK_BY_ID_SUCCESS"
export const GET_TASK_BY_STAGE_ID = "GET_TASK_BY_STAGE_ID"
export const GET_TASK_BY_STAGE_ID_SUCCESS = "GET_TASK_BY_STAGE_ID_SUCCESS"
export const GET_ALL_TASK_BY_STAGE_ID = "GET_ALL_TASK_BY_STAGE_ID"
export const GET_ALL_TASK_BY_STAGE = "GET_ALL_TASK_BY_STAGE"
export const GET_ALL_TASK_BY_STAGE_ID_SUCCESS = "GET_ALL_TASK_BY_STAGE_ID_SUCCESS"
export const GET_TASK_BY_PROJECT_ID = "GET_TASK_BY_PROJECT_ID"
export const GET_TASK_BY_PROJECT_ID_SUCCESS = "GET_TASK_BY_PROJECT_ID_SUCCESS"
export const GET_STAGE_BY_PROJECT_ID = "GET_STAGE_BY_PROJECT_ID"
export const GET_STAGE_BY_PROJECT_ID_SUCCESS = "GET_STAGE_BY_PROJECT_ID_SUCCESS"
export const SET_STAGE_BY_UPDATE_CUSTOM_ORDER = "SET_STAGE_BY_UPDATE_CUSTOM_ORDER"
export const SET_STAGE_BY_UPDATE_CUSTOM_ORDER_SUCCESS = "SET_STAGE_BY_UPDATE_CUSTOM_ORDER_SUCCESS"
export const GET_ALL_DELIVERIES_PROJECTVIEW = 'GET_ALL_DELIVERIES_PROJECTVIEW'
export const GET_ALL_DELIVERIES_PROJECTVIEW_SUCCESS = 'GET_ALL_DELIVERIES_PROJECTVIEW_SUCCESS'
export const GET_ALL_DELIVERIES_STAGEVIEW = 'GET_ALL_DELIVERIES_STAGEVIEW'
export const GET_ALL_DELIVERIES_STAGEVIEW_SUCCESS = 'GET_ALL_DELIVERIES_STAGEVIEW_SUCCESS'
export const GET_ALL_BCPC_HISTORY = 'GET_ALL_BCPC_HISTORY'
export const GET_ALL_BCPC_HISTORY_SUCCESS = 'GET_ALL_BCPC_HISTORY_SUCCESS'
// Set Pagination
export const SET_PAGINATION = "SET_PAGINATION";
export const ELAPSEDTIME = "ELAPSEDTIME";
export const GET_TIME_SUMMERY = "GET_TIME_SUMMERY";
export const GET_STAGE_TIME_SUMMERY = "GET_STAGE_TIME_SUMMERY";
export const GET_TIME_SUMMERY_SUCCESS = "GET_TIME_SUMMERY_SUCCESS";
export const GET_TIME_TRACKING = "GET_TIME_TRACKING";
export const GET_TIME_TRACKING_SUCCESS = "GET_TIME_TRACKING_SUCCESS";
export const GET_GENERAL_TIME_TRACKING = "GET_GENERAL_TIME_TRACKING";
export const GET_GENERAL_TIME_TRACKING_SUCCESS = "GET_GENERAL_TIME_TRACKING_SUCCESS";

// GET ALL MASTER DATA LSITING : -

export const GET_ALL_PROJECT_NO_PAGINATION = "GET_ALL_PROJECT_NO_PAGINATION"
export const GET_ALL_PROJECT_NO_PAGINATION_SUCCESS = "GET_ALL_PROJECT_NO_PAGINATION_SUCCESS"

export const GET_ALL_MASTERDATA_FOR_TABLE = "GET_ALL_MASTERDATA_FOR_TABLE";
export const GET_ALL_MASTERDATA_FOR_TABLE_SUCCESS = "GET_ALL_MASTERDATA_FOR_TABLE_SUCCESS";

export const GET_ALL_ARCHITECT_MASTERDATA = "GET_ALL_ARCHITECT_MASTERDATA";
export const GET_ALL_ARCHITECT_MASTERDATA_SUCCESS = "GET_ALL_ARCHITECT_MASTERDATA_SUCCESS";

export const GET_ARCHITECT_BY_ID = "GET_ARCHITECT_BY_ID";
export const GET_ARCHITECT_BY_ID_SUCCESS = "GET_ARCHITECT_BY_ID_SUCCESS";

export const GET_ALL_CHECKLIST_TEMPLATES = "GET_ALL_CHECKLIST_TEMPLATES ";
export const GET_ALL_CHECKLIST_TEMPLATES_SUCCESS = "GET_ALL_CHECKLIST_TEMPLATES_SUCCESS";
export const GET_ALL_CHECKLIST_TEMPLATES_BY_ID = "GET_ALL_CHECKLIST_TEMPLATES_BY_ID";
export const GET_ALL_CHECKLIST_TEMPLATES_BY_ID_SUCCESS = "GET_ALL_CHECKLIST_TEMPLATES_BY_ID_SUCCESS";
export const GET_ALL_STAGES_MASTERDATA = "GET_ALL_STAGES_MASTERDATA";
export const GET_ALL_STAGES_MASTERDATA_SUCCESS = "GET_ALL_STAGES_MASTERDATA_SUCCESS";

export const GET_ALL_PROJECT_TYPE_MASTERDATA = "GET_ALL_PROJECT_TYPE_MASTERDATA";
export const GET_ALL_PROJECT_TYPE_MASTERDATA_SUCCESS = "GET_ALL_PROJECT_TYPE_MASTERDATA_SUCCESS";

export const GET_ALL_TASK_MASTERDATA = "GET_ALL_TASK_MASTERDATA";
export const GET_ALL_TASK_MASTERDATA_SUCCESS = "GET_ALL_TASK_MASTERDATA_SUCCESS";

export const GET_ALL_TASK_MAPPING_MASTERDATA = "GET_ALL_TASK_MAPPING_MASTERDATA";
export const GET_ALL_TASK_MAPPING_MASTERDATA_SUCCESS = "GET_ALL_TASK_MAPPING_MASTERDATA_SUCCESS";

export const GET_TASK_MAPPING_BY_ID = "GET_TASK_MAPPING_BY_ID";
export const GET_TASK_MAPPING_BY_ID_SUCCESS = "GET_TASK_MAPPING_BY_ID_SUCCESS";

export const GET_ALL_TASK_FIELDS_MASTERDATA = "GET_ALL_TASK_FIELDS_MASTERDATA";
export const GET_ALL_TASK_FIELDS_MASTERDATA_SUCCESS = "GET_ALL_TASK_FIELDS_MASTERDATA_SUCCESS";

export const GET_TASK_FIELDS_BY_ID = "GET_TASK_FIELDS_BY_ID";
export const GET_TASK_FIELDS_BY_ID_SUCCESS = "GET_TASK_FIELDS_BY_ID_SUCCESS";

export const GET_ALL_TASK_TYPE_MASTERDATA = "GET_ALL_TASK_TYPE_MASTERDATA";
export const GET_ALL_TASK_TYPE_MASTERDATA_SUCCESS = "GET_ALL_TASK_TYPE_MASTERDATA_SUCCESS";

export const GET_TASK_TYPE_BY_ID = "GET_TASK_TYPE_BY_ID";
export const GET_TASK_TYPE_BY_ID_SUCCESS = "GET_TASK_TYPE_BY_ID_SUCCESS";

export const GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION = "GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION";
export const GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION_SUCCESS = "GET_TASK_TYPE_DATA_WITHOUT_PAGINATATION_SUCCESS";

export const GET_ALL_MASTER_DATA = "GET_ALL_MASTER_DATA";
export const GET_ALL_MASTER_DATA_SUCCESS = "GET_ALL_MASTER_DATA_SUCCESS";

// Stage View
export const GET_STAGE_VIEW = "GET_STAGE_VIEW"
export const GET_STAGE_VIEW_SUCCESS = "GET_STAGE_VIEW_SUCCESS"

export const GET_STAGE_BY_ID = "GET_STAGE_BY_ID";
export const GET_STAGE_BY_ID_SUCCESS = "GET_STAGE_BY_ID_SUCCESS";

//  Media Upload

export const MEDIA_UPLOAD = "MEDIA_UPLOAD"
export const MEDIA_UPLOAD_SUCCESS = "MEDIA_UPLOAD_SUCCESS"
export const DELETE_MEDIA_UPLOAD = "DELETE_MEDIA_UPLOAD"
export const MEDIA_UPLOAD_FAIL = "MEDIA_UPLOAD_FAIL"

// Tasks View
export const GET_ALL_TASK_VIEW = "GET_ALL_TASK_VIEW"
export const GET_ALL_TASK_VIEW_SUCCESS = "GET_ALL_TASK_VIEW_SUCCESS"

// Get All SubTask

export const GET_ALL_SUBTASK = "GET_ALL_SUBTASK"
export const GET_ALL_SUBTASK_SUCCESS = "GET_ALL_SUBTASK_SUCCESS"
export const GET_ALL_DUPLICATE_SUBTASK = "GET_ALL_DUPLICATE_SUBTASK"
export const GET_ALL_DUPLICATE_SUBTASK_SUCCESS = "GET_ALL_DUPLICATE_SUBTASK_SUCCESS"
export const GET_ALL_DUPLICATE_SUBTASK_FAILURE = "GET_ALL_DUPLICATE_SUBTASK_FAILURE"

export const GET_ALL_SUBTASK_SAME_TASK = "GET_ALL_SUBTASK_SAME_TASK"
export const GET_ALL_SUBTASK_SAME_TASK_SUCCESS = "GET_ALL_SUBTASK_SAME_TASK_SUCCESS"
export const GET_ALL_SUBTASK_SAME_TASK_FAILURE = "GET_ALL_SUBTASK_SAME_TASK_FAILURE"
export const GET_ALL_SUBTASK_FAILURE = "GET_ALL_SUBTASK_FAILURE"
export const GET_ALL_SUBTASK_SUMMARY = "GET_ALL_SUBTASK_SUMMARY"
export const GET_ALL_SUBTASK_SUMMARY_SUCCESS = "GET_ALL_SUBTASK_SUMMARY_SUCCESS"
export const GET_ALL_SUBTASK_SUMMARY_FAILURE = "GET_ALL_SUBTASK_SUMMARY_FAILURE"

//Checklist Template
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES"
export const GET_ALL_TEMPLATES_SUCCESS = "GET_ALL_TEMPLATES_SUCCESS"

// COMMOUN-USAGE
export const UPDATE_BY_ID = 'UPDATE_BY_ID';
export const DELETE_BY_ID = 'DELETE_BY_ID';
export const CREATE_BY_URL = 'CREATE_BY_URL';


//Time Tracking

export const START_TRACKING = "START_TRACKING";
export const START_TRACKING_SUCCESS = "START_TRACKING_SUCCESS"

export const STOP_TRACKING = "STOP_TRACKING";
export const STOP_TRACKING_SUCCESS = "STOP_TRACKING_SUCCESS"

export const GET_TIME_BY_ID = "GET_TIME_BY_ID";
export const GET_TIME_BY_ID_SUCCESS = "GET_TIME_BY_ID_SUCCESS"

export const GET_TIME_BY_STAGE_ID = "GET_TIME_BY_STAGE_ID"
export const GET_TIME_BY_STAGE_ID_SUCCESS = "GET_TIME_BY_STAGE_ID_SUCCESS"

export const GET_TIME_BY_PROJECT_ID = "GET_TIME_BY_PROJECT_ID"
export const GET_TIME_BY_PROJECT_ID_SUCCESS = "GET_TIME_BY_PROJECT_ID_SUCCESS"

export const GET_LATEST_TIME_BY_ID = "GET_LATEST_TIME_BY_ID"
export const GET_LATEST_TIME_BY_ID_SUCCESS = "GET_LATEST_TIME_BY_ID_SUCCESS"


//actions

export const NEED_INFO = "NEED_INFO"
export const NEED_INFO_SUCCESS = "NEED_INFO_SUCCESS"

export const STAGE_ACTION = "STAGE_ACTION"
export const STAGE_ACTION_SUCCESS = "STAGE_ACTION_SUCCESS"

export const PROJECT_ACTION = "PROJECT_ACTION"
export const PROJECT_ACTION_SUCCESS = "PROJECT_ACTION_SUCCESS"

//highlighters

export const ALL_HIGHLIGHTERS = "ALL_HIGHLIGHTERS"
export const ALL_HIGHLIGHTERS_SUCCESS = "ALL_HIGHLIGHTERS_SUCCESS"
export const GET_HIGHLIGHT_BY_VIEW = "GET_HIGHLIGHT_BY_VIEW"
export const GET_HIGHLIGHT_BY_VIEW_SUCCESS = "GET_HIGHLIGHT_BY_VIEW_SUCCESS"
export const GET_HISTORY_BY_VIEW = "GET_HISTORY_BY_VIEW"
export const GET_HISTORY_BY_VIEW_SUCCESS = "GET_HISTORY_BY_VIEW_SUCCESS"
export const GET_ALL_REVERT_HISTORY = "GET_ALL_REVERT_HISTORY"
export const GET_ALL_REVERT_HISTORY_SUCCESS = "GET_ALL_REVERT_HISTORY_SUCCESS"
export const GET_REVERT_HISTORY = "GET_REVERT_HISTORY"
export const GET_REVERT_HISTORY_SUCCESS = "GET_REVERT_HISTORY_SUCCESS"
export const GET_ALL_DELIVER_CHECKLIST = 'GET_ALL_DELIVER_CHECKLIST'
export const GET_ALL_DELIVER_CHECKLIST_SUCCESS = 'GET_ALL_DELIVER_CHECKLIST_SUCCESS'
export const REMOVE_HIGHLIGHTER = 'REMOVE_HIGHLIGHTER'
export const REMOVE_HIGHLIGHTER_SUCCESS = 'REMOVE_HIGHLIGHTER_SUCCESS'
export const REMOVE_HIGHLIGHTER_FROM_TASKVIEW = 'REMOVE_HIGHLIGHTER_FROM_TASKVIEW'
export const REMOVE_HIGHLIGHTER_FROM_TASKVIEW_SUCCESS = 'REMOVE_HIGHLIGHTER_FROM_TASKVIEW_SUCCESS'
export const REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW = 'REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW'
export const REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW_SUCCESS = 'REMOVE_HIGHLIGHTER_FROM_PROJECTVIEW_SUCCESS'
//FrontApp

export const FRONT_APP = "FRONT_APP"
export const FRONT_APP_SUCCESS = "FRONT_APP_SUCCESS"

export const UPDATE_DT_PM = "UPDATE_DT_PM"
export const UPDATE_DT_PM_SUCCESS = "UPDATE_DT_PM_SUCCESS"

export const UPDATE_COMMENT = "UPDATE_COMMENT"
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS"

export const UPDATE_FOLDER_NUMBER = "UPDATE_FOLDER_NUMBER"
export const UPDATE_FOLDER_NUMBER_SUCCESS = "UPDATE_FOLDER_NUMBER_SUCCESS"


// Rating Criteria

export const GET_RATING_CRITERIA = "GET_RATING_CRITERIA"
export const GET_RATING_CRITERIA_SUCCESS = "GET_RATING_CRITERIA_SUCCESS"
export const GET_RATING_CRITERIA_BY_ID = "GET_RATING_CRITERIA_BY_ID"
export const GET_RATING_CRITERIA_BY_ID_SUCCESS = "GET_RATING_CRITERIA_BY_ID_SUCCESS"

//Upload Type
export const GET_UPLOAD_TYPE = "GET_UPLOAD_TYPE"
export const GET_UPLOAD_TYPE_SUCCESS = "GET_UPLOAD_TYPE_SUCCESS"
export const GET_UPLOAD_TYPE_NO_PAGINATION = "GET_UPLOAD_TYPE_NO_PAGINATION"
export const GET_UPLOAD_TYPE_NO_PAGINATION_SUCCESS = "GET_UPLOAD_TYPE_NO_PAGINATION_SUCCESS"
export const GET_UPLOAD_TYPE_BY_ID = "GET_UPLOAD_TYPE_BY_ID"
export const GET_UPLOAD_TYPE_BY_ID_SUCCESS = "GET_UPLOAD_TYPE_BY_ID_SUCCESS"
//Rating

export const GET_RATING_BY_ID = " GET_RATING_BY_ID"
export const GET_RATING_BY_ID_SUCCESS = "GET_RATING_BY_ID_SUCCESS"
export const GET_MY_RATING = "GET_MY_RATING"
export const GET_MY_RATING_SUCCESS = "GET_MY_RATING_SUCCESS"
export const CREATE_RATING = "CREATE_RATING"
export const CREATE_RATING_SUCCESS = "CREATE_RATING_SUCCESS"
export const GET_REPORT_HISTORY = "GET_REPORT_HISTORY"
export const GET_REPORT_HISTORY_SUCCESS = "GET_REPORT_HISTORY_SUCCESS"
export const GET_REPORT_HISTORY_BY_ID = "GET_REPORT_HISTORY_BY_ID"
export const GET_REPORT_HISTORY_BY_ID_SUCCESS = "GET_REPORT_HISTORY_BY_ID_SUCCESS"

//Trading Rating
export const GET_TRADING_RATING = "GET_TRADING_RATING"
export const GET_TRADING_RATING_SUCCESS = "GET_TRADING_RATING_SUCCESS"
export const GET_TRADING_RATING_BY_ID = "GET_TRADING_RATING_BY_ID"
export const GET_TRADING_RATING_BY_ID_SUCCESS = "GET_TRADING_RATING_BY_ID_SUCCESS"
export const TRADING_RATING_FAIL = "TRADING_RATING_FAIL"


// Action Highlights
export const GET_ACTION_HIGHLIGHTS_BY_IDS = "GET_ACTION_HIGHLIGHTS_BY_IDS"
export const GET_ACTION_HIGHLIGHTS_BY_IDS_SUCCESS = "GET_ACTION_HIGHLIGHTS_BY_IDS_SUCCESS"
export const GET_ACTION_BY_ID = "GET_ACTION_BY_ID"
export const GET_ACTION_BY_ID_SUCCESS = "GET_ACTION_BY_ID_SUCCESS"
export const GET_ALL_ACTION_BY_NAME = "GET_ALL_ACTION_BY_NAME"
export const GET_ALL_ACTION_BY_NAME_SUCCESS = "GET_ALL_ACTION_BY_NAME_SUCCESS"
export const GET_ALL_BC_PC_REV_STAGES = "GET_ALL_BC_PC_REV_STAGES"
export const GET_ALL_BC_PC_REV_STAGES_SUCCESS = "GET_ALL_BC_PC_REV_STAGES_SUCCESS"
export const ACTION_HIGHLIGHTS_FAIL = "ACTION_HIGHLIGHTS_FAIL"
export const CREATE_ACTION_HIGHLIGHTS = "CREATE_ACTION_HIGHLIGHTS"
export const CREATE_ACTION_HIGHLIGHTS_SUCCESS = "CREATE_ACTION_HIGHLIGHTS_SUCCESS"
export const CREATE_BC_DONE_HIGHLIGHTS = "CREATE_BC_DONE_HIGHLIGHTS"
export const CREATE_BC_DONE_HIGHLIGHTS_SUCCESS = "CREATE_BC_DONE_HIGHLIGHTS_SUCCESS"
export const ADD_DESCRIPTION_ACTION_HIGHLIGHTS = "ADD_DESCRIPTION_ACTION_HIGHLIGHTS"
export const ADD_DESCRIPTION_BULK_ACTION_HIGHLIGHTS = "ADD_DESCRIPTION_BULK_ACTION_HIGHLIGHTS"
export const EDIT_ACTION_HIGHLIGHTS = "EDIT_ACTION_HIGHLIGHTS"
export const EDIT_ACTION_HIGHLIGHTS_SUCCESS = "EDIT_ACTION_HIGHLIGHTS_SUCCESS"
export const EDIT_ACTION_PAYLOAD = "EDIT_ACTION_PAYLOAD"
export const EDIT_ACTION_PAYLOAD_SUCCESS = "EDIT_ACTION_PAYLOAD_SUCCESS"
export const DELETE_ACTION_HIGHLIGHTS = "DELETE_ACTION_HIGHLIGHTS"
export const DELETE_ACTION_HIGHLIGHTS_SUCCESS = "DELETE_ACTION_HIGHLIGHTS_SUCCESS"
export const DELETE_ACTION__MULTIPLE_HIGHLIGHTS = "DELETE_ACTION_MULTIPLE_HIGHLIGHTS"
export const DELETE_ACTION_MULTIPLE_HIGHLIGHTS_SUCCESS = "DELETE_ACTION_MULTIPLE_HIGHLIGHTS_SUCCESS"
export const ADD_COMMENT_ACTION_HIGHLIGHTS = "ADD_COMMENT_ACTION_HIGHLIGHTS"
export const EDIT_COMMENT_ACTION_HIGHLIGHTS = "EDIT_COMMENT_ACTION_HIGHLIGHTS"
export const DELETE_COMMENT_ACTION_HIGHLIGHTS = "DELETE_COMMENT_ACTION_HIGHLIGHTS"

//Chat 
export const CREATE_CHAT = "CREATE_CHAT"
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS"
export const GET_ALL_CHATS = "GET_ALL_CHATS"
export const GET_ALL_CHATS_SUCCESS = "GET_ALL_CHATS_SUCCESS"
export const GET_ALL_GENERAL_CHATS = "GET_ALL_GENERAL_CHATS"
export const GET_ALL_GENERAL_CHATS_SUCCESS = "GET_ALL_GENERAL_CHATS_SUCCESS"
export const DELETE_CHAT = "DELETE_CHAT"
export const DELETE_CHAT_SUCCESS = "DELETE_CHAT_SUCCESS"
export const EDIT_CHAT = "EDIT_CHAT"
export const EDIT_CHAT_SUCCESS = "EDIT_CHAT_SUCCESS"
export const EDIT_CHAT_IN_BULK = "EDIT_CHAT_IN_BULK"
export const EDIT_CHAT_IN_BULK_SUCCESS = "EDIT_CHAT_IN_BULK_SUCCESS"
export const CHAT_MESSAGE_FAIL = "CHAT_MESSAGE_FAIL"
export const GET_TRADE_FOR_CHATS = "GET_TRADE_FOR_CHATS"
export const GET_TRADE_FOR_CHATS_SUCCESS = "GET_TRADE_FOR_CHATS_SUCCESS"


//UPDATE ACTION STATUS TO ANY
export const UPDATE_ACTION_STATUS_TO_ANY = "UPDATE_ACTION_STATUS_TO_ANY"
export const UPDATE_ACTION_STATUS_TO_ANY_SUCCESS = "UPDATE_ACTION_STATUS_TO_ANY_SUCCESS"

//GET REPORT
export const GET_EXCEL_REPORT = "GET_EXCEL_REPORT"
export const GET_EXCEL_REPORT_SUCCESS = "GET_EXCEL_REPORT_SUCCESS"
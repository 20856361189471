import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Badge, Box, Card, Flex, Grid, Image, Text } from 'theme-ui';
import { ItemType, RootState } from '../../../@types';
// import "../../../project-components/projectTab.css";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import EditTaskView from '../pages/TaskView/EditTaskView';
import { AddTaskModalBox } from './AddTaskModalBox/AddTaskModalBox';
import moment from 'moment';
import { CheckBox2 } from '../CheckBox/Project';
import FormSelect from '../forms-component/globalSelect';
import { Inputform } from '../forms-component/InputForm';

interface StagesTabProps {
    selectedIndex: number;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
    values: any;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
    errors: any;
    touched: any;
    SowChecklist: any[];
    sowfields: any[];
    projectId: string,
    stagebyproject: any
}

const StagesTabView: React.FC<StagesTabProps> = ({
    stagebyproject,
    selectedIndex,
    setSelectedIndex,
    projectId,
    values,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    SowChecklist,
    sowfields,
}) => {
    const { createprojectMasterData } = useSelector((state: RootState) => state.project);
    const { stageId } = useParams<{ stageId: string }>();

    const projectManagers = createprojectMasterData?.allProjectManagers || [];

    const projectWorkers = createprojectMasterData?.allTaskWorkers || [];
    const [isOpen, setIsOpen] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleEditTask = (taskId: any) => {
        setIsOpen(true);
        navigate(`/edit-task/${taskId}`);
    };
    console.log('chk58', values);

    const groupedTasks: { [key: string]: { [key: string]: any[] } } = values.reduce((acc: { [key: string]: { [key: string]: any[] } }, task: any) => {


        const stageName = task?.stageId?.name;
        const stageDueDate = task?.stageDueDate || task?.stageDueDate === null;

        // Initialize the group for the stage name if it doesn't exist
        if (!acc[stageName]) {
            acc[stageName] = {};
        }

        // Initialize the array for the stage due date if it doesn't exist
        if (!acc[stageName][stageDueDate]) {
            acc[stageName][stageDueDate] = [];
        }

        // Push the task into the appropriate array
        acc[stageName][stageDueDate].push(task);

        return acc;
    }, {});

    // groupedTasks will now contain tasks grouped by stage name and then by stage due date
    console.log(groupedTasks);

    useEffect(() => {
        const stageIndex = stagebyproject?.findIndex((stage: any) => stage?._id === stageId);
        if (stageIndex >= 0) {
            setSelectedIndex(stageIndex);
        }
    }, [stageId, stagebyproject, setSelectedIndex]);

    return (
        <Box >
            <Flex sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                <Text as={'h4'} mt={3} mb={2}>
                    Tasks
                </Text>
                <AddTaskModalBox />
            </Flex>

            <Tabs selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
                <TabList>
                    <>
                        {stagebyproject?.map((stage: any, index: number) => {

                            return (
                                <Tab
                                    key={stage?._id}
                                    style={{
                                        borderColor: selectedIndex === index ? '#ffffff00' : '',
                                        color: selectedIndex === index ? stage?.color : 'black',
                                        borderRadius: selectedIndex === index ? '5px 5px 0 0' : '',
                                        fontSize: selectedIndex === index ? '18px' : '',
                                        fontWeight: selectedIndex === index ? 600 : '',
                                        borderBottom: selectedIndex === index ? `3px solid ${stage?.color}` : '',
                                    }}
                                >
                                    {`${stage?.name}-${moment(stage?.createdAt).format('MMM DD')}`}
                                </Tab>
                            );
                        })}
                    </>
                </TabList>

                {stagebyproject?.map((stage: any, stageIndex: number) => (
                    <TabPanel key={stage?._id}>
                        {Object.keys(groupedTasks).map((stageName) => {

                            return Object.keys(groupedTasks[stageName]).map((dueDate) => {

                                // Check if the dueDate matches the current stage's dueDate
                                if (dueDate === stage?.dueDate) {

                                    return groupedTasks[stageName][dueDate].map((task, taskIndex) => {

                                        const ChecklistItem = SowChecklist?.filter((entry) => entry?.taskType === task?.taskId?._id);
                                        const SowFields = sowfields?.filter((entry) => entry?.taskName === task?.taskId?._id);
                                        let checkedData = [];
                                        const checklistLength = ChecklistItem?.length || 0;

                                        if (checklistLength > 0) {
                                            const defaultSelected = task?.sowMapping?.length > 0
                                                ? task.sowMapping
                                                : ChecklistItem?.filter(value => value?.default)?.map(val => val._id) || [];
                                            checkedData = defaultSelected;
                                        }

                                        return (
                                            <Card key={taskIndex} mt={7} sx={{ width: '100%', borderRadius: '12px' }}>
                                                <Box sx={{ borderLeft: `3px solid ${task?.color}`, paddingLeft: '24px' }}>
                                                    <Flex as={'h3'} sx={{ color: task?.color, cursor: "pointer", gap: "10px" }} onClick={() => handleEditTask(task?._id)}>
                                                        {task?.name}
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                                            <h4 style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
                                                                {task?.highlighters?.map((badge: any) => (
                                                                    <Badge
                                                                        key={badge?._id}
                                                                        color={badge?.color}
                                                                        sx={{
                                                                            backgroundColor: 'transparent',
                                                                            borderRadius: "8px",
                                                                            gap: '3px',
                                                                            padding: '4px 8px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            border: `1px solid ${badge?.color}`,
                                                                        }}
                                                                    >
                                                                        {badge?.icon && <Image
                                                                            title={badge.name}
                                                                            src={badge?.icon}
                                                                            alt={badge?.name}
                                                                            width={14}
                                                                            height={14}
                                                                        />}
                                                                        <Text sx={{ color: `${badge?.color}` }}>{badge?.name}</Text>
                                                                    </Badge>
                                                                ))}
                                                            </h4>
                                                        </div>
                                                    </Flex>
                                                    <Text>{task.status}</Text>
                                                    <Box mt={3}>
                                                        <CheckBox2
                                                            title={''}
                                                            Option={ChecklistItem}
                                                            checkedData={checkedData}
                                                            onChange={(event: any, item: ItemType) => {
                                                                if (event) {
                                                                    task?.sowMapping?.push(item?._id);
                                                                } else {
                                                                    const indexToRemove = task?.sowMapping?.indexOf(item?._id);
                                                                    if (indexToRemove !== -1) {
                                                                        task?.sowMapping?.splice(indexToRemove, 1);
                                                                    }
                                                                }
                                                            }}
                                                            disable={true}
                                                        />
                                                    </Box>
                                                    <Text mt={3} as={'h5'}>Description</Text>
                                                    <textarea
                                                        className='form-control mt-2'
                                                        name={`stagesData[${selectedIndex}].tasks[${taskIndex}].description`}
                                                        value={task?.description}
                                                        onChange={(values) => {
                                                            setFieldValue(`stagesData[${selectedIndex}].tasks[${taskIndex}].description`, values?.target?.value, true);
                                                        }}
                                                        onBlur={handleBlur}
                                                        rows={8}
                                                        disabled
                                                    />
                                                    {errors.stagesData && touched.stagesData ? (
                                                        <Text variant="Inter15Regular30lh" className="text-danger" sx={{ my: '5px', minHeight: '11px', lineHeight: '10px' }}>
                                                            {(errors?.stagesData?.[selectedIndex] as { tasks?: Array<{ description?: string }> })?.tasks?.[taskIndex]?.description}
                                                        </Text>
                                                    ) : (
                                                        <Text variant="Inter15Regular30lh" sx={{ my: '5px', minHeight: '11px' }}></Text>
                                                    )}
                                                    <Grid mt={2} gap={2} columns={[1, 2, 3, 3, 4, 4]}>
                                                        {SowFields?.map((sowfield: any, index: number) => {
                                                            return (
                                                                <Box key={index}>
                                                                    {sowfield?.type === 'select' && sowfield?.fieldName === 'Task Worker' ? (
                                                                        <FormSelect
                                                                            Name=''
                                                                            options={projectWorkers?.map((projectWorker: { FirstName: any; _id: any; }) => ({
                                                                                label: projectWorker?.FirstName,
                                                                                value: projectWorker?._id
                                                                            })) || []}
                                                                            label={sowfield?.fieldName}
                                                                            placeholder={sowfield?.fieldName}
                                                                            value={task?.worker?._id}
                                                                            onBlur={handleBlur}
                                                                            touched={touched?.stagesData?.[selectedIndex]?.tasks?.[taskIndex]?.worker}
                                                                            errors={(errors?.stagesData?.[selectedIndex] as { tasks?: Array<{ worker?: string }> })?.tasks?.[taskIndex]?.worker}
                                                                            onChange={(values: any) => {
                                                                                setFieldValue(`stagesData[${selectedIndex}].tasks[${taskIndex}].worker`, values?.value);
                                                                            }}
                                                                            disabled={true}
                                                                        />
                                                                    ) : sowfield?.type === 'select' && sowfield?.fieldName === 'Task Manager' ? (
                                                                        <FormSelect
                                                                            options={projectManagers.map((projectManager: { FirstName: string; LastName: string; _id: any; }) => ({
                                                                                label: `${projectManager?.FirstName} ${projectManager?.LastName}`,
                                                                                value: projectManager?._id
                                                                            })) || []}
                                                                            placeholder={task?.manager?.FirstName}
                                                                            label={sowfield?.fieldName}
                                                                            onBlur={handleBlur}
                                                                            touched={touched?.stagesData?.[selectedIndex]?.tasks?.[taskIndex]?.manager}
                                                                            errors={(errors?.stagesData?.[selectedIndex] as { tasks?: Array<{ manager?: string }> })?.tasks?.[taskIndex]?.manager}
                                                                            value={task?.manager?._id}
                                                                            onChange={() => { }}
                                                                            disabled={true}
                                                                        />
                                                                    ) : (
                                                                        <Inputform
                                                                            type={sowfield?.type}
                                                                            label={sowfield?.fieldName}
                                                                            Placeholder={sowfield?.fieldName}
                                                                            touched={touched?.stagesData?.[selectedIndex]?.tasks?.[taskIndex]?.taskFields}
                                                                            errors={(errors?.stagesData?.[selectedIndex] as { tasks?: Array<{ taskFields?: string }> })?.tasks?.[taskIndex]?.taskFields}
                                                                            Name={`stagesData[${selectedIndex}].tasks[${taskIndex}].taskFields[${sowfield?.fieldName}]`}
                                                                            Title_sx={{ color: 'grey' }}
                                                                            values={task?.taskFields[0]?.[sowfield?.fieldName]}
                                                                            onBlur={handleBlur}
                                                                            OnChange={(values: any) => {
                                                                                setFieldValue(
                                                                                    `stagesData[${selectedIndex}].tasks[${taskIndex}].taskFields[${sowfield?.fieldName}]`,
                                                                                    values?.target?.value,
                                                                                    true
                                                                                );
                                                                            }}
                                                                            disabled={true}
                                                                        />
                                                                    )}
                                                                </Box>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        );
                                    });
                                }
                                return null; // If the due date doesn't match, return null
                            });
                        })}
                    </TabPanel>
                ))}

            </Tabs>
            {isOpen && (
                <EditTaskView />
            )}

        </Box >

    );
};

export default StagesTabView;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePreviousURL } from '../../../utils/CustomHandler';
import moment from 'moment';
import theme from '../../../theme';

interface StageNameProps {
    stageId: string;
    stageName: string;
    dueDate: string;
    timeRemaining: number;
}

const getGradientColor = (dueDate: string, timeRemaining: number) => {
    const now = moment();
    const due = moment(dueDate);
    const diffDays = due.diff(now, 'days');

    let color = "white";
    let borderBottomStyle = "none";

    if (diffDays < 0) {
        color = "red";
    } else {
        const timeRemainingHours = timeRemaining / (1000 * 60 * 60);

        switch (true) {
            case timeRemainingHours <= 5:
                color = "green";
                break;
            case timeRemainingHours <= 10:
                color = "lightgreen";
                break;
            case timeRemainingHours <= 15:
                color = "lightsalmon";
                break;
            case timeRemainingHours <= 20:
                color = "orange";
                break;
            case timeRemainingHours > 20:
                borderBottomStyle = "3px dashed red";
                break;
            default:
                color = "white";
        }
    }

    return {
        color,
        borderBottom: borderBottomStyle
    };

};

export const StageName: React.FC<StageNameProps> = ({ stageId, stageName, dueDate, timeRemaining }) => {
    const navigate = useNavigate();
    const previousURL = usePreviousURL();
    const handleClick = () => {
        previousURL(`/edit-stages/${stageId}`);
    };
    const gradientStyle = getGradientColor(dueDate, timeRemaining);

    return (
        <div
            onClick={handleClick}
            style={{
                textDecoration: 'underline',
                color: `${theme.colors?.secondary}`,
                cursor: "pointer",
                paddingTop: '7px',
                paddingBottom: '0px',
                position: 'relative',
                fontWeight: 500,
                textAlign: 'center',
                paddingLeft: '5px',
            }}
        >
            {stageName}
            <div
                style={{
                    position: 'sticky',
                    left: 0,
                    width: '100%',
                    height: '3px',
                    background: `linear-gradient(to right, ${gradientStyle.color} 0%, ${gradientStyle.color} 100%)`,
                    borderBottom: gradientStyle.borderBottom,
                }}
            />
        </div>
    );
};

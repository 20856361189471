import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, Label } from "theme-ui";
import * as Yup from "yup";
import { CreateRoleFormValues, RootState } from "../../../../@types";
import { editRole, getAllRoles, getRolebyId } from "../../../store/actions/user.action";
import theme from "../../../theme";


const validationSchema = Yup.object({
    RoleName: Yup.string().required("Role Name is required"),
    description: Yup.string().required("Description is required"),
});

const EditRole: React.FC = () => {
    const { roleId } = useParams<{ roleId: string }>();
    const { roles } = useSelector((state: RootState) => state.user);
    const { rolesbyId } = useSelector((state: RootState) => state.user);
    // const roleToEdit = roles.find((role) => role._id === roleId);
    // const roleToEdit = Array.isArray(roles) ? roles.find((role) => role?._id === roleId) || undefined : undefined;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleEdit = (values: CreateRoleFormValues) => {
        const { RoleName, description, permissions, _id } = values;
        const permissionKeys = Object.keys(permissions).reduce((acc, entity) => {
            const actions = Object.keys((permissions as any)[entity]);
            const entityPermissions = actions.filter((action) => (permissions as any)[entity][action]);
            return [...acc, ...entityPermissions.map((action) => `${entity}.${action}`)];
        }, [] as string[]);

        const payload = {
            _id: _id,
            RoleName: RoleName,
            Description: description,
            Permission: permissionKeys,
        };
        dispatch(editRole(payload))
        // console.log("Form submitted with values:", payload);
    };
    useEffect(() => {
        dispatch(getAllRoles());
        dispatch(getRolebyId(roleId))
    }, [dispatch, roleId]);
    const masterData = Array.isArray(roles?.data) ? Array.from(new Set(roles?.data.flatMap((role: any) => role?.Permission.map((p: any) => p.split(".")[0])).filter((item: any) => item !== ""))) : [];
    // console.log(masterData, "masterData")
    const theadData = Array.isArray(roles?.data) ? Array.from(new Set(roles?.data.flatMap((role: any) => role?.Permission.map((p: any) => p.split(".")[1])).filter((item: any) => item !== ""))) : [];
    const initialValues: CreateRoleFormValues = {
        _id: rolesbyId?._id,
        RoleName: rolesbyId?.RoleName || "",
        description: rolesbyId?.Description || "",
        permissions: masterData.reduce((acc, entity) => {
            (acc as Record<string, Record<string, boolean>>)[entity] = theadData.reduce((actionsAcc, action) => {
                actionsAcc[action] = rolesbyId?.Permission?.includes(`${entity}.${action}`) || false;
                return actionsAcc;
            }, {} as Record<string, boolean>); return acc;
        }, {}),
    };
    const handlecancle = () => {
        navigate('/role')
    };
    // console.log("initialValues-----", initialValues);


    return (
        <div className="main-content">
            <div style={{ margin: '12px' }} > <h1 style={{}}>Edit Role</h1></div>
            <hr />
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleEdit} enableReinitialize={true}>
                <Form style={{ margin: '12px' }}>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="RoleName" className="form-label">Role Name*</label>
                            <Field type="text" id="RoleName" name="RoleName" className="form-control" placeholder="Role Name" required />
                            <ErrorMessage name="RoleName" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-9">
                            <label htmlFor="description" className="form-label">Description*</label>
                            <Field type="text" id="description" name="description" className="form-control" placeholder="Enter Description" required />
                            <ErrorMessage name="description" component="div" className="text-danger" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="mt-5">Permissions</h5>
                            <div className="table-responsive">
                                <table className="table table-bordered custom-table w-100 mt-2">
                                    <thead className="table-warning">
                                        <tr>
                                            <th>Master Table</th>
                                            {theadData.map((theadItem) => (<th key={theadItem}>{theadItem}</th>))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {masterData.map((masterItem) => (<tr key={masterItem}>
                                            <td>{masterItem}</td>
                                            {theadData.map((theadItem) => (
                                                <td key={`${masterItem}-${theadItem}`}>
                                                    <Label><Field type="checkbox" id={`${masterItem}-${theadItem}`} name={`permissions.${masterItem}.${theadItem}`} as={Checkbox} /> </Label>
                                                </td>))}
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'><Button type="reset" sx={{ border: `1px solid ${theme.colors?.primary || 'defaultBorderColor'}`, background: 'white', color: theme.colors?.muted, borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }} onClick={handlecancle}> Cancel </Button></div>
                        <div className='col-md-4'><Button type="submit" sx={{ borderRadius: '12px', padding: [2, 4, 2, 4], width: '95%' }}>Submit</Button></div>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

export default EditRole;

import { FormikErrors, FormikTouched } from "formik";
import React, { ChangeEventHandler, FocusEventHandler, useState } from "react";
import Select from 'react-select';
import { Box, Text, ThemeUIStyleObject } from "theme-ui";
interface FormSelectProps {
    disabled?: boolean | undefined;
    id?: string | undefined;
    placeholder?: string | undefined;
    value: any;
    sx?: any;
    Arrow_sx?: ThemeUIStyleObject;
    onChange: ChangeEventHandler | any | undefined,
    Name?: string | undefined;
    styles?: any;
    DefultSelect?: string;
    className?: string;
    options?: any;
    touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined,
    errors?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined,
    Max_Length?: number,
    onBlur?: FocusEventHandler | undefined,
    handleSubmit?: () => void,
    autoFocus?: boolean | undefined;
    description?: string | undefined;
    label?: string | undefined;
    asterisk?: boolean;
    mb?: number | undefined | Array<number>;
    mt?: number | undefined | Array<number>;
    isMulti?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
    Name = "",
    onChange = () => null,
    value,
    sx,
    Arrow_sx,
    id = "",
    placeholder = "",
    DefultSelect = "",
    styles,
    errors = "",
    disabled = false,
    options = [],
    onBlur = () => null,
    handleSubmit = () => null,
    autoFocus = false,
    description = "",
    touched = false,
    label,
    className,
    asterisk,
    mb,
    mt,
    isMulti = false,
}) => {
    const [filteredOptions, setFilteredOptions] = useState<{ value: string, label: string }[]>([]);

    const customFilter = (option: { value: string, label: string }, inputValue: string) => {
        if (!inputValue) {
            return true;
        }

        const lowerCasedInput = inputValue.toLowerCase();
        const label = option.label.toLowerCase();

        return label.includes(lowerCasedInput);
    };

    const handleInputChange = (inputValue: string, { action }: any) => {
        // console.log(action, "check69", inputValue, filteredOptions);
        if (action === 'input-change') {
            // Filter options based on input value
            const filtered = options.filter((option: { value: string, label: string }) => customFilter(option, inputValue)).slice(0, 10);
            setFilteredOptions(filtered);
        }
    };
    // console.log(value, "check76", label);

    return (
        <Box>
            <Text variant="SelectLabel">
                {label}
                {asterisk && <span className="mx-1 text-danger">*</span>}
            </Text>
            <Box mb={mb} mt={mt}>
                <Select
                    key={options.value}
                    isDisabled={disabled}
                    isSearchable={true}
                    isMulti={isMulti}
                    options={filteredOptions.length > 0 ? filteredOptions : options.slice(0, 10)}
                    onChange={onChange}
                    classNamePrefix="an-simple-select"
                    name={Name}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    onInputChange={handleInputChange}
                    id={id}
                    styles={styles}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#F7EFE5',
                        },
                    })}
                    onBlur={onBlur}
                    value={
                        value
                            ? (isMulti
                                ? options.filter((opt: { value: any }) =>
                                    value?.includes(String(opt.value))
                                )
                                : options.find((opt: { value: any }) => String(opt.value) === String(value)))
                            : null
                    }
                />
                {errors && touched ? (
                    <Text variant="Inter15Regular30lh" className="text-danger" sx={{ fontSize: 17, lineHeight: "10px" }}>
                        {errors.toString()}
                    </Text>
                ) : (
                    <Text variant="Inter15Regular30lh" sx={{ my: "5px", minHeight: "11px" }}>
                        {description}
                    </Text>
                )}
            </Box>
        </Box >
    );
};

export default FormSelect;

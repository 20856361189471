import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Button } from 'theme-ui';
import dropdownIcon from "../assets/images/Icon/dropdownicon.svg";
import dot from "../assets/images/Icon/dot.svg";
import { useSelector } from 'react-redux';
import queryString from 'query-string';

export const TaskDropDownFilter = () => {
    const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
    const { createprojectMasterData } = useSelector((state: any) => state.project);
    const location = useLocation();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [_, setSearchParams] = useSearchParams();
    const parsedQuery = queryString.parse(location.search);

    const toggleTaskSelection = (id: any, name: string) => {
        let updatedTasks;
        if (selectedTasks.some(task => task.id === id)) {
            updatedTasks = selectedTasks.filter(task => task.id !== id);
        } else {
            updatedTasks = [...selectedTasks, { id, name }];
        }
        setSelectedTasks(updatedTasks);

        setSearchParams({
            ...parsedQuery,
            taskId: JSON.stringify(updatedTasks.map(task => task.id)),
            stageViewTwo: 'true'
        });
        setIsPopoverOpen(false);
    };

    const handleClickOutside = (event: any) => {
        if (!event.target.closest('.popover-content')) {
            if (!event.target.closest('.toggle-btn')) {
                setIsPopoverOpen(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    useEffect(() => {
        if (parsedQuery.stageViewTwo) {
            const taskIdsStr = Array.isArray(parsedQuery?.taskId) ? parsedQuery.taskId.join('') : parsedQuery?.taskId;
            const taskIds = JSON.parse(taskIdsStr || '[]');
            const selectedTaskDetails = taskIds.map((id: string) => {
                const task = createprojectMasterData?.allTask?.data.find((task: any) => task._id === id);
                return task ? { id: task._id, name: task.name, color: task.color } : null;
            }).filter((task: any) => task !== null);
            setSelectedTasks(selectedTaskDetails);
        } else {
            setSelectedTasks([]);
        }
    }, [location.search, parsedQuery.stageViewTwo]);

    return (
        <>
            <div ref={popoverRef} className='d-flex g-4 position-relative'>
                <Button variant='PrimaryHeadBtn' onClick={togglePopover} className='toggle-btn position-relative' sx={{ marginLeft: "60px" }}>
                    {selectedTasks.length > 0 ? (
                        selectedTasks.map(task => (

                            <div key={task.id} className="chip m-1" style={{ backgroundColor: task.color }}>
                                {task.name}
                                <button className="chip-delete" style={{ border: 'none' }} onClick={() => toggleTaskSelection(task.id, task.name)}>x</button>
                            </div>
                        ))
                    ) : (
                        'Default'
                    )}
                    <img src={dropdownIcon} style={{ marginLeft: "10px" }} />
                </Button>
                {isPopoverOpen && (
                    <div className="popover-content" style={{ position: 'absolute', zIndex: 99999999, left: "50%", top: "60px", height: "400px", overflowY: "auto", overflowX: "hidden", transform: "translateX(-50%)" }}>
                        <ul className="headermenu">
                            {createprojectMasterData?.allTask?.data.map((element: any) => {
                                const isSelected = selectedTasks.some(task => task.id === element?._id);
                                return (
                                    <li key={element?._id} className={isSelected ? "active-dropdown" : ""}>
                                        <p onClick={() => toggleTaskSelection(element?._id, element?.name)} className="DashboardSide custom-des-a">
                                            <img className='mt-3' src={dot} />
                                            {element?.name}
                                        </p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
};

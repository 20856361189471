import { FocusEventHandler, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Box, Button, Card, Divider, Flex, Image, Switch, Text, Textarea } from 'theme-ui';
import { ActionModuleTab } from './ActionModuleTab';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { createActionHighlightSuccess, createBCDoneHighlight, getActionIdSuccess, getAllTemplateChecklist } from '../../../store/actions/action.Highlighters';
import { RootState } from '../../../../@types';
import CustomTooltip from '../../CustomTooltip';
import { rolePermissionsHighLighters } from '../../../utils/CustomHandler';
import { getAllTaskViewByStageId } from '../../../store/actions/project.action';
import editIcon from "../../assets/images/edit.svg";
import CreatableSelect from 'react-select/creatable';
import { Inputform } from '../../forms-component/InputForm';
import theme from '../../../theme';
import MarkDownParagraph from '../../forms-component/MarkDownParagraph';
import moment from 'moment';
import { MultiSelect } from 'react-multi-select-component';
import { useParams } from 'react-router-dom';

interface IChecklistTemplate {
    checklistItems: string[];
    showInActions: string[];
    _id: string;
    templateName: string;
    checklistItem: string[];
    showInAction: string[];
}
interface Option {
    label: string;
    value: IChecklistTemplate;
}
const components = {
    DropdownIndicator: null,
};

const createOption = (label: string) => ({
    label,
    value: label,
});
const ActionBcPcChecklist = ({ Open, setOpen, selectedHighlight, actionObject, keys, actionId }: any) => {
    const { auther } = useSelector((state: RootState) => state.auth);
    const is_Task_Worker = auther?.Role?.map(role => role?.RoleName).every(role => role.toLocaleLowerCase() === "task worker")
    const { stageAllTaskView } = useSelector((state: any) => state.project);
    const dispatch = useDispatch();
    const { templateList, actionHighlights } = useSelector((state: any) => state.actionHighlight);
    const { stageId } = useParams<{ stageId: string }>();
    const [selectedTemplates, setSelectedTemplates] = useState<any>();
    const [isSwitchChecked, setIsSwitchChecked] = useState(false);
    const isBcChecked = actionHighlights?.payload?.Description?.every((check: any) => check.isChecked === true) && actionHighlights?.payload?.Description?.length > 0
    const [inputValue, setInputValue] = useState('');
    const [emailLinks, setEmailLinks] = useState<string[]>([]);
    const [isEmailEditing, setIsEmailEditing] = useState<boolean>(false);
    const [editedEmailLinks, setEditedEmailLinks] = useState<{ label: string; value: string }[]>([]);
    const [taskName, setTaskName] = useState([]);
    const [isTaskEditing, setIsTaskEditing] = useState(false);
    const [editedTasks, setEditedTasks] = useState([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
    const [nameInputValue, setNameInputValue] = useState<string>(actionHighlights?.payload?.name || "");
    const [description, setDescription] = useState<string>("");
    const [isDeltaEditing, setIsDeltaEditing] = useState<boolean>(false);
    const [deltaInputValue, setDeltaInputValue] = useState<string>(actionHighlights?.payload?.delta || "");
    const taskIdSet = new Set<string>();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const isStageMatch = stageId === actionHighlights?.stageId;
    console.log('chk67', isStageMatch);

    const uniqueTasks = stageAllTaskView?.filter((obj: any) => {
        const taskId = obj?.taskId?._id;
        const isUnique = taskId && !taskIdSet?.has(taskId);
        if (isUnique) {
            taskIdSet?.add(taskId);
        }
        return isUnique;
    });

    const taskOptions: Option[] = uniqueTasks?.map((obj: any) => ({
        label: obj?.taskId?.name,
        value: obj?._id,
    }));
    const togglePopover = () => {
        setOpen(!Open);
    }

    useEffect(() => {
        dispatch(getAllTemplateChecklist({ query: "subtasks" }))
    }, [dispatch])
    useEffect(() => {
        if (actionHighlights?.payload?.description) {
            setDescription(actionHighlights?.payload?.description);
        }
    }, [actionHighlights]);
    useEffect(() => {
        setIsSwitchChecked(actionHighlights?.payload?.isDone)
    }, [actionHighlights?.payload?.isDone])

    const handleEditClick = () => {
        if (isStageMatch) {
            setIsEditing(true);
        }
    };

    const handleNameEditClick = () => {

        if (isStageMatch) {
            setIsNameEditing(true);
            setNameInputValue(actionHighlights?.payload?.name || "");
        }
    };
    const handleDeltaEditClick = () => {
        if (isStageMatch) {
            setIsDeltaEditing(true);
            setDeltaInputValue(actionHighlights?.payload?.delta || "");
        }
    };

    const handleDeltaCancelClick = () => {
        setIsDeltaEditing(false);
        setDeltaInputValue(actionHighlights?.payload?.delta || "");
    };

    const handleDeltaSaveClick = () => {

        const payload = {
            actionType: selectedHighlight?.name,
            delta: deltaInputValue,
            stageId: actionObject?.stageId
        };

        dispatch(createBCDoneHighlight(payload, actionHighlights?._id));
        setIsDeltaEditing(false);
    };
    const handleNameCancelClick = () => {
        setIsNameEditing(false);
    };
    const handleDescriptionCancelClick = () => {
        setIsEditing(false);
    };
    const handleNameSaveClick = () => {
        const payload = {
            actionType: selectedHighlight?.name,
            name: nameInputValue,
            stageId: actionObject?.stageId
        };
        dispatch(createBCDoneHighlight(payload, actionHighlights?._id));
        setIsNameEditing(false);
    };

    const handleSaveClick = () => {
        const payload = {
            actionType: selectedHighlight?.name,
            description: description,
            stageId: actionObject?.stageId
        };
        dispatch(createBCDoneHighlight(payload, actionHighlights?._id));
        setIsEditing(false);
    };
    const handleTaskEditClick = () => {
        setIsTaskEditing(true);
        setEditedTasks(taskName);
    };
    const handleTaskSaveClick = () => {
        setIsTaskEditing(false);
        const payload = {
            actionType: selectedHighlight?.name,
            taskId: editedTasks?.map((task: any) => task?.value),
            stageId: actionObject?.stageId
        };
        dispatch(createBCDoneHighlight(payload, actionHighlights?._id));
        setIsEditing(false);
    };
    const handleTaskCancelClick = () => {
        setIsTaskEditing(false);
        setEditedTasks([]);
    };
    const handleTextareaChange = (event: any) => {
        setDescription(event.target.value);
    };

    useEffect(() => {
        setEmailLinks(actionHighlights?.payload?.emailLink || []);
    }, [actionHighlights?.payload?.emailLink]);

    const handleEmailEditClick = () => {
        if (isStageMatch) {
            setIsEmailEditing(true);
            setEditedEmailLinks(emailLinks?.map(link => createOption(link)));
        }
    };

    const handleEmailSaveClick = () => {
        setIsEmailEditing(false);

        const emailLinkValues = editedEmailLinks?.map((link: any) => link?.value);

        setEmailLinks(emailLinkValues);

        const payload = {

            emailLink: emailLinkValues,
        };

        dispatch(createBCDoneHighlight(payload, actionHighlights._id));
    };

    const handleEmailCancelClick = () => {
        setIsEmailEditing(false);
        setEditedEmailLinks([]);
    };


    useEffect(() => {
        return () => {
            dispatch(getActionIdSuccess([]))
            dispatch(createActionHighlightSuccess([]))
        }
    }, [])

    const toggleBCDone = (value: boolean) => {
        if (isStageMatch) {
            const payload = {
                actionType: selectedHighlight?.name,
                isDone: value,
                stageId: actionObject?.stageId
            };
            dispatch(createBCDoneHighlight(payload, actionHighlights?._id));
            setIsSwitchChecked(value);
        }
    }
    useEffect(() => {
        function handleKeyDown(event: any) {
            if (Open && event.key === 'Escape') {
                setOpen(!Open)
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [Open]);
    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setEditedEmailLinks((prev) => [...prev, createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
        }
    };

    const handleBlur: FocusEventHandler = () => {
        if (inputValue.trim() !== '') {
            setEditedEmailLinks((prev) => [...prev, createOption(inputValue)]);
            setInputValue('');
        }
    };
    useEffect(() => {
        if (textareaRef?.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px `;
            //   textareaRef.current.style.marginTop = "20px";
        }
    }, [isNameEditing]);
    const switchLabel = isSwitchChecked ? 'Background Change Done' : 'Background Change InProgress';

    useEffect(() => {
        if (Open) {
            if (actionHighlights?.stageId) {
                dispatch(getAllTaskViewByStageId(actionHighlights?.stageId));
            }
        }
    }, [Open, actionHighlights])

    useEffect(() => {
        if (Open && stageAllTaskView && actionHighlights?.payload?.taskId) {
            const matchingTaskNames: any = [];
            for (const task of stageAllTaskView) {
                if (actionHighlights?.payload?.taskId?.includes(task?._id)) {
                    matchingTaskNames.push({ label: task.taskId.name, value: task._id });
                }
            }
            setTaskName(matchingTaskNames);
        }
    }, [Open, stageAllTaskView, actionHighlights]);

    return (
        <Modal isOpen={Open} scrollable size="xl" centered keyboard={false}>
            <ModalHeader toggle={togglePopover}>
                <Flex sx={{ gap: "1rem" }}>
                    <Image src={selectedHighlight?.icon} />
                    <Text sx={{ color: selectedHighlight?.color }}>{selectedHighlight?.name?.toUpperCase()}</Text>
                </Flex>
            </ModalHeader>
            {!isStageMatch && <Text className='ps-3' sx={{ color: `${theme?.colors?.danger}` }}>Note: This content is for viewing only and cannot be modified </Text>
            }
            <ModalBody className="scrollable-modal-body">
                <Card sx={{ boxShadow: "none", border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: "12px", padding: "8px 16px", position: "relative" }}>
                    {selectedHighlight?.name === 'Background Change' && (
                        <Flex sx={{ position: 'absolute', top: '8px', right: '8px' }}>
                            <Box>
                                {(is_Task_Worker && rolePermissionsHighLighters.includes(selectedHighlight?.name)) ? (
                                    <CustomTooltip id={switchLabel.trim()} title="Permission Denied">
                                        <Switch sx={{ cursor: "not-allowed" }} label={switchLabel} checked={isSwitchChecked} />
                                    </CustomTooltip>
                                ) : (
                                    <Switch
                                        disabled={!isStageMatch || !isBcChecked}
                                        label={switchLabel}
                                        checked={isSwitchChecked}
                                        onChange={(e: any) => toggleBCDone(e?.target?.checked)}
                                    />
                                )}
                            </Box>
                        </Flex>
                    )}
                    <Flex sx={{ flexDirection: 'column' }}>
                        <Flex sx={{ alignItems: 'center' }}>
                            <Text variant="SelectLabel" sx={{ flex: '1' }}>Name:</Text>
                            {!isNameEditing && isStageMatch && (
                                <>
                                    <Image
                                        src={editIcon}
                                        sx={{ cursor: 'pointer', width: '24px', height: '24px', ml: '1rem', mt: '10px' }}
                                        onClick={handleNameEditClick}
                                    />
                                </>
                            )}
                        </Flex>
                        <Box sx={{ mt: '6px' }}>
                            {!isNameEditing && (
                                <>
                                    <Textarea
                                        ref={textareaRef}
                                        value={actionHighlights?.payload?.name}
                                        placeholder={"Enter Name"}
                                        name={"name"}
                                        variant="PersonalDetailsInput"
                                        className="form-control"
                                        sx={{ overflow: 'hidden' }}
                                        disabled={!isStageMatch}
                                    />
                                </>
                            )}
                            {isNameEditing && (
                                <Textarea
                                    value={nameInputValue}
                                    placeholder={"Enter Name"}
                                    name={"name"}
                                    // type='text'
                                    // Max_Length={10}

                                    // Title_sx={{ color: "grey3" }}
                                    onChange={(e: any) => setNameInputValue(e.target.value)}
                                />
                            )}
                        </Box>
                        {isNameEditing && (
                            <Flex className="mt-3" sx={{ justifyContent: 'end', gap: '8px' }}>
                                <Button
                                    sx={{
                                        background: theme?.colors?.white,
                                        color: theme?.colors?.primary,
                                        border: `1px solid ${theme?.colors?.primary}`,
                                        textAlign: 'center',
                                        borderRadius: '12px',
                                    }}
                                    onClick={handleNameCancelClick}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    sx={{ background: theme?.colors?.primary, textAlign: 'center', borderRadius: '12px' }}
                                    onClick={handleNameSaveClick}
                                >
                                    Save
                                </Button>
                            </Flex>
                        )}
                        <Box sx={{ flexDirection: 'column' }}>
                            <Flex sx={{ alignItems: 'center' }}>
                                <Text variant="SelectLabel">Description:</Text>
                                {!isEditing && isStageMatch && (
                                    <Image
                                        src={editIcon}
                                        sx={{ cursor: 'pointer', width: '24px', height: '24px', ml: 'auto' }}
                                        onClick={handleEditClick}
                                    />
                                )}
                            </Flex>
                            <Box sx={{ mt: '8px' }}>
                                {!isEditing ? (
                                    <Box className='ps-2' sx={{ minHeight: `${description?.length < 30 ? "65px" : "150px"}`, maxHeight: '300px', border: "2px solid rgb(247, 239, 229)", overflowY: "auto", borderRadius: "4px", resize: 'vertical' }}>
                                        <MarkDownParagraph markdown={description} />
                                    </Box>
                                ) : (
                                    <>
                                        <Textarea
                                            value={description}
                                            onChange={handleTextareaChange}
                                            rows={description?.length > 30 ? 10 : 2}
                                            placeholder="Description..."
                                            disabled={!isStageMatch}

                                        />
                                        <Flex className="mt-3" sx={{ justifyContent: 'end', gap: '8px' }}>
                                            <Button
                                                sx={{
                                                    background: theme?.colors?.white,
                                                    color: theme?.colors?.primary,
                                                    border: `1px solid ${theme?.colors?.primary}`,
                                                    textAlign: 'center',
                                                    borderRadius: '12px',
                                                }}
                                                onClick={handleDescriptionCancelClick}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                sx={{ background: theme?.colors?.primary, textAlign: 'center', borderRadius: '12px' }}
                                                onClick={handleSaveClick}
                                            >
                                                Save
                                            </Button>
                                        </Flex>
                                    </>
                                )}
                            </Box>
                        </Box>


                        {/* Other fields and components */}
                        <Box>
                            <Flex sx={{ alignItems: 'center' }}>
                                <Text variant="SelectLabel" sx={{ flex: '1' }}>URL Links:</Text>
                                {!isEmailEditing && isStageMatch && (
                                    <Image
                                        src={editIcon}
                                        sx={{ cursor: 'pointer', width: '24px', height: '24px', ml: '1rem' }}
                                        onClick={handleEmailEditClick}
                                    />
                                )}
                            </Flex>
                            <Box sx={{ mt: '6px' }}>
                                {/* {!isEmailEditing ? (
                                    <CreatableSelect
                                        components={{ DropdownIndicator: null }}
                                        inputValue={inputValue}
                                        isClearable
                                        isMulti
                                        menuIsOpen={false}
                                        value={emailLinks?.map((link: string) => createOption(link))}
                                        onChange={(newValue: any) => {
                                            setEmailLinks(newValue?.map((item: any) => item.label));
                                        }}
                                        onInputChange={(newValue) => {
                                            setInputValue(newValue);
                                        }}
                                        placeholder=""
                                        styles={{
                                            valueContainer: (base) => ({
                                                ...base,
                                                maxHeight: '90px',
                                                overflowY: 'auto',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                paddingRight: '30px',
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                border: '1px solid #f7efe5',
                                                borderRadius: '12px',
                                                minHeight: '48px',
                                                flexWrap: 'wrap',
                                                display: 'flex',
                                                position: 'relative',
                                            }),
                                            clearIndicator: (base) => ({
                                                ...base,
                                                position: 'absolute',
                                                right: '10px',
                                                top: '45%',
                                                transform: 'translateY(-95%)',
                                                zIndex: 1,
                                                padding: '0 5px',
                                                cursor: 'pointer',
                                            }),
                                            multiValue: (base) => ({
                                                ...base,
                                            }),
                                        }}
                                        isDisabled={!isStageMatch || !isEmailEditing}
                                    />
                                ) */}

                                <Box sx={{ mt: '6px' }}>
                                    {!isEmailEditing ? (
                                        <Box className='px-2' sx={{
                                            border: `1px solid ${theme?.colors?.lightbackground}`, borderRadius: '4px',
                                        }}>
                                            {emailLinks?.map((link, index) => (
                                                <a key={index} href={link} target="_blank" rel="noopener noreferrer" style={{ display: 'block', margin: '4px 0' }}>
                                                    {link}
                                                </a>
                                            ))}
                                        </Box>
                                    )
                                        :
                                        (
                                            <>
                                                <CreatableSelect
                                                    components={{ DropdownIndicator: null }}
                                                    inputValue={inputValue}
                                                    isClearable
                                                    isMulti
                                                    menuIsOpen={false}
                                                    value={editedEmailLinks}
                                                    onChange={(newValue: any) => {
                                                        setEditedEmailLinks(newValue);
                                                    }}
                                                    onInputChange={(newValue) => {
                                                        setInputValue(newValue);
                                                    }}
                                                    onKeyDown={handleKeyDown}
                                                    onBlur={handleBlur}
                                                    placeholder=""
                                                    styles={{
                                                        valueContainer: (base) => ({
                                                            ...base,
                                                            maxHeight: '90px',
                                                            overflowY: 'auto',
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            paddingRight: '30px',
                                                        }),
                                                        control: (base) => ({
                                                            ...base,
                                                            border: `1px solid ${theme?.colors?.lightbackground}`,
                                                            borderRadius: '12px',
                                                            minHeight: '48px',
                                                            flexWrap: 'wrap',
                                                            display: 'flex',
                                                            position: 'relative',
                                                        }),
                                                        clearIndicator: (base) => ({
                                                            ...base,
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '45%',
                                                            transform: 'translateY(-95%)',
                                                            zIndex: 1,
                                                            padding: '0 5px',
                                                            cursor: 'pointer',
                                                        }),
                                                        multiValue: (base) => ({
                                                            ...base,
                                                        }),
                                                    }}
                                                />
                                                <Flex sx={{ justifyContent: 'end', gap: '8px', mt: '6px' }}>
                                                    <Button
                                                        sx={{
                                                            background: theme?.colors?.white,
                                                            color: theme?.colors?.primary,
                                                            border: `1px solid ${theme?.colors?.primary}`,
                                                            textAlign: 'center',
                                                            borderRadius: '12px',
                                                        }}
                                                        onClick={handleEmailCancelClick}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        sx={{ background: `${theme.colors?.primary}`, textAlign: 'center', borderRadius: '12px' }}
                                                        onClick={handleEmailSaveClick}
                                                    >
                                                        Save
                                                    </Button>
                                                </Flex>
                                            </>
                                        )}
                                </Box>
                                {/* <Text variant="SelectLabel">URL Links:</Text> */}

                            </Box>

                            <Flex sx={{ alignItems: 'center' }}>
                                <Text variant="SelectLabel" sx={{ flex: '1' }}>Task:</Text>
                                {!isTaskEditing && isStageMatch && (
                                    <Image
                                        src={editIcon}
                                        sx={{ cursor: 'pointer', width: '24px', height: '24px', ml: '1rem' }}
                                        onClick={handleTaskEditClick}
                                    />
                                )}
                            </Flex>
                            <Box sx={{ mt: '6px' }}>
                                {!isTaskEditing ? (
                                    <MultiSelect
                                        options={taskOptions}
                                        value={taskName}
                                        onChange={setTaskName}
                                        labelledBy="Select"
                                        disabled={!isStageMatch || !isTaskEditing}
                                    />
                                ) : (
                                    <>
                                        <MultiSelect
                                            options={taskOptions}
                                            value={editedTasks}
                                            onChange={setEditedTasks}
                                            labelledBy="Select"
                                        />
                                        <Flex sx={{ justifyContent: 'end', gap: '8px', mt: '6px' }}>
                                            <Button
                                                sx={{
                                                    background: theme?.colors?.white,
                                                    color: theme?.colors?.primary,
                                                    border: `1px solid ${theme?.colors?.primary}`,
                                                    textAlign: 'center',
                                                    borderRadius: '12px',
                                                }}
                                                onClick={handleTaskCancelClick}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                sx={{ background: `${theme.colors?.primary}`, textAlign: 'center', borderRadius: '12px' }}
                                                onClick={handleTaskSaveClick}
                                            >
                                                Save
                                            </Button>
                                        </Flex>
                                    </>
                                )}
                            </Box>
                            <Flex sx={{ flexDirection: 'column' }} >
                                <Flex sx={{ alignItems: 'center' }}>
                                    <Text variant="SelectLabel" sx={{ flex: '1' }}>Delta:</Text>
                                    {!isDeltaEditing && isStageMatch && (
                                        <>

                                            <Image
                                                src={editIcon}
                                                sx={{ cursor: 'pointer', width: '24px', height: '24px', ml: '1rem' }}
                                                onClick={handleDeltaEditClick}
                                            />
                                        </>
                                    )}
                                </Flex>
                                <Box sx={{ mt: '6px' }}>
                                    {!isDeltaEditing && (
                                        <>
                                            <Inputform
                                                values={actionHighlights?.payload?.delta}
                                                Placeholder={"Enter Delta"}
                                                Name={"delta"}
                                                type='text'
                                                Title_sx={{ color: "grey3" }}
                                                OnChange={(e: any) => setDeltaInputValue(e.target.value)}
                                                disabled={!isStageMatch}
                                            />
                                        </>
                                    )}
                                    {isDeltaEditing && (
                                        <Inputform
                                            values={deltaInputValue}
                                            Placeholder={"Enter Delta"}
                                            Name={"delta"}
                                            type='text'
                                            Title_sx={{ color: "grey3" }}
                                            OnChange={(e: any) => setDeltaInputValue(e.target.value)}
                                        />
                                    )}
                                </Box>
                                {isDeltaEditing && (
                                    <Flex className="mt-3" sx={{ justifyContent: 'end', gap: '8px' }}>
                                        <Button
                                            sx={{
                                                background: theme?.colors?.white,
                                                color: theme?.colors?.primary,
                                                border: `1px solid ${theme?.colors?.primary}`,
                                                textAlign: 'center',
                                                borderRadius: '12px',
                                            }}
                                            onClick={handleDeltaCancelClick}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            sx={{ background: theme?.colors?.primary, textAlign: 'center', borderRadius: '12px' }}
                                            onClick={handleDeltaSaveClick}
                                        >
                                            Save
                                        </Button>
                                    </Flex>
                                )}
                            </Flex>

                            <Flex className='mt-2'>
                                <Box className=' col-6 me-2'>
                                    <Inputform
                                        label={"Version"}
                                        Placeholder={""}
                                        Name={"version"}
                                        sx={{ backgroundColor: '#a9a9a97a' }}
                                        type='text'
                                        Title_sx={{ color: "grey3" }}
                                        values={actionHighlights?.payload?.number || ""}
                                        Max_Length={10}
                                        disabled
                                    // OnChange={(event: any) => {
                                    //     const newName = event.target.value;
                                    //     setFieldValue("name", newName, true);
                                    // }}
                                    />

                                </Box>
                                <Box className=' col-6 ' >
                                    <Inputform
                                        label={"Created Date:"}
                                        Placeholder={""}
                                        Name={"createdAt"}
                                        sx={{ backgroundColor: '#a9a9a97a' }}
                                        type='text'
                                        Title_sx={{ color: "grey3" }}
                                        values={moment(actionHighlights?.createdAt).format('YYYY-MM-DD') || ""}
                                        Max_Length={10}
                                        disabled
                                    // OnChange={(event: any) => {
                                    //     const newName = event.target.value;
                                    //     setFieldValue("name", newName, true);
                                    // }}
                                    />

                                </Box>

                            </Flex>
                        </Box>
                    </Flex>

                    <Divider />

                    <div>
                        <ActionModuleTab
                            actionObject={{ ...actionObject }}
                            selectedHighlight={selectedHighlight}
                            keys={keys}
                            actionId={actionId}
                        />
                    </div>

                </Card>
                <Divider />
            </ModalBody>
            <ModalFooter className='gap-2  justify-content-end p-2'>
                <Button variant="SaveButton" type="submit" onClick={togglePopover}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ActionBcPcChecklist;

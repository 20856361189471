import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Image } from "theme-ui";
import crossIcon from "../assets/images/Icon/cross.svg";
import plusIcon from "../assets/images/Icon/plus.svg";

import { Overlay, Popover } from "react-bootstrap";
import { ActionModule } from "./ActionModule/ActionModule";
import { ActionHourADay } from "./ActionModule/ActionHourADay";
import { useDispatch } from "react-redux";
import { getAllTaskViewByStageId } from "../../store/actions/project.action";
import SubmitReview from "./ActionModule/SubmitReview";
import ActionIPRCompleted from "./ActionModule/ActionIPRCompleted";
import ActionBackgroundCheck from "./ActionModule/ActionBackgroundCheck";
import { RootState } from "../../../@types";
import { ShowHideTaskAction } from "../../utils/CustomHandler";
import ActionConfirmation from "./ActionModule/ActionConfirmation";
import theme from "../../theme";
import RatingReviewModal from "../pages/demotable/Ratings/RatingReviewModal";
import { createRating, getCriteriabyTaskId } from "../../store/actions/Rating.Action";
import toast from "react-hot-toast";
interface Props {
    selectedTab: any
    taskId: string
    handleTabClick: any
}
const AddAction: React.FC<Props> = ({ selectedTab, handleTabClick, taskId }) => {
    const dispatch = useDispatch()
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [selectedHighlight, setSelectedHighlight] = useState<any>(null);
    const { tasksView, stageAllTaskView } = useSelector((state: any) => state.project);
    const { auther } = useSelector((state: RootState) => state.auth);
    // console.log('chk25', taskId);

    const { rating: RatingCriteria } = useSelector((state: any) => state.rating);
    const [comment, setComment] = useState<string[]>([]);
    const [rating, setRating] = useState<number[]>([1, 1, 1, 1]);
    const [showSubmitReview, setShowSubmitReview] = useState(false);

    const { highlighterByView } = useSelector((state: any) => state.project)
    const [Open, setOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const [target, setTarget] = useState(null);
    const [ratingModalOpen, setRatingModalOpen] = useState(false);
    const commentRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

    useEffect(() => {
        if (tasksView?.stageDataId) {
            dispatch(getAllTaskViewByStageId(tasksView?.stageDataId));
        }
    }, [tasksView?.stageDataId])

    // Function to handle click on highlight name
    const handleHighlightClick = (highlight: any) => {

        setSelectedHighlight(highlight);
        if (highlight?.name === 'Ready for Final Review' && tasksView?.status === 'Internal Review') {
            if (tasksView?.worker === undefined || tasksView?.worker === null) {
                toast.error('Please select a worker!');
                return;
            }
            setRatingModalOpen(true)
            dispatch(getCriteriabyTaskId(tasksView?.taskId?._id || 'null'))

        }
        else {
            setOpen(true);

        }

        setIsPopoverOpen(false);
    };
    const handleRatingChange = (index: number, newRating: number) => {
        const updatedRatings = [...rating];
        updatedRatings[index] = newRating;
        setRating(updatedRatings);
    };
    const handleCommentChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedComments = [...comment];
        updatedComments[index] = event.target.value;
        setComment(updatedComments);

        const textarea = commentRefs.current[index];
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    };
    const handleSave = () => {
        const payload = {
            workerName: ` ${tasksView?.worker?.FirstName} ${tasksView?.worker?.LastName}`,
            workerNameRef: tasksView?.worker?._id,
            ratingGivenBy: auther?.FirstName,
            ratingGivenByRef: auther?._id,
            projectName: tasksView?.projectId?.name,
            projectNameRef: tasksView?.projectId?._id,
            tradeName: tasksView?.name,
            tradeNameRef: tasksView?.taskId?._id,
            projectPhase: {
                value: tasksView?.stageId?._id,
                label: tasksView?.stageId?.name
            },
            criteria: RatingCriteria?.ratingCriteria?.map((ratingText: any, index: number) => ({
                id: ratingText?._id,
                name: ratingText?.ratingCriteria,
                rating: rating[index] || 0,
                comment: comment[index]
            }))
        };
        dispatch(createRating(payload));
        setShowSubmitReview(true);
        setOpen(true);
        setRatingModalOpen(false);
        setComment([]);

    };
    const togglePopover = (event: any) => {
        setIsPopoverOpen(!isPopoverOpen);
        setTarget(event.target);
    };
    const handleClickOutside = (event: any) => {

        if (!event.target.closest('.popover-body')) {

            if (!event.target.closest('.toggle-btn')) {
                setIsPopoverOpen(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const taskIndex = stageAllTaskView?.findIndex((task: any) => task?._id === taskId) + 1;

    const customProps = { selectedHighlight, Open, setOpen, actionObject: { projectId: tasksView?.projectId?._id, stageId: tasksView?.stageDataId, taskId: tasksView?._id }, activeTab: taskIndex, TaskView: stageAllTaskView?.map((task: any) => ({ id: task?._id, name: task?.name })) }

    // const getFilterdHighliters = ShowHideTaskAction(highlighterByView, tasksView?.status)

    const [getFilterdHighliters, setGetFilterdHighliters] = useState<any>([])

    useEffect(() => {
        if (isPopoverOpen) {
            const isProjectManager = auther?.Role?.some((role) => role.RoleName === "Project Manager")
            let filterdHighlights = (isProjectManager && tasksView?.status === "Pause") ? ShowHideTaskAction(highlighterByView, tasksView?.status).filter((item) => item?.name !== "Task Done") : ShowHideTaskAction(highlighterByView, tasksView?.status);
            let RTD = highlighterByView?.filter((highlight: any) => highlight?.name === "Ready To Deliver");
            if (isProjectManager && tasksView?.status === "Pause") {
                filterdHighlights?.push(RTD[0])
            }

            let TSKD = highlighterByView?.filter((highlight: any) => highlight?.name === "Task Done");

            if (tasksView?.timeRemaining === 0 && tasksView?.status === "IPR Completed") {

                filterdHighlights?.push(TSKD[0])
            }
            if (tasksView?.status === "Pause" && tasksView?.lastStatus === "IPR") {
                let IPRCompleted = highlighterByView?.filter((highlight: any) => highlight?.name === "IPR Completed");
                filterdHighlights?.push(IPRCompleted[0]);
            }
            setGetFilterdHighliters(filterdHighlights)
        }
    }, [isPopoverOpen])


    return (
        <div ref={popoverRef}>
            <Overlay
                show={isPopoverOpen}
                target={target}
                placement="left"
            >
                <Popover style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }} id={`popover-positioned`}>
                    <Popover.Body className="w-auto">
                        {getFilterdHighliters?.map((highlight: any, index: number) => {

                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        color: highlight?.color, padding: "12px", borderRadius: "8px", cursor: 'pointer', '&:hover': {
                                            backgroundColor: `${theme?.colors?.lightbackground}`
                                        }
                                    }}
                                    onClick={(e: any) => {
                                        handleHighlightClick(highlight);
                                    }}
                                >
                                    {highlight?.icon && < img width={22} src={highlight?.icon} alt='' />} &nbsp;{highlight?.name}
                                </Box>
                            );
                        })}
                    </Popover.Body>
                </Popover>

            </Overlay >
            <button className='btn toggle-btn' style={{ backgroundColor: selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit', padding: "0px" }} onClick={togglePopover}
            >
                {isPopoverOpen ? <Image src={crossIcon} alt='' style={{ fill: selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />
                    : <Image src={plusIcon} alt='' style={{ fill: selectedTab === 'action' ? `${theme?.colors?.lightbackground}` : 'inherit' }} />}
                {/* <br /> */}
                {/* {selectedHighlight && (<img src={selectedHighlight?.icon} />)} */}
            </button>
            {selectedHighlight?.name === "Need Info" && <ActionModule {...customProps} keys={{ first: "isChecked", second: "isBlockByInfo" }} />}
            {selectedHighlight?.name === "New Info Added" && <ActionModule {...customProps} keys={{ first: "isChecked" }} handleHighlightClick={handleHighlightClick} />}
            {selectedHighlight?.name === "Delivery Notes" && <ActionModule {...customProps} keys={{ first: "isChecked", second: "isInternal" }} />}
            {selectedHighlight?.name === "Background Change" && <ActionBackgroundCheck {...customProps} keys={{ first: "isChecked", second: "isInternal" }} />}
            {selectedHighlight?.name === "Internal Review" && <ActionModule {...customProps} keys={{ first: "isChecked" }} />}
            {selectedHighlight?.name === "Blocked By Trade" && <ActionModule {...customProps} keys={{ first: "isChecked", second: "blockingTaskId" }} />}
            {selectedHighlight?.name === "Hour a Day" && <ActionHourADay {...customProps} keys={{ first: "blockingTaskId" }} />}
            {selectedHighlight?.name === "Final Review" && <ActionModule {...customProps} keys={{ first: "isChecked" }} />}
            {selectedHighlight?.name === "Task Done" && <SubmitReview {...customProps} keys={{ first: "isChecked" }} message={"Submit For Internal Review"} actionName={"Internal Review"} />}
            {selectedHighlight?.name === "Stage Done" && <SubmitReview {...customProps} keys={{ first: "isChecked" }} message={"Submit Your Final Review"} actionName={"Final Review"} />}
            {selectedHighlight?.name === "Ready To Deliver" && <SubmitReview {...customProps} keys={{ first: "isChecked" }} message={"Mark Task as Completed"} actionName={"Completed"} />}
            {selectedHighlight?.name === "IPR Completed" && <ActionIPRCompleted {...customProps} keys={{ first: "isChecked" }} message={"Are you sure you want to change status to IPR Complete"} actionName={"IPRCompleted"} />}
            {selectedHighlight?.name === "Ready for Final Review" && <RatingReviewModal
                open={ratingModalOpen}
                toggle={() => setRatingModalOpen(false)}
                RatingCriteria={RatingCriteria || []}
                comment={comment}
                handleSave={handleSave}
                handleRatingChange={handleRatingChange}
                handlecommentChange={handleCommentChange}
                data={tasksView}

            />}
            {showSubmitReview && (
                <SubmitReview
                    {...customProps}
                    keys={{ first: "isChecked" }}
                    message={"Submit Your Final Review"}
                    actionName={"Final Review"}
                />
            )}
            {selectedHighlight?.name === "Narrarative of Changes" && <ActionConfirmation {...customProps} />}

        </div >
    );
}
export default AddAction

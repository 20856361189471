import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Box, Divider } from 'theme-ui';
import DataTable from '../../../Tabels/Tabel';
import { setPagination } from '../../../../store/actions/Pagination.action';
import { getAllTaskTypesMasterData } from '../../../../store/actions/masterData.action';
import TableActions from '../../users/TableActions';
import { TabelHeadingTxt } from '../../../Text/TabelCommonTxt';
import { useNavigate } from 'react-router-dom';
import plus from "../../../assets/images/Icon/plus.svg";

const TaskTypeMasterData: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [refresh, setRrefresh] = useState<boolean>(true);
    const { taskType, loader } = useSelector((state: any) => state.masterData);
    const { paginations } = useSelector((state: any) => state.pagination);
    const columns = useMemo<ColumnDef<any>[]>(() => [
        // {
        //     accessorKey: "id",
        //     id: "select",
        //     enableColumnFilter: false,
        //     header: ({ table }) => (
        //         <div className="d-flex justify-content-center">
        //             <IndeterminateCheckbox
        //                 {...{
        //                     checked: table.getIsAllRowsSelected(),
        //                     indeterminate: table.getIsSomeRowsSelected(),
        //                     onChange: table.getToggleAllRowsSelectedHandler(),
        //                 }}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }) => (
        //         <IndeterminateCheckbox
        //             {...{
        //                 checked: row.getIsSelected(),
        //                 disabled: !row.getCanSelect(),
        //                 indeterminate: row.getIsSomeSelected(),
        //                 onChange: row.getToggleSelectedHandler(),
        //             }}
        //         />
        //     ),
        // },
        {
            header: 'No',
            enableColumnFilter: false,
            accessorKey: "no",
            cell: ({ row }) => {
                return (
                    <Text variant='Poppins14Normal24lh'>{((paginations?.currentPage - 1) * paginations?.limit) + row?.index + 1}</Text>
                )
            }
        },
        {
            accessorKey: "name",
            header: 'Task Name',
            cell: info => info.getValue(),
        },
        {
            header: 'Color',
            enableColumnFilter: false,
            accessorKey: "color",
            cell: ({ row }) => {
                return (
                    <div className='d-flex justify-content-center'>
                        <Box sx={{ border: "2px solid black", width: "40%", borderRadius: 40, justifyContent: 'center', borderColor: row?.original?.color, backgroundColor: row?.original?.color, color: row?.original?.color }} className="text-center d-flex">.</Box>
                    </div>
                )
            }
        },
        {
            header: 'Accessor',
            accessorKey: "Accessor",
            enableColumnFilter: false,
            cell: ({ row }) => {
                return (
                    <TableActions row={row} editTo={`/edit-task-type/${row?.original?._id}`} deleteMessage='Are you sure you want to delete this task type?' deleteApi={`task-types/delete-task-type/${row?.original?._id}`} toggleRefresh={() => setRrefresh(true)} />
                )
            },
        }
    ], [taskType?.data]);
    useEffect(() => {
        return () => {
            dispatch(setPagination({
                limit: 15,
                currentPage: 1,
            }))
        }
    }, [])
    useEffect(() => {
        if (refresh) {
            dispatch(getAllTaskTypesMasterData(paginations))
            setRrefresh(false);
        }
    }, [refresh])
    const handleclick = () => {
        navigate("/create-task-type")
    }
    return (
        <div className='main-content'>
            <TabelHeadingTxt Title='Task Type' Count={taskType?.count || 15} BtnTxt='Create Task Type' src={plus} onClick={handleclick} sx={{ backgroundColor: '#7743DB', color: 'white' }} />
            <Divider sx={{ color: '#D9D7DD' }} opacity={2} my={[3, 3, 4]} />
            <DataTable
                loader={loader}
                columns={columns}
                data={taskType?.data || []}
                TotalLength={taskType?.count || 15}
                perPageCount={paginations.limit}
                currentPage={paginations.currentPage}
                onPerPageChange={(itemsPerPage) => {
                    dispatch(setPagination({
                        limit: itemsPerPage,
                        currentPage: 1
                    }));
                    setRrefresh(true);
                }}
                onPageChange={(value) => {
                    dispatch(setPagination({
                        ...paginations,
                        ...{ currentPage: value }
                    }));
                    setRrefresh(true);
                }} />
        </div>
    )
};

export default TaskTypeMasterData;

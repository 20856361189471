import { Box, Button, Flex, Grid, Input, Label, Text } from 'theme-ui';
import deleteIcon from "../../../Components/assets/images/Icon/delete.svg";
import editIcon from "../../../Components/assets/images/edit.svg";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Inputform } from '../../forms-component/InputForm';
import { useDispatch } from 'react-redux';
import { addCommentActionHighlight, deleteCommentActionHighlight, editCommentActionHighlight } from '../../../store/actions/action.Highlighters';
import { useState } from 'react';
import { DeletePopModule } from '../../DeletePopModule/DeletePopModule';
import theme from '../../../theme';


const AddCommentAction = ({ actionId, nameDec, commnets, actionObject }: any) => {
  const [updateComment, setUpdateComment] = useState<string>();
  const [oldComment, setOldComment] = useState<string>();
  const [isEditing, setIsEditing] = useState<any>();
  const [deleteData, setDeleteData] = useState<string>("");
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState<boolean>(false);

  const dispatch = useDispatch()
  const formik = useFormik(
    {
      enableReinitialize: true,
      initialValues: {
        name: ''
      },
      validationSchema: Yup.object({
        name: Yup.string().required('Check list item is required'),
      }),
      onSubmit: (value) => {
        const newObj = {
          name: nameDec,
          data: value,
          ...actionObject
        }
        dispatch(addCommentActionHighlight(newObj, actionId))
        formik.resetForm()
      }
    }
  );
  const { handleSubmit, setFieldValue, values, handleBlur, touched, errors } = formik;
  const editActionHighlightComment = () => {
    const payload = {
      name: oldComment,
      data: {
        name: updateComment,
      },
      descriptionName: nameDec
    }
    dispatch(editCommentActionHighlight(payload, actionId))
  }
  const deleteCheckAction = () => {
    dispatch(deleteCommentActionHighlight({ name: deleteData, descriptionName: nameDec }, actionId))
  }

  return (
    <>
      <Grid sx={{ border: ` 0.5px solid ${theme?.colors?.text} `, background: "#C3ACD029", borderRadius: "12px", padding: "12px", gap: "16px" }}>
        {commnets?.map((item: any, index: number) => <Flex key={index} sx={{
          paddingLeft: "12px",
          paddingRight: "20px",
          background: "#fff",
          borderRadius: "8px",
          gap: "10px",
          width: "85%",
          marginLeft: "auto",
          alignItems: "center"
        }}>
          <Input
            variant="PersonalDetailsInput"
            className="form-control"
            id={`commnetList${index}`}
            sx={{
              '&:disabled ': {
                backgroundColor: "transparent",
              }, background: "#fff", borderRadius: "12px", borderColor: "transparent !important"
            }}
            defaultValue={item?.name}
            value={index === isEditing ? updateComment : item?.name}
            disabled={index === isEditing ? false : true}
            onChange={(e) => {
              setOldComment(item?.name);
              setUpdateComment(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                editActionHighlightComment();
                setIsEditing(null);
              }
            }}
          />

          <Flex sx={{ marginLeft: "auto", gap: "1rem" }}>
            <Label htmlFor={`commnetList${index}`} sx={{ cursor: "pointer" }} onClick={() => {
              setUpdateComment(item?.name);
              setIsEditing(index)
            }}><img src={editIcon} /></Label>
            <Text sx={{ cursor: "pointer" }}
              onClick={() => {
                setIsDeleteModelOpen(true);
                setDeleteData(item?.name)

              }}
            >
              <img src={deleteIcon} width={"23px"} />
            </Text>
          </Flex>
        </Flex>)}

        <Box sx={{ position: "relative", background: "#fff", borderRadius: "12px" }}>
          <form onSubmit={handleSubmit}>

            <Inputform
              label={""}
              Placeholder={"Enter check list item..."}
              Name={"name"}
              Title_sx={{ color: "grey3" }}
              touched={touched?.name}
              errors={errors?.name}
              values={values.name}
              Max_Length={10}
              onBlur={handleBlur}
              OnChange={(event: any) => {
                const newName = event.target.value;
                setFieldValue("name", newName, true);
              }}
            />
            <Button type='submit' sx={{ display: "flex", alignItems: "center", position: "absolute", right: "0px", top: "0px", borderRadius: "8px", paddingLeft: "12px" }}>Send</Button>
          </form>

        </Box>
      </Grid>
      <DeletePopModule setIsDeleteModelOpen={setIsDeleteModelOpen} updatedDataFun={deleteCheckAction} isDeleteModelOpen={isDeleteModelOpen} message={`Are you sure you want to delete this ${deleteData}`} />
    </>
  )
}

export default AddCommentAction